<template>
  <b-row>
    <b-col lg="12">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title text-primary"
          ><strong>Search & Filter</strong></h4
          >
        </template>
        <template v-slot:body>
          <b-form class="row">
            <b-col cols="6" md="3">
              <b-form-group label="Job Category*" label-for="fleet_category">
                <v-select label="text" :options="jobCategories" :reduce="(type) => type.value" v-model="lists.params.jobCategoryId"></v-select>
              </b-form-group>
            </b-col>
            <b-col cols="6" md="3">
              <b-form-group label="Search Job" label-for="search_job">
                <b-form-input id="search_job" v-model="lists.params.name" class="w-100" type="text" placeholder="Search..."></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="2">
              <b-form-group label="Search" label-for="button_search">
                <b-button type="submit" @click.prevent="getJobsData()" variant="primary" class="ml-2"><i class="fa fa-search"></i></b-button>
              </b-form-group>
            </b-col>
          </b-form>
        </template>
      </iq-card>
    </b-col>
    <b-col sm="8">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">Job List</h4>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col cols="12">
              <div class="table-responsive">
                <table class="table mb-0 table-striped table-hover">
                  <thead class="thead-dark text-center">
                  <tr>
                    <th>No.</th>
                    <th>Job Category</th>
                    <th>Job Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data, index) in lists.data">
                    <td>{{index+1}}.</td>
                    <td>{{data.jobCategory ? data.jobCategory.name : '-'}}</td>
                    <td>{{data.name}}</td>
                    <td>
                      <b-badge v-if="data.active" class="border border-success text-success" variant="none">Active</b-badge>
                      <b-badge v-else class="border border-danger text-danger" variant="none">InActive</b-badge>
                    </td>
                    <td>
                      <span class="text-info mr-1 cursor-pointer" @click="getJobDetailData(data.id)"><i class="fa fa-edit" /></span>
                      <span class="text-danger cursor-pointer" @click="deleteJobData(data.id)"><i class="fa fa-trash" /></span>
                    </td>
                  </tr>
                  <tr v-if="lists.data.length === 0 && !lists.isLoading">
                    <td colspan="5">
                      No Data
                    </td>
                  </tr>
                  <tr v-if="lists.isLoading">
                    <td colspan="5">
                      Loading
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
            <b-col class="mt-4">
              <b-pagination
                :value="lists.meta.currentPage"
                v-model="lists.meta.currentPage"
                :total-rows="lists.meta.total"
                @change="getJobsData"
                :per-page="lists.meta.perPage"
                first-number
                align="center"
              ></b-pagination>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
    <b-col sm="4">
      <iq-card
        :class="{
      'border border-danger shadow-lg': form.id,
              }"
      >
        <template v-slot:headerTitle>
          <h4 class="card-title">{{form.id ? 'Edit' : 'Add'}} Job</h4>
        </template>
        <template v-slot:body>
          <b-form @submit.prevent="onSave()">
            <b-form-group label="Job Name*" label-for="job_name">
              <b-form-input id="Job Name" required v-model="form.name" type="text" placeholder="Job Name"></b-form-input>
            </b-form-group>
            <b-form-group label-for="status-job" label="Status, Active / Inactive">
              <b-form-checkbox
                required
                v-model="form.active"
                switch
                :value="true"
                :unchecked-value="false"
              >
              </b-form-checkbox>
            </b-form-group>
            <b-form-group label="Fleet Category *" label-for="job_id">
              <v-select required label="text" :options="vehicleTypes" :reduce="(type) => type.value" v-model="form.vehicleTypeId"></v-select>
            </b-form-group>
            <b-form-group label="Job Category *" label-for="job_id">
              <v-select required :disabled="!form.vehicleTypeId" label="text" :options="filteredJobCategories" :reduce="(type) => type.value" v-model="form.jobCategoryId"></v-select>
            </b-form-group>
<!--            <b-form-group label-for="description" label="Description">-->
<!--              <b-form-textarea id="description" v-model="form.description" rows="3" placeholder="Description"></b-form-textarea>-->
<!--            </b-form-group>-->
            <b-form-group class="text-right">
              <b-button :disabled="form.saving" type="submit" variant="primary">
                <span>Save</span>
              </b-button>
              <b-button @click="resetForm()" variant="danger" class="iq-bg-danger ml-3">Cancel</b-button>
            </b-form-group>
          </b-form>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import { fleetsActions, dockingActions } from '@src/Utils/helper'

export default {
  name: 'DockingMasterDataJob',
  data () {
    return {
      vehicleTypes: [],
      jobCategories: [],
      lists: {
        isLoading: true,
        data: [],
        params: {
          name: '',
          jobCategoryId: '',
          page: 1,
          perPage: 10,
          isActive: true
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      form: {
        id: null,
        saving: false,
        name: '',
        description: '',
        active: true,
        vehicleTypeId: '',
        jobCategoryId: ''
      }
    }
  },
  computed: {
    filteredJobCategories () {
      return this.jobCategories.filter(category => category.vehicleTypeId && category.vehicleTypeId === this.form.vehicleTypeId)
    }
  },
  mounted () {
    this.getJobCategoriesData()
    this.getJobsData()
    this.getVehicleTypes()
  },
  methods: {
    ...dockingActions,
    ...fleetsActions,
    async getVehicleTypes () {
      let params = { active: true }
      let res = await this.getFleetTypes(params)

      let data = []

      if (res.data) {
        res.data.forEach(f => {
          data.push({
            checked: true,
            value: f.id,
            text: f.name,
            disabled: false
          })
        })
      }

      this.vehicleTypes = data
    },

    async getJobCategoriesData () {
      let params = { active: true, page: 1, perPage: 9999 }
      let res = await this.getJobCategories(params)

      let data = []

      if (res.data.data) {
        res.data.data.forEach(f => {
          data.push({
            checked: true,
            value: f.id,
            text: f.name,
            vehicleTypeId: f.vehicleType ? f.vehicleType.id : null,
            disabled: false
          })
        })
      }

      this.jobCategories = data
    },
    async getJobsData (page = 1) {
      this.lists.params.page = page
      if (this.lists.params.jobCategoryId === '') {
        delete this.lists.params.jobCategoryId
      }
      let res = await this.getJobs(this.lists.params)
      this.lists.isLoading = true

      if (res.status === 'success') {
        if (res.data) {
          this.lists.data = res.data.data
          this.lists.meta.perPage = res.data.perPage
          this.lists.meta.currentPage = res.data.currentPage
          this.lists.meta.total = res.data.total
          this.lists.meta.totalPage = res.data.totalPage
        }
        this.lists.isLoading = false
      } else {
        this.lists.data = []
        this.lists.isLoading = false
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async getJobDetailData (id) {
      if (id) {
        const { status, data } = await this.getJobDetail({
          id
        })

        if (status === 'success') {
          if (data) {
            this.form.id = data.id
            this.form.name = data.name
            this.form.active = data.active
            this.form.description = data.description
            this.form.jobCategoryId = data.jobCategory ? data.jobCategory.id : ''
            this.form.vehicleTypeId = data.jobCategory.vehicleType ? data.jobCategory.vehicleType.id : ''
          }
        }
      }
    },
    resetForm () {
      this.form = {
        id: null,
        saving: false,
        name: '',
        description: '',
        active: true,
        jobCategoryId: '',
        vehicleTypeId: '',
      }
    },
    async onSave () {
      this.form.saving = true

      let method = 'saveJob'

      if (this.form.id) {
        method = 'updateJob'
      }

      const { status } = await this[method](this.form)

      if (status !== 'success') {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        this.form.saving = false
        return
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      this.resetForm()
      this.getJobsData(1)

      this.form.saving = false
    },
    async deleteJobData (id) {
      if (id) {
        this.$swal({
          title: 'Yakin menghapus data ?',
          text: `Periksa Kembali Seluruh Data`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Hapus'
        }).then(async (result) => {
          if (result.isConfirmed) {
            const { status, data } = await this.deleteJob({
              id
            })

            if (status === 'success') {
              this.$swal(
                `Data Terhapus`,
                '',
                'success'
              )
              await this.getJobsData(1)
            } else {
              this.$swal(
                `Oops!`,
                data.message,
                'error'
              )
            }
          }
        })
      }
    },
  }
}
</script>

<style scoped>

</style>
