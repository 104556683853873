var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("RUNNING SHIPMENT")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-dropdown', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Export Data',
            expression: "'Export Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "link iq-bg-primary",
            "toggle-class": "text-decoration-none",
            "no-caret": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-save m-0"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _vm._v("Export Excel")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf mr-2"
        }), _vm._v("Export PDF")])], 1), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Refresh Data',
            expression: "'Refresh Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-refresh"
        })]), _c('b-button', {
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              _vm.realisasiData = !_vm.realisasiData;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v("   ADD REALISASI DATA")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Period",
            "label-for": "Period"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Cargo Type",
            "label-for": "cargo"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": ""
          }
        }, [_c('b-form-select-option', [_vm._v("All")]), _c('b-form-select-option', [_vm._v("COAL")]), _c('b-form-select-option', [_vm._v("SPLIT STONE")]), _c('b-form-select-option', [_vm._v("NICKEL")]), _c('b-form-select-option', [_vm._v("CPO")]), _c('b-form-select-option', [_vm._v("ASD")]), _c('b-form-select-option', [_vm._v("OTHERS")])], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Ownership",
            "label-for": "cargo"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": ""
          }
        }, [_c('b-form-select-option', [_vm._v("All")]), _c('b-form-select-option', [_vm._v("Own Ship")]), _c('b-form-select-option', [_vm._v("Third Party")])], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Keyword",
            "label-for": "keyword"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "id": "keyword"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table",
          staticStyle: {
            "width": "5000px",
            "max-height": "750px",
            "overflow-y": "scroll"
          }
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "position": "sticky",
            "left": "0px",
            "width": "50px"
          }
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "position": "sticky",
            "left": "50px",
            "width": "150px"
          }
        }, [_vm._v("SHIPPER")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "position": "sticky",
            "left": "200px",
            "width": "250px"
          }
        }, [_vm._v("SHIPMENT")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "position": "sticky",
            "left": "450px",
            "width": "100px"
          }
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("MODA")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "width": "200px"
          }
        }, [_vm._v("FLEET")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "width": "200px"
          }
        }, [_vm._v("COMPANY")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "width": "150px"
          }
        }, [_vm._v("CARGO TYPE")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "width": "150px"
          }
        }, [_vm._v("POL")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "width": "150px"
          }
        }, [_vm._v("TA POL/READY")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "width": "150px"
          }
        }, [_vm._v("BERTHING")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "width": "150px"
          }
        }, [_vm._v("COMMENCED")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("COMPLETED")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("CARGO LOADING")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("POD")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("TA POD/READY")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("BERTHING")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("COMMENCED")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("COMPLETED")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("CARGO UNLOADING")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("PRICE")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("PRICE TO VENDOR")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center",
          staticStyle: {
            "position": "sticky",
            "left": "0px",
            "width": "50px",
            "background-color": "white"
          }
        }, [_vm._v("1")]), _c('td', {
          staticClass: "text-center",
          staticStyle: {
            "position": "sticky",
            "left": "50px",
            "width": "150px",
            "background-color": "white"
          }
        }, [_vm._v("PLN Rembang - Longhauling"), _c('br'), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("PT.Arutmin Indonesia")])], 1), _c('td', {
          staticClass: "text-center",
          staticStyle: {
            "position": "sticky",
            "left": "200px",
            "width": "250px",
            "background-color": "white"
          }
        }, [_vm._v("JANUARY")]), _c('td', {
          staticClass: "text-center",
          staticStyle: {
            "position": "sticky",
            "left": "450px",
            "width": "100px",
            "background-color": "white"
          }
        }, [_c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("Completed")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("330 ft")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT.TCP 201/PB.TCP 3301")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT.Transcoal Pacific")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Nickel")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("MP Asam2")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("31-Dec-21 00.00")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("02-Jan-22 02.10")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("02-Jan-22 03.05")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("02-Jan-22 05.05")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("7.606 MT")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("LABUAN")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("04-Jan-22 20.00")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("04-Jan-22 20.00")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("04-Jan-22 20.00")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("04-Jan-22 20.00")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("7.506 MT")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("IDR 198.078")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.add-activity",
            modifiers: {
              "add-activity": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-info-circle mr-1"
        }), _vm._v("Show ")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-1"
        }), _vm._v("Delete ")])], 1)], 1)])])])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1)])], 1), _c('b-sidebar', {
          attrs: {
            "id": "realisasiData",
            "title": "ADD REALISASI DATA",
            "width": "90%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var hide = _ref.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3 bg-default"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("Find Data")]), _c('b-form-row', [_c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Realisasi Period",
                  "label-for": "Period"
                }
              }, [_c('date-picker', {
                attrs: {
                  "lang": _vm.lang,
                  "type": "month",
                  "placeholder": "Select Month"
                },
                model: {
                  value: _vm.realisasiPeriod,
                  callback: function callback($$v) {
                    _vm.realisasiPeriod = $$v;
                  },
                  expression: "realisasiPeriod"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label-for": "dateIssued",
                  "label": "Project Filter"
                }
              }, [_c('v-multiselect', {
                attrs: {
                  "options": _vm.projectOptions,
                  "multiple": true,
                  "group-values": "sub",
                  "group-label": "project",
                  "group-select": true,
                  "placeholder": "Select one",
                  "track-by": "name",
                  "label": "name"
                },
                model: {
                  value: _vm.filterDailyOps.subProject,
                  callback: function callback($$v) {
                    _vm.$set(_vm.filterDailyOps, "subProject", $$v);
                  },
                  expression: "filterDailyOps.subProject"
                }
              }, [_c('span', {
                attrs: {
                  "slot": "noResult"
                },
                slot: "noResult"
              }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-form-group', {
                staticClass: "mb-0",
                attrs: {
                  "label": "Search",
                  "label-for": "kind-fleet"
                }
              }, [_c('b-button', {
                attrs: {
                  "type": "submit",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-search"
              })])], 1)], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("Select Shipper Data")]), _c('b-row', {
                staticClass: "pl-3 pr-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-warning",
                attrs: {
                  "show": true,
                  "variant": " "
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "ri-information-fill"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Not Found Data of Shipper in this period")])])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('thead', [_c('tr', {
                staticClass: "bg-primary"
              }, [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("NO")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("TYPE")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("PROJECT")])])]), _c('tbody', [_c('tr', {
                staticClass: "iq-bg-primary"
              }, [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("1")]), _c('th', {
                staticClass: "text-center"
              }, [_c('b-badge', {
                staticClass: "border border-primary text-primary",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("EXISTING")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('strong', [_vm._v("ARUTMIN")])])]), _c('tr', [_c('td', {
                attrs: {
                  "colspan": "3"
                }
              }, [_c('table', {
                staticClass: "table mb-0",
                attrs: {
                  "width": "100%"
                }
              }, [_c('thead', [_c('tr', {
                staticClass: "bg-info"
              }, [_c('th', [_vm._v("#")]), _c('th', {
                attrs: {
                  "width": "17%"
                }
              }, [_vm._v("Description")]), _c('th', [_vm._v("Fleet")]), _c('th', [_vm._v("Cargo & Term")]), _c('th', [_vm._v("Location")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Shipment Plan")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Plan Quantity")]), _c('th', [_vm._v("Remarks")])])]), _c('tbody', [_c('tr', [_c('td', [_c('input', {
                attrs: {
                  "type": "checkbox",
                  "name": "shipper1",
                  "id": "shipper1"
                }
              })]), _c('td', [_vm._v("PLN Rembang")]), _c('td', [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Third Party")]), _c('br'), _vm._v(" TB.Dharma 1")], 1), _c('td', [_c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "5",
                  "label": "CARGO TYPE",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("COAL")])])]), _c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "5",
                  "label": "TERM",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("CIF")])])])], 1), _c('td', [_c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "POL",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("Paiton")])])]), _c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "POD",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("Rembang")])])])], 1), _c('td', [_c('b-row', [_c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "12"
                }
              }, [_c('strong', [_vm._v("Januari")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('strong', [_vm._v("ETA POL")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('strong', [_vm._v("ETA POD")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('p', [_vm._v("10 Januari 2023")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('p', [_vm._v("16 Januari 2023")])])], 1)], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('strong', [_vm._v("7.000 MT")])]), _c('td', [_vm._v("-")])]), _c('tr', [_c('td', [_c('input', {
                attrs: {
                  "type": "checkbox",
                  "name": "shipper2",
                  "id": "shipper2"
                }
              })]), _c('td', [_vm._v("PLN Rembang")]), _c('td', [_c('b-badge', {
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("Own Ship")]), _c('br'), _vm._v(" TB.ETI 102")], 1), _c('td', [_c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "5",
                  "label": "CARGO TYPE",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("COAL")])])]), _c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "5",
                  "label": "TERM",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("CIF")])])])], 1), _c('td', [_c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "POL",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("Paiton")])])]), _c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "POD",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("Rembang")])])])], 1), _c('td', [_c('b-row', [_c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "12"
                }
              }, [_c('strong', [_vm._v("Januari")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('strong', [_vm._v("ETA POL")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('strong', [_vm._v("ETA POD")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('p', [_vm._v("19 Januari 2023")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('p', [_vm._v("23 Januari 2023")])])], 1)], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('strong', [_vm._v("7.500 MT")])]), _c('td', [_vm._v("-")])])])])])]), _c('tr', {
                staticClass: "iq-bg-primary"
              }, [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("2")]), _c('th', {
                staticClass: "text-center"
              }, [_c('b-badge', {
                staticStyle: {
                  "font-size": "12pt"
                },
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v("New")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('strong', {
                staticStyle: {
                  "font-size": "12pt"
                }
              }, [_vm._v("Pertamina Gas")])])]), _c('tr', [_c('td', {
                attrs: {
                  "colspan": "3"
                }
              }, [_c('table', {
                staticClass: "table mb-0",
                attrs: {
                  "width": "100%"
                }
              }, [_c('thead', [_c('tr', {
                staticClass: "bg-info"
              }, [_c('th', [_vm._v("#")]), _c('th', {
                attrs: {
                  "width": "17%"
                }
              }, [_vm._v("Description")]), _c('th', [_vm._v("Fleet")]), _c('th', [_vm._v("Cargo & Term")]), _c('th', [_vm._v("Location")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Shipment Plan")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Plan Quantity")]), _c('th', [_vm._v("Remarks")])])]), _c('tbody', [_c('tr', [_c('td', [_c('input', {
                attrs: {
                  "type": "checkbox",
                  "name": "shipper3",
                  "id": "shipper3"
                }
              })]), _c('td', [_vm._v("PLN Rembang")]), _c('td', [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Third Party")]), _c('br'), _vm._v(" TB.Dharma 1")], 1), _c('td', [_c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "5",
                  "label": "CARGO TYPE",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("COAL")])])]), _c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "5",
                  "label": "TERM",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("CIF")])])])], 1), _c('td', [_c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "POL",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("Paiton")])])]), _c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "POD",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("Rembang")])])])], 1), _c('td', [_c('b-row', [_c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "12"
                }
              }, [_c('strong', [_vm._v("Januari")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('strong', [_vm._v("ETA POL")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('strong', [_vm._v("ETA POD")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('p', [_vm._v("10 Januari 2023")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('p', [_vm._v("16 Januari 2023")])])], 1)], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('strong', [_vm._v("29.000 MT")])]), _c('td', [_vm._v("-")])])])])])])])])])])], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("Select Operational Data")]), _c('b-row', {
                staticClass: "pl-3 pr-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-warning",
                attrs: {
                  "show": true,
                  "variant": " "
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "ri-information-fill"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Operational Not Yet Input Data For This Period, Please Input First")])])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table table-striped",
                staticStyle: {
                  "width": "3700px",
                  "max-height": "750px",
                  "overflow-y": "scroll"
                }
              }, [_c('thead', {
                staticClass: "thead-dark text-center"
              }, [_c('tr', [_c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "0px",
                  "background-color": "#039BAB"
                },
                attrs: {
                  "width": "50px"
                }
              }, [_vm._v("#")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "45px",
                  "background-color": "#039BAB"
                },
                attrs: {
                  "width": "250px"
                }
              }, [_vm._v("PROJECT")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "295px",
                  "background-color": "#039BAB"
                },
                attrs: {
                  "width": "150px"
                }
              }, [_vm._v("FLEET")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "425px",
                  "background-color": "#039BAB"
                },
                attrs: {
                  "width": "250px"
                }
              }, [_vm._v("STATUS")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "127px"
                }
              }, [_vm._v("MODA")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "197px"
                }
              }, [_vm._v("COMPANY")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "127px"
                }
              }, [_vm._v("CARGO TYPE")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "197px"
                }
              }, [_vm._v("POL")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "187px"
                }
              }, [_vm._v("TA POL/READY")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "147px"
                }
              }, [_vm._v("BERTHING")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "147px"
                }
              }, [_vm._v("COMMENCED")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "147px"
                }
              }, [_vm._v("COMPLETED")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "147px"
                }
              }, [_vm._v("CARGO LOADING")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "197px"
                }
              }, [_vm._v("POD")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "147px"
                }
              }, [_vm._v("TA POD/READY")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "147px"
                }
              }, [_vm._v("BERTHING")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "147px"
                }
              }, [_vm._v("COMMENCED")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "147px"
                }
              }, [_vm._v("COMPLETED")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "147px"
                }
              }, [_vm._v("CARGO UNLOADING")])])]), _c('tbody', [_c('tr', [_c('td', {
                staticStyle: {
                  "position": "sticky",
                  "left": "0px",
                  "background-color": "#039BAB",
                  "color": "#ffffff"
                }
              }, [_c('input', {
                attrs: {
                  "type": "checkbox",
                  "name": "voyage",
                  "id": "voyage"
                }
              })]), _c('td', {
                staticStyle: {
                  "position": "sticky",
                  "left": "45px",
                  "background-color": "#039BAB",
                  "color": "#ffffff"
                }
              }, [_vm._v("AI PROJECT - LONG HAULING")]), _c('td', {
                staticStyle: {
                  "position": "sticky",
                  "left": "295px",
                  "background-color": "#039BAB",
                  "color": "#ffffff"
                }
              }, [_vm._v("PT.TCP 201/PB.TCP 3301")]), _c('td', {
                staticStyle: {
                  "position": "sticky",
                  "left": "425px",
                  "background-color": "#039BAB",
                  "color": "#ffffff"
                }
              }, [_c('b-badge', {
                staticStyle: {
                  "font-size": "12pt"
                },
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v("Completed")])], 1), _c('td', [_vm._v("330 ft")]), _c('td', [_vm._v("PT.Transcoal Pacific")]), _c('td', [_vm._v("Nickel")]), _c('td', [_vm._v("MP Asam2")]), _c('td', [_vm._v("31-Dec-21 00.00")]), _c('td', [_vm._v("02-Jan-22 02.10")]), _c('td', [_vm._v("02-Jan-22 03.05")]), _c('td', [_vm._v("02-Jan-22 05.05")]), _c('td', [_vm._v("7.606 MT")]), _c('td', [_vm._v("LABUAN")]), _c('td', [_vm._v("04-Jan-22 20.00")]), _c('td', [_vm._v("04-Jan-22 20.00")]), _c('td', [_vm._v("04-Jan-22 20.00")]), _c('td', [_vm._v("04-Jan-22 20.00")]), _c('td', [_vm._v("7.506 MT")])]), _c('tr', [_c('td', {
                staticStyle: {
                  "position": "sticky",
                  "left": "0px",
                  "background-color": "#039BAB",
                  "color": "#ffffff"
                }
              }, [_c('input', {
                attrs: {
                  "type": "checkbox",
                  "name": "voyage",
                  "id": "voyage"
                }
              })]), _c('td', {
                staticStyle: {
                  "position": "sticky",
                  "left": "45px",
                  "background-color": "#039BAB",
                  "color": "#ffffff"
                }
              }, [_vm._v("AI PROJECT - LONG HAULING")]), _c('td', {
                staticStyle: {
                  "position": "sticky",
                  "left": "295px",
                  "background-color": "#039BAB",
                  "color": "#ffffff"
                }
              }, [_vm._v("PT.TCP 202/PB.TCP 3302")]), _c('td', {
                staticStyle: {
                  "position": "sticky",
                  "left": "425px",
                  "background-color": "#039BAB",
                  "color": "#ffffff"
                }
              }, [_c('b-badge', {
                staticStyle: {
                  "font-size": "12pt"
                },
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v("Completed")])], 1), _c('td', [_vm._v("330 ft")]), _c('td', [_vm._v("PT.Transcoal Pacific")]), _c('td', [_vm._v("Nickel")]), _c('td', [_vm._v("MP Asam2")]), _c('td', [_vm._v("31-Dec-21 00.00")]), _c('td', [_vm._v("02-Jan-22 02.10")]), _c('td', [_vm._v("02-Jan-22 03.05")]), _c('td', [_vm._v("02-Jan-22 05.05")]), _c('td', [_vm._v("7.606 MT")]), _c('td', [_vm._v("LABUAN")]), _c('td', [_vm._v("04-Jan-22 20.00")]), _c('td', [_vm._v("04-Jan-22 20.00")]), _c('td', [_vm._v("04-Jan-22 20.00")]), _c('td', [_vm._v("04-Jan-22 20.00")]), _c('td', [_vm._v("7.506 MT")])])])])])])], 1), _c('b-row', {
                staticClass: "mt-4"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label-for": "dateIssued",
                  "label": "Company"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.CompanyName,
                  "id": "gender"
                },
                model: {
                  value: _vm.companySelected,
                  callback: function callback($$v) {
                    _vm.companySelected = $$v;
                  },
                  expression: "companySelected"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Price",
                  "label-for": "cost"
                }
              }, [_c('b-input-group', {
                attrs: {
                  "size": "md",
                  "prepend": "Rp"
                }
              }, [_c('b-form-input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number"
                }
              })], 1)], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Price to Vendor",
                  "label-for": "cost"
                }
              }, [_c('b-input-group', {
                attrs: {
                  "size": "md",
                  "prepend": "Rp"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "number"
                }
              })], 1)], 1)], 1)], 1)], 1)], 1)], 1)]), _c('b-col', {
                staticClass: "mt-5",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                attrs: {
                  "size": "lg",
                  "variant": "success",
                  "block": ""
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Save Data ")])], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.realisasiData,
            callback: function callback($$v) {
              _vm.realisasiData = $$v;
            },
            expression: "realisasiData"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }