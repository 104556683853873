<template>
  <b-row>
    <b-col md="12">
      <iq-card>
        <template v-slot:headerTitle>
            <h5 class="card-title text-primary"><strong>PROSES PENAWARAN</strong></h5>
        </template>
        <template v-slot:headerAction>
          <b-dropdown v-b-tooltip.top="'Export Data'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
              <template v-slot:button-content>
                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                  <i class="fa fa-save m-0"></i>
                  </span>
              </template>
              <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-excel mr-2"></i>Export Excel</b-dropdown-item>
              <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
          </b-dropdown>
          <b-button v-b-tooltip.top="'Refresh Data'" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-refresh"></i></b-button>
          <b-button v-b-tooltip.top="'Add Data'" @click="addPurchaseRequest = !addPurchaseRequest;" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-plus"></i> Add PFA (Pre Fixture Analysis)</b-button>
        </template>
        <template v-slot:body>
            <b-row class="mb-4">
              <b-col md="2">
                <b-form-input type="text" id="search_name" size="sm" style="border: solid 1px gray;" placeholder="search name"></b-form-input>
              </b-col>
              <b-col md="1">
                  <b-button variant="primary" size="sm"><i class="fa fa-search"></i></b-button>
              </b-col>
              <b-col md="9">
                  
              </b-col>
            </b-row>
            <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                  <tr>
                    <th class="text-center">PFA ID</th>
                    <th class="text-center">NAME</th>
                    <th class="text-center">FLEET</th>
                    <th class="text-center">DATE</th>
                    <th class="text-center">DESCRIPTION</th>
                    <th class="text-center">QUANTITY</th>
                    <th class="text-center">FREIGHT</th>
                    <th class="text-center">REVENUE</th>
                    <th class="text-center">PROFIT</th>
                    <th class="text-center">PROFIT (%)</th>
                    <th class="text-center">STATUS</th>                          
                    <th class="text-center">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th class="text-center"><a href="#" @click="addPurchaseRequest = !addPurchaseRequest;">SP-2210001</a></th>
                    <td class="text-center">PRE FIXTURE ANALYSIS Jetty CLM Malili - OSS MOROSI</td>
                    <td class="text-center"><strong>Tug Boat :</strong>TB ETI 102 <br/> <strong>Barge : </strong> BG ETI 3008 <br/></td>
                    <td class="text-center">25 Juli 2022 - 26 Juli 2022</td>
                    <td class="text-center"><strong>POL : </strong>Jetty CLM<br/><strong>POD : </strong>OSS Morosi<strong>KIND OF CARGO : </strong>NIKEL</td>
                    <td class="text-center">
                      7.400
                    </td>
                    <td class="text-center">140.000</td>
                    <td class="text-center">1.050.000.000</td>
                    <td class="text-center">1.050.000.000</td>
                    <td class="text-center"><b-badge variant="success" style="font-size:16pt;">15%</b-badge></td>
                    <td class="text-center">
                      <div class="mb-1" style="font-size:14pt;"><b-badge variant="primary">Proposed</b-badge></div>
                      <div class="mb-1" style="font-size:14pt;"><b-badge variant="secondary">Needs Review</b-badge></div>
                      <div class="mb-1" style="font-size:14pt;"><b-badge variant="warning">Needs Approval</b-badge></div>
                      <div class="mb-1" style="font-size:14pt;"><b-badge variant="info">Draft SPAL / Contract</b-badge></div>
                      <div class="mb-1" style="font-size:14pt;"><b-badge variant="success">Realized</b-badge></div>
                    </td>
                    <td class="text-center">
                        <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                        <template v-slot:button-content>
                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                            <i class="fa fa-list-alt m-0 text-primary"></i>
                            </span>
                        </template>
                        <b-dropdown-item href="#" variant="dark"><i class="fa fa-edit mr-2"></i>Update</b-dropdown-item>
                        <b-dropdown-item href="#" variant="dark"><i class="fa fa-eye mr-2"></i>Review</b-dropdown-item>
                        <b-dropdown-item href="#" variant="dark"><i class="fa fa-check mr-2"></i>Approved</b-dropdown-item>
                        <b-dropdown-item @click="spalHistory = !spalHistory;" variant="dark"><i class="fa fa-pencil-square-o mr-2"></i>Process SPAL / Contract</b-dropdown-item>
                        <b-dropdown-item href="#" variant="dark"><i class="fa fa-trash mr-2"></i>Delete</b-dropdown-item>
                        </b-dropdown>
                    </td>
                  </tr>
                </tbody>
            </table>
            </div>
          <div class="mt-3">
              <b-pagination :value="1" :total-rows="50" align="center" />
          </div>

          <b-sidebar
            v-model="addPurchaseRequest"
            id="addPurchaseRequest"
            title="ADD PFA(PRE FIXTURE ANALYSIS)"
            width="60%"
            backdrop-variant="dark"
            bg-variant="white"
            right
            backdrop
            shadow>
            <template #default="{hide}">
              <b-row class="m-3">
                <b-col md="12">
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 h5 text-primary">General Information</legend>
                    <b-row class="pl-3 pr-3">
                      <b-col md="6">
                        <b-form>
                          <b-form-group class="row" label-cols-md="4" label="Shipment Planning" label-for="inventoryID">
                            <!-- <b-alert :show="true" variant="primary"> -->
                              <template v-for="(item,index) in shipmentPlanning">
                                <b-form-radio inline v-model="shipmentPlanningActive[item[Object.keys(item)[0]]]" :name="item.name" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                              </template>
                            <!-- </b-alert> -->
                          </b-form-group>
                          <b-form-group class="row" label-cols-md="4" label="PFA ID" label-for="inventoryID">
                            <b-form-input id="orderNumber" style="height:40px" placeholder="<< CREATE PFA ID >>"></b-form-input>
                          </b-form-group>
                          <b-form-group class="row" label-cols-md="4" label="Name Pfa" label-for="descriptionItem">
                            <b-form-input type="number" id="descriptionItem" style="height:40px"></b-form-input>
                          </b-form-group>
                          <b-form-group class="row" label-cols-md="4" label="Voyage" label-for="uom">
                            <b-form-select plain>
                              <b-form-select-option>1</b-form-select-option>
                              <b-form-select-option>2</b-form-select-option>
                              <b-form-select-option>3</b-form-select-option>
                              <b-form-select-option>4</b-form-select-option>
                            </b-form-select>
                          </b-form-group>
                          <b-form-group class="row" label-cols-md="4" label="Port of Loading" label-for="descriptionItem">
                            <b-form-input type="text" id="descriptionItem" style="height:40px"></b-form-input>
                          </b-form-group>
                          <b-form-group class="row" label-cols-md="4" label="Port of Dischare" label-for="descriptionItem">
                            <b-form-input type="text" id="descriptionItem" style="height:40px"></b-form-input>
                          </b-form-group>
                          <b-form-group class="row" label-cols-md="4" label="Revenue" label-for="location">
                            <h3><b-badge variant="success">IDR 1.050.000.000</b-badge></h3>
                          </b-form-group>
                        </b-form>
                      </b-col>

                      <b-col md="6">
                        <b-form>
                          <b-form-group class="row" label-cols-md="4" label="From Planning" label-for="inventoryID">
                            <v-multiselect v-model="projectValue" :options="projectOptions" group-values="sub" group-label="project" placeholder="Select one" track-by="name" label="name"></v-multiselect>
                          </b-form-group>
                          <b-form-group class="row" label-cols-md="4" label="Tugboat" label-for="Department">
                            <b-form-select plain v-model="selectDepartment" :options="optionDepartment" size="lg">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Select Tugboat</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                          <b-form-group class="row" label-cols-md="4" label="Barge" label-for="Department">
                            <b-form-select plain v-model="selectDepartment" :options="optionDepartment" size="lg">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Select Barge</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                          <b-form-group class="row" label-cols-md="4" label="Kind of Cargo" label-for="picName">
                            <b-form-select plain>
                              <b-form-select-option>Coal</b-form-select-option>
                              <b-form-select-option>Nickel</b-form-select-option>
                              <b-form-select-option>CPO</b-form-select-option>
                              <b-form-select-option>HSD</b-form-select-option>
                              <b-form-select-option>Split Stone</b-form-select-option>
                              <b-form-select-option>Others</b-form-select-option>
                            </b-form-select>
                          </b-form-group>
                          <b-form-group class="row" label-cols-md="4" label="Quantity" label-for="location">
                            <b-row>
                              <b-col md="5">
                                <b-form-select plain>
                                  <b-form-select-option>&#x33A5;</b-form-select-option>
                                  <b-form-select-option>Tonnage</b-form-select-option>
                                  <b-form-select-option>Lump Sum</b-form-select-option>
                                  <b-form-select-option>Liter</b-form-select-option>
                                </b-form-select>
                              </b-col>
                              <b-col md="7">
                                <b-form-input type="number" class="text-right" id="qty" style="height:40px"></b-form-input>
                              </b-col>
                            </b-row>
                          </b-form-group>
                          <b-form-group class="row" label-cols-md="4" label="Freight" label-for="location">
                            <b-form-input type="number" class="text-right" id="qty" style="height:40px"></b-form-input>
                          </b-form-group>
                        </b-form>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 h5 text-primary">Distace, Speed, Est Time(Hour)</legend>
                    <b-row>
                      <b-col md="12">
                        <div class="table-responsive">
                          <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th class="text-center">Laden</th>
                                  <th class="text-center">Ballast</th>
                                  <th class="text-center">Est Dist (NM)</th>
                                  <th class="text-center">Speed</th>
                                  <th class="text-center">Est Time(Hour)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <b-form-input type="text" class="text-left" id="qty" style="height:40px" value="Jetty CLM"></b-form-input>
                                  </td>
                                  <td>
                                    <b-form-input type="text" class="text-left" id="qty" style="height:40px" value="OSS Morosi"></b-form-input>
                                  </td>
                                  <td>
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px" value="310"></b-form-input>
                                  </td>
                                  <td>
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px" value="3.5"></b-form-input>
                                  </td>
                                  <td class="text-center">
                                    <h5><b-badge variant="info">88.57</b-badge></h5>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b-form-input type="text" class="text-left" id="qty" style="height:40px" value="OSS Morosi"></b-form-input>
                                  </td>
                                  <td>
                                    <b-form-input type="text" class="text-left" id="qty" style="height:40px" value="Jetty CLM"></b-form-input>
                                  </td>
                                  <td>
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px" value="310"></b-form-input>
                                  </td>
                                  <td>
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px" value="5.5"></b-form-input>
                                  </td>
                                  <td class="text-center">
                                    <h5><b-badge variant="info">56.36</b-badge></h5>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="5"><b-button variant="info" block><i class="fa fa-plus"></i> Add Data</b-button></td>
                                </tr>
                              </tbody>
                          </table>
                        </div>
                      </b-col>
                      <!-- <b-col md="6">
                        <b-form>
                          <b-form-group class="row" label-cols-md="4" label="Item Class" label-for="itemClass">
                            <b-form-select plain v-model="selectItemClass" :options="optionItemClass" size="lg">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Select Item Class</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                          <b-form-group class="row" label-cols-md="4" label="Item Type" label-for="itemType">
                            <b-form-select plain v-model="selectItemType" :options="optionItemType" size="lg">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Select Item Type</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                          <b-form-group class="row" label-cols-md="4" label="Valuation Method" label-for="ValuationMethod">
                            <b-form-select plain v-model="selectValuationMethod" :options="optionValuationMethod" size="lg">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Select Valuation Method</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                        </b-form>
                      </b-col>
                      <b-col md="6">
                        <b-form>
                          <b-form-group class="row" label-cols-md="4" label="Tax Category" label-for="taxtCategory">
                            <b-form-select plain v-model="selecttaxtCategory" :options="optiontaxtCategory" size="lg">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Select Tax Category</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                          <b-form-group class="row" label-cols-md="4" label="Is a Kit" label-for="isKit">
                            <template v-for="(item,index) in text">
                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color float-left" :key="index">
                                <div class="custom-switch-inner">
                                  <p class="mb-0"> {{ item.label }} </p>
                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                  </label>
                                </div>
                              </div>
                            </template>
                          </b-form-group>
                          <b-form-group class="row" label-cols-md="4" label="Warehouse" label-for="WareHouse">
                            <b-form-select plain v-model="selectWareHouse" :options="optionWareHouse" size="lg">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Select Ware House</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                        </b-form>
                      </b-col> -->
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 h5 text-primary">Cost Factor</legend>
                    <b-row>
                      <b-col md="12">
                        <div class="table-responsive">
                          <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th class="text-center">Item</th>
                                  <th class="text-center">Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Charter Hire / Day</td>
                                  <td>
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Bunker Cost / litre</td>
                                  <td>
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <td>F Water Cost / MT</td>
                                  <td>
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                  </td>
                                </tr>
                              </tbody>
                          </table>
                        </div>
                    </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 h5 text-primary">Time Voyage</legend>
                    <b-row>
                      <b-col md="12" class="mb-4">
                        <div class="table-responsive">
                          <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th class="text-center">Distances  steamed (Ballast / Laden)</th>
                                  <th class="text-center">Steaming Time / hours</th>
                                  <th class="text-center">Cons</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Present position to load port</td>
                                  <td>
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>Manouver Loading / Discharging</td>
                                  <td>
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>Waiting Time</td>
                                  <td>
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>Laden</td>
                                  <td class="text-center">
                                    88,57
                                  </td>
                                  <td class="text-center">11.071,43</td>
                                </tr>
                                <tr>
                                  <td>Ballast/MT</td>
                                  <td class="text-center">
                                    56,36	
                                  </td>
                                  <td class="text-center">7.045,45</td>
                                </tr>
                                <tr>
                                  <td>Manouver Loading / Discharging</td>
                                  <td class="text-center">
                                  </td>
                                  <td class="text-center">1.000,00</td>
                                </tr>
                                <tr>
                                  <td>A/E OB</td>
                                  <td class="text-center">
                                  </td>
                                  <td class="text-center">1.000,00</td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td class="bg-warning">Total duration(hour/Day) & Consumption</td>
                                  <td class="bg-info">144,94</td>
                                  <td class="bg-info">19.116,88 </td>
                                </tr>
                              </tfoot>
                          </table>
                        </div>
                      </b-col>
                      <b-col md="12" class="mb-4">
                        <div class="table-responsive">
                          <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th class="text-center">Port Stay (load/discharge)</th>
                                  <th class="text-center">Steaming Time / day</th>
                                  <th class="text-center">Idling/waiting</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Port stay at LP (est)	</td>
                                  <td class="text-center">
                                    4
                                  </td>
                                  <td class="text-center">500,00</td>
                                </tr>
                                <tr>
                                  <td>Port Stay at DP (est)</td>
                                  <td class="text-center">
                                    4
                                  </td>
                                  <td class="text-center">500,00</td>
                                </tr>
                                <tr>
                                  <td>A/E Barge</td>
                                  <td class="text-center">
                                    0
                                  </td>
                                  <td class="text-center">0,00</td>
                                </tr>
                                <tr>
                                  <td>Pump Discharge</td>
                                  <td class="text-center">
                                    0
                                  </td>
                                  <td class="text-center">0,00</td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td class="bg-warning">Total duration & Total Bunker Consumption</td>
                                  <td class="bg-info">8,00</td>
                                  <td class="bg-info">1.000,00 </td>
                                </tr>
                                <tr>
                                  <td class="bg-warning">Grand total duration (day) & consumption	</td>
                                  <td class="bg-info">14,04</td>
                                  <td class="bg-info">20.116,88</td>
                                </tr>
                              </tfoot>
                          </table>
                        </div>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 h5 text-primary">Miscellaneous Charge</legend>
                    <b-row>
                      <b-col md="12">
                        <div class="table-responsive">
                          <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th class="text-center">Item</th>
                                  <th class="text-center">Cost</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Liner Term</td>
                                  <td class="text-center">
                                    -
                                  </td>
                                </tr>
                                <tr>
                                  <td>Freigt Tax (1,2 %)</td>
                                  <td class="text-center">
                                    Rp. 12.600.000,-
                                  </td>
                                </tr>
                                <tr>
                                  <td>C/E/V</td>
                                  <td class="text-center">
                                    -
                                  </td>
                                </tr>
                                <tr>
                                  <td>Insurance</td>
                                  <td class="text-center">
                                    -
                                  </td>
                                </tr>
                                <tr>
                                  <td>Premi Kapal</td>
                                  <td class="text-center">
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Channel Fee</td>
                                  <td class="text-center">
                                    -
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td><strong>TOTAL</strong></td>
                                  <td class="text-center bg-success">Rp28.600.000,00 </td>
                                </tr>
                              </tfoot>
                          </table>
                        </div>
                    </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 h5 text-primary">Operational Expenses</legend>
                    <b-row>
                      <b-col md="12">
                        <div class="table-responsive">
                          <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th class="text-center">Operation</th>
                                  <th class="text-center">Expense</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Total charter hire costs</td>
                                  <td class="text-center">
                                    Rp292.478.355
                                  </td>
                                </tr>
                                <tr>
                                  <td>Bunker costs</td>
                                  <td class="text-center">
                                    Rp412.396.104
                                  </td>
                                </tr>
                                <tr>
                                  <td>Fresh water cost </td>
                                  <td class="text-center">
                                    Rp412.396.104
                                  </td>
                                </tr>
                                <tr>
                                  <td>Agency Charge / costs (load port)	</td>
                                  <td class="text-center">
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Agency Charge / costs (discharge port)</td>
                                  <td class="text-center">
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Surveyor Loading & Discharging Port	</td>
                                  <td class="text-center">
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Jasa Ramdoor</td>
                                  <td class="text-center">
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Assist & Pandu / Buka Tutup Terpal</td>
                                  <td class="text-center">
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Cleaning Barge</td>
                                  <td class="text-center">
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <td>PNI Surveyor</td>
                                  <td class="text-center">
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Marketing Fee</td>
                                  <td class="text-center">
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Terpal</td>
                                  <td class="text-center">
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Others</td>
                                  <td class="text-center">
                                    <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td><strong>Total Operational Expenses</strong></td>
                                  <td class="text-center bg-primary"> Rp768.182.251 </td>
                                </tr>
                                <tr>
                                  <td><strong>Revenue	</strong></td>
                                  <td class="text-center bg-primary">  Rp1.050.000.000  </td>
                                </tr>
                                <tr>
                                  <td><strong>Grand Total Expenses	</strong></td>
                                  <td class="text-center bg-primary">   Rp796.782.251   </td>
                                </tr>
                                <tr>
                                  <td><strong>Profit	</strong></td>
                                  <td class="text-center bg-success">    Rp253.217.749   </td>
                                </tr>
                                <tr>
                                  <td><strong>Profit	%</strong></td>
                                  <td class="text-center bg-success">    31,78% <small>from Equity ( Grand Total Expenses)</small>   </td>
                                </tr>
                                <tr>
                                  <td><strong>Profit Per MT</strong></td>
                                  <td class="text-center bg-success">    33.762,367 </td>
                                </tr>
                              </tfoot>
                          </table>
                        </div>
                    </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
                <b-col md="12" class="mt-5 mb-4">
                  <b-button variant="primary" block>Submit</b-button>
                </b-col>
              </b-row>
            </template>
          </b-sidebar>
          <!-- SPAL HISTORY -->
          <b-sidebar
            v-model="spalHistory"
            id="spalHistory"
            title="SPAL / Contract Process"
            width="60%"
            backdrop-variant="dark"
            bg-variant="white"
            right
            backdrop
            shadow>
            <template #default="{hide}">
              <b-row class="m-3">
                <b-col md="12">
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 h5 text-primary">General Information <span><b-button size="sm" variant="primary" v-b-toggle.generalInformation>
                        <i class="fa fa-eye pr-0"></i> Detail
                      </b-button></span>
                    </legend>
                    <b-collapse id="generalInformation">
                      <b-row class="pl-3 pr-3">
                        <b-col md="6">
                          <b-form>
                            <b-form-group class="row" label-cols-md="4" label="Shipment Planning" label-for="inventoryID">
                              <!-- <b-alert :show="true" variant="primary"> -->
                                <template v-for="(item,index) in shipmentPlanning">
                                  <b-form-radio inline v-model="shipmentPlanningActive[item[Object.keys(item)[0]]]" :name="item.name" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                                </template>
                              <!-- </b-alert> -->
                            </b-form-group>
                            <b-form-group class="row" label-cols-md="4" label="PFA ID" label-for="inventoryID">
                              <b-form-input id="orderNumber" style="height:40px" placeholder="<< CREATE PFA ID >>"></b-form-input>
                            </b-form-group>
                            <b-form-group class="row" label-cols-md="4" label="Name Pfa" label-for="descriptionItem">
                              <b-form-input type="number" id="descriptionItem" style="height:40px"></b-form-input>
                            </b-form-group>
                            <b-form-group class="row" label-cols-md="4" label="Voyage" label-for="uom">
                              <b-form-select plain>
                                <b-form-select-option>1</b-form-select-option>
                                <b-form-select-option>2</b-form-select-option>
                                <b-form-select-option>3</b-form-select-option>
                                <b-form-select-option>4</b-form-select-option>
                              </b-form-select>
                            </b-form-group>
                            <b-form-group class="row" label-cols-md="4" label="Port of Loading" label-for="descriptionItem">
                              <b-form-input type="text" id="descriptionItem" style="height:40px"></b-form-input>
                            </b-form-group>
                            <b-form-group class="row" label-cols-md="4" label="Port of Dischare" label-for="descriptionItem">
                              <b-form-input type="text" id="descriptionItem" style="height:40px"></b-form-input>
                            </b-form-group>
                            <b-form-group class="row" label-cols-md="4" label="Revenue" label-for="location">
                              <h3><b-badge variant="success">IDR 1.050.000.000</b-badge></h3>
                            </b-form-group>
                          </b-form>
                        </b-col>

                        <b-col md="6">
                          <b-form>
                            <b-form-group class="row" label-cols-md="4" label="From Planning" label-for="inventoryID">
                              <v-multiselect v-model="projectValue" :options="projectOptions" group-values="sub" group-label="project" placeholder="Select one" track-by="name" label="name"></v-multiselect>
                            </b-form-group>
                            <b-form-group class="row" label-cols-md="4" label="Tugboat" label-for="Department">
                              <b-form-select plain v-model="selectDepartment" :options="optionDepartment" size="lg">
                                <template v-slot:first>
                                <b-form-select-option :value="null">Select Tugboat</b-form-select-option>
                                </template>
                              </b-form-select>
                            </b-form-group>
                            <b-form-group class="row" label-cols-md="4" label="Barge" label-for="Department">
                              <b-form-select plain v-model="selectDepartment" :options="optionDepartment" size="lg">
                                <template v-slot:first>
                                <b-form-select-option :value="null">Select Barge</b-form-select-option>
                                </template>
                              </b-form-select>
                            </b-form-group>
                            <b-form-group class="row" label-cols-md="4" label="Kind of Cargo" label-for="picName">
                              <b-form-select plain>
                                <b-form-select-option>Coal</b-form-select-option>
                                <b-form-select-option>Nickel</b-form-select-option>
                                <b-form-select-option>CPO</b-form-select-option>
                                <b-form-select-option>ASD</b-form-select-option>
                                <b-form-select-option>Split Stone</b-form-select-option>
                                <b-form-select-option>Others</b-form-select-option>
                              </b-form-select>
                            </b-form-group>
                            <b-form-group class="row" label-cols-md="4" label="Quantity" label-for="location">
                              <b-row>
                                <b-col md="5">
                                  <b-form-select plain>
                                    <b-form-select-option>M3</b-form-select-option>
                                    <b-form-select-option>Tonnage</b-form-select-option>
                                  </b-form-select>
                                </b-col>
                                <b-col md="7">
                                  <b-form-input type="number" class="text-right" id="qty" style="height:40px"></b-form-input>
                                </b-col>
                              </b-row>
                            </b-form-group>
                            <b-form-group class="row" label-cols-md="4" label="Freight" label-for="location">
                              <b-form-input type="number" class="text-right" id="qty" style="height:40px"></b-form-input>
                            </b-form-group>
                          </b-form>
                        </b-col>

                        <b-col md="12">
                          <table class="table table-striped">
                            <tfoot>
                                  <tr>
                                    <td><strong>Total Operational Expenses</strong></td>
                                    <td class="text-center bg-primary"> Rp768.182.251 </td>
                                  </tr>
                                  <tr>
                                    <td><strong>Grand Total Expenses	</strong></td>
                                    <td class="text-center bg-primary">   Rp796.782.251   </td>
                                  </tr>
                                  <tr>
                                    <td><strong>Profit	</strong></td>
                                    <td class="text-center bg-success">    Rp253.217.749   </td>
                                  </tr>
                                  <tr>
                                    <td><strong>Profit	%</strong></td>
                                    <td class="text-center bg-success">    31,78% <small>from Equity ( Grand Total Expenses)</small>   </td>
                                  </tr>
                                </tfoot>
                          </table>
                        </b-col>
                      </b-row>
                    </b-collapse>
                  </fieldset>
                  <fieldset class="form-group border p-3 bg-default">
                    <legend class="w-auto px-2 h5 text-primary">SPAL / Contract Input</legend>
                    <b-form-row>
                      <b-col>
                        <b-form-group label="Title" label-for="date">
                          <b-form-input type="text" id="start"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group label="File Upload" label-for="berita_acara">
                          <div class="custom-file">
                            <input type="file" class="custom-file-input" id="validatedCustomFile">
                            <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                          </div>
                          <small>If you want to upload multiple file, press "ctrl / command" in your keyboard</small>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col>
                        <b-form-group label="Notes" label-for="notes">
                          <b-form-textarea id="description" rows="3" placeholder="Type Text ..."></b-form-textarea>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col>
                        <b-form-group label="Is Fix SPAL / CONTRACT ?" label-for="internal_man_power">
                          <template v-for="(item,index) in fix">
                            <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                              <div class="custom-switch-inner">
                                <p class="mb-0"> {{ item.label }} </p>
                                <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel"></label>
                              </div>
                            </div>
                          </template>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-button type="submit" variant="success" class="pull-right"><i class="fa fa-save"></i> Submit Data</b-button>
                      </b-col>
                    </b-form-row>
                  </fieldset>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 h5 text-primary">History</legend>
                    <b-row class="pl-3 pr-3">
                      <b-col md="12">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th width="5%">No</th>
                              <th width="20%">Title</th>
                              <th width="20%">File</th>
                              <th>Notes</th>
                              <th width="15%">Comment</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1.</td>
                              <td>PFA</td>
                              <td>
                                <a href="javascript:void(0);">
                                  <img src="../../../assets/images/activity/pdf.png" alt="gallary-image" height="50px">
                                </a>
                              </td>
                              <td>Generated By System</td>
                              <td>
                                <b-button size="sm" variant="primary" v-b-toggle.comment>
                                  <i class="fa fa-comment pr-0"></i> Comment
                                </b-button>
                              </td>
                            </tr>
                            <!-- Comment Box-->
                            <tr>
                              <td colspan="5">
                                <b-collapse id="comment">
                                <table class="table mb-0" width="100%">
                                  <thead>
                                    <tr>
                                      <th>Comment</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <h6 class="float-left mb-1"><strong>Tri Leksono</strong></h6>
                                        <br />
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Revisi dibagian Muatan dong</p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="chat-footer p-3 bg-white">
                                          <form class="d-flex align-items-center"  action="javascript:void(0);">
                                            <div class="chat-attagement d-flex">
                                              <a href="javascript:void(0)"><i class="fa fa-smile-o pr-3" aria-hidden="true"></i></a>
                                              <a href="javascript:void(0)"><i class="fa fa-paperclip pr-3" aria-hidden="true"></i></a>
                                            </div>
                                            <input type="text" class="form-control mr-3" placeholder="Type your message">
                                            <button type="submit" class="btn btn-primary d-flex align-items-center p-2"><i class="fas fa-paper-plane" aria-hidden="true"></i><span class="d-none d-lg-block ml-1">Send</span></button>
                                          </form>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                </b-collapse>
                              </td>
                            </tr>

                            <tr>
                              <td>2.</td>
                              <td>Shipping Instruction</td>
                              <td>
                                <a href="javascript:void(0);">
                                  <img src="../../../assets/images/activity/pdf.png" alt="gallary-image" height="50px">
                                </a>
                              </td>
                              <td>-</td>
                              <td>
                                <b-button size="sm" variant="primary" v-b-toggle.comment2>
                                  <i class="fa fa-comment pr-0"></i> Comment
                                </b-button>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="5">
                                <b-collapse id="comment2">
                                <table class="table mb-0" width="100%">
                                  <thead>
                                    <tr>
                                      <th>Comment</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <h6 class="float-left mb-1"><strong>Tri Leksono</strong></h6>
                                        <br />
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Revisi dibagian Muatan dong</p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="chat-footer p-3 bg-white">
                                          <form class="d-flex align-items-center"  action="javascript:void(0);">
                                            <div class="chat-attagement d-flex">
                                              <a href="javascript:void(0)"><i class="fa fa-smile-o pr-3" aria-hidden="true"></i></a>
                                              <a href="javascript:void(0)"><i class="fa fa-paperclip pr-3" aria-hidden="true"></i></a>
                                            </div>
                                            <input type="text" class="form-control mr-3" placeholder="Type your message">
                                            <button type="submit" class="btn btn-primary d-flex align-items-center p-2"><i class="fas fa-paper-plane" aria-hidden="true"></i><span class="d-none d-lg-block ml-1">Send</span></button>
                                          </form>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                </b-collapse>
                              </td>
                            </tr>

                            <tr>
                              <td>3.</td>
                              <td>Draft SPAL / Contract</td>
                              <td>
                                <a href="javascript:void(0);">
                                  <img src="../../../assets/images/activity/pdf.png" alt="gallary-image" height="50px">
                                </a>
                              </td>
                              <td>-</td>
                              <td>
                                <b-button size="sm" variant="primary" v-b-toggle.comment3>
                                  <i class="fa fa-comment pr-0"></i> Comment
                                </b-button>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="5">
                                <b-collapse id="comment3">
                                <table class="table mb-0" width="100%">
                                  <thead>
                                    <tr>
                                      <th>Comment</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <h6 class="float-left mb-1"><strong>Tri Leksono</strong></h6>
                                        <br />
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Revisi dibagian Muatan dong</p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="chat-footer p-3 bg-white">
                                          <form class="d-flex align-items-center"  action="javascript:void(0);">
                                            <div class="chat-attagement d-flex">
                                              <a href="javascript:void(0)"><i class="fa fa-smile-o pr-3" aria-hidden="true"></i></a>
                                              <a href="javascript:void(0)"><i class="fa fa-paperclip pr-3" aria-hidden="true"></i></a>
                                            </div>
                                            <input type="text" class="form-control mr-3" placeholder="Type your message">
                                            <button type="submit" class="btn btn-primary d-flex align-items-center p-2"><i class="fas fa-paper-plane" aria-hidden="true"></i><span class="d-none d-lg-block ml-1">Send</span></button>
                                          </form>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                </b-collapse>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
              </b-row>
            </template>
          </b-sidebar>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { projectsActions, companiesActions, fleetsActions } from '@src/Utils/helper'

export default {
  name: 'TabProsesPenawaran',
  async mounted () {
    await this.getProjectList()
    await this.fetchFleets()
    await this.getCompanyListData()
  },
  data () {
    return {
      vehicle: '',
      projectValue: [],
      filterDailyOps: {
        date: moment().format('YYYY-MM-DD'),
        ownership: 'OWNED',
        subProject: [],
        vehicleTypeIds: [1, 2]
      },
      CompanyName: [{ value: null, text: 'Select Company' }],
      companySelected: null,
      optionsVehicles: [],
      crewList: {
        isFiltering: false,
        params: {
          fullname: '',
          vehicle_id: null,
          from: null,
          to: null,
          position_id: null,
          status: null,
        },
        data: {
          head: [],
          body: [],
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        },
      },
      realisasiPeriod: '',
      projectValue: '',
      projectOptions: [
      ],

      pageDetailSalesOrder: false,
      addPurchaseRequest: false,
      spalHistory: false,
      realisasiData: false,

      // fleet
      slideDetailRakor: false,
      slideAddRakor: false,
      slideDetailPlan: false,
      
      // COMPANY
      companyActive: {
        single: 'active',
        disable: 'active',
        number: 'two',
        colorDisabled: 'five',
        color: 'danger'
      },
      company: [
        {
          name: 'single',
          label: 'EXISTING PROJECT',
          value: 'active',
          disabled: false
        },
        {
          name: 'single',
          label: 'NEW PROJECT',
          value: 'inactive',
          disabled: false
        }
      ],
      shipmentPlanningActive: {
        single: 'yes',
        disable: 'yes',
        number: 'two',
        colorDisabled: 'five',
        color: 'danger'
      },
      shipmentPlanning: [
        {
          name: 'single',
          label: 'Yes',
          value: 'yes',
          disabled: false
        },
        {
          name: 'single',
          label: 'No',
          value: 'no',
          disabled: false
        }
      ],

      // NEGARA
      stateActive: {
        single: 'active',
        disable: 'active',
        number: 'two',
        colorDisabled: 'five',
        color: 'danger'
      },
      state: [
        {
          name: 'single',
          label: 'INDONESIA',
          value: 'active',
          disabled: false
        },
        {
          name: 'single',
          label: 'FOREIGN',
          value: 'inactive',
          disabled: false
        }
      ],

      // OWNERSHIP
      ownershipActive: {
        single: 'active',
        disable: 'active',
        number: 'two',
        colorDisabled: 'five',
        color: 'danger'
      },
      ownership: [
        {
          name: 'ownship',
          label: 'OWN SHIP',
          value: 'active',
          disabled: false
        },
        {
          name: 'chartered',
          label: 'CHARTERED',
          value: 'inactive',
          disabled: false
        }
      ],

      // SELECT PROVINSI
      selectedPlanning: 1,
      optionPlanning: [
        { value: '1', text: 'Arutmin == PLN Rembang - Longhauling' },
        { value: '2', text: 'KPC == PLN Rembang' }
      ],
      
      // SELECT PROVINSI
      selectedProvinsi: 1,
      optionProvinsi: [
        { value: '1', text: 'DKI JAKARTA' },
        { value: '2', text: 'JAWA BARAT' },
        { value: '3', text: 'JAWA TENGAH' },
        { value: '4', text: 'JAWA TIMUR' }
      ],

      // SELECT PROVINSI
      selectedKota: 4,
      optionKota: [
        { value: '1', text: 'JAKARTA PUSAT' },
        { value: '2', text: 'JAKARTA UTARA' },
        { value: '3', text: 'JAKARTA TIMUR' },
        { value: '4', text: 'JAKARTA SELATAN' },
        { value: '5', text: 'JAKARTA BARAT' },
        { value: '6', text: 'KEPULAUAN SERIBU' },
      ],

      // SELECT KECAMATAN
      selectedKecamatan: 1,
      optionKecamatan: [
        { value: '1', text: 'KEBAYORAN LAMA' },
        { value: '2', text: 'KEBAYORAN BARU' },
        { value: '3', text: 'GROGOL' },
        { value: '4', text: 'PASAR MINGGU' }
      ],

      // SELECT KELURAHAN
      selectedKelurahan: 1,
      optionKelurahan: [
        { value: '1', text: 'PONDOK PINANG' },
        { value: '2', text: 'PESANGGRAHAN' },
        { value: '3', text: 'PETUKANGAN' }
      ],

      // SELECT TAHUN
      selectedTahun: null,
      optionTahun: [
        { value: '1', text: '2023' },
        { value: '2', text: '2022' },
        { value: '3', text: '2021' },
        { value: '3', text: '2020' }
      ],
      fix: [{
          activeLabel: 'Yes',
          inactiveLabel: 'No',
          class: 'primary',
          checked: false
        }],
    }
  },
  methods: {
    ...projectsActions,
    ...fleetsActions,
    ...companiesActions,
    async getCompanyListData() {
      const res = await this.getCompanies()

      if (res.status == 'success' && res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          this.CompanyName.push({
            // text: `${element.company} - ${element.location}`,
            text: `${element.company}`,
            value: element.id,
          })
        })
      } else {
        this.$swal(`Oops!`, res.data, 'error')
      }
    },
    async getProjectList () {
      let params = {
        active: true,
        search: '',
        page: 1,
        perPage: 250
      }
      const res = await this.getProjects(params)
      if (res.status == 'success') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(val => {
            this.projectOptions.push({
              project: val.projectName,
              sub: val.subProject
            })
          })
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data.data,
          'error'
        )
      }
    },
    async fetchFleets() {
      this.fleetsLoading = true
      this.fleets = []

      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
        search: '',
      })
      let res = await this.getFleets(params)

      if (res.status) {
        this.fleetsLoading = false
        if (res.data && res.data.length > 0) {
          this.fleetOptions = res.data.map((val) => ({
            text: val.name,
            value: val.id,
          }))
        }
      }
    },
  }
}
</script>
<style>
/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/
.table-fixed tbody {
    height: 300px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tbody td,
.table-fixed tbody th,
.table-fixed thead > tr > th {
    float: left;
    position: relative;

    &::after {
        content: '';
        clear: both;
        display: block;
    }
}
</style>