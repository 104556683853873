<template>
    <b-row>
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"
                ><strong>MASTER CAUSE</strong></h4
              >
            </template>
            <template v-slot:body>
                <b-row>
                  <b-col cols="12" md="3">
                    <b-form-group
                      label="Search Master Reject Reason:"
                      label-for="name"
                    >
                      <b-form-input
                        id="name"
                        class="w-100"
                        type="text"
                        placeholder="Search..."
                        v-model="params.name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
        <b-col :sm="!menuPermission('update') && !menuPermission('add') ? '12' : '8'">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">List Master Reject Reason</h4>
              </template>
              <template v-slot:body>
                <div v-if="loading" class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th width="10%">No</th>
                          <th width="25%">Name</th>
                          <th width="25%">Alias</th>
                          <th width="10%">Active</th>
                          <th width="15%">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(p, i) in filteredTableData" :key="`p-${i}`">
                          <td class="text-center">{{ startRowIndexData + i }}</td>
                          <td>{{ p.name }}</td>
                          <td>{{ p.alias ? p.alias : '-' }}</td>
                          <td>{{ p.is_active === true ? 'Active' : 'Inactive' }}</td>
                          <td>
                            <b-button
                              v-if="menuPermission('update')"
                              variant="success"
                              size="sm"
                              class="mr-2"
                              @click="onEditRejectReason(p)"
                              ><i class="fa fa-edit"></i
                            ></b-button>
                            <b-button
                              v-if="menuPermission('delete')"
                              variant="danger"
                              size="sm"
                              @click="onDeleteRejectReason(p)"
                            >
                              <i
                                v-if="!loading_delete"
                                class="fa fa-trash"
                              ></i>
                              
                              <span v-else
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="displayedData.length > 0">
                    <b-pagination
                      v-model="data.currentPage"
                      :total-rows="totalData"
                      :per-page="data.perPage"
                      aria-controls="my-table"
                      align="center"
                      first-number
                    />
                  </div>
                  <p v-else class="text-center my-4">No Reject data found.</p>
                </template>
              </template>
            </iq-card>
        </b-col>
        <b-col sm="4"
          v-if="menuPermission('update') || menuPermission('add')">
          <iq-card
            :class="{
              'border border-danger shadow-lg': edit_mode,
            }"
          >
            <template v-slot:headerTitle>
              <h4 class="card-title"
                >{{ edit_mode ? 'Edit' : 'Add' }} Reject Reason</h4
              >
            </template>
            <template v-slot:body>
              <b-form @submit.prevent="submitFormRejectReason">
                <b-form-group
                  label="Name Reject Reason"
                  label-for="name_reject_reason"
                >
                  <b-form-input
                    id="name_reject_reason"
                    type="text"
                    placeholder="Type text..."
                    maxlength="30"
                    v-model="form.name"
                  ></b-form-input>
                </b-form-group>
  
                <b-form-group
                  label="Alias Reject Reason"
                  label-for="alias_reject_reason"
                >
                  <b-form-input
                    id="alias_reject_reason"
                    type="text"
                    placeholder="Type text..."
                    maxlength="30"
                    v-model="form.alias"
                  ></b-form-input>
                </b-form-group>
  
                <b-form-group label="Is Active" label-for="is_active_reject_reason">
                  <b-form-checkbox
                    id="is_active_reject_reason"
                    v-model="form.is_active"
                    name="is_active"
                    switch
                    inline
                  >
                    {{ form.is_active ? 'Active' : 'Inactive' }}
                  </b-form-checkbox>
                </b-form-group>
                <hr />
                <b-form-group class="text-right">
                  <b-button type="submit" variant="primary">
                    <span v-if="!loading_submit">{{
                      edit_mode || !menuPermission('add') ? 'Save' : 'Add'
                    }}</span>
                    <template v-else>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Saving...
                    </template>
                  </b-button>
                  <b-button
                    type="button"
                    variant="danger"
                    class="iq-bg-danger ml-3"
                    @click.prevent="resetFormRejectReason"
                    >Cancel</b-button
                  >
                </b-form-group>
              </b-form>
            </template>
          </iq-card>
        </b-col>
    </b-row>
</template>

<script>
import { srActions } from '@src/Utils/helper'
import _ from 'lodash'

export default {
  name: 'SrMasterRejectReason',
  data () {
    return {
        loading:false,
        loading_delete:false,
        loading_submit:false,

        edit_mode:false,

        params:{
            name:''
        },

        data: {
            currentPage: 1,
            perPage: 10,
            data:[]
        },

        selected_id:null,
        form: {
            name:'',
            alias:'',
            is_active:true,
        }
    }
  },
  computed: {
    totalData() {
      return this.data.data.length;
    },
    displayedData() {
      const start = (this.data.currentPage - 1) * this.data.perPage;
      const end = start + this.data.perPage;
      return this.data.data.slice(start, end);
    },
    startRowIndexData() {
      return (this.data.currentPage - 1) * this.data.perPage + 1;
    },
    filteredTableData() {
    if (this.params.name) {
      const searchRegex = new RegExp(this.params.name, 'i'); // case-insensitive search
      return this.displayedData.filter(item => searchRegex.test(item.name));
    } else {
      return this.displayedData; // no search query, return all data
    }
  }
  },
  async mounted () {
   await this.fetchMasterRejectReason()
  },
  methods: {
   ...srActions,
    menuPermission (output) {
       const { menuObject } = this.$route.query
    
       if (!menuObject) {
         return true
       }
    
       return JSON.parse(window.atob(menuObject))[output]
    },
    onEditRejectReason (reject_reason) {
        this.edit_mode = true
        this.selected_id = reject_reason.id
        this.form = {
          name: reject_reason.name,
          alias: reject_reason.alias,
          is_active: reject_reason.is_active  === true ? true : false,
        }
    },
    onDeleteRejectReason (payload) {
        this.$swal({
          title: 'Delete Reject Reason?',
          text: `Reject Reason ${payload.name} will be deleted and cannot be recovered.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Delete'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loading_delete = true
            let res = await this.deleteMasterRejectReason({id:payload.id})
            if (res.status != 'success') {
              this.loading_delete = false
              this.$swal(
                  'Oops!',
                  'Something wrong with the server. Please try again later.',
                  'error'
                )
            } else {
              this.loading_delete = false
              this.fetchMasterRejectReason()
              this.$swal(
                `Reject Reason deleted!`,
                `Reject Reason ${payload.name} successfully deleted`,
                'success'
              )
            }
          }
        })
    },
    resetFormRejectReason () {
        this.edit_mode = false
        this.form = {
            name:'',
            alias:'',
            is_active: true,
        }
    },
    async fetchMasterRejectReason () {
        this.loading = true

        const res = await this.getMasterRejectReason()

        if(res.status === 'success'){
            this.data.data = res.data
        }        

        this.loading = false
    },
    async submitFormRejectReason () {
        this.loading_submit = true
  
        let payload = {
          id: this.selected_id,
          data: {
            name: this.form.name,
            alias: this.form.alias,
            is_active: this.form.is_active === true ? true : false,
          }
        }
  
        let form_has_empty_value = false
        for (var key in payload.data) {
          if (payload.data[key] === '') form_has_empty_value = true
          else if (Array.isArray(payload.data[key]) && !payload.data[key].length) { form_has_empty_value = true }
        }
  
        if (form_has_empty_value) {
          this.$swal(
            'Empty form!',
            'Please check again and fill all the forms.',
            'error'
          )
        } else {
          if (!this.edit_mode) {
            let res = await this.createMasterRejectReason({data: payload.data})
            if (res.status == 'success') {
              this.resetFormRejectReason()
              this.fetchMasterRejectReason()
              this.$swal(
                'Reject Reason added!',
                'Reject Reason added successfully.',
                'success'
              )
            } else {
                this.$swal(
                  'Oops!',
                  'Something wrong with the server. Please try again later.',
                  'error'
                )
            }
          } else {  
            let res = await this.updateMasterRejectReason(payload)
            console.log(res)
            if (res.status == 'success') {
              this.resetFormRejectReason()
              this.fetchMasterRejectReason()
              this.$swal(
                'Reject Reason updated!',
                'Reject Reason updated successfully.',
                'success'
              )
            } else {
                this.$swal(
                  'Oops!',
                  'Something wrong with the server. Please try again later.',
                  'error'
                )
            }
          }
        }
        this.loading_submit = false
      },

  }
}
</script>