var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    attrs: {
      "action": "#",
      "method": "post"
    },
    on: {
      "submit": _vm.onSave
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("LAPORAN CONDUITE CREW / "), _c('i', [_vm._v("CONDUITE CREW REPORT")])])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$userDetails$fulln, _vm$userDetails, _vm$userDetails$no_kt, _vm$userDetails2, _vm$userDetails$perso, _vm$userDetails3, _vm$userDetails3$pers, _vm$userDetails$perso2, _vm$userDetails4, _vm$userDetails4$pers, _vm$userDetails$perso3, _vm$userDetails5, _vm$userDetails5$pers, _vm$userDetails$posit, _vm$userDetails6, _vm$userDetails6$posi, _vm$userDetails$nip, _vm$userDetails7, _vm$userDetails$compa, _vm$userDetails8, _vm$userDetails8$comp, _vm$userDetails$perso4, _vm$userDetails9, _vm$userDetails9$pers, _vm$userDetails$vehic, _vm$userDetails10, _vm$userDetails10$veh, _vm$userDetails$seama, _vm$userDetails11, _vm$userDetails$port$, _vm$userDetails12, _vm$userDetails12$por, _vm$userDetails$perso5, _vm$userDetails13, _vm$userDetails13$per, _vm$userDetails$sign_, _vm$userDetails14, _vm$userDetails$perso6, _vm$userDetails15, _vm$userDetails15$per, _vm$userDetails$join_, _vm$userDetails16, _vm$userDetails$email, _vm$userDetails17, _vm$userDetails$sign_2, _vm$userDetails18, _vm$userDetails$candi, _vm$userDetails19, _vm$userDetails19$can, _vm$userDetails19$can2, _vm$userDetails19$can3, _vm$userDetails20, _vm$userDetails21;
        return [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Crew Information")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "10"
          }
        }, [_c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Crew")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$fulln = (_vm$userDetails = _vm.userDetails) === null || _vm$userDetails === void 0 ? void 0 : _vm$userDetails.fullname) !== null && _vm$userDetails$fulln !== void 0 ? _vm$userDetails$fulln : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("No KTP")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$no_kt = (_vm$userDetails2 = _vm.userDetails) === null || _vm$userDetails2 === void 0 ? void 0 : _vm$userDetails2.no_ktp) !== null && _vm$userDetails$no_kt !== void 0 ? _vm$userDetails$no_kt : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Tempat/Tanggal Lahir")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$perso = (_vm$userDetails3 = _vm.userDetails) === null || _vm$userDetails3 === void 0 ? void 0 : (_vm$userDetails3$pers = _vm$userDetails3.personal_data) === null || _vm$userDetails3$pers === void 0 ? void 0 : _vm$userDetails3$pers.place_of_birth) !== null && _vm$userDetails$perso !== void 0 ? _vm$userDetails$perso : '-') + ", " + _vm._s((_vm$userDetails$perso2 = (_vm$userDetails4 = _vm.userDetails) === null || _vm$userDetails4 === void 0 ? void 0 : (_vm$userDetails4$pers = _vm$userDetails4.personal_data) === null || _vm$userDetails4$pers === void 0 ? void 0 : _vm$userDetails4$pers.date_of_birth) !== null && _vm$userDetails$perso2 !== void 0 ? _vm$userDetails$perso2 : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("No NPWP")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$perso3 = (_vm$userDetails5 = _vm.userDetails) === null || _vm$userDetails5 === void 0 ? void 0 : (_vm$userDetails5$pers = _vm$userDetails5.personal_data) === null || _vm$userDetails5$pers === void 0 ? void 0 : _vm$userDetails5$pers.npwp_number) !== null && _vm$userDetails$perso3 !== void 0 ? _vm$userDetails$perso3 : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Jabatan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$posit = (_vm$userDetails6 = _vm.userDetails) === null || _vm$userDetails6 === void 0 ? void 0 : (_vm$userDetails6$posi = _vm$userDetails6.position) === null || _vm$userDetails6$posi === void 0 ? void 0 : _vm$userDetails6$posi.name) !== null && _vm$userDetails$posit !== void 0 ? _vm$userDetails$posit : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("ID Crew")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$nip = (_vm$userDetails7 = _vm.userDetails) === null || _vm$userDetails7 === void 0 ? void 0 : _vm$userDetails7.nip) !== null && _vm$userDetails$nip !== void 0 ? _vm$userDetails$nip : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Perusahaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$compa = (_vm$userDetails8 = _vm.userDetails) === null || _vm$userDetails8 === void 0 ? void 0 : (_vm$userDetails8$comp = _vm$userDetails8.company) === null || _vm$userDetails8$comp === void 0 ? void 0 : _vm$userDetails8$comp.company) !== null && _vm$userDetails$compa !== void 0 ? _vm$userDetails$compa : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Seaferer Code")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$perso4 = (_vm$userDetails9 = _vm.userDetails) === null || _vm$userDetails9 === void 0 ? void 0 : (_vm$userDetails9$pers = _vm$userDetails9.personal_data) === null || _vm$userDetails9$pers === void 0 ? void 0 : _vm$userDetails9$pers.seaferer_code) !== null && _vm$userDetails$perso4 !== void 0 ? _vm$userDetails$perso4 : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kapal")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$vehic = (_vm$userDetails10 = _vm.userDetails) === null || _vm$userDetails10 === void 0 ? void 0 : (_vm$userDetails10$veh = _vm$userDetails10.vehicle) === null || _vm$userDetails10$veh === void 0 ? void 0 : _vm$userDetails10$veh.name) !== null && _vm$userDetails$vehic !== void 0 ? _vm$userDetails$vehic : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Seaman Book")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$seama = (_vm$userDetails11 = _vm.userDetails) === null || _vm$userDetails11 === void 0 ? void 0 : _vm$userDetails11.seamant_book) !== null && _vm$userDetails$seama !== void 0 ? _vm$userDetails$seama : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Pelabuhan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$port$ = (_vm$userDetails12 = _vm.userDetails) === null || _vm$userDetails12 === void 0 ? void 0 : (_vm$userDetails12$por = _vm$userDetails12.port) === null || _vm$userDetails12$por === void 0 ? void 0 : _vm$userDetails12$por.port_name) !== null && _vm$userDetails$port$ !== void 0 ? _vm$userDetails$port$ : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("No Passport")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$perso5 = (_vm$userDetails13 = _vm.userDetails) === null || _vm$userDetails13 === void 0 ? void 0 : (_vm$userDetails13$per = _vm$userDetails13.personal_data) === null || _vm$userDetails13$per === void 0 ? void 0 : _vm$userDetails13$per.passport_number) !== null && _vm$userDetails$perso5 !== void 0 ? _vm$userDetails$perso5 : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Sign On")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$sign_ = (_vm$userDetails14 = _vm.userDetails) === null || _vm$userDetails14 === void 0 ? void 0 : _vm$userDetails14.sign_on) !== null && _vm$userDetails$sign_ !== void 0 ? _vm$userDetails$sign_ : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nomor Tlp / Hp")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$perso6 = (_vm$userDetails15 = _vm.userDetails) === null || _vm$userDetails15 === void 0 ? void 0 : (_vm$userDetails15$per = _vm$userDetails15.personal_data) === null || _vm$userDetails15$per === void 0 ? void 0 : _vm$userDetails15$per.hp_number) !== null && _vm$userDetails$perso6 !== void 0 ? _vm$userDetails$perso6 : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Join Onboard")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$join_ = (_vm$userDetails16 = _vm.userDetails) === null || _vm$userDetails16 === void 0 ? void 0 : _vm$userDetails16.join_on_board) !== null && _vm$userDetails$join_ !== void 0 ? _vm$userDetails$join_ : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Email")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$email = (_vm$userDetails17 = _vm.userDetails) === null || _vm$userDetails17 === void 0 ? void 0 : _vm$userDetails17.email) !== null && _vm$userDetails$email !== void 0 ? _vm$userDetails$email : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Sign Off")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$sign_2 = (_vm$userDetails18 = _vm.userDetails) === null || _vm$userDetails18 === void 0 ? void 0 : _vm$userDetails18.sign_off) !== null && _vm$userDetails$sign_2 !== void 0 ? _vm$userDetails$sign_2 : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Periode Penilaian")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.evaluasi,
            "size": "sm",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Periode")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.form.evaluation_period,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "evaluation_period", $$v);
            },
            expression: "form.evaluation_period"
          }
        })], 1)]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Alamat")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v(" " + _vm._s((_vm$userDetails$candi = (_vm$userDetails19 = _vm.userDetails) === null || _vm$userDetails19 === void 0 ? void 0 : (_vm$userDetails19$can = _vm$userDetails19.candidate) === null || _vm$userDetails19$can === void 0 ? void 0 : (_vm$userDetails19$can2 = _vm$userDetails19$can.alamat_ktp) === null || _vm$userDetails19$can2 === void 0 ? void 0 : (_vm$userDetails19$can3 = _vm$userDetails19$can2[0]) === null || _vm$userDetails19$can3 === void 0 ? void 0 : _vm$userDetails19$can3.alamat) !== null && _vm$userDetails$candi !== void 0 ? _vm$userDetails$candi : '-') + " ")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(" ")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(" ")])])])])]), _c('b-col', {
          staticClass: "text-center",
          attrs: {
            "md": "2"
          }
        }, [_c('b-img', {
          staticClass: "ml-0",
          attrs: {
            "thumbnail": "",
            "src": (_vm$userDetails20 = _vm.userDetails) !== null && _vm$userDetails20 !== void 0 && _vm$userDetails20.photo ? (_vm$userDetails21 = _vm.userDetails) === null || _vm$userDetails21 === void 0 ? void 0 : _vm$userDetails21.photo : require('../../assets/images/page-img/10.jpg'),
            "alt": "Responsive image"
          }
        })], 1)], 1)], 1), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Form Conduite")]), _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          staticClass: "mb-3",
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', {
          staticClass: "text-center"
        }, [_c('th', {
          staticClass: "align-middle",
          attrs: {
            "width": "18%",
            "rowspan": "2"
          }
        }, [_vm._v("URAIAN")]), _vm._l(_vm.condite_points, function (point, index) {
          return _c('th', {
            key: index,
            attrs: {
              "width": "15%"
            }
          }, [_vm._v("NILAI Poin = " + _vm._s(point.value))]);
        }), _c('th', {
          staticClass: "align-middle",
          attrs: {
            "width": "12%",
            "rowspan": "2"
          }
        }, [_vm._v("POIN")])], 2), _c('tr', {
          staticClass: "text-center"
        }, _vm._l(_vm.condite_points, function (point, index) {
          return _c('th', {
            key: index
          }, [_vm._v(_vm._s(point.name))]);
        }), 0)]), _c('tbody', _vm._l(_vm.merged_condite, function (state, index) {
          return _c('tr', {
            key: index,
            staticStyle: {
              "font-size": "9pt"
            }
          }, [_c('th', [_vm._v(" " + _vm._s(state.uraian) + " ")]), _vm._l(state.description, function (val, i) {
            return _c('td', {
              key: i
            }, [_vm._v(" " + _vm._s(val.description) + " ")]);
          }), _c('td', {
            staticClass: "text-center"
          }, [_c('b-form-select', {
            staticClass: "col-md-12",
            attrs: {
              "plain": "",
              "options": _vm.optionsPoints,
              "size": "sm",
              "required": ""
            },
            scopedSlots: _vm._u([{
              key: "first",
              fn: function fn() {
                return [_c('b-form-select-option', {
                  attrs: {
                    "value": null
                  }
                }, [_vm._v("Nilai")])];
              },
              proxy: true
            }], null, true),
            model: {
              value: state.value,
              callback: function callback($$v) {
                _vm.$set(state, "value", $$v);
              },
              expression: "state.value"
            }
          })], 1)], 2);
        }), 0)])]), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('small', {
          staticClass: "mb-3"
        }, [_c('strong', [_vm._v("Rekomendasi oleh Nakhoda / Chief Officer "), _c('br')]), _vm._v(" Recommendation by Master")]), _c('b-form-textarea', {
          staticStyle: {
            "font-size": "9pt"
          },
          attrs: {
            "rows": "2",
            "placeholder": "Comments if any / Komentar jika ada",
            "required": ""
          },
          model: {
            value: _vm.form.recommendation_master,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "recommendation_master", $$v);
            },
            expression: "form.recommendation_master"
          }
        })], 1), _c('hr'), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "Clasification *",
            "for": "nama_kandidiat"
          }
        }, [_vm._v("Wawancara Oleh")]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-input', {
          attrs: {
            "id": "job_name",
            "type": "text",
            "placeholder": "Nama",
            "required": ""
          },
          model: {
            value: _vm.form.name_master,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "name_master", $$v);
            },
            expression: "form.name_master"
          }
        })], 1)])]), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('small', {
          staticClass: "mb-3"
        }, [_c('strong', [_vm._v("Rekomendasi oleh KKM (khusus untuk crew bagian mesin) "), _c('br')]), _vm._v(" Recommendation by Chief Engineer (only for engine crew)")]), _c('b-form-textarea', {
          staticStyle: {
            "font-size": "9pt"
          },
          attrs: {
            "rows": "2",
            "placeholder": "Comments if any / Komentar jika ada",
            "required": ""
          },
          model: {
            value: _vm.form.recommendation_user,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "recommendation_user", $$v);
            },
            expression: "form.recommendation_user"
          }
        })], 1), _c('hr'), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "Clasification *",
            "for": "nama_kandidiat"
          }
        }, [_vm._v("Wawancara Oleh")]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-input', {
          attrs: {
            "id": "job_name",
            "type": "text",
            "placeholder": "Nama",
            "required": ""
          },
          model: {
            value: _vm.form.name_user,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "name_user", $$v);
            },
            expression: "form.name_user"
          }
        })], 1)])]), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('small', {
          staticClass: "mb-3"
        }, [_c('strong', [_vm._v("Rekomendasi oleh Port Captain / Port Engineer "), _c('br')]), _vm._v(" Recommendation by Port Captain / Port Engineer")]), _c('b-form-textarea', {
          staticStyle: {
            "font-size": "9pt"
          },
          attrs: {
            "rows": "2",
            "placeholder": "Comments if any / Komentar jika ada",
            "required": ""
          },
          model: {
            value: _vm.form.recommendation4,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "recommendation4", $$v);
            },
            expression: "form.recommendation4"
          }
        })], 1), _c('hr'), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "Clasification *",
            "for": "nama_kandidiat"
          }
        }, [_vm._v("Wawancara Oleh")]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-input', {
          attrs: {
            "id": "job_name",
            "type": "text",
            "placeholder": "Nama",
            "required": ""
          },
          model: {
            value: _vm.form.name4,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "name4", $$v);
            },
            expression: "form.name4"
          }
        })], 1)])]), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('small', {
          staticClass: "mb-3"
        }, [_c('strong', [_vm._v("Rekomendasi oleh Site Manager "), _c('br')]), _vm._v(" Recommendation by Site Manager")]), _c('b-form-textarea', {
          staticStyle: {
            "font-size": "9pt"
          },
          attrs: {
            "rows": "2",
            "placeholder": "Comments if any / Komentar jika ada",
            "required": ""
          },
          model: {
            value: _vm.form.recommendation_manager,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "recommendation_manager", $$v);
            },
            expression: "form.recommendation_manager"
          }
        })], 1), _c('hr'), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "Clasification *",
            "for": "nama_kandidiat"
          }
        }, [_vm._v("Wawancara Oleh")]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-input', {
          attrs: {
            "id": "job_name",
            "type": "text",
            "placeholder": "Nama",
            "required": ""
          },
          model: {
            value: _vm.form.name_manager,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "name_manager", $$v);
            },
            expression: "form.name_manager"
          }
        })], 1)])])], 1)], 1), _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-3",
          attrs: {
            "disabled": _vm.saving,
            "variant": "primary",
            "type": "submit"
          }
        }, [_vm.saving ? _c('span', {
          staticClass: "spinner-border spinner-border-sm"
        }) : _c('span', [_vm._v("Save")])]), _c('b-button', {
          staticClass: "iq-bg-danger float-right",
          attrs: {
            "type": "reset",
            "variant": "none"
          }
        }, [_c('i', {
          staticClass: "fa fa-close"
        }), _vm._v(" Reset Form")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }