<template>
  <b-container id="erm-form" fluid>
    <form @submit.prevent="onSave">
      <b-row v-if="detailMi">
        <b-col lg="12">
          <StepWizard
            current-step="ERM"
            :claim-type="
              detailMi.type_form === 'non_claim' ? 'NON_CLAIM' : 'CLAIM'
            "
          />
        </b-col>
        <b-col md="12" class="text-right mb-3"  >
          <a v-if="isPreview" class="btn btn-primary" :href="`/mi/enterprise-risk-management?formId=${detailMi.id}`"
          >Edit</a>
          <b-button v-else type="submit" :disabled="form._saving" variant="primary"
          >Simpan Data</b-button>
        </b-col>
        <b-col md="6">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"
                ><strong>Enterprise Risk Management Assessor :</strong></h4
              >
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Name">
                    <b-form-input
                      required
                      class="w-100"
                      type="text"
                      v-model="form.assessor_fullname"
                      placeholder="Name..."
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group label="Department">
                    <template v-if="isPreview">
                      {{departments.find(department => department.code === form.assessor_department_id) ? departments.find(department => department.code === form.assessor_department_id).name : '-'}}
                    </template>
                    <v-select
                      v-else
                      required
                      v-model="form.assessor_department_id"
                      label="name"
                      :options="departments"
                      :reduce="(department) => department.code"
                    ></v-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Company">
                    <template v-if="isPreview">
                      {{companies.find(company => company.id === form.assessor_company_id) ? companies.find(company => company.id === form.assessor_company_id).company : '-'}}
                    </template>
                    <v-select
                      v-else
                      required
                      v-model="form.assessor_company_id"
                      label="company"
                      :options="companies"
                      :reduce="(companyOptions) => companyOptions.id"
                    ></v-select>
                  </b-form-group>
                  <b-form-group label="ERM Credential ">
                    <b-form-input
                      required
                      class="w-100"
                      v-model="form.assessor_erm_credential"
                      type="text"
                      placeholder=""
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
        <b-col md="6">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"
                ><strong>Enterprise Risk Management Target :</strong></h4
              >
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Person In Charge">
                    <b-form-input
                      required
                      v-model="form.target_pic"
                      class="w-100"
                      type="text"
                      placeholder="PIC"
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group label="Department">
                    <template v-if="isPreview">
                      {{departments.find(department => department.code === form.target_department_id) ? departments.find(department => department.code === form.target_department_id).name : '-'}}
                    </template>
                    <v-select
                      v-else
                      required
                      v-model="form.target_department_id"
                      multiple
                      label="name"
                      :options="departments"
                      :reduce="(department) => department.code"
                    ></v-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Location">
                    <b-form-input
                      required
                      v-model="form.target_location"
                      class="w-100"
                      type="text"
                      placeholder=""
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group label="Month Assessment">
                    <date-picker
                      class="w-100"
                      :disabled="isPreview"
                      @change="onChangeDate()"
                      v-model="form.date_range"
                      required
                      value-type="YYYY-MM-DD"
                      format="DD MMM YYYY"
                      type="date"
                      range
                      placeholder="Select date range"
                    ></date-picker>
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>

        <!-- Sasaran Smart -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">Sasaran Smart</h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.sasaran_smart
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="sasaran_smart">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <b-alert :show="true" class="text-white bg-primary">
                      <div class="iq-alert-text"
                        ><strong>Sasaran Smart :</strong></div
                      >
                    </b-alert>
                    <table class="table">
                      <thead>
                        <tr>
                          <th width="16%" class="text-center">Sasaran</th>
                          <th width="16%" class="text-center">Spesific</th>
                          <th width="16%" class="text-center">Measurable</th>
                          <th width="16%" class="text-center">Achievable</th>
                          <th width="16%" class="text-center">Relevant</th>
                          <th width="16%" class="text-center">Time-Bound</th>
                          <th width="4%" class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in form.sasaran_smart"
                          :key="index"
                        >
                          <td>
                            <template v-if="isPreview">
                              {{data.sasaran}}
                            </template>
                            <b-form-textarea
                              v-else
                              @change="onSasaranSmartChanged(index)"
                              v-model="data.sasaran"
                              placeholder="Input your text..."
                              style="line-height: 20px"
                              rows="4"
                            ></b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.spesific}}
                            </template>
                            <b-form-textarea
                              v-else
                              @change="onSasaranSmartChanged(index)"
                              v-model="data.spesific"
                              style="line-height: 20px"
                              rows="4"
                            ></b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.measurable}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.measurable"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.achievable}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.achievable"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.relevant}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.relevant"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <date-picker
                              v-model="data.timebound"
                              :disabled="isPreview"
                              value-type="YYYY-MM-DD"
                              format="DD MMM YYYY"
                              type="date"
                              placeholder="Select date"
                            ></date-picker>
                          </td>
                          <td>
                            <b-button
                              v-if="!isPreview"
                              @click="removeSasaranSmart(index)"
                              variant="danger"
                              ><i class="fa fa-trash"></i>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <b-button
                      variant="warning"
                      v-if="!isPreview"
                      @click="addSasaranSmart"
                      block
                      class="mt-2 mb-3"
                      ><i class="fa fa-plus"></i> Add More Data</b-button
                    >
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Sasaran Smart End -->

        <!-- Penamaan Risiko -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">Penamaan Risiko</h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.penamaan_resiko
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="penamaan_resiko">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <table class="table mb-2">
                      <thead>
                        <tr>
                          <th width="14%" class="text-center">Sasaran</th>
                          <th width="14%" class="text-center">Nama Risiko</th>
                          <th width="14%" class="text-center">Perisitiwa</th>
                          <th width="14%" class="text-center">Sebab</th>
                          <th width="14%" class="text-center">Konsekuensi</th>
                          <th width="14%" class="text-center"
                            >Kategori Risiko</th
                          >
                          <th width="14%" class="text-center">Tindakan</th>
<!--                          <th width="2%" class="text-center">Action</th>-->
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in form.penamaan_resiko"
                          :key="index"
                        >
                          <td>
                            <template v-if="isPreview">
                              {{data.sasaran}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.sasaran" />
                          </td>
                          <td>
                            <b-form-select
                              plain
                              v-model="data.risiko"
                              :options="listRisk"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select Risk</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.peristiwa}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.peristiwa"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.sebab}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.sebab"
                              style="line-height: 20px"
                              rows="4"
                            ></b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.konsekuensi}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.konsekuensi"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <b-form-select
                              plain
                              v-model="data.kategori_risiko"
                              :options="catRisk"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.tindakan}}
                            </template>
                            <b-form-textarea
                              v-else
                              @change="onPenamaanResikoChanged(index)"
                              v-model="data.tindakan"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                        <!--                          <td>
                            -
                            <b-button
                              variant="danger"
                              @click="removePenamaanResiko(index)"
                              ><i class="fa fa-trash"></i>
                            </b-button>
                          </td>-->
                        </tr>
                      </tbody>
                    </table>
<!--                    <b-button-->
<!--                      variant="warning"-->
<!--                      @click="addPenamaanResiko"-->
<!--                      block-->
<!--                      class="mt-2 mb-3"-->
<!--                      ><i class="fa fa-plus"></i> Add More Data</b-button-->
<!--                    >-->
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Penamaan Risiko End -->

        <!-- Prinsip Manajemen Risiko -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">Prinsip Manajemen Risiko</h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.prinsip_manajemen_resiko
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="prinsip_manajemen_resiko">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <table class="table mb-2">
                      <thead>
                        <tr>
                          <th width="25%" class="text-center"
                            >Prinsip Manajemen Risiko</th
                          >
                          <th width="25%" class="text-center"
                            >Definisi Prinsip</th
                          >
                          <th width="25%" class="text-center"
                            >Implementasi Manajemen Risiko</th
                          >
                          <th width="25%" class="text-center"
                            >Status Implementasi</th
                          >
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in form.prinsip_manajemen_resiko"
                          :key="index"
                        >
                          <td>
                            <p>{{ data.prinsip }}</p>
                          </td>
                          <td>
                            <p>{{ data.definisi }}</p>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.implementasi}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.implementasi"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <b-form-select
                              plain
                              v-model="data.status"
                              :options="statusImplementasi"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Prinsip Manajemen Risiko End -->

        <!-- Kepemimpinan Dan Komitmen -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">Kepemimpinan Dan Komitmen</h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.kepemimpinan_komitmen
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="kepemimpinan_komitmen">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <table class="table mb-2">
                      <thead>
                        <tr>
                          <th width="24%" class="text-center"
                            >Pemimpin Risiko</th
                          >
                          <th width="24%" class="text-center"
                            >Uraian Tugas Pemimpin Risiko</th
                          >
                          <th width="24%" class="text-center"
                            >Dokumen Pendukung</th
                          >
                          <th width="24%" class="text-center">Remarks</th>
                          <th width="4%" class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in form.kepemimpinan_komitmen"
                          :key="index"
                        >
                          <td>
                            <b-form-input required v-model="data.pimpinan" class="w-100" type="text" placeholder=""></b-form-input>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.tugas}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.tugas"
                              style="line-height: 20px"
                              rows="4"
                            ></b-form-textarea>
                          </td>
                          <td>
                            <div v-if="data.dokumen" class="d-flex justify-content-between">
                              <a :href="data.dokumen" class="text-primary" v-b-tooltip.hover title="Download File" download><i class="fa fa-download"></i> Download</a>
                              <a href="#" @click.prevent="data.dokumen = ''" class="text-danger" v-b-tooltip.hover title="Hapus" download><i class="fa fa-trash"></i></a>
                            </div>
                            <template v-else>
                              <div class="custom-file">
                                <input
                                  :id="`commitment-file-input-${index}`"
                                  type="file"
                                  @change="
                                  onKepemimpinanKomitmentFileChanged(
                                    index,
                                    $event
                                  )
                                "
                                  accept="image/png,image/jpg,image/jpeg,.pdf,.xls,.xlsx,.doc,.docx"
                                  class="custom-file-input"
                                />
                                <label
                                  class="custom-file-label"
                                  :for="`commitment-file-input-${index}`"
                                >Browse file...</label
                                >
                              </div>
                              <small class="text-muted">
                                File Type * :
                                <b-badge variant="info"
                                >.jpg | .jpeg | .png | .xlx | .xlxs | .doc |
                                  .docs | .pdf</b-badge
                                ><br />
                                File Capacity * :
                                <b-badge variant="info">3 MB / File</b-badge>
                              </small>
                            </template>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.remarks}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.remarks"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <b-button
                              v-if="!isPreview "
                              variant="danger"
                              @click="removeKepemimpinanKomitment(index)"
                              ><i class="fa fa-trash"></i
                            ></b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <b-button
                      variant="warning"
                      v-if="!isPreview "
                      @click="addKepemimpinanKomitment"
                      block
                      class="mt-2 mb-3"
                      ><i class="fa fa-plus"></i> Add More Data</b-button
                    >
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Kepemimpinan Dan Komitmen End -->

        <!-- Analisa Pemangku Kepentingan -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"
                >Analisa Pemangku Kepentingan</h4
              >
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.analisa_pemangku_kepentingan
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="analisa_pemangku_kepentingan">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <table class="table mb-2">
                      <thead>
                        <tr>
                          <th width="11%" class="text-center">Status</th>
                          <th width="11%" class="text-center">Nama</th>
                          <th width="14%" class="text-center">Kebutuhan/Tantangan</th>
                          <th width="11%" class="text-center">Dukungan</th>
                          <th width="11%" class="text-center">Ancaman</th>
                          <th width="11%" class="text-center">Strategi</th>
                          <th width="14%" class="text-center">Deskripsi Tindakan</th>
                          <th width="14%" class="text-center">Remarks</th>
                          <th width="3%" class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in form.analisa_pemangku_jabatan"
                          :key="index"
                        >
                          <td>
                            <b-form-select
                              plain
                              v-model="data.status"
                              :options="statusKesesuaian"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                          <td>
                            <b-form-input v-model="data.nama" class="w-100" type="text" placeholder=""></b-form-input>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.tantangan}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.tantangan"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <b-form-select
                              plain
                              v-model="data.dukungan"
                              :options="dukunganAncaman"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                          <td>
                            <b-form-select
                              plain
                              v-model="data.ancaman"
                              :options="dukunganAncaman"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                          <td>
                            <b-form-select
                              plain
                              v-model="data.strategi"
                              :options="listStrategi"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.tindakan}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.tindakan"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.remarks}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.remarks"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td class="text-center">
                            <b-button
                              v-if="!isPreview "
                              variant="danger"
                              @click="removeAnalisaPemangkuJabatan(index)"
                              ><i class="fa fa-trash"></i
                            ></b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <b-button
                      variant="warning"
                      v-if="!isPreview "
                      @click="addAnalisaPemangkuJabatan"
                      block
                      class="mt-2 mb-3"
                      ><i class="fa fa-plus"></i> Add More Data</b-button
                    >
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Analisa Pemangku Kepentingan End -->

        <!-- Integrasi - Sasaran Smart -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">Integrasi - Sasaran Smart</h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.integrasi_sasaran_smart
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="integrasi_sasaran_smart">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <table class="table mb-2">
                      <thead>
                        <tr>
                          <th width="25%" class="text-center"
                            >Status Kesesuaian</th
                          >
                          <th width="25%" class="text-center">Deskripsi</th>
                          <th width="25%" class="text-center"
                            >Dokumen Pendukung</th
                          >
                          <th width="25%" class="text-center">Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in form.integrasi_sasaran_smart"
                          :key="index"
                        >
                          <td>
                            <p>{{ data.kesesuaian }}</p>
                          </td>
                          <td>
                            <p>{{ data.deskripsi }}</p>
                          </td>
                          <td>
                            <div v-if="data.dokumen" class="d-flex justify-content-between">
                              <a :href="data.dokumen" class="text-primary" v-b-tooltip.hover title="Download File" download><i class="fa fa-download"></i> Download</a>
                              <a href="#" @click.prevent="data.dokumen = ''" class="text-danger" v-b-tooltip.hover title="Hapus" download><i class="fa fa-trash"></i></a>
                            </div>
                            <template v-else>
                              <div class="custom-file">
                                <input
                                 
                                  :id="`integrasi-sasaran-smart-file-input-${index}`"
                                  type="file"
                                  @change="
                                  onIntegrasiSasaranSmartFileChanged(
                                    index,
                                    $event
                                  )
                                "
                                  accept="image/png,image/jpg,image/jpeg,.pdf,.xls,.xlsx,.doc,.docx"
                                  class="custom-file-input"
                                />
                                <label
                                  class="custom-file-label"
                                  :for="`integrasi-sasaran-smart-file-input-${index}`"
                                >Browse file...</label
                                >
                              </div>
                              <small class="text-muted">
                                File Type * :
                                <b-badge variant="info"
                                >.jpg | .jpeg | .png | .xlx | .xlxs | .doc |
                                  .docs | .pdf</b-badge
                                ><br />
                                File Capacity * :
                                <b-badge variant="info">3 MB / File</b-badge>
                              </small>
                            </template>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.remarks}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.remarks"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Integrasi - Sasaran Smart End -->

        <!-- Evaluasi -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">Evaluasi</h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.evaluasi
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="evaluasi">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <table class="table mb-2">
                      <thead>
                        <tr>
                          <th width="13%" class="text-center"
                            >Tahapan Kegiatan</th
                          >
                          <th width="13%" class="text-center"
                            >Sasaran Tahapan (Milestone)</th
                          >
                          <th width="13%" class="text-center">Uraian Tugas</th>
                          <th width="13%" class="text-center"
                            >Indikator Keberhasilan</th
                          >
                          <th width="12%" class="text-center"
                            >Metode Evaluasi</th
                          >
                          <th width="12%" class="text-center">Frekuensi</th>
                          <th width="12%" class="text-center">Remarks</th>
                          <th width="5" class="text-center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in form.evaluasi" :key="index">
                          <td>
                            <template v-if="isPreview">
                              {{data.kegiatan}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.kegiatan"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.sasaran}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.sasaran"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.uraian}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.uraian"
                              style="line-height: 20px"
                              rows="4"
                            ></b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.keberhasilan}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.keberhasilan"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.evaluasi}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.evaluasi"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.frekuensi}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.frekuensi"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.remarks}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.remarks"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <b-button
                              v-if="!isPreview "
                              @click="removeEvaluasi(index)"
                              variant="danger"
                              ><i class="fa fa-trash"></i>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <b-button
                      variant="warning"
                      v-if="!isPreview "
                      @click="addEvaluasi"
                      block
                      class="mt-2 mb-3"
                      ><i class="fa fa-plus"></i> Add More Data</b-button
                    >
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Evaluasi End -->

        <!-- Perbaikan Sinambung -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">Perbaikan Sinambung</h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.perbaikan_sinambung
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="perbaikan_sinambung">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <table class="table mb-2">
                      <thead>
                        <tr>
                          <th width="14%" class="text-center">Tahapan</th>
                          <th width="14%" class="text-center"
                            >Sasaran Tahapan</th
                          >
                          <th width="15%" class="text-center">Uraian Tugas</th>
                          <th width="14%" class="text-center"
                            >Indikator Keberhasilan</th
                          >
                          <th width="14%" class="text-center"
                            >Hasil Evaluasi</th
                          >
                          <th width="15%" class="text-center">Frekuensi</th>
                          <th width="14%" class="text-center">Pelaksana</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in form.perbaikan_sinambung"
                          :key="index"
                        >
                          <td>
                            <p>{{ data.tahapan }}</p>
                          </td>
                          <td>
                            <p>{{ data.sasaran }}</p>
                          </td>
                          <td>
                            <p>{{ data.uraian }}</p>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.keberhasilan}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.keberhasilan"
                              style="line-height: 20px"
                              rows="4"
                              placeholder="Berjalannya unit manajemen resiko dengan pencapaian Terkendalinya accident/ incident setiap bulannya dengan minimum 2 major case"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.evaluasi}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.evaluasi"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.frekuensi}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.frekuensi"
                              style="line-height: 20px"
                              rows="4"
                              placeholder="Dilakukan pengawasan serta review lebih lanjut terkait hambatan-hambatan yang terjadi dalam prosesnya"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <b-form-input
                              v-model="data.pelaksana"
                              class="w-100"
                              type="text"
                              placeholder="Capt Doddy"
                            >
                            </b-form-input>
<!--                            <b-form-select
                              plain
                              v-model="data.pelaksana"
                              :options="userLevel"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select</b-form-select-option
                                >
                              </template>
                            </b-form-select>-->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Perbaikan Sinambung End -->

        <!-- Komunikasi Dan Konsultasi -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">Komunikasi Dan Konsultasi</h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.komunikasi_konsultasi
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="komunikasi_konsultasi">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <table class="table mb-2">
                      <thead>
                        <tr>
                          <th width="12%" class="text-center">Pemangku</th>
                          <th width="12%" class="text-center">Konten</th>
                          <th width="12%" class="text-center">Tujuan</th>
                          <th width="12%" class="text-center">Komunikator</th>
                          <th width="12%" class="text-center">Waktu</th>
                          <th width="12%" class="text-center">Frekuensi</th>
                          <th width="12%" class="text-center">Media/Metode</th>
                          <th width="12%" class="text-center">PIC</th>
                          <th width="4%" class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in form.komunikasi_konsultasi"
                          :key="index"
                        >
                          <td>
                            <b-form-input v-model="data.pemangku" class="w-100" type="text" placeholder=""></b-form-input>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.konten}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.konten"
                              style="line-height: 20px"
                              rows="4"
                              placeholder="Minimalisir risiko dan kerugian dari kejadian-kejadian yang terjadi dan melakukan evaluasi atas kendali yang sudah dan akan dilakukan kedepannya."
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.tujuan}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.tujuan"
                              style="line-height: 20px"
                              rows="4"
                              placeholder="Koordinasi dan induksi"
                            ></b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.komunikator}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.komunikator"
                              style="line-height: 20px"
                              rows="4"
                              placeholder="Superintendent, PE, PC, Radio Room, QHSE"
                            ></b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.waktu}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.waktu"
                              style="line-height: 20px"
                              rows="4"
                              placeholder="WEEKLY Meeting"
                            ></b-form-textarea>
                          </td>
                          <td>
                            <b-form-select
                              plain
                              v-model="data.frekuensi"
                              :options="frekuensi"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.metode}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.metode"
                              style="line-height: 20px"
                              rows="4"
                              placeholder="Melalui presentasi capaian kendali saat rapat dilakukan"
                            ></b-form-textarea>
                          </td>
                          <td>
                            <b-form-select
                             
                              plain
                              v-model="data.pic"
                              :options="userLevel"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                          <td class="text-center">
                            <b-button
                              v-if="!isPreview "
                              @click="removeKomunikasiKonsultasi(index)"
                              variant="danger"
                              ><i class="fa fa-trash"></i
                            ></b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <b-button
                      variant="warning"
                      v-if="!isPreview "
                      @click="addKomunikasiKonsultasi"
                      block
                      class="mt-2 mb-3"
                      ><i class="fa fa-plus"></i> Add More Data</b-button
                    >
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Komunikasi Dan Konsultasi End -->

        <!-- Raci Matrix -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">Raci Matrix</h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.raci_matrix
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="raci_matrix">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <table class="table mb-2" v-if="raciMatrix">
                      <thead>
                        <tr>
                          <th class="text-center" rowspan="2">Details</th>
                          <th class="text-center" v-if="raciMatrix.columns" :colspan="raciMatrix.columns.length">Owner Risk</th>
                        </tr>
                        <tr v-if="raciMatrix.columns">
                          <th class="text-center" v-for="(col, colIndex) in raciMatrix.columns" :key="colIndex">{{ col.name }}</th>
                        </tr>
                      </thead>
                      <tbody v-if="raciMatrix.rows">
                        <tr v-for="(row, rowIndex) in raciMatrix.rows" :key="rowIndex">
                          <td>
                            <strong>{{ row.name }}</strong>
                          </td>
                          <template v-if="raciMatrix.columns">
                            <td class="text-center" v-for="(col, colIndex) in raciMatrix.columns" :key="colIndex">
                              <p v-if="isPreview">{{raxiMatrixFormValue(row.id, col.id)}}</p>
                              <v-select
                                v-else
                                :value="raxiMatrixFormValue(row.id, col.id)"
                                @input="onRaciMatrixChanged(row.id, col.id, $event)"
                                multiple
                                :options="['R', 'A', 'C', 'I']"
                              ></v-select>

                            </td>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col md="1">
                    <div class="bg-danger text-center text-white">
                      <h4 class="text-white">R</h4>
                    </div>
                  </b-col>
                  <b-col md="11">
                    <p
                      ><strong>"Responsible"</strong> adalah orang atau unit
                      yang harus melaksanakan kegiatan atau aktivitas dalam
                      mekanisme informasi dan pelaporan</p
                    >
                  </b-col>

                  <b-col md="1">
                    <div class="bg-warning text-center text-white">
                      <h4 class="text-white">A</h4>
                    </div>
                  </b-col>
                  <b-col md="11">
                    <p
                      ><strong>"Accountable"</strong> adalah orang atau unit
                      yang berhak membuat keputusan atas kegiatan dalam
                      mekanisme informasi dan pelaporan</p
                    >
                  </b-col>

                  <b-col md="1">
                    <div class="bg-success text-center text-white">
                      <h4 class="text-white">C</h4>
                    </div>
                  </b-col>
                  <b-col md="11">
                    <p
                      ><strong>"Consulted"</strong> adalah orang atau unit yang
                      harus di ajak konsultasi atau dilibatkan sebelum atau saat
                      kegiatan dalam mekanisme informasi dan pelaporan</p
                    >
                  </b-col>

                  <b-col md="1">
                    <div class="bg-info text-center text-white">
                      <h4 class="text-white">I</h4>
                    </div>
                  </b-col>
                  <b-col md="11">
                    <p
                      ><strong>"Informed"</strong> adalah orang atau unit yang
                      harus di beri cukup informasi mengenal kegiatan dalam
                      mekanisme informasi, berikut pelaporan hasil dan proses
                      pelaksanaan</p
                    >
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Raci Matrix End -->

        <!-- Kriteria Konsekuensi -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">Kriteria Konsekuensi</h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.kriteria_konsekuensi
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="kriteria_konsekuensi">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <table class="table mb-2">
                      <thead>
                        <tr>
                          <th width="10%" class="text-center"
                            >Skala Konsekuensi</th
                          >
                          <th width="35%" class="text-center"
                            >Deskripsi Skala</th
                          >
                          <th width="20%" class="text-center"
                            >Deskripsi Kriteria</th
                          >
                          <th width="35%" class="text-center">Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in form.kriteria_konsekuensi"
                          :key="index"
                        >
                          <td class="text-center">
                            <p>{{ data.skala }}</p>
                          </td>
                          <td>
                            <p>{{ data.deskripsi_skala }}</p>
                          </td>
                          <td class="text-center">
                            <p>{{ data.deskripsi_kriteria }}</p>
                          </td>
                          <td>
                            <p>{{ data.remarks }}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Kriteria Konsekuensi End -->

        <!-- Kriteria Kemungkinan -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">Kriteria Kemungkinan</h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.kriteria_kemungkinan
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="kriteria_kemungkinan">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <table class="table mb-2">
                      <thead>
                        <tr>
                          <th width="10%" class="text-center"
                            >Skala Kemungkinan</th
                          >
                          <th width="35%" class="text-center"
                            >Deskripsi Skala</th
                          >
                          <th width="20%" class="text-center"
                            >Deskripsi Kriteria</th
                          >
                          <th width="35%" class="text-center">Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in form.kriteria_kemungkinan"
                          :key="index"
                        >
                          <td class="text-center">
                            <p>{{ data.skala }}</p>
                          </td>
                          <td>
                            <p>{{ data.deskripsi_skala }}</p>
                          </td>
                          <td class="text-center">
                            <p>{{ data.deskripsi_kriteria }}</p>
                          </td>
                          <td>
                            <p>{{ data.remarks }}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Kriteria Kemungkinan End -->

        <!-- Efektifitas Kendali -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">Efektifitas Kendali</h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.efektifitas_kendali
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="efektifitas_kendali">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <table class="table mb-2">
                      <thead>
                        <tr>
                          <th width="10%" class="text-center"
                            >Skala Efiktifitas Kendali</th
                          >
                          <th width="35%" class="text-center"
                            >Deskripsi Skala</th
                          >
                          <th width="20%" class="text-center"
                            >Deskripsi Kriteria</th
                          >
                          <th width="35%" class="text-center">Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in form.efektifitas_kendali"
                          :key="index"
                        >
                          <td class="text-center">
                            <p>{{ data.skala }}</p>
                          </td>
                          <td>
                            <p>{{ data.deskripsi_skala }}</p>
                          </td>
                          <td class="text-center">
                            <p>{{ data.deskripsi_kriteria }}</p>
                          </td>
                          <td>
                            <p>{{ data.remarks }}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Efektifitas Kendali End -->

        <!-- Kriteria Evaluasi -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">Kriteria Evaluasi</h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.kriteria_evaluasi
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="kriteria_evaluasi">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <table class="table mb-2">
                      <thead>
                        <tr>
                          <th width="10%" class="text-center"
                            >Efiktifitas Kendali</th
                          >
                          <th width="30%" class="text-center">Selera Risiko</th>
                          <th width="30%" class="text-center"
                            >Toleransi Risiko</th
                          >
                          <th width="30%" class="text-center"
                            >Tindakan & Eskalasi</th
                          >
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in form.kriteria_evaluasi"
                          :key="index"
                        >
                          <td class="text-center">
                            <p>{{ data.skala }}</p>
                          </td>
                          <td>
                            <p>{{ data.selera }}</p>
                          </td>
                          <td>
                            <p>{{ data.toleransi }}</p>
                          </td>
                          <td>
                            <p>{{ data.tindakan }}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Kriteria Evaluasi End -->

        <!-- Identifikasi Risiko -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">Identifikasi Risiko</h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.identifikasi_risiko
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="identifikasi_risiko">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <table class="table mb-2">
                      <thead>
                        <tr>
                          <th width="25%" class="text-center">Sasaran</th>
                          <th width="25%" class="text-center">Peristiwa Risiko</th>
                          <th width="25%" class="text-center">Penyebab</th>
                          <th width="25%" class="text-center">Akibat</th>
                          <th width="25%" class="text-center">Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in form.identifikasi_resiko"
                          :key="index"
                        >
                          <td>
                            <template v-if="isPreview">
                              {{data.sasaran}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.sasaran"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.peristiwa}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.peristiwa"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.penyebab}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.penyebab"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.akibat}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.akibat"
                              style="line-height: 20px"
                              rows="4"
                            ></b-form-textarea>
                          </td>
                          <td>
                            <b-button
                              v-if="!isPreview "
                              @click="removeIdentifikasiResiko(index)"
                              variant="danger"
                              ><i class="fa fa-trash"></i>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <b-button
                      variant="warning"
                      v-if="!isPreview "
                      @click="addIdentifikasiResiko"
                      block
                      class="mt-2 mb-3"
                      ><i class="fa fa-plus"></i> Add More Data</b-button
                    >
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Identifikasi Risiko End -->

        <!-- Analisis Dan Evaluasi Risiko -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"
                >Analisis Dan Evaluasi Risiko</h4
              >
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.analisis_evaluasi_risiko
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="analisis_evaluasi_risiko">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <table class="table mb-2">
                      <thead>
                        <tr>
                          <th width="18%" class="text-center"
                            >Peristiwa Risiko</th
                          >
                          <th width="12%" class="text-center">Efektivitas</th>
                          <th width="12%" class="text-center">Konsekuensi</th>
                          <th width="12%" class="text-center">Kemungkinan</th>
                          <th width="12%" class="text-center"
                            >Total Eksposur</th
                          >
                          <th width="12%" class="text-center"
                            >Tingkat Risiko</th
                          >
                          <th width="12%" class="text-center">Priority Risk</th>
                          <th width="12%" class="text-center"
                            >Evaluasi Risiko</th
                          >
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in form.analisis_evaluasi_resiko"
                          :key="index"
                          
                        >
                          <td>
                            <template v-if="isPreview">
                              {{data.peristiwa}}
                            </template>
                            <b-form-textarea
                              v-else
                              v-model="data.peristiwa"
                              style="line-height: 20px"
                              rows="4"
                            >
                            </b-form-textarea>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.efektifitas}}
                            </template>
                            <b-form-select
                             
                              v-else
                              plain
                              v-model="data.efektifitas"
                              :options="number"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="''"
                                >Select</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.konsekuensi}}
                            </template>
                            <b-form-select
                              @change="onAnalisaEvaluasiResikoChanged(index)"
                              v-else
                              plain
                              v-model="data.konsekuensi"
                              :options="number"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="''"
                                >Select</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.kemungkinan}}
                            </template>
                            <b-form-select
                              @change="onAnalisaEvaluasiResikoChanged(index)"
                              v-else
                              plain
                              v-model="data.kemungkinan"
                              :options="number"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="''"
                                >Select</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                          <td class="text-center">
                            <template v-if="isPreview">
                              {{data.eksposur}}
                            </template>
                            <p v-else>
                              {{data.eksposur = Number(data.konsekuensi) * Number(data.kemungkinan)}}
                            </p>
                          </td>
                          <td class="text-center">
                            <b-form-select
                              
                              plain
                              v-model="data.tingkat_risiko"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option value="Rendah"
                                  >Rendah</b-form-select-option
                                >
                                <b-form-select-option value="Sedang"
                                  >Sedang</b-form-select-option
                                >
                                <b-form-select-option value="Tinggi"
                                  >Tinggi</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                          <td class="text-center">
                            <b-form-select
                              plain
                              v-model="data.prioritas"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select</b-form-select-option
                                >
                              </template>
                              <b-form-select-option v-for="n in 10" :value="n"
                              >{{ n }}</b-form-select-option
                              >
                            </b-form-select>
                          </td>
                          <td>
                            <template v-if="isPreview">
                              {{data.evaluasi}}
                            </template>
                            <b-form-select
                              @change="onAnalisaEvaluasiResikoChanged(index)"
                              v-else
                              plain
                              v-model="data.evaluasi"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option value="Tolak"
                                >Tolak</b-form-select-option
                                >
                                <b-form-select-option value="Turunkan"
                                >Turunkan</b-form-select-option
                                >
                                <b-form-select-option value="Berbagi"
                                >Berbagi</b-form-select-option
                                >
                                <b-form-select-option value="Terima"
                                >Terima</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Analisis Dan Evaluasi Risiko End -->

        <!-- Peta Risiko - Before -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">Peta Risiko - Before</h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.peta_risiko_before
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="peta_risiko_before">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <table class="table mb-2">
                      <thead>
                        <tr>
                          <th rowspan="2" class="text-center">Likelihood/Kemungkinan/</th>
                          <th colspan="5" class="text-center">Impact/Konsekuensi</th>
                        </tr>
                        <tr>
                          <th class="text-center" v-for="col in 5" :key="col">{{ col }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="row in 5" :key="row">
                          <td class="text-center">
                            <p>{{row}}</p>
                          </td>
                          <td class="text-center" v-for="col in 5" :key="col" :class="{'bg-danger text-white' : petaRisikoBefore(row, col).length > 0}">
                            {{ petaRisikoBefore(row, col).map((r, ir) => ir+1).join(',')}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Peta Risiko - Before End -->

        <!-- Perlakuan Risiko -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">Perlakuan Risiko</h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.perlakuan_risiko
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="perlakuan_risiko">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <table class="table mb-2">
                      <thead>
                        <tr>
                          <th width="15%" class="text-center"
                            >Peristiwa Risiko</th
                          >
                          <th width="8%" class="text-center">Total Eksposur</th>
                          <th width="8%" class="text-center"
                            >Evaluasi Risiko</th
                          >
                          <th width="19%" class="text-center"
                            >Tindakan/Kendali</th
                          >
                          <th width="10%" class="text-center"
                            >Frekuensi Pemantauan</th
                          >
                          <th width="10%" class="text-center"
                            >Frekuensi Pelaporan</th
                          >
                          <th width="10%" class="text-center"
                            >Konsekuensi Residual</th
                          >
                          <th width="10%" class="text-center"
                            >Kemungkinan Residual</th
                          >
                          <th width="10%" class="text-center"
                            >Total Eksposur Residual</th
                          >
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in form.perlakuan_resiko">
                          <td>{{ data.peristiwa }}</td>
                          <td class="text-center">{{ data.total_eksposur }}</td>
                          <td class="text-center">{{ data.evaluasi_resiko }}</td>
                          <td>
                            {{ data.tindakan }}
                          </td>
                          <td class="text-center">
                            <b-form-select
                            
                              plain
                              v-model="data.frekuensi_pemantauan"
                              :options="frekuensi"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                          <td class="text-center">
                            <b-form-select
                           
                              plain
                              v-model="data.frekuensi_pelaporan"
                              :options="frekuensi"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                          <td class="text-center">
                            <b-form-select
                           
                              plain
                              v-model="data.konsekuensi_residual"
                              :options="number"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                          <td class="text-center">
                            <b-form-select
                           
                              plain
                              v-model="data.kemungkinan_residual"
                              :options="number"
                              size="lg"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                          <td class="text-center">
                            {{data.total_eksposur_residual = Number(data.konsekuensi_residual) * Number(data.kemungkinan_residual)}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Perlakuan Risiko End -->

        <!-- Peta Risiko - After -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">Peta Risiko - After</h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right ml-3"
                v-b-toggle.peta_risiko_after
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-angle-down" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :visible="isPreview" id="peta_risiko_after">
                <b-row>
                  <b-col md="12" class="mt-2 m-auto">
                    <table class="table mb-2">
                      <thead>
                      <tr>
                        <th rowspan="2" class="text-center">Likelihood/Kemungkinan/</th>
                        <th colspan="5" class="text-center">Impact/Konsekuensi</th>
                      </tr>
                      <tr>
                        <th class="text-center" v-for="col in 5" :key="col">{{ col }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="row in 5" :key="row">
                        <td class="text-center">
                          <p>{{row}}</p>
                        </td>
                        <td class="text-center" v-for="col in 5" :key="col" :class="{'bg-success text-white' : petaRisikoAfter(row, col).length > 0}">
                          {{ petaRisikoAfter(row, col).map((r, ir) => ir+1).join(',')}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- Peta Risiko - After End -->
      </b-row>
    </form>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { companiesActions, filesActions, fleetsActions, miActions } from '@src/Utils/helper'
import moment from 'moment'
import StepNavigation from '@src/views/MI/component/StepNavigation'
import StepWizard from '@src/views/MI/component/StepWizard'

export default {
  name: 'EnterpriseRiskManagement',
  components: { StepNavigation, StepWizard },
  async mounted () {
    xray.index()
    if (this.$route.query.formId) {
      await this.fetchCompanies()
      await this.fetchDepartments()
      await this.fetchMasterErm()
      await this.fetchRaciMatrix()
      await this.fetchMi()
    }

    if (this.$route.query.preview === 'true' || this.$route.query.preview === true) {
      this.isPreview = true
    }
  },
  data () {
    return {
      permission_user:JSON.parse(localStorage.getItem('profile')).role.roles,
      isPreview: false,
      detailMi: null,
      raciMatrix: null,
      form: {
        _saving: false,
        status: false,
        date_range: [],
        assessor_fullname: '',
        assessor_company_id: '',
        assessor_erm_credential: '',
        assessor_department_id: '',
        target_department_id: [],
        target_location: '',
        target_pic: '',
        target_from: '',
        target_to: '',
        sasaran_smart: [],
        penamaan_resiko: [],
        prinsip_manajemen_resiko: [],
        kepemimpinan_komitmen: [],
        analisa_pemangku_jabatan: [],
        integrasi_sasaran_smart: [],
        evaluasi: [],
        perbaikan_sinambung: [],
        komunikasi_konsultasi: [],
        identifikasi_resiko: [],
        analisis_evaluasi_resiko: [],
        kriteria_konsekuensi: [],
        kriteria_kemungkinan: [],
        kriteria_evaluasi: [],
        efektifitas_kendali: [],
        perlakuan_resiko: [],
        raci_matrix: []
      },
      listRisk: [
        { value: 'FINANCIAL', text: 'Financial Risk' },
        { value: 'NONFINANCIAL', text: 'Non Financial Risk' }
      ],
      catRisk: [
        { value: 'TECHNICAL', text: 'Technical Risk' },
        { value: 'OPERATIONAL', text: 'Operational Risk' }
      ],
      statusImplementasi: [
        { value: 'BERJALAN', text: 'Berjalan' },
        { value: 'TIDAKBERJALAN', text: 'Tidak Berjalan' },
        { value: 'SEBAGIAN', text: 'Sebagian' }
      ],
      userLevel: [
        { value: 'SITE MANAGER', text: 'Site Manager' },
        { value: 'SUPERINTENDENT', text: 'Superintendent' },
        { value: 'PE', text: 'Port Engineer' },
        { value: 'PC', text: 'Port Captain' },
        { value: 'ETC', text: 'etc..' }
      ],
      statusKesesuaian: [
        { value: 'INTERNAL', text: 'Internal' },
        { value: 'EXTERNAL', text: 'External' },
        { value: 'NA', text: 'N/A' },
        { value: 'TBA', text: 'TBA' }
      ],
      dukunganAncaman: [
        { value: 'TINGGI', text: 'Tinggi' },
        { value: 'RENDAH', text: 'Rendah' }
      ],
      listStrategi: [
        { value: 'KEEP INFORMED', text: 'Keep Informed' },
        { value: 'KEEP SATIFIED', text: 'Keep Statisfied' }
      ],
      frekuensi: [
        { value: 'HARIAN', text: 'Harian' },
        { value: 'MINGGUAN', text: 'Mingguan' },
        { value: 'BULANAN', text: 'Bulanan' },
        { value: '3BULANAN', text: '3 Bulanan' },
        { value: '6BULANAN', text: '6 Bulanan' },
        { value: 'TAHUNAN', text: 'Tahunan' }
      ],
      number: [
        { value: '1', text: '1' },
        { value: '2', text: '2' },
        { value: '3', text: '3' },
        { value: '4', text: '4' },
        { value: '5', text: '5' }
      ],
      evaluasiResiko: [
        { value: '1', text: 'Tolak' },
        { value: '2', text: 'Turunkan' },
        { value: '3', text: 'Berbagi' },
        { value: '4', text: 'Terima' }
      ],
      statusYesNo: [
        { value: '1', text: 'Yes' },
        { value: '2', text: 'No' }
      ],

      companies: [],
      departments: []
    }
  },
  watch: {
    isPreview (value) {
      const inputs = document.getElementById('erm-form').querySelectorAll('input,select')
      if (inputs) {
        for (const input of inputs) {
          input.classList.remove('transparent-disabled')
          input.removeAttribute('disabled')
        }

        if (typeof value === 'boolean') {
          if (value) {
            for (const input of inputs) {
              input.classList.add('transparent-disabled')
              input.setAttribute('disabled', 'disabled')
            }
          }
        }
      }
    }
  },
  computed:{
    userPermission(){
      if(['SUPER ADMIN', 'MARINE INSURANCE', 'MARINE INSURANCE ADMIN', 'MARINE INSURANCE MANAGER'].includes(this.permission_user)){
        return true
      } else{
        return false
      } 
    }
  },
  methods: {
    ...fleetsActions,
    ...companiesActions,
    ...miActions,
    ...filesActions,

    async fetchMi () {
      const { data, status } = await this.fetchDetailMi({
        id: this.$route.query.formId,
        with: 'vehicle,type_coverage,type_loss,company,documents,company_claimant,status,creator,histories,evaluations,erm'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(`Oops!`, 'Ada Yang Salah', 'error')
        this.form._saving = false
        return
      }

      this.detailMi = data

      if (data.evaluations) {
        data.evaluations.forEach(evaluation => {
          this.form.evaluasi.push({
            kegiatan: evaluation.activity_stage,
            sasaran: evaluation.milestone,
            uraian: evaluation.job_description,
            keberhasilan: evaluation.success_indicator,
            evaluasi: evaluation.evaluation_result,
            frekuensi: evaluation.frequency,
            remarks: ''
          })
        })
      }

      if (data.erm) {
        const erm = data.erm
        this.form.assessor_fullname = erm.assessor_fullname
        this.form.assessor_company_id = erm.assessor_company_id
        this.form.assessor_erm_credential = erm.assessor_erm_credential
        this.form.assessor_department_id = erm.assessor_department_id
        this.form.target_pic = erm.target_pic
        this.form.target_department_id = erm.target_department_id
        this.form.target_location = erm.target_location
        this.form.target_from = erm.target_from
        this.form.target_to = erm.target_to
        if (erm.target_from && erm.target_to) {
          this.form.date_range = [moment(erm.target_from).format('YYYY-MM-DD'), moment(erm.target_to).format('YYYY-MM-DD')]
        }
        this.form.sasaran_smart = erm.sasaran_smart.map(array => {
          if (array.timebound) {
            array.timebound = moment(array.timebound).format('YYYY-MM-DD')
          }
          return { ...array }
        })
        this.form.penamaan_resiko = erm.penamaan_resiko.map(array => {
          return { ...array }
        })
        this.form.prinsip_manajemen_resiko = erm.prinsip_manajemen_resiko.map(array => {
          return { ...array }
        })
        this.form.kepemimpinan_komitmen = erm.kepemimpinan_komitmen.map(array => {
          return { ...array }
        })
        this.form.analisa_pemangku_jabatan = erm.analisa_pemangku_jabatan.map(array => {
          return { ...array }
        })
        this.form.integrasi_sasaran_smart = erm.integrasi_sasaran_smart.map(array => {
          return { ...array }
        })
        this.form.evaluasi = erm.evaluasi.map(array => {
          return { ...array }
        })
        this.form.perbaikan_sinambung = erm.perbaikan_sinambung.map(array => {
          return { ...array }
        })
        this.form.komunikasi_konsultasi = erm.komunikasi_konsultasi.map(array => {
          return { ...array }
        })
        this.form.identifikasi_resiko = erm.identifikasi_resiko.map(array => {
          return { ...array }
        })
        this.form.analisis_evaluasi_resiko = erm.analisis_evaluasi_resiko.map(array => {
          return { ...array }
        })
        this.form.perlakuan_resiko = erm.perlakuan_resiko.map(array => {
          return { ...array }
        })
        this.form.raci_matrix = erm.raci_matrix.map(array => {
          return { ...array }
        })

        if (this.form.raci_matrix.length === 0) {
          await this.fetchRaciMatrix()
        }
      }
    },
    async fetchCompanies () {
      let res = await this.getCompanies()
      this.companies = res.data
    },
    async fetchDepartments () {
      this.departments = []
      let res = await this.getDepartments({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.departments.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
    async fetchMasterErm () {
      let self = this
      let res = await this.getMasterAllErm({})

      if (res.status) {
        if (res.data) {
          if (res.data.prinsip_manajemen_resiko) {
            self.form.prinsip_manajemen_resiko = []
            res.data.prinsip_manajemen_resiko.map((result) => {
              self.form.prinsip_manajemen_resiko.push({
                prinsip: result.risiko,
                definisi: result.definisi,
                implementasi: '',
                status: '' // SEBAGIAN|TIDAK BERJALAN
              })
            })
          }
          if (res.data.perbaikan_sinambung) {
            self.form.perbaikan_sinambung = []
            res.data.perbaikan_sinambung.map((result) => {
              self.form.perbaikan_sinambung.push({
                tahapan: result.tahapan,
                sasaran: result.sasaran,
                uraian: result.uraian,
                keberhasilan: '',
                evaluasi: '',
                frekuensi: '',
                pelaksana: '' // SITE MANAGER|SUPERINTENDENT|PE|PC|ETC
              })
            })
          }
          if (res.data.kriteria_konsekuensi) {
            self.form.kriteria_konsekuensi = []
            res.data.kriteria_konsekuensi.map((result) => {
              self.form.kriteria_konsekuensi.push(result)
            })
          }
          if (res.data.kriteria_kemungkinan) {
            self.form.kriteria_kemungkinan = []
            res.data.kriteria_kemungkinan.map((result) => {
              self.form.kriteria_kemungkinan.push(result)
            })
          }
          if (res.data.kriteria_evaluasi) {
            self.form.kriteria_evaluasi = []
            res.data.kriteria_evaluasi.map((result) => {
              self.form.kriteria_evaluasi.push(result)
            })
          }
          if (res.data.efektifitas_kendali) {
            self.form.efektifitas_kendali = []
            res.data.efektifitas_kendali.map((result) => {
              self.form.efektifitas_kendali.push(result)
            })
          }
        }
      }
    },
    async fetchRaciMatrix () {
      let { data, status } = await this.getRaciMatrix()

      if (status === 'success') {
        if (data) {
          this.raciMatrix = data
          this.form.raci_matrix = []
          if (data.rows && data.columns) {
            data.rows.forEach(row => {
              data.columns.forEach(column => {
                this.form.raci_matrix.push({
                  raci_matrix_id: row.id,
                  department_id: column.id,
                  value: []
                })
              })
            })
          }
        }
      }
    },
    async onSave () {
      let payload = this.form
      payload.assessor_company_id = parseInt(payload.assessor_company_id)
      payload.assessor_department_id = parseInt(payload.assessor_department_id)
      payload.target_department_id = [parseInt(payload.target_department_id)]
      payload.perlakuan_resiko = payload.perlakuan_resiko?.map(val => {
        return {
          ...val,
          total_eksposur: val.total_eksposur?.toString()
        }
      })
      this.form._saving = true

      const { status } = await this.saveERM({
        form_id: this.$route.query.formId,
        data: payload
      })

      if (status === 422) {
        this.$swal(`Oops!`, 'Semua Field Wajib Diisi', 'error')
        this.form._saving = false
        return
      }

      if ([500, 404, 403].includes(status)) {
        this.$swal(`Oops!`, 'Ada Yang Salah', 'error')
        this.form._saving = false
        return
      }

      this.$swal(`Tersimpan`, 'Data Tersimpan', 'success')

      setTimeout(() => {
        this.$router.push('/mi/list-initial')
      }, 1500)

      this.form._saving = false
    },
    async handleFileRemoteUpload (event) {
      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      const { data, status } = await this.upload(formData)

      if (status === 'error') {
        return {
          status
        }
      }

      return {
        data, status
      }
    },
    onChangeDate () {
      this.form.target_from = this.form.date_range
        ? moment(this.form.date_range[0]).format('YYYY-MM-DD')
        : ''
      this.form.target_to = this.form.date_range
        ? moment(this.form.date_range[1]).format('YYYY-MM-DD')
        : ''
    },
    addSasaranSmart () {
      this.form.sasaran_smart.push({
        sasaran: '',
        spesific: '',
        measurable: '',
        achievable: '',
        relevant: '',
        timebound: ''
      })

      this.addIntegrasiSasaranSmart()
      this.addPenamaanResiko()
      this.addIdentifikasiResiko()
      this.addAnalisaEvaluasiResiko()
      this.addPerlakuanResiko()
    },
    removeSasaranSmart (index) {
      this.form.sasaran_smart.splice(index, 1)
      this.removeIntegrasiSasaranSmart(index)
    },
    onSasaranSmartChanged (index) {
      this.form.integrasi_sasaran_smart[index].kesesuaian = this.form.sasaran_smart[index].sasaran
      this.form.integrasi_sasaran_smart[index].deskripsi = this.form.sasaran_smart[index].spesific

      this.form.penamaan_resiko[index].sasaran = this.form.sasaran_smart[index].sasaran

      this.form.identifikasi_resiko[index].sasaran = this.form.sasaran_smart[index].sasaran

      this.form.analisis_evaluasi_resiko[index].peristiwa = this.form.sasaran_smart[index].sasaran

      this.form.perlakuan_resiko[index].peristiwa = this.form.sasaran_smart[index].sasaran
    },
    addPenamaanResiko () {
      this.form.penamaan_resiko.push({
        sasaran: '',
        risiko: '', // FINANCIAL RISK|NON FINANCIAL RISK
        peristiwa: '',
        sebab: '',
        konsekuensi: '',
        kategori_risiko: '', // TECHNICAL RISK|OPERATIONAL RISK
        tindakan: ''
      })
    },
    onPenamaanResikoChanged (index) {
      this.form.identifikasi_resiko[index].peristiwa = this.form.penamaan_resiko[index].peristiwa
      this.form.identifikasi_resiko[index].penyebab = this.form.penamaan_resiko[index].sebab
      this.form.identifikasi_resiko[index].akibat = this.form.penamaan_resiko[index].konsekuensi

      this.form.perlakuan_resiko[index].tindakan = this.form.penamaan_resiko[index].tindakan
    },
    removePenamaanResiko (index) {
      this.form.penamaan_resiko.splice(index, 1)
    },
    addKepemimpinanKomitment () {
      this.form.kepemimpinan_komitmen.push({
        pimpinan: '', // SITE MANAGER|SUPERINTENDENT|PE|PC|ETC
        tugas: '',
        dokumen: '', // base64 arrays
        remarks: ''
      })
    },
    removeKepemimpinanKomitment (index) {
      this.form.kepemimpinan_komitmen.splice(index, 1)
    },
    async onKepemimpinanKomitmentFileChanged (index, event) {
      const { data, status } = await this.handleFileRemoteUpload(event)

      if (status === 'success') {
        this.form.kepemimpinan_komitmen[index].dokumen = data.fileName

        return true
      }

      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
        this.form.kepemimpinan_komitmen[index].dokumen = reader.result
      }
    },
    addAnalisaPemangkuJabatan () {
      this.form.analisa_pemangku_jabatan.push({
        status: '',
        nama: '', // SITE MANAGER|SUPERINTENDENT|PE|PC|ETC
        tantangan: '', // TINGGI|RENDAH
        dukungan: '', // TINGGI|RENDAH
        ancaman: '', // TINGGI/RENDAH
        strategi: '', // KEEP INFORMED|KEEP SATIFIED
        tindakan: '',
        remarks: ''
      })
    },
    removeAnalisaPemangkuJabatan (index) {
      this.form.analisa_pemangku_jabatan.splice(index, 1)
    },
    addKomunikasiKonsultasi () {
      this.form.komunikasi_konsultasi.push({
        pemangku: '', // SITE MANAGER|SUPERINTENDENT|PE|PC|ETC
        konten: '',
        tujuan: '',
        komunikator: '',
        waktu: '',
        frekuensi: '', // HARIAN|MINGGUAN|BULANAN|3 BULANAN|6 BULANAN|TAHUNAN
        metode: '',
        pic: '' // SITE MANAGER|SUPERINTENDENT|PE|PC|ETC
      })
    },
    removeKomunikasiKonsultasi (index) {
      this.form.komunikasi_konsultasi.splice(index, 1)
    },
    addIntegrasiSasaranSmart () {
      this.form.integrasi_sasaran_smart.push({
        kesesuaian: '',
        deskripsi: '',
        dokumen: '',
        remarks: ''
      })
    },
    removeIntegrasiSasaranSmart (index) {
      this.form.integrasi_sasaran_smart.splice(index, 1)
    },
    async onIntegrasiSasaranSmartFileChanged (index, event) {
      const { data, status } = await this.handleFileRemoteUpload(event)

      if (status === 'success') {
        this.form.integrasi_sasaran_smart[index].dokumen = data.fileName

        return true
      }

      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
        this.form.integrasi_sasaran_smart[index].dokumen = reader.result
      }
    },
    addEvaluasi () {
      this.form.evaluasi.push({
        kegiatan: '',
        sasaran: '',
        uraian: '',
        keberhasilan: '',
        evaluasi: '',
        frekuensi: '',
        remarks: ''
      })
    },
    removeEvaluasi (index) {
      this.form.evaluasi.splice(index, 1)
    },
    addIdentifikasiResiko () {
      this.form.identifikasi_resiko.push({
        sasaran: '',
        peristiwa: '',
        penyebab: '',
        akibat: ''
      })
    },
    removeIdentifikasiResiko (index) {
      this.form.identifikasi_resiko.splice(index, 1)
    },
    addAnalisaEvaluasiResiko () {
      this.form.analisis_evaluasi_resiko.push({
        peristiwa: '',
        efektifitas: '',
        konsekuensi: '',
        kemungkinan: '',
        eksposur: '',
        tingkat_risiko: '',
        prioritas: '',
        evaluasi: ''
      })
    },
    onAnalisaEvaluasiResikoChanged (index) {
      this.form.perlakuan_resiko[index].total_eksposur = this.form.analisis_evaluasi_resiko[index].eksposur
      this.form.perlakuan_resiko[index].evaluasi_resiko = this.form.analisis_evaluasi_resiko[index].evaluasi
    },
    removeAnalisaEvaluasiResiko (index) {
      this.form.analisis_evaluasi_resiko.splice(index, 1)
    },
    addPerlakuanResiko () {
      this.form.perlakuan_resiko.push({
        peristiwa: '',
        total_eksposur: '',
        evaluasi_resiko: '',
        tindakan: '',
        frekuensi_pemantauan: 'HARIAN',
        frekuensi_pelaporan: 'HARIAN',
        konsekuensi_residual: '',
        kemungkinan_residual: '',
        total_eksposur_residual: ''
      })
    },
    removePerlakuanResiko (index) {
      this.form.perlakuan_resiko.splice(index, 1)
    },
    onRaciMatrixChanged (matrixId, departmentId, value) {
      const form = this.form.raci_matrix.find(matrix => matrix.raci_matrix_id === matrixId && matrix.department_id === departmentId)
      if (form) {
        form.value = value
        return form
      }

      return null
    },
    raxiMatrixFormValue (matrixId, departmentId) {
      const form = this.form.raci_matrix.find(matrix => matrix.raci_matrix_id === matrixId && matrix.department_id === departmentId)
      if (form) {
        return form.value
      }

      return null
    },
    petaRisikoBefore (likelihoodNumber, impactNumber) {
      return this.form.analisis_evaluasi_resiko.filter(row => Number(row.kemungkinan) === Number(likelihoodNumber) && Number(row.konsekuensi) === Number(impactNumber))
    },
    petaRisikoAfter (likelihoodNumber, impactNumber) {
      return this.form.perlakuan_resiko.filter(row => Number(row.kemungkinan_residual) === Number(likelihoodNumber) && Number(row.konsekuensi_residual) === Number(impactNumber))
    }
  }
}
</script>
