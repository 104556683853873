<template>
    <b-container fluid>
      <!-- Tab -->
      <b-row>
        <b-col lg="12">
          <iq-card body-class="p-0">
            <template v-slot:body>
              <div class="iq-edit-list">
                <tab-nav :pills="true" class="iq-edit-profile d-flex">
                  <tab-nav-items
                    class="col-md-4 p-0"
                    :active="true"
                    href="#certificate-list"
                    title="MASTER CERTIFICATE LIST"
                  />
                  <tab-nav-items
                    class="col-md-4 p-0"
                    :active="false"
                    href="#reason-sign-off"
                    title="MASTER REASON SIGN OFF"
                  />
                  <tab-nav-items
                    class="col-md-4 p-0"
                    :active="false"
                    href="#positions"
                    title="MASTER POSITIONS"
                  />
                </tab-nav>
              </div>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <!-- End Tab -->
      <div id="pills-tabContent-2" class="tab-content">
        <tab-content-item :active="true" id="certificate-list">
          <b-row>
            <b-col lg="12">
              <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title text-primary"
                    ><strong>MASTER CERTIFICATE LIST</strong></h4
                  >
                </template>
                <template v-slot:body>
                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="Search Master Certificate List:"
                        label-for="kind-fleet"
                      >
                        <b-form-input
                          id="fleet_name"
                          class="w-100"
                          type="text"
                          placeholder="Search..."
                          v-model="certificate.params.search"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                      <b-form-group label="Search" label-for="button_search">
                        <b-button
                          variant="primary"
                          class="ml-2"
                          @click="getCertificateList(1)"
                          ><i class="fa fa-search"></i
                        ></b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-col>
            <b-col :sm="!menuPermission('update') && !menuPermission('add') ? '12' : '8'">
              <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">List Master Certificate List</h4>
                </template>
                <template v-slot:body>
                  <div v-if="certificate._loading" class="text-center my-5">
                    <b-spinner type="grow" label="Spinning"></b-spinner>
                  </div>
                  <template v-else>
                    <div class="table-responsive" v-if="certificate.data && certificate.data.length > 0">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th width="10%">No</th>
                            <th width="25%">Name</th>
                            <th width="25%">Alias</th>
                            <th width="15%">Type</th>
                            <th width="10%">reminder</th>
                            <th width="10%">Unlimited</th>
                            <th width="15%">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(p, i) in certificate.data" :key="`p-${i}`">
                            <td class="text-center">{{ certificate.meta.currentPage > 1 ? certificate.meta.perPage + i + 1 : i + 1 }}</td>
                            <td>{{ p.name }}</td>
                            <td>{{ p.alias ? p.alias : '-' }}</td>
                            <td>{{ p.type ? p.type : '-' }}</td>
                            <td>{{ p.is_reminder === 1 ? 'Active' : 'Inactive' }}</td>
                            <td>{{ p.unlimited === 1 ? 'Yes' : 'No' }}</td>
                            <td>
                              <b-button
                                v-if="menuPermission('update')"
                                variant="success"
                                size="sm"
                                class="mr-2"
                                @click="onEditCertificate(p)"
                                ><i class="fa fa-edit"></i
                              ></b-button>
                              <b-button
                                v-if="menuPermission('delete')"
                                variant="danger"
                                size="sm"
                                @click="onDeleteCertificate(p)"
                              >
                                <i
                                  v-if="!loading_delete_certificate"
                                  class="fa fa-trash"
                                ></i>
                                <template v-else>
                                  <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  Deleting...
                                </template>
                              </b-button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div v-if="certificate.data && certificate.data.length > 0">
                      <b-pagination
                        v-model="certificate.meta.currentPage"
                        :total-rows="certificate.meta.total"
                        @change="getCertificateList"
                        :per-page="certificate.meta.perPage"
                        first-number
                        align="center"
                      ></b-pagination>
                    </div>
                    <p v-else class="text-center my-4">No certificate data found.</p>
                  </template>
                </template>
              </iq-card>
            </b-col>
            <b-col sm="4"
              v-if="menuPermission('update') || menuPermission('add')">
              <iq-card
                :class="{
                  'border border-danger shadow-lg': form_certificate_edit_mode,
                }"
              >
                <template v-slot:headerTitle>
                  <h4 class="card-title"
                    >{{ form_certificate_edit_mode ? 'Edit' : 'Add' }} Certificate</h4
                  >
                </template>
                <template v-slot:body>
                  <b-form @submit.prevent="submitFormCertificate">
                    <b-form-group
                      label="Name Certificate"
                      label-for="name_certificate"
                    >
                      <b-form-input
                        id="name_certificate"
                        type="text"
                        placeholder="Type text..."
                        v-model="form.certificate.name"
                      ></b-form-input>
                    </b-form-group>
  
                    <b-form-group
                      label="Alias Certificate"
                      label-for="alias_certificate"
                    >
                      <b-form-input
                        id="alias_certificate"
                        type="text"
                        placeholder="Type text..."
                        maxlength="30"
                        v-model="form.certificate.alias"
                        max="20"
                      ></b-form-input>
                      <small class="text-muted">Note: max alias character 20</small>
                    </b-form-group>
  
                    <b-form-group label="Type Certificate" label-for="type_certificate">
                        <b-form-select
                        id="type_certificate"
                        plain
                        v-model="form.certificate.type"
                        size="sm"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="null">Select Type Certificate</b-form-select-option>
                            <b-form-select-option :value="'competency'">Competency</b-form-select-option>
                            <b-form-select-option :value="'proficiency'">Proficiency</b-form-select-option>
                            <b-form-select-option :value="'general'">General</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
  
                    <b-form-group label="Is Reminder" label-for="is_reminder">
                      <b-form-checkbox
                        id="is_reminder"
                        v-model="form.certificate.is_reminder"
                        name="check-button"
                        switch
                        inline
                      >
                        {{ form.certificate.is_reminder ? 'Active' : 'Inactive' }}
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group label="Unlimited" label-for="unlimited">
                      <b-form-checkbox
                        id="unlimited"
                        v-model="form.certificate.unlimited"
                        name="check-button"
                        switch
                        inline
                      >
                        {{ form.certificate.unlimited ? 'Yes' : 'No' }}
                      </b-form-checkbox>
                    </b-form-group>
                    <hr />
                    <b-form-group class="text-right">
                      <b-button type="submit" variant="primary">
                        <span v-if="!loading_form_certificate">{{
                          form_certificate_edit_mode || !menuPermission('add') ? 'Save' : 'Add'
                        }}</span>
                        <template v-else>
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Saving...
                        </template>
                      </b-button>
                      <b-button
                        type="button"
                        variant="danger"
                        class="iq-bg-danger ml-3"
                        @click.prevent="resetFormCertificate"
                        >Cancel</b-button
                      >
                    </b-form-group>
                  </b-form>
                </template>
              </iq-card>
            </b-col>
          </b-row>
        </tab-content-item>
        <tab-content-item :active="false" id="reason-sign-off">
          <b-row>
            <b-col lg="12">
              <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title text-primary"
                    ><strong>MASTER REASON SIGN OFF</strong></h4
                  >
                </template>
                <template v-slot:body>
                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="Search Data Reason Sign Off:"
                        label-for="kind-fleet"
                      >
                        <b-form-input
                          id="fleet_name"
                          class="w-100"
                          type="text"
                          placeholder="Search..."
                          v-model="reasons.params.search"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                      <b-form-group label="Search" label-for="button_search">
                        <b-button
                          variant="primary"
                          class="ml-2"
                          @click="getReasonsSignOff(1)"
                          ><i class="fa fa-search"></i
                        ></b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-col>
            <b-col :sm="!menuPermission('update') && !menuPermission('add') ? '12' : '8'">
              <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">List Master Data Reason Sign Off</h4>
                </template>
                <template v-slot:body>
                  <div v-if="reasons._loading" class="text-center my-5">
                    <b-spinner type="grow" label="Spinning"></b-spinner>
                  </div>
                  <template v-else>
                    <div class="table-responsive" v-if="reasons.data && reasons.data.length > 0">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th width="10%">No</th>
                            <th width="25%">Name</th>
                            <th width="25%">Alias</th>
                            <th width="25%">blacklist</th>
                            <th width="10%">Status</th>
                            <th width="15%">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(r, i) in reasons.data" :key="`r-${i}`">
                            <td class="text-center">{{ reasons.meta.currentPage > 1 ? reasons.meta.perPage + i + 1 : i + 1 }}</td>
                            <td>{{ r.name }}</td>
                            <td>{{ r.alias }}</td>
                            <td>{{ r.is_blacklist === 1 ? 'Yes' : 'No' }}</td>
                            <td>{{ r.active === 1 ? 'Active' : 'Inactive' }}</td>
                            <td>
                              <b-button
                                v-if="menuPermission('update')"
                                variant="success"
                                size="sm"
                                class="mr-2"
                                @click="onEditReason(r)"
                                ><i class="fa fa-edit"></i
                              ></b-button>
                              <b-button
                                v-if="menuPermission('delete')"
                                variant="danger"
                                size="sm"
                                @click="onDeleteReason(r)"
                                ><i class="fa fa-trash"></i
                              ></b-button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div v-if="reasons.data && reasons.data.length > 0">
                      <b-pagination
                        v-model="reasons.meta.currentPage"
                        :total-rows="reasons.meta.total"
                        @change="getReasonsSignOff"
                        :per-page="reasons.meta.perPage"
                        first-number
                        align="center"
                      ></b-pagination>
                    </div>
                    <p v-else class="text-center my-4">No reasons data found.</p>
                  </template>
                </template>
              </iq-card>
            </b-col>
            <b-col sm="4"
              v-if="menuPermission('update') || menuPermission('add')">
              <iq-card
                :class="{
                  'border border-danger shadow-lg': form_reason_edit_mode,
                }"
              >
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{ form_reason_edit_mode ? 'Edit' : 'Add' }} Reason Sign Off</h4>
                </template>
                <template v-slot:body>
                  <b-form @submit.prevent="submitFormReason">
                    <b-form-group label="Reason Name" label-for="reason_name">
                      <b-form-input
                        id="reason_name"
                        type="text"
                        placeholder="Reason Name"
                        v-model="form.reason.name"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Reason Alias" label-for="reason_alias">
                      <b-form-input
                        id="reason_alias"
                        type="text"
                        placeholder="Reason Alias"
                        v-model="form.reason.alias"
                        max="20"
                      ></b-form-input>
                      <small class="text-muted">Note: max alias character 20</small>
                    </b-form-group>

                    <b-form-group label="Blacklist" label-for="blacklist_reason">
                      <b-form-checkbox
                        id="blacklist_reason"
                        v-model="form.reason.is_blacklist"
                        name="check-button"
                        switch
                        inline
                      >
                        {{ form.reason.is_blacklist ? 'Yes' : 'No' }}
                      </b-form-checkbox>
                    </b-form-group>
  
                    <b-form-group label="Status" label-for="status_reason">
                      <b-form-checkbox
                        id="status_reason"
                        v-model="form.reason.active"
                        name="check-button"
                        switch
                        inline
                      >
                        {{ form.reason.active ? 'Active' : 'Inactive' }}
                      </b-form-checkbox>
                    </b-form-group>
                    <hr />
                    <b-form-group class="text-right">
                      <b-button type="submit" variant="primary">
                        <span v-if="!loading_form_reason">{{
                          form_reason_edit_mode || !menuPermission('add') ? 'Save' : 'Add'
                        }}</span>
                        <template v-else>
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Saving...
                        </template>
                      </b-button>
                      <b-button
                        type="submit"
                        variant="none"
                        class="iq-bg-danger ml-3"
                        @click.prevent="resetFormReason"
                        >Cancel</b-button
                      >
                    </b-form-group>
                  </b-form>
                </template>
              </iq-card>
            </b-col>
          </b-row>
        </tab-content-item>
        <tab-content-item :active="false" id="positions">
          <b-row>
            <b-col lg="12">
              <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title text-primary"
                    ><strong>MASTER POSITIONS</strong></h4
                  >
                </template>
                <template v-slot:body>
                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="Search Data Positions:"
                        label-for="kind-positions"
                      >
                        <b-form-input
                          id="fleet_name"
                          class="w-100"
                          type="text"
                          placeholder="Search..."
                          v-model="positions.params.search"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                      <b-form-group label="Search" label-for="button_search">
                        <b-button
                          variant="primary"
                          class="ml-2"
                          @click="getPositions(1)"
                          ><i class="fa fa-search"></i
                        ></b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-col>
            <b-col :sm="!menuPermission('update') && !menuPermission('add') ? '12' : '8'">
              <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">List Master Data Positions</h4>
                </template>
                <template v-slot:body>
                  <div v-if="reasons._loading" class="text-center my-5">
                    <b-spinner type="grow" label="Spinning"></b-spinner>
                  </div>
                  <template v-else>
                    <div class="table-responsive" v-if="positions.data && positions.data.length > 0">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th width="10%">No</th>
                            <th width="25%">Name</th>
                            <th width="25%">Alias</th>
                            <th width="25%">Type Position</th>
                            <th width="10%">Class Type</th>
                            <th width="15%">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(r, i) in positions.data" :key="`r-${i}`">
                            <td class="text-center">{{ positions.meta.currentPage > 1 ? positions.meta.perPage + i + 1 : i + 1 }}</td>
                            <td>{{ r.name }}</td>
                            <td>{{ r.alias }}</td>
                            <td>{{ r?.type_position?.name }}</td>
                            <td>{{ r.class_type }}</td>
                            <td>
                              <b-button
                                v-if="menuPermission('update')"
                                variant="success"
                                size="sm"
                                class="mr-2"
                                @click="onEditPosition(r)"
                                ><i class="fa fa-edit"></i
                              ></b-button>
                              <b-button
                                v-if="menuPermission('delete')"
                                variant="danger"
                                size="sm"
                                @click="onDeletePosition(r)"
                                ><i class="fa fa-trash"></i
                              ></b-button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div v-if="positions.data && positions.data.length > 0">
                      <b-pagination
                        v-model="positions.meta.currentPage"
                        :total-rows="positions.meta.total"
                        @change="getPositions"
                        :per-page="positions.meta.perPage"
                        first-number
                        align="center"
                      ></b-pagination>
                    </div>
                    <p v-else class="text-center my-4">No master data positions found.</p>
                  </template>
                </template>
              </iq-card>
            </b-col>
            <b-col sm="4"
              v-if="menuPermission('update') || menuPermission('add')">
              <iq-card
                :class="{
                  'border border-danger shadow-lg': form_position_edit_mode,
                }"
              >
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{ form_position_edit_mode ? 'Edit' : 'Add' }} Position</h4>
                </template>
                <template v-slot:body>
                  <b-form @submit.prevent="submitFormPosition">
                    <b-form-group label="Position Name" label-for="position_name">
                      <b-form-input
                        id="position_name"
                        type="text"
                        placeholder="Position Name"
                        v-model="form.position.name"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Position Alias" label-for="position_alias">
                      <b-form-input
                        id="position_alias"
                        type="text"
                        placeholder="Position Alias..."
                        v-model="form.position.alias"
                        max="20"
                      ></b-form-input>
                      <small class="text-muted">Note: max alias character 20</small>
                    </b-form-group>
                    <b-form-group label="Type Position" label-for="type_position_id">
                      <b-form-select
                          plain
                          placeholder="Select Type Position..."
                          v-model="form.position.type_position_id"
                          :options="list_type_position"
                          required
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="null">Select Type Position</b-form-select-option>
                          </template>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group label="Position Class Type" label-for="class_type">
                      <b-form-select
                          plain
                          placeholder="Position Class Type..."
                          v-model="form.position.class_type"
                          required
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="''">Select Class Position</b-form-select-option>
                            <b-form-select-option value="perwira">Perwira</b-form-select-option>
                            <b-form-select-option value="non_perwira">Non Perwira</b-form-select-option>
                            <b-form-select-option value="none">None</b-form-select-option>
                          </template>
                        </b-form-select>
                    </b-form-group>

                   <!--  <b-form-group label="Status" label-for="status_reason">
                      <b-form-checkbox
                        id="status_reason"
                        v-model="form.reason.active"
                        name="check-button"
                        switch
                        inline
                      >
                        {{ form.reason.active ? 'Active' : 'Inactive' }}
                      </b-form-checkbox>
                    </b-form-group> -->
                    <hr />
                    <b-form-group class="text-right">
                      <b-button type="submit" variant="primary">
                        <span v-if="!loading_form_position">{{
                          form_position_edit_mode || !menuPermission('add') ? 'Save' : 'Add'
                        }}</span>
                        <template v-else>
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Saving...
                        </template>
                      </b-button>
                      <b-button
                        type="submit"
                        variant="none"
                        class="iq-bg-danger ml-3"
                        @click.prevent="resetFormPosition"
                        >Cancel</b-button
                      >
                    </b-form-group>
                  </b-form>
                </template>
              </iq-card>
            </b-col>
          </b-row>
        </tab-content-item>
      </div>
    </b-container>
  </template>
  
  <script>
  import {
    projectsActions,
    fleetsActions,
    reasonsActions,
    crewingActions
  } from '@src/Utils/helper'
  import _ from 'lodash'
  
  export default {
    name: 'MasterCertificateReasons',
    data () {
      return {
        list_type_position:[],
        certificate: {
          data: [],
          _loading: true,
          detail: null,
          params: {
            name: '',
            active: true,
            perPage: 50,
            page: 1
          },
          meta: {
            perPage: 0,
            currentPage: 0,
            total: 0,
            totalPage: 0
          }
        },
        reasons: {
          data: [],
          _loading: true,
          detail: null,
          params: {
            name: '',
            active: true,
            perPage: 50,
            page: 1
          },
          meta: {
            perPage: 0,
            currentPage: 0,
            total: 0,
            totalPage: 0
          }
        },
        positions: {
          data: [],
          _loading: true,
          detail: null,
          params: {
            name: '',
            active: true,
            perPage: 50,
            page: 1
          },
          meta: {
            perPage: 0,
            currentPage: 0,
            total: 0,
            totalPage: 0
          }
        },
        certificate_form: [],
        form_certificate_edit_mode: false,
        loading_export_certificate: false,
        loading_delete_certificate: false,
        loading_form_certificate: false,

        form_reason_edit_mode: false,
        loading_export_reason: false,
        loading_delete_reason: false,
        loading_form_reason: false,

        form_position_edit_mode: false,
        loading_export_position: false,
        loading_delete_position: false,
        loading_form_position: false,

        form: {
         selected_certificate_id:null,
         selected_reason_id:null,
         selected_position_id:null,
         certificate:{
            name:'',
            alias:'',
            type:'',
            is_reminder:0,
            unlimited:0,
         },
         reason:{
            name:'',
            alias:'',
            is_blacklist:0,
            active:0,
         },
         position:{
            name:'',
            alias:'',
            type_position_id:null,
            class_type:'',
         }
        },
      }
    },
    created () {
      this.getCertificateList = _.debounce(this.getCertificateList, 500)
      this.getReasonsSignOff = _.debounce(this.getReasonsSignOff, 500)
      this.getPositions = _.debounce(this.getPositions, 500)
    },
    async mounted () {
      await  this.getTypePositionList()
      await  this.getCertificateList()
      await  this.getReasonsSignOff()
      await  this.getPositions()
    },
    methods: {
      ...projectsActions,
      ...fleetsActions,
      ...reasonsActions,
      ...crewingActions,
      async getTypePositionList () {
        let res = await this.fetchTypePositionList()

        if(res.status){
          let tempArr = []
          res.data.data.map((val) => {
            tempArr.push({
              value:val.id,
              text:val.name
            })
          })
          this.list_type_position = tempArr
        }
      }, 
      async getCertificateList (page) {
        this.certificate.params.page = page || 1
        this.certificate._loading = true
        this.certificate.data = []
  
        let res = await this.fetchCertificateList(this.certificate.params)
        if (res.data.data && res.data.data.length > 0) {
            this.certificate.data = res.data.data
            this.certificate.meta.perPage = res.data.per_page
            this.certificate.meta.currentPage = res.data.current_page
            this.certificate.meta.total = res.data.total
            // this.certificate.meta.totalPage = res.data.totalPage

            this.certificate._loading = false
        }

        this.certificate._loading = false
      },
      async getReasonsSignOff (page){
        this.reasons.params.page = page || 1
        this.reasons._loading = true
        this.reasons.data = []
        let params = this.$options.filters.cleanObject(this.reasons.params)
  
        let res = await this.fetchReasonSignOff(params)
        if (res.status == 'success' || res.status == true) {
          if (res.data.data && res.data.data.length > 0) {
            this.reasons.data = res.data.data
            this.reasons.meta.perPage = res.data.per_page
            this.reasons.meta.currentPage = res.data.current_page
            this.reasons.meta.total = res.data.total
            // this.reasons.meta.totalPage = res.data.totalPage
          }
          this.reasons._loading = false
        }
        this.reasons._loading = false
      }, 
      async getPositions (page){
        this.positions.params.page = page || 1
        this.positions._loading = true
        this.positions.data = []
        let params = this.$options.filters.cleanObject(this.positions.params)
  
        let res = await this.fetchMasterPosition(params)
        console.log(res, "WHAT IS THIS MASTER POSITIONS")
        if (res.status == 'success' || res.status == true) {
          if (res.data.data && res.data.data.length > 0) {
            this.positions.data = res.data.data
            this.positions.meta.perPage = res.data.per_page
            this.positions.meta.currentPage = res.data.current_page
            this.positions.meta.total = res.data.total
            // this.reasons.meta.totalPage = res.data.totalPage
          }
          this.positions._loading = false
        }
        this.positions._loading = false
      },
      async searchCertificate (query) {
        let params = {
          active: 1,
          name: query
        }
        let res = await this.getCertificateList(params)
        this.certificate.data = res.data
      },
  
      async submitFormCertificate () {
        this.loading_form_certificate = true
  
        let payload = {
          id: this.form.selected_certificate_id,
          data: {
            name: this.form.certificate.name,
            alias: this.form.certificate.alias,
            type: this.form.certificate.type,
            is_reminder: this.form.certificate.is_reminder === true ? "1" : "0",
            unlimited: this.form.certificate.unlimited === true ? "1" : "0",
          }
        }
  
        let form_has_empty_value = false
        for (var key in payload.data) {
          if (payload.data[key] === '') form_has_empty_value = true
          else if (Array.isArray(payload.data[key]) && !payload.data[key].length) { form_has_empty_value = true }
        }
  
        if (form_has_empty_value) {
          this.$swal(
            'Empty form!',
            'Please check again and fill all the forms.',
            'error'
          )
        } else {
          if (!this.form_certificate_edit_mode) {
            let res = await this.setCertificateList({data: payload.data})
            if (res.status == 'success') {
              this.resetFormCertificate()
              this.getCertificateList()
              this.$swal(
                'Certificate added!',
                'Certificate added successfully.',
                'success'
              )
            } else {
              if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
                this.$swal(
                  'Error',
                  'Something wrong with the server. Please try again later.',
                  'error'
                )
              }
            }
          } else {  
            let res = await this.updateCertificateList(payload)
            if (res.status == 'success') {
              this.resetFormCertificate()
              this.getCertificateList()
              this.$swal(
                'Certificate updated!',
                'Certificate updated successfully.',
                'success'
              )
            } else {
              if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
                this.$swal(
                  'Error',
                  'Something wrong with the server. Please try again later.',
                  'error'
                )
              }
            }
          }
        }
        this.loading_form_certificate = false
      },
      async submitFormReason () {
        this.loading_form_reason = true

        let payload = {
          id: this.form.selected_reason_id,
          data: {
            name: this.form.reason.name,
            alias: this.form.reason.alias,
            is_blacklist: this.form.reason.is_blacklist === true ? "1" : "0",
            active: this.form.reason.active === true ? "1" : "0",
          }
        }
  
  
        let form_has_empty_value = false
        for (var key in payload) {
          if (payload[key] === '') form_has_empty_value = true
          else if (Array.isArray(payload[key]) && !payload[key].length) { form_has_empty_value = true }
        }
  
        if (form_has_empty_value) {
          this.$swal(
            'Empty form!',
            'Please check again and fill all the forms.',
            'error'
          )
        } else {
          if (!this.form_reason_edit_mode) {
            let res = await this.setReasonSignOff({data: payload.data})
            if (res.status == 'success') {
              this.resetFormReason()
              this.getReasonsSignOff()
              this.$swal('Reason added!', 'Reason added successfully.', 'success')
            } else {
              if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
                this.$swal(
                  'Error',
                  'Something wrong with the server. Please try again later.',
                  'error'
                )
              }
            }
          } else {
            let res = await this.updateReasonSignOff(payload)
            if (res.status == 'success') {
              this.resetFormReason()
              this.getReasonsSignOff()
              this.$swal(
                'Reason updated!',
                'Reason updated successfully.',
                'success'
              )
            } else {
              if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
                this.$swal(
                  'Error',
                  'Something wrong with the server. Please try again later.',
                  'error'
                )
              }
            }
          }
        }
        this.loading_form_reason = false
      },
      async submitFormPosition () {
        this.loading_form_position = true

        let payload = {
          id: this.form.selected_position_id,
          data: {
            name: this.form.position.name,
            alias: this.form.position.alias,
            type_position_id: this.form.position.type_position_id,
            class_type: this.form.position.class_type ,
          }
        }
  
        let form_has_empty_value = false
        for (var key in payload) {
          if (payload[key] === '') form_has_empty_value = true
          else if (Array.isArray(payload[key]) && !payload[key].length) { form_has_empty_value = true }
        }
  
        if (form_has_empty_value) {
          this.$swal(
            'Empty form!',
            'Please check again and fill all the forms.',
            'error'
          )
        } else {
          if (!this.form_position_edit_mode) {
            let res = await this.setMasterPosition({data: payload.data})
            if (res.status == 'success') {
              this.resetFormPosition()
              this.getPositions()
              this.$swal('Position added!', 'Position added successfully.', 'success')
            } else {
              if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
                this.$swal(
                  'Error',
                  res?.data,
                  'error'
                )
              }
            }
          } else {
            let res = await this.updateMasterPosition(payload)
            if (res.status == 'success') {
              this.resetFormPosition()
              this.getPositions()
              this.$swal(
                'Position updated!',
                'Position updated successfully.',
                'success'
              )
            } else {
              if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
                this.$swal(
                  'Error',
                  res?.data,
                  'error'
                )
              }
            }
          }
        }
        this.loading_form_position = false
      },
      onEditCertificate (certificate) {
        this.form_certificate_edit_mode = true
        this.form.selected_certificate_id = certificate.id
        this.form.certificate = {
          name: certificate.name,
          alias: certificate.alias,
          type: certificate.type,
          is_reminder: certificate.is_reminder  === 1 ? true : false,
          unlimited: certificate.unlimited  === 1 ? true : false,
        }
      },
      onEditReason (reason) {
        this.form_reason_edit_mode = true
        this.form.selected_reason_id = reason.id
        this.form.reason = {
          name: reason.name,
          alias: reason.alias,
          is_blacklist: reason.is_blacklist,
          active: reason.active,
        }
      },
      onEditPosition (position) {
        this.form_position_edit_mode = true
        this.form.selected_position_id = position.id
        this.form.position = {
          name: position.name,
          alias: position.alias,
          class_type: position.class_type,
          type_position_id: position.type_position_id,
        }
      },
      onDeletePosition (position) {
        this.$swal({
          title: 'Delete position?',
          text: `Position ${position.name} will be deleted and cannot be recovered.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Delete'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loading_delete_position = true
            let res = await this.deleteMasterPosition({id:position.id})
            if (res.status != 'success') {
              this.loading_delete_position = false
              if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
                this.$swal(
                  'Error',
                  'Something wrong with the server. Cannot get user data.',
                  'error'
                )
              }
            } else {
              this.loading_delete_position = false
              this.getPositions()
              this.$swal(
                `Position deleted!`,
                `Position ${position.name} successfully deleted`,
                'success'
              )
            }
          }
        })
      },
      onDeleteCertificate (project) {
        this.$swal({
          title: 'Delete certificate?',
          text: `Certificate ${project.name} will be deleted and cannot be recovered.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Delete'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loading_delete_certificate = true
            let res = await this.deleteCertificateList({id:project.id})
            if (res.status != 'success') {
              this.loading_delete_certificate = false
              if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
                this.$swal(
                  'Error',
                  'Something wrong with the server. Cannot get user data.',
                  'error'
                )
              }
            } else {
              this.loading_delete_certificate = false
              this.getCertificateList()
              this.$swal(
                `Project deleted!`,
                `Project ${project.name} successfully deleted`,
                'success'
              )
            }
          }
        })
      },
      onDeleteReason (reason) {
        console.log(reason, "REASONS DATA DELETE")
        this.$swal({
          title: 'Delete reason sign off?',
          text: `Reason ${reason.name} will be deleted and cannot be recovered.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Delete'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loading_delete_reason = true
            let res = await this.deleteReasonSignOff({id:reason.id})
            if (res.status != 'success') {
              this.loading_delete_reason = false
              if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
                this.$swal(
                  'Error',
                  'Something wrong with the server. Cannot get user data.',
                  'error'
                )
              }
            } else {
              this.loading_delete_reason = false
              this.getReasonsSignOff()
              this.$swal(
                `Reason deleted!`,
                `Reason ${reason.name} successfully deleted`,
                'success'
              )
            }
          }
        })
      },
      resetFormCertificate () {
        this.form_certificate_edit_mode = false
        this.form.certificate = {
            name:'',
            alias:'',
            type:'',
            is_reminder:0,
            unlimited:0,
        }
      },
      resetFormReason () {
        this.form_reason_edit_mode = false
        this.form.reason = {
            name:'',
            alias:'',
            is_blacklist:0,
            active:0,
        }
      },
      resetFormPosition () {
        this.form_position_edit_mode = false
        this.form.position = {
            name:'',
            alias:'',
            type_position_id:null,
            class_type:'',
        }
      },
      menuPermission (output) {
        const { menuObject } = this.$route.query
  
        if (!menuObject) {
          return true
        }
  
        return JSON.parse(window.atob(menuObject))[output]
      }
    }
  }
  </script>
  
  <style scoped>
  </style>
  