var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tab-nav', {
    attrs: {
      "tabs": true,
      "align": "center"
    }
  }, [_c('tab-nav-items', {
    attrs: {
      "active": true,
      "href": "#blacklist-crew",
      "title": "Blacklist Crew"
    }
  }), _c('tab-nav-items', {
    attrs: {
      "active": false,
      "href": "#blacklist-candidate",
      "title": "Blacklist Candidate"
    }
  })], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "myTabContent2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "blacklist-crew"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('ContentBlacklistCrew', {
    attrs: {
      "optionsPosition": _vm.optionsPosition
    }
  })], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "blacklist-candidate"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('ContentBlacklistCandidate', {
    attrs: {
      "optionsPosition": _vm.optionsPosition
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }