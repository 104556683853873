<template>
  <div>
    <iq-card>
      <template v-slot:headerTitle>
        <h5 class="card-title text-primary"
          ><strong>BLACK LIST CREW</strong></h5
        >
      </template>
      <template v-slot:headerAction>
        <div class="d-flex align-items-center">
          <b-button variant="success" class="mr-3" @click="exportBlacklist()" :disabled="blackList.loading">
            <span
              v-if="blackList.loading"
              class="spinner-border spinner-border-sm"
            />
            <div v-else class="d-flex gap-2 align-items-center"> 
              <i class="fa fa-file-excel"></i> 
              <span>Export</span>
            </div>
          </b-button>
          <a
            class="text-primary float-right"
            v-b-toggle.collapse-filter
            role="button"
            style="cursor: pointer"
          >
            <i class="fa fa-filter" />
          </a>
        </div>
      </template>
      <template v-slot:body>
        <b-collapse visible id="collapse-filter" class="mb-2">
          <b-row>
            <b-col md="2">
              <b-form-group label="Search" label-for="search">
                <b-form-input
                  v-model="blackList.params.full_name"
                  type="text"
                  placeholder="Ketikan Nama"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Jabatan" label-for="jabatan">
                <b-form-select
                  plain
                  v-model="blackList.params.position_id"
                  :options="optionsPosition"
                  size="lg"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null"
                      >Pilih Jabatan</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group label="Filter" label-for="button_search">
                <b-button
                  @click="getBlackList(null)"
                  variant="primary"
                  class="mb-3 mr-1"
                  ><i class="fa fa-filter"></i
                ></b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-collapse>
        <div class="d-flex align-items-center my-2">
          <span class="mr-1">Total Blacklist: </span> <strong>{{ blackList.meta.total  }}</strong> 
        </div>
        <div class='d-flex overflow-auto'>
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="text-center">PHOTO</th>
                <th class="text-center">NAMA</th>
                <th class="text-center">JABATAN</th>
                <th class="text-center">VESSEL</th>
                <th class="text-center">COMPANY</th>
                <th class="text-center" style="min-width: 200px">REASON OFF</th>
                <th class="text-center">REMARKS</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in blackList.data" :key="index">
                <td>
                  <b-img
                    style="width:70px; height: 70px;"
                    :src="data.photo || require('../../assets/images/page-img/10.jpg')"
                    alt="img"
                  />
                </td>
                <td class="text-truncate">
                  <router-link
                    :to="{
                      path: `profile`,
                      query: {
                        user: 'crew',
                        id: data.id,
                        preview: false,
                      },
                    }">{{ data?.personal_data?.full_name }}</router-link><br />
                    <small style="color: gray">NIP : <b>{{ data?.nip ?? '-' }}</b><br />
                      Seaferer Code : {{ data?.personal_data?.seaferer_code ?? '-' }}</small>
                </td>
                <td class="text-center text-truncate ">{{ data?.position?.name ?? '-' }}</td>
                <td class="text-center text-truncate ">{{ data?.vehicle?.name ?? '-' }}<br />
                  <small style="color: gray">Port : {{data?.port_id ?? '-'}}</small>
                </td>
                <td class="text-center text-truncate ">{{ data?.company?.company ?? '-' }}</td>
                <td class="text-center">
                  <div class="d-flex flex-column " v-if="data?.reason_sign_off_list?.length > 0">
                    <span v-for="(reason, reasonIndex) in data?.reason_sign_off_list" class="mb-2" :key="reasonIndex">{{reasonIndex + 1}}. {{reason?.name}}</span>
                  </div>
                  <span v-else>-</span>
                </td>
                <td>{{ data?.remarks ?? 'Tanpa keterangan' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-3" v-if="blackList.data.length > 0">
          <b-pagination
            v-model="blackList.meta.currentPage"
            :total-rows="blackList.meta.total"
            :per-page="blackList.meta.perPage"
            first-number
            @change="getBlackList"
            align="center"
          />
        </div>
        <p v-else class="text-center my-4"
          >List blacklist belum ada.</p
        >
      </template>
    </iq-card>
  </div>
</template>

<script>
import { crewingActions } from '@src/Utils/helper'
import _ from 'lodash'
export default {
  name: 'ContentBlacklistCrew',
  props:{
    optionsPosition:{
      type:Array,
      default: () => []
    },
  },
  data() {
    return {
      loading: false, 
      blackList: {
        params: {
          full_name: '',
          status: null,
          from: null,
          to: null,
          position_id: null,
        },
        data: [],
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        },
      },
    }
  },
  created () {
    this.getBlackList = _.debounce(this.getBlackList, 500)
  },
  async mounted () {
    this.getBlackList()
  },

  methods: {
    ...crewingActions,
    async getBlackList (page = 1) {
      const params = {
        page: page || 1,
      }

      for (const key in this.blackList.params) {
        if (this.blackList.params[key]) {
          params[key] = this.blackList.params[key]
        }
      }

      const { status, data } = await this.fetchBlackListCrew(params)

      if (status === 'success') {
        this.blackList.meta.perPage = data.per_page
        this.blackList.meta.currentPage = data.current_page
        this.blackList.meta.total = data.total
        this.blackList.meta.totalPage = Math.round(data.total / data.per_page)
        if (data.data) {
          this.blackList.data = data.data
        }
      }
    },

    async exportBlacklist () {
      this.blackList.loading = true
      let params = {
        ...this.blackList.params,
        crew: 1
      }
      
      if(!params.position_id) delete params.position_id
      if(!params.full_name) delete params.full_name

      const res = await this.exportListBlacklist({params})

      if(res.status === 404 || res.status === 500 || res.status === 400 || res.status === 401){
        this.$swal(`Oops!`, `Something went wrong.`, 'error')
        this.blackList.loading = false
        return
      }

      saveAs(res.data, 'List Blacklist.xlsx')
      this.blackList.loading = false
    },
  }
}
</script>
