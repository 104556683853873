<template>
    <b-container fluid>
      <!-- Tab -->
      <b-row>
        <b-col lg="12">
          <iq-card body-class="p-0">
            <template v-slot:headerTitle>
                  <h4 class="card-title text-primary"
                    ><strong>SERVICE AND REPAIR MASTER DATA</strong></h4
                  >
                </template>
            <template v-slot:body>
              <div class="iq-edit-list">
                <tab-nav :pills="true" class="iq-edit-profile d-flex">
                  <tab-nav-items
                    class="col-md-2 p-0"
                    :active="true"
                    href="#task-type"
                    title="TASK TYPE"
                  />
                  <tab-nav-items
                    class="col-md-2 p-0"
                    :active="false"
                    href="#category"
                    title="CATEGORY"
                  />
                  <tab-nav-items
                    class="col-md-2 p-0"
                    :active="false"
                    href="#classification"
                    title="CLASSIFICATION"
                  />
                  <tab-nav-items
                    class="col-md-2 p-0"
                    :active="false"
                    href="#cause"
                    title="CAUSE"
                  />
                  <tab-nav-items
                    class="col-md-2 p-0"
                    :active="false"
                    href="#kind-task"
                    title="KIND TASK"
                  />
                  <tab-nav-items
                    class="col-md-2 p-0"
                    :active="false"
                    href="#activity"
                    title="ACTIVITY"
                  /> 
                   <tab-nav-items
                    class="col-md-2 p-0"
                    :active="false"
                    href="#reject-reason"
                    title="REJECT"
                  />
                </tab-nav>
              </div>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <!-- End Tab -->
      <div id="pills-tabContent-2" class="tab-content">
        <tab-content-item :active="true" id="task-type">
            <SrMasterTaskType />
        </tab-content-item>
        <tab-content-item :active="false" id="category">
            <SrMasterCategory />
        </tab-content-item>
        <tab-content-item :active="false" id="classification">
            <SrMasterClassification />
        </tab-content-item>
        <tab-content-item :active="false" id="cause">
            <SrMasterCause />
        </tab-content-item>
        <tab-content-item :active="false" id="kind-task">
            <SrMasterKindTask />
        </tab-content-item>
        <tab-content-item :active="false" id="activity">
            <SrMasterActivity />
        </tab-content-item>
        <tab-content-item :active="false" id="reject-reason">
            <SrMasterRejectReason />
        </tab-content-item>
      </div>
    </b-container>
  </template>
  
  <script>
  import { srActions } from '@src/Utils/helper'
  
  import SrMasterTaskType from './SrMasterTaskType.vue'
  import SrMasterActivity from './SrMasterActivity.vue'
  import SrMasterCategory from './SrMasterCategory.vue'
  import SrMasterCause from './SrMasterCause.vue'
  import SrMasterClassification from './SrMasterClassification.vue'
  import SrMasterKindTask from './SrMasterKindTask.vue'
  import SrMasterRejectReason from './SrMasterRejectReason.vue'

  import _ from 'lodash'
  
  export default {
    name: 'ServiceRepairMasterData',

    components:{
        SrMasterTaskType,
        SrMasterActivity,
        SrMasterCategory,
        SrMasterCause,
        SrMasterClassification,
        SrMasterKindTask,
        SrMasterRejectReason,
    }
  }
  </script>
  
  <style scoped>
  </style>
  