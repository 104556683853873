import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
    profile: (state) => state.profile,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarTheme: (state) => state.leftSidebarTheme,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
  }),
}

export const authMethods = mapActions('auth', [
  'logIn',
  'logOut',
  'getProfile',
  'updateProfile',
  'updatePhoto',
  'updateUsername',
  'updatePassword',
  'forgotPassword',
  'validateForgotToken',
  'changePassword',
])

export const layoutMethods = mapActions('layout', [
  'changeLayoutType',
  'changeLeftSidebarTheme',
  'changeLeftSidebarType',
  'changeLayoutWidth',
])

export const rolesActions = mapActions('roles', [
  'getRoles',
  'addRole',
  'updateRole',
  'deleteRole',
])

export const usersActions = mapActions('users', [
  'getUsers',
  'getUserById',
  'registerUser',
  'updateUser',
  'updateUserPhoto',
  'updateUserStatus',
])

export const companiesActions = mapActions('companies', ['getCompanies'])

export const menusActions = mapActions('menus', [
  'getMenus',
  'getMenusByRole',
  'saveRolePrivilege',
])

export const fleetsActions = mapActions('fleets', [
  'getUnscheduledDockingFleet',
  'getFleets',
  'getFleetActivities',
  'getFleetById',
  'getCrewInFleet',
  'addFleet',
  'getFleetTypes',
  'getFleetTypeGroups',
  'addFleetType',
  'updateFleetType',
  'updateFleet',
  'deleteFleet',
  'deleteFleetType',
])

export const filesActions = mapActions('files', ['upload'])

export const logsActions = mapActions('logs', ['getLogs','exportLogs'])

export const structuresActions = mapActions('structures', [
  'getStructures',
  'saveStructures',
])

export const portsActions = mapActions('ports', [
  'getPorts',
  'getPortById',
  'savePort',
  'updatePort',
  'deletePort',
  'exportPort',
])

export const projectsActions = mapActions('projects', [
  'getProjects',
  'getProjectById',
  'addProject',
  'addProjectDetail',
  'updateProjectDetail',
  'deleteProject',
  'exportProjects',
])

export const reasonsActions = mapActions('reasons', [
  'getReasons',
  'getReasonById',
  'addReason',
  'updateReason',
  'updateReasonPosition',
  'deleteReason',
  'exportReasons',
])

export const modulesActions = mapActions('modules', [
  'getModules',
  'getModuleCategories',
  'addModule',
  'saveModuleCategories',
  'updateModule',
  'deleteModule',
])

export const certificatesActions = mapActions('certificates', [
  'getCertificates',
  'getCertificateVehicles',
  'getCertificateVehicleLogs',
  'getCertificateById',
  'getCertificateVehicleById',
  'getCertificateCategories',
  'addCertificate',
  'addCertificateVehicle',
  'updateCertificateCategory',
  'addCertificateCategory',
  'processRenewalCertificateVehicle',
  'finishRenewalCertificateVehicle',
  'endorsementCertificateVehicle',
  'updateCertificate',
  'updateCertificateVehicle',
  'deleteCertificate',
  'deleteCertificateVehicle',
  'exportCertificates',
  'exportCertificateVehicles',
  'getEndorsementDates',
])

export const notificationsActions = mapActions('notifications', [
  'getNotifications',
  'readNotification',
])

export const voyagesActions = mapActions('voyages', [
  'getVoyageUnfinishedTrip',
  'getVoyageHistories',
  'getVoyageHistoriesExport',
  'getVoyageLastActivity',
  'getVoyageMatrix',
  'getActivityById',
  'getSCL',
  'getSCLById',
  'deleteActivityById',
  'deleteSCLById',
  'updateActivity',
  'updateSCL',
  'checkVehicleVoyage',
  'createVoyageActivity',
  'createSCL',
  'getFcPeriod',
  'getFcDashboardPeriod',
  'saveFC',
  'getIdle',
])

export const powerActions = mapActions('power', [
  'getPowerTypes',
  'getPowerSeries',
  'getPowerTypeById',
  'getPowerSeriesById',
  'savePowerType',
  'savePowerSeries',
  'deletePowerTypeById',
  'deletePowerSeriesById',
  'updatePowerType',
  'updatePowerSeries',
])

export const robActions = mapActions('rob', [
  'getRobTypes',
  'getRobSeries',
  'getRobTypeById',
  'getRobSeriesById',
  'saveRobType',
  'saveRobSeries',
  'deleteRobTypeById',
  'deleteRobSeriesById',
  'updateRobType',
  'updateRobSeries',
])

export const dashboardActions = mapActions('dashboards', [
  'getStatsCertificate',
  'getDailyOps',
  'getDailyOpsExport',
  'getMonthlyOps',
  'getMonthlyOpsExport',
])

export const maintenanceActions = mapActions('maintenance', [
  'getJobType',
  'getJobClass',
  'getMasterEquipment',
  'getComponent',
  'getComponentSetup',
  'getJobTypeById',
  'getJobClassById',
  'getMasterEquipmentById',
  'getComponentById',
  'getComponentSetupById',
  'getMaintenance',
  'getMaintenanceFinished',
  'getInitiation',
  'getMaintenanceDetail',
  'checkHasComponentSetup',
  'createJobType',
  'createJobClass',
  'createMasterEquipment',
  'createComponent',
  'createComponentSetup',
  'saveInitMaintenance',
  'updateMaintenance',
  'updateJobType',
  'updateJobClass',
  'updateMasterEquipment',
  'updateComponent',
  'updateComponentSetup',
  'deleteJobTypeById',
  'deleteJobClassById',
  'deleteMasterById',
  'deleteComponentById',
  'deleteComponentSetupyId',
  'deleteInitMaintenanceById',
  'saveComponentStructures',
  'getRunningHours',
  'getLatestRunningHours',
  'saveRunningHours',
  'deleteRunningHours',
  'getRob',
  'getLatestRob',
  'saveRob',
  'deleteRob',
  'saveCommentMaintenance',
  'saveDoMaintenance',
  'savePostponeMaintenance',
  'saveReviewMaintenance',
  'saveApproveMaintenance',
  'updateDoMaintenance',
  'createDuplicateComponent',
])

export const miActions = mapActions('mi', [
  'getForecastSuggestInitialCalculaiton',
  'getTypeCoverages',
  'getTypeLoss',
  'getDepartments',
  'getInsurers',
  'getStatuses',

  'getMi',
  'exportInitial',
  'fetchDetailMi',
  'saveMi',
  'updateMi',
  'deleteMi',
  'updateDocumentSupport',

  'getMasterSurvey',
  'getMasterSurveyDetail',
  'saveMasterSurvey',
  'updateMasterSurvey',
  'deleteMasterSurvey',

  'getMasterInputSurvey',

  'getMasterCostBreakdown',
  'getMasterCostBreakdownDetail',
  'saveMasterCostBreakdown',
  'updateMasterCostBreakdown',
  'deleteMasterCostBreakdown',

  'saveClaimAnalysis',
  'getClaimAnalysisByFormId',
  'getFormClaimAnalysisByFormId',
  'getCostBreakdownByFormId',
  'saveCostBreakdown',

  'getMasterPureRisk',
  'getMasterPureRiskDetail',
  'updateMasterPureRisk',
  'saveMasterPureRisk',
  'deleteMasterPureRisk',

  'getMasterPureRiskItem',
  'getMasterPureRiskItemDetail',
  'updateMasterPureRiskItem',
  'saveMasterPureRiskItem',
  'deleteMasterPureRiskItem',

  'getRCAFormModel',
  'getRCA',
  'createRCA',
  'editRCA',
  'deleteRCA',
  'deleteRCA',
  'getRCAFinal',
  'createRCAFinal',
  'editRCAFinal',
  'deleteRCAFinal',

  'getLossRatio',
  'getLossRatioInput',
  'saveLossRatio',

  'getMasterCauseOfLoss',
  'getMasterClassificationOfLoss',
  'getMasterAllErm',
  'saveERM',
  'editERM',
  'getMasterQuestions',
  'getMasterCauseOfLoss',

  'getMonitoring',
  'saveMonitoring',
  'editMonitoring',
  'deleteMonitoring',

  'getRecommendation',
  'saveRecommendation',
  'updateRecommendation',

  'getEvaluation',
  'saveEvaluation',

  'getReport',
  'getRecommendationList',
  'deleteRecommendation',
  'getSummaryManagement',
  'createSummaryManagement',
  'createSummaryManagementClaimPurposed',
  'deleteSummaryManagementClaimPurposed',
  'getFormSummary',

  'createPriceFleet',
  'updatePriceFleet',
  'createPriceFleetChild',
  'updatePriceFleetChild',
  'deletePriceFleetChild',
  'getFleetPriceList',
  'getFleetPriceListExport',
  'getFleetPriceListDetail',
  'getFleetPriceModel',
  'getFleetPriceFilter',
  'getFleetPriceLastValue',

  'getFleetByCompanyAndType',
  'getFleetForms',
  'getCurrencyMapping',
  'getCurrencies',
  'createCurrencyMapping',
  'getCheckForecast',
  'getForecastById',
  'getOutstandingForecast',
  'getInitialCalculationForecast',
  'getListForecast',

  'getFormDeductible',
  'getPesimistic',
  'getForecastPriceFleet',
  'saveForecast',
  'updateForecast',
  'getRaciMatrix',

  'getForecastFinalIndependent',
  'saveForecastFinalIndependent',
  'updateForecastFinalIndependent',
  'deleteForecastFinalIndependent',

  'uploadFile',

  'sendEmailAccident',
  'sendEmailRecommendation',
  'sendEmailEvaluation',
])

export const vehiclesActions = mapActions('vehicles', [
  'getVehicles',
  'getVehicle',
])

export const dockingActions = mapActions('docking', [
  'getTaskType',
  'getJobCategories',
  'getJobCategoryDetail',
  'saveJobCategory',
  'updateJobCategory',
  'deleteJobCategory',

  'getJobs',
  'getJobDetail',
  'saveJob',
  'updateJob',
  'deleteJob',

  'getShipyards',
  'getShipyardDetail',
  'saveShipyard',
  'updateShipyard',
  'deleteShipyard',

  'getActiveDockingSchedules',
  'getProgressDockingSchedules',
  'getFinishDockingSchedules',
  'getDockingDetail',
  'getDockingTasks',
  'getDockingDetailTask',
  'getDockingActivities',
  'createDockingScheduleInterval',
  'createDockingEmergency',
  'createDockingFromSchedule',
  'createDockingPending',
  'createDockingTaskActivity',
  'createDockingSeaTrial',
  'createDockingHandOver',
  'reviewDocking',
  'approveDocking',
  'getTaskDetail',

  'deleteDockingScheduleById',
  'approvalDeleteDockingScheduleById',
  
  'createDockingScheduleIntermediate',
  'createDockingScheduleSpecialSurvey'
])

export const crewingActions = mapActions('crewing', [
  "deleteFleetHistory",
  "setRequestSignOff",
  'fetchCrewList',
  'getCrewDetail',
  'fetchCandidateList',
  'fetchBlackList',
  'fetchBlackListCrew',
  'createCandidate',
  'createCrew',
  'fetchProvience',
  'fetchCities',
  'fetchDistricts',
  'fetchVillages',
  'fetchVehicles',
  'fetchCompanies',
  'fetchWorkArea',
  'fetchPorts',

  'exportListCrew',
  "exportListBlacklist",
  "exportListCandidate",

  'setNoticeSignOff',
  'fetchPayrollAllowance',
  'fetchPositionList',
  'fetchTypePositionList',
  'fetchPayrollSettingBPJS',
  'getProfileCandidate',
  'setBlacklistCandidate',
  'setBlacklistCrew',
  'setInterviewCandidate',
  'fetchUserExpert',
  'fetchPointInterview',
  'fetchCriteriaInterview',
  'setFormInterview',
  'medicalCheckup',
  'masterDocumentList',
  'setDocumentChecklist',
  'fetchMasterDepartmentList',
  'fetchInductionChecklist',
  'setInductionCandidate',
  'setSignOnCandidate',
  'masterSignOffReasons',
  'setSignOffCrew',
  'changeStatusCandidate',
  'fetchConditeModel',
  'setConditeCrew',
  'fetchConductModel',
  'setConductCrew',
  'fetchBlacklistReasons',
  'fetchDownloadPKL',
  'setVerificationDocument',
  'setSwapPosition',
  'fetchDashboardCrew',
  'fetchCertificateList',
  'fetchEducationList',
  'fetchEmployeeStatus',
  'fetchMaterPajak',
  'fetchLisBloodType',
  'deleteCrew',
  'deleteCandidate',
  'updateJoinDateCrew',

  'fetchExpiredNotifCrew',
  'fetchExpiredNotifCOPCOC', 
  'exportExpiredCrew',
  'exportExpiredCrewMonth',
  'exportExpiredNotifCOPCOC',

  'updateDataDiri',
  'updateAlamat',

  'createKeluarga',
  'updateKeluarga',
  'deleteKeluarga',

  'createPekerjaan',
  'updatePekerjaan',
  'deletePekerjaan',

  'createTravelDokumen',
  'updateTravelDokumen',
  'deleteTravelDokumen',

  'createCompetency',
  'updateCompetency',
  'deleteCompetency',

  'createProficiency',
  'updateProficiency',
  'deleteProficiency',

  'createOrUpdateDokumenPendukung',
  'deleteDokumenPendukung',

  'fetchCertificateList',
  'setCertificateList',
  'updateCertificateList',
  'deleteCertificateList',

  'fetchReasonSignOff',
  'setReasonSignOff',
  'updateReasonSignOff',
  'deleteReasonSignOff',
  
  'fetchMasterPosition',
  'setMasterPosition',
  'updateMasterPosition',
  'deleteMasterPosition',

  'sendEmailToInterviewer',
  'sendEmailToCandidate',

  'fetchListBirthdayCrew',
  'exportListBirthdayCrew',

  'fetchListNoticeSignOff',
  'exportListNoticeSignOff',

  'fetchListPklCrew',
  'fetchExpiredCrew',
  'fetchExpiredCrewMonth',
  'exportListPklCrew',

  'medicalCheckupCrew',
  'setFormInterviewCrew',
  'fetchInductionChecklistCrew',
  'setInductionCandidateCrew',
])

export const publicActions = mapActions('public', [
  'fetchCandidatePublic',
  'fetchPointListPublic',
  'fetchInterviewCriteriaPublic',
  'fetchProfileCandidatePublic',
  'setFormInterviewPublic',
  'fetchMasterSignOff',
  'fetchCrewList',
  'setNoticeSignOff',
  'fetchCertificateList',
  'fetchEducations',
])

export const srActions = mapActions('sr', [
  'deleteService',
  'deleteRepair',
  'requestDeleteSr',
  
  'fetchListDepartment',
  'fetchListServiceAndRepair',
  'fetchListTableServiceAndRepair',
  'fetchListProgressTableServiceAndRepair',
  'fetchSingleServiceAndRepair',
  'fetchListTableFinishedServiceAndRepair',
  'fetchListTableFinishedServiceAndRepairById',
  'fetchDetailRepairServiceAndRepair',
  'fetchSingleRepair',
  'fetchListRepairService',
  'fetchLogs',

  'fetchListVehicles',
  'fetchMasterActivity',
  'fetchMasterCategory',
  'fetchMasterCause',
  'fetchMasterClassification',
  'fetchMasterKindTask',
  'fetchMasterStatus',
  'fetchMasterTaskType',
  'checkVehicleExist',
  'fetchListVehicleType',

  'fetchReportDashboard',
  'fetchReportDashboardGraphics',
  'fetchReportDashboardPies',
  'fetchReportDashboardTables',

  'createServiceAndRepair',
  'setActivityRepair',
  'setReviewRepair',
  'createJobs',
  'updateJobs',

  'getMasterCategory',
  'createMasterCategory',
  'updateMasterCategory',
  'deleteMasterCategory',

  'getMasterActivity',
  'createMasterActivity',
  'updateMasterActivity',
  'deleteMasterActivity',

  'getMasterCause',
  'createMasterCause',
  'updateMasterCause',
  'deleteMasterCause',

  'getMasterClassification',
  'createMasterClassification',
  'updateMasterClassification',
  'deleteMasterClassification',

  'getMasterKindTask',
  'createMasterKindTask',
  'updateMasterKindTask',
  'deleteMasterKindTask',

  'getMasterTaskType',
  'createMasterTaskType',
  'updateMasterTaskType',
  'deleteMasterTaskType',

  'getMasterRejectReason',
  'createMasterRejectReason',
  'updateMasterRejectReason',
  'deleteMasterRejectReason',

  'updateJobs',
  'setRejectService',

  'approveSr',
])

export const busdevActions = mapActions('busdev', [
  'fetchCountry',
  'fetchCurrency',
  'fetchCargoType',
  'fetchTerms',
  'fetchVehicleTypeMasterSection',
  'createVehicleTypeMasterSection',
  'fetchTaxCategory',
  'fetchTaxZone',
  'fetchDocumentList',
  // 'fetchCountry',
  'fetchListFile',
  'sendMailPayment',

  'fetchCustomer',
  'exportCustomer',
  'fetchCustomerById',
  'createCustomer',
  'updateCustomer',
  'deleteCustomer',

  'fetchVendor',
  'exportVendor',
  'fetchVendorById',
  'createVendor',
  'updateVendor',
  'deleteVendor',

  'fetchInspectionChecklistCategory',
  'createInspectionChecklistCategory',
  'updateInspectionChecklistCategory',
  'deleteInspectionChecklistCategory',

  'fetchInspectionChecklistQuestion',
  'createInspectionChecklistQuestion',
  'updateInspectionChecklistQuestion',
  'deleteInspectionChecklistQuestion',

  'fetchSectionHead',
  'createSectionHead',
  'updateSectionHead',
  'deleteSectionHead',

  'fetchComponentExpense',
  'createComponentExpense',
  'updateComponentExpense',
  'deleteComponentExpense',

  'fetchQuestionType',
  'fetchQuestionTypeById',
  'createQuestionType',
  'updateQuestionType',
  'deleteQuestionType',

  'fetchCollectionForm',
  'fetchOptionsCollectionForm',
  'fetchCollectionFormById',
  'createCollectionForm',
  'updateCollectionForm',
  'deleteCollectionForm',
  
  'fetchFormCategory',
  'fetchFormCategoryById',
  'createFormCategory',
  'updateFormCategory',
  'deleteFormCategory',

  'fetchFormQuestion',
  'fetchFormQuestionById',
  'createFormQuestion',
  'updateFormQuestion',
  'deleteFormQuestion',

  'fetchProjects',
  'fetchProjectById',
  'createProject',
  'updateProject',
  'deleteProject',

  'fetchProjectContract',
  'fetchProjectContractById',
  'createProjectContract',
  'updateProjectContract',
  'deleteProjectContract',

  'fetchShipmentPlanningTable',
  'exportShipmentPlanningTable',
  'fetchDetailShipmentPlanning',
  'fetchDetailProjectContract',
  'createShipmentPlanning',
  'savedMovedStage',
  'saveMovedProjects',
  'changeValuePrioritize',
  'changeStatusStage',
  'updateStageTitle',
  'changeDenyStage',
  'changeSpalProcessStage',
  'changeRunningProcessStage',
  'updatePeriodeDate',
  'deleteShipmentPlanning', 
  'deleteProjectContract', 
  'deleteProjectContractPlan', 
  
  'fetchDetailProjectStage',
  'fetchDetailProjectStageById',
  'createDetailProjectStage',
  'updateDetailProjectStage',
  'deleteDetailProjectStage',

  'fetchContractType',
  'fetchContractTypeById',
  'createContractType',
  'updateContractType',
  'deleteContractType',

  'createProjectContractTender',
  'updateProjectContractTender',
  'deleteProjectContractTender',

  'createProjectContractDocument',
  'updateProjectContractDocument',
  'deleteProjectContractDocument',

  'fetchVehicleTypeForm',
  'fetchVehicleTypeFormById',
  'createVehicleTypeForm',
  'updateVehicleTypeForm',
  'deleteVehicleTypeForm',

  'fetchInspectionChecklist',
  'fetchInspectionChecklistById',
  'createInspectionChecklist',
  'updateInspectionChecklist',
  'deleteInspectionChecklist',
  'exportInspectionChecklist',

  'setProjectForm',
  'saveApprovalUser',

  'fetchCharterCompany',
  'fetchCharterCompanyById',
  'createCharterCompany',
  'updateCharterCompany',
  'deleteCharterCompany',

  'fetchTimeVoyage',
  'fetchTimeVoyageById',
  'createTimeVoyage',
  'updateTimeVoyage',
  'deleteTimeVoyage',

  'fetchCostFactor',
  'fetchCostFactorById',
  'createCostFactor',
  'updateCostFactor',
  'deleteCostFactor',

  'fetchMiscellaneousCharge',
  'fetchMiscellaneousChargeById',
  'createMiscellaneousCharge',
  'updateMiscellaneousCharge',
  'deleteMiscellaneousCharge',

  'fetchVehicleType',
  'fetchVehicleTypeById',

  'fetchPortStay',
  'fetchPortStayById',
  'createPortStay',
  'updatePortStay',
  'deletePortStay',

  'fetchResultPlus',
  'fetchResultPlusById',
  'createResultPlus',
  'updateResultPlus',
  'deleteResultPlus',

  'createOrUpdateProjectPfa',
  'deleteProjectPfa',
  'fetchPfaNextId',
  'fetchProjectContractNextId',
  'exportPFA',
  
  'fetchVehicleTypeGroup',
  'fetchVehicleTypeGroupById',
  'createVehicleTypeGroup',
  'updateVehicleTypeGroup',
  'deleteVehicleTypeGroup', 

  'fetchVehicleTypeSectionRelationById',
  'createVehicleTypeSectionRelation',
  'deleteVehicleTypeSectionRelation',

  'sendEmailNotification',

  'fetchSpal',
  'fetchSpalById',
  'createSpal',
  'updateSpal',
  'deleteSpal',

  'fetchCargoType',
  'fetchCargoTypeById',
  'createCargoType',
  'updateCargoType',
  'deleteCargoType',

  'fetchUnit',
  'fetchUnityId',
  'createUnit',
  'updateUnit',
  'deleteUnit',

  'fetchMasterPfa',
  'fetchMasterPfaById',
  'createMasterPfa',
  'updateMasterPfa',
  'deleteMasterPfa',

  'createVendorClass',
  'updateVendorClass',
  'deleteVendorClass',
  'fetchVendorClass',

  'createVendorType',
  'updateVendorType',
  'deleteVendorType',
  'fetchVendorType', 
  
  'fetchPaymentList',
  'fetchPaymentListById',
  'createPaymentList',
  'updatePaymentList',
  'deletePaymentList',

  'addRealInvoice',
  
  'createCustomerClass',
  'updateCustomerClass',
  'deleteCustomerClass',
  'fetchCustomerClass',

  'fetchMasterBank',
  'createMasterBank',
  'updateMasterBank',
  'deleteMasterBank',
  
  'fetchTripDocument',
  'fetchTripDocumentById',
  'createTripDocument',
  'updateTripDocument',
  'deleteTripDocument',

  'fetchSpalProcess',
  'fetchSpalProcessById',
  'createSpalProcess',
  'createSpalProcessComment',
  'updateSpalProcess',
  'deleteSpalProcess',

  'exportSpesification',
  'fetchSpesification',
  'fetchSpesificationById',
  'createSpesification',
  'updateSpesification',
  'deleteSpesification',

  'exportInventoryItem',
  'fetchInventoryItem',
  'fetchInventoryItemById',
  'createInventoryItem',
  'updateInventoryItem',
  'deleteInventoryItem',

  'exportMaterialCategory',
  'fetchMaterialCategory',
  'fetchMaterialCategoryById',
  'createMaterialCategory',
  'updateMaterialCategory',
  'deleteMaterialCategory',

  'getStockAdjustments',
  'createStockAdjustments',
  'updateStockAdjustments',
  'deleteStockAdjustment',
  'updateStockAdjustmentDetail',
  'createStockAdjustmentDetail',
  'deleteStockAdjustmentDetail',
  'getStockData',
  'getStockDataDetails',
  'getVehicleStockData',
  'getListProducts',
  'checkStockAvailable',
  'exportVehicleStockData',
  'exportVehicleStockAdjustment',
  'exportStockData',

  'uploadFileSingle',

  // PUBLIC
  'saveApprovalUserPublic',
  'fetchDetailProjectContractPublic',
])

export const picMovementActions = mapActions('picMovement', [
  'createPICMovement',
  'approvalPICMovement',
  'getListPicMovement',
  'getPicMovementDetail',
  'getPicMovementByVehicle',
  'getVehicleByPic',
])

export const platformModuleActions = mapActions('platformModule', [
  'getPlatformModuleById',
  'getListPlatformModule',
  'updatePlatformModule',
])
