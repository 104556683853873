var render = function render(){
  var _vm$formCustomer, _vm$formBilling$billi, _vm$formBilling, _vm$formBilling$billi2, _vm$formBilling2, _vm$formBilling$billi3, _vm$formBilling3, _vm$formBilling$billi4, _vm$formBilling4, _vm$formBilling$billi5, _vm$formBilling5, _vm$formBilling$billi6, _vm$formBilling6, _vm$formBilling$billi7, _vm$formBilling7, _vm$formBilling$billi8, _vm$formBilling8, _vm$formBilling$billi9, _vm$formBilling9, _vm$formBilling$billi10, _vm$formBilling10, _vm$formBilling$billi11, _vm$formBilling11, _vm$formBilling$billi12, _vm$formBilling12, _vm$formShipping$si_s;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    attrs: {
      "action": "#"
    },
    on: {
      "submit": _vm.onSave
    }
  }, [_c('b-row', [!_vm.previewData ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    staticStyle: {
      "width": "15%"
    },
    attrs: {
      "disabled": _vm.saving,
      "block": "",
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('div', [_c('i', {
    staticClass: "fa fa-save"
  }), _c('span', [_vm._v("Submit Data")])])])], 1)]) : _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    staticStyle: {
      "width": "15%"
    },
    attrs: {
      "block": "",
      "variant": "primary",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.$nextTick(function () {
          return _vm.$bvModal.hide('new-customer');
        });
      }
    }
  }, [_c('div', [_c('i', {
    staticClass: "fa fa-check"
  }), _c('span', [_vm._v("Select Customer")])])])], 1)]), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "* Customer Code",
      "label-for": "customer_code"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.customer_code))]) : _c('b-form-input', {
    attrs: {
      "id": "customer_code",
      "type": "text",
      "placeholder": "Type text",
      "required": ""
    },
    model: {
      value: _vm.formCustomer.customer_code,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_code", $$v);
      },
      expression: "formCustomer.customer_code"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "* Customer Status",
      "label-for": "customer_status"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.customer_status))]) : _c('b-form-select', {
    attrs: {
      "id": "customer_status",
      "plain": "",
      "size": "md"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": ''
          }
        }, [_vm._v("Select Customer Status...")]), _c('b-form-select-option', {
          attrs: {
            "value": 'active'
          }
        }, [_vm._v("Active")]), _c('b-form-select-option', {
          attrs: {
            "value": 'on-hold'
          }
        }, [_vm._v("On Hold")]), _c('b-form-select-option', {
          attrs: {
            "value": 'credit-hold'
          }
        }, [_vm._v("Credit Hold")]), _c('b-form-select-option', {
          attrs: {
            "value": 'one-time'
          }
        }, [_vm._v("One-Time")]), _c('b-form-select-option', {
          attrs: {
            "value": 'inactive'
          }
        }, [_vm._v("Inactive")]), _c('b-form-select-option', {
          attrs: {
            "value": 'not-recommended'
          }
        }, [_vm._v("Not Recommended")]), _c('b-form-select-option', {
          attrs: {
            "value": 'blacklist'
          }
        }, [_vm._v("Blacklist")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formCustomer.customer_status,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_status", $$v);
      },
      expression: "formCustomer.customer_status"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "* Customer Class",
      "label-for": "customer_class_id"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.customerClassName(_vm.formCustomer.customer_class_id)))]) : _c('b-form-select', {
    attrs: {
      "id": "customer_class_id",
      "plain": "",
      "size": "md",
      "options": _vm.optionsCustomerClass
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Customer Class...")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formCustomer.customer_class_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_class_id", $$v);
      },
      expression: "formCustomer.customer_class_id"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "nav-fill mb-3",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": true,
            "href": "#general",
            "title": "General"
          },
          nativeOn: {
            "click": function click($event) {
              _vm.tab = 'GENERAL';
            }
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": false,
            "href": "#billing",
            "title": "Billing"
          },
          nativeOn: {
            "click": function click($event) {
              _vm.tab = 'BILLING';
            }
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": false,
            "href": "#shipping",
            "title": "Shipping"
          },
          nativeOn: {
            "click": function click($event) {
              _vm.tab = 'SHIPPING';
            }
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": false,
            "href": "#contact",
            "title": "Contact"
          },
          nativeOn: {
            "click": function click($event) {
              _vm.tab = 'CONTACT';
            }
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": false,
            "href": "#customer-file",
            "title": "Customer File"
          },
          nativeOn: {
            "click": function click($event) {
              _vm.tab = 'CUSTOMER_FILE';
            }
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "tab-content"
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "general"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3 mb-2"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Account Info")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "* Account Name",
      "label-for": "customer_name"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.account_name))]) : _c('b-form-input', {
    attrs: {
      "id": "account_name",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formCustomer.account_name,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "account_name", $$v);
      },
      expression: "formCustomer.account_name"
    }
  })], 1)], 1)], 1)], 1), _c('fieldset', {
    staticClass: "form-group border p-3 mb-2"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Account Address")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "* Country",
      "label-for": "customer_country_id"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.customer_country_id))]) : _vm._e(), _c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.optionsCountry,
      "reduce": function reduce(type) {
        return type.value;
      },
      "placeholder": "Select country..."
    },
    model: {
      value: _vm.formCustomer.customer_country_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_country_id", $$v);
      },
      expression: "formCustomer.customer_country_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Province",
      "label-for": "customer_province"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.customer_province))]) : _c('b-form-input', {
    attrs: {
      "id": "customer_province",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formCustomer.customer_province,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_province", $$v);
      },
      expression: "formCustomer.customer_province"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "City",
      "label-for": "customer_city"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.customer_city))]) : _c('b-form-input', {
    attrs: {
      "id": "customer_city",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formCustomer.customer_city,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_city", $$v);
      },
      expression: "formCustomer.customer_city"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "District",
      "label-for": "customer_district"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.customer_district))]) : _c('b-form-input', {
    attrs: {
      "id": "customer_district",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formCustomer.customer_district,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_district", $$v);
      },
      expression: "formCustomer.customer_district"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Village",
      "label-for": "customer_village"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.customer_village))]) : _c('b-form-input', {
    attrs: {
      "id": "customer_village",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formCustomer.customer_village,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_village", $$v);
      },
      expression: "formCustomer.customer_village"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Postal Code",
      "label-for": "customer_postal_code"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.customer_postal_code))]) : _c('b-form-input', {
    attrs: {
      "id": "customer_postal_code",
      "type": "number",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formCustomer.customer_postal_code,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_postal_code", $$v);
      },
      expression: "formCustomer.customer_postal_code"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Address",
      "label-for": "customer_address"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.customer_address))]) : _c('b-form-input', {
    attrs: {
      "id": "customer_address",
      "type": "textarea",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formCustomer.customer_address,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_address", $$v);
      },
      expression: "formCustomer.customer_address"
    }
  })], 1)], 1)], 1)], 1), _c('fieldset', {
    staticClass: "form-group border p-3 mb-2"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Additional Account Info")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Logo",
      "label-for": "logo"
    }
  }, [_vm.previewData ? _c('img', {
    staticStyle: {
      "width": "70px",
      "height": "70px"
    },
    attrs: {
      "src": (_vm$formCustomer = _vm.formCustomer) === null || _vm$formCustomer === void 0 ? void 0 : _vm$formCustomer.logo,
      "alt": "logo"
    }
  }) : _c('b-form-file', {
    attrs: {
      "id": "file",
      "accept": "image/*"
    },
    model: {
      value: _vm.formCustomer.logo,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "logo", $$v);
      },
      expression: "formCustomer.logo"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Web",
      "label-for": "customer_web"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.customer_web))]) : _c('b-form-input', {
    attrs: {
      "id": "customer_web",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formCustomer.customer_web,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_web", $$v);
      },
      expression: "formCustomer.customer_web"
    }
  })], 1)], 1)], 1)], 1)]), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Primary Contact")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fullname",
      "label-for": "customer_name"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.customer_name))]) : _c('b-form-input', {
    attrs: {
      "id": "customer_name",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formCustomer.customer_name,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_name", $$v);
      },
      expression: "formCustomer.customer_name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "customer_email"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.customer_email))]) : _c('b-form-input', {
    attrs: {
      "id": "customer_email",
      "type": "email",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formCustomer.customer_email,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_email", $$v);
      },
      expression: "formCustomer.customer_email"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Phone 1",
      "label-for": "customer_phone1"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.customer_phone1))]) : _c('b-form-input', {
    attrs: {
      "id": "customer_phone1",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formCustomer.customer_phone1,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_phone1", $$v);
      },
      expression: "formCustomer.customer_phone1"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Phone 2",
      "label-for": "customer_phone2"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.customer_phone2))]) : _c('b-form-input', {
    attrs: {
      "id": "customer_phone2",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formCustomer.customer_phone2,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_phone2", $$v);
      },
      expression: "formCustomer.customer_phone2"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Mobile 1",
      "label-for": "customer_mobile1"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.customer_mobile1))]) : _c('b-form-input', {
    attrs: {
      "id": "customer_mobile1",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formCustomer.customer_mobile1,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_mobile1", $$v);
      },
      expression: "formCustomer.customer_mobile1"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Mobile 2",
      "label-for": "customer_mobile2"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.customer_mobile2))]) : _c('b-form-input', {
    attrs: {
      "id": "customer_mobile2",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formCustomer.customer_mobile2,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_mobile2", $$v);
      },
      expression: "formCustomer.customer_mobile2"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Assistant",
      "label-for": "customer_assistant"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.customer_assistant))]) : _c('b-form-input', {
    attrs: {
      "id": "customer_assistant",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formCustomer.customer_assistant,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_assistant", $$v);
      },
      expression: "formCustomer.customer_assistant"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fax",
      "label-for": "customer_fax"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formCustomer.customer_fax))]) : _c('b-form-input', {
    attrs: {
      "id": "customer_fax",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formCustomer.customer_fax,
      callback: function callback($$v) {
        _vm.$set(_vm.formCustomer, "customer_fax", $$v);
      },
      expression: "formCustomer.customer_fax"
    }
  })], 1)], 1)], 1)], 1)])], 1)]), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "billing"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3 mb-2"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Bill-to Address")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formBilling.billing_from_general ? "From General" : "Not From General"))]) : _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.formBilling.billing_from_general,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_from_general", $$v);
      },
      expression: "formBilling.billing_from_general"
    }
  }, [_vm._v(" From General ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "* Country",
      "label-for": "billing_country_id"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formBilling.billing_country_id))]) : _vm._e(), _c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.optionsCountry,
      "reduce": function reduce(type) {
        return type.value;
      },
      "placeholder": "Select country..."
    },
    model: {
      value: _vm.formBilling.billing_country_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_country_id", $$v);
      },
      expression: "formBilling.billing_country_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Province",
      "label-for": "billing_province"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formBilling.billing_province))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_province",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.billing_province,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_province", $$v);
      },
      expression: "formBilling.billing_province"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "City",
      "label-for": "billing_city"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formBilling.billing_city))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_city",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.billing_city,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_city", $$v);
      },
      expression: "formBilling.billing_city"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "District",
      "label-for": "billing_district"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formBilling.billing_district))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_district",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.billing_district,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_district", $$v);
      },
      expression: "formBilling.billing_district"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Village",
      "label-for": "billing_village"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formBilling.billing_village))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_village",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.billing_village,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_village", $$v);
      },
      expression: "formBilling.billing_village"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Postal Code",
      "label-for": "billing_postal_code"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formBilling.billing_postal_code))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_postal_code",
      "type": "number",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.billing_postal_code,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_postal_code", $$v);
      },
      expression: "formBilling.billing_postal_code"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Address",
      "label-for": "billing_address"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formBilling.billing_address))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_address",
      "type": "textarea",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.billing_address,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_address", $$v);
      },
      expression: "formBilling.billing_address"
    }
  })], 1)], 1)], 1)], 1), _c('fieldset', {
    staticClass: "form-group border p-3 mb-2"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Bill-to Info")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Account Name",
      "label-for": "billing_account_name"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s((_vm$formBilling$billi = (_vm$formBilling = _vm.formBilling) === null || _vm$formBilling === void 0 ? void 0 : _vm$formBilling.billing_account_name) !== null && _vm$formBilling$billi !== void 0 ? _vm$formBilling$billi : '-'))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_account_name",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.billing_account_name,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_account_name", $$v);
      },
      expression: "formBilling.billing_account_name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Attention",
      "label-for": "billing_attention"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s((_vm$formBilling$billi2 = (_vm$formBilling2 = _vm.formBilling) === null || _vm$formBilling2 === void 0 ? void 0 : _vm$formBilling2.billing_attention) !== null && _vm$formBilling$billi2 !== void 0 ? _vm$formBilling$billi2 : '-'))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_attention",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.billing_attention,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_attention", $$v);
      },
      expression: "formBilling.billing_attention"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bussiness 1",
      "label-for": "billing_business1"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s((_vm$formBilling$billi3 = (_vm$formBilling3 = _vm.formBilling) === null || _vm$formBilling3 === void 0 ? void 0 : _vm$formBilling3.billing_business1) !== null && _vm$formBilling$billi3 !== void 0 ? _vm$formBilling$billi3 : '-'))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_business1",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.billing_business1,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_business1", $$v);
      },
      expression: "formBilling.billing_business1"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bussiness 2",
      "label-for": "billing_business2"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s((_vm$formBilling$billi4 = (_vm$formBilling4 = _vm.formBilling) === null || _vm$formBilling4 === void 0 ? void 0 : _vm$formBilling4.billing_business2) !== null && _vm$formBilling$billi4 !== void 0 ? _vm$formBilling$billi4 : '-'))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_business2",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.billing_business2,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_business2", $$v);
      },
      expression: "formBilling.billing_business2"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bussiness 3",
      "label-for": "billing_business3"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s((_vm$formBilling$billi5 = (_vm$formBilling5 = _vm.formBilling) === null || _vm$formBilling5 === void 0 ? void 0 : _vm$formBilling5.billing_business3) !== null && _vm$formBilling$billi5 !== void 0 ? _vm$formBilling$billi5 : '-'))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_business3",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.billing_business3,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_business3", $$v);
      },
      expression: "formBilling.billing_business3"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cell",
      "label-for": "billing_cell"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s((_vm$formBilling$billi6 = (_vm$formBilling6 = _vm.formBilling) === null || _vm$formBilling6 === void 0 ? void 0 : _vm$formBilling6.billing_cell) !== null && _vm$formBilling$billi6 !== void 0 ? _vm$formBilling$billi6 : '-'))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_cell",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.billing_cell,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_cell", $$v);
      },
      expression: "formBilling.billing_cell"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Assistant",
      "label-for": "billing_assistant"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s((_vm$formBilling$billi7 = (_vm$formBilling7 = _vm.formBilling) === null || _vm$formBilling7 === void 0 ? void 0 : _vm$formBilling7.billing_assistant) !== null && _vm$formBilling$billi7 !== void 0 ? _vm$formBilling$billi7 : '-'))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_assistant",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.billing_assistant,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_assistant", $$v);
      },
      expression: "formBilling.billing_assistant"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fax",
      "label-for": "billing_fax"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s((_vm$formBilling$billi8 = (_vm$formBilling8 = _vm.formBilling) === null || _vm$formBilling8 === void 0 ? void 0 : _vm$formBilling8.billing_fax) !== null && _vm$formBilling$billi8 !== void 0 ? _vm$formBilling$billi8 : '-'))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_fax",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.billing_fax,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_fax", $$v);
      },
      expression: "formBilling.billing_fax"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Home",
      "label-for": "billing_home"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s((_vm$formBilling$billi9 = (_vm$formBilling9 = _vm.formBilling) === null || _vm$formBilling9 === void 0 ? void 0 : _vm$formBilling9.billing_home) !== null && _vm$formBilling$billi9 !== void 0 ? _vm$formBilling$billi9 : '-'))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_home",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.billing_home,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_home", $$v);
      },
      expression: "formBilling.billing_home"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Home Fax",
      "label-for": "billing_home_fax"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s((_vm$formBilling$billi10 = (_vm$formBilling10 = _vm.formBilling) === null || _vm$formBilling10 === void 0 ? void 0 : _vm$formBilling10.billing_home_fax) !== null && _vm$formBilling$billi10 !== void 0 ? _vm$formBilling$billi10 : '-'))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_home_fax",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.billing_home_fax,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_home_fax", $$v);
      },
      expression: "formBilling.billing_home_fax"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "billing_email"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s((_vm$formBilling$billi11 = (_vm$formBilling11 = _vm.formBilling) === null || _vm$formBilling11 === void 0 ? void 0 : _vm$formBilling11.billing_email) !== null && _vm$formBilling$billi11 !== void 0 ? _vm$formBilling$billi11 : '-'))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_email",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.billing_email,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_email", $$v);
      },
      expression: "formBilling.billing_email"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Web",
      "label-for": "billing_web"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s((_vm$formBilling$billi12 = (_vm$formBilling12 = _vm.formBilling) === null || _vm$formBilling12 === void 0 ? void 0 : _vm$formBilling12.billing_web) !== null && _vm$formBilling$billi12 !== void 0 ? _vm$formBilling$billi12 : '-'))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_web",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.billing_web,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "billing_web", $$v);
      },
      expression: "formBilling.billing_web"
    }
  })], 1)], 1)], 1)], 1)]), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Parent Info")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v("Consolidate Balance? "), _c('br'), _vm._v(" " + _vm._s(_vm.formBilling.consolidate_balance ? "√" : "x"))]) : _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.formBilling.consolidate_balance,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "consolidate_balance", $$v);
      },
      expression: "formBilling.consolidate_balance"
    }
  }, [_vm._v(" Consolidate Balance ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v("Consolidate Statements? "), _c('br'), _vm._v(" " + _vm._s(_vm.formBilling.consolidate_statements ? "√" : "x"))]) : _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.formBilling.consolidate_statements,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "consolidate_statements", $$v);
      },
      expression: "formBilling.consolidate_statements"
    }
  }, [_vm._v(" Consolidate Statements ")])], 1)], 1)], 1)], 1), _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Print and Email Settings")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-form-group', [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v("Send invoice by email? "), _c('br'), _vm._v(" " + _vm._s(_vm.formBilling.send_invoice_by_email ? "√" : "x"))]) : _c('b-form-checkbox', {
    staticClass: "mr-2",
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.formBilling.send_invoice_by_email,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "send_invoice_by_email", $$v);
      },
      expression: "formBilling.send_invoice_by_email"
    }
  }, [_vm._v(" Send Invoices by Email ")])], 1), _c('b-form-group', [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v("Print Invoices? "), _c('br'), _vm._v(" " + _vm._s(_vm.formBilling.print_invoices ? "√" : "x"))]) : _c('b-form-checkbox', {
    staticClass: "mr-2",
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.formBilling.print_invoices,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "print_invoices", $$v);
      },
      expression: "formBilling.print_invoices"
    }
  }, [_vm._v(" Print Invoices ")])], 1)], 1)]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-form-group', [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v("Send Statement Email? "), _c('br'), _vm._v(" " + _vm._s(_vm.formBilling.send_statement_email ? "√" : "x"))]) : _c('b-form-checkbox', {
    staticClass: "mr-2",
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.formBilling.send_statement_email,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "send_statement_email", $$v);
      },
      expression: "formBilling.send_statement_email"
    }
  }, [_vm._v(" Send Statements by Email ")])], 1), _c('b-form-group', [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v("Print Statements? "), _c('br'), _vm._v(" " + _vm._s(_vm.formBilling.print_statements ? "√" : "x"))]) : _c('b-form-checkbox', {
    staticClass: "mr-2",
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.formBilling.print_statements,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "print_statements", $$v);
      },
      expression: "formBilling.print_statements"
    }
  }, [_vm._v(" Print Statements ")])], 1)], 1)]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "* Statement Type",
      "label-for": "statement_type"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formBilling.statement_type))]) : _c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "sm",
      "id": "statement_type"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": ''
          }
        }, [_vm._v("Select Statement Type...")]), _c('b-form-select-option', {
          attrs: {
            "value": 'open-item'
          }
        }, [_vm._v("Open Item")]), _c('b-form-select-option', {
          attrs: {
            "value": 'balance-brought-forward'
          }
        }, [_vm._v("Balance Brought Forward")]), _c('b-form-select-option', {
          attrs: {
            "value": 'custom'
          }
        }, [_vm._v("Custom")]), _c('b-form-select-option', {
          attrs: {
            "value": 'none'
          }
        }, [_vm._v("None")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formBilling.statement_type,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "statement_type", $$v);
      },
      expression: "formBilling.statement_type"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v("Multi Currency Statements? "), _c('br'), _vm._v(" " + _vm._s(_vm.formBilling.multi_currency_statements ? "√" : "x"))]) : _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.formBilling.multi_currency_statements,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "multi_currency_statements", $$v);
      },
      expression: "formBilling.multi_currency_statements"
    }
  }, [_vm._v(" Multi-Currency Statements ")])], 1)], 1)], 1)], 1)])], 1)]), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "shipping"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3 mb-2"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Ship-to Address")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v("Shipping from general? " + _vm._s(_vm.formShipping.shipping_from_general ? "√" : "x"))]) : _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.formShipping.shipping_from_general,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_from_general", $$v);
      },
      expression: "formShipping.shipping_from_general"
    }
  }, [_vm._v(" From General ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "* Country",
      "label-for": "shipping_country_id"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_country_id))]) : _vm._e(), _c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.optionsCountry,
      "reduce": function reduce(type) {
        return type.value;
      },
      "placeholder": "Select country..."
    },
    model: {
      value: _vm.formShipping.shipping_country_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_country_id", $$v);
      },
      expression: "formShipping.shipping_country_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Province",
      "label-for": "billing_province"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_province))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_province",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.shipping_province,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_province", $$v);
      },
      expression: "formShipping.shipping_province"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "City",
      "label-for": "billing_city"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_city))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_city",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.shipping_city,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_city", $$v);
      },
      expression: "formShipping.shipping_city"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "District",
      "label-for": "billing_district"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_district))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_district",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formBilling.shipping_district,
      callback: function callback($$v) {
        _vm.$set(_vm.formBilling, "shipping_district", $$v);
      },
      expression: "formBilling.shipping_district"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Village",
      "label-for": "billing_village"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_village))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_village",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.shipping_village,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_village", $$v);
      },
      expression: "formShipping.shipping_village"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Postal Code",
      "label-for": "billing_postal_code"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_postal_code))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_postal_code",
      "type": "number",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.shipping_postal_code,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_postal_code", $$v);
      },
      expression: "formShipping.shipping_postal_code"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Address",
      "label-for": "billing_address"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_address))]) : _c('b-form-input', {
    attrs: {
      "id": "billing_address",
      "type": "textarea",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.shipping_address,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_address", $$v);
      },
      expression: "formShipping.shipping_address"
    }
  })], 1)], 1)], 1)], 1), _c('fieldset', {
    staticClass: "form-group border p-3 mb-2"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Ship-to Info")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Account Name",
      "label-for": "shipping_account_name"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_account_name))]) : _c('b-form-input', {
    attrs: {
      "id": "shipping_account_name",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.shipping_account_name,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_account_name", $$v);
      },
      expression: "formShipping.shipping_account_name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Attention",
      "label-for": "shipping_attention"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_attention))]) : _c('b-form-input', {
    attrs: {
      "id": "shipping_attention",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.shipping_attention,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_attention", $$v);
      },
      expression: "formShipping.shipping_attention"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bussiness 1",
      "label-for": "shipping_business1"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_business1))]) : _c('b-form-input', {
    attrs: {
      "id": "shipping_business1",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.shipping_business1,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_business1", $$v);
      },
      expression: "formShipping.shipping_business1"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bussiness 2",
      "label-for": "shipping_business2"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_business2))]) : _c('b-form-input', {
    attrs: {
      "id": "shipping_business2",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.shipping_business2,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_business2", $$v);
      },
      expression: "formShipping.shipping_business2"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bussiness 3",
      "label-for": "shipping_business3"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_business3))]) : _c('b-form-input', {
    attrs: {
      "id": "shipping_business3",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.shipping_business3,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_business3", $$v);
      },
      expression: "formShipping.shipping_business3"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cell",
      "label-for": "shipping_cell"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_cell))]) : _c('b-form-input', {
    attrs: {
      "id": "shipping_cell",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.shipping_cell,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_cell", $$v);
      },
      expression: "formShipping.shipping_cell"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Assistant",
      "label-for": "shipping_assistant"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_assistant))]) : _c('b-form-input', {
    attrs: {
      "id": "shipping_assistant",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.shipping_assistant,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_assistant", $$v);
      },
      expression: "formShipping.shipping_assistant"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fax",
      "label-for": "shipping_fax"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_fax))]) : _c('b-form-input', {
    attrs: {
      "id": "shipping_fax",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.shipping_fax,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_fax", $$v);
      },
      expression: "formShipping.shipping_fax"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Home",
      "label-for": "shipping_home"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_home))]) : _c('b-form-input', {
    attrs: {
      "id": "shipping_home",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.shipping_home,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_home", $$v);
      },
      expression: "formShipping.shipping_home"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Home Fax",
      "label-for": "shipping_home_fax"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_home_fax))]) : _c('b-form-input', {
    attrs: {
      "id": "shipping_home_fax",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.shipping_home_fax,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_home_fax", $$v);
      },
      expression: "formShipping.shipping_home_fax"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "shipping_email"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_email))]) : _c('b-form-input', {
    attrs: {
      "id": "shipping_email",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.shipping_email,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_email", $$v);
      },
      expression: "formShipping.shipping_email"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Web",
      "label-for": "shipping_web"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.shipping_web))]) : _c('b-form-input', {
    attrs: {
      "id": "shipping_web",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.shipping_web,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "shipping_web", $$v);
      },
      expression: "formShipping.shipping_web"
    }
  })], 1)], 1)], 1)], 1)]), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Tax Settings")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tax Registration ID",
      "label-for": "tax_registration_number"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.tax_registration_number))]) : _c('b-form-input', {
    attrs: {
      "id": "tax_registration_number",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.tax_registration_number,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "tax_registration_number", $$v);
      },
      expression: "formShipping.tax_registration_number"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tax Zone",
      "label-for": "tax_zone_id"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.tax_zone_id))]) : _c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "sm",
      "id": "tax_zone_id",
      "options": _vm.optionsTaxZone
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Tax zone...")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formShipping.tax_zone_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "tax_zone_id", $$v);
      },
      expression: "formShipping.tax_zone_id"
    }
  })], 1)], 1)], 1)], 1), _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Shipping Instructions")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Warehouse",
      "label-for": "si_warehouse"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.si_warehouse))]) : _c('v-select', {
    attrs: {
      "label": "text",
      "options": [],
      "reduce": function reduce(type) {
        return type === null || type === void 0 ? void 0 : type.value;
      },
      "placeholder": "Select..."
    },
    model: {
      value: _vm.formShipping.si_warehouse,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "si_warehouse", $$v);
      },
      expression: "formShipping.si_warehouse"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Ship Via",
      "label-for": "si_ship_via"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.si_ship_via))]) : _c('b-form-input', {
    attrs: {
      "id": "si_ship_via",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.si_ship_via,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "si_ship_via", $$v);
      },
      expression: "formShipping.si_ship_via"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Shipping Terms",
      "label-for": "si_shipping_terms_id"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s((_vm$formShipping$si_s = _vm.formShipping.si_shipping_terms) === null || _vm$formShipping$si_s === void 0 ? void 0 : _vm$formShipping$si_s.code))]) : _c('b-form-select', {
    attrs: {
      "id": "si_shipping_terms_id",
      "plain": "",
      "size": "md",
      "options": _vm.optionsTerms
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select terms....")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formShipping.si_shipping_terms_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "si_shipping_terms_id", $$v);
      },
      expression: "formShipping.si_shipping_terms_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Shipping Zone",
      "label-for": "si_shipping_zone"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.si_shipping_zone))]) : _c('b-form-input', {
    attrs: {
      "id": "si_shipping_zone",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.si_shipping_zone,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "si_shipping_zone", $$v);
      },
      expression: "formShipping.si_shipping_zone"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "FOB Point",
      "label-for": "si_fob_point"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.si_fob_point))]) : _c('b-form-input', {
    attrs: {
      "id": "si_fob_point",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formShipping.si_fob_point,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "si_fob_point", $$v);
      },
      expression: "formShipping.si_fob_point"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v("Residential Delivery? " + _vm._s(_vm.formShipping.si_residential_delivery ? "√" : "x"))]) : _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.formShipping.si_residential_delivery,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "si_residential_delivery", $$v);
      },
      expression: "formShipping.si_residential_delivery"
    }
  }, [_vm._v(" Residential Delivery ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v("Saturday Delivery? " + _vm._s(_vm.formShipping.si_saturday_delivery ? "√" : "x"))]) : _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.formShipping.si_saturday_delivery,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "si_saturday_delivery", $$v);
      },
      expression: "formShipping.si_saturday_delivery"
    }
  }, [_vm._v(" Saturday Delivery ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v("Insurance? " + _vm._s(_vm.formShipping.si_insurance ? "√" : "x"))]) : _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.formShipping.si_insurance,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "si_insurance", $$v);
      },
      expression: "formShipping.si_insurance"
    }
  }, [_vm._v(" Insurance ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "* Shipping Rule",
      "label-for": "shipping_rule"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.si_shipping_rule))]) : _c('b-form-select', {
    attrs: {
      "id": "shipping_rule",
      "plain": "",
      "size": "md"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": ''
          }
        }, [_vm._v("Select Shipping Rule...")]), _c('b-form-select-option', {
          attrs: {
            "value": 'ship-complete'
          }
        }, [_vm._v("Ship Complete")]), _c('b-form-select-option', {
          attrs: {
            "value": 'back-order-allowed'
          }
        }, [_vm._v("Back Order Allowed")]), _c('b-form-select-option', {
          attrs: {
            "value": 'cancel-reminder'
          }
        }, [_vm._v("Cancel Remainder")]), _c('b-form-select-option', {
          attrs: {
            "value": 'custom'
          }
        }, [_vm._v("Custom")]), _c('b-form-select-option', {
          attrs: {
            "value": 'none'
          }
        }, [_vm._v("None")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formShipping.si_shipping_rule,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "si_shipping_rule", $$v);
      },
      expression: "formShipping.si_shipping_rule"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Order Priority",
      "label-for": "si_order_priority"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.si_order_priority))]) : _c('b-form-input', {
    attrs: {
      "id": "si_order_priority",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.formShipping.si_order_priority,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "si_order_priority", $$v);
      },
      expression: "formShipping.si_order_priority"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Lead Time (Days)",
      "label-for": "si_lead_time_days"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.si_lead_time_days))]) : _c('b-form-input', {
    attrs: {
      "id": "si_lead_time_days",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.formShipping.si_lead_time_days,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "si_lead_time_days", $$v);
      },
      expression: "formShipping.si_lead_time_days"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Calendar",
      "label-for": "si_calendar"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.formShipping.si_calendar))]) : _c('b-form-input', {
    attrs: {
      "id": "si_calendar",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.formShipping.si_calendar,
      callback: function callback($$v) {
        _vm.$set(_vm.formShipping, "si_calendar", $$v);
      },
      expression: "formShipping.si_calendar"
    }
  })], 1)], 1)], 1)], 1)])], 1)]), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "contact"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3 mb-2"
  }, [_c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('a', {
    staticClass: "text-primary mr-4",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "role": "button"
    },
    on: {
      "click": _vm.addRowContact
    }
  }, [_c('i', {
    staticClass: "fa fa-plus mr-2"
  }), _c('span', [_vm._v("Add Row")])])])])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped table-bordered"
  }, [_c('thead', [_c('tr', [!_vm.previewData ? _c('th', {
    staticClass: "text-center align-middle"
  }, [_vm._v("#")]) : _vm._e(), _c('th', {
    staticClass: "text-center align-middle"
  }, [_vm._v("No")]), _c('th', {
    staticClass: "text-left align-middle",
    staticStyle: {
      "min-width": "200px"
    }
  }, [_vm._v("Contact")]), _c('th', {
    staticClass: "text-left align-middle",
    staticStyle: {
      "min-width": "200px"
    }
  }, [_vm._v("Job Title")]), _c('th', {
    staticClass: "text-left align-middle",
    staticStyle: {
      "min-width": "200px"
    }
  }, [_vm._v("Division")]), _c('th', {
    staticClass: "text-left align-middle",
    staticStyle: {
      "min-width": "200px"
    }
  }, [_vm._v("Email")]), _c('th', {
    staticClass: "text-left align-middle",
    staticStyle: {
      "min-width": "200px"
    }
  }, [_vm._v("Phone")]), _c('th', {
    staticClass: "text-left align-middle",
    staticStyle: {
      "min-width": "200px"
    }
  }, [_vm._v("Mobile Phone")])])]), _c('tbody', _vm._l(_vm.formContact, function (state, index) {
    return _c('tr', {
      key: index
    }, [!_vm.previewData ? _c('td', [_c('i', {
      staticClass: "fa fa-trash text-danger",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.formContact.splice(index, 1);
        }
      }
    })]) : _vm._e(), _c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_c('b-form-group', [_c('b-form-input', {
      attrs: {
        "id": "contact_name",
        "type": "text",
        "placeholder": "Type text",
        "disabled": _vm.previewData
      },
      model: {
        value: state.contact_name,
        callback: function callback($$v) {
          _vm.$set(state, "contact_name", $$v);
        },
        expression: "state.contact_name"
      }
    })], 1)], 1), _c('td', [_c('b-form-group', [_c('b-form-input', {
      attrs: {
        "id": "contact_job_title",
        "type": "text",
        "placeholder": "Type text",
        "disabled": _vm.previewData
      },
      model: {
        value: state.contact_job_title,
        callback: function callback($$v) {
          _vm.$set(state, "contact_job_title", $$v);
        },
        expression: "state.contact_job_title"
      }
    })], 1)], 1), _c('td', [_c('b-form-group', [_c('b-form-input', {
      attrs: {
        "id": "contact_division",
        "type": "text",
        "placeholder": "Type text",
        "disabled": _vm.previewData
      },
      model: {
        value: state.contact_division,
        callback: function callback($$v) {
          _vm.$set(state, "contact_division", $$v);
        },
        expression: "state.contact_division"
      }
    })], 1)], 1), _c('td', [_c('b-form-group', [_c('b-form-input', {
      attrs: {
        "id": "contact_email",
        "type": "text",
        "placeholder": "Type text",
        "disabled": _vm.previewData
      },
      model: {
        value: state.contact_email,
        callback: function callback($$v) {
          _vm.$set(state, "contact_email", $$v);
        },
        expression: "state.contact_email"
      }
    })], 1)], 1), _c('td', [_c('b-form-group', [_c('b-form-input', {
      attrs: {
        "id": "contact_phone",
        "type": "text",
        "placeholder": "Type text",
        "disabled": _vm.previewData
      },
      model: {
        value: state.contact_phone,
        callback: function callback($$v) {
          _vm.$set(state, "contact_phone", $$v);
        },
        expression: "state.contact_phone"
      }
    })], 1)], 1), _c('td', [_c('b-form-group', [_c('b-form-input', {
      attrs: {
        "id": "contact_mobile",
        "type": "text",
        "placeholder": "Type text",
        "disabled": _vm.previewData
      },
      model: {
        value: state.contact_mobile,
        callback: function callback($$v) {
          _vm.$set(state, "contact_mobile", $$v);
        },
        expression: "state.contact_mobile"
      }
    })], 1)], 1)]);
  }), 0)])])])], 1)], 1)])], 1)]), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "customer-file"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3 mb-2"
  }, [_c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('a', {
    staticClass: "text-primary mr-4",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "role": "button"
    },
    on: {
      "click": _vm.addRowVendor
    }
  }, [_c('i', {
    staticClass: "fa fa-plus mr-2"
  }), _c('span', [_vm._v("Add Row")])])])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped table-bordered"
  }, [_c('thead', [_c('tr', [!_vm.previewData ? _c('th', {
    staticClass: "text-center align-middle",
    staticStyle: {
      "width": "30px"
    }
  }, [_vm._v("#")]) : _vm._e(), _c('th', {
    staticClass: "text-center align-middle",
    staticStyle: {
      "width": "20px"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "text-left align-middle",
    staticStyle: {
      "min-width": "300px"
    }
  }, [_vm._v("Document List")]), _c('th', {
    staticClass: "text-left align-middle",
    staticStyle: {
      "min-width": "150px"
    }
  }, [_vm._v("File")])])]), _c('tbody', _vm._l(_vm.formVendorFile, function (state, index) {
    return _c('tr', {
      key: index
    }, [!_vm.previewData ? _c('td', [_c('i', {
      staticClass: "fa fa-trash text-danger",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.formVendorFile.splice(index, 1);
        }
      }
    })]) : _vm._e(), _c('td', {
      staticStyle: {
        "width": "20px"
      }
    }, [_vm._v(_vm._s(index + 1))]), _c('td', [_c('b-form-select', {
      attrs: {
        "id": "document_list_id",
        "plain": "",
        "required": "",
        "size": "md",
        "options": _vm.optionsDocumentList,
        "disabled": _vm.previewData
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select Document...")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: state.document_list_id,
        callback: function callback($$v) {
          _vm.$set(state, "document_list_id", $$v);
        },
        expression: "state.document_list_id"
      }
    })], 1), _c('td', [_c('b-form-group', [_c('b-form-file', {
      staticClass: "form-control",
      attrs: {
        "plain": "",
        "id": "file",
        "accept": "image/*,application/*",
        "multiple": "",
        "disabled": _vm.previewData
      },
      model: {
        value: state.file,
        callback: function callback($$v) {
          _vm.$set(state, "file", $$v);
        },
        expression: "state.file"
      }
    })], 1)], 1)]);
  }), 0)])])])], 1)], 1)])], 1)])], 1)])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }