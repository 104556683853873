<template>
  <b-row>
    <b-col lg="12">
      <b-row>
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">Email Template</h4>
            </template>
            <!-- <template v-slot:headerAction>
              <b-breadcrumb
                class="mt-3"
                :items="breadcrumIcon"
                style="background: none"
              />
            </template> -->   
            <template v-slot:body>
              <b-row>
                <b-col cols="4" class="d-flex">
                  <b-form-input
                    id="fleet_name"
                    type="text"
                    placeholder="Search templates..."
                  ></b-form-input>
                  <b-button variant="primary" class="ml-2">
                    <i class="fa fa-search"></i>
                  </b-button>
                </b-col>
                <b-col cols="6"> </b-col>
                <b-col cols="2">
                  <b-button block variant="primary" @click="openSlideCreateEmail">
                    <template>
                      <i class="fa fa-plus"></i> &nbsp; Create
                    </template>
                    <!-- <template>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Exporting...
                    </template> -->
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <b-row>
        <!-- Card Fleet -->
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">List Data</h4>
            </template>
            <template v-slot:headerAction>
              <b-pagination
                class="mt-3"
                :total-rows="1"
                :per-page="1"
                first-number
                align="center"
              ></b-pagination>
            </template>
            <template v-slot:body>
              <!-- <div v-if="ports._loading" class="text-center my-5">
                <b-spinner type="grow" label="Spinning"></b-spinner>
              </div> -->
              <template>
                <div class="d-flex flex-column overflow-auto">
                  <table class="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th width="5%" class="text-center"><b-form-checkbox type="checkbox"></b-form-checkbox></th>
                        <th width="30%" class="text-truncate">NAME</th>
                        <th width="20%" class="text-truncate">APPLIES TO</th>
                        <th width="30%" class="text-truncate">SUBJECT</th>
                        <th width="30%" class="text-truncate">FROM</th>
                        <th width="30%" class="text-truncate">TO (EMAILS)</th>
                        <th width="30%" class="text-truncate">TO (PARTNERS)</th>
                        <th width="30%" class="text-truncate">REPORT FILENAME</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(state, index) in dataTable" :key="index" style="cursor:pointer" @click="openSlideCreateEmail">
                        <td class="text-truncate text-center"><b-form-checkbox type="checkbox"></b-form-checkbox></td>
                        <td class="text-truncate">{{ state?.name }}</td>
                        <td class="text-truncate">{{ state?.applies_to }}</td>
                        <td class="text-truncate">{{ state?.subject }}</td>
                        <td class="text-truncate">{{ state?.from }}</td>
                        <td class="text-truncate">{{ state?.to_emails }}</td>
                        <td class="text-truncate">{{ state?.to_partners }}</td>
                        <td class="text-truncate">{{ state?.report_filename }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- <p class="text-center my-4">No ports data found.</p> -->
              </template>
            </template>
          </iq-card>
        </b-col>
        <!-- End Card Fleet -->
      </b-row>
    </b-col>

    <b-col lg="12">
      <!-- SLIDE - CREATE EMAIL -->
      <b-sidebar
        v-model="slideCreateEmail"
        id="slideCreateEmail"
        :title="'Create Email Template'"
        width="100%"
        backdrop-variant="dark"
        bg-variant="white"
        right
        backdrop
        shadow>
        <template #default="{hide}">
          <SlideFormCreateEmail  :openSlideCreateEmail="openSlideCreateEmail" />
        </template>
      </b-sidebar>
      <!-- SLIDE - CREATE EMAIL END -->
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'
import { saveAs } from 'file-saver'
import { portsActions, usersActions } from '@src/Utils/helper'
import SlideFormCreateEmail from './SlideFormCreateEmail.vue'

export default {
  name: 'TemplateEmail',
  data () {
    return {
      slideCreateEmail:false,
      dataTable: [
        {
          name: 'Applicant: Acknowledgement',
          applies_to:'Applicant',
          subject:'Your Job Application: ${object.job_id.name | safe}',
          from:'noreply@mailing.hashmicro.com',
          to_emails:'${(not object.partner_id and object.email_from or") | safe}',
          to_partners:'${object.partner_id.id or"}',
          report_filename:''
        },
        {
          name: 'Applicant: Acknowledgement',
          applies_to:'Applicant',
          subject:'Your Job Application: ${object.job_id.name | safe}',
          from:'noreply@mailing.hashmicro.com',
          to_emails:'${(not object.partner_id and object.email_from or") | safe}',
          to_partners:'${object.partner_id.id or"}',
          report_filename:''
        },
        {
          name: 'Applicant: Acknowledgement',
          applies_to:'Applicant',
          subject:'Your Job Application: ${object.job_id.name | safe}',
          from:'noreply@mailing.hashmicro.com',
          to_emails:'${(not object.partner_id and object.email_from or") | safe}',
          to_partners:'${object.partner_id.id or"}',
          report_filename:''
        }
      ]
    }
  },
  components:{
    SlideFormCreateEmail
  },
  mounted () {},
  methods: {
    openSlideCreateEmail(){
      this.slideCreateEmail = !this.slideCreateEmail
    }
  }
}
</script>