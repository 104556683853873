<template>
  <b-container fluid>
    <b-row class="mt-1">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>Crewing Report Dashboard</strong></h4>
          </template>
          <template v-slot:headerAction>
            <a
              class="text-primary float-right ml-3"
              v-b-toggle.filter
              role="button"
              style="cursor: pointer"
            >
              <i class="fa fa-filter" />
            </a>
          </template>
          <template v-slot:body>
            <b-collapse visible id="filter">
              <b-row>
                <b-col md="3" class="mr-5">
                  <b-form-group label-for="dateIssued" label="Date Range Filter">
                    <date-picker :lang="lang" v-model="summary.params.dateRange" type="date" range placeholder="Select date range"></date-picker>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label-for="dateIssued" label="Select Fleet">
                    <v-select
                      label="text"
                      v-model="summary.params.vehicle_ids"
                      :options="kapal"
                      :reduce="(type) => type.value"
                      placeholder="select Fleet"
                    ></v-select>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label-for="dateIssued" label="Search">
                    <b-button @click="handleFilterDashboard" variant="primary" class="mb-3 mr-1"><i class="fa fa-search"></i>
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-collapse>
            <hr />
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <div v-if="isLoading" class="alert alert-info">
      Loading...
    </div>
    <template v-else>
      <!-- COUNTING -->
      <b-row>
        <b-col md="6" lg="4">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary rounded">
            <template v-slot:body>
              <a href="#" @click.prevent="">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-primary"><i class="las la-users"></i></div>
                  <div class="text-right">
                    <h2 class="mb-0"><span class="counter">{{ summary?.data?.active ?? 0 }}</span></h2>
                    <h5 class="">Active Crew</h5>
                  </div>
                </div>
              </a>
            </template>
          </iq-card>
        </b-col>
        <b-col md="6" lg="4">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-success rounded">
            <template v-slot:body>
              <a href="#" @click.prevent="">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-success"><i class="las la-id-card-alt"></i></div>
                  <div class="text-right">
                    <h2 class="mb-0"><span class="counter">{{ summary?.data?.new_recruitment  }}</span></h2>
                    <h5 class="">New Recruitment</h5>
                  </div>
                </div>
              </a>
            </template>
          </iq-card>
        </b-col>
        <b-col md="6" lg="4">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-warning rounded">
            <template v-slot:body>
              <a href="#" @click.prevent="">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-warning"><i class="las la-id-card-alt"></i></div>
                  <div class="text-right">
                    <h2 class="mb-0"><span class="counter">{{ summary?.data?.finish }}</span></h2>
                    <h5 class="">Finish</h5>
                  </div>
                </div>
              </a>
            </template>
          </iq-card>
        </b-col>
        <b-col md="6" lg="4">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-warning rounded">
            <template v-slot:body>
              <a href="#" @click.prevent="">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-warning"><i class="las la-exchange-alt"></i></div>
                  <div class="text-right">
                    <h2 class="mb-0"><span class="counter">{{ summary?.data?.swap }}</span></h2>
                    <h5 class="">Swap / Rotation</h5>
                  </div>
                </div>
              </a>
            </template>
          </iq-card>
        </b-col>
        <b-col md="6" lg="4">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-default rounded">
            <template v-slot:body>
              <a href="#" @click.prevent="">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-dark"><i class="las la-crosshairs"></i></div>
                  <div class="text-right">
                    <h2 class="mb-0"><span class="counter">{{ summary?.data?.unfinish }}</span></h2>
                    <h5 class="">Unfinished</h5>
                  </div>
                </div>
              </a>
            </template>
          </iq-card>
        </b-col>
        <b-col md="6" lg="4">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-danger rounded">
            <template v-slot:body>
              <a href="#" @click.prevent="">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-danger"><i class="las la-window-close"></i></div>
                  <div class="text-right">
                    <h2 class="mb-0"><span class="counter">{{ summary?.data?.blacklist }}</span></h2>
                    <h5 class="">Total Blacklist</h5>
                  </div>
                </div>
              </a>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>Crew List Availability</strong></h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="12">
                  <table class="table table-bordered">
                    <thead>
                    <tr>
                      <th class="bg-success text-center">NO</th>
                      <th class="bg-success text-center">Fleet</th>
                      <th class="bg-success text-center">Active Crew</th>
                      <th class="bg-success text-center">Crew Needed</th>
                    </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(crew, i) in displayedAvaibility" :key="'crew-' + i" >
                        <td class="text-center">
                            {{ startRowIndexAvaibility + i }}
                        </td>
                        <td class="text-center">
                         {{ crew?.fleet }}
                        </td>
                        <td class="text-center">
                          {{ crew?.active_crew }}
                        </td>
                        <td class="text-center">
                          {{ crew?.available }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-3" v-if="displayedAvaibility?.length > 0">
                    <b-pagination
                      v-model="availability.currentPage"
                      :total-rows="totalAvaibility"
                      :per-page="availability.perPage"
                      aria-controls="my-table"
                      align="center"
                      first-number
                    />
                  </div>
                  <p v-else class="text-center my-4"
                    >List Avaibility Crew tidak ada.</p
                  >
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
        <b-col md="6">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>Delay (Idle) Crewing Factor</strong><small> NB: based on operational team input</small></h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="12">
                  <table class="table table-bordered">
                    <thead>
                    <tr>
                      <th class="bg-warning text-center">NO</th>
                      <th class="bg-warning text-center">Fleet</th>
                      <th class="bg-warning text-center">Date</th>
                      <th class="bg-warning text-center">Reason</th>
                      <th class="bg-warning text-center">Remarks</th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(val, i) in delay.data" :key="'delay-crew-' + i" >
                        <td class="text-center">
                          {{ i + 1 }}
                        </td>
                        <td class="text-center">
                         {{ val?.fleet }}
                        </td>
                        <td class="text-center">
                          {{ moment(val?.start_date).format("YYYY MM DD") }}
                        </td>
                        <td class="text-center">
                          {{ val?.reason?.name }}
                        </td>
                        <td class="text-center">
                          -
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-3" v-if="delay?.data?.length > 0">
                    <b-pagination
                      v-model="delay.meta.currentPage"
                      :total-rows="delay.meta.total"
                      :per-page="delay.meta.perPage"
                      first-number
                      align="center"
                    />
                  </div>
                  <p v-else class="text-center my-4"
                    >List delay tidak ada.</p
                  >
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
        <b-col md="6">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>Expired Crew Certificate</strong></h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="12">
                  <table class="table table-bordered">
                    <thead>
                    <tr>
                      <th class="bg-danger text-center">NO</th>
                      <th class="bg-danger text-center">Name of Crew</th>
                      <th class="bg-danger text-center">Fleet</th>
                      <th class="bg-danger text-center">Name of Certificate</th>
                      <th class="bg-danger text-center">Valid Until</th>
                      <th class="bg-danger text-center">Status</th>
                    </tr>
                    </thead>
                    
                  </table>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
        <b-col md="6">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>Crew not yet appraised</strong></h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="12">
                  <table class="table table-bordered">
                    <thead>
                    <tr>
                      <th class="bg-danger text-center">NO</th>
                      <th class="bg-danger text-center">Name of Crew</th>
                      <th class="bg-danger text-center">Fleet</th>
                      <th class="bg-danger text-center">Sign On / Sign Off</th>
                      <th class="bg-danger text-center">Action</th>
                    </tr>
                    </thead>
                    
                  </table>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
        <b-col md="6">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>Crew Statistic Based on Religion</strong></h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="12">
                  <ApexChart :element="'patient-chart-02'" :chartOption="crewReligionChart" />
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
        <b-col md="6">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>Crew Statistic Based on Marital Status</strong></h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="12">
                  <ApexChart :element="'patient-chart-01'" :chartOption="crewMaritalStatusChart" />
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
import Loader from '../../components/xray/loader/Loader'
import { crewingActions, vehiclesActions } from '@/src/Utils/helper'
import { xray } from '../../config/pluginInit'
import { numberFormat } from '@src/plugins/helpers'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'Dashboard',
  components: {
    Loader,
  },
  data () {
    return {
      isLoading: false,
      selectedFleets: null,

      expiredData:{},
      lang: {
        formatLocale: {
          // MMMM
          months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
          ],
          // MMM
          monthsShort: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
          ],
          // dddd
          weekdays: [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday'
          ],
          // ddd
          weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          // dd
          weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
          // first day of week
          firstDayOfWeek: 0,
          // first week contains January 1st.
          firstWeekContainsDate: 1
        },
        monthBeforeYear: false
      },
      summary: {
        params: {
          dateRange: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)],
          type: 'all',
          vehicle_ids: null
        },
        data: null
      },
      filteredForms: [],
      kapal: [],
      availability: {
        currentPage: 1,
        perPage: 10,
        data:[]
      },
      delay: {
        data: [],
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        },
      },

      crewReligionChart: {
        series: [
          {
            name: 'Agama',
            data: [0,0,0,0,0,0]
          }
        ],
        chart: {
          height: 350,
          type: 'bar'
        },
        colors: ['#089bab'],
        plotOptions: {
          bar: {
            columnWidth: '50%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2
        },

        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: [
            'ISLAM',
            'PROTESTAN',
            'KATOLIK',
            'HINDU',
            'BUDDHA',
            'KHONGHUCU',
          ],
          tickPlacement: 'on'
        },
        yaxis: {
          title: {
            text: 'Jumlah Crew'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100]
          }
        }
      },

      crewMaritalStatusChart: {
        series: [
          {
            name: 'Marital Status',
            data: [0,0]
          }
        ],
        chart: {
          height: 350,
          type: 'bar'
        },
        colors: ['#089bab'],
        plotOptions: {
          bar: {
            columnWidth: '50%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2
        },

        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: [
            'SINGLE',
            'MARIED',
          ],
          tickPlacement: 'on'
        },
        yaxis: {
          title: {
            text: 'Jumlah Crew'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100]
          }
        }
      },
    }
  },
  computed: {
    totalAvaibility() {
      return this.availability.data.length;
    },
    displayedAvaibility() {
      const start = (this.availability.currentPage - 1) * this.availability.perPage;
      const end = start + this.availability.perPage;
      return this.availability.data.slice(start, end);
    },
    startRowIndexAvaibility() {
      return (this.availability.currentPage - 1) * this.availability.perPage + 1;
    },
  },
  async mounted () {
    xray.index()
    await this.getDashboardDataCrew()
    await this.getVehicleList()
  },
  methods: {
    ...crewingActions,
    ...vehiclesActions,
    async getVehicleList () {
      const { data } = await this.getVehicles({ showAll: true, page: 1 })
      this.kapal = data.data.map((val) => ({ value: val.id, text: val.name }))
    },

    async getDashboardDataCrew(){
      this.isLoading = true

      let params = {
        startDate: moment(this.summary.params.dateRange[0]).format("YYYY-MM-DD"),
        endDate: moment(this.summary.params.dateRange[1]).format("YYYY-MM-DD")
      }

      const {data}  = await this.fetchDashboardCrew(params)

      this.summary.data = data

      this.availability.data = data.availability

      this.delay.data = data.delay.data
      this.delay.meta = {
          perPage: data.delay.per_page,
          currentPage: data.delay.current_page,
          total: data.delay.total,
      }

      // CONVERT RELIGION DATA INTO CHART DATA
      let newDataReligon = data.statistic_religion.map(val => {
        return val.AgamaCount
      })
      let newLabelReligion = data.statistic_religion.map(val => {
        return val.name
      })
      this.crewReligionChart.series[0].data = newDataReligon
      this.crewReligionChart.xaxis.categories = newLabelReligion

      // CONVERT MARITAL STATUS DATA INTO CHART DATA
      let newDataMarital = data.statistic_marital_status.map(val => {
        return val.MaritalStatusCount
      })
      let newLabelMarital = data.statistic_marital_status.map(val => {
        return val.name
      })
      this.crewMaritalStatusChart.series[0].data = newDataMarital
      this.crewMaritalStatusChart.xaxis.categories = newLabelMarital

    
      this.isLoading = false
    },

    async handleFilterDashboard(){
      this.isLoading = true

      console.log(this.summary.params.vehicle_ids, "THIS IS VEHICLE")

      let params = {
        startDate: moment(this.summary.params.dateRange[0]).format("YYYY-MM-DD"),
        endDate: moment(this.summary.params.dateRange[1]).format("YYYY-MM-DD"),
        fleet: this.summary.params.vehicle_ids
      }

      if(!this.summary.params.vehicle_ids) delete params.fleet.vehicle_ids

      const { status, data }  = await this.fetchDashboardCrew(params)

      if(status === 400 || status === 500){
        return this.isLoading = false
      }

      this.summary.data = data


      // CONVERT RELIGION DATA INTO CHART DATA
      let newDataReligon = data.statistic_religion.map(val => {
        return val.AgamaCount
      })
      let newLabelReligion = data.statistic_religion.map(val => {
        return val.name
      })
      this.crewReligionChart.series[0].data = newDataReligon
      this.crewReligionChart.xaxis.categories = newLabelReligion

      // CONVERT MARITAL STATUS DATA INTO CHART DATA
      let newDataMarital = data.statistic_marital_status.map(val => {
        return val.MaritalStatusCount
      })
      let newLabelMarital = data.statistic_marital_status.map(val => {
        return val.name
      })
      this.crewMaritalStatusChart.series[0].data = newDataMarital
      this.crewMaritalStatusChart.xaxis.categories = newLabelMarital

      this.isLoading = false
    }
  }
}
</script>

<style>
  @import url("../../assets/css/custom.css");
  .modal-backdrop {
    opacity: .5;
  }
  @media  print
  {
    body
    {
      font-size:70%;
    }
    .no-print, .no-print *
    {
      display: none !important;
    }
  }
</style>
