var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "no-print pull-right"
  }, [_c('b-button-group', {
    staticClass: "mr-2"
  }, [_vm.preview ? _c('button', {
    staticClass: "btn btn-sm btn-dark",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.print.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-print"
  }), _vm._v(" Print")]) : _vm._e(), _vm.preview ? _c('button', {
    staticClass: "btn btn-sm btn-success",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.edit.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-edit"
  }), _vm._v(" Edit")]) : _c('button', {
    staticClass: "btn btn-sm btn-primary",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.saveSCL();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }), _vm._v(" Save")])])], 1), _vm._m(0), _vm._m(1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('table', {
    attrs: {
      "border": "0",
      "width": "100%"
    }
  }, [_c('tbody', [_c('tr', [_c('td', [_vm._v("Tug & Barge Name")]), _vm.preview ? _c('td', [_vm._v(": " + _vm._s(_vm.formScl.tugBargeName))]) : _c('td', [_c('b-form-input', {
    staticClass: "mt-1",
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.formScl.tugBargeName,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "tugBargeName", $$v);
      },
      expression: "formScl.tugBargeName"
    }
  })], 1), _c('td', [_vm._v("Voyage ID")]), _vm.preview ? _c('td', [_vm._v(": " + _vm._s(_vm.formScl.voyageId))]) : _c('td', [_c('b-form-input', {
    staticClass: "mt-1",
    staticStyle: {
      "width": "80%",
      "float": "right"
    },
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.formScl.voyageId,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "voyageId", $$v);
      },
      expression: "formScl.voyageId"
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v("Port of Loading")]), _vm.preview ? _c('td', [_vm._v(": " + _vm._s(_vm.formScl.pol))]) : _c('td', [_c('b-form-input', {
    staticClass: "mt-1",
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.formScl.pol,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "pol", $$v);
      },
      expression: "formScl.pol"
    }
  })], 1), _c('td', [_vm._v("Location Check 1")]), _vm.preview ? _c('td', [_vm._v(": " + _vm._s(_vm.formScl.locationCheck1))]) : _c('td', [_c('b-form-input', {
    staticClass: "mt-1",
    staticStyle: {
      "width": "80%",
      "float": "right"
    },
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.formScl.locationCheck1,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "locationCheck1", $$v);
      },
      expression: "formScl.locationCheck1"
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v("Port of Discharge")]), _vm.preview ? _c('td', [_vm._v(": " + _vm._s(_vm.formScl.pod))]) : _c('td', [_c('b-form-input', {
    staticClass: "mt-1",
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.formScl.pod,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "pod", $$v);
      },
      expression: "formScl.pod"
    }
  })], 1), _c('td', [_vm._v("Date & Time Check 1")]), _vm.preview ? _c('td', [_vm._v(": " + _vm._s(_vm.formScl.dateTimeCheck1))]) : _c('td', [_c('date-picker', {
    staticClass: "mt-1",
    staticStyle: {
      "width": "80%",
      "float": "right"
    },
    attrs: {
      "type": "datetime",
      "value-type": "DD-MM-YYYY HH:mm",
      "placeholder": "Select date and time"
    },
    model: {
      value: _vm.formScl.dateTimeCheck1,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "dateTimeCheck1", $$v);
      },
      expression: "formScl.dateTimeCheck1"
    }
  }), _vm._m(2)], 1)]), _c('tr', [_c('td', [_vm._v("Cargo Type")]), _vm.preview ? _c('td', [_vm._v(": " + _vm._s(_vm.formScl.cargoType))]) : _c('td', [_c('b-form-input', {
    staticClass: "mt-1",
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.formScl.cargoType,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "cargoType", $$v);
      },
      expression: "formScl.cargoType"
    }
  })], 1), _c('td', [_vm._v("Location Check 2")]), _vm.preview ? _c('td', [_vm._v(": " + _vm._s(_vm.formScl.locationCheck2))]) : _c('td', [_c('b-form-input', {
    staticClass: "mt-1",
    staticStyle: {
      "width": "80%",
      "float": "right"
    },
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.formScl.locationCheck2,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "locationCheck2", $$v);
      },
      expression: "formScl.locationCheck2"
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v("Quantity")]), _vm.preview ? _c('td', [_vm._v(": " + _vm._s(_vm.formScl.quantity) + " MT")]) : _c('td', [_c('b-input-group', {
    staticClass: "mt-1",
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "size": "md",
      "append": "Ton"
    }
  }, [_c('b-form-input', {
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "type": "number",
      "step": ".01"
    },
    model: {
      value: _vm.formScl.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "quantity", $$v);
      },
      expression: "formScl.quantity"
    }
  })], 1)], 1), _c('td', [_vm._v("Date & Time Check 2")]), _vm.preview ? _c('td', [_vm._v(": " + _vm._s(_vm.formScl.dateTimeCheck2))]) : _c('td', [_c('date-picker', {
    staticStyle: {
      "width": "80%",
      "float": "right"
    },
    attrs: {
      "type": "datetime",
      "value-type": "DD-MM-YYYY HH:mm",
      "placeholder": "Select date and time"
    },
    model: {
      value: _vm.formScl.dateTimeCheck2,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "dateTimeCheck2", $$v);
      },
      expression: "formScl.dateTimeCheck2"
    }
  }), _vm._m(3)], 1)])])])])]), _c('div', [_vm._v(" ")]), _vm._m(4), _c('table', {
    attrs: {
      "width": "100%",
      "border": "1"
    }
  }, [_c('thead', [_c('tr', {
    staticStyle: {
      "background-color": "#bae1ff"
    }
  }, [_c('th', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Point to Point")]), _c('th', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "colspan": "2",
      "width": "50%"
    }
  }, [_vm._v("Time & Date")]), _c('th', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Steaming Time")]), _c('th', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "width": "17%"
    }
  }, [_vm._v("Remarks")]), !_vm.preview ? _c('th', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("#")]) : _vm._e()])]), _c('tbody', [_vm._l(_vm.formScl.summarySteaming.detail, function (stream, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm.preview ? _c('div', [_vm._v(" " + _vm._s(stream.ptp))]) : _c('div', {
      staticClass: "text-center"
    }, [_c('b-form-input', {
      staticStyle: {
        "width": "80%",
        "margin": "auto"
      },
      attrs: {
        "type": "text",
        "size": "sm"
      },
      model: {
        value: stream.ptp,
        callback: function callback($$v) {
          _vm.$set(stream, "ptp", $$v);
        },
        expression: "stream.ptp"
      }
    })], 1)]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm.preview ? _c('div', [_vm._v(" " + _vm._s(stream.startDate))]) : _c('div', {
      staticClass: "p-2",
      staticStyle: {
        "width": "80%",
        "margin": "auto"
      }
    }, [_c('date-picker', {
      attrs: {
        "type": "datetime",
        "value-type": "DD-MM-YYYY HH:mm",
        "placeholder": "Select date and time"
      },
      model: {
        value: stream.startDate,
        callback: function callback($$v) {
          _vm.$set(stream, "startDate", $$v);
        },
        expression: "stream.startDate"
      }
    }), _vm._m(5, true)], 1)]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm.preview ? _c('div', [_vm._v(" " + _vm._s(stream.endDate))]) : _c('div', {
      staticClass: "p-2",
      staticStyle: {
        "width": "80%",
        "margin": "auto"
      }
    }, [_c('date-picker', {
      attrs: {
        "type": "datetime",
        "value-type": "DD-MM-YYYY HH:mm",
        "placeholder": "Select date and time"
      },
      model: {
        value: stream.endDate,
        callback: function callback($$v) {
          _vm.$set(stream, "endDate", $$v);
        },
        expression: "stream.endDate"
      }
    }), _vm._m(6, true)], 1)]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_c('div', [_vm._v(" " + _vm._s(stream.steamingTime = _vm.steamingTimeCalculate(stream.startDate, stream.endDate)))])]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm.preview ? _c('div', [_vm._v(" " + _vm._s(stream.remarks))]) : _c('div', {
      staticClass: "text-center"
    }, [_c('b-form-input', {
      attrs: {
        "type": "text",
        "size": "sm"
      },
      model: {
        value: stream.remarks,
        callback: function callback($$v) {
          _vm.$set(stream, "remarks", $$v);
        },
        expression: "stream.remarks"
      }
    })], 1)]), !_vm.preview && _vm.formScl.summarySteaming.detail.length > 1 ? _c('td', {
      staticClass: "text-center"
    }, [_c('b-button', {
      staticClass: "mb-3",
      attrs: {
        "size": "sm",
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.removeSteamingTime(index);
        }
      }
    }, [_c('i', {
      staticClass: "ri-delete-bin-line"
    })])], 1) : _vm._e()]);
  }), !_vm.preview ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "6"
    }
  }, [_c('b-button', {
    staticClass: "mb-3 mr-1",
    attrs: {
      "block": "",
      "size": "sm",
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.addSteamingTime();
      }
    }
  }, [_c('i', {
    staticClass: "ri-add-line"
  }), _vm._v(" Add Steaming Time")])], 1)]) : _vm._e(), _c('tr', [_c('td', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Total Steaming Time")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(_vm._s(_vm.preview ? _vm.formScl.summarySteaming.totalSteaming : _vm.formScl.summarySteaming.totalSteaming = _vm.steamingTotalTimeCalculate()))]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(" ")])])], 2)]), _c('br'), _vm._m(7), _c('table', {
    attrs: {
      "width": "100%",
      "border": "1"
    }
  }, [_vm._m(8), _c('tbody', [_vm._l(_vm.formScl.summaryAec.detail, function (aed, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm.preview ? _c('div', [_vm._v(_vm._s(aed.description))]) : _c('div', {
      staticClass: "text-center p-2"
    }, [_c('b-form-input', {
      staticStyle: {
        "width": "90%",
        "margin": "auto"
      },
      attrs: {
        "type": "text",
        "size": "sm"
      },
      model: {
        value: aed.description,
        callback: function callback($$v) {
          _vm.$set(aed, "description", $$v);
        },
        expression: "aed.description"
      }
    })], 1)]), _c('td', {
      staticClass: "p-2",
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm.preview ? _c('div', [_vm._v(" " + _vm._s(aed.startDate))]) : _c('div', [_c('date-picker', {
      attrs: {
        "type": "datetime",
        "value-type": "DD-MM-YYYY HH:mm",
        "placeholder": "Select date and time"
      },
      model: {
        value: aed.startDate,
        callback: function callback($$v) {
          _vm.$set(aed, "startDate", $$v);
        },
        expression: "aed.startDate"
      }
    })], 1)]), _c('td', {
      staticClass: "p-2",
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm.preview ? _c('div', [_vm._v(" " + _vm._s(aed.endDate))]) : _c('div', [_c('date-picker', {
      attrs: {
        "type": "datetime",
        "value-type": "DD-MM-YYYY HH:mm",
        "placeholder": "Select date and time"
      },
      model: {
        value: aed.endDate,
        callback: function callback($$v) {
          _vm.$set(aed, "endDate", $$v);
        },
        expression: "aed.endDate"
      }
    })], 1)]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_c('div', [_vm._v(" " + _vm._s(_vm.preview ? aed.idle : aed.idle = _vm.steamingTimeCalculate(aed.startDate, aed.endDate)))])]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm.preview ? _c('div', [_vm._v(" " + _vm._s(aed.ltrs * 100 / 100))]) : _c('div', {
      staticClass: "text-center"
    }, [_c('b-form-input', {
      staticStyle: {
        "width": "90%",
        "margin": "auto"
      },
      attrs: {
        "type": "number",
        "size": "sm"
      },
      model: {
        value: aed.ltrs,
        callback: function callback($$v) {
          _vm.$set(aed, "ltrs", $$v);
        },
        expression: "aed.ltrs"
      }
    })], 1)]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(_vm.preview ? aed.ae : aed.ae = Math.ceil(_vm.steamingTimeCalculate(aed.startDate, aed.endDate) * aed.ltrs)) + " ")]), index === 0 ? [_c('td', {
      staticStyle: {
        "text-align": "center"
      },
      attrs: {
        "rowspan": _vm.formScl.summaryAec.detail.length
      }
    }, [_vm.preview ? _c('div', [_vm._v(" " + _vm._s(_vm.formScl.summaryAec.quotation) + " ")]) : _c('div', {
      staticClass: "text-center"
    }, [_c('b-form-input', {
      staticStyle: {
        "width": "90%",
        "margin": "auto"
      },
      attrs: {
        "type": "number",
        "size": "sm"
      },
      model: {
        value: _vm.formScl.summaryAec.quotation,
        callback: function callback($$v) {
          _vm.$set(_vm.formScl.summaryAec, "quotation", $$v);
        },
        expression: "formScl.summaryAec.quotation"
      }
    })], 1)]), _c('td', {
      staticStyle: {
        "text-align": "center"
      },
      attrs: {
        "rowspan": _vm.formScl.summaryAec.detail.length
      }
    }, [_c('div', [_vm._v(" " + _vm._s(_vm.formScl.summaryAec.deductionAe = _vm.totalAE() - _vm.formScl.summaryAec.quotation) + " ")])])] : _vm._e()], 2);
  }), _c('tr', [_c('td', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Total Auxiliary Engine Idle")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(_vm._s(_vm.formScl.summaryAec.totalIdle = _vm.aecTotalTimeCalculate()))]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("-")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(_vm._s(_vm.formScl.summaryAec.totalAe = _vm.totalAE()))]), _c('td'), _c('td')])], 2)]), _c('br'), _vm._m(9), _c('table', {
    attrs: {
      "width": "100%",
      "border": "1"
    }
  }, [_vm._m(10), _c('tbody', [_vm._l(_vm.formScl.summary.detail, function (summary, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm.preview ? _c('div', [_vm._v(" " + _vm._s(summary.ptp))]) : _c('div', {
      staticClass: "text-center"
    }, [_c('b-form-input', {
      staticStyle: {
        "width": "90%",
        "margin": "auto"
      },
      attrs: {
        "type": "text",
        "size": "sm"
      },
      model: {
        value: _vm.formScl.summarySteaming.detail[index].ptp,
        callback: function callback($$v) {
          _vm.$set(_vm.formScl.summarySteaming.detail[index], "ptp", $$v);
        },
        expression: "formScl.summarySteaming.detail[index].ptp"
      }
    })], 1)]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm.preview ? _c('div', [_vm._v(" " + _vm._s(summary.distance))]) : _c('div', {
      staticClass: "text-center"
    }, [_c('b-form-input', {
      staticStyle: {
        "width": "90%",
        "margin": "auto"
      },
      attrs: {
        "type": "number",
        "size": "sm"
      },
      model: {
        value: summary.distance,
        callback: function callback($$v) {
          _vm.$set(summary, "distance", $$v);
        },
        expression: "summary.distance"
      }
    })], 1)]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm.preview ? _c('div', [_vm._v(" " + _vm._s(summary.speed.kpi))]) : _c('div', {
      staticClass: "text-center"
    }, [_c('b-form-input', {
      staticStyle: {
        "width": "90%",
        "margin": "auto"
      },
      attrs: {
        "type": "number",
        "size": "sm"
      },
      model: {
        value: summary.speed.kpi,
        callback: function callback($$v) {
          _vm.$set(summary.speed, "kpi", $$v);
        },
        expression: "summary.speed.kpi"
      }
    })], 1)]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_c('div', [_vm._v(_vm._s(summary.speed.vessel = summary.distance && _vm.formScl.summarySteaming.detail[index].steamingTime ? (summary.distance / _vm.formScl.summarySteaming.detail[index].steamingTime).toFixed(2) : null))])]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm._v(_vm._s(summary.speed.diff = (summary.speed.vessel - summary.speed.kpi).toFixed(2)))]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_c('div', [_vm._v(" " + _vm._s(summary.steaming.kpi = summary.distance && summary.speed.kpi ? (summary.distance / summary.speed.kpi).toFixed(2) : null))])]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_c('div', [_vm._v(" " + _vm._s(summary.steaming.vessel = _vm.formScl.summarySteaming.detail[index].steamingTime ? _vm.formScl.summarySteaming.detail[index].steamingTime : null))])]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm._v(_vm._s(summary.steaming.diff = summary.steaming.kpi && summary.steaming.vessel ? (summary.steaming.kpi - summary.steaming.vessel).toFixed(2) : null))]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm.preview ? _c('div', [_vm._v(" " + _vm._s(summary.focLtrs))]) : _c('div', {
      staticClass: "text-center"
    }, [_c('b-form-input', {
      staticStyle: {
        "width": "90%",
        "margin": "auto"
      },
      attrs: {
        "type": "number",
        "size": "sm"
      },
      model: {
        value: summary.focLtrs,
        callback: function callback($$v) {
          _vm.$set(summary, "focLtrs", $$v);
        },
        expression: "summary.focLtrs"
      }
    })], 1)]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_c('div', [_vm._v(" " + _vm._s(summary.focKpi = summary.focLtrs && summary.steaming.kpi ? (summary.focLtrs * summary.steaming.kpi).toFixed(2) : null))])]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm._v(_vm._s(Math.round(summary.steaming.vessel * summary.focLtrs)))])]);
  }), _c('tr', [_c('td', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "colspan": "9"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('span', {
    staticClass: "align-self-center"
  }, [_vm._v("Auxiliary Engine Idle ( ")]), _vm.preview ? _c('span', [_vm._v(_vm._s(_vm.formScl.summary.aeIdleDay))]) : _c('div', {
    staticClass: "text-center p-2"
  }, [_c('b-form-input', {
    staticStyle: {
      "width": "80%",
      "margin": "auto"
    },
    attrs: {
      "type": "number",
      "size": "sm"
    },
    model: {
      value: _vm.formScl.summary.aeIdleDay,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl.summary, "aeIdleDay", $$v);
      },
      expression: "formScl.summary.aeIdleDay"
    }
  })], 1), _c('span', {
    staticClass: "align-self-center"
  }, [_vm._v("Days)")])])]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm.preview ? _c('div', [_vm._v(_vm._s(_vm.formScl.summary.aeIdleKpi))]) : _c('div', {
    staticClass: "text-center p-2"
  }, [_c('b-form-input', {
    staticStyle: {
      "width": "90%",
      "margin": "auto"
    },
    attrs: {
      "type": "number",
      "size": "sm"
    },
    model: {
      value: _vm.formScl.summary.aeIdleKpi,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl.summary, "aeIdleKpi", $$v);
      },
      expression: "formScl.summary.aeIdleKpi"
    }
  })], 1)]), _c('td', {
    attrs: {
      "rowspan": "3"
    }
  })]), _c('tr', [_c('td', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "colspan": "9"
    }
  }, [_vm._v("Manuevering, Idle, Runing Free")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm.preview ? _c('div', [_vm._v(_vm._s(_vm.formScl.summary.runningFree))]) : _c('div', {
    staticClass: "text-center p-2"
  }, [_c('b-form-input', {
    staticStyle: {
      "width": "90%",
      "margin": "auto"
    },
    attrs: {
      "type": "number",
      "size": "sm"
    },
    model: {
      value: _vm.formScl.summary.runningFree,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl.summary, "runningFree", $$v);
      },
      expression: "formScl.summary.runningFree"
    }
  })], 1)])]), _c('tr', [_c('td', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "colspan": "9"
    }
  }, [_vm._v("Cargo Pump")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm.preview ? _c('div', [_vm._v(_vm._s(_vm.formScl.summary.cargoPump))]) : _c('div', {
    staticClass: "text-center p-2"
  }, [_c('b-form-input', {
    staticStyle: {
      "width": "90%",
      "margin": "auto"
    },
    attrs: {
      "type": "number",
      "size": "sm"
    },
    model: {
      value: _vm.formScl.summary.cargo_pump,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl.summary, "cargo_pump", $$v);
      },
      expression: "formScl.summary.cargo_pump"
    }
  })], 1)])]), _c('tr', [_c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Total")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(_vm._s(_vm.formScl.summary.totalDistance = _vm.totalDistance().toFixed(2)))]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(_vm._s(_vm.formScl.summary.totalSpeed.kpi = _vm.totalKPISpeed().toFixed(2)))]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(_vm._s(_vm.formScl.summary.totalSpeed.vessel = _vm.totalVesselSpeed().toFixed(2)))]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(_vm._s(_vm.formScl.summary.totalSpeed.diff = _vm.totalDiffSpeed().toFixed(2)))]), _c('td', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.formScl.summary.steamingTotal.kpi = _vm.totalKPISteamingTime().toFixed(2)))]), _c('td', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.formScl.summary.steamingTotal.vessel = _vm.totalVesselSteamingTime().toFixed(2)))]), _c('td', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.formScl.summary.steamingTotal.diff = _vm.totalDiffSteamingTime().toFixed(2)))]), _c('td', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("-")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.formScl.summary.totalKpi = _vm.totalKPIFoc().toFixed(2)))]), _c('td', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(Math.round(_vm.totalVesselFoc()).toFixed(2)))])]), _c('tr', [_c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Average")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(_vm._s(this.formScl.summary.detail.length > 0 ? (_vm.formScl.summary.totalDistance / this.formScl.summary.detail.length).toFixed(2) : 0.00))]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(_vm._s(_vm.formScl.summary.averageSpeed.kpi = (_vm.formScl.summary.totalSpeed.kpi / this.formScl.summary.detail.length).toFixed(2)))]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(_vm._s(_vm.formScl.summary.averageSpeed.vessel = (_vm.formScl.summary.totalSpeed.vessel / this.formScl.summary.detail.length).toFixed(2)))]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(_vm._s(_vm.formScl.summary.averageSpeed.diff = (_vm.formScl.summary.totalSpeed.diff / this.formScl.summary.detail.length).toFixed(2)))])])], 2)]), _c('br'), _c('table', {
    attrs: {
      "width": "100%",
      "border": "1"
    }
  }, [_vm._m(11), _c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm.preview ? _c('div', [_vm._v(_vm._s(_vm.formScl.summary.check1 || 0))]) : _c('div', {
    staticClass: "text-center"
  }, [_c('b-form-input', {
    staticStyle: {
      "width": "90%",
      "margin": "auto"
    },
    attrs: {
      "type": "number",
      "size": "sm"
    },
    model: {
      value: _vm.formScl.summary.check1,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl.summary, "check1", $$v);
      },
      expression: "formScl.summary.check1"
    }
  })], 1)]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm.preview ? _c('div', [_vm._v(_vm._s(_vm.formScl.summary.bunkerFo || 0))]) : _c('div', {
    staticClass: "text-center"
  }, [_c('b-form-input', {
    staticStyle: {
      "width": "90%",
      "margin": "auto"
    },
    attrs: {
      "type": "number",
      "size": "sm"
    },
    model: {
      value: _vm.formScl.summary.bunkerFo,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl.summary, "bunkerFo", $$v);
      },
      expression: "formScl.summary.bunkerFo"
    }
  })], 1)]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.formScl.summary.stockFo = Number(_vm.formScl.summary.check1 || 0) + Number(_vm.formScl.summary.bunkerFo || 0)))])]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm.preview ? _c('div', [_vm._v(_vm._s(_vm.formScl.summary.check2 || 0))]) : _c('div', {
    staticClass: "text-center"
  }, [_c('b-form-input', {
    staticStyle: {
      "width": "90%",
      "margin": "auto"
    },
    attrs: {
      "type": "number",
      "size": "sm"
    },
    model: {
      value: _vm.formScl.summary.check2,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl.summary, "check2", $$v);
      },
      expression: "formScl.summary.check2"
    }
  })], 1)]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.formScl.summary.consumption = Number(_vm.formScl.summary.stockFo) - Number(_vm.formScl.summary.check2)))])]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm.preview ? _c('div', [_vm._v(_vm._s(_vm.formScl.summary.kpi || 0))]) : _c('div', {
    staticClass: "text-center"
  }, [_c('b-form-input', {
    staticStyle: {
      "width": "90%",
      "margin": "auto"
    },
    attrs: {
      "type": "number",
      "size": "sm"
    },
    model: {
      value: _vm.formScl.summary.kpi,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl.summary, "kpi", $$v);
      },
      expression: "formScl.summary.kpi"
    }
  })], 1)]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.formScl.summary.buyBack = (_vm.formScl.summary.kpi - _vm.formScl.summary.consumption).toFixed(2)))])]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('span', {
    staticClass: "d-none"
  }, [_vm._v(_vm._s(_vm.formScl.summary.deduction = _vm.formScl.summaryAec.deductionAe.toFixed(2)))]), _c('div', {
    staticClass: "text-center p-2"
  }, [!_vm.preview ? _c('b-form-input', {
    staticStyle: {
      "width": "90%",
      "margin": "auto"
    },
    attrs: {
      "type": "number",
      "size": "sm"
    },
    model: {
      value: _vm.formScl.summary.deduction,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl.summary, "deduction", $$v);
      },
      expression: "formScl.summary.deduction"
    }
  }) : _c('span', [_vm._v(_vm._s(_vm.formScl.summary.deduction))])], 1)]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(_vm._s(_vm.formScl.summary.difference = (parseInt(_vm.formScl.summary.buyBack) + parseInt(_vm.formScl.summary.deduction)).toFixed(2)))])])])]), _c('table', {
    staticStyle: {
      "margin-top": "5px"
    },
    attrs: {
      "width": "100%",
      "border": "1"
    }
  }, [_c('tbody', [_c('tr', [_c('td', [_vm.preview ? _c('div', {
    staticClass: "col-md-12"
  }, [_c('span', [_vm._v("Remarks")]), _c('br'), _vm._v(" " + _vm._s(_vm.formScl.remarks ? _vm.formScl.remarks : '-') + " ")]) : _c('div', {
    staticClass: "col-md-12"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Remarks",
      "label-for": "remarks"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "description",
      "rows": "2"
    },
    model: {
      value: _vm.formScl.remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "remarks", $$v);
      },
      expression: "formScl.remarks"
    }
  }), _c('small', {
    staticStyle: {
      "float": "left"
    }
  }, [_c('i', {
    staticClass: "fa fa-info-circle"
  }), _vm._v(" Max 200 Character")])], 1)], 1)])]), _c('tr', [_c('td', [_vm.preview ? _c('div', {
    staticClass: "col-md-12"
  }, [_c('span', [_vm._v("Conclusion")]), _c('br'), _vm._v(" " + _vm._s(_vm.formScl.conclusion ? _vm.formScl.conclusion : '-') + " ")]) : _c('div', {
    staticClass: "col-md-12"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Conclusion",
      "label-for": "conclusion"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "description",
      "rows": "2"
    },
    model: {
      value: _vm.formScl.conclusion,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "conclusion", $$v);
      },
      expression: "formScl.conclusion"
    }
  }), _c('small', {
    staticStyle: {
      "float": "left"
    }
  }, [_c('i', {
    staticClass: "fa fa-info-circle"
  }), _vm._v(" Max 200 Character")])], 1)], 1)])]), _c('tr', [_c('td', [_vm.preview ? _c('div', {
    staticClass: "col-md-12"
  }, [_c('span', [_vm._v("Notes")]), _c('br'), _vm._v(" " + _vm._s(_vm.formScl.notes ? _vm.formScl.notes : '-') + " ")]) : _c('div', {
    staticClass: "col-md-12"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Notes",
      "label-for": "notes"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "description",
      "rows": "2"
    },
    model: {
      value: _vm.formScl.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "notes", $$v);
      },
      expression: "formScl.notes"
    }
  }), _c('small', {
    staticStyle: {
      "float": "left"
    }
  }, [_c('i', {
    staticClass: "fa fa-info-circle"
  }), _vm._v(" Max 200 Character")])], 1)], 1)])])])]), _vm._m(12), _c('br'), _c('br'), _c('br'), _c('table', {
    attrs: {
      "border": "0",
      "width": "100%"
    }
  }, [_c('tbody', [_c('tr', [_vm.preview ? _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(" " + _vm._s(_vm.formScl.preparedBy ? _vm.formScl.preparedBy : '-'))]) : _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_c('b-form-input', {
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.formScl.preparedBy,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "preparedBy", $$v);
      },
      expression: "formScl.preparedBy"
    }
  })], 1), _vm.preview ? _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s(_vm.formScl.acknowledgeBy ? _vm.formScl.acknowledgeBy : '-'))]) : _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_c('b-form-input', {
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.formScl.acknowledgeBy,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "acknowledgeBy", $$v);
      },
      expression: "formScl.acknowledgeBy"
    }
  })], 1), _vm.preview ? _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s(_vm.formScl.checkedBy ? _vm.formScl.checkedBy : '-'))]) : _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_c('b-form-input', {
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.formScl.checkedBy,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "checkedBy", $$v);
      },
      expression: "formScl.checkedBy"
    }
  })], 1), _vm.preview ? _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s(_vm.formScl.approvedBy ? _vm.formScl.approvedBy : '-'))]) : _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_c('b-form-input', {
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.formScl.approvedBy,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "approvedBy", $$v);
      },
      expression: "formScl.approvedBy"
    }
  })], 1)]), _c('tr', [_vm.preview ? _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(" " + _vm._s(_vm.formScl.positionPreparedBy ? _vm.formScl.positionPreparedBy : '-'))]) : _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_c('b-form-input', {
    staticClass: "mb-5",
    staticStyle: {
      "width": "80%",
      "height": "30px"
    },
    attrs: {
      "type": "text",
      "placeholder": "Position"
    },
    model: {
      value: _vm.formScl.positionPreparedBy,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "positionPreparedBy", $$v);
      },
      expression: "formScl.positionPreparedBy"
    }
  })], 1), _vm.preview ? _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(" " + _vm._s(_vm.formScl.positionAcknowledgeBy ? _vm.formScl.positionAcknowledgeBy : '-'))]) : _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_c('b-form-input', {
    staticClass: "mb-5",
    staticStyle: {
      "width": "80%",
      "height": "30px"
    },
    attrs: {
      "type": "text",
      "placeholder": "Position"
    },
    model: {
      value: _vm.formScl.positionAcknowledgeBy,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "positionAcknowledgeBy", $$v);
      },
      expression: "formScl.positionAcknowledgeBy"
    }
  })], 1), _vm.preview ? _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(" " + _vm._s(_vm.formScl.positionCheckedBy ? _vm.formScl.positionCheckedBy : '-'))]) : _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_c('b-form-input', {
    staticClass: "mb-5",
    staticStyle: {
      "width": "80%",
      "height": "30px"
    },
    attrs: {
      "type": "text",
      "placeholder": "Position"
    },
    model: {
      value: _vm.formScl.positionCheckedBy,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "positionCheckedBy", $$v);
      },
      expression: "formScl.positionCheckedBy"
    }
  })], 1), _vm.preview ? _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(" " + _vm._s(_vm.formScl.positionApprovedBy ? _vm.formScl.positionApprovedBy : '-'))]) : _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_c('b-form-input', {
    staticClass: "mb-5",
    staticStyle: {
      "width": "80%",
      "height": "30px"
    },
    attrs: {
      "type": "text",
      "placeholder": "Position"
    },
    model: {
      value: _vm.formScl.positionApprovedBy,
      callback: function callback($$v) {
        _vm.$set(_vm.formScl, "positionApprovedBy", $$v);
      },
      expression: "formScl.positionApprovedBy"
    }
  })], 1)])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-left"
  }, [_c('img', {
    staticClass: "admin_img",
    attrs: {
      "src": "http://ops.transenergilogistik.com/assets/img/logo-black.png",
      "alt": "logo"
    }
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row",
    staticStyle: {
      "margin-top": "-20px"
    }
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('h4', {
    staticClass: "mt-5",
    staticStyle: {
      "text-decoration": "underline"
    }
  }, [_vm._v("STEAMING TIME, SOUNDING CHECK LIST, & SUMMARY")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('small', {
    staticStyle: {
      "float": "right"
    }
  }, [_vm._v("click "), _c('i', {
    staticClass: "fa fa-calendar"
  }), _vm._v(" to show")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('small', {
    staticStyle: {
      "float": "right"
    }
  }, [_vm._v("click "), _c('i', {
    staticClass: "fa fa-calendar"
  }), _vm._v(" to show")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row mt-4 mb-2"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('h6', {
    staticStyle: {
      "text-decoration": "underline"
    }
  }, [_vm._v("STEAMING TIME")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('small', {
    staticStyle: {
      "float": "right"
    }
  }, [_vm._v("click "), _c('i', {
    staticClass: "fa fa-calendar"
  }), _vm._v(" to show")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('small', {
    staticStyle: {
      "float": "right"
    }
  }, [_vm._v("click "), _c('i', {
    staticClass: "fa fa-calendar"
  }), _vm._v(" to show")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center mb-2"
  }, [_c('h6', {
    staticStyle: {
      "text-decoration": "underline"
    }
  }, [_vm._v("AUXILIARY ENGINE CALCULATION")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', {
    staticStyle: {
      "background-color": "#bae1ff"
    }
  }, [_c('th', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Description")]), _c('th', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Start")]), _c('th', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("End")]), _c('th', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Total")]), _c('th', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Ltrs")]), _c('th', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Total AE")]), _c('th', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Quotation")]), _c('th', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Deduction AE")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row mb-2"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('h6', {
    staticStyle: {
      "text-decoration": "underline"
    }
  }, [_vm._v("SUMMARY")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', {
    staticStyle: {
      "background-color": "#bae1ff"
    }
  }, [_c('td', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("Point To Point")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("Distance")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Speed")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Steaming Time")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Fuel Oil Consumption")])]), _c('tr', {
    staticStyle: {
      "background-color": "#bae1ff"
    }
  }, [_c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("KPI")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Vessel")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Diff")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("KPI")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Vessel")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Diff")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Ltrs")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("KPI")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Vessel")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', {
    staticStyle: {
      "background-color": "#bae1ff"
    }
  }, [_c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Check 1")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Bunker FO")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Stock FO")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Check 2")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Consumption")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("KPI")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Buy Back")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Deduction")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Difference")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      "border": "0",
      "width": "100%"
    }
  }, [_c('thead', [_c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Prepared By")]), _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Acknowledge By")]), _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Checked By")]), _c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Approved By")])])])]);

}]

export { render, staticRenderFns }