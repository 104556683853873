var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Crewing Report Dashboard")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.filter",
            modifiers: {
              "filter": true
            }
          }],
          staticClass: "text-primary float-right ml-3",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          attrs: {
            "visible": "",
            "id": "filter"
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "mr-5",
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Date Range Filter"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.summary.params.dateRange,
            callback: function callback($$v) {
              _vm.$set(_vm.summary.params, "dateRange", $$v);
            },
            expression: "summary.params.dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Select Fleet"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.kapal,
            "reduce": function reduce(type) {
              return type.value;
            },
            "placeholder": "select Fleet"
          },
          model: {
            value: _vm.summary.params.vehicle_ids,
            callback: function callback($$v) {
              _vm.$set(_vm.summary.params, "vehicle_ids", $$v);
            },
            expression: "summary.params.vehicle_ids"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.handleFilterDashboard
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)], 1), _c('hr')];
      },
      proxy: true
    }])
  })], 1)], 1), _vm.isLoading ? _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(" Loading... ")]) : [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-primary rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        var _vm$summary$data$acti, _vm$summary, _vm$summary$data;
        return [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "las la-users"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h2', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v(_vm._s((_vm$summary$data$acti = (_vm$summary = _vm.summary) === null || _vm$summary === void 0 ? void 0 : (_vm$summary$data = _vm$summary.data) === null || _vm$summary$data === void 0 ? void 0 : _vm$summary$data.active) !== null && _vm$summary$data$acti !== void 0 ? _vm$summary$data$acti : 0))])]), _c('h5', {}, [_vm._v("Active Crew")])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-success rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        var _vm$summary2, _vm$summary2$data;
        return [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-success"
        }, [_c('i', {
          staticClass: "las la-id-card-alt"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h2', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v(_vm._s((_vm$summary2 = _vm.summary) === null || _vm$summary2 === void 0 ? void 0 : (_vm$summary2$data = _vm$summary2.data) === null || _vm$summary2$data === void 0 ? void 0 : _vm$summary2$data.new_recruitment))])]), _c('h5', {}, [_vm._v("New Recruitment")])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-warning rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        var _vm$summary3, _vm$summary3$data;
        return [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-warning"
        }, [_c('i', {
          staticClass: "las la-id-card-alt"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h2', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v(_vm._s((_vm$summary3 = _vm.summary) === null || _vm$summary3 === void 0 ? void 0 : (_vm$summary3$data = _vm$summary3.data) === null || _vm$summary3$data === void 0 ? void 0 : _vm$summary3$data.finish))])]), _c('h5', {}, [_vm._v("Finish")])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-warning rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        var _vm$summary4, _vm$summary4$data;
        return [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-warning"
        }, [_c('i', {
          staticClass: "las la-exchange-alt"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h2', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v(_vm._s((_vm$summary4 = _vm.summary) === null || _vm$summary4 === void 0 ? void 0 : (_vm$summary4$data = _vm$summary4.data) === null || _vm$summary4$data === void 0 ? void 0 : _vm$summary4$data.swap))])]), _c('h5', {}, [_vm._v("Swap / Rotation")])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-default rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        var _vm$summary5, _vm$summary5$data;
        return [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-dark"
        }, [_c('i', {
          staticClass: "las la-crosshairs"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h2', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v(_vm._s((_vm$summary5 = _vm.summary) === null || _vm$summary5 === void 0 ? void 0 : (_vm$summary5$data = _vm$summary5.data) === null || _vm$summary5$data === void 0 ? void 0 : _vm$summary5$data.unfinish))])]), _c('h5', {}, [_vm._v("Unfinished")])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-danger rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        var _vm$summary6, _vm$summary6$data;
        return [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-danger"
        }, [_c('i', {
          staticClass: "las la-window-close"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h2', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v(_vm._s((_vm$summary6 = _vm.summary) === null || _vm$summary6 === void 0 ? void 0 : (_vm$summary6$data = _vm$summary6.data) === null || _vm$summary6$data === void 0 ? void 0 : _vm$summary6$data.blacklist))])]), _c('h5', {}, [_vm._v("Total Blacklist")])])])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Crew List Availability")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$displayedAvaibili;
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "bg-success text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "bg-success text-center"
        }, [_vm._v("Fleet")]), _c('th', {
          staticClass: "bg-success text-center"
        }, [_vm._v("Active Crew")]), _c('th', {
          staticClass: "bg-success text-center"
        }, [_vm._v("Crew Needed")])])]), _c('tbody', _vm._l(_vm.displayedAvaibility, function (crew, i) {
          return _c('tr', {
            key: 'crew-' + i
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(_vm.startRowIndexAvaibility + i) + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(crew === null || crew === void 0 ? void 0 : crew.fleet) + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(crew === null || crew === void 0 ? void 0 : crew.active_crew) + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(crew === null || crew === void 0 ? void 0 : crew.available) + " ")])]);
        }), 0)]), ((_vm$displayedAvaibili = _vm.displayedAvaibility) === null || _vm$displayedAvaibili === void 0 ? void 0 : _vm$displayedAvaibili.length) > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.totalAvaibility,
            "per-page": _vm.availability.perPage,
            "aria-controls": "my-table",
            "align": "center",
            "first-number": ""
          },
          model: {
            value: _vm.availability.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.availability, "currentPage", $$v);
            },
            expression: "availability.currentPage"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("List Avaibility Crew tidak ada.")])])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Delay (Idle) Crewing Factor")]), _c('small', [_vm._v(" NB: based on operational team input")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$delay, _vm$delay$data;
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "bg-warning text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "bg-warning text-center"
        }, [_vm._v("Fleet")]), _c('th', {
          staticClass: "bg-warning text-center"
        }, [_vm._v("Date")]), _c('th', {
          staticClass: "bg-warning text-center"
        }, [_vm._v("Reason")]), _c('th', {
          staticClass: "bg-warning text-center"
        }, [_vm._v("Remarks")])])]), _c('tbody', _vm._l(_vm.delay.data, function (val, i) {
          var _val$reason;
          return _c('tr', {
            key: 'delay-crew-' + i
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(i + 1) + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(val === null || val === void 0 ? void 0 : val.fleet) + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(_vm.moment(val === null || val === void 0 ? void 0 : val.start_date).format("YYYY MM DD")) + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(val === null || val === void 0 ? void 0 : (_val$reason = val.reason) === null || _val$reason === void 0 ? void 0 : _val$reason.name) + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" - ")])]);
        }), 0)]), ((_vm$delay = _vm.delay) === null || _vm$delay === void 0 ? void 0 : (_vm$delay$data = _vm$delay.data) === null || _vm$delay$data === void 0 ? void 0 : _vm$delay$data.length) > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.delay.meta.total,
            "per-page": _vm.delay.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          model: {
            value: _vm.delay.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.delay.meta, "currentPage", $$v);
            },
            expression: "delay.meta.currentPage"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("List delay tidak ada.")])])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Expired Crew Certificate")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "bg-danger text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "bg-danger text-center"
        }, [_vm._v("Name of Crew")]), _c('th', {
          staticClass: "bg-danger text-center"
        }, [_vm._v("Fleet")]), _c('th', {
          staticClass: "bg-danger text-center"
        }, [_vm._v("Name of Certificate")]), _c('th', {
          staticClass: "bg-danger text-center"
        }, [_vm._v("Valid Until")]), _c('th', {
          staticClass: "bg-danger text-center"
        }, [_vm._v("Status")])])])])])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Crew not yet appraised")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "bg-danger text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "bg-danger text-center"
        }, [_vm._v("Name of Crew")]), _c('th', {
          staticClass: "bg-danger text-center"
        }, [_vm._v("Fleet")]), _c('th', {
          staticClass: "bg-danger text-center"
        }, [_vm._v("Sign On / Sign Off")]), _c('th', {
          staticClass: "bg-danger text-center"
        }, [_vm._v("Action")])])])])])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Crew Statistic Based on Religion")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('ApexChart', {
          attrs: {
            "element": 'patient-chart-02',
            "chartOption": _vm.crewReligionChart
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Crew Statistic Based on Marital Status")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('ApexChart', {
          attrs: {
            "element": 'patient-chart-01',
            "chartOption": _vm.crewMaritalStatusChart
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }