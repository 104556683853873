var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MASTER CAUSE")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Master Reject Reason:",
            "label-for": "name"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.params.name,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "name", $$v);
            },
            expression: "params.name"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": !_vm.menuPermission('update') && !_vm.menuPermission('add') ? '12' : '8'
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Master Reject Reason")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Name")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Alias")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Active")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.filteredTableData, function (p, i) {
          return _c('tr', {
            key: "p-".concat(i)
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(_vm.startRowIndexData + i))]), _c('td', [_vm._v(_vm._s(p.name))]), _c('td', [_vm._v(_vm._s(p.alias ? p.alias : '-'))]), _c('td', [_vm._v(_vm._s(p.is_active === true ? 'Active' : 'Inactive'))]), _c('td', [_vm.menuPermission('update') ? _c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onEditRejectReason(p);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), _vm.menuPermission('delete') ? _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteRejectReason(p);
              }
            }
          }, [!_vm.loading_delete ? _c('i', {
            staticClass: "fa fa-trash"
          }) : _c('span', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status",
              "aria-hidden": "true"
            }
          })]) : _vm._e()], 1)]);
        }), 0)])]), _vm.displayedData.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.totalData,
            "per-page": _vm.data.perPage,
            "aria-controls": "my-table",
            "align": "center",
            "first-number": ""
          },
          model: {
            value: _vm.data.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "currentPage", $$v);
            },
            expression: "data.currentPage"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No Reject data found.")])]];
      },
      proxy: true
    }])
  })], 1), _vm.menuPermission('update') || _vm.menuPermission('add') ? _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.edit_mode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.edit_mode ? 'Edit' : 'Add') + " Reject Reason")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.submitFormRejectReason.apply(null, arguments);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Name Reject Reason",
            "label-for": "name_reject_reason"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "name_reject_reason",
            "type": "text",
            "placeholder": "Type text...",
            "maxlength": "30"
          },
          model: {
            value: _vm.form.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "name", $$v);
            },
            expression: "form.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Alias Reject Reason",
            "label-for": "alias_reject_reason"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "alias_reject_reason",
            "type": "text",
            "placeholder": "Type text...",
            "maxlength": "30"
          },
          model: {
            value: _vm.form.alias,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "alias", $$v);
            },
            expression: "form.alias"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Is Active",
            "label-for": "is_active_reject_reason"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "id": "is_active_reject_reason",
            "name": "is_active",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.is_active,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "is_active", $$v);
            },
            expression: "form.is_active"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.is_active ? 'Active' : 'Inactive') + " ")])], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.loading_submit ? _c('span', [_vm._v(_vm._s(_vm.edit_mode || !_vm.menuPermission('add') ? 'Save' : 'Add'))]) : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Saving... ")]], 2), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.resetFormRejectReason.apply(null, arguments);
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 3876413255)
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }