<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <b-row>
          <b-col lg="3" class="px-3">
            <div class="d-flex mb-2" >
              <b-form-input placeholder="Search fleet..." class="mr-2" @input="filterFleetByName" v-model="searchFleet" />
              <b-button variant="primary" size="sm" @click="onOpenSlideDetailVehicle">Select</b-button>
            </div>
            <div class="d-flex flex-column overflow-auto px-3 mb-4 mt-2" style="max-height:100vh">
              <div  v-for="(fleet, i) in filteredItems" :key="`fleet-${i}`" @click="onCheckedFleet(fleet, i)">
                <b-form-checkbox type="checkbox" :checked="fleet.checked"></b-form-checkbox>
                <div class="d-flex flex-column align-items-center justify-content-center">
                  <img 
                    :src="require('@src/assets/images/fleet/PusherBarge.png')"
                    alt="#"
                    style="width:200px; height:100px"
                  />
                  <small><strong>{{ fleet?.text }}</strong></small>
                </div>
              </div>
            </div>
          </b-col>
          <b-col lg="9">
            <b-row>
              <b-col lg="12" class="mt-2 mb-4">
                <div class="">
                  <tab-nav :pills="true" class=" d-flex">
                    <tab-nav-items
                      class="p-0"
                      :active="true"
                      href="#operational"
                      title="Operational"
                    />
                    <tab-nav-items
                      class="p-0"
                      :active="false"
                      href="#certificate"
                      title="Certificate"
                    />  
                  </tab-nav>
                </div>
              </b-col>
              <b-col lg="12">
                <div id="pills-tabContent-2" class="tab-content">
                  <tab-content-item :active="true" id="operational">
                    <template v-if="fleetId">
                      <TabOperational :fleet-id="fleetId" :preview="true" />
                    </template>
                    <div class="alert alert-dark m-4" v-else>
                     Select Fleet
                    </div>
                  </tab-content-item>
                  <tab-content-item :active="false" id="certificate">
                    <template v-if="fleetId">
                      <TabCertificate :fleet-id="fleetId" :preview="true" />
                    </template>
                    <div class="alert alert-dark m-4" v-else>
                     Select Fleet
                    </div>
                  </tab-content-item>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { projectsActions, companiesActions, fleetsActions, busdevActions, crewingActions } from '@src/Utils/helper'
  import TabOperational from '@src/components/Operational/TabOperational.vue'
  import TabCertificate from '@src/components/certificate/TabCertificate.vue'

  export default {
    name:'SlidePreviewDetailVehicle',
    props:{
      fleet: {
        type: Number,
        default: 0,
      },
      fleetTypeId: {
        type: Number,
        default: 0,
      },
      ownership: {
        type:String,
        default: ''
      },
      optionsFleets: {
        type:Array,
        default: []
      },
      onOpenSlideDetailVehicle: {
        type:Function,
        default: true
      }
    },
    components:{
      TabOperational,
      TabCertificate,
    },
    data(){
      return{
        newOptionsFleets:[],

        searchFleet:'',
        filteredItems: [],

        fleetId: null
      }
    },
    watch:{
      'ownership': {
        deep:true,
        immediate:true,
        handler(){
          this.createNewOptionsFleet()
        }
      },
      'fleet':{
        handler(value){
          this.filteredItems.map(val => {
            if(val.value === this.fleet){
              val.checked = true
            } else {
              val.checked = false
            }
            return val
          })
        }
      },
      'optionsFleets':{
        // deep:true,
        immediate:true,
        handler(value){
          this.createNewOptionsFleet()
        }
      }
    },
    mounted(){},
    methods:{
      onCheckedFleet(data, object){
        this.filteredItems.map(val => {
          if(val.value === data.value){
            val.checked = true
            this.fleetId = val.value
            this.updateParent()
          } else {
            val.checked = false
          }
          return val
        })

        console.log(this.fleetId, "FLEET ID CHECKED")
      },
      updateParent() {
        // Emit an event to notify the parent component about the change
        this.$emit('updateVehicleId', this.fleetId);
      },
      createNewOptionsFleet(){
        this.newOptionsFleets = []

        this.optionsFleets?.map(val => {
          this.newOptionsFleets.push({
            ...val,
            checked:false
          })
        })

        this.filteredItems = this.newOptionsFleets
      },
      filterFleetByName() {
        this.filteredItems = this.newOptionsFleets?.filter(item => {
          // Use item.name.toLowerCase() for case-insensitive search
          return item.text.toLowerCase().includes(this.searchFleet.toLowerCase());
        });
      }
    }
  }
</script>
