var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("BLACK LIST CANDIDATE")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-3",
          attrs: {
            "variant": "success",
            "disabled": _vm.blackList.loading
          },
          on: {
            "click": function click($event) {
              return _vm.exportBlacklist();
            }
          }
        }, [_vm.blackList.loading ? _c('span', {
          staticClass: "spinner-border spinner-border-sm"
        }) : _c('div', {
          staticClass: "d-flex gap-2 align-items-center"
        }, [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _c('span', [_vm._v("Export")])])]), _c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-filter",
            modifiers: {
              "collapse-filter": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "visible": "",
            "id": "collapse-filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "search"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Ketikan Nama"
          },
          model: {
            value: _vm.blackList.params.full_name,
            callback: function callback($$v) {
              _vm.$set(_vm.blackList.params, "full_name", $$v);
            },
            expression: "blackList.params.full_name"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Jabatan",
            "label-for": "jabatan"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsPosition,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Jabatan")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.blackList.params.position_id,
            callback: function callback($$v) {
              _vm.$set(_vm.blackList.params, "position_id", $$v);
            },
            expression: "blackList.params.position_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getBlackList(null);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "d-flex align-items-center my-2"
        }, [_c('span', {
          staticClass: "mr-1"
        }, [_vm._v("Total Blacklist: ")]), _vm._v(" "), _c('strong', [_vm._v(_vm._s(_vm.blackList.meta.total))])]), _c('div', {
          staticClass: "d-flex overflow-auto"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("PHOTO")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("NAMA")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("JABATAN")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("VESSEL")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("COMPANY")]), _c('th', {
          staticClass: "text-center",
          staticStyle: {
            "min-width": "200px"
          }
        }, [_vm._v("REASON OFF")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("REMARKS")])])]), _c('tbody', _vm._l(_vm.blackList.data, function (data, index) {
          var _data$nip, _data$seaferer_code, _data$name_position, _data$vehicle, _data$port_id, _data$company;
          return _c('tr', {
            key: index
          }, [_c('td', [_c('b-img', {
            staticStyle: {
              "width": "70px",
              "height": "70px"
            },
            attrs: {
              "src": data.photo || require('../../assets/images/page-img/10.jpg'),
              "alt": "img"
            }
          })], 1), _c('td', {
            staticClass: "text-truncate"
          }, [_c('router-link', {
            attrs: {
              "to": {
                path: "profile",
                query: {
                  user: 'candidate',
                  id: data.id
                }
              }
            }
          }, [_vm._v(_vm._s(data.full_name))]), _c('br'), _c('small', {
            staticStyle: {
              "color": "gray"
            }
          }, [_vm._v("NIP : "), _c('b', [_vm._v(_vm._s((_data$nip = data === null || data === void 0 ? void 0 : data.nip) !== null && _data$nip !== void 0 ? _data$nip : '-'))]), _c('br'), _vm._v(" Seaferer Code : " + _vm._s((_data$seaferer_code = data === null || data === void 0 ? void 0 : data.seaferer_code) !== null && _data$seaferer_code !== void 0 ? _data$seaferer_code : '-'))])], 1), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s((_data$name_position = data === null || data === void 0 ? void 0 : data.name_position) !== null && _data$name_position !== void 0 ? _data$name_position : '-'))]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s((_data$vehicle = data === null || data === void 0 ? void 0 : data.vehicle) !== null && _data$vehicle !== void 0 ? _data$vehicle : '-')), _c('br'), _c('small', {
            staticStyle: {
              "color": "gray"
            }
          }, [_vm._v("Port : " + _vm._s((_data$port_id = data === null || data === void 0 ? void 0 : data.port_id) !== null && _data$port_id !== void 0 ? _data$port_id : '-'))])]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s((_data$company = data === null || data === void 0 ? void 0 : data.company) !== null && _data$company !== void 0 ? _data$company : '-'))]), _c('td', {
            staticClass: "text-center"
          }, [_c('p', {
            staticClass: "text-justify"
          }, [_vm._v(_vm._s(data.reason_off))])]), _c('td', [_vm._v(_vm._s(data.remarks || 'Tanpa keterangan'))])]);
        }), 0)])]), _vm.blackList.data.length > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.blackList.meta.total,
            "per-page": _vm.blackList.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getBlackList
          },
          model: {
            value: _vm.blackList.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.blackList.meta, "currentPage", $$v);
            },
            expression: "blackList.meta.currentPage"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("List blacklist belum ada.")])];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }