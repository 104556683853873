import axios from './auth'

// const BUSDEV_BASE_URL = `http://localhost:8005/api`
// const BUSDEV_BASE_URL_PUBLIC = `http://localhost:8005`
const BUSDEV_BASE_URL = `${process.env.VUE_APP_API_BUSDEV_BASE_URL}`
const BUSDEV_BASE_URL_PUBLIC = `${process.env.VUE_APP_API_BUSDEV_BASE_URL}`

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  // GET 
  async fetchCountry({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/master-country`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  }, 
  async fetchTerms({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/terms`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },    
  async fetchVehicleTypeMasterSection({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/vehicle-type-m-section`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createVehicleTypeMasterSection({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/vehicle-type-m-section`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async fetchCurrency({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/currency`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchTaxCategory({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/tax-category`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchTaxZone({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/tax-zone`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchDocumentList({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/document-list-data`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  // async fetchCountry({ commit }, params) {
  //   const url = `${BUSDEV_BASE_URL}/country`

  //   const method = 'get'
  //   const headers = {}
  //   const config = {
  //     url,
  //     method,
  //     headers,
  //     params,
  //   }

  //   try {
  //     const response = await axios(config)
  //     let data = response.data
  //     return {
  //       status: 'success',
  //       data,
  //     }
  //   } catch (e) {
  //     return {
  //       status: 'error',
  //       data: e.response.data,
  //     }
  //   }
  // }, 

  async exportCustomer({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/export-customer`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
      responseType:'blob'
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchCustomer({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/customers`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async fetchCustomerById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/customer/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createCustomer({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/customer`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateCustomer({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/customer/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteCustomer({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/customer/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async exportVendor({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/export-vendor`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
      responseType:'blob'
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchVendor({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/vendor`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async fetchVendorById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/vendor/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createVendor({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/vendor`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateVendor({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/vendor/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteVendor({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/vendor/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchInspectionChecklistCategory({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/inspection-checklist-category`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createInspectionChecklistCategory({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/inspection-checklist-category`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateInspectionChecklistCategory({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/inspection-checklist-category/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteInspectionChecklistCategory({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/inspection-checklist-category/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchInspectionChecklistQuestion({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/inspection-checklist-question`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createInspectionChecklistQuestion({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/inspection-checklist-question`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateInspectionChecklistQuestion({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/inspection-checklist-question/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteInspectionChecklistQuestion({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/inspection-checklist-question/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchSectionHead({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/section-head`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createSectionHead({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/section-head`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateSectionHead({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/section-head/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteSectionHead({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/section-head/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchComponentExpense({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/component-expense`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createComponentExpense({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/component-expense`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateComponentExpense({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/component-expense/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteComponentExpense({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/component-expense/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchQuestionType({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/question-type`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async fetchQuestionTypeById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/question-type/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createQuestionType({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/question-type`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateQuestionType({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/question-type/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteQuestionType({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/question-type/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchVehicleType({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/vehicle-type`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async fetchVehicleTypeById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/vehicle-type/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   

  async fetchCollectionForm({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/collection-form`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },     
  async fetchOptionsCollectionForm({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/options-collection-form`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async fetchCollectionFormById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/collection-form/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createCollectionForm({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/collection-form`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateCollectionForm({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/collection-form/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteCollectionForm({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/collection-form/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchFormCategory({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/collection-form-category`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async fetchFormCategoryById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/collection-form-category/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createFormCategory({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/collection-form-category`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateFormCategory({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/collection-form-category/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteFormCategory({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/collection-form-category/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchFormQuestion({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/collection-form-question`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async fetchFormQuestionById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/collection-form-question/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createFormQuestion({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/collection-form-question`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateFormQuestion({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/collection-form-question/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteFormQuestion({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/collection-form-question/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },  
  
  async fetchVehicleTypeGroup({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/vehicle-type-group`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchVehicleTypeGroupById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/vehicle-type-group/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createVehicleTypeGroup({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/vehicle-type-group`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateVehicleTypeGroup({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/vehicle-type-group/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteVehicleTypeGroup({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/vehicle-type-group/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  }, 
  
  async fetchProjects({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/project`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchProjectById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/project/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createProject({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/project`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateProject({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/project/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteProject({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/project/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchProjectContract({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/project-contract-parent`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchProjectContractById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/project-contract-parent/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createProjectContract({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/project-contract-parent`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async updateProjectContract({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/update-project-contract/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async fetchShipmentPlanningTable({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/shipment-planning-table`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },    
  async fetchDetailShipmentPlanning({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/detail-shipment-planning/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },    
  async fetchDetailProjectContract({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/detail-project-contract/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async fetchDetailProjectContractPublic({ commit }, params) {
    const url = `${BUSDEV_BASE_URL_PUBLIC}/detail-project-contract-public/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createShipmentPlanning({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/shipment-planning`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async saveMovedProjects({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/save-moved-project`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async savedMovedStage({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/save-moved-stage`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },  
  async changeDenyStage({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/change-deny-stage`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },  
  async changeSpalProcessStage({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/change-spal-process-stage`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  }, 
  async updatePeriodeDate({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/update-periode-date`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  }, 
  async changeValuePrioritize({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/change-value-prioritize`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  }, 
  async changeStatusStage({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/change-status-stage`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateStageTitle({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/update-stage-title`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteProjectContractPlan({ commit }, params = {}) {
    const url = `${BUSDEV_BASE_URL}/project-contract-plan/${params.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteProjectContract({ commit }, params = {}) {
    const url = `${BUSDEV_BASE_URL}/delete-project-contract/${params.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },  

  async fetchContractType({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/contract-type`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  }, 
  async fetchContractTypeById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/contract-type/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createContractType({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/contract-type`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateContractType({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/contract-type/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteContractType({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/contract-type/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchDetailProjectStage({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/detail-project-stage`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchDetailProjectStageById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/detail-project-stage/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createDetailProjectStage({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/detail-project-stage`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateDetailProjectStage({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/detail-project-stage/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteDetailProjectStage({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/detail-project-stage/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async createProjectContractTender({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/project-contract-tender`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateProjectContractTender({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/project-contract-tender/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  }, 
  async deleteProjectContractTender({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/project-contract-tender/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  
  async createProjectContractDocument({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/project-contract-document`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateProjectContractDocument({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/project-contract-document/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteProjectContractDocument({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/project-contract-document/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchVehicleTypeForm({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/vehicle-type-form`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async fetchVehicleTypeFormById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/vehicle-type-form/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createVehicleTypeForm({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/vehicle-type-form`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data,
      }
    }
  },
  async updateVehicleTypeForm({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/vehicle-type-form/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteVehicleTypeForm({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/vehicle-type-form/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchInspectionChecklist({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/inspection-checklist`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async fetchInspectionChecklistById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/inspection-checklist/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createInspectionChecklist({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/inspection-checklist`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data,
      }
    }
  },
  async updateInspectionChecklist({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/inspection-checklist/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteInspectionChecklist({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/inspection-checklist/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  }, 
  async setProjectForm({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/set-project-form`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data:payload.data
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async exportInspectionChecklist({ commit }, params = {}) {
    const url = `${BUSDEV_BASE_URL}/inspection-checklist-export/${params.id}/${params.fileType}`

    const method = "get";
    const headers = {};
    const config = {
        url,
        method,
        headers,
        responseType: 'blob'
    };

    try {
        const response = await axios(config);
        let data = response.data;
        return {
            status: 'success',
            data,
        };
    } catch (e) {
        return {
            status: 'error',
            data: e.response.data
        };
    }
  }, 
  async saveApprovalUser({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/save-approval-user/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async saveApprovalUserPublic({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL_PUBLIC}/save-approval-user-public/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchCharterCompany({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/charter-company`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchCharterCompanyById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/charter-company/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createCharterCompany({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/charter-company`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateCharterCompany({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/charter-company/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteCharterCompany({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/charter-company/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchTimeVoyage({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/time-voyage`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchTimeVoyageById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/time-voyage/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createTimeVoyage({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/time-voyage`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateTimeVoyage({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/time-voyage/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteTimeVoyage({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/time-voyage/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchCostFactor({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/cost-factor`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchCostFactorById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/cost-factor/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createCostFactor({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/cost-factor`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateCostFactor({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/cost-factor/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteCostFactor({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/cost-factor/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchMiscellaneousCharge({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/miscellaneous-charge`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchMiscellaneousChargeById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/miscellaneous-charge/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createMiscellaneousCharge({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/miscellaneous-charge`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateMiscellaneousCharge({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/miscellaneous-charge/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteMiscellaneousCharge({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/miscellaneous-charge/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchPortStay({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/port-stay`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchPortStayById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/port-stay/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createPortStay({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/port-stay`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updatePortStay({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/port-stay/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deletePortStay({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/port-stay/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchResultPlus({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/result-plus`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchResultPlusById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/result-plus/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createResultPlus({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/result-plus`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateResultPlus({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/result-plus/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteResultPlus({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/result-plus/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async createOrUpdateProjectPfa({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/project-pfa`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteProjectPfa({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/project-pfa/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async fetchPfaNextId({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/project-pfa-next-id`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async exportPFA({ commit }, params = {}) {
    const url = `${BUSDEV_BASE_URL}/pfa-export/${params.project_contract_id}/${params.file}`

    const method = "get";
    const headers = {};
    const config = {
        url,
        method,
        headers,
        responseType: 'blob'
    };

    try {
        const response = await axios(config);
        let data = response.data;
        return {
            status: 'success',
            data,
        };
    } catch (e) {
        return {
            status: 'error',
            data: e.response.data
        };
    }
  },

  async fetchVehicleTypeSectionRelationById({ commit }, params = {}) {
    const url = `${BUSDEV_BASE_URL}/vehicle-type-relation/${params.vehicle_type_id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  }, 
  async createVehicleTypeSectionRelation({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/vehicle-type-relation`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteVehicleTypeSectionRelation({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/vehicle-type-relation/${payload.vehicle_type_id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async sendEmailNotification({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/send-email`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchSpal({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/spal`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async fetchSpalById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/spal/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createSpal({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/spal`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateSpal({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/spal/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteSpal({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/spal/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },  

  async fetchCargoType({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/cargo-type`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  }, 
  async fetchCargoTypeById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/cargo-type/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createCargoType({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/cargo-type`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateCargoType({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/cargo-type/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteCargoType({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/cargo-type/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },  

  async fetchUnit({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/unit`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  }, 
  async fetchUnityId({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/unit/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createUnit({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/unit`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateUnit({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/unit/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteUnit({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/unit/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },  

  async fetchMasterPfa({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/master-pfa`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async fetchMasterPfaById({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/master-pfa/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createMasterPfa({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/master-pfa`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateMasterPfa({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/master-pfa/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteMasterPfa({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/master-pfa/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async createVendorClass({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/vendor-class`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateVendorClass({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/vendor-class/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteVendorClass({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/vendor-class/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },  
  async fetchVendorClass({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/vendor-class`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  

  async createVendorType({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/vendor-type`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateVendorType({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/vendor-type/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteVendorType({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/vendor-type/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },  
  async fetchVendorType({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/vendor-type`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  }, 

  async createCustomerClass({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/customer-class`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateCustomerClass({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/customer-class/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteCustomerClass({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/customer-class/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },  
  async fetchCustomerClass({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/customer-class`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  

  async fetchMasterBank({ commit }, params) {
    const url = `${BUSDEV_BASE_URL}/master-bank`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async createMasterBank({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/master-bank`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateMasterBank({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/master-bank/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteMasterBank({ commit }, payload = {}) {
    const url = `${BUSDEV_BASE_URL}/master-bank/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
}