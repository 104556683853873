<template>
  <form action="#" method="post" @submit="onSaveInduction">
    <b-row
      v-for="(state, index) in department_form"
      v-bind:key="index"
    >
      <b-col md="12">
        <b-card no-body class="mb-1">
          <b-card-header
            header-tag="header"
            class="p-1"
            role="tab"
          >
            <b-button
              :disabled="
                state?.checked ||
                (state.department_id !== profile_department &&
                  profile_department !== null)
                  ? true
                  : false
              "
              block
              v-b-toggle="`accordion-` + index"
              :variant="`${
                state?.checked ? 'success' : 'info'
              }`"
              :class="`${
                state?.checked &&
                `justify-content-between d-flex align-items-center`
              }`"
              ><span>{{ state?.name }}</span>
              <i
                v-if="state?.checked"
                class="fas fa-check"
              ></i>
            </b-button>
          </b-card-header>
          <b-collapse
            :id="`accordion-` + index"
            :visible="false"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-form-group
                label="Induksi Oleh"
                label-for="induction_by"
              >
                <input
                  type="text"
                  class="form-control"
                  id="induction_by"
                  v-model="state.induction_by"
                />
              </b-form-group>
              <b-form-group
                label="Tanggal Induksi"
                label-for="induction_date"
              >
                <input
                  type="date"
                  class="form-control"
                  id="induction_date"
                  v-model="state.induction_date"
                />
              </b-form-group>
              <b-form-group
                label="Attachment"
                label-for="attachment"
              >
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="validatedCustomFile"
                    @change="
                      (event) =>
                        prefiewFilesInduction(event, state)
                    "
                    accept="image/*,application/*"
                  />
                  <label
                    class="custom-file-label"
                    for="validatedCustomFile"
                    v-if="!state.attachment"
                    >Browse file...</label
                  >
                  <label
                    class="custom-file-label"
                    for="validatedCustomFile"
                    v-else
                    >{{ state.attachment_label }}</label
                  >
                </div>
              </b-form-group>
              <b-form-group label="Notes" label-for="notes">
                <b-form-textarea
                  id="notes"
                  name="notes"
                  rows="3"
                  v-model="state.notes"
                ></b-form-textarea>
              </b-form-group>
            </b-card-body>
          </b-collapse>
        </b-card>
      </b-col>
    </b-row>
    <div
      v-if="loading"
      class="d-flex justify-content-center w-100 mt-3"
    >
      <span class="spinner-border spinner-border-lg" />
    </div>

    <b-row class="mt-3">
      <b-col :md="'6'">
        <b-button
          variant="secondary"
          block
          @click="$bvModal.hide('induction')"
          >Close</b-button
        >
      </b-col>
      <b-col md="6">
        <b-button
          variant="primary"
          block
          type="submit"
          :disabled="saving"
          ><i class="fa fa-file-signature" v-if="!saving"></i>
          <span v-if="!saving">SUBMIT</span>
          <span
            v-if="saving"
            class="spinner-border spinner-border-sm"
          />
        </b-button>
      </b-col>
    </b-row>
  </form>
</template>

<script>
import { crewingActions } from '@src/Utils/helper'

export default {
  name:'ContentInduction',
  data() {
    return {
      saving:false,
      department_form:[],
      master_list_department:[],

      profile_department: JSON.parse(localStorage.getItem('profile')).userDetail.department,
    }
  },
  props: {
    getProfileCrew:{
      type: Function,
      default: () => {},
    }
  },
  computed: {
   
  },
  mounted() {
    this.getMasterDepartmentList()
  },
  methods: {
    ...crewingActions,
    prefiewFilesInduction: function (event, state) {
      const input = event.target

      if (input.files && input.files[0]) {
        state.attachment_label = input.files[0].name
        state.attachment = input.files[0]
      }
    },

    async getMasterDepartmentList() {
      this.loading = true
      this.department_form = []
      this.master_list_department = []
      let induction_checklist = []

      let params = {
        is_induction: 1,
      }
      const { status, data } = await this.fetchMasterDepartmentList(params)
      const res = await this.fetchInductionChecklistCrew(Number(this.$route.query.id))

      if (status !== 'success') {
        this.loading = false
        return
      }

      this.master_list_department = data?.data
      induction_checklist = res.data

      this.master_list_department.map((val) => {
        this.department_form.push({
          department_id: val.id,
          name: `${val.name} Department`,
          induction_by: null,
          induction_date: null,
          attachment: null,
          attachment_label: '',
          notes: '',
          checked: false,
        })
      })

      if (induction_checklist.length >= 1) {
        this.department_form.map((val) => {
          induction_checklist.map((item) => {
            if (val.department_id === item.department_id) {
              val.checked = true
            }
          })
        })
      }
      this.loading = false
    },

    async onSaveInduction(e) {
      e.preventDefault()

      let isFileFilled = false
      let readyToSave = []

      this?.department_form?.map((val) => {
        if(!val.attachment) isFileFilled = true

        if (!val.checked) {
          if (this.profile_department === null) {
            readyToSave.push({
              department_id: val.department_id,
              induction_by: val.induction_by,
              induction_date: val.induction_date,
              attachment: val.attachment,
              notes: val.notes,
            })
          } else if (val.department_id === Number(this.profile_department)) {
            readyToSave.push({
              department_id: val.department_id,
              induction_by: val.induction_by,
              induction_date: val.induction_date,
              attachment: val.attachment,
              notes: val.notes,
            })
          }
        }
      })

      if(isFileFilled) return this.$swal(`Required`, 'Induction Attachment Required, please fill it.', 'warning')

      this.saving = true

      let statusFetching = null

      const promises = readyToSave.map(async (val) => {
        let formData = new FormData()
        formData.append('department_id', val.department_id)
        formData.append('induction_by', val.induction_by)
        formData.append('induction_date', val.induction_date)
        formData.append('attachment', val.attachment)
        formData.append('notes', val.notes)

        const { status, data } = await this.setInductionCandidateCrew({
          formData,
          id: Number(this.$route.query.id),
        })

        if (status === 422) {
          statusFetching = status
        }

        this.department_form.map((item) => {
          if (val.department_id === item.department_id) {
            if (status !== 422) {
              item.checked = true
            }
          }
        })
      })

      await Promise.all(promises).then(async () => {
        if (statusFetching === null) {
          this.saving = false
          this.$swal(`Tersimpan`, 'Berhasil melakukan induction', 'success')

          this.$nextTick(() => {
            this.$bvModal.hide('induction')
          })
          this.getProfileCrew()
        } else {
          this.saving = false
        }

        this.saving = false
      })
    },
  },
}
</script>