<template>
  <div>
    <tab-nav :tabs="true" align="center">
      <tab-nav-items :active="true" href="#category" title="Master Data Category" />
      <tab-nav-items :active="false" href="#question" title="Master Data Question" />
    </tab-nav>
    <div id="myTabContent2" class="tab-content">
      <tab-content-item :active="true" id="category">
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"
                ><strong>MASTER DATA CATEGORY</strong></h4
                >
              </template>
              <template v-slot:headerAction>
                <!-- <b-button variant="primary">
                  <template v-if="!loading_export_power">
                    <i class="fa fa-file-excel"></i> &nbsp; Export
                  </template>
                  <template v-else>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                    Exporting...
                  </template>
                </b-button> -->
              </template>
              <template v-slot:body>
                <b-form class="row" @submit.prevent="getInspectionChecklistCategory()">
                  <b-col cols="12" md="3">
                    <b-form-group
                      label="Search Master Data Category:"
                      label-for="kind-fleet"
                    >
                      <b-form-input
                        id="name"
                        class="w-100"
                        type="text"
                        placeholder="Search..."
                        v-model="categoryList.params.name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="2">
                    <b-form-group label="Search" label-for="button_search">
                      <b-button type="submit" variant="primary" class="ml-2"><i class="fa fa-search"></i></b-button>
                    </b-form-group>
                  </b-col>
                </b-form>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="8">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">List Master Data Category</h4>
              </template>
              <template v-slot:body>
                <div v-if="categoryList._loading" class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <div class="table-responsive" v-if="categoryList.data && categoryList.data.length > 0">
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th width="10%">No</th>
                        <th width="25%">Name</th>
                        <th width="10%">Alias</th>
                        <th width="10%">Maintenance Component</th>
                        <th width="10%">Vehicle Type Section</th>
                        <th width="10%">Status</th>
                        <th width="15%">Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(p, i) in categoryList.data" :key="`p-${i}`">
                        <td>{{ i + 1 }}</td>
                        <td>{{ p.name }}</td>
                        <td>{{ p.alias }}</td>
                        <td>{{ p.maintenance_component?.component_name ?? '-' }}</td>
                        <td>{{ p.vehicle_type_section?.name ?? '-' }}</td>
                        <td>{{ p.status ? 'Active' : 'Inactive' }}</td>
                        <td>
                          <b-button variant="success" size="sm" class="mr-2" @click="editFormCategory(p)"><i class="fa fa-edit"></i></b-button>
                          <b-button variant="danger" size="sm" @click="onDeleteCategory(p)">
                            <i v-if="!delete_category" class="fa fa-trash"></i>
                            <template v-else>
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Deleting...
                            </template>
                          </b-button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="categoryList.data && categoryList.data.length > 0">
                    <b-pagination
                      v-model="categoryList.meta.page"
                      :total-rows="categoryList.meta.totalItem"
                      @change="getInspectionChecklistCategory"
                      :per-page="categoryList.meta.perPage"
                      first-number
                      align="center"
                    ></b-pagination>
                  </div>
                  <p v-else class="text-center my-4">No Category data found.</p>
                </template>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="4">
            <iq-card
              :class="{
                'border border-danger shadow-lg': category_edit,
              }"
            >
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ category_edit ? 'Edit' : 'Add' }} Category</h4>
              </template>
              <template v-slot:body>
                <b-form @submit.prevent="onSaveCategory()">
                  <b-form-group label="Category" label-for="name">
                    <b-form-input v-model="formCategory.name" id="name" type="text" placeholder="Type text" maxlength="30"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Parent" label-for="parent_id">
                    <v-select
                      v-model="formCategory.parent_id"
                      label="text"
                      :options="listParent"
                      :reduce="(obj) => obj.value"
                      placeholder="Select parent"
                    ></v-select>
                  </b-form-group>
                  <b-form-group label="Alias" label-for="alias">
                    <b-form-input v-model="formCategory.alias" id="alias" type="text" placeholder="Type text" maxlength="30"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Maintenance Component" label-for="maintenance_component_id">
                    <b-form-select
                      plain
                      v-model="formCategory.maintenance_component_id"
                      size="sm"
                      placeholder="Select options..."
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null"
                          >Select Maintenance Component</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label="Vehicle Type Section" label-for="vehicle_type_m_section_id">
                    <b-form-select
                      plain
                      v-model="formCategory.vehicle_type_m_section_id"
                      size="sm"
                      placeholder="Select options..."
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null"
                          >Select Vehicle Type</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label="Status" label-for="status">
                    <b-form-checkbox v-model="formCategory.status" name="check-button" switch inline>Active / Inactive
                    </b-form-checkbox>
                  </b-form-group>
                  <hr />
                  <b-form-group class="text-right">
                    <b-button type="submit" variant="primary">
                      <span v-if="!category_edit">Add</span>
                      <span v-else>Save Edit</span>
                    </b-button>
                    <b-button
                      type="button"
                      variant="danger"
                      class="iq-bg-danger ml-3"
                      @click="resetFormCategory()"
                    >Cancel</b-button>
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
      <tab-content-item :active="false" id="question">
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"
                ><strong>MASTER DATA QUESTION</strong></h4
                >
              </template>
              <template v-slot:headerAction>
                <!-- <b-button variant="primary">
                  <template v-if="!loading_export_power">
                    <i class="fa fa-file-excel"></i> &nbsp; Export
                  </template>
                  <template v-else>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                    Exporting...
                  </template>
                </b-button> -->
              </template>
              <template v-slot:body>
                <b-form class="row" @submit.prevent="getInspectionChecklistQuestion()">
                  <b-col cols="12" md="3">
                    <b-form-group
                      label="Search Master Data Question:"
                      label-for="kind-fleet"
                    >
                      <b-form-input
                        id="question"
                        class="w-100"
                        type="text"
                        placeholder="Search..."
                        v-model="questionList.params.question"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="2">
                    <b-form-group label="Search" label-for="button_search">
                      <b-button type="submit" variant="primary" class="ml-2"><i class="fa fa-search"></i></b-button>
                    </b-form-group>
                  </b-col>
                </b-form>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="8">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">List Master Data Question</h4>
              </template>
              <template v-slot:body>
                <div v-if="questionList._loading" class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <div v-if="questionList.data && questionList.data.length > 0">
                    <div class="table-responsive">
                      <table class="table table-striped" v-for="(parentQuestion, parentQuestionIndex) in questionList.data" :key="parentQuestionIndex">
                        <thead>
                          <tr v-if="!parentQuestion.parent_id && parentQuestion?.inspection_checklist_question?.length <= 0">
                            <th colspan="6" class="align-middle text-center">{{ parentQuestion?.name }}</th>
                          </tr>
                          <tr v-else>
                            <th>No</th>
                            <th>{{parentQuestion?.name }}</th>
                            <th>Alias</th>
                            <th>Status</th>
                            <th>#</th>
                          </tr>
                        </thead>
                        <tbody v-if="parentQuestion?.inspection_checklist_question?.length > 0">
                          <tr v-for="(question, questionId) in parentQuestion?.inspection_checklist_question" :key="questionId">
                            <td>{{ questionId + 1 }}</td>
                            <td>{{ question.question }}</td>
                            <td>{{ question.alias }}</td>
                            <td :class="question.status ? 'text-success' : 'text-danger'">{{ question.status ? 'Active' : 'Inactive' }}</td>
                            <td>
                              <b-button variant="success" size="sm" class="mr-2"><i class="fa fa-edit" @click="editFormQuestion(question)"></i></b-button>
                              <b-button variant="danger" size="sm" @click="onDeleteQuestion(question)">
                                <i v-if="!delete_question" class="fa fa-trash"></i>
                                <template v-else>
                                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Deleting...
                                </template>
                              </b-button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- <div>
                      <b-pagination
                        v-model="questionList.meta.page"
                        :total-rows="questionList.meta.totalItem"
                        @change="getInspectionChecklistQuestion"
                        :per-page="questionList.meta.perPage"
                        first-number
                        align="center"
                      ></b-pagination>
                    </div> -->
                  </div>
                  <p v-else class="text-center my-4">No Question data found.</p>
                </template>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="4">
            <iq-card
              :class="{
            'border border-danger shadow-lg': question_edit,
            }"
            >
              <template v-slot:headerTitle>
                <h4 class="card-title"
                >{{ question_edit ? 'Edit' : 'Add' }} Question</h4
                >
              </template>
              <template v-slot:body>
                <b-form @submit.prevent="onSaveQuestion()">
                  <b-form-group label="Question" label-for="question">
                    <b-form-input v-model="formQuestion.question" id="question" type="text" placeholder="Type text"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Alias" label-for="alias">
                    <b-form-input v-model="formQuestion.alias" id="alias" type="text" placeholder="Type text" maxlength="30"></b-form-input>
                  </b-form-group>  
                  <b-form-group label="Checklist Category" label-for="checklist_category_id">
                    <v-select
                      v-model="formQuestion.checklist_category_id"
                      label="text"
                      :options="listCategory"
                      :reduce="(obj) => obj.value"
                      placeholder="Select checklist category"
                      required
                    ></v-select>
                  </b-form-group>
                  <b-form-group label="Status" label-for="status">
                    <b-form-checkbox v-model="formQuestion.status" name="check-button" switch inline>Active / Inactive
                    </b-form-checkbox>
                  </b-form-group>
                  <hr />
                  <b-form-group class="text-right">
                    <b-button type="submit" variant="primary">
                      <span v-if="saving_question" class="spinner-border spinner-border-sm"/>
                      <span v-else-if="!question_edit && !saving_question">Add</span>
                      <span v-else-if="question_edit && !saving_question">Save Edit</span>
                    </b-button>
                    <b-button
                      type="button"
                      variant="danger"
                      class="iq-bg-danger ml-3"
                      @click="resetFormQuestion()"
                    >Cancel</b-button>
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
    </div>
  </div>
</template>

<script>
import { busdevActions } from '@src/Utils/helper'
import _ from 'lodash'
export default {
  name: 'MasterChecklist',
  data () {
    return {
      listCategory:[],
      listParent:[],

      questionList: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          question: '',
          // active: true,
          perPage: 10000,
          page: 1
        },
        meta: {
          perPage: 0,
          page: 0,
          totalItem: 0,
          totalPages: 0
        }
      },
      categoryList: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          name: '',
          // active: true,
          perPage: 30,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      loading_question:false,
      saving_question:false,
      delete_question:false,

      loading_category:false,
      saving_category:false,
      delete_category:false,

      formQuestion: {
        alias: '',
        question: '',
        checklist_category_id: null,
        status: true,
      },
      formCategory: {
        alias: '',
        name: '',
        maintenance_component_id: null,
        vehicle_type_m_section_id: null,
        parent_id: null,
        status: true,
      },
      question_edit: null,
      question_id: null,
      category_edit: null,
      category_id: null
    }
  },
  async mounted () {
    await this.getInspectionChecklistCategory()
    await this.getListCategory()

    await this.getInspectionChecklistQuestion()
  },
  created () {
    this.getInspectionChecklistCategory = _.debounce(this.getInspectionChecklistCategory, 200)
    this.getInspectionChecklistQuestion = _.debounce(this.getInspectionChecklistQuestion, 200)
  },
  methods: {
    ...busdevActions,
    async getListCategory () {
      let params = {
        page: 1,
        perPage:10000,
        status: 1
      }

      const res = await this.fetchInspectionChecklistCategory(params)
  
      if (res.data.status == true && res?.data?.data?.data.length > 0) {
        res.data.data?.data?.map(val => {
          this.listCategory.push({
            text:val.name,
            value:val.id,
          })
        })
        res.data.data?.data?.map(val => {
          if(val.parent_id === null){
            this.listParent.push({
              text:val.name,
              value:val.id,
            })
          }
        })
      }
    }, 
    async getInspectionChecklistCategory (page) {
      this.categoryList._loading = true
      this.categoryList.data = []
      let params = {
        ...this.categoryList.params,
        page: page || 1
      }
      if(!params.name) delete params.name 

      const res = await this.fetchInspectionChecklistCategory(params)
  
      if (res.data.status == true) {
        if(res?.data?.data?.data.length > 0){
          this.categoryList.data = res.data.data?.data
          this.categoryList.meta = res.data.data?.meta
        }
        this.categoryList._loading = false
      } else {
        this.categoryList._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async getInspectionChecklistQuestion (page) {
      this.questionList._loading = true
      this.questionList.data = []
      let params = {
        ...this.questionList.params,
        page: page || 1
      }
      if(!params.question) delete params.question 

      const res = await this.fetchInspectionChecklistQuestion(params)

      if (res.data.status == true) {
        if(res?.data?.data?.data.length > 0){
          this.questionList.data = res.data.data?.data
          this.questionList.meta = res.data.data?.meta
        }
        this.questionList._loading = false
      } else {
        this.questionList._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },

    async onSaveCategory () {
      this.saving_category = true
      let payload = {
        ...this.formCategory,
        parent_id: this.formCategory.parent_id ?? null,
        status: this.formCategory.status ? 1 : 0
      }

      let res = null
      if(this.category_edit && this.category_edit){
        res = await this.updateInspectionChecklistCategory({data: payload, id: this.category_id})
      } else if (!this.category_edit) {
        res = await this.createInspectionChecklistCategory({data: payload})
      }

      console.log(res.data, "what is this")

      if (res.data.status === true) {
        this.$swal(`${this.category_edit ? 'Edit' : "Add"} Inspection Checklist Category Success!`, res.data.message, 'success')
        this.resetFormCategory()
        this.getInspectionChecklistCategory()
        this.saving_category = false
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
        this.saving_category = false
      }
      this.saving_category = false
    },
    async onSaveQuestion() {
      this.saving_question = true
      let payload = {
        ...this.formQuestion,
        status: this.formQuestion.status ? 1 : 0
      }

      let res = null
      if(this.question_edit && this.question_id){
        res = await this.updateInspectionChecklistQuestion({data: payload, id: this.question_id})
      } else if (!this.question_edit) {
        res = await this.createInspectionChecklistQuestion({data: payload})
      }

      console.log(res.data, "what is this")

      if (res.data.status === true) {
        this.$swal(`${this.question_edit ? 'Edit' : "Add"} Inspection Checklist Question Success!`, res.data.message, 'success')
        this.resetFormQuestion()
        this.getInspectionChecklistQuestion()
        this.saving_question = false
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
        this.saving_question = false
      }
      this.saving_question = false
    },

    resetFormCategory (data) {
      this.formCategory = {
        "alias":'',
        "name":'',
        "maintenance_component_id":null,
        "vehicle_type_m_section_id":null,
        "parent_id":null,
        "status":true
      }
      this.category_edit = false
      this.category_id = null
    },
    resetFormQuestion (data) {
      this.formQuestion = {
        "alias":'',
        "question":'',
        "checklist_category_id":null,
        "status":true
      }
      this.question_edit = false
      this.question_id = null
    },

    editFormCategory (data) {
      this.formCategory = {
        "alias":data.alias,
        "name":data.name,
        "maintenance_component_id":data.maintenance_component_id,
        "vehicle_type_m_section_id":data.vehicle_type_m_section_id,
        "parent_id":data.parent_id,
        "status":data.status == 1 ? true : false

      }
      this.category_edit = true
      this.category_id = data.id
    },
    editFormQuestion (data) {
      this.formQuestion = {
        "alias":data?.alias,
        "question":data?.question,
        "checklist_category_id":data?.checklist_category_id,
        "status":data?.status == 1 ? true : false
      }
      this.question_edit = true
      this.question_id = data.id
    },

    async onDeleteCategory (data) {
      this.$swal({
        title: 'Delete Category?',
        text: `Category ${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.saving_category = true
          let res = await this.deleteInspectionChecklistCategory({id: data.id})
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
            this.saving_category = false
          } else {
            this.$swal(
              `Category deleted!`,
              `Category ${data.name} successfully deleted`,
              'success'
            )
            await this.getInspectionChecklistCategory()
            this.saving_category = false
          }
        }
      })
    },
    async onDeleteQuestion (data) {
      this.$swal({
        title: 'Delete Question?',
        text: `${data.question} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.saving_question = true
          let res = await this.deleteInspectionChecklistQuestion({id: data.id})
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
            this.saving_question = false
          } else {
            this.$swal(
              `Question deleted!`,
              `${data.name} successfully deleted`,
              'success'
            )
            await this.getInspectionChecklistQuestion()
            this.saving_question = false
          }
        }
      })
    },
  }
}
</script>

<style scoped>
</style>
