var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MASTER QUESTION TYPE")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.getQuestionType();
            }
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Master Question Type :",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.questionTypeList.params.name,
            callback: function callback($$v) {
              _vm.$set(_vm.questionTypeList.params, "name", $$v);
            },
            expression: "questionTypeList.params.name"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Master Question Type")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.questionTypeList._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.questionTypeList.data && _vm.questionTypeList.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Code")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Name")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rule")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.questionTypeList.data, function (p, i) {
          return _c('tr', {
            key: "p-".concat(i)
          }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(_vm._s(p.code))]), _c('td', [_vm._v(_vm._s(p.name))]), _c('td', [_vm._v(_vm._s(p.rule))]), _c('td', [_vm._v(_vm._s(p.status ? 'Active' : 'Inactive'))]), _c('td', [_c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.editFormQuestionType(p);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]), _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteQuestionType(p);
              }
            }
          }, [!_vm.delete_question_type ? _c('i', {
            staticClass: "fa fa-trash"
          }) : [_c('span', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status",
              "aria-hidden": "true"
            }
          }), _vm._v("Deleting... ")]], 2)], 1)]);
        }), 0)])]) : _vm._e(), _vm.questionTypeList.data && _vm.questionTypeList.data.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.questionTypeList.meta.totalItem,
            "per-page": _vm.questionTypeList.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getQuestionType
          },
          model: {
            value: _vm.questionTypeList.meta.page,
            callback: function callback($$v) {
              _vm.$set(_vm.questionTypeList.meta, "page", $$v);
            },
            expression: "questionTypeList.meta.page"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No Question Type data found.")])]];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.question_type_edit
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.question_type_edit ? 'Edit' : 'Add') + " Question Type")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSaveQuestionType();
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Code",
            "label-for": "code"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "code",
            "type": "text",
            "placeholder": "Type text",
            "maxlength": "30"
          },
          model: {
            value: _vm.formQuestionType.code,
            callback: function callback($$v) {
              _vm.$set(_vm.formQuestionType, "code", $$v);
            },
            expression: "formQuestionType.code"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Name",
            "label-for": "name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "placeholder": "Type text",
            "maxlength": "30"
          },
          model: {
            value: _vm.formQuestionType.name,
            callback: function callback($$v) {
              _vm.$set(_vm.formQuestionType, "name", $$v);
            },
            expression: "formQuestionType.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Rule",
            "label-for": "rule"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "rule",
            "type": "text",
            "placeholder": "Type text",
            "maxlength": "30"
          },
          model: {
            value: _vm.formQuestionType.rule,
            callback: function callback($$v) {
              _vm.$set(_vm.formQuestionType, "rule", $$v);
            },
            expression: "formQuestionType.rule"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.formQuestionType.status,
            callback: function callback($$v) {
              _vm.$set(_vm.formQuestionType, "status", $$v);
            },
            expression: "formQuestionType.status"
          }
        }, [_vm._v("Active / Inactive ")])], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.question_type_edit ? _c('span', [_vm._v("Add")]) : _c('span', [_vm._v("Save Edit")])]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.resetFormQuestionType();
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }