<template>
  <form
    action="#"
    method="post"
    @submit="onSaveMedichalCheckup"
  >
    <b-row
      v-for="(state, index) in medical_checkup"
      v-bind:key="index"
    >
      <b-col md="12">
        <div
          v-if="medical_checkup.length > 1 && index > 0"
          class="d-flex w-100 px-4 my-1 justify-content-end cursor-pointer position-absolute top-0"
          @click="deleteMedicalCheckupForm(index)"
        >
          <i class="fa fa-trash text-danger"></i>
        </div>
        <b-form-group
          label="Attachment"
          label-for="attachment"
        >
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="validatedCustomFile"
              accept="image/*,application/*"
              @change="(event) => prefiewFiles(event, state)"
            />

            <label
              class="custom-file-label"
              for="validatedCustomFile"
              v-if="!state.file"
              >Browse file...</label
            ><label
              class="custom-file-label"
              for="validatedCustomFile"
              v-else
              >{{ state.label_file }}</label
            >
            <!-- this.medical_checkup -->
          </div>
        </b-form-group>
        <b-form-group label="Notes" label-for="notes">
          <b-form-textarea
            id="notes"
            name="notes"
            rows="3"
            v-model="state.nama_document"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col md="12">
        <b-button
          variant="success"
          class="mt-1"
          @click="addMedicalCheckupForm"
          ><i class="fa fa-plus"></i> ADD FORM</b-button
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col :md="'6'">
        <b-button
          variant="secondary"
          block
          @click="$bvModal.hide('medical-checkup')"
          >Close</b-button
        >
      </b-col>
      <b-col md="6">
        <b-button
          variant="primary"
          block
          type="submit"
          :disabled="saving"
          ><i class="fa fa-file-signature" v-if="!saving"></i>
          <span v-if="!saving">SUBMIT</span>
          <span
            v-if="saving"
            class="spinner-border spinner-border-sm"
          />
        </b-button>
      </b-col>
    </b-row>
  </form>
</template>

<script>
import { crewingActions } from '@src/Utils/helper'
import moment from 'moment'

export default {
  name:'ContentMedicalCheckup',
  data() {
    return {
      saving:false,

      medical_checkup: [
        {
          label_file: '',
          file: '',
          nama_document: '',
        },
      ],
    }
  },
  props: {
    getProfileCrew:{
      type: Function,
      default: () => {},
    }
  },
  computed: {
   
  },
  mounted() {},
  methods: {
    ...crewingActions,
    prefiewFiles: function (event, state) {
      const input = event.target

      if (input.files && input.files[0]) {
        state.label_file = input.files[0].name
        state.file = input.files[0]
      }
    },
    
    async onSaveMedichalCheckup(e) {
      e.preventDefault()
      this.saving = true

      let tempMedicalCheckup = []
      this.medical_checkup.map((item) => {
        tempMedicalCheckup.push({
          file: item.file,
          nama_document: item.nama_document,
        })
      })

      let formData = new FormData()

      tempMedicalCheckup.forEach((item, index) => {
        for (const property in item) {
          formData.append(
            `medical_checkup[${index}][${property}]`,
            item[property]
          )
        }
      })

      const { status, data } = await this.medicalCheckupCrew({
        data: formData,
        id: Number(this.$route.query.id),
      })

      if (status !== 'success') {
        this.saving = false
        this.$swal(`Oops!`, `${data}`, 'error')
        return
      }

      this.$swal(`Success!`, `${data}`, 'success')

      this.saving = false
      this.medical_checkup = [{
        label_file: '',
        file: '',
        nama_document: '',
      }]
      this.$nextTick(() => {
        this.$bvModal.hide('medical-checkup')
      })
      this.getProfileCrew()
    },

    // Medical Checkup Adding and Removing Forms
    addMedicalCheckupForm() {
      this.medical_checkup.push({
        label_file: '',
        file: '',
        nama_document: '',
      })
    },
    deleteMedicalCheckupForm(index) {
      this.medical_checkup.splice(index, 1)
    },
  },
}
</script>