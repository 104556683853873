var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": true,
            "href": "#certificate-list",
            "title": "MASTER CERTIFICATE LIST"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": false,
            "href": "#reason-sign-off",
            "title": "MASTER REASON SIGN OFF"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": false,
            "href": "#positions",
            "title": "MASTER POSITIONS"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "certificate-list"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MASTER CERTIFICATE LIST")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Master Certificate List:",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.certificate.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.certificate.params, "search", $$v);
            },
            expression: "certificate.params.search"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getCertificateList(1);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": !_vm.menuPermission('update') && !_vm.menuPermission('add') ? '12' : '8'
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Master Certificate List")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.certificate._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.certificate.data && _vm.certificate.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Name")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Alias")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Type")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("reminder")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Unlimited")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.certificate.data, function (p, i) {
          return _c('tr', {
            key: "p-".concat(i)
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(_vm.certificate.meta.currentPage > 1 ? _vm.certificate.meta.perPage + i + 1 : i + 1))]), _c('td', [_vm._v(_vm._s(p.name))]), _c('td', [_vm._v(_vm._s(p.alias ? p.alias : '-'))]), _c('td', [_vm._v(_vm._s(p.type ? p.type : '-'))]), _c('td', [_vm._v(_vm._s(p.is_reminder === 1 ? 'Active' : 'Inactive'))]), _c('td', [_vm._v(_vm._s(p.unlimited === 1 ? 'Yes' : 'No'))]), _c('td', [_vm.menuPermission('update') ? _c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onEditCertificate(p);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), _vm.menuPermission('delete') ? _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteCertificate(p);
              }
            }
          }, [!_vm.loading_delete_certificate ? _c('i', {
            staticClass: "fa fa-trash"
          }) : [_c('span', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status",
              "aria-hidden": "true"
            }
          }), _vm._v(" Deleting... ")]], 2) : _vm._e()], 1)]);
        }), 0)])]) : _vm._e(), _vm.certificate.data && _vm.certificate.data.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.certificate.meta.total,
            "per-page": _vm.certificate.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getCertificateList
          },
          model: {
            value: _vm.certificate.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.certificate.meta, "currentPage", $$v);
            },
            expression: "certificate.meta.currentPage"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No certificate data found.")])]];
      },
      proxy: true
    }])
  })], 1), _vm.menuPermission('update') || _vm.menuPermission('add') ? _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.form_certificate_edit_mode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.form_certificate_edit_mode ? 'Edit' : 'Add') + " Certificate")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.submitFormCertificate.apply(null, arguments);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Name Certificate",
            "label-for": "name_certificate"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "name_certificate",
            "type": "text",
            "placeholder": "Type text..."
          },
          model: {
            value: _vm.form.certificate.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "name", $$v);
            },
            expression: "form.certificate.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Alias Certificate",
            "label-for": "alias_certificate"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "alias_certificate",
            "type": "text",
            "placeholder": "Type text...",
            "maxlength": "30",
            "max": "20"
          },
          model: {
            value: _vm.form.certificate.alias,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "alias", $$v);
            },
            expression: "form.certificate.alias"
          }
        }), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v("Note: max alias character 20")])], 1), _c('b-form-group', {
          attrs: {
            "label": "Type Certificate",
            "label-for": "type_certificate"
          }
        }, [_c('b-form-select', {
          attrs: {
            "id": "type_certificate",
            "plain": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Type Certificate")]), _c('b-form-select-option', {
                attrs: {
                  "value": 'competency'
                }
              }, [_vm._v("Competency")]), _c('b-form-select-option', {
                attrs: {
                  "value": 'proficiency'
                }
              }, [_vm._v("Proficiency")]), _c('b-form-select-option', {
                attrs: {
                  "value": 'general'
                }
              }, [_vm._v("General")])];
            },
            proxy: true
          }], null, false, 3436333021),
          model: {
            value: _vm.form.certificate.type,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "type", $$v);
            },
            expression: "form.certificate.type"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Is Reminder",
            "label-for": "is_reminder"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "id": "is_reminder",
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.certificate.is_reminder,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "is_reminder", $$v);
            },
            expression: "form.certificate.is_reminder"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.certificate.is_reminder ? 'Active' : 'Inactive') + " ")])], 1), _c('b-form-group', {
          attrs: {
            "label": "Unlimited",
            "label-for": "unlimited"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "id": "unlimited",
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.certificate.unlimited,
            callback: function callback($$v) {
              _vm.$set(_vm.form.certificate, "unlimited", $$v);
            },
            expression: "form.certificate.unlimited"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.certificate.unlimited ? 'Yes' : 'No') + " ")])], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.loading_form_certificate ? _c('span', [_vm._v(_vm._s(_vm.form_certificate_edit_mode || !_vm.menuPermission('add') ? 'Save' : 'Add'))]) : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Saving... ")]], 2), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.resetFormCertificate.apply(null, arguments);
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 1115637116)
  })], 1) : _vm._e()], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "reason-sign-off"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MASTER REASON SIGN OFF")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Data Reason Sign Off:",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.reasons.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.reasons.params, "search", $$v);
            },
            expression: "reasons.params.search"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getReasonsSignOff(1);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": !_vm.menuPermission('update') && !_vm.menuPermission('add') ? '12' : '8'
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Master Data Reason Sign Off")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.reasons._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.reasons.data && _vm.reasons.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Name")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Alias")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("blacklist")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.reasons.data, function (r, i) {
          return _c('tr', {
            key: "r-".concat(i)
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(_vm.reasons.meta.currentPage > 1 ? _vm.reasons.meta.perPage + i + 1 : i + 1))]), _c('td', [_vm._v(_vm._s(r.name))]), _c('td', [_vm._v(_vm._s(r.alias))]), _c('td', [_vm._v(_vm._s(r.is_blacklist === 1 ? 'Yes' : 'No'))]), _c('td', [_vm._v(_vm._s(r.active === 1 ? 'Active' : 'Inactive'))]), _c('td', [_vm.menuPermission('update') ? _c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onEditReason(r);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), _vm.menuPermission('delete') ? _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteReason(r);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e()], 1)]);
        }), 0)])]) : _vm._e(), _vm.reasons.data && _vm.reasons.data.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.reasons.meta.total,
            "per-page": _vm.reasons.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getReasonsSignOff
          },
          model: {
            value: _vm.reasons.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.reasons.meta, "currentPage", $$v);
            },
            expression: "reasons.meta.currentPage"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No reasons data found.")])]];
      },
      proxy: true
    }])
  })], 1), _vm.menuPermission('update') || _vm.menuPermission('add') ? _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.form_reason_edit_mode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.form_reason_edit_mode ? 'Edit' : 'Add') + " Reason Sign Off")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.submitFormReason.apply(null, arguments);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Reason Name",
            "label-for": "reason_name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "reason_name",
            "type": "text",
            "placeholder": "Reason Name"
          },
          model: {
            value: _vm.form.reason.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.reason, "name", $$v);
            },
            expression: "form.reason.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Reason Alias",
            "label-for": "reason_alias"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "reason_alias",
            "type": "text",
            "placeholder": "Reason Alias",
            "max": "20"
          },
          model: {
            value: _vm.form.reason.alias,
            callback: function callback($$v) {
              _vm.$set(_vm.form.reason, "alias", $$v);
            },
            expression: "form.reason.alias"
          }
        }), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v("Note: max alias character 20")])], 1), _c('b-form-group', {
          attrs: {
            "label": "Blacklist",
            "label-for": "blacklist_reason"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "id": "blacklist_reason",
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.reason.is_blacklist,
            callback: function callback($$v) {
              _vm.$set(_vm.form.reason, "is_blacklist", $$v);
            },
            expression: "form.reason.is_blacklist"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.reason.is_blacklist ? 'Yes' : 'No') + " ")])], 1), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status_reason"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "id": "status_reason",
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.reason.active,
            callback: function callback($$v) {
              _vm.$set(_vm.form.reason, "active", $$v);
            },
            expression: "form.reason.active"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.reason.active ? 'Active' : 'Inactive') + " ")])], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.loading_form_reason ? _c('span', [_vm._v(_vm._s(_vm.form_reason_edit_mode || !_vm.menuPermission('add') ? 'Save' : 'Add'))]) : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Saving... ")]], 2), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "submit",
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.resetFormReason.apply(null, arguments);
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 3443543849)
  })], 1) : _vm._e()], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "positions"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MASTER POSITIONS")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Data Positions:",
            "label-for": "kind-positions"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.positions.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.positions.params, "search", $$v);
            },
            expression: "positions.params.search"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getPositions(1);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": !_vm.menuPermission('update') && !_vm.menuPermission('add') ? '12' : '8'
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Master Data Positions")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.reasons._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.positions.data && _vm.positions.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Name")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Alias")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Type Position")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Class Type")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.positions.data, function (r, i) {
          var _r$type_position;
          return _c('tr', {
            key: "r-".concat(i)
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(_vm.positions.meta.currentPage > 1 ? _vm.positions.meta.perPage + i + 1 : i + 1))]), _c('td', [_vm._v(_vm._s(r.name))]), _c('td', [_vm._v(_vm._s(r.alias))]), _c('td', [_vm._v(_vm._s(r === null || r === void 0 ? void 0 : (_r$type_position = r.type_position) === null || _r$type_position === void 0 ? void 0 : _r$type_position.name))]), _c('td', [_vm._v(_vm._s(r.class_type))]), _c('td', [_vm.menuPermission('update') ? _c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onEditPosition(r);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), _vm.menuPermission('delete') ? _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeletePosition(r);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e()], 1)]);
        }), 0)])]) : _vm._e(), _vm.positions.data && _vm.positions.data.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.positions.meta.total,
            "per-page": _vm.positions.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getPositions
          },
          model: {
            value: _vm.positions.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.positions.meta, "currentPage", $$v);
            },
            expression: "positions.meta.currentPage"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No master data positions found.")])]];
      },
      proxy: true
    }])
  })], 1), _vm.menuPermission('update') || _vm.menuPermission('add') ? _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.form_position_edit_mode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.form_position_edit_mode ? 'Edit' : 'Add') + " Position")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.submitFormPosition.apply(null, arguments);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Position Name",
            "label-for": "position_name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "position_name",
            "type": "text",
            "placeholder": "Position Name"
          },
          model: {
            value: _vm.form.position.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.position, "name", $$v);
            },
            expression: "form.position.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Position Alias",
            "label-for": "position_alias"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "position_alias",
            "type": "text",
            "placeholder": "Position Alias...",
            "max": "20"
          },
          model: {
            value: _vm.form.position.alias,
            callback: function callback($$v) {
              _vm.$set(_vm.form.position, "alias", $$v);
            },
            expression: "form.position.alias"
          }
        }), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v("Note: max alias character 20")])], 1), _c('b-form-group', {
          attrs: {
            "label": "Type Position",
            "label-for": "type_position_id"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "placeholder": "Select Type Position...",
            "options": _vm.list_type_position,
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Type Position")])];
            },
            proxy: true
          }], null, false, 1175296023),
          model: {
            value: _vm.form.position.type_position_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form.position, "type_position_id", $$v);
            },
            expression: "form.position.type_position_id"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Position Class Type",
            "label-for": "class_type"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "placeholder": "Position Class Type...",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": ''
                }
              }, [_vm._v("Select Class Position")]), _c('b-form-select-option', {
                attrs: {
                  "value": "perwira"
                }
              }, [_vm._v("Perwira")]), _c('b-form-select-option', {
                attrs: {
                  "value": "non_perwira"
                }
              }, [_vm._v("Non Perwira")]), _c('b-form-select-option', {
                attrs: {
                  "value": "none"
                }
              }, [_vm._v("None")])];
            },
            proxy: true
          }], null, false, 4174328067),
          model: {
            value: _vm.form.position.class_type,
            callback: function callback($$v) {
              _vm.$set(_vm.form.position, "class_type", $$v);
            },
            expression: "form.position.class_type"
          }
        })], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.loading_form_position ? _c('span', [_vm._v(_vm._s(_vm.form_position_edit_mode || !_vm.menuPermission('add') ? 'Save' : 'Add'))]) : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Saving... ")]], 2), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "submit",
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.resetFormPosition.apply(null, arguments);
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 967822074)
  })], 1) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }