var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("SERVICE AND REPAIR MASTER DATA")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": true,
            "href": "#task-type",
            "title": "TASK TYPE"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": false,
            "href": "#category",
            "title": "CATEGORY"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": false,
            "href": "#classification",
            "title": "CLASSIFICATION"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": false,
            "href": "#cause",
            "title": "CAUSE"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": false,
            "href": "#kind-task",
            "title": "KIND TASK"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": false,
            "href": "#activity",
            "title": "ACTIVITY"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": false,
            "href": "#reject-reason",
            "title": "REJECT"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "task-type"
    }
  }, [_c('SrMasterTaskType')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "category"
    }
  }, [_c('SrMasterCategory')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "classification"
    }
  }, [_c('SrMasterClassification')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "cause"
    }
  }, [_c('SrMasterCause')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "kind-task"
    }
  }, [_c('SrMasterKindTask')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "activity"
    }
  }, [_c('SrMasterActivity')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "reject-reason"
    }
  }, [_c('SrMasterRejectReason')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }