var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("FILTER DATA SUMMARY OF DAILY FUEL, WATER, & CARGOS REMAINING ONBOARD")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "secondary"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v("   Export ")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "p-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Date Range Filter",
            "label-for": "from"
          }
        }, [_c('date-picker', {
          attrs: {
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          on: {
            "change": function change($event) {
              return _vm.onChangeDate();
            }
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Category:",
            "label-for": "kind-fleet"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "options": _vm.vehicleTypes,
            "placeholder": "Choose category fleet",
            "label": "text",
            "track-by": "value"
          },
          model: {
            value: _vm.form.selectedCategory,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "selectedCategory", $$v);
            },
            expression: "form.selectedCategory"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet/Equipment:",
            "label-for": "fleet-equipment"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Search or add fleets",
            "label": "name",
            "track-by": "code",
            "options": _vm.fleetsByCategory,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.selectedFleetEquipment,
            callback: function callback($$v) {
              _vm.selectedFleetEquipment = $$v;
            },
            expression: "selectedFleetEquipment"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getRobList();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1), _c('b-col', {
          staticClass: "text-right pull-right",
          attrs: {
            "cols": "2"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.form-rh",
            modifiers: {
              "form-rh": true
            }
          }],
          staticClass: "mt-4",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.resetForm();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus-circle"
        }), _vm._v("   Add Summary ROB")]), _c('b-modal', {
          attrs: {
            "id": "form-rh",
            "size": "lg",
            "title": "".concat(_vm.selectedDetailVehicleId ? 'Edit' : 'Tambah', " SUMMARY OF DAILY FUEL, WATER, & CARGOS REMAINING ONBOARD"),
            "ok-title": "Save",
            "cancel-title": "Cancel",
            "centered": ""
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref) {
              var cancel = _ref.cancel;
              return [_c('b-row', [_c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "iq-bg-danger text-right",
                attrs: {
                  "variant": "none"
                },
                on: {
                  "click": function click($event) {
                    return cancel();
                  }
                }
              }, [_vm._v("Cancel")]), _c('b-button', {
                staticClass: "ml-2 text-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                },
                on: {
                  "click": function click($event) {
                    return _vm.save();
                  }
                }
              }, [_vm._v("Save")])], 1)], 1)];
            }
          }])
        }, [_c('b-row', {
          staticClass: "mb-5"
        }, [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Date"
          }
        }, [_c('date-picker', {
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "disabled": _vm.selectedDetailVehicleId,
            "value-type": "DD-MM-YYYY",
            "format": "DD MMM YYYY",
            "type": "date",
            "placeholder": "Select date"
          },
          model: {
            value: _vm.form.date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "date", $$v);
            },
            expression: "form.date"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Choose your Fleet/Equipment"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "disabled": _vm.selectedDetailVehicleId,
            "options": _vm.fleets,
            "placeholder": "Choose your fleet",
            "label": "name",
            "track-by": "code"
          },
          on: {
            "input": _vm.getLatestRobList
          },
          model: {
            value: _vm.form.selectedVehicle,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "selectedVehicle", $$v);
            },
            expression: "form.selectedVehicle"
          }
        })], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Type")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Opening (ROB From Previous Day)")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Consumption (Based on Actual Sounding)")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Received")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Transferred")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Closing")])])]), _c('tbody', [_vm.form.selectedVehicle === null ? _c('tr', [_c('td', {
          attrs: {
            "colspan": "6"
          }
        }, [_vm._v("Silahkan pilih fleet")])]) : _vm._e(), _vm._l(_vm.form.robs, function (rob, index) {
          return _c('tr', [_c('td', [_vm._v(_vm._s(rob.robTypeName))]), _c('td', [_c('p', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "placeholder": "consumption",
              "value": "20000"
            },
            on: {
              "change": function change($event) {
                return _vm.calculateActualRob(index);
              }
            },
            model: {
              value: rob.opening,
              callback: function callback($$v) {
                _vm.$set(rob, "opening", $$v);
              },
              expression: "rob.opening"
            }
          })], 1)]), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "placeholder": "consumption",
              "value": "10000"
            },
            on: {
              "change": function change($event) {
                return _vm.calculateActualRob(index);
              }
            },
            model: {
              value: rob.consumption,
              callback: function callback($$v) {
                _vm.$set(rob, "consumption", $$v);
              },
              expression: "rob.consumption"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "placeholder": "received",
              "value": "5000"
            },
            on: {
              "change": function change($event) {
                return _vm.calculateActualRob(index);
              }
            },
            model: {
              value: rob.received,
              callback: function callback($$v) {
                _vm.$set(rob, "received", $$v);
              },
              expression: "rob.received"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "placeholder": "transferred"
            },
            on: {
              "change": function change($event) {
                return _vm.calculateActualRob(index);
              }
            },
            model: {
              value: rob.transferred,
              callback: function callback($$v) {
                _vm.$set(rob, "transferred", $$v);
              },
              expression: "rob.transferred"
            }
          })], 1), _c('td', [_c('strong', [_vm._v(_vm._s(rob.actualRob) + " Ltrs")])])]);
        })], 2)])]), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "cols": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Remarks",
            "label-for": "Remarks"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "remarks",
            "rows": "2"
          },
          model: {
            value: _vm.form.remark,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "remark", $$v);
            },
            expression: "form.remark"
          }
        })], 1)], 1)], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Vehicle")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "p-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_vm._l(_vm.rob, function (r, index) {
          return [_c('b-card', {
            key: "mat-".concat(index),
            staticClass: "mb-1",
            attrs: {
              "no-body": ""
            }
          }, [_c('b-card-header', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: 'matrix' + r.vehicle[Object.keys(r.vehicle)[0]].id,
              expression: "'matrix' + r.vehicle[Object.keys(r.vehicle)[0]].id"
            }],
            staticClass: "p-1",
            attrs: {
              "header-tag": "header",
              "role": "tab"
            }
          }, [_c('h4', {
            staticClass: "p-3",
            staticStyle: {
              "cursor": "pointer",
              "font-weight": "bold"
            }
          }, [_vm._v(_vm._s(r.vehicle[Object.keys(r.vehicle)[0]].name) + " ")])]), _c('b-collapse', {
            attrs: {
              "id": 'matrix' + r.vehicle[Object.keys(r.vehicle)[0]].id,
              "role": "tabpanel"
            }
          }, [_c('b-card-body', [_c('table', {
            staticClass: "table table-sm table-striped"
          }, [_c('thead', [_c('tr', [_c('th', {
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("Date")]), _c('th', {
            attrs: {
              "width": "45%"
            }
          }, [_vm._v("Detail Summary ROB")]), _c('th', {
            attrs: {
              "width": "15%"
            }
          }, [_vm._v("Remarks")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "5%"
            }
          }, [_vm._v("#")])])]), _c('tbody', _vm._l(r.robHistory, function (robHistory, indexRobHistory) {
            return _c('tr', [_c('td', [_vm._v(_vm._s(robHistory.date))]), _c('td', [_c('table', {
              staticClass: "table-dark"
            }, [_c('thead', [_c('tr', [_c('th', {
              attrs: {
                "width": "20%"
              }
            }, [_vm._v("Type")]), _c('th', {
              attrs: {
                "width": "20%"
              }
            }, [_vm._v("Previous")]), _c('th', {
              attrs: {
                "width": "15%"
              }
            }, [_vm._v("Cons")]), _c('th', {
              attrs: {
                "width": "15%"
              }
            }, [_vm._v("Received")]), _c('th', {
              attrs: {
                "width": "15%"
              }
            }, [_vm._v("Transferred")]), _c('th', {
              attrs: {
                "width": "15%"
              }
            }, [_vm._v("Closing")])])]), _c('tbody', _vm._l(robHistory.robs, function (detail) {
              return _c('tr', [_c('td', [_vm._v(_vm._s(detail.robTypeName))]), _c('td', [_c('small', [_vm._v(_vm._s(detail.logDate))]), _c('p', [_vm._v(_vm._s(detail.actualRob) + " Ltrs")])]), _c('td', [_vm._v(_vm._s(detail.consumption))]), _c('td', [_vm._v(_vm._s(detail.received))]), _c('td', [_vm._v(_vm._s(detail.transferred))]), _c('td', [_c('strong', [_vm._v(_vm._s(detail.opening) + " Ltrs")])])]);
            }), 0)])]), _c('td', [_vm._v(_vm._s(robHistory.remark))]), _c('td', {
              staticClass: "text-center"
            }, [indexRobHistory === 0 ? _c('a', {
              on: {
                "click": function click($event) {
                  _vm.deleteData(r.vehicle[Object.keys(r.vehicle)[0]].id, robHistory.date);
                }
              }
            }, [_c('span', {
              staticClass: "text-danger"
            }, [_c('i', {
              staticClass: "fa fa-trash"
            })])]) : _vm._e()])]);
          }), 0)])])], 1)], 1)];
        })], 2)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }