var render = function render(){
  var _vm$detailForm, _vm$detailForm7;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    attrs: {
      "action": "#"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    }
  }, [_vm.edit_loading ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "text-center my-5"
  }, [_c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Spinning"
    }
  })], 1)])], 1) : _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Vehicle",
      "label-for": "vehicle_id"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "options": _vm.listVehicle,
      "placeholder": "Select vehicle",
      "track-by": "name",
      "label": "name"
    },
    on: {
      "input": _vm.onSelectedVehicle
    },
    model: {
      value: _vm.form.vehicle_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "vehicle_id", $$v);
      },
      expression: "form.vehicle_id"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1), _vm.form.vehicle_id ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Collection Forms",
      "label-for": "collection_forms"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "options": _vm.listCollectionForm,
      "placeholder": "Select forms",
      "track-by": "name",
      "label": "name",
      "disabled": _vm.loading_fleet_form
    },
    on: {
      "input": _vm.onSelectedForm
    },
    model: {
      value: _vm.form.collection_of_forms_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "collection_of_forms_id", $$v);
      },
      expression: "form.collection_of_forms_id"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1) : _vm._e(), _vm.detailForm ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', {
    staticClass: "mt-2"
  }, [_c('div', {
    staticClass: "border my-2 mx-3 w-100"
  }), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Title"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Type Text",
      "required": ""
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1)], 1), _vm._l((_vm$detailForm = _vm.detailForm) === null || _vm$detailForm === void 0 ? void 0 : _vm$detailForm.field, function (state, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": state === null || state === void 0 ? void 0 : state.label
      }
    }, [state.type === 'Numeric Type' ? _c('b-form-input', {
      attrs: {
        "type": "number",
        "placeholder": "Type Number",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Text Type' ? _c('b-form-input', {
      attrs: {
        "placeholder": "Type Text",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Textarea' ? _c('b-form-textarea', {
      attrs: {
        "placeholder": "Type Text",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Date' ? _c('b-form-input', {
      attrs: {
        "type": "date",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Datetime' ? _c('b-form-input', {
      attrs: {
        "type": "datetime-local",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Boolean Type' ? _c('b-form-checkbox', {
      attrs: {
        "name": "check-button",
        "switch": ""
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }, [_vm._v("Yes / No ")]) : _vm._e()], 1)], 1);
  })], 2)], 1), _c('div', {
    staticClass: "border my-2 mx-3 w-100"
  }), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Question Type Content")]), _vm.detailForm.collection_of_forms_category.length > 0 ? _c('div', _vm._l(_vm.detailForm.collection_of_forms_category, function (parentQuestion, parentQuestionIndex) {
    var _parentQuestion$inspe, _vm$detailForm2, _vm$detailForm3, _vm$detailForm4, _vm$detailForm4$quest, _parentQuestion$inspe2;
    return _c('table', {
      key: parentQuestionIndex,
      staticClass: "table table-striped"
    }, [_c('thead', [!parentQuestion.parent_id && (parentQuestion === null || parentQuestion === void 0 ? void 0 : (_parentQuestion$inspe = parentQuestion.inspection_checklist_question) === null || _parentQuestion$inspe === void 0 ? void 0 : _parentQuestion$inspe.length) <= 0 ? _c('tr', [_c('th', {
      staticClass: "align-middle text-center",
      attrs: {
        "colspan": "6"
      }
    }, [_vm._v(_vm._s(parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.name))])]) : _c('tr', [_c('th', {
      staticStyle: {
        "width": "5%"
      }
    }, [_vm._v("No")]), _c('th', {
      staticStyle: {
        "width": "45%"
      }
    }, [_vm._v(_vm._s(parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.name))]), Array.isArray((_vm$detailForm2 = _vm.detailForm) === null || _vm$detailForm2 === void 0 ? void 0 : _vm$detailForm2.question_type_content) ? _vm._l((_vm$detailForm3 = _vm.detailForm) === null || _vm$detailForm3 === void 0 ? void 0 : _vm$detailForm3.question_type_content, function (questionType, questionTypeIndex) {
      return _c('th', {
        key: questionTypeIndex,
        staticClass: "text-truncate",
        staticStyle: {
          "min-width": "20%"
        }
      }, [_vm._v(" " + _vm._s(questionType === null || questionType === void 0 ? void 0 : questionType.label) + " ")]);
    }) : [['date', 'single-lines-text-box', 'datetime', 'numerical-value'].includes(_vm.detailForm.question_type.code) ? _c('th', {
      staticStyle: {
        "width": "50%"
      }
    }, [_vm._v(_vm._s((_vm$detailForm4 = _vm.detailForm) === null || _vm$detailForm4 === void 0 ? void 0 : (_vm$detailForm4$quest = _vm$detailForm4.question_type_content) === null || _vm$detailForm4$quest === void 0 ? void 0 : _vm$detailForm4$quest.label))]) : _vm._e()], _vm.detailForm.using_remarks ? _c('th', {
      staticStyle: {
        "width": "20%"
      }
    }, [_vm._v("Remarks")]) : _vm._e()], 2)]), (parentQuestion === null || parentQuestion === void 0 ? void 0 : (_parentQuestion$inspe2 = parentQuestion.inspection_checklist_question) === null || _parentQuestion$inspe2 === void 0 ? void 0 : _parentQuestion$inspe2.length) > 0 ? _c('tbody', _vm._l(parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.inspection_checklist_question, function (question, questionIndex) {
      var _vm$detailForm5, _vm$detailForm6;
      return _c('tr', {
        key: questionIndex
      }, [_c('td', {
        staticStyle: {
          "max-width": "20px"
        }
      }, [_vm._v(_vm._s(questionIndex + 1))]), _c('td', [_vm._v(_vm._s(question.question))]), _vm.detailForm.question_type.code == 'multiple-choice-multiple-answers-allowed' ? _vm._l((_vm$detailForm5 = _vm.detailForm) === null || _vm$detailForm5 === void 0 ? void 0 : _vm$detailForm5.question_type_content, function (questionType, questionTypeIndex) {
        return _c('td', {
          key: questionTypeIndex
        }, [_c('b-form-checkbox', {
          attrs: {
            "value": questionType === null || questionType === void 0 ? void 0 : questionType.label,
            "unchecked-value": ''
          },
          model: {
            value: question.answer[questionTypeIndex].value,
            callback: function callback($$v) {
              _vm.$set(question.answer[questionTypeIndex], "value", $$v);
            },
            expression: "question.answer[questionTypeIndex].value"
          }
        })], 1);
      }) : _vm._e(), _vm.detailForm.question_type.code == 'multiple-choice-only-one-answer' ? _vm._l(_vm.detailForm.collection_of_forms_category[parentQuestionIndex].inspection_checklist_question[questionIndex].answer, function (questionType, questionTypeIndex) {
        return _c('td', {
          key: questionTypeIndex
        }, [[_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          on: {
            "change": function change($event) {
              return _vm.onChangeAnswer(parentQuestionIndex, questionIndex, questionTypeIndex);
            }
          },
          model: {
            value: questionType.value,
            callback: function callback($$v) {
              _vm.$set(questionType, "value", $$v);
            },
            expression: "questionType.value"
          }
        })]], 2);
      }) : _vm._e(), ['single-lines-text-box'].includes(_vm.detailForm.question_type.code) ? [_c('td', [_c('b-form-input', {
        attrs: {
          "type": "text"
        },
        model: {
          value: question.answer,
          callback: function callback($$v) {
            _vm.$set(question, "answer", $$v);
          },
          expression: "question.answer"
        }
      })], 1)] : _vm._e(), ['multiple-lines-text-box'].includes(_vm.detailForm.question_type.code) ? _vm._l((_vm$detailForm6 = _vm.detailForm) === null || _vm$detailForm6 === void 0 ? void 0 : _vm$detailForm6.question_type_content, function (questionType, questionTypeIndex) {
        return _c('td', {
          key: questionTypeIndex
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Type Text"
          },
          model: {
            value: question.answer[questionTypeIndex].value,
            callback: function callback($$v) {
              _vm.$set(question.answer[questionTypeIndex], "value", $$v);
            },
            expression: "question.answer[questionTypeIndex].value"
          }
        })], 1);
      }) : _vm._e(), ['numerical-value'].includes(_vm.detailForm.question_type.code) ? [_c('td', [_c('b-form-input', {
        attrs: {
          "type": "number"
        },
        model: {
          value: question.answer,
          callback: function callback($$v) {
            _vm.$set(question, "answer", $$v);
          },
          expression: "question.answer"
        }
      })], 1)] : _vm._e(), ['date'].includes(_vm.detailForm.question_type.code) ? [_c('td', [_c('b-form-input', {
        attrs: {
          "type": "date"
        },
        model: {
          value: question.answer,
          callback: function callback($$v) {
            _vm.$set(question, "answer", $$v);
          },
          expression: "question.answer"
        }
      })], 1)] : _vm._e(), ['datetime'].includes(_vm.detailForm.question_type.code) ? [_c('td', [_c('b-form-input', {
        attrs: {
          "type": "datetime-local"
        },
        model: {
          value: question.answer,
          callback: function callback($$v) {
            _vm.$set(question, "answer", $$v);
          },
          expression: "question.answer"
        }
      })], 1)] : _vm._e(), _vm.detailForm.using_remarks ? _c('td', [_c('b-form-textarea', {
        attrs: {
          "placeholder": "Type Text"
        },
        model: {
          value: question.remarks,
          callback: function callback($$v) {
            _vm.$set(question, "remarks", $$v);
          },
          expression: "question.remarks"
        }
      })], 1) : _vm._e()], 2);
    }), 0) : _vm._e()]);
  }), 0) : _vm._e()])]), _c('div', {
    staticClass: "border my-2 mx-3 w-100"
  }), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', _vm._l((_vm$detailForm7 = _vm.detailForm) === null || _vm$detailForm7 === void 0 ? void 0 : _vm$detailForm7.signature, function (state, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "3"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": state === null || state === void 0 ? void 0 : state.label
      }
    }, [state.type === 'Numeric Type' ? _c('b-form-input', {
      attrs: {
        "type": "number",
        "placeholder": "Type Number",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Text Type' ? _c('b-form-input', {
      attrs: {
        "placeholder": "Type Text",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Textarea' ? _c('b-form-textarea', {
      attrs: {
        "placeholder": "Type Text",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Date' ? _c('b-form-input', {
      attrs: {
        "type": "date",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Datetime' ? _c('b-form-input', {
      attrs: {
        "type": "datelocal-time",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Boolean Type' ? _c('b-form-checkbox', {
      attrs: {
        "name": "check-button",
        "switch": ""
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }, [_vm._v("Yes / No ")]) : _vm._e()], 1)], 1);
  }), 1)], 1)], 1)], 1) : _vm._e(), _vm.form.vehicle_id && _vm.form.collection_of_forms_id ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    staticClass: "mr-4 mt-2",
    staticStyle: {
      "width": "15%"
    },
    attrs: {
      "block": "",
      "variant": "danger",
      "type": "button"
    }
  }, [_c('i', {
    staticClass: "fa fa-remove"
  }), _c('span', [_vm._v("Cancel")])]), _c('b-button', {
    staticStyle: {
      "width": "20%"
    },
    attrs: {
      "disabled": _vm.saving,
      "block": "",
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('div', [_c('i', {
    staticClass: "fa fa-save"
  }), _c('span', [_vm._v("Submit Data")])])])], 1)]) : _vm._e()], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }