<template>
  <div>
    <div class="no-print pull-right">
      <b-button-group class="mr-2">
        <button v-if="preview" class="btn btn-sm btn-dark" @click.prevent="print"><i class="fa fa-print"></i> Print</button>
        <button class="btn btn-sm btn-success" @click.prevent="edit" v-if="preview"><i class="fa fa-edit"></i> Edit</button>
        <button class="btn btn-sm btn-primary" @click.prevent="saveSCL()" v-else><i class="fa fa-save"></i> Save</button>
      </b-button-group>
    </div>

    <div class="row">
      <div class="col-md-12 text-left">
        <img src="http://ops.transenergilogistik.com/assets/img/logo-black.png" class="admin_img" alt="logo">
      </div>
    </div>
    <div class="row" style="margin-top:-20px">
      <div class="col-lg-12 text-center">
        <h4 class="mt-5" style="text-decoration: underline;">STEAMING TIME, SOUNDING CHECK LIST, & SUMMARY</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <table border="0" width="100%">
          <tbody>
          <tr>
            <td>Tug & Barge Name</td>
            <td v-if="preview">: {{formScl.tugBargeName}}</td>
            <td v-else>
              <b-form-input class="mt-1" style="width: 80%;" type="text" v-model="formScl.tugBargeName"></b-form-input>
            </td>
            <td>Voyage ID</td>
            <td v-if="preview">: {{formScl.voyageId}}</td>
            <td v-else>
              <b-form-input class="mt-1" style="width: 80%;float: right;" type="number" v-model="formScl.voyageId"></b-form-input>
            </td>
          </tr>
          <tr>
            <td>Port of Loading</td>
            <td v-if="preview">: {{formScl.pol}}</td>
            <td v-else>
              <b-form-input class="mt-1" style="width: 80%;" type="text" v-model="formScl.pol"></b-form-input>
            </td>
            <td>Location Check 1</td>
            <td v-if="preview">: {{formScl.locationCheck1}}</td>
            <td v-else>
              <b-form-input class="mt-1" style="width: 80%;float: right;" type="text"  v-model="formScl.locationCheck1"></b-form-input>
            </td>
          </tr>
          <tr>
            <td>Port of Discharge</td>
            <td v-if="preview">: {{formScl.pod}}</td>
            <td v-else>
              <b-form-input class="mt-1" style="width: 80%;" type="text" v-model="formScl.pod"></b-form-input>
            </td>
            <td>Date & Time Check 1</td>
            <td v-if="preview">: {{formScl.dateTimeCheck1}}</td>
            <td v-else>
              <date-picker class="mt-1" style="width: 80%;float: right;" v-model="formScl.dateTimeCheck1" type="datetime" value-type="DD-MM-YYYY HH:mm" placeholder="Select date and time"></date-picker>
              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
            </td>
          </tr>
          <tr>
            <td>Cargo Type</td>
            <td v-if="preview">: {{formScl.cargoType}}</td>
            <td v-else>
              <b-form-input class="mt-1" style="width: 80%;" type="text" v-model="formScl.cargoType"></b-form-input>
            </td>
            <td>Location Check 2</td>
            <td v-if="preview">: {{formScl.locationCheck2}}</td>
            <td v-else>
              <b-form-input class="mt-1" style="width: 80%;float: right;" type="text" v-model="formScl.locationCheck2"></b-form-input>
            </td>
          </tr>
          <tr>
            <td>Quantity</td>
            <td v-if="preview">: {{formScl.quantity}} MT</td>
            <td v-else>
              <b-input-group size="md" append="Ton" class="mt-1" style="width: 80%;">
                <b-form-input style="width: 80%;" type="number" step=".01" v-model="formScl.quantity"></b-form-input>
              </b-input-group>
            </td>
            <td>Date & Time Check 2</td>
            <td v-if="preview">: {{formScl.dateTimeCheck2}}</td>
            <td v-else>
              <date-picker v-model="formScl.dateTimeCheck2" style="width: 80%;float: right;" type="datetime" value-type="DD-MM-YYYY HH:mm" placeholder="Select date and time"></date-picker>
              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div>&nbsp;</div>
    <div class="row mt-4 mb-2">
      <div class="col-lg-12 text-center">
        <h6 style="text-decoration: underline;">STEAMING TIME</h6>
      </div>
    </div>

    <table width="100%" border="1">
      <thead>
      <tr style="background-color: #bae1ff;">
        <th style="text-align: center;" width="25%">Point to Point</th>
        <th style="text-align: center;" colspan="2" width="50%">Time & Date</th>
        <th style="text-align: center;" width="15%">Steaming Time</th>
         <th style="text-align: center;" width="17%">Remarks</th>
        <th style="text-align: center;" v-if="!preview">#</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(stream, index) in formScl.summarySteaming.detail" :key="index">
        <td style="text-align: center;"><div v-if="preview"> {{ stream.ptp }}</div>
          <div v-else class="text-center">
            <b-form-input type="text" v-model="stream.ptp" size="sm" style="width: 80%; margin:auto"></b-form-input>
          </div>
        </td>
        <td style="text-align: center;"><div v-if="preview"> {{ stream.startDate }}</div>
          <div v-else style="width: 80%; margin:auto" class="p-2">
            <date-picker v-model="stream.startDate" type="datetime" value-type="DD-MM-YYYY HH:mm" placeholder="Select date and time"></date-picker>
            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
          </div>
        </td>
        <td style="text-align: center;"><div v-if="preview"> {{ stream.endDate }}</div>
          <div v-else style="width: 80%; margin:auto" class="p-2">
            <date-picker v-model="stream.endDate" type="datetime" value-type="DD-MM-YYYY HH:mm" placeholder="Select date and time"></date-picker>
            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
          </div>
        </td>
        <td style="text-align: center;">
          <!-- <div v-if="preview"> {{ stream.steamingTime }}</div> -->
          <div> {{ stream.steamingTime = steamingTimeCalculate(stream.startDate, stream.endDate)}}</div>
          <!-- <div v-else class="text-center">
            <b-form-input type="number" :disabled="true" v-model="stream.steamingTime" size="sm" style="width: 80%; margin:auto"></b-form-input>
          </div> -->
        </td>
        <td style="text-align: center;"><div v-if="preview"> {{stream.remarks}}</div>
          <div v-else class="text-center">
            <b-form-input type="text" v-model="stream.remarks" size="sm"></b-form-input>
          </div>
        </td>
        <td v-if="!preview && formScl.summarySteaming.detail.length > 1" class="text-center">
          <b-button @click.prevent="removeSteamingTime(index)" size="sm" variant="danger" class="mb-3"><i class="ri-delete-bin-line"></i></b-button>
        </td>
      </tr>
      <tr v-if="!preview">
        <td colspan="6">
          <b-button block size="sm" variant="warning" class="mb-3 mr-1" @click.prevent="addSteamingTime()"><i class="ri-add-line"></i> Add Steaming Time</b-button>
        </td>
      </tr>
      <tr>
        <td style="text-align: right;" colspan="3">Total Steaming Time</td>
        <!-- <td style="text-align: center;">{{ formScl.summarySteaming.totalSteaming }}</td> -->
        <td style="text-align: center;">{{preview ? formScl.summarySteaming.totalSteaming : formScl.summarySteaming.totalSteaming = steamingTotalTimeCalculate() }}</td>
        <td style="text-align: center;">&nbsp;</td>
      </tr>
      </tbody>
    </table>
    <br/>

    <div class="row">
      <div class="col-lg-12 text-center mb-2">
        <h6 style="text-decoration: underline;">AUXILIARY ENGINE CALCULATION</h6>
      </div>
    </div>

    <table width="100%" border="1">
      <thead>
      <tr style="background-color: #bae1ff;">
        <th style="text-align: center;">Description</th>
        <th style="text-align: center;">Start</th>
        <th style="text-align: center;">End</th>
        <th style="text-align: center;">Total</th>
        <th style="text-align: center;">Ltrs</th>
        <th style="text-align: center;">Total AE</th>
        <th style="text-align: center;">Quotation</th>
        <th style="text-align: center;">Deduction AE</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(aed,index) in formScl.summaryAec.detail" :key="index">
        <td style="text-align: center;"><div v-if="preview">{{ (aed.description) }}</div>
          <div v-else class="text-center p-2">
            <b-form-input type="text" v-model="aed.description" size="sm" style="width: 90%; margin:auto"></b-form-input>
          </div>
        </td>
        <td style="text-align: center;" class="p-2"><div v-if="preview"> {{ (aed.startDate) }}</div>
          <div v-else>
            <date-picker v-model="aed.startDate" type="datetime" value-type="DD-MM-YYYY HH:mm" placeholder="Select date and time"></date-picker>
          </div>
        </td>
        <td style="text-align: center;" class="p-2"><div v-if="preview"> {{ (aed.endDate) }}</div>
          <div v-else>
            <date-picker v-model="aed.endDate" type="datetime" value-type="DD-MM-YYYY HH:mm" placeholder="Select date and time"></date-picker>
          </div>
        </td>
        <td style="text-align: center;">
          <div> {{ preview ? aed.idle : aed.idle = steamingTimeCalculate(aed.startDate, aed.endDate) }}</div>
        </td>
        <td style="text-align: center;"><div v-if="preview"> {{ aed.ltrs * 100 / 100 }}</div>
          <div v-else class="text-center">
            <b-form-input type="number" v-model="aed.ltrs" size="sm"  style="width: 90%; margin:auto"></b-form-input>
          </div>
        </td>
        <td style="text-align: center;">
            {{preview ? aed.ae : aed.ae =  Math.ceil(steamingTimeCalculate(aed.startDate, aed.endDate) * aed.ltrs)}}
        </td>
        <template v-if="index === 0">
          <td style="text-align: center;" :rowspan="formScl.summaryAec.detail.length">
            <div v-if="preview"> {{ formScl.summaryAec.quotation }} </div>
            <div v-else class="text-center">
              <b-form-input type="number" v-model="formScl.summaryAec.quotation" size="sm"  style="width: 90%; margin:auto"></b-form-input>
            </div>
          </td>
          <td style="text-align: center;" :rowspan="formScl.summaryAec.detail.length">
            <div > {{ formScl.summaryAec.deductionAe =  (totalAE() - formScl.summaryAec.quotation)}} </div>
            <!-- <div v-else class="text-center">
              <b-form-input type="number" v-model="formScl.summaryAec.deductionAe" size="sm" style="width: 90%; margin:auto"></b-form-input>
            </div> -->
          </td>
        </template>
      </tr>
      <tr>
        <td style="text-align: right;" colspan="3">Total Auxiliary Engine Idle</td>
        <td style="text-align: center;">{{formScl.summaryAec.totalIdle = aecTotalTimeCalculate()}}</td>
<!--        <td style="text-align: center;">{{totalLtrsAE()}}</td>-->
        <td style="text-align: center;">-</td>
        <td style="text-align: center;">{{formScl.summaryAec.totalAe = totalAE() }}</td>
        <td></td>
        <td></td>
      </tr>
      </tbody>
    </table>

    <br/>
    <div class="row mb-2">
      <div class="col-lg-12 text-center">
        <h6 style="text-decoration: underline;">SUMMARY</h6>
      </div>
    </div>

    <table width="100%" border="1">
      <thead>
      <tr style="background-color: #bae1ff;">
        <td style="text-align: center;" rowspan="2">Point To Point</td>
        <td style="text-align: center;" rowspan="2">Distance</td>
        <td style="text-align: center;" colspan="3">Speed</td>
        <td style="text-align: center;" colspan="3">Steaming Time</td>
        <td style="text-align: center;" colspan="3">Fuel Oil Consumption</td>
      </tr>
      <tr style="background-color: #bae1ff;">
        <td style="text-align: center;">KPI</td>
        <td style="text-align: center;">Vessel</td>
        <td style="text-align: center;">Diff</td>
        <td style="text-align: center;">KPI</td>
        <td style="text-align: center;">Vessel</td>
        <td style="text-align: center;">Diff</td>
        <td style="text-align: center;">Ltrs</td>
        <td style="text-align: center;">KPI</td>
        <td style="text-align: center;">Vessel</td>

      </tr>
      </thead>
      <tbody>
      <tr v-for="(summary,index) in formScl.summary.detail" :key="index">
        <td style="text-align: center;"><div v-if="preview"> {{ (summary.ptp) }}</div>
          <div v-else class="text-center">
            <b-form-input type="text" v-model="formScl.summarySteaming.detail[index].ptp" size="sm" style="width: 90%; margin:auto"></b-form-input>
            <!-- <b-form-input type="number" v-model="summary.ptp" disabled size="sm"  style="width: 90%; margin:auto"></b-form-input> -->
          </div>
        </td>
        <td style="text-align: center;"><div v-if="preview"> {{ summary.distance }}</div>
          <div v-else class="text-center">
            <b-form-input type="number" v-model="summary.distance" size="sm"  style="width: 90%; margin:auto"></b-form-input>
          </div>
        </td>
        <td style="text-align: center;"><div v-if="preview"> {{ summary.speed.kpi }}</div>
          <div v-else class="text-center">
            <b-form-input type="number" v-model="summary.speed.kpi" size="sm" style="width: 90%; margin:auto"></b-form-input>
          </div>
        </td>
        <td style="text-align: center;">
          <div>{{ summary.speed.vessel = summary.distance && formScl.summarySteaming.detail[index].steamingTime ?  (summary.distance/formScl.summarySteaming.detail[index].steamingTime).toFixed(2) : null}}</div>
          <!-- <div v-else class="text-center">
            <b-form-input type="number" v-model="summary.speed.vessel" size="sm" style="width: 90%; margin:auto"></b-form-input>
          </div> -->
        </td>
        <td style="text-align: center;">{{summary.speed.diff = (summary.speed.vessel-summary.speed.kpi).toFixed(2)}}</td>
        <td style="text-align: center;">
          <div > {{ summary.steaming.kpi = summary.distance && summary.speed.kpi ? (summary.distance/summary.speed.kpi).toFixed(2) : null}}</div>
          <!-- <div v-else class="text-center">
            <b-form-input type="number" v-model="summary.steaming.kpi" size="sm" style="width: 90%; margin:auto"></b-form-input>
          </div> -->
        </td>
        <td style="text-align: center;"><div> {{ summary.steaming.vessel = formScl.summarySteaming.detail[index].steamingTime ? formScl.summarySteaming.detail[index].steamingTime : null }}</div>
          <!-- <div v-else class="text-center">
            <b-form-input type="number" v-model="summary.steaming.vessel" size="sm" style="width: 90%; margin:auto"></b-form-input>
          </div> -->
        </td>
        <td style="text-align: center;">{{ summary.steaming.diff = summary.steaming.kpi && summary.steaming.vessel ? (summary.steaming.kpi-summary.steaming.vessel).toFixed(2) : null }}</td>
        <td style="text-align: center;"><div v-if="preview"> {{ summary.focLtrs }}</div>
          <div v-else class="text-center">
            <b-form-input type="number" v-model="summary.focLtrs" size="sm" style="width: 90%; margin:auto"></b-form-input>
          </div>
        </td>
        <td style="text-align: center;">
          <div> {{ summary.focKpi = summary.focLtrs && summary.steaming.kpi ? (summary.focLtrs*summary.steaming.kpi).toFixed(2) : null }}</div>
          <!-- <div v-else class="text-center p-2">
            <b-form-input type="number" v-model="summary.focKpi" size="sm" style="width: 90%; margin:auto"></b-form-input>
          </div> -->
        </td>
        <td style="text-align: center;">{{Math.round(summary.steaming.vessel*summary.focLtrs)}}</td>
      </tr>

      <tr>
        <td style="text-align: right;" colspan="9">
          <div class="d-flex justify-content-end">
            <span class="align-self-center">Auxiliary Engine Idle ( </span>
            <span v-if="preview">{{formScl.summary.aeIdleDay}}</span>
          <div v-else class="text-center p-2">
            <b-form-input type="number" v-model="formScl.summary.aeIdleDay" size="sm"  style="width: 80%; margin:auto"></b-form-input>
          </div>
          <span class="align-self-center">Days)</span>
          </div>
        </td>
        <td style="text-align: center;"><div v-if="preview">{{ formScl.summary.aeIdleKpi }}</div>
        <div v-else class="text-center p-2">
            <b-form-input type="number" v-model="formScl.summary.aeIdleKpi" size="sm"  style="width: 90%; margin:auto"></b-form-input>
          </div>
        </td>
        <td rowspan="3"></td>
      </tr>
      <tr>
        <td style="text-align: right;" colspan="9">Manuevering, Idle, Runing Free</td>
        <td style="text-align: center;"><div v-if="preview">{{ formScl.summary.runningFree}}</div>
        <div v-else class="text-center p-2">
            <b-form-input type="number" v-model="formScl.summary.runningFree" size="sm"  style="width: 90%; margin:auto"></b-form-input>
          </div>
        </td>
      </tr>
      <tr>
        <td style="text-align: right;" colspan="9">Cargo Pump</td>
        <td style="text-align: center;"><div v-if="preview">{{ formScl.summary.cargoPump}}</div>
        <div v-else class="text-center p-2">
            <b-form-input type="number" v-model="formScl.summary.cargo_pump" size="sm"  style="width: 90%; margin:auto"></b-form-input>
          </div>
        </td>
      </tr>
      <tr>
        <td style="text-align: center;">Total</td>
        <td style="text-align: center;">{{ formScl.summary.totalDistance = (totalDistance()).toFixed(2) }}</td>
        <td style="text-align: center;">{{ formScl.summary.totalSpeed.kpi = (totalKPISpeed()).toFixed(2) }}</td>
        <td style="text-align: center;">{{ formScl.summary.totalSpeed.vessel = (totalVesselSpeed()).toFixed(2) }}</td>
        <td style="text-align: center;">{{ formScl.summary.totalSpeed.diff = (totalDiffSpeed()).toFixed(2) }}</td>
        <td style="text-align: center;" rowspan="2">{{ formScl.summary.steamingTotal.kpi  = (totalKPISteamingTime()).toFixed(2) }}</td>
        <td style="text-align: center;" rowspan="2">{{ formScl.summary.steamingTotal.vessel = (totalVesselSteamingTime()).toFixed(2) }}</td>
        <td style="text-align: center;" rowspan="2">{{ formScl.summary.steamingTotal.diff = (totalDiffSteamingTime()).toFixed(2) }}</td>
        <td style="text-align: center;" rowspan="2">-</td>
        <td style="text-align: center;" rowspan="2">{{ formScl.summary.totalKpi = (totalKPIFoc()).toFixed(2) }}</td>
        <td style="text-align: center;" rowspan="2">{{ (Math.round(totalVesselFoc())).toFixed(2) }}</td>
      </tr>
      <tr>
        <td style="text-align: center;">Average</td>
        <td style="text-align: center;">{{ this.formScl.summary.detail.length > 0 ? (formScl.summary.totalDistance/this.formScl.summary.detail.length).toFixed(2) : 0.00 }}</td>
        <td style="text-align: center;">{{ formScl.summary.averageSpeed.kpi =  (formScl.summary.totalSpeed.kpi/this.formScl.summary.detail.length).toFixed(2)}}</td>
        <td style="text-align: center;">{{ formScl.summary.averageSpeed.vessel = (formScl.summary.totalSpeed.vessel/this.formScl.summary.detail.length).toFixed(2)}}</td>
        <td style="text-align: center;">{{ formScl.summary.averageSpeed.diff = (formScl.summary.totalSpeed.diff/this.formScl.summary.detail.length).toFixed(2)}}</td>
      </tr>
      </tbody>
    </table>
    <br/>
    <table width="100%" border="1">
      <thead>
      <tr style="background-color: #bae1ff;">
        <td style="text-align: center;">Check 1</td>
        <td style="text-align: center;">Bunker FO</td>
        <td style="text-align: center;">Stock FO</td>
        <td style="text-align: center;">Check 2</td>
        <td style="text-align: center;">Consumption</td>
        <td style="text-align: center;">KPI</td>
        <td style="text-align: center;">Buy Back</td>
        <td style="text-align: center;">Deduction</td>
        <td style="text-align: center;">Difference</td>

      </tr>
      </thead>
      <tbody>
      <tr>
        <td style="text-align: center;">
          <div v-if="preview">{{ formScl.summary.check1 || 0 }}</div>
          <div v-else class="text-center">
            <b-form-input type="number" size="sm" v-model="formScl.summary.check1"  style="width: 90%; margin:auto"></b-form-input>
          </div>
        </td>
        <td style="text-align: center;">
          <div v-if="preview">{{ formScl.summary.bunkerFo || 0 }}</div>
          <div v-else class="text-center">
            <b-form-input type="number" size="sm" v-model="formScl.summary.bunkerFo"  style="width: 90%; margin:auto"></b-form-input>
          </div>
        </td>
        <td style="text-align: center;">
          <div>{{ formScl.summary.stockFo = Number(formScl.summary.check1 || 0)+Number(formScl.summary.bunkerFo || 0) }}</div>
          <!-- <div v-else class="text-center">
            <b-form-input type="number" size="sm" v-model="formScl.summary.stockFo"  style="width: 90%; margin:auto"></b-form-input>
          </div> -->
        </td>
        <td style="text-align: center;">
          <div v-if="preview">{{ formScl.summary.check2 || 0 }}</div>
          <div v-else class="text-center">
            <b-form-input type="number" size="sm" v-model="formScl.summary.check2"  style="width: 90%; margin:auto"></b-form-input>
          </div>
        </td>
        <td style="text-align: center;">
          <div >{{ formScl.summary.consumption = Number(formScl.summary.stockFo) - Number(formScl.summary.check2) }}</div>
<!--          <div >{{ formScl.summary.consumption = formScl.summary.check2 !== '' && formScl.summary.stockFo !== '' ? (formScl.summary.stockFo-formScl.summary.check2) : null }}</div>-->
          <!-- <div v-else class="text-center">
            <b-form-input v-model="formScl.summary.consumption" type="number" size="sm" style="width: 90%; margin:auto"></b-form-input>
          </div> -->
        </td>
        <td style="text-align: center;">
          <div v-if="preview">{{ formScl.summary.kpi || 0 }}</div>
          <div v-else class="text-center">
            <b-form-input type="number" v-model="formScl.summary.kpi" size="sm" style="width: 90%; margin:auto"></b-form-input>
          </div>
        </td>
        <td style="text-align: center;">
          <div>{{ formScl.summary.buyBack = (formScl.summary.kpi-formScl.summary.consumption).toFixed(2) }}</div>
          <!-- <div v-else class="text-center p-2">
            <b-form-input type="number" size="sm" style="width: 90%; margin:auto" v-model="formScl.summary.buyBack"></b-form-input>
          </div> -->
        </td>
        <td style="text-align: center;">
          <span class="d-none">{{formScl.summary.deduction = (formScl.summaryAec.deductionAe).toFixed(2)}}</span>
          <div class="text-center p-2">
            <b-form-input v-if="!preview" type="number" size="sm" style="width: 90%; margin:auto" v-model="formScl.summary.deduction"></b-form-input>
            <span v-else>{{formScl.summary.deduction}}</span>
          </div>
        </td>
        <td style="text-align: center;">{{ formScl.summary.difference = (parseInt(formScl.summary.buyBack) + parseInt(formScl.summary.deduction)).toFixed(2)}}</td>

      </tr>
      </tbody>
    </table>

    <table width="100%" border="1" style="margin-top:5px;">
      <tbody>
      <tr>
        <td>
          <div class="col-md-12" v-if="preview">
            <span>Remarks</span>
            <br>
            {{ formScl.remarks ? formScl.remarks : '-' }}
          </div>
          <div class="col-md-12" v-else>
            <b-form-group label="Remarks" label-for="remarks">
              <b-form-textarea v-model="formScl.remarks" id="description" rows="2"></b-form-textarea>
              <small style="float: left"><i class="fa fa-info-circle"></i> Max 200 Character</small>
            </b-form-group>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="col-md-12" v-if="preview">
            <span>Conclusion</span>
            <br>
            {{ formScl.conclusion ? formScl.conclusion : '-' }}
          </div>
          <div class="col-md-12" v-else>
            <b-form-group label="Conclusion" label-for="conclusion">
              <b-form-textarea id="description" rows="2"  v-model="formScl.conclusion" ></b-form-textarea>
              <small style="float: left"><i class="fa fa-info-circle"></i> Max 200 Character</small>
            </b-form-group>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="col-md-12" v-if="preview">
            <span>Notes</span>
            <br>
            {{ formScl.notes ? formScl.notes : '-' }}
          </div>
          <div class="col-md-12" v-else>
            <b-form-group label="Notes" label-for="notes">
              <b-form-textarea id="description" rows="2"  v-model="formScl.notes" ></b-form-textarea>
              <small style="float: left"><i class="fa fa-info-circle"></i> Max 200 Character</small>
            </b-form-group>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <table border="0" width="100%" style="margin-top:10px;">
      <thead>
      <tr>
        <td style="text-align: left" width="25%">Prepared By</td>
        <td style="text-align: left;" width="25%">Acknowledge By</td>
        <td style="text-align: left;" width="25%">Checked By</td>
        <td style="text-align: left;" width="25%">Approved By</td>
      </tr>
      </thead>
    </table>
    <br/><br/><br/>
    <table border="0" width="100%">
      <tbody>
      <tr>
        <td style="text-align: left;" width="25%" v-if="preview"> {{ formScl.preparedBy ? formScl.preparedBy : '-' }}</td>
        <td style="text-align: left;" width="25%" v-else> <b-form-input type="text" style="width: 80%;"  v-model="formScl.preparedBy"></b-form-input></td>
        <td style="text-align: left;" width="25%" v-if="preview">{{ formScl.acknowledgeBy ? formScl.acknowledgeBy : '-' }}</td>
        <td style="text-align: left;" width="25%" v-else> <b-form-input type="text" style="width: 80%;"  v-model="formScl.acknowledgeBy"></b-form-input></td>
        <td style="text-align: left;" width="25%" v-if="preview">{{ formScl.checkedBy ? formScl.checkedBy : '-' }}</td>
        <td style="text-align: left;" width="25%" v-else> <b-form-input type="text" style="width: 80%;"  v-model="formScl.checkedBy"></b-form-input></td>
        <td style="text-align: left;" width="25%" v-if="preview">{{ formScl.approvedBy ? formScl.approvedBy : '-' }}</td>
        <td style="text-align: left;" width="25%" v-else> <b-form-input type="text" style="width: 80%;"  v-model="formScl.approvedBy"></b-form-input></td>
      </tr>
      <tr>
        <td style="text-align: left;" width="25%" v-if="preview"> {{ formScl.positionPreparedBy ? formScl.positionPreparedBy : '-' }}</td>
        <td style="text-align: left;" width="25%" v-else> <b-form-input class="mb-5" type="text" style="width: 80%;height:30px" placeholder="Position" v-model="formScl.positionPreparedBy"></b-form-input></td>
        <td style="text-align: left;" width="25%" v-if="preview"> {{ formScl.positionAcknowledgeBy ? formScl.positionAcknowledgeBy : '-' }}</td>
        <td style="text-align: left;" width="25%" v-else> <b-form-input class="mb-5" type="text" style="width: 80%;height:30px" placeholder="Position" v-model="formScl.positionAcknowledgeBy"></b-form-input></td>
        <td style="text-align: left;" width="25%" v-if="preview"> {{ formScl.positionCheckedBy ? formScl.positionCheckedBy : '-' }}</td>
        <td style="text-align: left;" width="25%" v-else> <b-form-input class="mb-5" type="text" style="width: 80%;height:30px" placeholder="Position" v-model="formScl.positionCheckedBy"></b-form-input></td>
        <td style="text-align: left;" width="25%" v-if="preview"> {{ formScl.positionApprovedBy ? formScl.positionApprovedBy : '-' }}</td>
        <td style="text-align: left;" width="25%" v-else> <b-form-input class="mb-5" type="text" style="width: 80%;height:30px" placeholder="Position" v-model="formScl.positionApprovedBy"></b-form-input></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { voyagesActions, fleetsActions } from '@src/Utils/helper'
import moment from 'moment'
import 'moment-timezone'

export default {
  name: 'SclAdd',
  data () {
    return {
      preview: false,
      formScl: {
        tugBargeName: '',
        pol: '',
        pod: '',
        cargoType: '',
        quantity: null,
        voyageId: '',
        locationCheck1: '',
        locationCheck2: '',
        dateTimeCheck1: '',
        dateTimeCheck2: '',
        summarySteaming: {
          detail: [
            {
              ptp: '',
              startDate: '',
              endDate: '',
              steamingTime: null
            }
          ],
          totalSteaming: null
        },
        summaryAec: {
          quotation: null,
          deductionAe: null,
          totalIdle: null,
          totalAe: null,
          detail: [
            {
              description: '',
              startDate: '',
              endDate: '',
              idle: null,
              ltrs: null,
              ae: null
            }
          ]
        },
        summary: {
          detail: [
            {
              ptp: '',
              distance: null,
              speed: {
                vessel: null,
                kpi: null,
                diff: null
              },
              steaming: {
                vessel: null,
                kpi: null,
                diff: null
              },
              focLtrs: null,
              focKpi: null
            }
          ],
          aeIdleDay: null,
          aeIdleKpi: null,
          runningFree: null,
          totalDistance: null,
          totalSpeed: {
            vessel: null,
            kpi: null,
            diff: null
          },
          averageSpeed: {
            vessel: null,
            kpi: null,
            diff: null
          },
          steamingTotal: {
            vessel: null,
            kpi: null,
            diff: null
          },
          totalLtrs: null,
          totalKpi: null,
          totalVessel: null,
          check1: null,
          bunkerFo: null,
          stockFo: null,
          check2: null,
          consumption: null,
          kpi: null,
          buyBack: null,
          deduction: null,
          difference: null,
          cargoPump: null
        },
        remarks: '',
        conclusion: '',
        notes: '',
        preparedBy: '',
        positionPreparedBy: '',
        acknowledgeBy: '',
        positionAcknowledgeBy: '',
        checkedBy: '',
        positionCheckedBy: '',
        approvedBy: '',
        positionApprovedBy: '',
        fleet: null
      }
    }
  },
  computed: {
  },
  async mounted () {
    if (this.$route.query.scl_id && this.$route.query.vehicleId) {
      this.getSCLDetail(this.$route.query.scl_id)
    }

    if (this.$route.query.vehicleId) {
      this.fetchFleet()
    }

    document.body.style.background = '#FFF'
  },
  methods: {
    ...voyagesActions,
    ...fleetsActions,
    formatDateWithValidate(date, format) {
      if(moment(date, format, true).isValid()) {
        date = moment(date, format)
      } else {
        date = moment(date)
      }

      return date
    },
    async fetchFleet () {
      const res = await this.getFleetById(this.$route.query.vehicleId)

      if (res.status == 'success') {
        this.fleet = res.data
        this.formScl.tugBargeName = this.fleet.name
      } else {
        this.fleet = {}
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    print () {
      window.print()
    },
    edit () {
      let timerInterval
      this.$swal.fire({
        title: 'Check Available!',
        timer: 500,
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading()
          timerInterval = setInterval(() => {
            const content = this.$swal.getContent()
            if (content) {
              const b = content.querySelector('b')
              if (b) {
                b.textContent = this.$swal.getTimerLeft()
              }
            }
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      }).then((result) => {
        this.preview = false
      })
    },
    previewNow () {
      let timerInterval
      this.$swal.fire({
        title: 'Check Available!',
        timer: 500,
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading()
          timerInterval = setInterval(() => {
            const content = this.$swal.getContent()
            if (content) {
              const b = content.querySelector('b')
              if (b) {
                b.textContent = this.$swal.getTimerLeft()
              }
            }
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      }).then((result) => {
        this.preview = true
      })
    },
    addSteamingTime () {
      this.formScl.summarySteaming.detail.push(
        {
          ptp: '',
          startDate: '',
          endDate: '',
          steamingTime: null
        }
      )
      this.formScl.summaryAec.detail.push(
        {
          description: '',
          startDate: '',
          endDate: '',
          idle: null,
          ltrs: null,
          ae: null
        }
      )
      this.formScl.summary.detail.push(
        {
          ptp: '',
          distance: null,
          speed: {
            vessel: null,
            kpi: null,
            diff: null
          },
          steaming: {
            vessel: null,
            kpi: null,
            diff: null
          },
          focLtrs: null,
          focKpi: null
        }
      )
    },
    removeSteamingTime (index) {
      this.formScl.summarySteaming.detail.splice(index, 1)
      this.formScl.summaryAec.detail.splice(index, 1)
      this.formScl.summary.detail.splice(index, 1)
    },
    formatDateTime (value, event) {
      return moment(value).format('YYYY-MM-DDThh:mm:ss')
    },
    steamingTimeCalculate (date1, date2) {
      var diff = 0
      if (date1 && date2) {
        diff = moment(this.formatDateWithValidate(date2, 'DD-MM-YYYY HH:mm')).valueOf() - moment(this.formatDateWithValidate(date1, 'DD-MM-YYYY HH:mm')).valueOf()
        diff = (diff / 1000 / 60 / 60)
        if (diff && Number.isInteger(diff) === false) {
          diff = diff.toFixed(2)
        }
      }
      return diff
    },
    steamingTotalTimeCalculate () {
      var diff = 0
      let total = 0
      this.formScl.summarySteaming.detail.forEach(el => {
        if (el.startDate && el.endDate) {
          diff = moment(this.formatDateWithValidate(el.endDate, 'DD-MM-YYYY HH:mm')).valueOf() - moment(this.formatDateWithValidate(el.startDate, 'DD-MM-YYYY HH:mm')).valueOf()
          diff = diff / 1000 / 60 / 60
          total += diff
        }
      })
      return total && Number.isInteger(total) === false ? total.toFixed(2) : total
    },
    aecTotalTimeCalculate () {
      var diff = 0
      let total = 0
      this.formScl.summaryAec.detail.forEach(el => {
        if (el.startDate && el.endDate) {
          diff = moment(this.formatDateWithValidate(el.endDate, 'DD-MM-YYYY HH:mm')).valueOf() - moment(this.formatDateWithValidate(el.startDate, 'DD-MM-YYYY HH:mm')).valueOf()
          diff = diff / 1000 / 60 / 60
          total += diff
        }
      })
      return total && Number.isInteger(total) === false ? total.toFixed(2) : total
    },
    totalAE () {
      let total = 0
      this.formScl.summaryAec.detail.forEach((el, i) => {
        if (el.ltrs) {
          total += this.steamingTimeCalculate(el.startDate, el.endDate) * el.ltrs
        }
      })
      return Math.ceil(total)
    },
    totalLtrsAE () {
      let total = 0
      this.formScl.summaryAec.detail.forEach((el, i) => {
        if (el.ltrs) {
          total += Number(el.ltrs)
        }
      })
      return total
    },
    totalKPISummary () {
      let total = 0
      this.formScl.summaryAec.detail.forEach((el, i) => {
        if (el.ltrs) {
          total += this.steamingTimeCalculate(this.formScl.summarySteaming.detail[i].startDate, this.formScl.summarySteaming.detail[i].endDate) * el.ltrs
        }
      })
      return total
    },
    totalDistance () {
      let total = 0
      this.formScl.summary.detail.forEach((el, i) => {
        if (el.distance) {
          total += Number(el.distance)
        }
      })
      return total
    },
    totalKPISpeed () {
      let total = 0
      this.formScl.summary.detail.forEach((el, i) => {
        if (el.speed.kpi) {
          total += Number(el.speed.kpi)
        }
      })
      return total
    },
    totalVesselSpeed () {
      let total = 0
      this.formScl.summary.detail.forEach((el, i) => {
        if (el.speed.vessel) {
          total += Number(el.speed.vessel)
        }
      })
      return total
    },
    totalDiffSpeed () {
      let total = 0
      this.formScl.summary.detail.forEach((el, i) => {
        if (el.speed.diff) {
          total += Number(el.speed.diff)
        }
      })
      return total
    },
    totalKPISteamingTime () {
      let total = 0
      this.formScl.summary.detail.forEach((el, i) => {
        if (el.steaming.kpi) {
          total += Number(el.steaming.kpi)
        }
      })
      return total
    },
    totalVesselSteamingTime () {
      let total = 0
      this.formScl.summary.detail.forEach((el, i) => {
        if (el.steaming.vessel) {
          total += Number(el.steaming.vessel)
        }
      })
      return total
    },
    totalDiffSteamingTime () {
      let total = 0
      this.formScl.summary.detail.forEach((el, i) => {
        if (el.steaming.diff) {
          total += Number(el.steaming.diff)
        }
      })
      return total
    },
    totalLtrsFoc () {
      let total = 0
      this.formScl.summary.detail.forEach((el, i) => {
        if (el.focLtrs) {
          total += Number(el.focLtrs)
        }
      })
      return total
    },
    totalKPIFoc () {
      let total = 0
      this.formScl.summary.detail.forEach((el, i) => {
        if (el.focKpi) {
          total += Number(el.focKpi)
        }
      })

      if (this.formScl.summary.aeIdleKpi) {
        total += Number(this.formScl.summary.aeIdleKpi)
      }

      if (this.formScl.summary.runningFree) {
        total += Number(this.formScl.summary.runningFree)
      }

      if (this.formScl.summary.cargo_pump) {
        total += Number(this.formScl.summary.cargo_pump)
      }

      this.formScl.summary.kpi = total

      return total
    },
    totalVesselFoc () {
      let total = 0
      this.formScl.summary.detail.forEach((el, i) => {
        if (el.focLtrs) {
          total += (Number(el.steaming.vessel) * Number(el.focLtrs))
        }
      })

      this.formScl.summary.totalVessel = total

      return total
    },
    async getSCLDetail (sclId) {
      const payload = {
        vehicleId: this.$route.query.vehicleId,
        id: sclId
      }
      const res = await this.getSCLById(payload)

      if (res.status == 'success') {
        const { data } = res
        this.formScl = data.scl
        this.formScl.dateTimeCheck1 = moment(this.formScl.dateTimeCheck1, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm')
        this.formScl.dateTimeCheck2 = moment(this.formScl.dateTimeCheck2, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm')

        this.formScl.summarySteaming.detail.forEach(el => {
          el.startDate = moment(el.startDate, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm')
          el.endDate = moment(el.endDate, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm')
        })
      } else {
        this.sclData = []
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },

    async saveSCL () {
      this.$swal.fire({
        title: 'Processing...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading()
        }
      })

      let payload = this.formScl

      payload.quantity = payload.quantity ? Number(payload.quantity) : null
      payload.dateTimeCheck1 = payload.dateTimeCheck1 ? moment(payload.dateTimeCheck1, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : null
      payload.dateTimeCheck2 = payload.dateTimeCheck2 ? moment(payload.dateTimeCheck2, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : null
      payload.summarySteaming.totalSteaming = payload.summarySteaming.totalSteaming ? Number(payload.summarySteaming.totalSteaming) : null
      payload.summarySteaming.detail.forEach(el => {
        el.steamingTime = this.steamingTimeCalculate(el.startDate, el.endDate)
        el.steamingTime = el.steamingTime ? Number(el.steamingTime) : null
        el.startDate = el.startDate ? moment(el.startDate, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : null
        el.endDate = el.endDate ? moment(el.endDate, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : null
      })

      payload.summaryAec.quotation = payload.summaryAec.quotation ? Number(payload.summaryAec.quotation) : null
      payload.summaryAec.deductionAe = payload.summaryAec.deductionAe ? Number(payload.summaryAec.deductionAe) : null
      payload.summaryAec.totalIdle = payload.summaryAec.totalIdle ? Number(payload.summaryAec.totalIdle) : null
      payload.summaryAec.totalAe = payload.summaryAec.totalAe ? Number(payload.summaryAec.totalAe) : null
      payload.summaryAec.detail.forEach((el, i) => {
        el.idle = payload.summarySteaming.detail[i].steamingTime ? Number(payload.summarySteaming.detail[i].steamingTime) : null
        el.ltrs = el.ltrs ? Number(el.ltrs) : null
        el.ae = Math.ceil(this.steamingTimeCalculate(el.startDate, el.endDate) * el.ltrs)
        el.startDate = el.startDate ? moment(el.startDate, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : null
        el.endDate = el.endDate ? moment(el.endDate, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : null
      })

      payload.summary.aeIdleDay = payload.summary.aeIdleDay ? Number(payload.summary.aeIdleDay) : null
      payload.summary.aeIdleKpi = payload.summary.aeIdleKpi ? Number(payload.summary.aeIdleKpi) : null
      payload.summary.runningFree = payload.summary.runningFree ? Number(payload.summary.runningFree) : null
      payload.summary.cargoPump = payload.summary.runningFree ? Number(payload.summary.cargoPump) : null
      payload.summary.totalDistance = payload.summary.totalDistance ? Number(payload.summary.totalDistance) : null
      payload.summary.totalLtrs = payload.summary.totalLtrs ? Number(payload.summary.totalLtrs) : null
      payload.summary.totalKpi = payload.summary.totalKpi ? Number(payload.summary.totalKpi) : null
      payload.summary.check1 = payload.summary.check1 ? Number(payload.summary.check1) : null
      payload.summary.bunkerFo = payload.summary.bunkerFo ? Number(payload.summary.bunkerFo) : null
      payload.summary.stockFo = payload.summary.stockFo ? Number(payload.summary.stockFo) : null
      payload.summary.check2 = payload.summary.check2 ? Number(payload.summary.check2) : null
      payload.summary.consumption = payload.summary.consumption ? Number(payload.summary.consumption) : null
      payload.summary.kpi = payload.summary.kpi ? Number(payload.summary.kpi) : null
      payload.summary.buyBack = payload.summary.buyBack ? Number(payload.summary.buyBack) : null
      payload.summary.deduction = payload.summary.deduction ? Number(payload.summary.deduction) : null
      payload.summary.difference = payload.summary.difference ? Number(payload.summary.difference) : null
      payload.summary.totalSpeed.vessel = payload.summary.totalSpeed.vessel ? Number(payload.summary.totalSpeed.vessel) : null
      payload.summary.totalSpeed.kpi = payload.summary.totalSpeed.kpi ? Number(payload.summary.totalSpeed.kpi) : null
      payload.summary.totalSpeed.diff = payload.summary.totalSpeed.diff ? Number(payload.summary.totalSpeed.diff) : null
      payload.summary.averageSpeed.vessel = payload.summary.averageSpeed.vessel ? Number(payload.summary.averageSpeed.vessel) : null
      payload.summary.averageSpeed.kpi = payload.summary.averageSpeed.kpi ? Number(payload.summary.averageSpeed.kpi) : null
      payload.summary.averageSpeed.diff = payload.summary.averageSpeed.diff ? Number(payload.summary.averageSpeed.diff) : null
      payload.summary.steamingTotal.vessel = payload.summary.steamingTotal.vessel ? Number(payload.summary.steamingTotal.vessel) : null
      payload.summary.steamingTotal.kpi = payload.summary.steamingTotal.kpi ? Number(payload.summary.steamingTotal.kpi) : null
      payload.summary.steamingTotal.diff = payload.summary.steamingTotal.diff ? Number(payload.summary.steamingTotal.diff) : null
      payload.summary.detail.forEach((el, i) => {
        el.ptp = this.formScl.summarySteaming.detail[i].ptp ? this.formScl.summarySteaming.detail[i].ptp : ''
        el.distance = el.distance ? Number(el.distance) : null
        el.focLtrs = el.focLtrs ? Number(el.focLtrs) : null
        el.focKpi = el.focKpi ? Number(el.focKpi) : null
        el.speed.vessel = el.speed.vessel ? Number(el.speed.vessel) : null
        el.speed.kpi = el.speed.kpi ? Number(el.speed.kpi) : null
        el.speed.diff = el.speed.diff ? Number(el.speed.diff) : null
        el.steaming.vessel = el.steaming.vessel ? Number(el.steaming.vessel) : null
        el.steaming.kpi = el.steaming.kpi ? Number(el.steaming.kpi) : null
        el.steaming.diff = el.steaming.diff ? Number(el.steaming.diff) : null
      })

      const id = this.$route.query.vehicleId
      if (this.$route.query.scl_id) {
        let res = await this.updateSCL({ vehicleId: id, id: this.$route.query.scl_id, data: payload })
        if (res.status == 'success') {
          this.$swal('Update SCL Success!', res.data.message, 'success')
          this.preview = true
        } else {
          if (res.data.message != null) {
            this.$swal('Error', res.data.message, 'error')
          } else {
            this.$swal(
              'Error',
              'Something wrong with the server. Please try again later.',
              'error'
            )
          }
        }
      } else {
        let res = await this.createSCL({ id, data: payload })
        if (res.status == 'success') {
          this.$swal('Add SCL Success!', res.data.message, 'success')
          this.preview = true
        } else {
          if (res.data.message != null) {
            this.$swal('Error', res.data.message, 'error')
          } else {
            this.$swal(
              'Error',
              'Something wrong with the server. Please try again later.',
              'error'
            )
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
