<template>
  <iq-card>
    <template v-slot:headerTitle>
      <h5 class="card-title text-primary"><strong>DOCKING PROGRESS</strong></h5>
    </template>
    <template v-slot:headerAction>
<!--      <router-link to="/ops/docking-create"></router-link>-->
      <a class="text-primary float-right ml-3" v-b-toggle.docking_schedule_progress_card role="button" style="cursor: pointer">
        <i class="fa fa-filter" />
      </a>

      <!-- MODAL CREATE EMERGENCY DOCKING -->
      <ModalDocking
        v-model="showDockingModal"
        :action="dockingAction"
        :vehicle-id="selectedVehicleId"
        :docking-id="selectedDockingId"
        @cancel="onCloseModalCreateDocking"
        @submit="onSubmitCreateDocking"
      />
      <!-- MODAL CREATE EMERGENCY DOCKING END -->

      <ModalDockingActivity
        v-model="showActivityDockingModal"
        :docking-id="selectedDockingId"
        @cancel="onCloseModalActivityDocking"
        @submit="onSubmitActivityDocking"
      />
    </template>
    <template v-slot:body>
      <b-collapse visible id="docking_schedule_progress_card" class="mb-2">
        <!-- FILTER DOCKING SCHEDULE-->
        <b-row>
          <b-col md="2">
            <b-form-group label="Fleet Category" label-for="fleet_category" placeholder="Select Fleet category">
              <v-select 
                v-model="lists.params.vehicleTypeIds" 
                multiple 
                label="name"
                :options="vehicleTypes" 
                :reduce="type => type.id"
                @input="onChangeFleetCategory"
              />
            </b-form-group>
          </b-col>
          <b-col lg="2">
            <b-form-group label="Vehicles" label-for="vehicleId" >
              <v-select
                v-model="lists.params.vehicleId"
                label="text"
                :options="fleetFilteredOptions"
                :reduce="(fleetFilteredOptions) => fleetFilteredOptions.value"
                placeholder="Select Vehicle"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Docking Type" label-for="docking_type">
              <v-select v-model="lists.params.dockingType" :options="dockingTypes">
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Status" label-for="status">
              <v-select v-model="lists.params.dockingStatus" :options="dockingStatus">
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Period" label-for="Period">
              <date-picker style="width: 100%" @change="onFilterChangeRangeDate()" v-model="lists.dateRange" format="DD MMM YYYY" type="date" range placeholder="Select date range"></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="1">
            <b-form-group label="Filter" label-for="button_search">
              <b-button variant="primary" @click="getDockingScheduleProgressData()" class="mb-3 mr-1"><i class="fa fa-filter"></i></b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- FILTER DOCKING SCHEDULE END -->

        <!-- DOCKING SCHEDULE -->
        <table class="table table-striped">
          <thead>
          <tr>
            <th class="text-center">NO</th>
            <th class="text-center">FLEET</th>
            <th class="text-center">DOCKING TYPE</th>
            <th class="text-center">SHIPYARD</th>
            <th class="text-center">DOCKING DATE</th>
            <th class="text-center">COMPLETE DATE</th>
            <th class="text-center">JOB</th>
            <th class="text-center">STATUS</th>
            <th class="text-center">ACTION</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="lists.loading">
            <td class="text-center" colspan="9">Loading...</td>
          </tr>
          <template v-else>
            <tr v-for="(data, index) in lists.data" :key="index"
                :class="{
                  'bg-danger' : ['REJECT'].includes(data.status),
                }"
            >
              <th class="text-center">{{index+1}}</th>
              <td class="text-center">{{ data.vehicle.name }}</td>
              <td class="text-center">{{ data.dockingType }}</td>
              <td class="text-center">{{ data.shipyard.name}}</td>
              <td class="text-center">{{ data.dockingSchedule }}</td>
              <td class="text-center">{{ data.deliveryDate }}</td>
              <td class="text-center">{{ data.jobCount.finishedJob }}/{{ data.jobCount.totalJob }}</td>
              <td class="text-center">
                <b-badge v-if="['SEA_TRIAL'].includes(data.status)" variant="success d-block">SEA TRIAL</b-badge>
                <b-badge v-else-if="['REVIEW'].includes(data.status)" variant="warning d-block">NEED REVIEW</b-badge>
                <b-badge v-else-if="['HANDOVER'].includes(data.status)" variant="d-block" class="text-white w-100" style="background-color:#9E07E6">SERAH TERIMA</b-badge>
                <b-badge v-else-if="['APPROVE'].includes(data.status)" variant="primary d-block">NEED APPROVE</b-badge>
<!--                <b-badge v-else-if="['APPROVE'].includes(data.status)" variant="primary d-block">SERAH TERIMA</b-badge>-->
                <b-badge v-else-if="['PROGRESS'].includes(data.status)" variant="info d-block">ON PROGRESS</b-badge>
                <b-badge v-else-if="['REJECT'].includes(data.status)" variant="light d-block">REJECT</b-badge>
              </td>
              <td class="text-center">
                <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-ship m-0 text-primary"></i>
                              </span>
                  </template>
                  <b-dropdown-item v-if="['REVIEW'].includes(data.status)" @click="onOpenModalDocking(data, 'REVIEW')" variant="warning"><i class="fa fa-search mr-2"></i>REVIEW</b-dropdown-item>
                  <b-dropdown-item v-if="['APPROVE'].includes(data.status)" @click="onOpenModalDocking(data, 'APPROVE')" variant="success"><i class="fa fa-check mr-2"></i>APPROVE</b-dropdown-item>
                  <b-dropdown-item v-if="['PROGRESS'].includes(data.status)" @click="onOpenModalActivityDocking(data)" variant="info"><i class="fa fa-comments mr-2"></i>ACTIVITY</b-dropdown-item>
                  <b-dropdown-item v-if="['PROGRESS', 'REVIEW'].includes(data.status)" @click="onOpenModalDocking(data, 'UPDATE')" variant="primary"><i class="fa fa-plus-circle mr-2"></i>ADD JOB</b-dropdown-item>
                  <b-dropdown-item v-if="['REJECT'].includes(data.status)" @click="onOpenModalDocking(data, 'UPDATE')" variant="danger"><i class="fa fa-percent mr-2"></i>UPDATE DOCKING</b-dropdown-item>
                  <b-dropdown-item href="#" v-if="['SEA_TRIAL'].includes(data.status)" variant="success"><router-link :to="`/docking/sea-trial?dockingId=${data.id}`"><i class="fa fa-ship mr-2"></i>SEA TRIAL</router-link></b-dropdown-item>
                  <b-dropdown-item href="#" v-if="['HANDOVER'].includes(data.status)" variant="#9E07E6"><router-link :to="`/docking/serah-terima?dockingId=${data.id}`"><i class="fas fa-handshake mr-2"></i>SERAH TERIMA</router-link></b-dropdown-item>
                  <b-dropdown-item href="#" @click="onOpenModalDocking(data, 'DETAIL')" variant="success"><i class="fas fa-info-circle"></i> DETAIL</b-dropdown-item>

<!--                  <b-dropdown-item href="#" variant="dark"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</b-dropdown-item>-->
                </b-dropdown>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <div class="mt-3">
          <b-pagination
            v-model="lists.params.page"
            :total-rows="lists.meta.total"
            :per-page="lists.meta.perPage"
            align="center"
            @input="getDockingScheduleProgressData"
          ></b-pagination>
        </div>
        <!-- DOCKING SCHEDULE END -->
      </b-collapse>
    </template>
  </iq-card>
</template>

<script>
import ModalSelectFleets from '@components/Modal/ModalSelectFleets'
import ModalDockingSetSchedule from '@components/Docking/ModalDockingSetSchedule'
import moment from 'moment'
import { dockingActions, fleetsActions } from '@src/Utils/helper'
import ModalDocking from '@components/Docking/ModalDocking'
import ModalDockingPending from '@components/Docking/ModalDockingPending'
import ModalDockingActivity from '@components/Docking/ModalDockingActivity'
export default {
  name: 'DockingDashboardProgress',
  components: { ModalDockingActivity, ModalDockingPending, ModalDocking, ModalDockingSetSchedule, ModalSelectFleets },
  props:{
    onGlobalRefresh: {
      type: Function,
      default: true
    },
    globalRefresh: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      showInitDockingFleetModal: false,
      showDockingModal: false,
      showSetDockingScheduleModal: false,
      showPendingDockingModal: false,
      showActivityDockingModal: false,
      dockingAction: 'CREATE_EMERGENCY',
      lists: {
        loading: true,
        data: [],
        dateRange: [],
        params: {
          page: 1,
          perPage: 25,
          vehicleTypeIds: [],
          dockingType: '',
          periodeStart: '',
          periodeEnd: '',
          dockingStatus: '',
          vehicleId:null
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      fleetOptions: [],
      fleetFilteredOptions: [],
      vehicleTypes: [],
      dockingTypes: ['SPECIAL', 'INTERMEDIATE', 'EMERGENCY'],
      dockingStatus: ['ACTIVE', 'DUE_DATE', 'PENDING', 'PROGRESS', 'APPROVE', 'REVIEW', 'SEA_TRIAL', 'FINISH', 'REJECT', 'OVER_SCHEDULE'],
      selectedDockingId: null,
      selectedVehicleId: null,
    }
  },
  mounted () {
    this.onFilterChangeRangeDate()
    this.fetchFleetTypes()
    this.getDockingScheduleProgressData()
    this.fetchFleets()
  },
  watch:{
    'globalRefresh': {
      async handler() {
        await this.getDockingScheduleProgressData()
      },
      deep: true,
    },
  },
  methods: {
    ...fleetsActions,
    ...dockingActions,

    onOpenModalCreateDocking (data) {
      this.selectedVehicleId = data
      this.showDockingModal = true
      this.showInitDockingFleetModal = false
    },
    onOpenModalDocking (data, action) {
      this.selectedVehicleId = data.vehicle.id
      this.selectedDockingId = data.id
      this.dockingAction = action
      this.showDockingModal = true
    },
    onOpenModalPendingDocking (data) {
      this.selectedVehicleId = data.vehicle.id
      this.selectedDockingId = data.id
      this.showPendingDockingModal = true
    },
    onOpenModalActivityDocking (data) {
      this.selectedDockingId = data.id
      this.showActivityDockingModal = true
    },
    onCloseModalCreateDocking () {
      this.selectedVehicleId = null
      this.selectedDockingId = null
      this.showDockingModal = false
      this.showInitDockingFleetModal = false
    },
    onClosePendingDocking () {
      this.showPendingDockingModal = false
      this.selectedVehicleId = null
      this.selectedDockingId = null
    },
    onCloseModalActivityDocking () {
      this.showActivityDockingModal = false
      this.selectedDockingId = null
    },
    onCloseSetDockingScheduleModal () {
      this.showSetDockingScheduleModal = false
    },
    onSubmitSetDockingSchedule () {
      this.getDockingScheduleProgressData()
      this.showSetDockingScheduleModal = false
    },
    onSubmitCreateDocking () {
      this.getDockingScheduleProgressData()
      this.onCloseModalCreateDocking()
    },
    onSubmitPendingDocking () {
      this.getDockingScheduleProgressData()
      this.onClosePendingDocking()
    },
    onSubmitActivityDocking () {
      this.getDockingScheduleProgressData()
      this.onCloseModalActivityDocking()
    },
    onFilterChangeRangeDate () {
      this.lists.params.periodeStart = this.lists.dateRange[0] ? moment(this.lists.dateRange[0]).format('DD-MM-YYYY') : ''
      this.lists.params.periodeEnd = this.lists.dateRange[1] ? moment(this.lists.dateRange[1]).format('DD-MM-YYYY') : ''
    },
    onChangeFleetCategory(){
      let tempData = this.fleetOptions

      tempData = tempData.filter(obj => this.lists.params.vehicleTypeIds.includes(obj.vehicleType.id))
      
      if(this.lists.params.vehicleTypeIds.length <= 0){
        this.fetchFleets()
      } else {
        this.fleetFilteredOptions = tempData
      }
    },
    async fetchFleets() {
      let params = {
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
      }

      let res = await this.getFleets(params)

      if (res.status) {
        if (res.data && res.data.length > 0) {
          this.fleetOptions = res.data.map((val) => ({
            ...val,
            text: val.name,
            value: val.id,
          }))
          this.fleetFilteredOptions = this.fleetOptions
        }
      }
    },
    async fetchFleetTypes () {
      let params = { active: true }
      let res = await this.getFleetTypes(params)

      let data = []

      if (res.data) {
        data = res.data
      }

      this.vehicleTypes = data
    },
    async getDockingScheduleProgressData () {
      this.lists.loading = true
      this.lists.data = []

      const params = {
        ...this.lists.params,
        vehicleTypeIds: this.lists.params.vehicleTypeIds.join(',')
      }

      if (params.dockingType === '') {
        delete params.dockingType
      }
      if (params.dockingStatus === '') {
        delete params.dockingStatus
      }
      if (params.vehicleTypeIds === '') {
        delete params.vehicleTypeIds
      }
      if (params.periodeStart === '') {
        delete params.periodeStart
      }
      if (params.periodeEnd === '') {
        delete params.periodeEnd
      }
      if (params.vehicleId === '') {
        delete params.vehicleId
      }

      const { status, data } = await this.getProgressDockingSchedules(params)

      if (status === 'success') {
        if (data.data) {
          this.lists.data = data.data
          this.lists.meta.perPage = data.perPage
          this.lists.meta.currentPage = data.currentPage
          this.lists.meta.total = data.total
          this.lists.meta.totalPage = data.totalPage
        }
        this.lists.loading = false
      } else {
        this.lists.data = []
        this.lists.loading = false
        this.$swal(
          `Oops!`,
          data,
          'error'
        )
      }
    }
  }
}
</script>

<style scoped>

</style>
