<template>
  <div>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"
            ><strong>MASTER Contract Type</strong></h4
            >
          </template>
          <template v-slot:body>
            <b-form class="row" @submit.prevent="getContractType()">
              <b-col cols="12" md="3">
                <b-form-group
                  label="Search Master Contract Type :"
                  label-for="kind-fleet"
                >
                  <b-form-input
                    id="name"
                    class="w-100"
                    type="text"
                    placeholder="Search..."
                    v-model="contractTypeList.params.name"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="2">
                <b-form-group label="Search" label-for="button_search">
                  <b-button type="submit" variant="primary" class="ml-2"><i class="fa fa-search"></i></b-button>
                </b-form-group>
              </b-col>
            </b-form>
          </template>
        </iq-card>
      </b-col>
      <b-col sm="8">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">List Master Contract Type</h4>
          </template>
          <template v-slot:body>
            <div v-if="contractTypeList._loading" class="text-center my-5">
              <b-spinner type="grow" label="Spinning"></b-spinner>
            </div>
            <template v-else>
              <div class="table-responsive" v-if="contractTypeList.data && contractTypeList.data.length > 0">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th width="10%">No</th>
                      <th width="25%">Name</th>
                      <th width="25%">Alias</th>
                      <th width="10%">Spal</th>
                      <th width="10%">Status</th>
                      <th width="15%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(p, i) in contractTypeList.data" :key="`p-${i}`">
                      <td>{{ i + 1 }}</td>
                      <td>{{ p.name }}</td>
                      <td>{{ p.alias }}</td>
                      <td>{{ p.is_spal ? 'Yes' : 'No' }}</td>
                      <td>{{ p.status ? 'Active' : 'Inactive' }}</td>
                      <td>
                        <b-button variant="success" size="sm" class="mr-2" @click="editFormContractType(p)"><i class="fa fa-edit"></i></b-button>
                        <b-button variant="danger" size="sm" @click="onDeleteContractType(p)">
                          <i v-if="!delete_contract_type" class="fa fa-trash"></i>
                          <template v-else>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Deleting...
                          </template>
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="contractTypeList.data && contractTypeList.data.length > 0">
                <b-pagination
                  v-model="contractTypeList.meta.page"
                  :total-rows="contractTypeList.meta.totalItem"
                  @change="getContractType"
                  :per-page="contractTypeList.meta.perPage"
                  first-number
                  align="center"
                ></b-pagination>
              </div>
              <p v-else class="text-center my-4">No Contract Type data found.</p>
            </template>
          </template>
        </iq-card>
      </b-col>
      <b-col sm="4">
        <iq-card
          :class="{
            'border border-danger shadow-lg': contract_type_edit,
          }"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title"
            >{{ contract_type_edit ? 'Edit' : 'Add' }} Contract Type</h4
            >
          </template>
          <template v-slot:body>
            <b-form @submit.prevent="onSaveContractType()">
              <b-form-group label="Name" label-for="alias">
                <b-form-input v-model="formContractType.name" id="name" type="text" placeholder="Type text" maxlength="30"></b-form-input>
              </b-form-group>
              <b-form-group label="Alias" label-for="alias">
                <b-form-input v-model="formContractType.rule" id="rule" type="text" placeholder="Type text" maxlength="30"></b-form-input>
              </b-form-group>
              <b-form-group label="Spal" label-for="is_spal">
                <b-form-checkbox v-model="formContractType.is_spal" name="check-button" switch inline>Active / Inactive
                </b-form-checkbox>
              </b-form-group>
              <b-form-group label="Status" label-for="status">
                <b-form-checkbox v-model="formContractType.status" name="check-button" switch inline>Active / Inactive
                </b-form-checkbox>
              </b-form-group>
              <hr />
              <b-form-group class="text-right">
                <b-button type="submit" variant="primary">
                  <span v-if="!contract_type_edit">Add</span>
                  <span v-else>Save Edit</span>
                </b-button>
                <b-button
                  type="button"
                  variant="danger"
                  class="iq-bg-danger ml-3"
                  @click="resetformContractType()"
                >Cancel</b-button>
              </b-form-group>
            </b-form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { busdevActions } from '@src/Utils/helper'
import _ from 'lodash'
export default {
  name: 'MasterContractType',
  data () {
    return {
      contractTypeList: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          name: '',
          // active: true,
          perPage: 30,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },

      loading_contract_type:false,
      saving_contract_type:false,
      delete_contract_type:false,

      formContractType: {
        "name": "",
        "alias": "",
        "is_spal": false,
        "status": true // 1 = active, 0 = inactive
      },

      contract_type_edit: null,
      contract_type_id: null
    }
  },
  async mounted () {
    await this.getContractType()
  },
  created () {
    this.getContractType = _.debounce(this.getContractType, 200)
  },
  methods: {
    ...busdevActions,
    async getContractType (page) {
      this.contractTypeList._loading = true
      this.contractTypeList.data = []
      let params = {
        ...this.contractTypeList.params,
        page: page || 1
      }
      if(!params.name) delete params.name 

      const res = await this.fetchContractType(params)
  
      if (res.data.status == true) {
        this.contractTypeList.data = res.data.data
      } else {
        this.contractTypeList._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
      this.contractTypeList._loading = false
    },

    async onSaveContractType () {
      this.saving_contract_type = true
      let payload = {
        ...this.formContractType,
        is_spal: this.formContractType.is_spal ? 1 : 0,
        status: this.formContractType.status ? 1 : 0
      }

      let res = null
      if(this.contract_type_edit && this.contract_type_id){
        res = await this.updateContractType({data: payload, id: this.contract_type_id})
      } else if (!this.contract_type_edit) {
        res = await this.createContractType({data: payload})
      }

      if (res.data.status === true) {
        this.$swal(`${this.contract_type_edit ? 'Edit' : "Add"} Contract Type Success!`, res.data.message, 'success')
        this.resetformContractType()
        this.getContractType()
        this.saving_contract_type = false
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
        this.saving_contract_type = false
      }
      this.saving_contract_type = false
    },

    resetformContractType () {
      this.formContractType = {
        "name": "",
        "alias": "",
        "is_spal" :false,
        "status": true // 1 = active, 0 = inactive
      }
      this.contract_type_edit = false
      this.contract_type_id = null
    },

    editFormContractType (data) {
      this.formContractType = {
        "name": data?.name,
        "alias": data?.alias,
        "is_spal": data?.is_spal ? true : false, // 1 = active, 0 = inactive
        "status": data?.status ? true : false // 1 = active, 0 = inactive
      }
      this.contract_type_edit = true
      this.contract_type_id = data.id
    },

    async onDeleteContractType (data) {
      this.$swal({
        title: 'Delete Contract Type?',
        text: `Contract Type ${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.saving_contract_type = true
          let res = await this.deleteContractType({id: data.id})
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
            this.saving_contract_type = false
          } else {
            this.$swal(
              `Contract Type deleted!`,
              `Contract Type ${data.name} successfully deleted`,
              'success'
            )
            await this.getContractType()
            this.saving_contract_type = false
          }
        }
      })
    },
  }
}
</script>

<style scoped>
</style>
