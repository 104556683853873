<template>
  <b-container fluid>
    <!-- Top Section !-->
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>OPERATIONAL</strong></h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col cols="8" md="8">
<!--                  <b-alert :show="true" variant=" " class="text-white bg-primary">
                  <div class="iq-alert-icon">
                    <i class="ri-alert-line"></i>
                  </div>
                  <div class="iq-alert-text">I'm Sorry <b>The Fleet</b> Not Available, Still Operational With TCP 101</div>
                </b-alert>
                <b-alert :show="true" variant=" " class="text-white bg-warning">
                  <div class="iq-alert-icon">
                    <i class="ri-alert-line"></i>
                  </div>
                  <div class="iq-alert-text">I'm Sorry <b>The Fleet</b> Not Available</div>
                </b-alert>-->

                <b-row>
                  <b-col md="12">
                    <h5 class="card-title text-primary mb-4"><strong>INPUT DAILY OPERATIONAL</strong></h5>
                    <hr>
                    <b-row>
                      <b-col md="6">
                        <b-form-group label="First Fleet *" label-for="first_fleet">
                          <h5><strong>{{ !isEdit ? fleet.name :  fleetEdit.name }}</strong></h5>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-row>
                          <b-col md="12" v-if="showVoyageWidth">
                            <b-form-group label="Voyage with" label-for="voyage_with">
                              <b-form-radio class="custom-radio-color-checked" inline v-model="formOperationalActivity.voyageActivityType" color="default" name="color" value="UNTOWING" :disabled="loadingCheck">Towing Free</b-form-radio>
                              <b-form-radio class="custom-radio-color-checked" inline v-model="formOperationalActivity.voyageActivityType" color="primary" name="color" value="SINGLE_TOWING" :disabled="loadingCheck">Single Towing</b-form-radio>
                              <b-form-radio class="custom-radio-color-checked" inline v-model="formOperationalActivity.voyageActivityType" color="primary" name="color" value="DOUBLE_TOWING" :disabled="loadingCheck">Double Towing</b-form-radio>
<!--                                <template v-for="(item,index) in action">
                                <b-form-radio class="custom-radio-color-checked" inline v-model="selectedAction" :color="item.color" name="color" :key="index" :value="item.value" :disabled="nextStep">{{ item.label }}</b-form-radio>
                              </template>-->
                            </b-form-group>
                          </b-col>
                          <b-col md="12" v-if="formOperationalActivity.voyageActivityType === 'SINGLE_TOWING' || formOperationalActivity.voyageActivityType === 'DOUBLE_TOWING'" >
                            <b-row class="mb-3">
                              <b-col md="7">
                                <label for="second_fleet">Second Fleet</label>
                              </b-col>
                              <b-col md="5" class="text-right">
                                <b-form-checkbox v-model="formOperationalActivity.vehicles[0].breakdown" name="check-button" switch inline :disabled="loadingCheck">
                                  Breakdown ?
                                </b-form-checkbox>
                              </b-col>
                              <b-col md="12">
                                <v-select v-model="formOperationalActivity.vehicles[0].vehicleId" label="name" :options="fleetListFiltered" :reduce="(fleetList) => fleetList.id" :disabled="loadingCheck" v-on:input="handleSelectFleet(fleetList, $event, 0)"></v-select>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col md="12" v-if="formOperationalActivity.voyageActivityType === 'DOUBLE_TOWING'" >
                            <b-row class="mb-3">
                              <b-col md="7">
                                <label for="another_fleet">Another Fleet</label>
                              </b-col>
                              <b-col md="5" class="text-right">
                                <b-form-checkbox v-model="formOperationalActivity.vehicles[1].breakdown" name="check-button" switch inline :disabled="loadingCheck">
                                  Breakdown ?
                                </b-form-checkbox>
                              </b-col>
                              <b-col md="12">
                                <v-select v-model="formOperationalActivity.vehicles[1].vehicleId" label="name" :options="fleetListFiltered" :reduce="(fleetList) => fleetList.id" :disabled="loadingCheck" v-on:input="handleSelectFleet(fleetList, $event, 1)"></v-select>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Start Date *" label-for="start_date">
                          <date-picker v-model="formOperationalActivity.startDate" :class="{ 'border-danger': $v.formOperationalActivity.startDate.$error }" type="datetime" placeholder="Select date and time" :disabled="loadingCheck"></date-picker>
                          <p><small v-if="$v.formOperationalActivity.startDate.$error  && !$v.formOperationalActivity.startDate.required " class="text-danger m-t-5">Start Date is Required</small></p>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="End Date *" label-for="end_date">
                          <date-picker v-model="formOperationalActivity.endDate" :class="{ 'border-danger': $v.formOperationalActivity.startDate.$error }" type="datetime" placeholder="Select date and time" :disabled="loadingCheck"></date-picker>
                          <p><small v-if="$v.formOperationalActivity.endDate.$error  && !$v.formOperationalActivity.endDate.required " class="text-danger m-t-5">End Date is Required</small></p>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" v-if="!isEdit">
                        <b-form-group label="Process" label-for="process">
                          <b-button block variant="success" v-if="!loadingCheck" class="mt-3" @click.prevent="checkVoyage"><i class="fa fa-map-pin"></i>Process</b-button>
                          <b-button block variant="light" v-else class="mt-3" @click.prevent="edit"><i class="fa fa-edit"></i>Edit</b-button>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <hr>
                  </b-col>
                  <template v-if="nextStep">
                    <b-col md="6">
                      <h6 class="card-title text-primary mb-4"><strong>PROCESS ACTIVITY</strong></h6>
                      <hr>
                      <b-form>
                        <b-form-group label="Project *" label-for="project">
                          <v-multiselect v-model="formOperationalActivity.subProjectId" :options="projectOptions" group-values="sub" group-label="project" placeholder="Select one" track-by="name" label="name"></v-multiselect>
                        <!-- <b-form-select plain v-model="project" :options="projectList" size="lg">
                            <template v-slot:first>
                              <b-form-select-option :value="null">Select Project</b-form-select-option>
                            </template>
                          </b-form-select>-->
                        </b-form-group>
                        <!-- <b-form-group label="Sub Project *" label-for="sub_project">
                          <b-form-select plain v-model="subProject" :options="subProjectList" size="lg">
                            <template v-slot:first>
                              <b-form-select-option :value="null">Select Sub Project</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>-->
                        <b-form-group label="Current Activity *" label-for="reason">
                          <v-select v-model="formOperationalActivity.reasonId" label="text" :options="reasonList" :reduce="reasonList => (reasonList.value+'|'+reasonList.text)" placeholder="Select Current Activity"></v-select>
                        </b-form-group>
                        <!--                          <b-form-row class="mb-3" v-if="reason === 'loading'">
                          <b-col>
                            <small>10 April 2021</small>
                          </b-col>
                          <b-col>
                            <small style="float: left;">Loading 1: 3.000 TON</small>
                          </b-col>
                        </b-form-row>
                        <b-form-row class="mb-3" v-if="reason === 'loading'">
                          <b-col>
                            <b-form-checkbox v-model="moreLoading" name="check-button" switch inline>
                              More Than 1 Loading
                            </b-form-checkbox>
                          </b-col>
                          <b-col v-if="moreLoading">
                            <b-form-checkbox v-model="endMoreLoading" name="check-button" switch inline>
                              End of Loading for Trip
                            </b-form-checkbox>
                          </b-col>
                        </b-form-row>-->
                        <b-form-row class="mb-3" v-if="formOperationalActivity.reasonId && formOperationalActivity.reasonId.split('|')[1] === 'Loading'">
                          <b-col :class="typeOfCargo === 'Others' ? 'col-md-6' : ''">
                            <b-form-group label="Cargo Type *" label-for="cargo_type">
                              <v-select v-model="typeOfCargo" label="text" :options="cargoOptions" :reduce="cargoOptions => cargoOptions.value" placeholder="Select Type of Cargo"></v-select>
                            </b-form-group>
                          </b-col>
                          <b-col v-if="typeOfCargo === 'Others'">
                            <b-form-group label="Other Cargo " label-for="other_cargo">
                              <b-form-input type="text" v-model="typeOfCargoOther"></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row class="mb-3" v-if="formOperationalActivity.reasonId && formOperationalActivity.reasonId.split('|')[1] === 'Loading'">
                          <b-col>
                            <b-form-group label="Cargo Loading *" label-for="cargo_loading">
                              <b-input-group size="md" append="Ton">
                                <b-form-input type="number" v-model="formOperationalActivity.cargoLoading"></b-form-input>
                              </b-input-group>
                              <small style="float: left"><i class="fa fa-warning"></i> Type Number</small>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row class="mb-3" v-if="formOperationalActivity.reasonId && formOperationalActivity.reasonId.split('|')[1]  === 'Unloading'">
                          <b-col>
                            <b-form-group label="Cargo Unloading *" label-for="cargo_unloading">
                              <b-input-group size="md" append="Ton">
                                <b-form-input type="number" v-model="formOperationalActivity.cargoUnloading"></b-form-input>
                              </b-input-group>
                              <small style="float: left"><i class="fa fa-warning"></i> Type Number</small>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group label="Is End Trip:" label-for="kind-fleet">
                                <b-form-checkbox v-model="formOperationalActivity.endOfTrip" name="check-button" switch inline :key="index">
                                   No / Yes
                                </b-form-checkbox>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-group label="Remarks" label-for="remarks">
                          <b-form-textarea id="description" rows="2" v-model="formOperationalActivity.remarks"></b-form-textarea>
                          <small style="float: left"><i class="fa fa-warning"></i> Max 500 Character</small>
                        </b-form-group>
                      </b-form>
                    </b-col>
                    <b-col md="6">
                      <h6 class="card-title text-primary mb-4"><strong>POSITION </strong></h6>
                      <hr>
                      <b-form-row>
                        <b-col :class="position === 'Others Position' ? 'col-md-6': ''">
                          <b-form-group label="Current Position" label-for="current_position">
                            <v-select v-model="position" label="text" :options="positionList" :reduce="positionList => positionList.text" placeholder="Select Position"></v-select>
                          </b-form-group>
                        </b-col>
                        <b-col v-if="position === 'Others Position'">
                          <b-form-group label="Other Position " label-for="other_position">
                            <b-form-input type="text" v-model="positionOther"></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-form-row>
                      <b-form-row>
                        <b-col cols="6" v-if="showDepartureDateTime">
                          <b-form-group label="Departure" label-for="departure">
                            <date-picker v-model="formOperationalActivity.departure" type="datetime" placeholder="Select date and time"></date-picker>
                          </b-form-group>
                        </b-col>
                        <b-col cols="6" v-if="showArrivalDateTime">
                          <b-form-group label="ETA / Arrival" label-for="eta">
                            <date-picker v-model="formOperationalActivity.arrival" type="datetime" placeholder="Select date and time"></date-picker>
                          </b-form-group>
                        </b-col>
                      </b-form-row>
                      <b-form-row v-if="showPortOfLoading || showPortOfDischarge">
                        <b-col md="6" v-if="showPortOfLoading">
                          <b-form-group label="Port of Loading" label-for="firts_fleet">
                            <v-select v-model="formOperationalActivity.portLoading" label="text" :options="positionList" :reduce="positionList => positionList.value" placeholder="Select Port of Loading"></v-select>
                          </b-form-group>
                          <b-form-group v-if="formOperationalActivity.portLoading === 'Others'" label="Other Position ">
                            <b-form-input type="text" v-model="formOperationalActivity.portLoadingOther"></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" v-if="showPortOfDischarge">
                          <b-form-group label="Port of Discharge" label-for="second_fleet">
                            <v-select v-model="formOperationalActivity.portDischarge" label="text" :options="positionList" :reduce="positionList => positionList.value" placeholder="Select Port of Discharge"></v-select>
                          </b-form-group>
                          <b-form-group v-if="formOperationalActivity.portDischarge === 'Others'" label="Other Position ">
                            <b-form-input type="text" v-model="formOperationalActivity.portDischargeOther"></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-form-row>
                      <b-form-row v-if="showPortAgentPol || showPortAgentPod">
                        <b-col v-if="showPortAgentPol">
                          <b-form-group label="Agent POL" label-for="firts_fleet">
                            <!-- <v-select v-model="agentPOL" label="text" :options="agentList" :reduce="agentList => agentList.value" placeholder="Select Agent POL"></v-select> -->
                            <b-form-input type="text" v-model="formOperationalActivity.agentPol"></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col v-if="showPortAgentPod">
                          <b-form-group label="Agent POD" label-for="second_fleet">
                            <b-form-input type="text" v-model="formOperationalActivity.agentPod"></b-form-input>
                            <!-- <v-select v-model="agentPOD" label="text" :options="agentList" :reduce="agentList => agentList.value" placeholder="Select Agent POD"></v-select> -->
                          </b-form-group>
                        </b-col>
                      </b-form-row>
                      <template v-if="showLatitude || showLongtitude">
                        <b-alert :show="true" variant="primary">
                          <h5>GPS DMS lat long</h5>
                        </b-alert>
                        <template v-if="showLatitude">
                          <b-alert :show="true" variant="light" class="text-center">
                            <strong>LATITUDE DMS</strong>
                          </b-alert>
                          <b-form-row class="mb-0">
                            <b-col>
                              <b-form-group label="Degrees " label-for="gps">
                                <b-input-group size="md" append="°">
                                  <b-form-input type="number" placeholder="Degrees" v-model="dmsLat.degrees"></b-form-input>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group label="Minutes" label-for="gps">
                                <b-input-group size="md" append="'">
                                  <b-form-input type="number" placeholder="Minutes" v-model="dmsLat.minutes"></b-form-input>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group label="Seconds " label-for="gps">
                                <b-input-group size="md" append='"'>
                                  <b-form-input type="number" placeholder="Seconds" v-model="dmsLat.seconds"></b-form-input>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group label="Directions" label-for="gps">
                                <b-form-select v-model="dmsLat.directions" size="md">
                                  <b-form-select-option>Please select an option</b-form-select-option>
                                  <b-form-select-option value="N">N</b-form-select-option>
                                  <b-form-select-option value="S">S</b-form-select-option>
                                </b-form-select>
                              </b-form-group>
                            </b-col>
                          </b-form-row>
                        </template>
                        <template v-if="showLongtitude">
                          <b-alert :show="true" variant="light" class="text-center">
                            <strong>LONGITUDE DMS</strong>
                          </b-alert>
                          <b-form-row class="mb-0">
                            <b-col>
                              <b-form-group label="Degrees " label-for="gps">
                                <b-input-group size="md" append="°">
                                  <b-form-input type="number" placeholder="Degrees" v-model="dmsLon.degrees"></b-form-input>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group label="Minutes" label-for="gps">
                                <b-input-group size="md" append="'">
                                  <b-form-input type="number" placeholder="Minutes" v-model="dmsLon.minutes"></b-form-input>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group label="Seconds " label-for="gps">
                                <b-input-group size="md" append='"'>
                                  <b-form-input type="number" placeholder="Seconds" v-model="dmsLon.seconds"></b-form-input>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group label="Directions" label-for="gps">
                                <b-form-select v-model="dmsLon.directions" size="md">
                                  <b-form-select-option>Please select an option</b-form-select-option>
                                  <b-form-select-option value="E">E</b-form-select-option>
                                  <b-form-select-option value="W">W</b-form-select-option>
                                </b-form-select>
                              </b-form-group>
                            </b-col>
                          </b-form-row>
                        </template>
                        <b-form-row class="mb-0">
                          <b-col v-if="showLatitude">
                            <b-form-group label="GPS Latitude" label-for="gps">
                              <b-form-input type="number" placeholder="latitude" v-model="formOperationalActivity.lat" disabled></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col v-if="showLongtitude">
                            <b-form-group label="GPS Longitude" label-for="gps">
                              <b-form-input type="number" placeholder="longitude" class="mt-2" v-model="formOperationalActivity.lon" disabled></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                      </template>
                      <b-form-row class="mb-0" v-if="showSpeed">
                        <b-col>
                          <b-form-group label="Speed " label-for="speed">
                            <b-input-group size="md" append="Knot">
                              <b-form-input type="number" v-model="formOperationalActivity.speed"></b-form-input>
                            </b-input-group>
                            <small style="float: left"><i class="fa fa-warning"></i> Type Number</small>
                          </b-form-group>
                        </b-col>
                      </b-form-row>
                      <b-form-row v-if="showDocumentOnBoard">
                        <b-col>
                          <b-form-group label="Document On Board" label-for="document_on_board">
                            <!--<b-form-input type="datetime-local" id="end_date" v-model="formOperationalActivity.docOnBoard"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>-->
                            <date-picker v-model="formOperationalActivity.docOnBoard" type="datetime" placeholder="Select date and time"></date-picker>
                          </b-form-group>
                        </b-col>
                      </b-form-row>
                      <b-form-row v-if="showPIC">
                        <b-col>
                          <b-form-group label="PIC / PE " label-for="pic">
                             <v-select v-model="picSelected" label="text" :options="picList" :reduce="picList => picList.value" placeholder="Select PIC" :multiple="true"></v-select>
                            <!-- <v-multiselect v-model="picSelected" placeholder="Choose PIC" label="name" track-by="value" :options="picList" :reduce="picList => picList.value" :multiple="true">
                              <span slot="noResult">Category not found.</span>
                            </v-multiselect> -->
                            <!-- <b-form-input type="text" value="Auto Suggest Based on Login"> </b-form-input> -->
                          </b-form-group>
                        </b-col>
                      </b-form-row>
                      <b-card-text><small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small></b-card-text>
                    </b-col>
                    <b-col md="12" class="text-right mt-3">
                      <b-button type="submit" variant="primary" @click="saveActivity()"><i class="fa fa-save"></i> Save</b-button>
                      <b-button v-if="!isEdit" type="submit" variant="none" class="iq-bg-danger ml-3" @click="initActivityForm()"><i class="fa fa-close"></i> Reset Form</b-button>
                      <b-button v-if="isEdit" type="submit" variant="none" class="iq-bg-danger ml-3" @click="cancelEdit()"><i class="fa fa-close"></i> Cancel Edit</b-button>
                    </b-col>
                  </template>
                </b-row>
              </b-col>
              <!-- Last Operational -->
              <b-col cols="4" md="4">
                <h5 class="card-title text-primary mb-4"><strong>LAST OPERATIONAL</strong></h5>
                <ul class="iq-timeline m-0 p-0" style="height:600px">
                  <li v-for="each in activityList" :key="each.id">
                    <b-row class="m-0">
                      <b-col md="10">
                        <div class="timeline-dots-fill"></div>
                        <h6 class="float-left mb-2 text-dark"><i class="ri-time-fill"></i> {{each.startDate +' - '+ each.endDate}}</h6>
                        <div class="d-inline-block w-100">
                          <!-- <b-badge variant="danger">Market : 2 Hours | 42 Minutes</b-badge> -->
                          <b-badge variant="info">{{each.activityDurationFormat}}</b-badge>
                          <p>{{each.activityName}}</p>
                        </div>
                      </b-col>
                      <b-col md="2">
                        <b-dropdown variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                          <template v-slot:button-content>
                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                            <i class="fa fa-caret-down m-0 text-primary"></i>
                            </span>
                          </template>
                          <b-dropdown-item href="#" class="m-0 p-0" @click="getActivityDetail(each.id, true)"><i class="fa fa-pencil-square-o mr-2"></i>Edit</b-dropdown-item>
                          <b-dropdown-item href="#" class="m-0 p-0" @click="onDeleteActivity(each)"><i class="fa fa-trash mr-2"></i>Delete</b-dropdown-item>
                        </b-dropdown>
                      </b-col>
                    </b-row>
                  </li>
                </ul>
              </b-col>
              <!-- End Last Operational -->
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Top Section !-->
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { voyagesActions, fleetsActions, projectsActions, reasonsActions, portsActions, usersActions } from '@src/Utils/helper'
import { required, requiredIf, sameAs, minLength, minValue } from 'vuelidate/lib/validators'
import moment from 'moment'
import { singleConvertDMSToDD } from '@src/plugins/helpers'

var date = new Date()

export default {
name: 'Operational',
data () {
  return {
    // Connect with API
    isEdit: false,
    isActivityId: '',
    fleet: {
      vehicleType: {
        name: ''
      }
    },
    fleetEdit: null,
    activityList: [],
    fleetList: [],
    dmsLat: {
      degrees: '',
      minutes: '',
      seconds: '',
      directions: 'S'
    },
    dmsLon: {
      degrees: '',
      minutes: '',
      seconds: '',
      directions: 'E'
    },
    formOperationalActivity: {
      startDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
      endDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
      vehicles: [
        {
          vehicleId: '',
          vehicleNumber: 1,
          breakdown: false
        },
        {
          vehicleId: '',
          vehicleNumber: 2,
          breakdown: false
        }
      ],
      voyageActivityType: 'UNTOWING',
      subProjectId: '',
      reasonId: '',
      cargoType: '',
      cargoLoading: '',
      cargoUnloading: '',
      remarks: '',
      currentPosition: '',
      arrival: '',
      departure: '',
      portLoading: '',
      portLoadingOther: '',
      portDischarge: '',
      portDischargeOther: '',
      agentPol: '',
      agentPod: '',
      dmsLat: '',
      dmsLon: '',
      lat: '',
      lon: '',
      speed: '',
      docOnBoard: '',
      pic: [],
      endOfTrip: true
    },
    loadingForm: false,
    loadingCheck: false,
    // End Connect
    nextStep: false,
    secondFleet: '7',
    anotherFleet: '3',
    startDate: '2021-04-13T13:45:00',
    endDate: '',
    reason: null,
    moreLoading: true,
    breakdown: false,
    breakdownAnother: false,
    endMoreLoading: true,
    selectedAction: 'UNTOWING',
    action: [
      {
        label: 'Not Towing',
        color: 'default',
        value: 'UNTOWING'
      },
      {
        label: 'Towing Tug/Barge',
        color: 'primary',
        value: 'SINGLE_TOWING'
      },
      {
        label: 'Double Towing',
        color: 'warning',
        value: 'DOUBLE_TOWING'
      }
    ],
    projectValue: '',
    projectOptions: [
    ],
    project: 2,
    projectList: [
      { value: '1', text: 'Arutmin Project' },
      { value: '2', text: 'KPC Project' },
      { value: '3', text: 'AI Project' },
      { value: '5', text: 'Virtue Dragon Nickel Industry Project' },
      { value: '6', text: 'Pracadi Project' },
      { value: '7', text: 'Conch Project' },
      { value: '8', text: 'Tsingshan Steel Indonesia Project' }
    ],
    subProject: 1,
    subProjectList: [
      { value: '1', text: 'Transhipment' },
      { value: '2', text: 'Long Hauling' },
      { value: '3', text: 'Armada Oil Barge' },
      { value: '4', text: 'Finance' },
      { value: '5', text: 'Loading' },
      { value: '6', text: 'Unloading' }
    ],
    position: '',
    positionOther: '',
    positionList: [
      { value: 'Others', text: 'Others Position' }
    ],
    reasonList: [],
    portLoading: '',
    portDischarge: '',
    portList: [
      { value: 'Asam-Asam', text: 'Asam-Asam' },
      { value: 'Sangatta', text: 'Sangatta' },
      { value: 'Paiton', text: 'Paiton' },
      { value: 'Bengalon', text: 'Bengalon' },
      { value: 'Tanjung Bara', text: 'Tanjung Bara' },
      { value: 'Tanjung Awar-Awar', text: 'Tanjung Awar-Awar' },
      { value: 'Rembang', text: 'Rembang' }
    ],

    picSelected: null,
    picList: [],
    agentPOL: 'TCP',
    agentPOD: 'SAJ',
    agentList: [
      { value: 'TCP', text: 'TCP' },
      { value: 'SAJ', text: 'SAJ' },
      { value: 'ANTAM', text: 'ANTAM' }
    ],
    typeOfCargo: 'COAL',
    typeOfCargoOther: '',
    cargoOptions: [
      { value: 'COAL', text: 'COAL' },
      { value: 'FUEL', text: 'FUEL' },
      { value: 'OIL', text: 'OIL' },
      { value: 'NICKEL', text: 'NICKEL' },
      { value: 'CRANE-CONTAINER', text: 'CRANE CONTAINER' },
      { value: 'Others', text: 'Others...' }
    ]
  }
},
computed: {
  vehicleId () {
    const { vehicleId } = this.$route.query

    if (!vehicleId) {
      return null
    }

    return vehicleId
  },
  vehicleIdParam () {
    return this.$route.query.vehicleId
      ? this.$route.query.vehicleId
      : ''
  },
  combineDNSLat () {
    return `${this.dmsLat.degrees}° ${this.dmsLat.minutes}' ${this.dmsLat.seconds}" ${this.dmsLat.directions}`
  },
  combineDNSLng () {
    return `${this.dmsLon.degrees}° ${this.dmsLon.minutes}' ${this.dmsLon.seconds}" ${this.dmsLon.directions}`
  },
  fleetListFiltered () {
    let currentFleetId = !this.isEdit ? this.fleet.id : this.fleetEdit.id
    return this.fleetList.filter(fleet => parseInt(fleet.id) !== parseInt(currentFleetId))
  },
  // field visibilities
  showVoyageWidth () {
    return ![3, 6].includes(this.fleet?.vehicleType?.id)
  },
  showDepartureDateTime () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showArrivalDateTime () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showPortOfLoading () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showPortOfDischarge () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showPortAgentPol () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showPortAgentPod () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showLatitude () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showLongtitude () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showSpeed () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showDocumentOnBoard () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showPIC () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  }
},
validations () {
  let ret = {
    formOperationalActivity: {
      startDate: { required },
      endDate: { required }
    }
  }

  return ret
  /* if (this.formOperationalActivity.voyageActivityType == 'TOWING' || this.formOperationalActivity.voyageActivityType == 'DOUBLE-TOWING'){
    return {
      formOperationalActivity: {
        startDate: { required },
        endDate: { required }
      }
    }
  } else {
    return {
      formOperationalActivity: {
        startDate: { required },
        endDate: { required },
      }
    }
  } */
},
async mounted () {
  xray.index()
  await this.fetchFleet()
  await this.listFleet()
  await this.getProjectList()
  await this.getReasonList()
  await this.getPortList()
  await this.getUserList()
  await this.getLastActivity()
  if (this.$route.query.activityEditId) {
    this.getActivityDetail(this.$route.query.activityEditId, true)
  } else {
    this.initActivityForm()
  }
},
watch: {
  'dmsLat': {
    handler () {
      this.generateGPSLat()
    },
    deep: true
  },
  'dmsLon': {
    handler () {
      this.generateGPSLng()
    },
    deep: true
  },
  endDate: function () {
    if (this.endDate <= this.startDate) {
      this.$swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'End Date tidak boleh kurang dari Start Date!'
      }).then((result) => {
        this.endDate = ''
      })
    }
  }
},
methods: {
  ...voyagesActions,
  ...fleetsActions,
  ...projectsActions,
  ...reasonsActions,
  ...portsActions,
  ...usersActions,
  async fetchFleet () {
    this.loadingForm = true
    const res = await this.getFleetById(this.vehicleId)

    if (res.status == 'success') {
      this.fleet = res.data
      console.log('fleet', this.fleet)
    } else {
      this.fleet = {}
      this.$swal(
        `Oops!`,
        res.data,
        'error'
      )
    }
    this.loadingForm = false
  },
  async listFleet () {
    if (this.fleet.vehicleType.name == 'Tugboat') {
      let params = {
        vehicleTypeId: [1, 2],
        page: 1,
        active: true,
        showAll: true
      }
      const res = await this.getFleets(params)
      if (res.status == 'success') {
        this.fleetList = res.data
        console.log('fleetList', this.fleet)
      } else {
        this.fleetList = {}
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    } else if (this.fleet.vehicleType.name == 'Pusher Tug') {
      let params = {
        vehicleTypeId: [21, 22],
        page: 1,
        active: true,
        showAll: true
      }
      const res = await this.getFleets(params)
      if (res.status == 'success') {
        this.fleetList = res.data
        console.log('fleetList', this.fleet)
      } else {
        this.fleetList = {}
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    } else {
      let params = {
        page: 1,
        active: true,
        showAll: true
      }
      const res = await this.getFleets(params)
      if (res.status == 'success') {
        this.fleetList = res.data
        console.log('fleetList', this.fleet)
      } else {
        this.fleetList = {}
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    }
    console.log('listFleet', this.fleetList)
  },
  async getProjectList () {
    let params = {
      active: true,
      search: '',
      page: 1,
      perPage: 250
    }
    const res = await this.getProjects(params)
    if (res.status == 'success') {
      if (res.data.data && res.data.data.length > 0) {
        res.data.data.forEach(val => {
          this.projectOptions.push({
            project: val.projectName,
            sub: val.subProject
          })
        })
      }
    } else {
      this.$swal(
        `Oops!`,
        res.data.data,
        'error'
      )
    }
  },
  async getReasonList () {
    let params = {
      search: '',
      vehicleTypeId: this.fleet.vehicleType.id,
      page: 1,
      perPage: 250
    }
    const res = await this.getReasons(params)
    if (res.status == 'success') {
      if (res.data.data && res.data.data.length > 0) {
        res.data.data.forEach(val => {
          if(val.active === true){
            this.reasonList.push({ value: val.id, text: val.name })
          }
        })
      }
    } else {
      this.$swal(
        `Oops!`,
        res.data.data,
        'error'
      )
    }
  },
  async getPortList () {
    let params = {
      active: true,
      search: '',
      page: 1,
      perPage: 250
    }
    const res = await this.getPorts(params)
    if (res.status == 'success') {
      if (res.data.data && res.data.data.length > 0) {
        res.data.data.forEach(val => {
          this.positionList.push({ value: val.id, text: val.portName })
        })
      }
    } else {
      this.$swal(
        `Oops!`,
        res.data.data,
        'error'
      )
    }
  },
  async getUserList () {
    let params = {
      active: true,
      page: 1,
      perPage: 1000
    }
    const res = await this.getUsers(params)
    if (res.data.status == 'success' || res.data.status == true) {
      if (res.data.data && res.data.data.length > 0) {
        res.data.data.forEach(val => {
          this.picList.push({ value: val.id, text: val.userDetail.name })
        })
        // const findIdx = this.picList.findIndex(val => val.value === this.fleet.id)
        // if (findIdx !== -1) this.picSelected = this.fleet.id
      }
    } else {
      this.$swal(
        `Oops!`,
        res.message,
        'error'
      )
    }
  },
  async getLastActivity () {
    this.activityList = []
    const vehicleId = this.$route.query.vehicleId
    let params = {
      page: 1
    }
    const payload = {
      vehicleId: vehicleId,
      params
    }
    const res = await this.getVoyageLastActivity(payload)
    if (res.status == 'success') {
      if (res.data && res.data.length > 0) {
        this.activityList = res.data
      }
    } else {
      this.$swal(
        `Oops!`,
        res.data,
        'error'
      )
    }
  },

  async getActivityDetail (activityId, isEdit = false) {
    const vehicleId = this.$route.query.vehicleId
    const res = await this.getActivityById({ vehicleId, id: activityId })
    if (res.status === 'success') {
      if (isEdit) {
        this.isActivityId = res.data.id
        this.isEdit = true
      }
      this.initActivityForm(1, res.data)
      this.nextStep = true
      this.loadingCheck = false
    } else {
      this.$swal(
        `Oops!`,
        res.data,
        'error'
      )
    }
  },
  async checkVoyage () {
    let self = this
    const vehicleId = this.$route.query.vehicleId
    this.$v.formOperationalActivity.$touch()

    if (this.formOperationalActivity.endDate <= this.formOperationalActivity.startDate) {
      this.$swal(
        'Error',
        'End Date harus lebih besar dari Start Date',
        'error'
      )
      return
    }
    if (!this.$v.formOperationalActivity.$invalid) {
      this.loadingCheck = true
      let param = {
        'startDate': moment(this.formOperationalActivity.startDate).format('DD-MM-YYYY HH:mm'),
        'endDate': moment(this.formOperationalActivity.endDate).format('DD-MM-YYYY HH:mm'),
        'vehicles': [
          {
            'vehicleId': this.formOperationalActivity.vehicles[0].vehicleId,
            'vehicleNumber': 1,
            'breakdown': this.formOperationalActivity.vehicles[0].breakdown
          },
          {
            'vehicleId': this.formOperationalActivity.vehicles[1].vehicleId,
            'vehicleNumber': 2,
            'breakdown': this.formOperationalActivity.vehicles[1].breakdown
          }
        ]
      }

      if (this.formOperationalActivity.voyageActivityType === 'UNTOWING') {
        delete param.vehicles
      } else if (this.formOperationalActivity.voyageActivityType === 'SINGLE_TOWING') {
        param.vehicles.splice(1, 1)
      }

      const vehicleId = this.$route.query.vehicleId
      let res = await self.checkVehicleVoyage({ vehicleId, data: param })
      if (res.status === 'success') {
        this.$swal('Available!', res.data.message, 'success')
        if (this.activityList[0]) {
          this.getActivityDetail(this.activityList[0].id)
        }
        this.nextStep = true
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
          this.loadingCheck = false
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
          this.loadingCheck = false
        }
      }
    }
  },
  process () {
    let timerInterval
    this.$swal.fire({
      title: 'Check Available!',
      timer: 500,
      timerProgressBar: true,
      didOpen: () => {
        this.$swal.showLoading()
        timerInterval = setInterval(() => {
          const content = this.$swal.getContent()
          if (content) {
            const b = content.querySelector('b')
            if (b) {
              b.textContent = this.$swal.getTimerLeft()
            }
          }
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      this.nextStep = true
    })
  },
  edit () {
    this.nextStep = false
    this.loadingCheck = false
    this.endDate = ''
  },
  handleSelectFleet (data, e, idx) {
    let findIdx = data.findIndex(val => val.id === e)
    this.formOperationalActivity.vehicles[idx].breakdown = false
    if (findIdx !== -1) {
      if (data[findIdx].vehicleType.id === this.fleet.vehicleType.id) {
        this.formOperationalActivity.vehicles[idx].breakdown = true
      }
    }
  },
  initActivityForm (type, data) {
    if (type) {
      var date = new Date()
      if (this.isEdit) {
        this.formOperationalActivity.startDate = data ? new Date(data.startDate.split(' ')[0].split('-')[2], (data.startDate.split(' ')[0].split('-')[1] - 1), data.startDate.split(' ')[0].split('-')[0], data.startDate.split(' ')[1].split(':')[0], data.startDate.split(' ')[1].split(':')[1]) : new Date(date.getFullYear(), date.getMonth(), date.getDate())
        this.formOperationalActivity.endDate = data ? new Date(data.endDate.split(' ')[0].split('-')[2], (data.endDate.split(' ')[0].split('-')[1] - 1), data.endDate.split(' ')[0].split('-')[0], data.endDate.split(' ')[1].split(':')[0], data.endDate.split(' ')[1].split(':')[1]) : new Date(date.getFullYear(), date.getMonth(), date.getDate())
      }
      this.formOperationalActivity.vehicles = [
        {
          vehicleId: data && (data.voyageType === 'SINGLE_TOWING' || data.voyageType === 'DOUBLE_TOWING') ? data.vehicle[1].id : '',
          vehicleNumber: data && (data.voyageType === 'SINGLE_TOWING' || data.voyageType === 'DOUBLE_TOWING') ? data.vehicle[1].vehicleNumber : 1,
          breakdown: data && (data.voyageType === 'SINGLE_TOWING' || data.voyageType === 'DOUBLE_TOWING') ? data.vehicle[1].breakdown : false
        },
        {
          vehicleId: data && data.voyageType === 'DOUBLE_TOWING' ? data.vehicle[2].id : '',
          vehicleNumber: data && data.voyageType === 'DOUBLE_TOWING' ? data.vehicle[2].vehicleNumber : 2,
          breakdown: data && data.voyageType === 'DOUBLE_TOWING' ? data.vehicle[2].breakdown : false
        }
      ]
      this.formOperationalActivity.voyageActivityType = data ? data.voyageType : 'UNTOWING'
      this.fleetEdit = data ? data.vehicle[0] : null
    }
    this.formOperationalActivity.subProjectId = data ? { id: data.subProject.id, name: data.subProject.name } : ''
    if (this.isEdit) {
      this.formOperationalActivity.reasonId = data ? `${data.reason.id}|${data.reason.name}` : ''
      this.formOperationalActivity.cargoType = ''
      this.formOperationalActivity.cargoLoading = data && data.reason.name === 'Loading' ? data.cargoLoading : ''
    }
    this.formOperationalActivity.cargoUnloading = data && data.reason.name === 'Unloading' ? data.cargoUnloading : ''
    this.formOperationalActivity.remarks = data ? data.remark : ''
    this.formOperationalActivity.currentPosition = ''
    this.formOperationalActivity.arrival = data && data.arrival ? new Date(data.arrival.split(' ')[0].split('-')[2], (data.arrival.split(' ')[0].split('-')[1] - 1), data.arrival.split(' ')[0].split('-')[0], data.arrival.split(' ')[1].split(':')[0], data.arrival.split(' ')[1].split(':')[1]) : ''
    this.formOperationalActivity.departure = data && data.departure ? new Date(data.departure.split(' ')[0].split('-')[2], (data.departure.split(' ')[0].split('-')[1] - 1), data.departure.split(' ')[0].split('-')[0], data.departure.split(' ')[1].split(':')[0], data.departure.split(' ')[1].split(':')[1]) : ''
    this.formOperationalActivity.portLoading = data ? Number(data.portLoading.id) : ''
    this.formOperationalActivity.portDischarge = data ? Number(data.portDischarge.id) : ''
    this.formOperationalActivity.agentPol = data ? data.agentPol : ''
    this.formOperationalActivity.agentPod = data ? data.agentPod : ''
    this.formOperationalActivity.lat = data ? (data.lat ? data.lat : '') : ''
    this.formOperationalActivity.lon = data ? (data.lon ? data.lon : '') : ''
    this.formOperationalActivity.speed = data ? data.speed : ''
    this.formOperationalActivity.docOnBoard = data && data.docOnBoard ? new Date(data.docOnBoard.split(' ')[0].split('-')[2], (data.docOnBoard.split(' ')[0].split('-')[1] - 1), data.docOnBoard.split(' ')[0].split('-')[0], data.docOnBoard.split(' ')[1].split(':')[0], data.docOnBoard.split(' ')[1].split(':')[1]) : ''
    this.formOperationalActivity.pic = []
    if (this.isEdit) {
      this.typeOfCargo = data ? (data.reason.name === 'Loading' && this.cargoOptions.findIndex(val => val.value === data.cargoType) !== -1 ? data.cargoType : 'Others') : ''
      this.typeOfCargoOther = data && data.reason.name === 'Loading' && this.typeOfCargo === 'Others' ? data.cargoType : ''
    }
    this.position = data ? (this.positionList.findIndex(val => val.text === data.currentPosition) !== -1 ? data.currentPosition : 'Others Position') : ''
    this.positionOther = data ? (this.position === 'Others Position' ? data.currentPosition : '') : ''
    this.picSelected = data.pic ? data.pic.map(pic => pic.id) : []

    this.dmsLat.degrees = data ? data.dmsLatitude.split(' ')[0]?.substr(0, data.dmsLatitude.split(' ')[0].length - 1) : ''
    this.dmsLat.minutes = data ? data.dmsLatitude.split(' ')[1]?.substr(0, data.dmsLatitude.split(' ')[1].length - 1) : ''
    this.dmsLat.seconds = data ? data.dmsLatitude.split(' ')[2]?.substr(0, data.dmsLatitude.split(' ')[2].length - 1) : ''
    this.dmsLat.directions = data ? data.dmsLatitude.split(' ')[3] : ''

    this.dmsLon.degrees = data ? data.dmsLongitude.split(' ')[0]?.substr(0, data.dmsLongitude.split(' ')[0].length - 1) : ''
    this.dmsLon.minutes = data ? data.dmsLongitude.split(' ')[1]?.substr(0, data.dmsLongitude.split(' ')[1].length - 1) : ''
    this.dmsLon.seconds = data ? data.dmsLongitude.split(' ')[2]?.substr(0, data.dmsLongitude.split(' ')[2].length - 1) : ''
    this.dmsLon.directions = data ? data.dmsLongitude.split(' ')[3] : ''

    /* if (this.formOperationalActivity.arrival) {
      this.formOperationalActivity.arrival = moment(this.formOperationalActivity.arrival).format('YYYY-MM-DDTHH:mm')
    } */
    /* if (this.formOperationalActivity.docOnBoard) {
      this.formOperationalActivity.docOnBoard = moment(this.formOperationalActivity.docOnBoard).format('YYYY-MM-DDTHH:mm')
    } */
  },
  generateGPSLat () {
    if (this.dmsLat.degrees && this.dmsLat.minutes && this.dmsLat.seconds) {
      this.formOperationalActivity.lat = singleConvertDMSToDD(this.combineDNSLat)
    }
  },
  generateGPSLng () {
    if (this.dmsLon.degrees && this.dmsLon.minutes && this.dmsLon.seconds) {
      this.formOperationalActivity.lon = singleConvertDMSToDD(this.combineDNSLng)
    }
  },
  async saveActivity () {
    let self = this
    const payload = {
      'startDate': this.formOperationalActivity.startDate ? moment(this.formOperationalActivity.startDate).format('DD-MM-YYYY HH:mm') : null,
      'endDate': this.formOperationalActivity.endDate ? moment(this.formOperationalActivity.endDate).format('DD-MM-YYYY HH:mm') : null,
      'vehicles': [
        {
          'vehicleId': this.formOperationalActivity.vehicles[0].vehicleId,
          'vehicleNumber': 1,
          'breakdown': this.formOperationalActivity.vehicles[0].breakdown
        },
        {
          'vehicleId': this.formOperationalActivity.vehicles[1].vehicleId,
          'vehicleNumber': 2,
          'breakdown': this.formOperationalActivity.vehicles[1].breakdown
        }
      ],
      'voyageActivityType': this.formOperationalActivity.voyageActivityType,
      'subProjectId': this.formOperationalActivity.subProjectId.id,
      'reasonId': Number(this.formOperationalActivity.reasonId.split('|')[0]),
      'cargoType': this.formOperationalActivity.reasonId.split('|')[1] === 'Loading' ? (this.typeOfCargo === 'Others' ? this.typeOfCargoOther : this.typeOfCargo) : null,
      'cargoLoading': this.formOperationalActivity.reasonId.split('|')[1] === 'Loading' ? Number(this.formOperationalActivity.cargoLoading) : null,
      'cargoUnloading': this.formOperationalActivity.reasonId.split('|')[1] === 'Unloading' ? Number(this.formOperationalActivity.cargoUnloading) : null,
      'remarks': this.formOperationalActivity.remarks,
      'currentPosition': this.position === 'Others Position' ? this.positionOther : this.position,
      'arrival': this.formOperationalActivity.arrival ? moment(this.formOperationalActivity.arrival).format('DD-MM-YYYY HH:mm') : null,
      'departure': this.formOperationalActivity.departure ? moment(this.formOperationalActivity.departure).format('DD-MM-YYYY HH:mm') : null,
      'portLoading': this.formOperationalActivity.portLoading === 'Others' ? this.formOperationalActivity.portLoadingOther : this.formOperationalActivity.portLoading,
      'portDischarge': this.formOperationalActivity.portDischarge === 'Others' ? this.formOperationalActivity.portDischargeOther : this.formOperationalActivity.portDischarge,
      'agentPol': this.formOperationalActivity.agentPol,
      'agentPod': this.formOperationalActivity.agentPod,
      'lat': Number(this.formOperationalActivity.lat),
      'lon': Number(this.formOperationalActivity.lon),
      'speed': Number(this.formOperationalActivity.speed),
      'docOnBoard': this.formOperationalActivity.docOnBoard ? moment(this.formOperationalActivity.docOnBoard).format('DD-MM-YYYY HH:mm') : null,
      'pic': this.picSelected,
      'endOfTrip': this.formOperationalActivity.endOfTrip,
      'dmsLatitude': this.combineDNSLat,
      'dmsLongitude': this.combineDNSLng
    }

    // 'endOfTrip': this.formOperationalActivity.reasonId.split('|')[1] === 'Unloading' ? this.formOperationalActivity.endOfTrip : true,

    if (this.formOperationalActivity.voyageActivityType === 'UNTOWING') {
      delete payload.vehicles
    } else if (this.formOperationalActivity.voyageActivityType === 'SINGLE_TOWING') {
      payload.vehicles.splice(1, 1)
    }

    const id = this.$route.query.vehicleId
    this.$swal.fire({
      title: 'Processing...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        swal.showLoading()
      }
    })

    if (!this.isEdit) {
      let res = await self.createVoyageActivity({ id, data: payload })
      if (res.status === 'success') {
        this.$swal('Add Activity Success!', res.data.message, 'success')
        setTimeout(() => {
          this.$router.go(-1)
        }, 2000)
        this.initActivityForm(1)
        this.nextStep = false
        this.loadingCheck = true
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
          this.loadingCheck = false
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
          this.loadingCheck = false
        }
      }
    } else {
      let res = await self.updateActivity({ id: this.isActivityId, vehicleId: this.fleetEdit.id, data: payload })
      if (res.status == 'success') {
        this.$swal('Update Activity Success!', res.data.message, 'success')
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000)
        this.cancelEdit()
        this.getLastActivity()
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    }
  },
  async onDeleteActivity (activityData) {
    let activity = activityData

    this.$swal({
      title: 'Delete Activity?',
      text: `Activity ${activity.activityName} will be deleted and cannot be recovered.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const vehicleId = this.$route.query.vehicleId
        let res = await this.deleteActivityById({ vehicleId, id: activity.id })
        if (res.status != 'success') {
          if (res.data.message != null) {
            this.$swal('Error', res.data.message, 'error')
          } else {
            this.$swal(
              'Error',
              'Something wrong with the server. Cannot get user data.',
              'error'
            )
          }
        } else {
          this.getLastActivity()
          this.$swal(
            `Activity deleted!`,
            `Activity ${activity.activityName} successfully deleted`,
            'success'
          )
        }
      }
    })
  },
  cancelEdit () {
    this.isEdit = false
    this.nextStep = false
    this.loadingCheck = false
    this.initActivityForm(1)
  }
}
}
</script>
