var render = function render(){
  var _vm$maintenanceDetail, _vm$maintenanceDetail2, _vm$maintenanceDetail5, _vm$maintenanceDetail6, _vm$maintenanceDetail7, _vm$maintenanceDetail8, _vm$maintenanceDetail11, _vm$maintenanceDetail12, _vm$maintenanceDetail15, _vm$maintenanceDetail16, _vm$maintenanceDetail17, _vm$maintenanceDetail18, _vm$maintenanceDetail19, _vm$maintenanceDetail20, _vm$maintenanceDetail21, _vm$maintenanceDetail22, _vm$maintenanceDetail23, _vm$maintenanceDetail24, _vm$maintenanceDetail25, _vm$maintenanceDetail26, _vm$maintenanceDetail27, _vm$maintenanceDetail28, _vm$maintenanceDetail29, _vm$maintenanceDetail30, _vm$maintenanceDetail31, _vm$maintenanceDetail32, _vm$maintenanceDetail33, _vm$maintenanceDetail34, _vm$maintenanceDetail35, _vm$maintenanceDetail36, _vm$maintenanceDetail37, _vm$maintenanceDetail38, _vm$maintenanceDetail39;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "view-maintenance-detail"
  }, [_vm.maintenanceDetailData ? _c('b-row', {
    staticClass: "pt-2 pl-3 pr-3 pb-2 mb-4"
  }, [_vm.stateAction === 'VIEW_DETAIL' ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12"
    }
  }, [['MAINTENANCE'].includes(_vm.maintenanceDetailData.status) && _vm.canReview ? _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "info",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onAction('ON_REVIEW');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-eye"
  }), _vm._v(" Review ")]) : _vm._e(), ['REVIEW'].includes(_vm.maintenanceDetailData.status) && _vm.canApprove ? _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "success",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onAction('ON_APPROVE');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-check"
  }), _vm._v(" Approve ")]) : _vm._e(), ['TOWARDS', 'OVERDUE'].includes(_vm.maintenanceDetailData.status) && _vm.canAdd ? _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onAction('ON_MAINTENANCE');
      }
    }
  }, [_c('i', {
    staticClass: "las la-tasks"
  }), _vm._v("Maintenance ")]) : _vm._e(), ['TOWARDS', 'OVERDUE'].includes(_vm.maintenanceDetailData.status) ? _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "warning",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onAction('ON_POSTPONE');
      }
    }
  }, [_c('i', {
    staticClass: "las la-calendar"
  }), _vm._v(" Postpone ")]) : _vm._e()], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2"
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.vehicle.vehicleType.name) + " - " + _vm._s(_vm.maintenanceDetailData.vehicle.name))]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-0 table-striped"
  }, [_c('tbody', _vm._l(_vm.maintenanceDetailData.vehicle.powers, function (engine, engineIndex) {
    return _c('tr', {
      key: engineIndex
    }, [_c('th', [_vm._v(_vm._s(engine.powerType.name) + " - " + _vm._s(engine.powerSeries.name))]), _c('td', {
      staticClass: "text-right"
    }, [_vm._v("Actual RH : "), _c('strong', [_vm._v(_vm._s(engine.actualRunningHours) + " RH")])])]);
  }), 0)])]), _c('b-col', {
    staticClass: "mb-3 mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('hr')]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('v-jstree', {
    attrs: {
      "data": _vm.treeInitMaintenance,
      "show-checkbox": "",
      "allow-batch": "",
      "whole-row": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_) {
        return [_c('div', {
          staticStyle: {
            "display": "inherit",
            "width": "200px"
          }
        }, [!_.model.loading ? _c('i', {
          class: _.vm.themeIconClasses,
          attrs: {
            "role": "presentation"
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(_.model.text) + " ")])];
      }
    }], null, false, 4262975071)
  })], 1), _c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2"
  }, [_vm._v("Maintenance Info")]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Job Code")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.jobCode))]), _c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Job Class")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s("".concat(_vm.maintenanceDetailData.jobClass.jobClassCode, " - ").concat(_vm.maintenanceDetailData.jobClass.jobClass)))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Job Type")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.jobType.jobTypeCode) + " " + _vm._s(_vm.maintenanceDetailData.jobType.description))]), _c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Job Name")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.jobName))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Type - Serial No")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.maintenanceMaster.equipmentId) + " - " + _vm._s(_vm.maintenanceDetailData.maintenanceMaster.equipmentName))]), _c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Interval/Frequency")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _vm.maintenanceDetailData.trigger === 'CALENDAR' ? _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.interval) + " MONTH")]) : _vm._e(), _vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.interval) + " HOURS")]) : _vm._e()]), _c('tr', [_c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Trigger")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _vm.maintenanceDetailData.trigger === 'CALENDAR' ? _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("CALENDAR")]) : _vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("COUNTER")]) : _vm._e(), _c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Status")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.status))])], 1)]), _vm.maintenanceDetailData.trigger === 'CALENDAR' ? _c('tr', [_c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Last Maintenance")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.lastMaintenanceDate))]), _c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Possible Maintenance")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.possibleMaintenanceDate))])]) : _vm._e(), _c('tr', [_c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Last Cost")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail = _vm.maintenanceDetailData.cost) === null || _vm$maintenanceDetail === void 0 ? void 0 : _vm$maintenanceDetail.toLocaleString()))]), _c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Attachment")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [(_vm$maintenanceDetail2 = _vm.maintenanceDetailData) !== null && _vm$maintenanceDetail2 !== void 0 && _vm$maintenanceDetail2.file ? _c('span', {
    staticClass: "text-primary",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        var _vm$maintenanceDetail3, _vm$maintenanceDetail4;
        return _vm.handleOpenFilePreview((_vm$maintenanceDetail3 = _vm.maintenanceDetailData) === null || _vm$maintenanceDetail3 === void 0 ? void 0 : (_vm$maintenanceDetail4 = _vm$maintenanceDetail3.file) === null || _vm$maintenanceDetail4 === void 0 ? void 0 : _vm$maintenanceDetail4.url);
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$maintenanceDetail5 = _vm.maintenanceDetailData) === null || _vm$maintenanceDetail5 === void 0 ? void 0 : (_vm$maintenanceDetail6 = _vm$maintenanceDetail5.file) === null || _vm$maintenanceDetail6 === void 0 ? void 0 : _vm$maintenanceDetail6.fileName))]) : _c('span', {
    staticClass: "text-muted"
  }, [_vm._v("No File Exist..")])])]), _c('tr', [_c('th', {
    staticClass: "align-top"
  }, [_vm._v("Job Description")]), _c('td', {
    staticClass: "text-center align-top"
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "colspan": "4"
    }
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.maintenanceDetailData.jobDesc)
    }
  })])]), _c('tr', [_c('th', {
    staticClass: "align-top"
  }, [_vm._v("Last Notes")]), _c('td', {
    staticClass: "text-center align-top"
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "colspan": "4"
    }
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.maintenanceDetailData.notes)
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.notes || '-') + "\">")])])])])])]), _vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2"
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.power.powerType.name) + " - " + _vm._s(_vm.maintenanceDetailData.power.powerSeries.name) + " " + _vm._s(_vm.maintenanceDetailData.power.powerSeries.model))]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Last Maintenance Date")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.lastMaintenanceDate))]), _c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Last Maintenance RH")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.lastMaintenanceHours) + " HOURS")])]), _c('tr', [_c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Actual RH")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.power.actualRunningHours) + " HOURS")]), _c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Possible Maintenance RH")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.possibleMaintenanceHours) + " HOURS")])])])])]) : _vm._e(), ['ON_MAINTENANCE', 'ON_POSTPONE', 'ON_CONVERT'].includes(_vm.stateAction) && !_vm.isEdit ? _c('fieldset', {
    staticClass: "form-group border p-3",
    attrs: {
      "id": "form-field"
    }
  }, [_c('legend', {
    staticClass: "w-auto px-2"
  }, [_vm._v("Maintenance Process")]), _vm.stateAction === 'ON_MAINTENANCE' ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Maintenance Date *"
    }
  }, [_c('date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "value-type": "DD-MM-YYYY",
      "format": "DD MMM YYYY",
      "type": "date",
      "placeholder": "Select date"
    },
    model: {
      value: _vm.formMaintenance.maintenanceDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formMaintenance, "maintenanceDate", $$v);
      },
      expression: "formMaintenance.maintenanceDate"
    }
  })], 1)], 1), _vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Actual Running Hours *"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formMaintenance.maintenanceRunningHours,
      expression: "formMaintenance.maintenanceRunningHours"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.formMaintenance.maintenanceRunningHours
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formMaintenance, "maintenanceRunningHours", $event.target.value);
      }
    }
  })])], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "File Maintenance"
    }
  }, [_c('b-form-file', {
    attrs: {
      "placeholder": "Choose a file",
      "name": "select-file-maintenance"
    },
    on: {
      "change": _vm.uploadFile
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cost"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formMaintenance.cost,
      expression: "formMaintenance.cost"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.formMaintenance.cost
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formMaintenance, "cost", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "text-right"
  }, [_vm._v(" Rp. " + _vm._s(_vm.numberFormat(_vm.formMaintenance.cost)) + " ")])])], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Notes *"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "rows": "3",
      "placeholder": "Type Notes"
    },
    model: {
      value: _vm.formMaintenance.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.formMaintenance, "notes", $$v);
      },
      expression: "formMaintenance.notes"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.saveMaintenance();
      }
    }
  }, [_vm._v("Save")]), _c('b-button', {
    on: {
      "click": function click($event) {
        return _vm.onAction('VIEW_DETAIL');
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1) : _vm.stateAction === 'ON_POSTPONE' ? _c('b-row', [_vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Extra Hours *"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formPostpone.extraHours,
      expression: "formPostpone.extraHours"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.formPostpone.extraHours
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formPostpone, "extraHours", $event.target.value);
      }
    }
  })])], 1) : _vm._e(), _vm.maintenanceDetailData.trigger === 'CALENDAR' ? _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Extra Date *"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formPostpone.extraDate,
      expression: "formPostpone.extraDate"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "date"
    },
    domProps: {
      "value": _vm.formPostpone.extraDate
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formPostpone, "extraDate", $event.target.value);
      }
    }
  })])], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Reason *"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "rows": "3",
      "placeholder": "Type Reason"
    },
    model: {
      value: _vm.formPostpone.reason,
      callback: function callback($$v) {
        _vm.$set(_vm.formPostpone, "reason", $$v);
      },
      expression: "formPostpone.reason"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.savePostpone();
      }
    }
  }, [_vm._v("Save")]), _c('b-button', {
    on: {
      "click": function click($event) {
        return _vm.onAction('VIEW_DETAIL');
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1) : _vm.stateAction === 'ON_CONVERT' ? _c('b-row') : _vm._e()], 1) : _vm._e(), ['REVIEW', 'APPROVE', 'FINISHED'].includes(_vm.maintenanceDetailData.status) ? _c('fieldset', {
    staticClass: "form-group border p-3",
    attrs: {
      "id": "form-field"
    }
  }, [_c('legend', {
    staticClass: "w-auto px-2"
  }, [_vm._v("Review Maintenance")]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("Last Maintenance Date")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "65%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.lastMaintenanceDate))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("Current Maintenance Date")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _vm.isEdit ? _c('td', {
    attrs: {
      "width": "65%"
    }
  }, [_c('date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "value-type": "DD-MM-YYYY",
      "format": "DD MMM YYYY",
      "type": "date",
      "placeholder": "Select date"
    },
    model: {
      value: _vm.formEdit.maintenanceDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formEdit, "maintenanceDate", $$v);
      },
      expression: "formEdit.maintenanceDate"
    }
  })], 1) : _c('td', {
    attrs: {
      "width": "65%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.maintenanceDate))])]), _vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('tr', [_c('th', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("Maintenance Running Hours")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _vm.isEdit ? _c('td', {
    attrs: {
      "width": "65%"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.formEdit.maintenanceRunningHours,
      callback: function callback($$v) {
        _vm.$set(_vm.formEdit, "maintenanceRunningHours", $$v);
      },
      expression: "formEdit.maintenanceRunningHours"
    }
  })], 1) : _c('td', {
    attrs: {
      "width": "65%"
    }
  }, [_vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('p', [_vm._v(_vm._s(_vm.maintenanceDetailData.lastMaintenanceHours) + " HOURS")]) : _vm._e()])]) : _vm._e(), _c('tr', [_c('th', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("Attachment")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _vm.isEdit ? _c('td', {
    attrs: {
      "width": "65%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('b-form-file', {
    attrs: {
      "placeholder": "Choose a file",
      "name": "select-file-maintenance",
      "accept": "application/*"
    },
    on: {
      "change": _vm.uploadFile
    }
  }), _vm.formEdit.fileName ? _c('span', {
    staticClass: "text-primary"
  }, [_vm._v("New File: "), _c('i', {
    staticClass: "fa fa-file"
  }), _vm._v(" " + _vm._s(_vm.formEdit.fileName))]) : _vm._e(), (_vm$maintenanceDetail7 = _vm.maintenanceDetailData) !== null && _vm$maintenanceDetail7 !== void 0 && (_vm$maintenanceDetail8 = _vm$maintenanceDetail7.file) !== null && _vm$maintenanceDetail8 !== void 0 && _vm$maintenanceDetail8.fileName ? _c('span', {
    staticClass: "text-secondary",
    on: {
      "click": function click($event) {
        var _vm$maintenanceDetail9, _vm$maintenanceDetail10;
        return _vm.handleOpenFilePreview((_vm$maintenanceDetail9 = _vm.maintenanceDetailData) === null || _vm$maintenanceDetail9 === void 0 ? void 0 : (_vm$maintenanceDetail10 = _vm$maintenanceDetail9.file) === null || _vm$maintenanceDetail10 === void 0 ? void 0 : _vm$maintenanceDetail10.url);
      }
    }
  }, [_vm._v("Existed File: "), _c('i', {
    staticClass: "fa fa-file"
  }), _vm._v(" " + _vm._s((_vm$maintenanceDetail11 = _vm.maintenanceDetailData) === null || _vm$maintenanceDetail11 === void 0 ? void 0 : (_vm$maintenanceDetail12 = _vm$maintenanceDetail11.file) === null || _vm$maintenanceDetail12 === void 0 ? void 0 : _vm$maintenanceDetail12.fileName))]) : _vm._e()], 1)]) : _c('td', {
    attrs: {
      "width": "65%"
    }
  }, [_c('span', {
    staticClass: "text-primary",
    on: {
      "click": function click($event) {
        var _vm$maintenanceDetail13, _vm$maintenanceDetail14;
        return _vm.handleOpenFilePreview((_vm$maintenanceDetail13 = _vm.maintenanceDetailData) === null || _vm$maintenanceDetail13 === void 0 ? void 0 : (_vm$maintenanceDetail14 = _vm$maintenanceDetail13.file) === null || _vm$maintenanceDetail14 === void 0 ? void 0 : _vm$maintenanceDetail14.url);
      }
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail15 = _vm.maintenanceDetailData) === null || _vm$maintenanceDetail15 === void 0 ? void 0 : (_vm$maintenanceDetail16 = _vm$maintenanceDetail15.file) === null || _vm$maintenanceDetail16 === void 0 ? void 0 : _vm$maintenanceDetail16.fileName))])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("Cost")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _vm.isEdit ? _c('td', {
    attrs: {
      "width": "65%"
    }
  }, [_c('b-input-group', {
    attrs: {
      "prepend": "Rp"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.formEdit.cost,
      callback: function callback($$v) {
        _vm.$set(_vm.formEdit, "cost", $$v);
      },
      expression: "formEdit.cost"
    }
  })], 1), _c('div', {
    staticClass: "text-right"
  }, [_vm._v(" Rp. " + _vm._s(_vm.numberFormat(_vm.formEdit.cost)) + " ")])], 1) : _c('td', {
    attrs: {
      "width": "65%"
    }
  }, [_vm._v("Rp." + _vm._s(_vm.numberFormat(_vm.maintenanceDetailData.cost)))])]), _c('tr', [_c('th', {
    staticClass: "align-top"
  }, [_vm._v("Current Notes")]), _c('td', {
    staticClass: "text-center align-top"
  }, [_vm._v(":")]), _vm.isEdit ? _c('td', {
    attrs: {
      "colspan": "4"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "placeholder": "Type Text"
    },
    model: {
      value: _vm.formEdit.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.formEdit, "notes", $$v);
      },
      expression: "formEdit.notes"
    }
  })], 1) : _c('td', {
    attrs: {
      "colspan": "4"
    }
  }, [_vm.maintenanceDetailData.status === 'APPROVE' ? _c('span', [_vm._v(_vm._s(_vm.maintenanceDetailData.finishNotes))]) : _c('span', [_vm._v(_vm._s(_vm.maintenanceDetailData.notes))])])])])]), _vm.isEdit ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "primary",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.editDoMaintenance();
      }
    }
  }, [_vm._v("Save Update")]), _c('b-button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.onAction('VIEW_DETAIL');
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2"
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.power.powerType.name) + " - " + _vm._s(_vm.maintenanceDetailData.power.powerSeries.name) + " " + _vm._s(_vm.maintenanceDetailData.power.powerSeries.model))]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Last Maintenance Date")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.lastMaintenanceDate))]), _c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Last Maintenance RH")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.lastMaintenanceHours) + " HOURS")])]), _c('tr', [_c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Actual RH")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.power.actualRunningHours) + " HOURS")]), _c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Possible Maintenance RH")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.possibleMaintenanceHours) + " HOURS")])])])])]) : _vm._e(), _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2"
  }, [_vm._v("Equipment Info - Propulsion")]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Type No")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail17 = (_vm$maintenanceDetail18 = _vm.maintenanceDetailData.possibleMaintenanceHours) === null || _vm$maintenanceDetail18 === void 0 ? void 0 : (_vm$maintenanceDetail19 = _vm$maintenanceDetail18.componentSetup) === null || _vm$maintenanceDetail19 === void 0 ? void 0 : _vm$maintenanceDetail19.typeNo) !== null && _vm$maintenanceDetail17 !== void 0 ? _vm$maintenanceDetail17 : '-'))]), _c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Serial No")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail20 = (_vm$maintenanceDetail21 = _vm.maintenanceDetailData.possibleMaintenanceHours) === null || _vm$maintenanceDetail21 === void 0 ? void 0 : (_vm$maintenanceDetail22 = _vm$maintenanceDetail21.componentSetup) === null || _vm$maintenanceDetail22 === void 0 ? void 0 : _vm$maintenanceDetail22.serialNo) !== null && _vm$maintenanceDetail20 !== void 0 ? _vm$maintenanceDetail20 : '-'))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Maker No")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail23 = (_vm$maintenanceDetail24 = _vm.maintenanceDetailData.possibleMaintenanceHours) === null || _vm$maintenanceDetail24 === void 0 ? void 0 : (_vm$maintenanceDetail25 = _vm$maintenanceDetail24.componentSetup) === null || _vm$maintenanceDetail25 === void 0 ? void 0 : _vm$maintenanceDetail25.makerNo) !== null && _vm$maintenanceDetail23 !== void 0 ? _vm$maintenanceDetail23 : '-'))]), _c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Maker")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail26 = (_vm$maintenanceDetail27 = _vm.maintenanceDetailData.possibleMaintenanceHours) === null || _vm$maintenanceDetail27 === void 0 ? void 0 : (_vm$maintenanceDetail28 = _vm$maintenanceDetail27.componentSetup) === null || _vm$maintenanceDetail28 === void 0 ? void 0 : _vm$maintenanceDetail28.maker) !== null && _vm$maintenanceDetail26 !== void 0 ? _vm$maintenanceDetail26 : '-'))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Drawing No")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail29 = (_vm$maintenanceDetail30 = _vm.maintenanceDetailData.possibleMaintenanceHours) === null || _vm$maintenanceDetail30 === void 0 ? void 0 : (_vm$maintenanceDetail31 = _vm$maintenanceDetail30.componentSetup) === null || _vm$maintenanceDetail31 === void 0 ? void 0 : _vm$maintenanceDetail31.drawingNo) !== null && _vm$maintenanceDetail29 !== void 0 ? _vm$maintenanceDetail29 : '-'))]), _c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Certificate")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail32 = _vm.maintenanceDetailData.possibleMaintenanceHours) !== null && _vm$maintenanceDetail32 !== void 0 && (_vm$maintenanceDetail33 = _vm$maintenanceDetail32.componentSetup) !== null && _vm$maintenanceDetail33 !== void 0 && _vm$maintenanceDetail33.haveCertificate ? 'Yes' : 'No'))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Additional Information")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail34 = (_vm$maintenanceDetail35 = _vm.maintenanceDetailData.possibleMaintenanceHours) === null || _vm$maintenanceDetail35 === void 0 ? void 0 : (_vm$maintenanceDetail36 = _vm$maintenanceDetail35.componentSetup) === null || _vm$maintenanceDetail36 === void 0 ? void 0 : _vm$maintenanceDetail36.additionalInfo) !== null && _vm$maintenanceDetail34 !== void 0 ? _vm$maintenanceDetail34 : '-'))]), _c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Descriptions")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail37 = (_vm$maintenanceDetail38 = _vm.maintenanceDetailData.possibleMaintenanceHours) === null || _vm$maintenanceDetail38 === void 0 ? void 0 : (_vm$maintenanceDetail39 = _vm$maintenanceDetail38.componentSetup) === null || _vm$maintenanceDetail39 === void 0 ? void 0 : _vm$maintenanceDetail39.description) !== null && _vm$maintenanceDetail37 !== void 0 ? _vm$maintenanceDetail37 : '-'))])])])])])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('b-btn', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.history",
      modifiers: {
        "history": true
      }
    }],
    attrs: {
      "block": "",
      "href": "#",
      "variant": "light"
    }
  }, [_vm._v(" History Form "), _c('span', {
    staticClass: "when-opened"
  }, [_c('i', {
    staticClass: "fa fa-arrow-down"
  })]), _c('span', {
    staticClass: "when-closed"
  }, [_c('i', {
    staticClass: "fa fa-arrow-up"
  })])]), _c('b-collapse', {
    attrs: {
      "id": "history",
      "role": "tabpanel"
    }
  }, [_c('ul', {
    staticClass: "iq-timeline"
  }, [_vm.maintenanceDetailData.logs.length === 0 ? _c('li', [_vm._v("Tidak ada histori log")]) : _vm._e(), _vm._l(_vm.maintenanceDetailData.logs, function (log, logIndex) {
    return _c('li', {
      key: logIndex
    }, [_c('div', {
      staticClass: "timeline-dots border-secondary"
    }), _c('small', {
      staticClass: "mt-1"
    }, [_vm._v(_vm._s(log.createdAt))]), log.status === 'INIT' ? _c('h6', {}, [_c('strong', [_vm._v(_vm._s(log.createdBy))]), _c('br'), _vm._v(" Telah membuat form")]) : ['APPROVED', 'APPROVAL', 'APPROVE'].includes(log.status) ? _c('h6', {}, [_c('strong', [_vm._v(_vm._s(log.createdBy))]), _vm._v(" "), _c('br'), _vm._v(" Telah memberikan approval form")]) : ['REVIEWED', 'REVIEW'].includes(log.status) ? _c('h6', {}, [_c('strong', [_vm._v(_vm._s(log.createdBy))]), _c('br'), _vm._v(" Telah memberikan review form")]) : ['OVERDUE', 'TOWARDS'].includes(log.status) ? _c('h6', {}, [_c('strong', [_vm._v(_vm._s(log.createdBy))]), _c('br'), _vm._v(" Harap segera lakukan maintenance")]) : ['NEW'].includes(log.status) ? _c('h6', {}, [_c('strong', [_vm._v(_vm._s(log.createdBy))]), _vm._v(" "), _c('br'), _vm._v(" Maintenance schedule telah dibuat")]) : _vm._e()]);
  })], 2)])], 1), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-btn', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.comment",
      modifiers: {
        "comment": true
      }
    }],
    attrs: {
      "block": "",
      "href": "#",
      "variant": "secondary"
    }
  }, [_c('i', {
    staticClass: "fa fa-comment"
  }), _vm._v(" Comment Box (" + _vm._s(_vm.maintenanceDetailData.comments.length) + ") "), _c('span', {
    staticClass: "when-opened"
  }, [_c('i', {
    staticClass: "fa fa-arrow-down"
  })]), _c('span', {
    staticClass: "when-closed"
  }, [_c('i', {
    staticClass: "fa fa-arrow-up"
  })])]), _c('b-collapse', {
    attrs: {
      "id": "comment",
      "role": "tabpanel"
    }
  }, [_c('div', {
    staticClass: "detailBox"
  }, [_c('div', {
    staticClass: "titleBox"
  }, [_c('label', [_vm._v("Comment Box")]), _c('button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.comment",
      modifiers: {
        "comment": true
      }
    }],
    staticClass: "close",
    attrs: {
      "type": "button",
      "aria-hidden": "true"
    }
  }, [_vm._v("×")])]), _c('div', {
    staticClass: "actionBox"
  }, [_c('ul', {
    staticClass: "commentList"
  }, [_vm.maintenanceDetailData.comments.length === 0 ? _c('li', [_vm._v("Tidak ada komentar")]) : _vm._e(), _vm._l(_vm.maintenanceDetailData.comments, function (comment) {
    return _c('li', [_c('div', {
      staticClass: "commentText"
    }, [_c('p', [_c('span', [_c('strong', [_vm._v(_vm._s(comment.name))]), _vm._v(" - " + _vm._s(comment.roleName))])]), _c('p', {}, [_vm._v(_vm._s(comment.comment))]), _vm._v(" "), _c('span', {
      staticClass: "date sub-text"
    }, [_vm._v("on " + _vm._s(comment.createdAt))])])]);
  })], 2)]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Input Your Comments..."
    },
    model: {
      value: _vm.formComment.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.formComment, "comment", $$v);
      },
      expression: "formComment.comment"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": !_vm.formComment.comment,
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.saveComment();
      }
    }
  }, [_vm._v("Add")])], 1)], 1)], 1)])], 1)], 1)], 1)], 1)], 1)])], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }