var render = function render(){
  var _vm$detailIncident, _vm$detailIncident$do, _vm$detailIncident2, _vm$detailIncident3, _vm$detailIncident3$h, _vm$detailIncident4, _vm$detailIncident5, _vm$detailIncident5$l, _vm$detailIncident6, _vm$detailIncident6$l, _vm$detailIncident7, _vm$detailIncident7$l, _vm$detailIncident8, _vm$detailIncident8$a, _vm$detailIncident9, _vm$detailIncident9$r, _vm$detailIncident10, _vm$detailIncident10$, _vm$detailIncident11, _vm$detailIncident12, _vm$detailIncident13, _vm$detailIncident13$, _vm$detailIncident14, _vm$detailIncident14$, _vm$detailIncident15, _vm$detailIncident15$, _vm$detailIncident16, _vm$detailIncident17, _vm$detailIncident17$, _vm$detailIncident18, _vm$detailIncident18$, _vm$detailIncident19, _vm$detailIncident19$, _vm$detailIncident20, _vm$detailIncident21, _vm$detailIncident22, _vm$detailIncident22$, _vm$detailIncident23, _vm$detailIncident23$, _vm$detailIncident24, _vm$detailIncident24$, _vm$detailIncident25;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showFilter ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("List of Accident AND Incident")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-filter",
            modifiers: {
              "collapse-filter": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          attrs: {
            "id": "collapse-filter",
            "visible": ""
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Date Range"
          }
        }, [_c('date-picker', {
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "type": "date",
            "value-type": "YYYY-MM-DD",
            "range": "",
            "placeholder": "Select date range"
          },
          on: {
            "change": _vm.handleChangeDate
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Company"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.companies,
            "reduce": function reduce(companyOptions) {
              return companyOptions.value;
            }
          },
          model: {
            value: _vm.incidents.params.company_id,
            callback: function callback($$v) {
              _vm.$set(_vm.incidents.params, "company_id", $$v);
            },
            expression: "incidents.params.company_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Fleet/Vessel"
          }
        }, [_vm.vehicleId ? _c('p', {
          staticClass: "form-control-static"
        }, [_vm._v(" " + _vm._s(_vm.fleets.find(function (fleet) {
          return fleet.code === _vm.vehicleId;
        }) ? _vm.fleets.find(function (fleet) {
          return fleet.code === _vm.vehicleId;
        }).name : '-') + " ")]) : _c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.fleets,
            "reduce": function reduce(fleetList) {
              return fleetList.code;
            }
          },
          model: {
            value: _vm.incidents.params.vehicle_id,
            callback: function callback($$v) {
              _vm.$set(_vm.incidents.params, "vehicle_id", $$v);
            },
            expression: "incidents.params.vehicle_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Type"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.typeOfForm,
            "reduce": function reduce(typeOfForm) {
              return typeOfForm.value;
            }
          },
          model: {
            value: _vm.incidents.params.type_form,
            callback: function callback($$v) {
              _vm.$set(_vm.incidents.params, "type_form", $$v);
            },
            expression: "incidents.params.type_form"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Type of Coverage"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.typeCoverages,
            "reduce": function reduce(typeOfCoverage) {
              return typeOfCoverage.code;
            }
          },
          model: {
            value: _vm.incidents.params.id_type_coverage,
            callback: function callback($$v) {
              _vm.$set(_vm.incidents.params, "id_type_coverage", $$v);
            },
            expression: "incidents.params.id_type_coverage"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Type of Loss"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.typeLoss,
            "reduce": function reduce(typeOfLoss) {
              return typeOfLoss.code;
            }
          },
          model: {
            value: _vm.incidents.params.id_type_loss,
            callback: function callback($$v) {
              _vm.$set(_vm.incidents.params, "id_type_loss", $$v);
            },
            expression: "incidents.params.id_type_loss"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Status"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.statusOptions,
            "reduce": function reduce(statusOptions) {
              return statusOptions.value;
            }
          },
          model: {
            value: _vm.incidents.params.status_id,
            callback: function callback($$v) {
              _vm.$set(_vm.incidents.params, "status_id", $$v);
            },
            expression: "incidents.params.status_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.handleFetchIncidents(1, true);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }], null, false, 2620112813)
  })], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        var _vm$incidents, _vm$incidents$data, _vm$incidents2;
        return [_c('b-row', [_c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "12"
          }
        }, [_vm.canExport ? _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.exportIncidentsFile
          }
        }, [!_vm.loading_export ? [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v("   Export ")] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Exporting... ")]], 2) : _vm._e(), _vm.canAdd ? _c('router-link', {
          attrs: {
            "to": "/mi/portal"
          }
        }, [_c('button', {
          staticClass: "btn btn-primary mb-3"
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add Form Submission")])]) : _vm._e()], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex w-100 overflow-auto"
        }, [_c('table', {
          staticClass: "table mb-0 table-borderless table-box-shadow"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No.")]), _c('th', [_vm._v("Name of Vessel")]), _c('th', [_vm._v("Type")]), _c('th', [_vm._v("Type of Coverage")]), _c('th', [_vm._v("Type of Loss")]), _c('th', [_vm._v("Cost")]), _c('th', [_vm._v("Remarks")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Date of Loss / Created")]), _c('th', [_vm._v("Action")])])]), _c('tbody', [((_vm$incidents = _vm.incidents) === null || _vm$incidents === void 0 ? void 0 : (_vm$incidents$data = _vm$incidents.data) === null || _vm$incidents$data === void 0 ? void 0 : _vm$incidents$data.length) === 0 || _vm.incidents.isLoading ? _c('tr', [_c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "10"
          }
        }, [_vm._v(_vm._s(_vm.incidents.isLoading ? 'Loading...' : "No Data"))])]) : _vm._e(), _vm._l((_vm$incidents2 = _vm.incidents) === null || _vm$incidents2 === void 0 ? void 0 : _vm$incidents2.data, function (data, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(_vm.incidents.meta.from + index) + ".")]), _c('td', [_c('a', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.handleOpenDetailIncident(index);
              }
            }
          }, [_vm._v(_vm._s(data.vehicle.name))])]), _c('td', [data.type_form === 'claim' ? _c('b-button', {
            ref: "button",
            refInFor: true,
            attrs: {
              "variant": "info"
            }
          }, [_vm._v("Claim")]) : _vm._e(), data.type_form === 'non_claim' ? _c('b-button', {
            ref: "button",
            refInFor: true,
            attrs: {
              "variant": "warning"
            }
          }, [_vm._v("Non Claim ")]) : _vm._e()], 1), _c('td', [_vm._v(_vm._s(data.type_coverage.name))]), _c('td', [_vm._v(_vm._s(data.type_loss.name))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("Rp. " + _vm._s(_vm.numberFormat(data.cost)) + ",-")]), _c('td', [_vm._v(_vm._s(data.remarks || '-'))]), _c('td', [_c('b-button', {
            ref: "button",
            refInFor: true,
            attrs: {
              "id": _vm.canApprove ? "popover-status-".concat(index) : '',
              "variant": "primary"
            }
          }, [_vm._v(_vm._s(data.status ? data.status.name : '-'))])], 1), _c('td', [_vm._v(" " + _vm._s(data.date_of_loss) + " "), _c('br'), _c('strong', [_vm._v("PIC: " + _vm._s(data.creator ? data.creator.username : '-'))])]), _c('td', [_c('b-dropdown', {
            attrs: {
              "variant": "primary"
            }
          }, [_c('template', {
            slot: "button-content"
          }, [_vm._v(" Action "), _c('i', {
            staticClass: "uil uil-angle-down"
          })]), [_c('b-dropdown-item', {
            on: {
              "click": function click($event) {
                return _vm.handleNextProcess(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-arrow-right"
          }), _vm._v(" Next Step Process")])], _c('b-dropdown-item', {
            on: {
              "click": function click($event) {
                return _vm.handleOpenDetailIncident(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-list"
          }), _vm._v(" Detail")]), _c('b-dropdown-item', {
            attrs: {
              "variant": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.handleOpenModalEmail('Accident', data.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-send"
          }), _vm._v(" Send Email Accident")]), _vm.role === 'MANAGEMENT' || _vm.canApprove ? [_c('b-dropdown-item', {
            on: {
              "click": function click($event) {
                return _vm.$router.push("/mi/management-approval?formId=".concat(data.id));
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-check"
          }), _vm._v(" Management Approval")]), _c('b-dropdown-item', {
            on: {
              "click": function click($event) {
                return _vm.$router.push("/mi/management-summary?formId=".concat(data.id));
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-check"
          }), _vm._v(" Management Summary")])] : _vm._e(), _vm.canDelete ? _c('b-dropdown-item', {
            on: {
              "click": function click($event) {
                return _vm.handleDeleteMi(data.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          }), _vm._v(" Delete")]) : _vm._e()], 2)], 1), _vm.showStatusAction ? _c('b-popover', {
            attrs: {
              "target": "popover-status-".concat(index),
              "placement": "bottom",
              "triggers": "hover focus"
            },
            scopedSlots: _vm._u([{
              key: "title",
              fn: function fn() {
                return [_vm._v("Status Initial Claim!")];
              },
              proxy: true
            }], null, true)
          }, [_vm._l(_vm.statuses, function (status, idx) {
            return [data.status.id !== status.code && _vm.handleShowListStatuses(data.status.id).includes(status.code) ? _c('div', {
              key: idx,
              on: {
                "click": function click($event) {
                  return _vm.handleChangeStatus(data.id, status, index);
                }
              }
            }, [status.code === 11 ? _c('b-button', {
              ref: "button",
              refInFor: true,
              staticClass: "mb-2",
              attrs: {
                "block": "",
                "variant": "warning"
              }
            }, [_vm._v(" Completed")]) : status.code === 10 ? _c('b-button', {
              ref: "button",
              refInFor: true,
              staticClass: "mb-2",
              attrs: {
                "block": "",
                "variant": "danger"
              }
            }, [_vm._v("Waiting R&D")]) : status.code === 9 ? _c('b-button', {
              ref: "button",
              refInFor: true,
              staticClass: "mb-2",
              attrs: {
                "block": "",
                "variant": "success"
              }
            }, [_vm._v("Waiting Final Adjustment")]) : _c('b-button', {
              ref: "button",
              refInFor: true,
              staticClass: "mb-2",
              attrs: {
                "block": "",
                "variant": "info"
              }
            }, [_vm._v(_vm._s(status.name) + " ")])], 1) : _vm._e()];
          })], 2) : _vm._e()], 1);
        })], 2)])]), _c('div', {
          staticClass: "mt-3 mb-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.incidents.meta.total,
            "per-page": _vm.incidents.meta.perPage,
            "align": "center"
          },
          on: {
            "input": _vm.handleFetchIncidents
          },
          model: {
            value: _vm.incidents.params.page,
            callback: function callback($$v) {
              _vm.$set(_vm.incidents.params, "page", $$v);
            },
            expression: "incidents.params.page"
          }
        })], 1)])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-sidebar', {
    attrs: {
      "id": "sidebar-view",
      "title": "DETAIL INFORMATION OF ACCIDENT/INCIDENT",
      "width": "1200px",
      "backdrop-variant": "dark",
      "right": "",
      "backdrop": "",
      "shadow": ""
    }
  }, [_vm.detailIncident ? _c('b-row', {
    staticClass: "pt-2 pl-3 pr-3 pb-2"
  }, [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center"
  }, [_vm._v("INFORMATION OF ACCIDENT/INCIDENT")])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Type")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_vm.detailIncident.type_form === 'claim' ? _c('b-button', {
    ref: "button",
    attrs: {
      "id": "popover-type",
      "variant": "info"
    }
  }, [_vm._v("Claim")]) : _vm._e(), _vm.detailIncident.type_form === 'non_claim' ? _c('b-button', {
    ref: "button",
    attrs: {
      "id": "popover-type",
      "variant": "warning"
    }
  }, [_vm._v("Non Claim")]) : _vm._e()], 1), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Status")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('b-button', {
    ref: "button",
    attrs: {
      "id": "popover-view-status",
      "variant": "primary"
    }
  }, [_vm._v(_vm._s(_vm.detailIncident.status ? _vm.detailIncident.status.name : ''))]), _c('b-popover', {
    attrs: {
      "target": "popover-view-status",
      "placement": "bottom",
      "triggers": "hover focus"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("Status Initial Claim!")];
      },
      proxy: true
    }], null, false, 3736808988)
  }, [_vm._l(_vm.statuses, function (status, idx) {
    return [_vm.detailIncident.status.id !== status.code && _vm.handleShowListStatuses(_vm.detailIncident.status.id).includes(status.code) ? _c('div', {
      key: idx,
      on: {
        "click": function click($event) {
          return _vm.handleChangeStatus(_vm.detailIncident.id, status);
        }
      }
    }, [status.code === 11 ? _c('b-button', {
      ref: "button",
      refInFor: true,
      staticClass: "mb-2",
      attrs: {
        "block": "",
        "variant": "warning"
      }
    }, [_vm._v(" Completed")]) : status.code === 10 ? _c('b-button', {
      ref: "button",
      refInFor: true,
      staticClass: "mb-2",
      attrs: {
        "block": "",
        "variant": "danger"
      }
    }, [_vm._v("Waiting R&D")]) : status.code === 9 ? _c('b-button', {
      ref: "button",
      refInFor: true,
      staticClass: "mb-2",
      attrs: {
        "block": "",
        "variant": "success"
      }
    }, [_vm._v("Waiting Final Adjustment")]) : _c('b-button', {
      ref: "button",
      refInFor: true,
      staticClass: "mb-2",
      attrs: {
        "block": "",
        "variant": "info"
      }
    }, [_vm._v(_vm._s(status.name) + " ")])], 1) : _vm._e()];
  })], 2)], 1)]), _c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Company")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.company.company))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Name of Vessel")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v(_vm._s(_vm.detailIncident.vehicle.name))])], 1)]), _c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Type of Coverage")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.type_coverage.name || '-'))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Place of Loss")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.place_of_loss || '-'))])])]), _c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Cause of Loss")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.cause_of_loss ? _vm.detailIncident.cause_of_loss.name : '-'))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Type of Loss")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.type_loss.name || '-'))])])]), _c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Date of Loss")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.date_of_loss))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Created Form")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_vm._v(_vm._s(_vm.detailIncident.created_at))])]), _c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Remarks")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.remarks || '-'))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Cost")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v("Rp. " + _vm._s(_vm.detailIncident.cost) + ",-")])])])])])]), _c('b-col', {
    staticClass: "text-right pt-2",
    attrs: {
      "md": "1"
    }
  }, [_c('b-dropdown', {
    attrs: {
      "size": "sm",
      "text": "Small",
      "right": ""
    }
  }, [_c('template', {
    slot: "button-content"
  }, [_c('i', {
    staticClass: "fa fa-ellipsis-h"
  })]), _c('b-dropdown-item', [_c('router-link', {
    attrs: {
      "to": "/mi/portal?formId=".concat(_vm.detailIncident.id)
    }
  }, [_c('i', {
    staticClass: "fa fa-edit"
  }), _vm._v(" Edit")])], 1), _c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.handleDeleteMi(_vm.detailIncident.id);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-trash"
  }), _vm._v(" Delete ")])], 2)], 1), _c('b-popover', {
    attrs: {
      "target": "popover-view-type",
      "placement": "bottom",
      "triggers": "hover focus"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("Type Form!")];
      },
      proxy: true
    }], null, false, 3348586610)
  }, [_c('b-button', {
    ref: "button",
    staticClass: "mb-2",
    attrs: {
      "block": "",
      "variant": "info"
    }
  }, [_vm._v("Non Claim")])], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center"
  }, [_vm._v("CLAIMANT / NOTIFICATION DETAILS")])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Company")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.company_claimant ? _vm.detailIncident.company_claimant.company : '-'))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Department/Vessel")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.department.name))])])]), _c('tr', [_c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Status")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.detailIncident.status ? _vm.detailIncident.status.name : '-'))])]), _c('td', {
    staticClass: "pl-5",
    attrs: {
      "width": "25%"
    }
  }), _c('td', {
    attrs: {
      "width": "5%"
    }
  }), _c('td', [_c('strong')])])])])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center"
  }, [_vm._v("Details of Chronology")])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-striped table-warning"
  }, [_c('tbody', [_c('tr', [_c('td', {
    domProps: {
      "innerHTML": _vm._s(_vm.detailIncident.details_of_chronology || '-')
    }
  })])])])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center"
  }, [_vm._v("Estimate Damage or Loss")])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-striped table-primary"
  }, [_c('tbody', [_c('tr', [_c('td', [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.detailIncident.estimate_damage || '-')
    }
  })])])])])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center"
  }, [_vm._v("Conclusion")])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-striped table-info"
  }, [_c('tbody', [_c('tr', [_c('td', [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.detailIncident.conclusion || '-')
    }
  })])])])])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center"
  }, [_vm._v("Support Document")])]), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-0 table-borderless table-box-shadow"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No.")]), _c('th', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v("Name of File / Document")]), _c('th', {
    attrs: {
      "width": "35%"
    }
  }, [_vm._v("File / Document")])])]), _c('tbody', [((_vm$detailIncident = _vm.detailIncident) === null || _vm$detailIncident === void 0 ? void 0 : (_vm$detailIncident$do = _vm$detailIncident.documents) === null || _vm$detailIncident$do === void 0 ? void 0 : _vm$detailIncident$do.length) === 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("No Document")])]) : _vm._e(), _vm._l((_vm$detailIncident2 = _vm.detailIncident) === null || _vm$detailIncident2 === void 0 ? void 0 : _vm$detailIncident2.documents, function (file, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1) + ".")]), _c('td', [_vm._v(_vm._s(file.name_of_file))]), _c('td', [_c('b-button-group', [_c('b-button', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "variant": "info",
        "href": file.document_url,
        "download": "",
        "title": "Preview File"
      }
    }, [_c('i', {
      staticClass: "fa fa-eye"
    })]), _c('b-button', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "variant": "dark",
        "href": file.document_url,
        "download": "",
        "title": "Download File"
      }
    }, [_c('i', {
      staticClass: "fa fa-download"
    })])], 1)], 1)]);
  })], 2)])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('b-btn', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.accordion1",
      modifiers: {
        "accordion1": true
      }
    }],
    attrs: {
      "block": "",
      "href": "#",
      "variant": "secondary"
    }
  }, [_vm._v(" History Form "), _c('span', {
    staticClass: "when-opened"
  }, [_c('i', {
    staticClass: "fa fa-arrow-down"
  })]), _c('span', {
    staticClass: "when-closed"
  }, [_c('i', {
    staticClass: "fa fa-arrow-up"
  })])]), _c('b-collapse', {
    attrs: {
      "id": "accordion1",
      "role": "tabpanel"
    }
  }, [_c('ul', {
    staticClass: "iq-timeline"
  }, [((_vm$detailIncident3 = _vm.detailIncident) === null || _vm$detailIncident3 === void 0 ? void 0 : (_vm$detailIncident3$h = _vm$detailIncident3.histories) === null || _vm$detailIncident3$h === void 0 ? void 0 : _vm$detailIncident3$h.length) == 0 ? _c('li', [_vm._v(" No Data ")]) : _vm._e(), _vm._l((_vm$detailIncident4 = _vm.detailIncident) === null || _vm$detailIncident4 === void 0 ? void 0 : _vm$detailIncident4.histories, function (history, index) {
    return _c('li', {
      key: index
    }, [_c('div', {
      staticClass: "timeline-dots border-secondary"
    }), _c('h6', {}, [_vm._v(_vm._s(history.history_text))]), _c('small', {
      staticClass: "mt-1"
    }, [_vm._v(_vm._s(history.date_history))])]);
  })], 2)])], 1), _vm.showApprovalForm || _vm.detailIncident.status_id === 3 ? _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', {
    staticClass: "mb-3 mt-2 text-center"
  }, [_c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(_vm._s(_vm.detailIncident.status_id !== 3 ? 'Approval' : 'Get') + " Marine Insurance Form")])], 1), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-sm": "3",
      "label": "Actual Close Date *",
      "label-for": "acd"
    }
  }, [_c('date-picker', {
    attrs: {
      "type": "date",
      "value-type": "YYYY-MM-DD",
      "format": "DD MMM YYYY",
      "placeholder": "Select date"
    },
    model: {
      value: _vm.approvalForm.actual_close_date,
      callback: function callback($$v) {
        _vm.$set(_vm.approvalForm, "actual_close_date", $$v);
      },
      expression: "approvalForm.actual_close_date"
    }
  })], 1), _c('table', {
    staticClass: "table mb-3 table-borderless table-box-shadow table-success"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "35%"
    }
  }, [_vm._v("Type")]), _c('th', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("Quantification")]), _c('th', {
    attrs: {
      "width": "35%"
    }
  })])]), _c('tbody', [_c('tr', [_c('td', [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.typeOfForm,
      "reduce": function reduce(typeOfForm) {
        return typeOfForm.value;
      }
    },
    model: {
      value: _vm.approvalForm.type_form,
      callback: function callback($$v) {
        _vm.$set(_vm.approvalForm, "type_form", $$v);
      },
      expression: "approvalForm.type_form"
    }
  })], 1), _c('td', [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.quantificationOptions,
      "reduce": function reduce(quantificationOptions) {
        return quantificationOptions.value;
      }
    },
    model: {
      value: _vm.approvalForm.quantification,
      callback: function callback($$v) {
        _vm.$set(_vm.approvalForm, "quantification", $$v);
      },
      expression: "approvalForm.quantification"
    }
  })], 1), _c('td')]), _c('tr', [_c('td', [_c('b-form-group', {
    attrs: {
      "label": "Coverage Period",
      "label-for": "fleet-equipment"
    }
  }, [_c('date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "year",
      "value-type": "YYYY",
      "range": "",
      "placeholder": "Select year"
    },
    on: {
      "change": _vm.handleCoverageDateRange
    },
    model: {
      value: _vm.coverageDateRange,
      callback: function callback($$v) {
        _vm.coverageDateRange = $$v;
      },
      expression: "coverageDateRange"
    }
  })], 1)], 1), _c('td', [_c('b-form-group', {
    attrs: {
      "label": "Status Coverage",
      "label-for": "fleet-equipment"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.statusCoverageOptions,
      "reduce": function reduce(statusCoverageOptions) {
        return statusCoverageOptions.value;
      }
    },
    model: {
      value: _vm.approvalForm.status_coverage,
      callback: function callback($$v) {
        _vm.$set(_vm.approvalForm, "status_coverage", $$v);
      },
      expression: "approvalForm.status_coverage"
    }
  })], 1)], 1), _c('td')]), _c('tr', [_c('td', [_c('b-form-group', {
    attrs: {
      "label": "Proximity Level",
      "label-for": "fleet-equipment"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "md",
      "append": "PL"
    }
  }, [_c('b-form-spinbutton', {
    staticClass: "bg-secondary text-white mr-2",
    attrs: {
      "min": "1",
      "max": "5",
      "inline": ""
    },
    model: {
      value: _vm.approvalForm.proximity_level,
      callback: function callback($$v) {
        _vm.$set(_vm.approvalForm, "proximity_level", $$v);
      },
      expression: "approvalForm.proximity_level"
    }
  })], 1)], 1)], 1), _c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Remarks"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.approvalForm.remarks,
      expression: "approvalForm.remarks"
    }],
    staticClass: "form-control border-dark",
    attrs: {
      "rows": "1"
    },
    domProps: {
      "value": _vm.approvalForm.remarks
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.approvalForm, "remarks", $event.target.value);
      }
    }
  })])], 1)]), _c('tr', [_c('td', [_c('b-form-group', {
    attrs: {
      "label": "Follow Up Response",
      "label-for": "fleet-equipment"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.responseFuOptions,
      "reduce": function reduce(responseFuOptions) {
        return responseFuOptions.value;
      }
    },
    model: {
      value: _vm.approvalForm.response_fu_user,
      callback: function callback($$v) {
        _vm.$set(_vm.approvalForm, "response_fu_user", $$v);
      },
      expression: "approvalForm.response_fu_user"
    }
  })], 1)], 1), _c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Reporting Status"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.statusOptions,
      "reduce": function reduce(statusOptions) {
        return statusOptions.text;
      }
    },
    model: {
      value: _vm.approvalForm.reporting_status,
      callback: function callback($$v) {
        _vm.$set(_vm.approvalForm, "reporting_status", $$v);
      },
      expression: "approvalForm.reporting_status"
    }
  })], 1)], 1)]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "3"
    }
  }, [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Insurer")]), _c('th', {
    attrs: {
      "width": "12%"
    }
  }, [_vm._v("Share")]), _c('th', {
    attrs: {
      "width": "12%"
    }
  }, [_vm._v("Currency")]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Amount")]), _c('th', {
    attrs: {
      "width": "13%"
    }
  }, [_vm._v("Paid at")]), _c('th', {
    attrs: {
      "width": "1%"
    }
  })])]), _c('tbody', [_vm._l(_vm.approvalForm.insurers, function (insurer, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('b-form-input', {
      staticClass: "border-dark",
      attrs: {
        "type": "text",
        "required": ""
      },
      model: {
        value: insurer.insurer_id,
        callback: function callback($$v) {
          _vm.$set(insurer, "insurer_id", $$v);
        },
        expression: "insurer.insurer_id"
      }
    })], 1), _c('td', [_c('b-input-group', {
      attrs: {
        "size": "sm",
        "append": "%"
      }
    }, [_c('b-form-input', {
      staticClass: "border-dark",
      attrs: {
        "type": "number",
        "required": ""
      },
      model: {
        value: insurer.share,
        callback: function callback($$v) {
          _vm.$set(insurer, "share", $$v);
        },
        expression: "insurer.share"
      }
    })], 1)], 1), _c('td', [_c('v-select', {
      attrs: {
        "label": "alias",
        "options": _vm.currencies,
        "reduce": function reduce(type) {
          return type.id;
        }
      },
      model: {
        value: insurer.currency_id,
        callback: function callback($$v) {
          _vm.$set(insurer, "currency_id", $$v);
        },
        expression: "insurer.currency_id"
      }
    })], 1), _c('td', [_c('b-input-group', {
      attrs: {
        "size": "sm",
        "prepend": "Rp"
      }
    }, [_c('b-form-input', {
      staticClass: "border-dark",
      attrs: {
        "required": "",
        "type": "number"
      },
      model: {
        value: insurer.amount,
        callback: function callback($$v) {
          _vm.$set(insurer, "amount", $$v);
        },
        expression: "insurer.amount"
      }
    })], 1)], 1), _c('td', [_c('date-picker', {
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "required": "",
        "type": "date",
        "placeholder": "Select Date",
        "value-type": "YYYY-MM-DD",
        "format": "DD MMM YYYY"
      },
      model: {
        value: insurer.paid_at,
        callback: function callback($$v) {
          _vm.$set(insurer, "paid_at", $$v);
        },
        expression: "insurer.paid_at"
      }
    })], 1), _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.removeApprovalInsurer(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])], 1)]);
  }), _c('tr', [_c('td', {
    attrs: {
      "colspan": "4"
    }
  }, [_c('b-button', {
    on: {
      "click": function click($event) {
        return _vm.addApprovalInsurer();
      }
    }
  }, [_vm._v("Add Insurer")])], 1)])], 2)])])]), _c('tr', [_c('td', [_c('b-form-group', {
    attrs: {
      "label": "Cause of Loss"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.causesOfLoss,
      "reduce": function reduce(causesOfLoss) {
        return causesOfLoss.code;
      }
    },
    model: {
      value: _vm.approvalForm.cause_of_loss_id,
      callback: function callback($$v) {
        _vm.$set(_vm.approvalForm, "cause_of_loss_id", $$v);
      },
      expression: "approvalForm.cause_of_loss_id"
    }
  })], 1)], 1), _c('td', [_c('b-form-group', {
    attrs: {
      "label": "Classification of Loss"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.classificationsOfLoss,
      "reduce": function reduce(classificationsOfLoss) {
        return classificationsOfLoss.code;
      }
    },
    model: {
      value: _vm.approvalForm.classification_of_loss_id,
      callback: function callback($$v) {
        _vm.$set(_vm.approvalForm, "classification_of_loss_id", $$v);
      },
      expression: "approvalForm.classification_of_loss_id"
    }
  })], 1)], 1), _c('td')])])]), _c('b-button', {
    staticClass: "mt-5 m-auto",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.onApprove();
      }
    }
  }, [_vm._v(_vm._s(_vm.detailIncident.status_id !== 3 ? 'Setujui' : 'Submit'))])], 1) : _vm.showRejectForm ? _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', {
    staticClass: "mb-3 mt-2 text-center text-white"
  }, [_c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("Reject / Pending Form")])], 1), _c('table', {
    staticClass: "table mb-3 table-borderless table-box-shadow table-warning"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Remarks")])])]), _c('tbody', [_c('tr', [_c('td', [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.rejectForm.remarks,
      expression: "rejectForm.remarks"
    }],
    staticClass: "form-control border-dark",
    attrs: {
      "rows": "3"
    },
    domProps: {
      "value": _vm.rejectForm.remarks
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.rejectForm, "remarks", $event.target.value);
      }
    }
  })])])])]), _c('b-button', {
    staticClass: "mt-5 m-auto",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.onReject();
      }
    }
  }, [_vm._v("Tolak")])], 1) : _vm._e(), _c('b-col', {
    staticClass: "mb-3 text-center",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('b-button-group', {
    staticClass: "mr-2"
  }, [![11, 12].includes(_vm.detailIncident.status.id) ? _c('b-button', {
    attrs: {
      "title": "Approval Marine Insurance",
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.handleChangeStatus(_vm.detailIncident.id, {
          code: 3
        });
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-check"
  }, [_vm._v(" Approval")])]) : _vm._e(), ![11, 12].includes(_vm.detailIncident.status.id) ? _c('b-button', {
    attrs: {
      "title": "Reject/Pending this Submit Form",
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.handleChangeStatus(_vm.detailIncident.id, {
          code: 12
        });
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-times"
  }, [_vm._v(" Reject / Pending")])]) : _vm._e(), _c('router-link', {
    attrs: {
      "to": "/mi/portal?formId=".concat(_vm.detailIncident.id)
    }
  }, [_c('b-button', {
    attrs: {
      "title": "Edit Data",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-edit"
  }, [_vm._v(" Edit")])])], 1), _c('b-button', {
    attrs: {
      "title": "Delete Data",
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.handleDeleteMi(_vm.detailIncident.id);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-trash"
  }, [_vm._v(" Delete")])])], 1)], 1)], 1) : _vm._e()], 1), _c('b-sidebar', {
    attrs: {
      "id": "sidebar-view-next",
      "title": "NEXT PROCESS",
      "width": "1400px",
      "backdrop-variant": "dark",
      "right": "",
      "backdrop": "",
      "shadow": ""
    }
  }, [_vm.detailIncident ? _c('b-row', {
    staticClass: "pt-2 pl-3 pr-3 pb-2"
  }, [_vm.detailIncident.type_form === 'claim' && _vm.activeInitial ? _c('b-col', {
    staticClass: "mb-3 bg-white",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center p-3 bg-info"
  }, [_vm._v("Initial Claim Analysis")]), _c('b-row', {
    staticClass: "mt-4 mb-3"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm.detailIncident.initialClaimAnalysis ? _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v("Already Input")]) : _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("Not Input Yet")])], 1), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "6"
    }
  }, [_vm.canAddInitial || _vm.canEditInitial ? _c('router-link', {
    attrs: {
      "to": "/mi/claim-analysis?formId=".concat(_vm.detailIncident.id)
    }
  }, [!_vm.userPermission ? _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    }
  }, [_vm._v("View InitialClaim Analysis")]) : _vm.detailIncident.initialClaimAnalysis ? _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    }
  }, [_vm._v("Edit InitialClaim Analysis")]) : _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    }
  }, [_vm._v("Input Initial Claim Analysis")])], 1) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-table-simple', [_c('b-thead', [_c('b-th', [_vm._v("Kesimpulan")]), _c('b-th', [_vm._v("Nilai Seluruhnya")]), _c('b-th', [_vm._v("Komentar / Saran")])], 1), _c('b-tbody', [_vm.detailIncident.initialClaimAnalysis ? _c('b-tr', [_c('b-td', [_vm._v(_vm._s(_vm.detailIncident.initialClaimAnalysis.conclusion))]), _c('b-td', [_vm._v(_vm._s(_vm.detailIncident.initialClaimAnalysis.value_total))]), _c('b-td', [_vm._v(_vm._s(_vm.detailIncident.initialClaimAnalysis.comments))])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm.detailIncident.type_form === 'claim' && _vm.activeCostBreakdown ? _c('b-col', {
    staticClass: "mb-3 bg-white",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center p-3 bg-info"
  }, [_vm._v("Cost Breakdown")]), _c('b-row', {
    staticClass: "mt-4 mb-3"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm.detailIncident.costBreakdown ? _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v("Already Input")]) : _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("Not Input Yet")])], 1), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "6"
    }
  }, [_vm.canAddCostBreakdown || _vm.canEditCostBreakdown ? _c('router-link', {
    attrs: {
      "to": "/mi/cost-breakdown?formId=".concat(_vm.detailIncident.id)
    }
  }, [!_vm.userPermission ? _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    }
  }, [_vm._v("View Cost Breakdown")]) : _vm.detailIncident.costBreakdown ? _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    }
  }, [_vm._v("Edit Cost Breakdown")]) : _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    }
  }, [_vm._v("Input Cost Breakdown")])], 1) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "md": "12"
    }
  }, [_vm.detailIncident.costBreakdown ? _c('b-table-simple', [_c('b-thead', [_c('b-th', [_vm._v("No")]), _c('b-th', [_vm._v("Description/Item")]), _c('b-th', [_vm._v("Value")])], 1), _c('b-tbody', _vm._l(_vm.detailIncident.costBreakdown.data, function (br, index) {
    return _c('b-tr', {
      key: index
    }, [_c('b-td', [_vm._v(_vm._s(parseInt(index) + 1))]), _c('b-td', [_vm._v(_vm._s(br.description))]), _c('b-td', [_vm._v("Rp" + _vm._s(_vm.numberFormat(br.value)))])], 1);
  }), 1), _c('b-tfoot', [_c('b-tr', [_c('b-th', {
    staticClass: "text-right",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" Total ")]), _c('b-td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('b', [_vm._v("IDR. " + _vm._s(_vm.numberFormat(_vm.subTotalCostBreakdown)))])])], 1), _c('b-tr', [_c('b-th', {
    staticClass: "text-right",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" Deductible ")]), _c('b-td', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" IDR. " + _vm._s(_vm.numberFormat(_vm.detailIncident.costBreakdown.deductible)) + " ")])], 1), _c('b-tr', [_c('b-th', {
    staticClass: "text-right",
    attrs: {
      "colspan": "2"
    }
  }, [_c('strong', [_vm._v("Grand Total")])]), _c('b-td', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" IDR. " + _vm._s(_vm.numberFormat(_vm.detailIncident.costBreakdown.grand_total)) + " ")])], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.detailIncident.type_form === 'claim' && _vm.activeLossRatio ? _c('b-col', {
    staticClass: "mb-3 bg-white",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center p-3 bg-info"
  }, [_vm._v("Loss Ratio")]), _c('b-row', {
    staticClass: "mt-4 mb-3"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [((_vm$detailIncident5 = _vm.detailIncident) === null || _vm$detailIncident5 === void 0 ? void 0 : (_vm$detailIncident5$l = _vm$detailIncident5.lostRatio) === null || _vm$detailIncident5$l === void 0 ? void 0 : _vm$detailIncident5$l.length) > 0 ? _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v("Already Input")]) : _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("Not Input Yet")])], 1), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "6"
    }
  }, [_vm.canAddLossRatio || _vm.canEditLossRatio ? _c('router-link', {
    attrs: {
      "to": "/mi/loss-ratio-input?formId=".concat(_vm.detailIncident.id)
    }
  }, [!_vm.userPermission ? _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    }
  }, [_vm._v("View Loss Ratio")]) : ((_vm$detailIncident6 = _vm.detailIncident) === null || _vm$detailIncident6 === void 0 ? void 0 : (_vm$detailIncident6$l = _vm$detailIncident6.lostRatio) === null || _vm$detailIncident6$l === void 0 ? void 0 : _vm$detailIncident6$l.length) > 0 ? _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    }
  }, [_vm._v("Edit Loss Ratio")]) : _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    }
  }, [_vm._v("Input Loss Ratio")])], 1) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "md": "12"
    }
  }, [((_vm$detailIncident7 = _vm.detailIncident) === null || _vm$detailIncident7 === void 0 ? void 0 : (_vm$detailIncident7$l = _vm$detailIncident7.lostRatio) === null || _vm$detailIncident7$l === void 0 ? void 0 : _vm$detailIncident7$l.length) > 0 ? _c('b-table-simple', [_c('b-thead', [_c('b-th', [_vm._v("Type")]), _c('b-th', [_vm._v("Premium")]), _c('b-th', [_vm._v("Loss")]), _c('b-th', [_vm._v("Ratio")])], 1), _vm._l(_vm.detailIncident.lostRatio, function (lostRatio) {
    return _c('b-tbody', {
      key: lostRatio.id
    }, [_c('b-tr', [_c('b-td', [_vm._v(_vm._s(lostRatio.type))]), _c('b-td', [_vm._v(_vm._s(_vm.numberFormat(lostRatio.premium)))]), _c('b-td', [_vm._v(_vm._s(_vm.numberFormat(lostRatio.loss)))]), _c('b-td', [_vm._v(_vm._s(lostRatio.ratio) + " %")])], 1)], 1);
  })], 2) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.detailIncident.type_form === 'claim' && _vm.activeManagementApproval ? _c('b-col', {
    staticClass: "mb-3 bg-white",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center p-3 bg-info"
  }, [_vm._v("Management Approval")]), _c('b-row', {
    staticClass: "mt-4 mb-3"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [!_vm.detailIncident.creator_fullname && !_vm.detailIncident.creator_date && !_vm.detailIncident.approver_fullname && !_vm.detailIncident.approver_date ? _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v(" Not Input Yet ")]) : _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v("Already Input")])], 1), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "6"
    }
  }, [_vm.canAddManagementApproval || _vm.canEditManagementApproval ? _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onCreateApproval(_vm.detailIncident.id);
      }
    }
  }, [_vm._v(" " + _vm._s(!_vm.detailIncident.creator_fullname && !_vm.detailIncident.creator_date && !_vm.detailIncident.approver_fullname && !_vm.detailIncident.approver_date ? 'Add' : 'Edit') + " Management Approval ")]) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "md": "12"
    }
  }, [_vm.detailIncident.creator_fullname && _vm.detailIncident.creator_date && _vm.detailIncident.approver_fullname && _vm.detailIncident.approver_date ? _c('b-table-simple', [_c('b-thead', [_c('b-th', [_vm._v("Creator Fullname")]), _c('b-th', [_vm._v("Creation Date")]), _c('b-th', [_vm._v("Aprrover Fullname")]), _c('b-th', [_vm._v("Approval Date")]), _c('b-th', [_vm._v("Approval Description")]), _c('b-th', [_vm._v("Action")])], 1), _c('b-tbody', [_c('b-tr', [_c('b-td', [_vm._v(_vm._s(_vm.detailIncident.creator_fullname))]), _c('b-td', [_vm._v(_vm._s(_vm.detailIncident.creator_date))]), _c('b-td', [_vm._v(_vm._s(_vm.detailIncident.approver_fullname))]), _c('b-td', [_vm._v(_vm._s(_vm.detailIncident.approver_date))]), _c('b-td', [_vm._v(" " + _vm._s(_vm.detailIncident.approver_description && ((_vm$detailIncident8 = _vm.detailIncident) === null || _vm$detailIncident8 === void 0 ? void 0 : (_vm$detailIncident8$a = _vm$detailIncident8.approver_description) === null || _vm$detailIncident8$a === void 0 ? void 0 : _vm$detailIncident8$a.length) > 20 ? _vm.detailIncident.approver_description.substring(0, _vm.length - 3) + '...' : _vm.detailIncident.approver_description) + " ")]), _c('b-td', [_c('b-button', {
    attrs: {
      "variant": "warning",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onPreviewApproval(_vm.detailIncident.id);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-eye"
  })])], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.detailIncident.type_form === 'claim' && _vm.activeRCA ? _c('b-col', {
    staticClass: "mb-3 bg-white",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center p-3 bg-info"
  }, [_vm._v("List RCA")]), _c('b-row', {
    staticClass: "mt-4 mb-3"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [!((_vm$detailIncident9 = _vm.detailIncident) !== null && _vm$detailIncident9 !== void 0 && (_vm$detailIncident9$r = _vm$detailIncident9.rcas) !== null && _vm$detailIncident9$r !== void 0 && _vm$detailIncident9$r.length) ? _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("Not Input Yet")]) : _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v("Already Input")])], 1), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "6"
    }
  }, [!_vm.userPermission ? _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onCreateRCA(_vm.detailIncident.id);
      }
    }
  }, [_vm._v("View RCA")]) : _vm.canAddRCA ? _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onCreateRCA(_vm.detailIncident.id);
      }
    }
  }, [_vm._v("Input RCA")]) : _vm._e()], 1), ((_vm$detailIncident10 = _vm.detailIncident) === null || _vm$detailIncident10 === void 0 ? void 0 : (_vm$detailIncident10$ = _vm$detailIncident10.rcas) === null || _vm$detailIncident10$ === void 0 ? void 0 : _vm$detailIncident10$.length) > 0 ? _c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-table-simple', [_c('b-thead', [_c('b-th', [_vm._v("Nama")]), _c('b-th', [_vm._v("Company")]), _c('b-th', [_vm._v("Gender")]), _c('b-th', [_vm._v("Posisi")]), _c('b-th', [_vm._v("Action")])], 1), _vm._l((_vm$detailIncident11 = _vm.detailIncident) === null || _vm$detailIncident11 === void 0 ? void 0 : _vm$detailIncident11.rcas, function (rca) {
    return _c('b-tbody', {
      key: rca.id
    }, [_c('b-tr', [_c('b-td', [_vm._v(_vm._s(rca.personal_fullname))]), _c('b-td', [_vm._v(_vm._s(_vm.findCompany(rca.personal_company_id)))]), _c('b-td', [_vm._v(_vm._s(rca.personal_gender === 'M' ? 'Laki - Laki' : 'Perempuan'))]), _c('b-td', [_vm._v(_vm._s(rca.personal_position))]), _c('b-td', [_vm.canEditRCA ? _c('b-button', {
      staticClass: "mr-2",
      attrs: {
        "variant": "success",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.onEditRCA(rca.id, _vm.detailIncident.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-edit"
    })]) : _vm._e(), _vm.canDeleteRCA ? _c('b-button', {
      staticClass: "mr-2",
      attrs: {
        "variant": "danger",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteRCA(rca);
        }
      }
    }, [!_vm.loading_delete_rca ? _c('i', {
      staticClass: "fa fa-trash"
    }) : [_c('span', {
      staticClass: "spinner-border spinner-border-sm",
      attrs: {
        "role": "status",
        "aria-hidden": "true"
      }
    }), _vm._v(" Deleting... ")]], 2) : _vm._e(), _c('b-button', {
      attrs: {
        "variant": "warning",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.onPreviewRCA(rca.id, _vm.detailIncident.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-eye"
    })])], 1)], 1)], 1);
  })], 2)], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.detailIncident.type_form === 'claim' && _vm.activeFinalRCA ? _c('b-col', {
    staticClass: "mb-3 bg-white",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center p-3 bg-info"
  }, [_vm._v("RCA Final")]), _c('b-row', {
    staticClass: "mt-4 mb-3"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm.detailIncident.listRcaFinal ? _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v("Already Input")]) : _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("Not Input Yet")])], 1), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "6"
    }
  }, [_vm.canAddFinalRCA || _vm.canEditFinalRCA ? [!_vm.userPermission ? _c('router-link', {
    attrs: {
      "to": "/mi/final-report-root-cause-analysis?formId=".concat(_vm.detailIncident.id)
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    }
  }, [_vm._v("View Final RCA")])], 1) : _vm.detailIncident.listRcaFinal ? _c('router-link', {
    attrs: {
      "to": "/mi/final-report-root-cause-analysis?id=".concat(_vm.detailIncident.listRcaFinal.id, "&formId=").concat(_vm.detailIncident.id)
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    }
  }, [_vm._v("Edit Final RCA")])], 1) : _c('router-link', {
    attrs: {
      "to": "/mi/final-report-root-cause-analysis?formId=".concat(_vm.detailIncident.id)
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    }
  }, [_vm._v("Input Final RCA")])], 1)] : _vm._e()], 2)], 1)], 1) : _vm._e(), _vm.detailIncident.type_form === 'claim' && _vm.activeMonitoring ? _c('b-col', {
    staticClass: "mb-3 bg-white",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center p-3 bg-info"
  }, [_vm._v("Monitoring")]), _c('b-row', {
    staticClass: "mt-4 mb-3"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [(_vm$detailIncident12 = _vm.detailIncident) !== null && _vm$detailIncident12 !== void 0 && _vm$detailIncident12.monitorings && ((_vm$detailIncident13 = _vm.detailIncident) === null || _vm$detailIncident13 === void 0 ? void 0 : (_vm$detailIncident13$ = _vm$detailIncident13.monitorings) === null || _vm$detailIncident13$ === void 0 ? void 0 : _vm$detailIncident13$.length) > 0 ? _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v("Already Input")]) : _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("Not Input Yet")])], 1), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "6"
    }
  }, [!_vm.userPermission ? _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onCreateMonitoring(_vm.detailIncident.id);
      }
    }
  }, [_vm._v(" View Monitoring ")]) : _vm.canAddMonitoring || _vm.canEditMonitoring ? _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onCreateMonitoring(_vm.detailIncident.id);
      }
    }
  }, [_vm._v(" " + _vm._s(!((_vm$detailIncident14 = _vm.detailIncident) !== null && _vm$detailIncident14 !== void 0 && (_vm$detailIncident14$ = _vm$detailIncident14.monitorings) !== null && _vm$detailIncident14$ !== void 0 && _vm$detailIncident14$.length) ? 'Input' : 'Edit') + " Monitoring ")]) : _vm._e()], 1)], 1), (_vm$detailIncident15 = _vm.detailIncident) !== null && _vm$detailIncident15 !== void 0 && (_vm$detailIncident15$ = _vm$detailIncident15.monitorings) !== null && _vm$detailIncident15$ !== void 0 && _vm$detailIncident15$.length ? _c('b-row', {
    staticClass: "mt-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-table-simple', [_c('b-thead', [_c('b-th', [_vm._v("Actual Claim Progress")]), _c('b-th', [_vm._v("Person in Charge")]), _c('b-th', [_vm._v("Total Days")]), _c('b-th', [_vm._v("Remarks")]), _c('b-th', [_vm._v("Status")])], 1), _vm._l(_vm.detailIncident.monitorings, function (monitoring) {
    return _c('b-tbody', {
      key: monitoring.id
    }, [_c('b-tr', [_c('b-td', [_vm._v(_vm._s(monitoring.actual_claim_progress))]), _c('b-td', [_vm._v(_vm._s(monitoring.person_in_charge))]), _c('b-td', [_vm._v(_vm._s(monitoring.total_days))]), _c('b-td', [_vm._v(_vm._s(monitoring.remarks))]), _c('b-td', [_vm._v(_vm._s(monitoring.status.split('_').join(' ')))])], 1)], 1);
  })], 2)], 1) : _vm._e()], 1) : _vm._e(), _vm.activeRecommendation ? _c('b-col', {
    staticClass: "mb-3 bg-white",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center p-3 bg-info"
  }, [_vm._v("Recommendation")]), _c('b-row', {
    staticClass: "mt-4 mb-3"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [(_vm$detailIncident16 = _vm.detailIncident) !== null && _vm$detailIncident16 !== void 0 && _vm$detailIncident16.recommendations && ((_vm$detailIncident17 = _vm.detailIncident) === null || _vm$detailIncident17 === void 0 ? void 0 : (_vm$detailIncident17$ = _vm$detailIncident17.recommendations) === null || _vm$detailIncident17$ === void 0 ? void 0 : _vm$detailIncident17$.length) > 0 ? _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v("Already Input")]) : _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("Not Input Yet")]), _c('b-badge', {
    staticClass: "ml-2",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.handleOpenModalEmail('Recommendation', _vm.detailIncident.id);
      }
    }
  }, [_vm._v("Send Email Notification")])], 1), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "6"
    }
  }, [!_vm.userPermission ? _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onCreateRecommendation(_vm.detailIncident.id);
      }
    }
  }, [_vm._v(" View Recommendation ")]) : _vm.canAddRecommendation || _vm.canEditRecommendation ? _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onCreateRecommendation(_vm.detailIncident.id);
      }
    }
  }, [_vm._v(" " + _vm._s(!((_vm$detailIncident18 = _vm.detailIncident) !== null && _vm$detailIncident18 !== void 0 && (_vm$detailIncident18$ = _vm$detailIncident18.recommendations) !== null && _vm$detailIncident18$ !== void 0 && _vm$detailIncident18$.length) ? 'Input' : 'Edit') + " Recommendation ")]) : _vm._e()], 1)], 1), (_vm$detailIncident19 = _vm.detailIncident) !== null && _vm$detailIncident19 !== void 0 && (_vm$detailIncident19$ = _vm$detailIncident19.recommendations) !== null && _vm$detailIncident19$ !== void 0 && _vm$detailIncident19$.length ? _c('b-row', {
    staticClass: "mt-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-table-simple', [_c('b-thead', [_c('b-th', [_vm._v("Problem")]), _c('b-th', [_vm._v("Vessel Case")]), _c('b-th', [_vm._v("Root Cause")]), _c('b-th', [_vm._v("Recommendation")]), _c('b-th', [_vm._v("Status")])], 1), _vm._l((_vm$detailIncident20 = _vm.detailIncident) === null || _vm$detailIncident20 === void 0 ? void 0 : _vm$detailIncident20.recommendations, function (recommendation) {
    return _c('b-tbody', {
      key: recommendation.id
    }, [_c('b-tr', [_c('b-td', [_vm._v(_vm._s(recommendation.problem))]), _c('b-td', [_vm._v(_vm._s(recommendation.vessel_case))]), _c('b-td', [_vm._v(_vm._s(recommendation.root_cause))]), _c('b-td', [_vm._v(_vm._s(recommendation.recommendation))]), _c('b-td', [_vm._v(_vm._s(recommendation.status))])], 1)], 1);
  })], 2)], 1) : _vm._e()], 1) : _vm._e(), _vm.activeEvaluation ? _c('b-col', {
    staticClass: "mb-3 bg-white",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center p-3 bg-info"
  }, [_vm._v("Evaluation")]), _c('b-row', {
    staticClass: "mt-4 mb-3"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [(_vm$detailIncident21 = _vm.detailIncident) !== null && _vm$detailIncident21 !== void 0 && _vm$detailIncident21.evaluations && ((_vm$detailIncident22 = _vm.detailIncident) === null || _vm$detailIncident22 === void 0 ? void 0 : (_vm$detailIncident22$ = _vm$detailIncident22.evaluations) === null || _vm$detailIncident22$ === void 0 ? void 0 : _vm$detailIncident22$.length) > 0 ? _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v("Already Input")]) : _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("Not Input Yet")]), _c('b-badge', {
    staticClass: "ml-2",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.handleOpenModalEmail('Evaluation', _vm.detailIncident.id);
      }
    }
  }, [_vm._v("Send Email Notification")])], 1), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "6"
    }
  }, [!_vm.userPermission ? _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onCreateEvaluation(_vm.detailIncident.id);
      }
    }
  }, [_vm._v(" View Evaluation ")]) : _vm.canAddEvaluation || _vm.canEditEvaluation ? _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onCreateEvaluation(_vm.detailIncident.id);
      }
    }
  }, [_vm._v(" " + _vm._s(!((_vm$detailIncident23 = _vm.detailIncident) !== null && _vm$detailIncident23 !== void 0 && (_vm$detailIncident23$ = _vm$detailIncident23.evaluations) !== null && _vm$detailIncident23$ !== void 0 && _vm$detailIncident23$.length) ? 'Input' : 'Edit') + " Evaluation ")]) : _vm._e()], 1)], 1), ((_vm$detailIncident24 = _vm.detailIncident) === null || _vm$detailIncident24 === void 0 ? void 0 : (_vm$detailIncident24$ = _vm$detailIncident24.evaluations) === null || _vm$detailIncident24$ === void 0 ? void 0 : _vm$detailIncident24$.length) > 0 ? _c('b-row', {
    staticClass: "mt-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-table-simple', [_c('b-thead', [_c('b-th', [_vm._v("Activity Stage")]), _c('b-th', [_vm._v("Milestone")]), _c('b-th', [_vm._v("Executor")]), _c('b-th', [_vm._v("Follow Up Needs")])], 1), _vm._l((_vm$detailIncident25 = _vm.detailIncident) === null || _vm$detailIncident25 === void 0 ? void 0 : _vm$detailIncident25.evaluations, function (evaluation) {
    return _c('b-tbody', {
      key: evaluation.id
    }, [_c('b-tr', [_c('b-td', [_vm._v(_vm._s(evaluation.activity_stage))]), _c('b-td', [_vm._v(_vm._s(evaluation.milestone))]), _c('b-td', [_vm._v(_vm._s(evaluation.executor))]), _c('b-td', [_vm._v(_vm._s(evaluation.follow_up_needs))])], 1)], 1);
  })], 2)], 1) : _vm._e()], 1) : _vm._e(), _vm.activeERM ? _c('b-col', {
    staticClass: "mb-3 bg-white",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center p-3 bg-info"
  }, [_vm._v("Enterprise Risk Management")]), _c('b-row', {
    staticClass: "mt-4 mb-3"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [!_vm.detailIncident.erm ? _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("Not Input Yet")]) : _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v("Already Input")])], 1), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "6"
    }
  }, [!_vm.userPermission ? _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onCreateErm(_vm.detailIncident.id);
      }
    }
  }, [_vm._v(" View Enterprise Risk Management ")]) : _vm.canAddERM || _vm.canEditERM ? _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onCreateErm(_vm.detailIncident.id);
      }
    }
  }, [_vm._v(" " + _vm._s(!_vm.detailIncident.erm ? 'Input' : 'Edit') + " Enterprise Risk Management ")]) : _vm._e()], 1), _vm.detailIncident.erm ? _c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-table-simple', [_c('b-thead', [_c('b-th', [_vm._v("Nama")]), _c('b-th', [_vm._v("Company")]), _c('b-th', [_vm._v("Action")])], 1), _c('b-tbody', [_c('b-tr', [_c('b-td', [_vm._v(_vm._s(_vm.detailIncident.erm.assessor_fullname))]), _c('b-td', [_vm._v(_vm._s(_vm.findCompany(_vm.detailIncident.erm.assessor_company_id)))]), _c('b-td', [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "success",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onEditErm(_vm.detailIncident.id);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-edit"
  })]), _c('b-button', {
    attrs: {
      "variant": "warning",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onPreviewErm(_vm.detailIncident.id);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-eye"
  })])], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.detailIncident.type_form === 'claim' && _vm.activeManagementSummary ? _c('b-col', {
    staticClass: "mb-3 bg-white",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('h5', {
    staticClass: "text-center p-3 bg-info"
  }, [_vm._v("Management Summary")]), _c('b-row', {
    staticClass: "mt-4 mb-3"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [!_vm.detailIncident.managementSummary ? _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("Not Input Yet")]) : _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v("Already Input")])], 1), _vm.canAddManagementSummary || _vm.canEditManagementSummary ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "6"
    }
  }, [_vm.detailIncident.managementSummary ? _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onCreateManagementSummary(_vm.detailIncident.id, _vm.detailIncident.managementSummary.id);
      }
    }
  }, [_vm._v("Edit Management Summary")]) : _c('b-button', {
    attrs: {
      "variant": "dark",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onCreateManagementSummary(_vm.detailIncident.id);
      }
    }
  }, [_vm._v("Input Management Summary")])], 1) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "id": "send-email",
      "size": "lg",
      "title": "Send Email ".concat(_vm.emailTitle),
      "cancel-title": "Close",
      "hide-footer": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 border my-2"
  })]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mt-2",
    attrs: {
      "label": "Notif mail to user",
      "label-for": "notifMailUser"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "placeholder": "Notif user",
      "label": "name",
      "track-by": "id",
      "options": _vm.filteredExpertUsers,
      "multiple": true
    },
    model: {
      value: _vm.notifMailUser,
      callback: function callback($$v) {
        _vm.notifMailUser = $$v;
      },
      expression: "notifMailUser"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Data not found.")])])], 1)], 1), _vm.emailTitle === 'Accident' ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mt-2",
    attrs: {
      "label": "Approval mail to user",
      "label-for": "approvalMailUser"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "placeholder": "Notif user",
      "label": "name",
      "track-by": "id",
      "options": _vm.filteredExpertUsers,
      "multiple": true
    },
    model: {
      value: _vm.approvalMailUser,
      callback: function callback($$v) {
        _vm.approvalMailUser = $$v;
      },
      expression: "approvalMailUser"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Data not found.")])])], 1)], 1) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 border my-2"
  })]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "lg": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.sendEmailToUser.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-send mr-2"
  }), _vm._v(" Send Mail")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }