<template>
  <iq-card>
    <template v-slot:headerTitle>
      <h5 class="card-title text-primary">
        <strong>DOCKING FINISH</strong>
      </h5>
    </template>
    <template v-slot:body>
      <!-- FILTER DOCKING PROGRESS-->
      <b-row class="mb-3">
        <b-col md="2">
          <b-form-group label="Fleet Category" label-for="fleet_category" placeholder="Select Fleet category">
            <v-select 
              v-model="lists.params.vehicleTypeIds" 
              multiple 
              label="name"
              :options="vehicleTypes" 
              :reduce="type => type.id"
              @input="onChangeFleetCategory"
            />
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Vehicles" label-for="vehicleId" >
            <v-select
              v-model="lists.params.vehicleId"
              label="text"
              :options="fleetFilteredOptions"
              :reduce="(fleetFilteredOptions) => fleetFilteredOptions.value"
              placeholder="Select Vehicle"
            ></v-select>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Docking Type" label-for="docking_type">
              <b-form-select plain v-model="lists.params.dockingType" :options="dockingTypes" size="lg">
                <template v-slot:first>
                <b-form-select-option :value="null">Select Docking Type</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Period Docking (Arrival)" label-for="Period">
            <date-picker :lang="lang" v-model="lists.dateRange" type="date" range placeholder="Select date range"></date-picker>
          </b-form-group>
        </b-col>
        <b-col md="1">
          <b-form-group label="Filter" label-for="button_search">
            <b-button variant="primary" class="mb-3 mr-1"><i class="fa fa-filter"></i></b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- FILTER DOCKING PROGRESS END -->
      <table class="table table-striped table-sm">
        <thead>
          <tr>
            <th class="text-center">FLEET NAME</th>
            <th class="text-center">TOTAL COST</th>
            <th class="text-center">TOTAL JOBS</th>
            <th class="text-center">PERFOMANCE INDICATOR</th>
            <th class="text-center">INTERMEDIATE SURVEY</th>
            <th class="text-center">SPESIAL SURVEY</th>
            <th class="text-center">ACTION</th>
          </tr>
        </thead>
        <tbody> 
          <tr v-for="(state, index) in lists.data" :key="index">
            <td class="text-center text-uppercase">{{ state?.vehicleName }}</td>
            <td class="text-center">{{ formatCurrency(state?.totalCost) }}</td>
            <td class="text-center">{{ state?.jobCount }} Jobs</td>
            <td class="text-center">
              <b-badge variant="success" class="mr-1">On Time : {{ state?.onTimeJob }} Jobs</b-badge>
              <b-badge variant="danger">Delay : {{ state?.delayJob }} Jobs</b-badge>
            </td>
            <td class="text-center">{{ state?.intermediate }} Jobs</td>
            <td class="text-center">{{ state?.special }} Jobs</td>
            <td class="text-center">
              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                  </span>
                </template>
                <b-dropdown-item href="#" disabled>
                  <router-link to="/templates/docking-history" >
                    <i class="fa fa-ship mr-2"></i>Detail History
                  </router-link>
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="mt-3">
          <b-pagination
            v-model="lists.params.page"
            :total-rows="lists.meta.total"
            :per-page="lists.meta.perPage"
            align="center"
            @input="getDockingScheduleFinishData"
          ></b-pagination>
        </div>
    </template>
  </iq-card>
</template>

<script>
import moment from 'moment'
import { dockingActions, fleetsActions } from '@src/Utils/helper'

export default {
  name: 'DockingDashboardFinish',
  data () {
    return {
      dockingTypes: ['SPECIAL', 'INTERMEDIATE', 'EMERGENCY'],
      fleetFilteredOptions: [],
      fleetOptions: [],
      vehicleTypes: [],
      lists: {
        loading: true,
        data: [],
        dateRange: [],
        params: {
          page: 1,
          perPage: 25,
          vehicleTypeIds: [],
          dockingType: '',
          periodeStart: '',
          periodeEnd: '',
          dockingStatus: '',
          vehicleId:null
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
    }
  },
  mounted () {
    this.fetchFleetTypes()
    this.fetchFleets()
    this.getDockingScheduleFinishData()
  },
  methods: {
    ...fleetsActions,
    ...dockingActions,
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
    onChangeFleetCategory(){
      let tempData = this.fleetOptions

      tempData = tempData.filter(obj => this.lists.params.vehicleTypeIds.includes(obj.vehicleType.id))
      
      if(this.lists.params.vehicleTypeIds.length <= 0){
        this.fetchFleets()
      } else {
        this.fleetFilteredOptions = tempData
      }
    },
    async fetchFleets() {
      let params = {
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
      }

      let res = await this.getFleets(params)

      if (res.status) {
        if (res.data && res.data.length > 0) {
          this.fleetOptions = res.data.map((val) => ({
            ...val,
            text: val.name,
            value: val.id,
          }))
          this.fleetFilteredOptions = this.fleetOptions
        }
      }
    },
    async fetchFleetTypes () {
      let params = { active: true }
      let res = await this.getFleetTypes(params)

      let data = []

      if (res.data) {
        data = res.data
      }

      this.vehicleTypes = data
    },

    async getDockingScheduleFinishData () {
      this.lists.loading = true
      this.lists.data = []

      const params = {
        ...this.lists.params,
        vehicleTypeIds: this.lists.params.vehicleTypeIds.join(','),
        periodeStart:this.lists.dateRange?.[0] ?? '',
        periodeEnd:this.lists.dateRange?.[1] ?? '' ,
      }

      if (params.dockingType === '') {
        delete params.dockingType
      }
      if (params.dockingStatus === '') {
        delete params.dockingStatus
      }
      if (params.vehicleTypeIds === '') {
        delete params.vehicleTypeIds
      }
      if (params.periodeStart === '') {
        delete params.periodeStart
      }
      if (params.periodeEnd === '') {
        delete params.periodeEnd
      }
      if (params.vehicleId === '') {
        delete params.vehicleId
      }

      const { status, data } = await this.getFinishDockingSchedules(params)

      if (status === 'success') {
        if (data.data) {
          this.lists.data = data.data
          this.lists.meta.perPage = data.perPage
          this.lists.meta.currentPage = data.currentPage
          this.lists.meta.total = data.total
          this.lists.meta.totalPage = data.totalPage
        }
        this.lists.loading = false
      } else {
        this.lists.data = []
        this.lists.loading = false
        this.$swal(
          `Oops!`,
          data,
          'error'
        )
      }
    }
  }
}
</script>

<style scoped>

</style>
