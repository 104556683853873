var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-user-profile-block1"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "user-details-block"
        }, [_c('div', {
          staticClass: "mt-5 pt-4 pull-right"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "text": "Small",
            "variant": "light",
            "right": ""
          }
        }, [_c('template', {
          slot: "button-content"
        }, [_c('i', {
          staticClass: "fa fa-ellipsis-h"
        })]), _vm.permissionEditFleet ? _c('b-dropdown-item', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.fetchFleetDetail(_vm.fleetData.id);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-edit"
        }), _vm._v(" Edit")]) : _vm._e(), _vm.permissionDeleteFleet ? _c('b-dropdown-item', {
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.onDeleteFleet(_vm.fleetData);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-trash"
        }), _vm._v(" Delete")]) : _vm._e()], 2)], 1), _c('div', {
          staticClass: "user-profile text-center"
        }, [_vm.fleetData.img !== null || _vm.fleetData.img !== 'null' || _vm.fleetData.img !== 'undefined' ? _c('img', {
          staticClass: "avatar-130 img-fluid",
          attrs: {
            "src": _vm.fleetData.vehicleType.icon.url,
            "alt": ""
          }
        }) : _c('img', {
          staticClass: "avatar-130 img-fluid",
          attrs: {
            "src": _vm.fleetData.img,
            "alt": ""
          }
        })]), _c('div', {
          staticClass: "text-center"
        }, [_c('h4', [_c('b', [_vm._v(_vm._s(_vm.fleetData.name))])]), _c('p', [_vm._v(_vm._s(_vm.fleetData.company.company))])]), _c('ul', {
          staticClass: "doctoe-sedual d-flex align-items-center justify-content-between p-0 mt-4 mb-0"
        }, [_c('li', {
          staticClass: "text-center"
        }, [_c('h6', {
          staticClass: "text-primary"
        }, [_vm._v("Year & Build")]), _c('h5', [_vm.fleetData.yearBuildCountry === null || _vm.fleetData.yearBuildCountry === 'null' || _vm.fleetData.yearBuildCountry === 'undefined' ? [_vm._v("-")] : [_vm._v(_vm._s(_vm.fleetData.yearBuildCountry))]], 2)]), _c('li', {
          staticClass: "text-center"
        }, [_c('h6', {
          staticClass: "text-primary"
        }, [_vm._v("Flag")]), _c('h5', [_vm.fleetData.flag === null || _vm.fleetData.flag === 'null' || _vm.fleetData.flag === 'undefined' ? [_vm._v("-")] : [_vm._v(_vm._s(_vm.fleetData.flag))]], 2)]), _c('li', {
          staticClass: "text-center"
        }, [_c('h6', {
          staticClass: "text-primary"
        }, [_vm._v("Complete Date")]), _c('h5', [_vm.fleetData.deliveryDate === null || _vm.fleetData.deliveryDate === 'null' || _vm.fleetData.deliveryDate === 'undefined' ? [_vm._v("-")] : [_vm._v(_vm._s(_vm.fleetData.deliveryDate))]], 2)])])])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_vm._v("Fleet Document")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('ul', {
          staticClass: "speciality-list m-0 p-0"
        }, [_vm._l(_vm.fleetData.docs, function (doc, index) {
          return [_c('li', {
            key: index,
            staticClass: "d-flex mb-4 align-items-center"
          }, [_c('div', {
            staticClass: "user-img img-fluid"
          }, [_c('a', {
            staticClass: "iq-bg-primary",
            attrs: {
              "href": "#"
            }
          }, [_c('i', {
            staticClass: "ri-award-fill"
          })])]), _c('div', {
            staticClass: "media-support-info ml-3"
          }, [_c('h6', [_vm._v(_vm._s(doc.iconName) + "Ship Particular")]), _c('span', {
            staticClass: "text-primary",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.handleOpenFile(doc.url);
              }
            }
          }, [_vm._v("Download File")])])])];
        })], 2)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-cad-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_vm._v("Active Crew")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$fleetData$name;
        return [_c('ul', {
          staticClass: "doctors-lists m-0 p-0"
        }, _vm._l(_vm.crewActiveInFleet, function (crew) {
          var _crew$position$name, _crew$position;
          return _c('li', {
            key: crew.id,
            staticClass: "d-flex mb-4 align-items-center"
          }, [_c('div', {
            staticClass: "user-img img-fluid"
          }, [_c('img', {
            staticClass: "rounded-circle avatar-40",
            attrs: {
              "src": crew !== null && crew !== void 0 && crew.photo ? crew === null || crew === void 0 ? void 0 : crew.photo : _vm.defaultPhoto,
              "alt": "story-img"
            }
          })]), _c('div', {
            staticClass: "media-support-info ml-3"
          }, [_c('h6', [_vm._v(_vm._s(crew === null || crew === void 0 ? void 0 : crew.fullname))]), _c('p', {
            staticClass: "mb-0 font-size-12"
          }, [_vm._v(_vm._s((_crew$position$name = crew === null || crew === void 0 ? void 0 : (_crew$position = crew.position) === null || _crew$position === void 0 ? void 0 : _crew$position.name) !== null && _crew$position$name !== void 0 ? _crew$position$name : '-'))])]), _c('div', {
            staticClass: "iq-card-header-toolbar d-flex align-items-center"
          }, [_c('b-dropdown', {
            attrs: {
              "size": "lg",
              "variant": "link",
              "toggle-class": "p-0 text-decoration-none",
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_c('span', {
                  staticClass: "dropdown-toggle p-0",
                  attrs: {
                    "id": "dropdownMenuButton6",
                    "data-toggle": "dropdown"
                  }
                }, [_c('i', {
                  staticClass: "ri-more-2-line"
                })])];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                return _vm.$router.push({
                  path: "/crewing/profile",
                  query: {
                    user: 'crew',
                    id: crew.id
                  }
                });
              }
            }
          }, [_c('i', {
            staticClass: "ri-eye-line mr-2"
          }), _vm._v("View Detail")])], 1)], 1)]);
        }), 0), _c('router-link', {
          attrs: {
            "to": "/ops/fleet-crew"
          }
        }, [_c('div', {
          staticClass: "btn btn-primary d-block mt-3"
        }, [_c('i', {
          staticClass: "fa fa-search"
        }), _vm._v(" VIEW CREW " + _vm._s((_vm$fleetData$name = _vm.fleetData.name) !== null && _vm$fleetData$name !== void 0 ? _vm$fleetData$name : '-'))])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "pb-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-borderless table-striped"
        }, [_c('tbody', [_c('tr', [_c('td', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Code")]), _c('td', {
          attrs: {
            "width": "65%"
          }
        }, [_c('strong', [_vm.fleetData.code === null || _vm.fleetData.code === 'null' || _vm.fleetData.code === 'undefined' ? [_vm._v("-")] : [_vm._v(_vm._s(_vm.fleetData.code))]], 2)])]), _c('tr', [_c('td', [_vm._v("Ownership")]), _c('td', [_c('strong', [_vm.fleetData.ownership === null || _vm.fleetData.ownership === 'null' || _vm.fleetData.ownership === 'undefined' ? [_vm._v("-")] : [_vm._v(_vm._s(_vm.fleetData.ownership))]], 2)])]), _c('tr', [_c('td', [_vm._v("Technical Module")]), _c('td', [_c('strong', [_vm.fleetData.technical === true ? [_vm._v("Yes")] : [_vm._v("No")]], 2)])]), _c('tr', [_c('td', [_vm._v("ID GPS")]), _c('td', [_c('strong', [_vm.fleetData.idGps === null || _vm.fleetData.idGps === 'null' || _vm.fleetData.idGps === 'undefined' ? [_vm._v("-")] : [_vm._v(_vm._s(_vm.fleetData.idGps))]], 2)])]), _c('tr', [_c('td', [_vm._v("Classification")]), _c('td', [_c('strong', [_vm.fleetData.classification === null || _vm.fleetData.classification === 'null' || _vm.fleetData.classification === 'undefined' ? [_vm._v("-")] : [_vm._v(_vm._s(_vm.fleetData.classification))]], 2)])]), _c('tr', [_c('td', [_vm._v("Basic Function")]), _c('td', [_c('strong', [_vm.fleetData.basicFunction === null || _vm.fleetData.basicFunction === 'null' || _vm.fleetData.basicFunction === 'undefined' ? [_vm._v("-")] : [_vm._v(_vm._s(_vm.fleetData.basicFunction))]], 2)])])])])])]), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('nav', {
          attrs: {
            "aria-label": "breadcrumb "
          }
        }, [_c('ol', {
          staticClass: "breadcrumb bg-primary"
        }, [_c('li', {
          staticClass: "breadcrumb-item active text-white",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("Dimension")])])]), _c('table', {
          staticClass: "table mb-2 table-borderless table-box-shadow"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Dimensions")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Feet")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Meters")])])]), _c('tbody', [_c('tr', [_c('td', [_c('h5', [_vm._v("Length")])]), _c('td', [_vm._v(_vm._s(_vm.fleetData.dimension.length.feet))]), _c('td', [_vm._v(_vm._s(_vm.fleetData.dimension.length.meter))])]), _c('tr', [_c('td', [_c('h5', [_vm._v("Width")])]), _c('td', [_vm._v(_vm._s(_vm.fleetData.dimension.width.feet))]), _c('td', [_vm._v(_vm._s(_vm.fleetData.dimension.width.meter))])]), _c('tr', [_c('td', [_c('h5', [_vm._v("Depth")])]), _c('td', [_vm._v(_vm._s(_vm.fleetData.dimension.depth.feet))]), _c('td', [_vm._v(_vm._s(_vm.fleetData.dimension.depth.meter))])]), _c('tr', [_c('td', [_c('h5', [_vm._v("Draft")])]), _c('td', [_vm._v(_vm._s(_vm.fleetData.dimension.draft.feet))]), _c('td', [_vm._v(_vm._s(_vm.fleetData.dimension.draft.meter))])]), _c('tr', [_c('td', [_c('h5', [_vm._v("Speed")])]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "2"
          }
        }, [_vm.fleetData.dimension.speed === null || _vm.fleetData.dimension.speed === 'null' || _vm.fleetData.dimension.speed === 'undefined' ? [_vm._v("-")] : [_vm._v(_vm._s(_vm.fleetData.dimension.speed))]], 2)])])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('nav', {
          attrs: {
            "aria-label": "breadcrumb "
          }
        }, [_c('ol', {
          staticClass: "breadcrumb bg-primary"
        }, [_c('li', {
          staticClass: "breadcrumb-item active text-white",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("POWER")])])]), _c('ol', {
          attrs: {
            "type": "numbered"
          }
        }, [_vm._l(_vm.fleetData.powers, function (pwr, index) {
          return [_c('li', {
            key: index
          }, [_vm._v(_vm._s(pwr.powerType.name) + " - " + _vm._s(pwr.powerSeries.name) + " "), _c('small', [_vm._v(_vm._s(pwr.powerSeries.model))]), _c('span', {
            staticClass: "pull-right"
          }, [_vm._v("Actual RH: "), _c('strong', [_vm._v(_vm._s(pwr.actualRunningHours) + " RH")])])])];
        })], 2)]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('nav', {
          attrs: {
            "aria-label": "breadcrumb "
          }
        }, [_c('ol', {
          staticClass: "breadcrumb bg-primary"
        }, [_c('li', {
          staticClass: "breadcrumb-item active text-white",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("ROB Type")])])]), _c('ol', {
          attrs: {
            "type": "numbered"
          }
        }, [_vm._l(_vm.fleetData.robs, function (pwr, index) {
          return [_c('li', {
            key: index
          }, [_vm._v(_vm._s(pwr.robType.name) + " - " + _vm._s(pwr.robSeries.name) + " "), _c('span', {
            staticClass: "pull-right"
          }, [_vm._v("Current ROB: "), _c('strong', [_vm._v(_vm._s(pwr.actualRob) + " RH")])])])];
        })], 2)])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/ops/certificate"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h5', {
          staticClass: "card-title mb-2 mt-2 text-primary"
        }, [_c('strong', [_vm._v("TOTAL CERTIFICATE")])]), _c('h3', {
          staticClass: "mb-0 line-height"
        }, [_vm._v(_vm._s(_vm.statsCertificateData.totalCertificate !== null ? _vm.statsCertificateData.totalCertificate : '-'))])]), _c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "las la-certificate"
        })])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/ops/certificate"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h5', {
          staticClass: "card-title mb-2 mt-2 text-primary"
        }, [_c('strong', [_vm._v("EXPIRED")])]), _c('h3', {
          staticClass: "mb-0 line-height"
        }, [_vm._v(_vm._s(_vm.statsCertificateData.totalExpired !== null ? _vm.statsCertificateData.totalExpired : '-'))])]), _c('div', {
          staticClass: "rounded-circle iq-card-icon bg-danger"
        }, [_c('i', {
          staticClass: "las la-exclamation"
        })])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/ops/certificate"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h5', {
          staticClass: "card-title mb-2 mt-2 text-primary"
        }, [_c('strong', [_vm._v("1 BULAN AKAN EXPIRED")])]), _c('h3', {
          staticClass: "mb-0 line-height"
        }, [_vm._v(_vm._s(_vm.statsCertificateData.totalOneMonth !== null ? _vm.statsCertificateData.totalOneMonth : '-'))])]), _c('div', {
          staticClass: "rounded-circle iq-card-icon bg-warning"
        }, [_c('i', {
          staticClass: "las la-tasks"
        })])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h5', {
          staticClass: "card-title mb-2 mt-2 text-primary"
        }, [_c('strong', [_vm._v("PROGRESS")])]), _c('h3', {
          staticClass: "mb-0 line-height"
        }, [_vm._v(_vm._s(_vm.statsCertificateData.totalProcess !== null ? _vm.statsCertificateData.totalProcess : '-'))])]), _c('div', {
          staticClass: "rounded-circle iq-card-icon bg-success"
        }, [_c('i', {
          staticClass: "fa fa-clock-o"
        })])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "pb-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('tab-nav', {
          staticClass: "text-primary",
          attrs: {
            "tabs": true
          }
        }, [_c('tab-nav-items', {
          attrs: {
            "active": true,
            "href": "#operational",
            "title": "OPERATIONAL"
          }
        }), _c('tab-nav-items', {
          attrs: {
            "active": false,
            "href": "#certificate",
            "title": "CERTIFICATE"
          }
        }), _c('tab-nav-items', {
          attrs: {
            "active": false,
            "href": "#maintenance-board",
            "title": "MAINTENANCE BOAR"
          }
        }), _c('tab-nav-items', {
          attrs: {
            "active": false,
            "href": "#maintenance-init",
            "title": "MAINTENANCE INIT"
          }
        }), _c('tab-nav-items', {
          attrs: {
            "active": false,
            "href": "#service-repair",
            "title": "SERVICE & REPAIR"
          }
        }), _c('tab-nav-items', {
          attrs: {
            "active": false,
            "href": "#list-initial",
            "title": "ACCIDENT"
          }
        }), _c('tab-nav-items', {
          attrs: {
            "active": false,
            "href": "#recommendation",
            "title": "RECOMENDATION"
          }
        }), _c('tab-nav-items', {
          attrs: {
            "active": false,
            "href": "#crewing",
            "title": "CREWING"
          }
        })], 1), _c('div', {
          staticClass: "tab-content",
          attrs: {
            "id": "myTabContent"
          }
        }, [_c('tab-content-item', {
          attrs: {
            "active": true,
            "id": "operational"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('iq-card', {
          attrs: {
            "body-class": "pb-0"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function fn() {
              return [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "8",
                  "lg": "8"
                }
              }, [_c('div', {
                staticStyle: {
                  "height": "100%",
                  "width": "100%",
                  "position": "relative",
                  "margin-left": "-30px",
                  "z-index": "9999"
                }
              }, [_c('l-map', {
                staticStyle: {
                  "width": "100%",
                  "height": "100%"
                },
                attrs: {
                  "zoom": _vm.zoom,
                  "center": _vm.center,
                  "options": _vm.mapOptions
                },
                on: {
                  "update:center": _vm.centerUpdate,
                  "update:zoom": _vm.zoomUpdate
                }
              }, [_c('l-tile-layer', {
                attrs: {
                  "url": _vm.url,
                  "attribution": _vm.attribution
                }
              }), _c('l-marker', {
                attrs: {
                  "lat-lng": _vm.getLatLng(_vm.fleetData.latitude, _vm.fleetData.longitude)
                }
              }, [_c('l-icon', {
                attrs: {
                  "icon-size": _vm.iconSize,
                  "icon-anchor": _vm.iconAnchor
                }
              }, [_c('img', {
                staticStyle: {
                  "width": "72px"
                },
                attrs: {
                  "src": _vm.getIcon(_vm.fleetData.vehicleType.icon)
                }
              })]), _c('l-tooltip', {
                attrs: {
                  "options": {
                    permanent: false,
                    interactive: true
                  }
                }
              }, [_vm._v(_vm._s(_vm.fleetData.name))])], 1), _c('l-control-zoom', {
                attrs: {
                  "position": "bottomleft"
                }
              })], 1)], 1)]), _c('b-col', {
                attrs: {
                  "md": "4",
                  "lg": "4"
                }
              }, [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-cad-height"
                },
                scopedSlots: _vm._u([{
                  key: "headerTitle",
                  fn: function fn() {
                    return [_c('h4', {
                      staticClass: "card-title"
                    }, [_vm._v("Last Operational")])];
                  },
                  proxy: true
                }, {
                  key: "body",
                  fn: function fn() {
                    return [_c('ul', {
                      staticClass: "iq-timeline m-0 p-0",
                      staticStyle: {
                        "height": "340px"
                      }
                    }, _vm._l(_vm.activityList, function (each) {
                      return _c('li', {
                        key: each.id
                      }, [_c('b-row', {
                        staticClass: "m-0"
                      }, [_c('b-col', {
                        attrs: {
                          "md": "12"
                        }
                      }, [_c('div', {
                        staticClass: "timeline-dots-fill"
                      }), _c('h6', {
                        staticClass: "float-left mb-2 text-dark"
                      }, [_c('i', {
                        staticClass: "ri-time-fill"
                      }), _vm._v(" " + _vm._s(each.startDate + ' - ' + each.endDate))]), _c('div', {
                        staticClass: "d-inline-block w-100"
                      }, [_c('b-badge', {
                        attrs: {
                          "variant": "info"
                        }
                      }, [_vm._v(_vm._s(each.activityDurationFormat))]), _c('p', [_vm._v(_vm._s(each.activityName))])], 1)])], 1)], 1);
                    }), 0)];
                  },
                  proxy: true
                }])
              })], 1)], 1), _c('hr'), _vm.vehicleId ? _c('b-row', {
                staticClass: "mt-5"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('Operational', {
                attrs: {
                  "isChildComponent": true
                }
              })], 1)], 1) : _vm._e()];
            },
            proxy: true
          }])
        })], 1)], 1)], 1), _c('tab-content-item', {
          attrs: {
            "active": false,
            "id": "certificate"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('Certificate', {
          attrs: {
            "isChildComponent": true
          }
        })], 1)], 1)], 1), _c('tab-content-item', {
          attrs: {
            "active": false,
            "id": "maintenance-board"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('MaintenanceSummaryBoard')], 1)], 1)], 1), _c('tab-content-item', {
          attrs: {
            "active": false,
            "id": "maintenance-init"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('MaintenanceSummaryInit')], 1)], 1)], 1), _c('tab-content-item', {
          attrs: {
            "active": false,
            "id": "service-repair"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('ServiceRepairSummary', {
          attrs: {
            "fleetId": _vm.$route.query.vehicleId
          }
        })], 1)], 1)], 1), _c('tab-content-item', {
          attrs: {
            "active": false,
            "id": "list-initial"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('ListInitialTable', {
          attrs: {
            "vehicle-id": _vm.vehicleId
          }
        })], 1)], 1)], 1), _c('tab-content-item', {
          attrs: {
            "active": false,
            "id": "recommendation"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('ListInitialRecommendation', {
          attrs: {
            "vehicle-id": _vm.vehicleId
          }
        })], 1)], 1)], 1), _c('tab-content-item', {
          attrs: {
            "active": false,
            "id": "crewing"
          }
        }, [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": true,
            "href": "#active_crew",
            "title": "CREW ACTIVE ".concat(_vm.fleetData.name, " (CURRENT)")
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": false,
            "href": "#history_crew",
            "title": "HISTORY CREW ".concat(_vm.fleetData.name)
          }
        })], 1)], 1), _c('div', {
          staticClass: "iq-edit-list-data"
        }, [_c('div', {
          staticClass: "tab-content",
          attrs: {
            "id": "pills-tabContent-2"
          }
        }, [_c('tab-content-item', {
          attrs: {
            "active": true,
            "id": "active_crew"
          }
        }, [_c('b-row', {
          staticClass: "mt-5"
        }, [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Jabatan",
            "label-for": "clasification"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsJabatan,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Clasification")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedJabatan,
            callback: function callback($$v) {
              _vm.selectedJabatan = $$v;
            },
            expression: "selectedJabatan"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "search"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Type Text ..."
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1), _c('b-row', {
          staticClass: "mt-3"
        }, _vm._l(_vm.crewActiveInFleet, function (crew) {
          return _c('b-col', {
            key: crew.user_id,
            attrs: {
              "sm": "12",
              "md": "2"
            }
          }, [_c('iq-card', {
            attrs: {
              "body-class": "text-center"
            },
            scopedSlots: _vm._u([{
              key: "body",
              fn: function fn() {
                var _crew$personal_data, _crew$personal_data$s, _crew$personal_data2, _crew$position$name2, _crew$position2;
                return [_c('div', {
                  staticClass: "doc-profile"
                }, [_c('img', {
                  staticClass: "rounded-circle img-fluid avatar-80",
                  attrs: {
                    "src": crew === null || crew === void 0 ? void 0 : crew.photo,
                    "alt": "profile"
                  }
                })]), _c('div', {
                  staticClass: "iq-doc-info mt-3"
                }, [_c('h4', [_vm._v(" " + _vm._s(crew === null || crew === void 0 ? void 0 : (_crew$personal_data = crew.personal_data) === null || _crew$personal_data === void 0 ? void 0 : _crew$personal_data.fullname))]), _c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(_vm._s((_crew$personal_data$s = crew === null || crew === void 0 ? void 0 : (_crew$personal_data2 = crew.personal_data) === null || _crew$personal_data2 === void 0 ? void 0 : _crew$personal_data2.seaferer_code) !== null && _crew$personal_data$s !== void 0 ? _crew$personal_data$s : '-'))]), _c('p', [_vm._v(_vm._s((_crew$position$name2 = crew === null || crew === void 0 ? void 0 : (_crew$position2 = crew.position) === null || _crew$position2 === void 0 ? void 0 : _crew$position2.name) !== null && _crew$position$name2 !== void 0 ? _crew$position$name2 : '-'))])]), _c('span', {
                  staticClass: "btn btn-primary",
                  on: {
                    "click": function click($event) {
                      return _vm.$router.push({
                        path: "/crewing/profile",
                        query: {
                          user: 'crew',
                          id: crew.id
                        }
                      });
                    }
                  }
                }, [_vm._v("View Profile")])];
              },
              proxy: true
            }], null, true)
          })], 1);
        }), 1)], 1), _c('tab-content-item', {
          attrs: {
            "active": false,
            "id": "history_crew"
          }
        }, [_c('b-row', {
          staticClass: "mt-5"
        }, [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Jabatan",
            "label-for": "clasification"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsJabatan,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Clasification")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedJabatan,
            callback: function callback($$v) {
              _vm.selectedJabatan = $$v;
            },
            expression: "selectedJabatan"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "search"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Type Text ..."
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1), _c('b-row', {
          staticClass: "mt-3"
        }, _vm._l(_vm.crewHistoryInFleet, function (crew) {
          return _c('b-col', {
            key: crew.user_id,
            attrs: {
              "sm": "12",
              "md": "2"
            }
          }, [_c('iq-card', {
            attrs: {
              "body-class": "text-center"
            },
            scopedSlots: _vm._u([{
              key: "body",
              fn: function fn() {
                var _crew$personal_data$s2, _crew$personal_data3, _crew$position$name3, _crew$position3;
                return [_c('div', {
                  staticClass: "doc-profile"
                }, [_c('img', {
                  staticClass: "rounded-circle img-fluid avatar-80",
                  attrs: {
                    "src": crew === null || crew === void 0 ? void 0 : crew.photo,
                    "alt": "profile"
                  }
                })]), _c('div', {
                  staticClass: "iq-doc-info mt-3"
                }, [_c('h4', [_vm._v(" " + _vm._s(crew === null || crew === void 0 ? void 0 : crew.fullname))]), _c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(_vm._s((_crew$personal_data$s2 = crew === null || crew === void 0 ? void 0 : (_crew$personal_data3 = crew.personal_data) === null || _crew$personal_data3 === void 0 ? void 0 : _crew$personal_data3.seaferer_code) !== null && _crew$personal_data$s2 !== void 0 ? _crew$personal_data$s2 : '-'))]), _c('p', [_vm._v(_vm._s((_crew$position$name3 = crew === null || crew === void 0 ? void 0 : (_crew$position3 = crew.position) === null || _crew$position3 === void 0 ? void 0 : _crew$position3.name) !== null && _crew$position$name3 !== void 0 ? _crew$position$name3 : '-'))])]), _c('span', {
                  staticClass: "btn btn-primary",
                  on: {
                    "click": function click($event) {
                      return _vm.$router.push({
                        path: "/crewing/profile",
                        query: {
                          user: 'crew',
                          id: crew.id
                        }
                      });
                    }
                  }
                }, [_vm._v("View Profile")])];
              },
              proxy: true
            }], null, true)
          })], 1);
        }), 1)], 1)], 1)])])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "edit-fleet",
      "size": "xl",
      "no-close-on-backdrop": true,
      "header-bg-variant": _vm.modal.headerBgVariant,
      "header-text-variant": _vm.modal.headerTextVariant,
      "body-bg-variant": _vm.modal.bodyBgVariant,
      "body-text-variant": _vm.modal.bodyTextVariant,
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('h5', [_vm._v("Edit Your Fleet")]), _c('b-button', {
          attrs: {
            "size": "md",
            "variant": "outline-danger"
          },
          on: {
            "click": close
          }
        }, [_c('i', {
          staticClass: "fa fa-times"
        }), _vm._v(" Close ")])];
      }
    }])
  }, [_c('AddFleet', {
    attrs: {
      "formData": _vm.fleetDetail
    },
    on: {
      "childAction": function childAction($event) {
        return _vm.$bvModal.hide('edit-fleet');
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }