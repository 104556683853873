<template>
  <div>
    <iq-card>
      <template v-slot:headerTitle>
        <h5 class="card-title text-primary"><strong>DOCKING SCHEDULE</strong></h5>
      </template>
      <template v-slot:headerAction>
        <b-button variant="primary" @click="showInitDockingFleetModal = true" class="mr-1"><i class="fa fa-edit"></i> &nbsp; CREATE EMERGENCY DOCKING</b-button>
        <a href="#" @click="() => {showSetDockingScheduleModal = true; scheduleType = 'intermerdiate'}" class="btn btn-primary mr-2"><i class="fa fa-calendar mr-2"></i>SET SCHEDULE DOCKING</a>
  <!--      <router-link to="/ops/docking-create"></router-link>-->
        <a class="text-primary float-right ml-3" v-b-toggle.docking_schedule_card role="button" style="cursor: pointer">
          <i class="fa fa-filter" />
        </a>
  
        <!-- MODAL ALL FLEET -->
        <ModalSelectFleets
          v-model="showInitDockingFleetModal"
          :multiple="false"
          @submit="onOpenModalCreateDocking"
        />
        <!-- MODAL ALL FLEET END -->
  
        <!-- MODAL CREATE EMERGENCY DOCKING -->
        <ModalDocking
          v-model="showDockingModal"
          :action="dockingAction"
          :vehicle-id="selectedVehicleId"
          :docking-id="selectedDockingId"
          :docking-data="lists.data"
          @cancel="onCloseModalCreateDocking"
          @submit="onSubmitCreateDocking"
        />
        <!-- MODAL CREATE EMERGENCY DOCKING END -->
  
        <!-- SETUP SCHEDULE FROM CALENDAR -->
        <ModalDockingSetSchedule
          v-model="showSetDockingScheduleModal"
          :type-schedule="scheduleType"
          @cancel="onCloseSetDockingScheduleModal()"
          @submit="onSubmitSetDockingSchedule()"
          :fetch-unscheduled-docking="fetchUnscheduledDocking"
        />
        <!-- SETUP SCHEDULE FROM CALENDAR END -->
  
        <!-- PENDING DOCKING -->
        <ModalDockingPending
          v-model="showPendingDockingModal"
          @cancel="onClosePendingDocking()"
          @submit="onSubmitPendingDocking()"
          :docking-id="selectedDockingId"
          :delivery-date-docking="deliveryDateDocking"
        />
        <!-- PENDING DOCKING END -->
      </template>
      <template v-slot:body>
        <b-collapse visible id="docking_schedule_card" class="mb-2">
          <!-- FILTER DOCKING SCHEDULE-->
          <b-row>
            <b-col md="2">
              <b-form-group label="Fleet Category" label-for="fleet_category" placeholder="Select Fleet category">
                <v-select 
                  v-model="lists.params.vehicleTypeIds" 
                  multiple 
                  label="name"
                  :options="vehicleTypes" :reduce="type => type.id"
                  @input="onChangeFleetCategory"
                />
              </b-form-group>
            </b-col>
            <b-col lg="2">
              <b-form-group label="Vehicles" label-for="vehicleId" >
                <v-select
                  v-model="lists.params.vehicleId"
                  label="text"
                  :options="fleetFilteredOptions"
                  :reduce="(fleetFilteredOptions) => fleetFilteredOptions.value"
                  placeholder="Select Vehicle"
                ></v-select>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Docking Type" label-for="docking_type">
                <v-select v-model="lists.params.dockingType" :options="dockingTypes">
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Status" label-for="status">
                <v-select v-model="lists.params.dockingStatus" :options="dockingStatus">
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Period" label-for="Period">
                <date-picker style="width: 100%" @change="onFilterChangeRangeDate()" v-model="lists.dateRange" format="DD MMM YYYY" type="date" range placeholder="Select date range"></date-picker>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group label="Filter" label-for="button_search">
                <b-button variant="primary" @click="getDockingScheduleActiveData()" class="mb-3 mr-1"><i class="fa fa-filter"></i></b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- FILTER DOCKING SCHEDULE END -->
  
          <!-- DOCKING SCHEDULE -->
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="text-center">NO</th>
              <th class="text-center">FLEET</th>
              <th class="text-center">DOCKING TYPE</th>
              <th class="text-center">REMAINING DAYS</th>
              <th class="text-center">REMAINING DATE</th>
              <th class="text-center">STATUS</th>
              <th class="text-center">ACTION</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="lists.loading">
              <td class="text-center" colspan="7">Loading...</td>
            </tr>
            <template v-else>
              <tr v-for="(data, index) in lists.data"
                  :class="{
                    'bg-danger' : ['OVER_SCHEDULE'].includes(data.status),
                    'bg-info' : ['PROGRESS'].includes(data.status),
                    'bg-warning' : ['REVIEW'].includes(data.status),
                    'bg-primary' : ['APPROVE'].includes(data.status),
                  }"
                  :key="index">
                <th class="text-center">{{index + 1}}</th>
                <td class="text-center">{{ data.vehicle.name }}</td>
                <td class="text-center">{{ data.dockingType }}</td>
                <td class="text-center">{{ data.remainingDays ? `${data.remainingDays} days` : '-' }}</td>
                <td class="text-center" v-if="data.status === 'PENDING'">{{ data.deliveryDate }}</td>
                <td class="text-center" v-else>{{ data.dockingSchedule }}</td>
                <template v-if="['PROGRESS', 'REVIEW', 'APPROVE'].includes(data.status)">
                  <td class="text-center" colspan="2">
                    <strong v-if="data.status === 'PROGRESS'">PROGRESS - CHECK TAB PROGRESS</strong>
                    <strong v-if="data.status === 'REVIEW'">NEED REVIEW - CHECK TAB PROGRESS</strong>
                    <strong v-if="data.status === 'APPROVE'">NEED APPROVE - CHECK TAB PROGRESS</strong>
                  </td>
                </template>
                <template v-else>
                  <td class="text-center">
                    <b-badge v-if="['ACTIVE'].includes(data.status)" variant="success d-block">ACTIVE</b-badge>
                    <b-badge v-else-if="['DUE_DATE'].includes(data.status)" variant="warning d-block">DUE DATE</b-badge>
                    <b-badge v-else-if="['PENDING'].includes(data.status)" variant="primary d-block">PENDING {{ data?.numberOfPending }}</b-badge>
                    <b-badge v-else-if="['OVER_SCHEDULE'].includes(data.status)" variant="light d-block">OVER SCHEDULE</b-badge>
                    <b-badge v-else-if="['REQUEST_DELETED'].includes(data.status)" variant="danger d-block">REQUEST DELETED</b-badge>
                  </td>
                  <td class="text-center">
                    <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                          <i class="fa fa-ship m-0 text-primary"></i>
                        </span>
                      </template>
                      <b-dropdown-item v-if="['DUE_DATE', 'PENDING', 'OVER_SCHEDULE'].includes(data.status)" @click="onOpenModalDocking(data, 'CREATE_FROM_SCHEDULE')" variant="primary"><i class="fa fa-pencil-square-o mr-2"></i>DOCKING</b-dropdown-item>
                      <b-dropdown-item v-if="['DUE_DATE', 'PENDING', 'OVER_SCHEDULE'].includes(data.status)" @click="onOpenModalPendingDocking(data)" variant="danger"><i class="fa fa-clock mr-2"></i>PENDING</b-dropdown-item>
                      <b-dropdown-item href="#" variant="dark"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item href="#" variant="danger" @click="() => onDeleteDocking(data.id, 'deleteRequest')" v-if="data?.status !== 'REQUEST_DELETED'"><i class="fa fa-trash mr-2"></i>DELETE SCHEDULE</b-dropdown-item>
                      <b-dropdown-item href="#" variant="danger" @click="() => onDeleteDocking(data.id, 'deletePermanent', true)" v-if="data?.status === 'REQUEST_DELETED' && userDeleteApprovalPermission"><i class="fa fa-check mr-2"></i>APPROVE DELETE SCHEDULE</b-dropdown-item>
                      <b-dropdown-item href="#" variant="danger" @click="() => onDeleteDocking(data.id, 'deletePermanent', false)" v-if="data?.status === 'REQUEST_DELETED' && userDeleteApprovalPermission"><i class="fa fa-ban mr-2"></i>REJECT DELETE SCHEDULE</b-dropdown-item>
                    </b-dropdown>
                  </td>
                </template>
              </tr>
            </template>
            </tbody>
          </table>
          <div class="mt-3">
            <b-pagination
              v-model="lists.params.page"
              :total-rows="lists.meta.total"
              :per-page="lists.meta.perPage"
              align="center"
              @input="getDockingScheduleActiveData"
            ></b-pagination>
          </div>
          <!-- DOCKING SCHEDULE END -->
        </b-collapse>
      </template>
    </iq-card>
    
    <iq-card>
      <template v-slot:headerTitle>
        <h5 class="card-title text-primary"><strong>DAFTAR KAPAL BELUM TERSISTEM DOCKING</strong></h5>
      </template>
      <template v-slot:body>
        <b-row class="mb-4">
          <b-col md="4" class="text-right">
            <b-form-input
              type="text"
              placeholder="Search fleets..."
              v-model="searchFleet"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row v-if="unscheduledDockingVehicle.length > 0" class="container-fleets">
          <b-col
            md="3"
            class="text-center py-5 border-top"
            v-for="(fleet, i) in filteredData"
            :key="`fleet-${i}`"
          >
            <div>
              <label :for="`ck-fl-${i}`">
                <!-- <img
                  :src="fleet?.img?.url ? fleet?.img?.url : require('@src/assets/images/fleet/PusherBarge.png')"
                  alt="#"
                  class="img-fluid"
                /> -->
                <img
                  :src="require('@src/assets/images/fleet/PusherBarge.png')"
                  alt="#"
                  class="img-fluid"
                />
              </label>
              <small><strong>{{ fleet.name }}</strong></small> <br />
              <b-badge v-if="fleet.unscheduledDocking.includes('INTERMEDIATE')" variant="danger" class="p-2 mb-2" style="cursor: pointer;" @click="() => {showSetDockingScheduleModal = true; scheduleType = 'intermerdiate'}"><i class="fa fa-ship m-0 mr-2"></i> Set intermediate Survey</b-badge> <br />
              <b-badge v-if="fleet.unscheduledDocking.includes('SPECIAL')" variant="primary" class="p-2" style="cursor: pointer;" @click="() => {showSetDockingScheduleModal = true; scheduleType = 'special_value'}"><i class="fa fa-ship m-0 mr-2"></i> Set Special Survey</b-badge>
            </div>
          </b-col>
        </b-row>
        <div v-else class="text-center my-5">
          <p class="mb-0 text-muted">No fleets data found.</p>
        </div>
      </template>
    </iq-card>
  </div>
</template>

<script>
import ModalSelectFleets from '@components/Modal/ModalSelectFleets'
import ModalDockingSetSchedule from '@components/Docking/ModalDockingSetSchedule'
import moment from 'moment'
import { dockingActions, fleetsActions } from '@src/Utils/helper'
import ModalDocking from '@components/Docking/ModalDocking'
import ModalDockingPending from '@components/Docking/ModalDockingPending'
export default {
  name: 'DockingDashboardSchedule',
  components: { ModalDockingPending, ModalDocking, ModalDockingSetSchedule, ModalSelectFleets },
  props:{
    onGlobalRefresh: {
      type: Function,
      default: true
    },
    globalRefresh: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      searchFleet: '',
      scheduleType: '',
      deliveryDateDocking:null,
      showInitDockingFleetModal: false,
      showDockingModal: false,
      showSetDockingScheduleModal: false,
      showPendingDockingModal: false,
      dockingAction: 'CREATE_EMERGENCY',
      lists: {
        loading: true,
        data: [],
        dateRange: [],
        params: {
          page: 1,
          perPage: 25,
          vehicleTypeIds: [],
          dockingType: '',
          periodeStart: '',
          periodeEnd: '',
          dockingStatus: '',
          vehicleId:null
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      unscheduledDockingVehicle: [],
      fleetOptions: [],
      fleetFilteredOptions: [],
      vehicleTypes: [],
      dockingTypes: ['SPECIAL', 'INTERMEDIATE', 'EMERGENCY'],
      dockingStatus: ['ACTIVE', 'DUE_DATE', 'PENDING', 'PROGRESS', 'APPROVE', 'REVIEW', 'SEA_TRIAL', 'FINISH', 'REJECT', 'OVER_SCHEDULE'],
      selectedDockingId: null,
      selectedVehicleId: null,
    }
  },
  async mounted () {
    this.fetchFleets()
    this.onFilterChangeRangeDate()
   await this.fetchUnscheduledDocking()
    this.fetchFleetTypes()
    this.getDockingScheduleActiveData()

    if(this.$route.query?.typeSchedule){
      if(this.$route.query?.typeSchedule === 'intermerdiate'){
        this.showSetDockingScheduleModal = true; 
        this.scheduleType = 'intermerdiate'
      }
      if(this.$route.query?.typeSchedule === 'special_value'){
        this.showSetDockingScheduleModal = true; 
        this.scheduleType = 'special_value'
      }
    }
  },
  computed: {
    filteredData() {
      // Perform the search on the data array based on the searchTerm
      const searchTermLower = this.searchFleet.toLowerCase();
      return this.unscheduledDockingVehicle.filter((item) =>
        item.name.toLowerCase().includes(searchTermLower)
      );
    },
  },
  methods: {
    ...fleetsActions,
    ...dockingActions,
    userDeleteApprovalPermission(){
      let permission = JSON.parse(localStorage.getItem('profile')).menu.find(obj => obj.menu === 'Apps').child.find(obj => obj.menu === 'Technical').child.find(obj => obj.menu === 'Docking')

      return permission.approval
    },

    onOpenModalCreateDocking (data) {
      this.selectedVehicleId = data
      this.showDockingModal = true
      this.showInitDockingFleetModal = false
      console.log(data, 'modal docking create')
    },
    onOpenModalDocking (data, action) {
      this.selectedVehicleId = data.vehicle.id
      this.selectedDockingId = data.id
      this.dockingAction = action
      this.showDockingModal = true
    },
    onOpenModalPendingDocking (data) {
      this.selectedVehicleId = data.vehicle.id
      this.selectedDockingId = data.id
      this.deliveryDateDocking = data.deliveryDate
      this.showPendingDockingModal = true
    },
    onCloseModalCreateDocking () {
      this.selectedVehicleId = null
      this.selectedDockingId = null
      this.showDockingModal = false
      this.showInitDockingFleetModal = false
    },
    onClosePendingDocking () {
      this.showPendingDockingModal = false
      this.selectedVehicleId = null
      this.selectedDockingId = null
    },
    onCloseSetDockingScheduleModal () {
      this.showSetDockingScheduleModal = false
    },
    onSubmitSetDockingSchedule () {
      this.getDockingScheduleActiveData()
      this.showSetDockingScheduleModal = false
    },
    onSubmitCreateDocking () {
      // location.reload()
      this.getDockingScheduleActiveData()
      this.onCloseModalCreateDocking()
      this.onGlobalRefresh()
    },
    onSubmitPendingDocking () {
      this.getDockingScheduleActiveData()
      this.onClosePendingDocking()
    },
    onFilterChangeRangeDate () {
      this.lists.params.periodeStart = this.lists.dateRange[0] ? moment(this.lists.dateRange[0]).format('DD-MM-YYYY') : ''
      this.lists.params.periodeEnd = this.lists.dateRange[1] ? moment(this.lists.dateRange[1]).format('DD-MM-YYYY') : ''
    },
    onChangeFleetCategory(){
      let tempData = this.fleetOptions

      tempData = tempData.filter(obj => this.lists.params.vehicleTypeIds.includes(obj.vehicleType.id))
      
      if(this.lists.params.vehicleTypeIds.length <= 0){
        this.fetchFleets()
      } else {
        this.fleetFilteredOptions = tempData
      }
    },
    async fetchFleets() {
      let params = {
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
      }

      let res = await this.getFleets(params)

      if (res.status) {
        if (res.data && res.data.length > 0) {
          this.fleetOptions = res.data.map((val) => ({
            ...val,
            text: val.name,
            value: val.id,
          }))
          this.fleetFilteredOptions = this.fleetOptions
        }
      }
    },
    async fetchUnscheduledDocking () {
      let params = {
        page: 1,
        perPage: 1000
      }
      let res = await this.getUnscheduledDockingFleet(params)
      let data = []

      if (res.status == 'success') {
        data = res.data
      }

      this.unscheduledDockingVehicle = data
    },
    async fetchFleetTypes () {
      let params = { active: true }
      let res = await this.getFleetTypes(params)

      let data = []

      if (res.data) {
        data = res.data
      }

      this.vehicleTypes = data
    },
    async getDockingScheduleActiveData () {
      this.lists.loading = true
      this.lists.data = []

      const params = {
        ...this.lists.params,
        vehicleTypeIds: this.lists.params.vehicleTypeIds.join(',')
      }

      if (!params.dockingType) delete params.dockingType
      if (!params.dockingStatus) delete params.dockingStatus
      if (!params.vehicleTypeIds) delete params.vehicleTypeIds
      if (!params.periodeStart) delete params.periodeStart
      if (!params.periodeEnd) delete params.periodeEnd
      if (!params.vehicleId) delete params.vehicleId

      const { status, data } = await this.getActiveDockingSchedules(params)

      if (status === 'success') {
        if (data.data) {
          this.lists.data = data.data
          this.lists.meta.perPage = data.perPage
          this.lists.meta.currentPage = data.currentPage
          this.lists.meta.total = data.total
          this.lists.meta.totalPage = data.totalPage
        }
        this.lists.loading = false
      } else {
        this.lists.data = []
        this.lists.loading = false
        this.$swal(
          `Oops!`,
          data,
          'error'
        )
      }
    },
    async onDeleteDocking(id, key, value){
      if(key === 'deletePermanent'){
        this.$swal({
          title: value ? `Are you sure want to approve delete this Docking Schedule?` : `Are you sure want to reject delete this Docking Schedule?`,
          text: "Yes, Im sure.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: value ? "Yes, Approve it." : "No, Reject it.",
      }).then(async (result) => {
          if (result.isConfirmed) {
            let params = {
              approved: value
            }
            let res = await this.approvalDeleteDockingScheduleById({id, params})
            if(res.status === true || res.status == 'success'){
              this.$swal(
                `Success.`,
                'Berhasil menghapus data.',
                'error'
              )
              this.getDockingScheduleActiveData()
            } else {
              this.$swal(
                `Oops!`,
                res?.data,
                'error'
              )
          }
        }
      })
      } else {
        this.$swal({
          title: `Are you sure want to delete this Docking Schedule?`,
          text: "Yes, i'm sure.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
          if (result.isConfirmed) {
            let res = await this.deleteDockingScheduleById({id})
            if(res.status === true || res.status == 'success'){
              this.$swal(
                `Success.`,
                'Berhasil menghapus data.',
                'error'
              )
              this.getDockingScheduleActiveData()
            } else {
              this.$swal(
                `Oops!`,
                res?.data,
                'error'
              )
          }
        }
      })
      }
    },
  }
}
</script>

<style scoped>

</style>
