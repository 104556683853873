var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("INSPECTION CHECKLIST")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center gap-4 float-right"
        }, [_c('a', {
          staticClass: "text-primary mr-4",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          },
          on: {
            "click": _vm.getListInspectionChecklist
          }
        }, [_c('i', {
          staticClass: "fa fa-rotate-right mr-2"
        }), _c('span', [_vm._v("Refresh Data")])]), _vm.addPermission ? _c('a', {
          staticClass: "text-primary mr-4",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          },
          on: {
            "click": _vm.addInspectionChecklist
          }
        }, [_c('i', {
          staticClass: "fa fa-plus mr-2"
        }), _c('span', [_vm._v("Create Data")])]) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$listInspectionChe, _vm$listInspectionChe2, _vm$listInspectionChe3, _vm$listInspectionChe4;
        return [_c('b-row', {
          staticClass: "mb-4"
        }, [_c('b-col', {
          attrs: {
            "md": "2"
          }
        }), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }), _c('b-col', {
          attrs: {
            "md": "3"
          }
        })], 1), _vm.loading ? _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1)])], 1) : _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_vm.loading ? _c('div', {
          staticClass: "alert alert-info"
        }, [_vm._v("Loading...")]) : _vm._e(), _c('iq-card', [((_vm$listInspectionChe = _vm.listInspectionChecklist) === null || _vm$listInspectionChe === void 0 ? void 0 : (_vm$listInspectionChe2 = _vm$listInspectionChe.data) === null || _vm$listInspectionChe2 === void 0 ? void 0 : _vm$listInspectionChe2.length) > 0 ? _c('div', {
          staticClass: "d-flex"
        }, [_c('table', {
          staticClass: "table table-striped table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center align-middle",
          staticStyle: {
            "width": "20px"
          }
        }, [_vm._v("No")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Fleet")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Code/Name")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Field")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Signature")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Status")]), _c('th', {
          staticClass: "text-center align-middle",
          staticStyle: {
            "width": "20px"
          }
        }, [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.listInspectionChecklist.data, function (state, index) {
          var _state$vehicle, _state$vehicle2, _state$vehicle2$vehic, _state$collection_of_, _state$collection_of_2;
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_c('div', {
            staticClass: "d-flex flex-column"
          }, [_c('strong', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$vehicle = state.vehicle) === null || _state$vehicle === void 0 ? void 0 : _state$vehicle.name))]), _c('small', [_vm._v("(" + _vm._s(state === null || state === void 0 ? void 0 : (_state$vehicle2 = state.vehicle) === null || _state$vehicle2 === void 0 ? void 0 : (_state$vehicle2$vehic = _state$vehicle2.vehicle_type) === null || _state$vehicle2$vehic === void 0 ? void 0 : _state$vehicle2$vehic.name) + ")")])])]), _c('td', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$collection_of_ = state.collection_of_forms) === null || _state$collection_of_ === void 0 ? void 0 : _state$collection_of_.code) + " - " + _vm._s(state === null || state === void 0 ? void 0 : (_state$collection_of_2 = state.collection_of_forms) === null || _state$collection_of_2 === void 0 ? void 0 : _state$collection_of_2.name))]), _c('td', [_c('div', {
            staticClass: "d-flex flex-column"
          }, _vm._l(state === null || state === void 0 ? void 0 : state.field, function (field, fieldIndex) {
            return _c('div', {
              key: fieldIndex,
              staticClass: "d-flex mb-1"
            }, [_c('strong', {
              staticClass: "mr-2"
            }, [_vm._v(_vm._s(field === null || field === void 0 ? void 0 : field.label) + ": ")]), _c('span', [_vm._v(_vm._s(field !== null && field !== void 0 && field.value ? _vm.handleTypeDate(field === null || field === void 0 ? void 0 : field.value) : '-'))])]);
          }), 0)]), _c('td', [_c('div', {
            staticClass: "d-flex flex-column"
          }, _vm._l(state === null || state === void 0 ? void 0 : state.signature, function (signature, signatureIndex) {
            return _c('div', {
              key: signatureIndex,
              staticClass: "d-flex mb-1"
            }, [_c('strong', {
              staticClass: "mr-2"
            }, [_vm._v(_vm._s(signature === null || signature === void 0 ? void 0 : signature.label) + ": ")]), _c('span', [_vm._v(_vm._s(signature !== null && signature !== void 0 && signature.value ? _vm.handleTypeDate(signature === null || signature === void 0 ? void 0 : signature.value) : '-'))])]);
          }), 0)]), _c('td', [_c('strong', {
            staticClass: "text-primary text-capitalize"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.status))])]), _vm.updatePermission || _vm.deletePermission ? _c('td', [_c('b-dropdown', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.top",
              value: 'Action',
              expression: "'Action'",
              modifiers: {
                "top": true
              }
            }],
            staticClass: "float-right mr-1",
            attrs: {
              "variant": "link iq-bg-primary",
              "toggle-class": "text-decoration-none",
              "no-caret": "",
              "size": "sm"
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_c('span', {
                  staticClass: "dropdown-toggle p-0",
                  attrs: {
                    "id": "dropdownMenuButton5",
                    "data-toggle": "dropdown"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-pen m-0"
                })])];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.detailInspectionChecklist(state === null || state === void 0 ? void 0 : state.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-eye mr-2"
          }), _vm._v("Detail")]), _c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteInspectionChecklist(state);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash mr-2"
          }), _vm._v("Delete")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.onExport(state === null || state === void 0 ? void 0 : state.id, 'pdf');
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-file-pdf mr-2"
          }), _vm._v("Export PDF")]), _c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "primary",
              "disabled": ""
            },
            on: {
              "click": function click($event) {
                return _vm.onExport(state === null || state === void 0 ? void 0 : state.id, 'excel');
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-file-excel mr-2"
          }), _vm._v("Export Excel")])], 1)], 1) : _vm._e()]);
        }), 0)])]) : _vm._e(), ((_vm$listInspectionChe3 = _vm.listInspectionChecklist) === null || _vm$listInspectionChe3 === void 0 ? void 0 : (_vm$listInspectionChe4 = _vm$listInspectionChe3.data) === null || _vm$listInspectionChe4 === void 0 ? void 0 : _vm$listInspectionChe4.length) > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.listInspectionChecklist.meta.totalItem,
            "per-page": _vm.listInspectionChecklist.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getListInspectionChecklist
          },
          model: {
            value: _vm.listInspectionChecklist.meta.page,
            callback: function callback($$v) {
              _vm.$set(_vm.listInspectionChecklist.meta, "page", $$v);
            },
            expression: "listInspectionChecklist.meta.page"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No Inspection Checklist Data Found.")])])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "add-inspection-checklist",
      "title": "".concat(_vm.isEdit ? 'EDIT' : 'CREATE', " INSPECTION CHECKLIST"),
      "width": "80%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('FormInspectionChecklist', {
          attrs: {
            "reset-form-to-initial-value": _vm.resetFormToInitialValue
          }
        })], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.showAddInspectionChecklist,
      callback: function callback($$v) {
        _vm.showAddInspectionChecklist = $$v;
      },
      expression: "showAddInspectionChecklist"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "detail-edit-inspection-checklist",
      "title": "".concat(_vm.isEdit ? 'EDIT' : 'DETAIL', " INSPECTION CHECKLIST"),
      "width": "80%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var hide = _ref2.hide;
        return [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('DetailEditInspectionChecklist', {
          attrs: {
            "is-edit": _vm.isEdit,
            "edit-id": _vm.editId,
            "reset-form-to-initial-value": _vm.resetFormToInitialValue
          }
        })], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.showDetailEditInspectionChecklist,
      callback: function callback($$v) {
        _vm.showDetailEditInspectionChecklist = $$v;
      },
      expression: "showDetailEditInspectionChecklist"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }