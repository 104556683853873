<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>CUSTOMERS</strong>
            </h4>
          </template>
          <template v-slot:headerAction>
            <div class="d-flex align-items-center gap-4 float-right">
              <a class="text-primary mr-4" role="button" style="cursor: pointer" @click="openCustomerMaster">
                <i class="fa fa-home mr-2" />
                <span>Masters Customer</span>
              </a>
              <a class="text-primary mr-4" role="button" style="cursor: pointer" @click="onExportCustomer">
                <i class="fa fa-file-excel mr-2" />
                <span>Export Data</span>
              </a>
              <a class="text-primary mr-4" role="button" style="cursor: pointer" @click="addCustomer" v-if="addPermission">
                <i class="fa fa-plus mr-2" />
                <span>Create Data</span>
              </a>
            </div>
          </template>
          <template v-slot:body>
            <!-- FILTERING CUSTOMERS -->
            <b-row class="mb-4">
              <b-col md="3">
                <b-form-group label="Search" label-for="search" v-model="params.search">
                  <b-form-input
                    type="text"
                    placeholder="Search ID/Name..."
                    v-model="params.search"
                    name="search"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Customer Class" label-for="customer_class_id">
                  <b-form-select plain  size="sm" 
                    v-model="params.customer_class_id"
                    name="customer_class_id"
                    :options="optionsCustomerClass"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null">All</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Customer Status" label-for="customer_status">
                  <b-form-select plain  size="sm" 
                    v-model="params.customer_status"
                    name="customer_status"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="''">All</b-form-select-option>
                      <b-form-select-option :value="'active'">Active</b-form-select-option>
                      <b-form-select-option :value="'on-hold'">On Hold</b-form-select-option>
                      <b-form-select-option :value="'credit-hold'">Credit Hold</b-form-select-option>
                      <b-form-select-option :value="'one-time'">One-Time</b-form-select-option>
                      <b-form-select-option :value="'inactive'">Inactive</b-form-select-option>
                      <b-form-select-option :value="'not-recommended'">Not Recommended</b-form-select-option>
                      <b-form-select-option :value="'blacklist'">Blacklist</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="2" />
              <b-col md="1">
                <b-form-group label="Filter" label-for="button_search">
                  <b-button
                    @click="getListCustomer"
                    type="button"
                    variant="primary"
                    ><i class="fa fa-filter"></i
                  ></b-button>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- TABLE CONTENT -->
            <b-row>
              <b-col md="12">
                <div v-if="loading" class="alert alert-info">Loading...</div>
                <iq-card> 
                  <div class="overflow-auto d-flex" v-if="listsCustomer?.data?.length > 0">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                            <th class="text-center align-middle" style="width: 20px">No</th>
                            <th class="text-left align-middle text-truncate">Customer ID</th>
                            <th class="text-left align-middle text-truncate">Customer Name</th>
                            <th class="text-left align-middle text-truncate">Customer Class</th>
                            <th class="text-left align-middle text-truncate">Country</th>
                            <th class="text-left align-middle text-truncate">City</th>
                            <!-- <th class="text-left align-middle text-truncate">Currency ID</th> -->
                            <th class="text-left align-middle text-truncate">Terms</th>
                            <th class="text-left align-middle text-truncate">Customer Status</th>
                            <th class="text-left align-middle">Address</th>
                            <!-- <th class="text-left align-middle">Statement Cycle ID</th> -->
                            <!-- <th class="text-left align-middle">Curr. Rate Type</th> -->
                            <th class="text-left align-middle">Phone 1</th>
                            <th class="text-left align-middle">Phone 2</th>
                            <th class="text-left align-middle">Email</th>
                            <th class="text-center align-middle" style="width: 20px">#</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(customer, customerIndex) in listsCustomer.data" :key="customerIndex">
                          <td>{{ customerIndex + 1 }}</td>
                          <td class="text-truncate">{{ customer?.customer_code }}</td>
                          <td>{{ customer?.account_name }}</td>
                          <td>{{ customer?.customer_class?.name ?? '-' }}</td>
                          <td>{{ customer?.country_id ?? '-' }}</td>
                          <td>{{ customer?.city }}</td>
                          <!-- <td>-</td> -->
                          <td>{{ customer?.customer_shipping?.shipping_terms?.code ?? '-' }}</td>
                          <td class="text-truncate text-capitalize ">{{ customer?.customer_status }}</td>
                          <td>{{ customer?.address }}</td>
                          <!-- <td>-</td> -->
                          <!-- <td>-</td> -->
                          <td>{{ customer?.phone1 }}</td>
                          <td>{{ customer?.phone2 }}</td>
                          <td>{{ customer?.email }}</td>
                          <td v-if="updatePermission || deletePermission">
                            <div class="d-flex align-items-center">
                              <i class="fa fa-pen text-primary mr-3" style="cursor: pointer;" @click="editCustomer(customer?.id)" />
                              <i class="fa fa-trash text-danger" style="cursor: pointer;" @click="onDeleteCustomer(customer)" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="mt-3" v-if="listsCustomer?.data?.length > 0">
                    <b-pagination
                      v-model="params.page"
                      :total-rows="listsCustomer?.meta?.totalItem"
                      @change="getListCustomer"
                      :per-page="listsCustomer?.meta?.perPage"
                      first-number
                      align="center"
                    ></b-pagination>
                  </div>
                  <p v-else class="text-center my-4">No customer data found.</p>
                </iq-card>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>

      <b-col lg="12">
        <b-sidebar v-model="showAddCustomer" id="add-customer" :title="`${isEdit ? 'EDIT' : 'ADD'} CUSTOMER`" width="100%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
          <template #default="{hide}">
            <b-row class="m-3">
              <b-col cols="12">
                <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Customers</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <FormCustomer 
                          :show-add-customer="showAddCustomer" 
                          :is-edit="isEdit" 
                          :edit-id="editId" 
                          :refresh-data="refreshData" 
                          :optionsCustomerClass="optionsCustomerClass"
                        />
                      </b-col>
                    </b-row>
                </fieldset>
              </b-col>
            </b-row>
            </template>
        </b-sidebar>
      </b-col>

      <b-col lg="12">
        <b-sidebar v-model="showCustomerMaster" id="customer-master" :title="`CUSTOMERS MASTERS`"  width="100%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
          <template #default="{hide}">
              <b-row class="m-3">
                <b-col cols="12">
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Customer Masters</legend>
                      <b-row class="mt-2">
                          <b-col md="12">
                            <ContentCustomerMasters :refreshMasterData="refreshMasterData"   />
                          </b-col>
                      </b-row>
                  </fieldset>
                </b-col>
              </b-row>
            </template>
        </b-sidebar>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import _ from 'lodash'
  import {
    fleetsActions,
  } from '@src/Utils/helper'
  import { createPopper } from '@popperjs/core'
  import moment from 'moment'
  import FormCustomer from './FormCustomer.vue'
  import ContentCustomerMasters from './ContentCustomerMasters.vue'
  import { busdevActions } from '@src/Utils/helper'

  export default {
    name: 'Customer',
    data() {
      return {
        exporting:false,
        loading:false,
        showCustomerMaster:false,
        showAddCustomer:false,
        optionsCustomerClass:[],

        isEdit:false,
        editId:null,

        params:{
          page: 1,
          perPage:25,
          customer_class_id:null,
          customer_status:'',
          search:'',
        },

        listsCustomer: {
          data:[],
          meta:null
        }
      }
    },
    components:{
      FormCustomer,
      ContentCustomerMasters
    },
    watch: {},
    computed: {
      addPermission(){
        let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
          .find(obj => obj.menu === 'Apps').child
          .find(obj => obj.menu === 'Business Development').child
          .find(obj => obj.menu === 'Busdev Customer').add

        return hasAccess
      },
      updatePermission(){
        let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
          .find(obj => obj.menu === 'Apps').child
          .find(obj => obj.menu === 'Business Development').child
          .find(obj => obj.menu === 'Busdev Customer').update

        return hasAccess
      },
      deletePermission(){
        let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
          .find(obj => obj.menu === 'Apps').child
          .find(obj => obj.menu === 'Business Development').child
          .find(obj => obj.menu === 'Busdev Customer').delete

        return hasAccess
      },
    },
    async mounted() {
      this.getListCustomerClass()
      this.getListCustomer()
    },
    created () {
      // this.getListCustomer = _.debounce(this.getListCustomer, 500)
    },
    methods: {
      ...busdevActions,
      refreshData(){
        this.getListCustomer()
      },
      refreshMasterData(){
        this.getListCustomerClass()
      },
      openCustomerMaster(){
        this.showCustomerMaster = true
        this.$root.$emit('bv::toggle::collapse', `customer-master`)
      }, 
      addCustomer(){
        this.isEdit = false
        this.editId = null

        this.showAddCustomer = true
        this.$root.$emit('bv::toggle::collapse', `add-customer`)
      }, 
      editCustomer(id){
        this.isEdit = true
        this.editId = id

        this.showAddCustomer = true
        this.$root.$emit('bv::toggle::collapse', `add-customer`)
      },
      refreshData(){
        this.showAddCustomer = false
        this.$root.$emit('bv::toggle::collapse', `add-customer`)

        this.getListCustomer()
      },
      
      async getListCustomerClass() {
        let res = await this.fetchCustomerClass()
        if (res.data.status === true) {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.map((state) => {
              this.optionsCustomerClass.push({
                value: state.id,
                text: state.name,
              })
            })
          }
        }
      },
      async getListCustomer(page) {
        let params = {
          page: page || 1,
          ...this.params,
        }

        if(!params.customer_class_id) delete params.customer_class_id
        if(!params.customer_status) delete params.customer_status
        if(!params.search) delete params.search

        let res = await this.fetchCustomer(params)

        if (res.data.status === true && res.data.data?.data.length > 0) {
          this.listsCustomer.data = res.data.data?.data
          this.listsCustomer.meta = res.data.data?.meta
        } else {
          this.listsCustomer.data = []
          this.listsCustomer.meta = null
        }
      },
      async onExportCustomer() {
        this.exporting = true
        try {
          let params = {
            ...this.params,
          }

          if(!params.customer_class_id) delete params.customer_class_id
          if(!params.customer_status) delete params.customer_status
          if(!params.search) delete params.search

          let res = await this.exportCustomer(params)

          if(res.status === 'success'){
            saveAs(res.data, 'List Customers.xlsx')
          }
        } catch (error) {
          this.$swal(
            'Failed Export!',
            'Something went wrong.',
            'error'
          ) 
        }

        this.exporting = false
      },

      async onDeleteCustomer(data){
        this.$swal({
            title: `Are you sure want to delete Customer Code ${data?.customer_code} ?`,
            text: "Yes, i'm sure.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
            const res = await this.deleteCustomer({id:data.id})
            if(res.status === false || res.status === 400 || res.status === 500){
                this.$swal(
                    'Failed!',
                    'Something went wrong.',
                    'error'
                )
                return 
            } 
                await this.getListCustomer()
                this.$swal(
                    'Success!',
                    'Success delete Customer.',
                    'success'
                )
            }
        })
      },
    }
  }
</script>