var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tab-nav', {
    attrs: {
      "tabs": true,
      "align": "center"
    }
  }, [_c('tab-nav-items', {
    attrs: {
      "active": true,
      "href": "#category",
      "title": "Master Data Category"
    }
  }), _c('tab-nav-items', {
    attrs: {
      "active": false,
      "href": "#question",
      "title": "Master Data Question"
    }
  })], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "myTabContent2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "category"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MASTER DATA CATEGORY")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.getInspectionChecklistCategory();
            }
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Master Data Category:",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.categoryList.params.name,
            callback: function callback($$v) {
              _vm.$set(_vm.categoryList.params, "name", $$v);
            },
            expression: "categoryList.params.name"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Master Data Category")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.categoryList._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.categoryList.data && _vm.categoryList.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Name")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Alias")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Maintenance Component")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Vehicle Type Section")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.categoryList.data, function (p, i) {
          var _p$maintenance_compon, _p$maintenance_compon2, _p$vehicle_type_secti, _p$vehicle_type_secti2;
          return _c('tr', {
            key: "p-".concat(i)
          }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(_vm._s(p.name))]), _c('td', [_vm._v(_vm._s(p.alias))]), _c('td', [_vm._v(_vm._s((_p$maintenance_compon = (_p$maintenance_compon2 = p.maintenance_component) === null || _p$maintenance_compon2 === void 0 ? void 0 : _p$maintenance_compon2.component_name) !== null && _p$maintenance_compon !== void 0 ? _p$maintenance_compon : '-'))]), _c('td', [_vm._v(_vm._s((_p$vehicle_type_secti = (_p$vehicle_type_secti2 = p.vehicle_type_section) === null || _p$vehicle_type_secti2 === void 0 ? void 0 : _p$vehicle_type_secti2.name) !== null && _p$vehicle_type_secti !== void 0 ? _p$vehicle_type_secti : '-'))]), _c('td', [_vm._v(_vm._s(p.status ? 'Active' : 'Inactive'))]), _c('td', [_c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.editFormCategory(p);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]), _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteCategory(p);
              }
            }
          }, [!_vm.delete_category ? _c('i', {
            staticClass: "fa fa-trash"
          }) : [_c('span', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status",
              "aria-hidden": "true"
            }
          }), _vm._v("Deleting... ")]], 2)], 1)]);
        }), 0)])]) : _vm._e(), _vm.categoryList.data && _vm.categoryList.data.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.categoryList.meta.totalItem,
            "per-page": _vm.categoryList.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getInspectionChecklistCategory
          },
          model: {
            value: _vm.categoryList.meta.page,
            callback: function callback($$v) {
              _vm.$set(_vm.categoryList.meta, "page", $$v);
            },
            expression: "categoryList.meta.page"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No Category data found.")])]];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.category_edit
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.category_edit ? 'Edit' : 'Add') + " Category")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSaveCategory();
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Category",
            "label-for": "name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "placeholder": "Type text",
            "maxlength": "30"
          },
          model: {
            value: _vm.formCategory.name,
            callback: function callback($$v) {
              _vm.$set(_vm.formCategory, "name", $$v);
            },
            expression: "formCategory.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Parent",
            "label-for": "parent_id"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.listParent,
            "reduce": function reduce(obj) {
              return obj.value;
            },
            "placeholder": "Select parent"
          },
          model: {
            value: _vm.formCategory.parent_id,
            callback: function callback($$v) {
              _vm.$set(_vm.formCategory, "parent_id", $$v);
            },
            expression: "formCategory.parent_id"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Alias",
            "label-for": "alias"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "alias",
            "type": "text",
            "placeholder": "Type text",
            "maxlength": "30"
          },
          model: {
            value: _vm.formCategory.alias,
            callback: function callback($$v) {
              _vm.$set(_vm.formCategory, "alias", $$v);
            },
            expression: "formCategory.alias"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Maintenance Component",
            "label-for": "maintenance_component_id"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "sm",
            "placeholder": "Select options..."
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Maintenance Component")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.formCategory.maintenance_component_id,
            callback: function callback($$v) {
              _vm.$set(_vm.formCategory, "maintenance_component_id", $$v);
            },
            expression: "formCategory.maintenance_component_id"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Vehicle Type Section",
            "label-for": "vehicle_type_m_section_id"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "sm",
            "placeholder": "Select options..."
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Vehicle Type")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.formCategory.vehicle_type_m_section_id,
            callback: function callback($$v) {
              _vm.$set(_vm.formCategory, "vehicle_type_m_section_id", $$v);
            },
            expression: "formCategory.vehicle_type_m_section_id"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.formCategory.status,
            callback: function callback($$v) {
              _vm.$set(_vm.formCategory, "status", $$v);
            },
            expression: "formCategory.status"
          }
        }, [_vm._v("Active / Inactive ")])], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.category_edit ? _c('span', [_vm._v("Add")]) : _c('span', [_vm._v("Save Edit")])]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.resetFormCategory();
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "question"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MASTER DATA QUESTION")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.getInspectionChecklistQuestion();
            }
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Master Data Question:",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "question",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.questionList.params.question,
            callback: function callback($$v) {
              _vm.$set(_vm.questionList.params, "question", $$v);
            },
            expression: "questionList.params.question"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Master Data Question")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.questionList._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.questionList.data && _vm.questionList.data.length > 0 ? _c('div', [_c('div', {
          staticClass: "table-responsive"
        }, _vm._l(_vm.questionList.data, function (parentQuestion, parentQuestionIndex) {
          var _parentQuestion$inspe, _parentQuestion$inspe2;
          return _c('table', {
            key: parentQuestionIndex,
            staticClass: "table table-striped"
          }, [_c('thead', [!parentQuestion.parent_id && (parentQuestion === null || parentQuestion === void 0 ? void 0 : (_parentQuestion$inspe = parentQuestion.inspection_checklist_question) === null || _parentQuestion$inspe === void 0 ? void 0 : _parentQuestion$inspe.length) <= 0 ? _c('tr', [_c('th', {
            staticClass: "align-middle text-center",
            attrs: {
              "colspan": "6"
            }
          }, [_vm._v(_vm._s(parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.name))])]) : _c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v(_vm._s(parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.name))]), _c('th', [_vm._v("Alias")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("#")])])]), (parentQuestion === null || parentQuestion === void 0 ? void 0 : (_parentQuestion$inspe2 = parentQuestion.inspection_checklist_question) === null || _parentQuestion$inspe2 === void 0 ? void 0 : _parentQuestion$inspe2.length) > 0 ? _c('tbody', _vm._l(parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.inspection_checklist_question, function (question, questionId) {
            return _c('tr', {
              key: questionId
            }, [_c('td', [_vm._v(_vm._s(questionId + 1))]), _c('td', [_vm._v(_vm._s(question.question))]), _c('td', [_vm._v(_vm._s(question.alias))]), _c('td', {
              class: question.status ? 'text-success' : 'text-danger'
            }, [_vm._v(_vm._s(question.status ? 'Active' : 'Inactive'))]), _c('td', [_c('b-button', {
              staticClass: "mr-2",
              attrs: {
                "variant": "success",
                "size": "sm"
              }
            }, [_c('i', {
              staticClass: "fa fa-edit",
              on: {
                "click": function click($event) {
                  return _vm.editFormQuestion(question);
                }
              }
            })]), _c('b-button', {
              attrs: {
                "variant": "danger",
                "size": "sm"
              },
              on: {
                "click": function click($event) {
                  return _vm.onDeleteQuestion(question);
                }
              }
            }, [!_vm.delete_question ? _c('i', {
              staticClass: "fa fa-trash"
            }) : [_c('span', {
              staticClass: "spinner-border spinner-border-sm",
              attrs: {
                "role": "status",
                "aria-hidden": "true"
              }
            }), _vm._v("Deleting... ")]], 2)], 1)]);
          }), 0) : _vm._e()]);
        }), 0)]) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No Question data found.")])]];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.question_edit
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.question_edit ? 'Edit' : 'Add') + " Question")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSaveQuestion();
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Question",
            "label-for": "question"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "question",
            "type": "text",
            "placeholder": "Type text"
          },
          model: {
            value: _vm.formQuestion.question,
            callback: function callback($$v) {
              _vm.$set(_vm.formQuestion, "question", $$v);
            },
            expression: "formQuestion.question"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Alias",
            "label-for": "alias"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "alias",
            "type": "text",
            "placeholder": "Type text",
            "maxlength": "30"
          },
          model: {
            value: _vm.formQuestion.alias,
            callback: function callback($$v) {
              _vm.$set(_vm.formQuestion, "alias", $$v);
            },
            expression: "formQuestion.alias"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Checklist Category",
            "label-for": "checklist_category_id"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.listCategory,
            "reduce": function reduce(obj) {
              return obj.value;
            },
            "placeholder": "Select checklist category",
            "required": ""
          },
          model: {
            value: _vm.formQuestion.checklist_category_id,
            callback: function callback($$v) {
              _vm.$set(_vm.formQuestion, "checklist_category_id", $$v);
            },
            expression: "formQuestion.checklist_category_id"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.formQuestion.status,
            callback: function callback($$v) {
              _vm.$set(_vm.formQuestion, "status", $$v);
            },
            expression: "formQuestion.status"
          }
        }, [_vm._v("Active / Inactive ")])], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_vm.saving_question ? _c('span', {
          staticClass: "spinner-border spinner-border-sm"
        }) : !_vm.question_edit && !_vm.saving_question ? _c('span', [_vm._v("Add")]) : _vm.question_edit && !_vm.saving_question ? _c('span', [_vm._v("Save Edit")]) : _vm._e()]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.resetFormQuestion();
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }