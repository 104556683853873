<template>
  <div class="view-maintenance-detail">
    <b-row v-if="maintenanceDetailData" class="pt-2 pl-3 pr-3 pb-2 mb-4">
      <b-col cols="12" class="text-right" v-if="stateAction === 'VIEW_DETAIL'">
        <b-button v-if="['MAINTENANCE'].includes(maintenanceDetailData.status) && canReview" @click="onAction('ON_REVIEW')" variant="info" size="sm" class="mr-2"><i class="fa fa-eye"></i> Review </b-button>
        <b-button v-if="['REVIEW'].includes(maintenanceDetailData.status) && canApprove" @click="onAction('ON_APPROVE')" variant="success" size="sm" class="mr-2"><i class="fa fa-check"></i> Approve </b-button>
        <b-button v-if="['TOWARDS','OVERDUE'].includes(maintenanceDetailData.status) && canAdd" @click="onAction('ON_MAINTENANCE')" variant="primary" size="sm" class="mr-2"><i class="las la-tasks"></i>Maintenance </b-button>
        <b-button v-if="['TOWARDS','OVERDUE'].includes(maintenanceDetailData.status)" @click="onAction('ON_POSTPONE')" variant="warning" size="sm" class="mr-2"><i class="las la-calendar"></i> Postpone </b-button>
        <!-- <b-button v-if="['TOWARDS','OVERDUE'].includes(maintenanceDetailData.status)" @click="onAction('ON_CONVERT')" variant="danger" size="sm" class="mr-2"><i class="las la-anchor"></i> Convert Service & Repair </b-button> -->
      </b-col>
      <b-col cols="12">
        <fieldset class="form-group border p-3">
          <legend class="w-auto px-2">{{maintenanceDetailData.vehicle.vehicleType.name}} - {{maintenanceDetailData.vehicle.name}}</legend>
          <b-row>
            <b-col cols="12">
              <table class="table mb-0 table-striped">
                <tbody>
                  <tr v-for="(engine, engineIndex) in maintenanceDetailData.vehicle.powers" :key="engineIndex">
                    <th>{{engine.powerType.name}} - {{engine.powerSeries.name}}</th>
                    <td class="text-right">Actual RH : <strong>{{engine.actualRunningHours}} RH</strong></td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col md="12" class="mb-3 mt-2"><hr></b-col>
            <b-col md="4">
              <v-jstree :data="treeInitMaintenance" show-checkbox allow-batch whole-row>
                <template slot-scope="_">
                  <div style="display: inherit; width: 200px">
                    <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                    {{_.model.text}}
                  </div>
                </template>
              </v-jstree>
            </b-col>
            <b-col md="8">
              <b-row>
                <b-col cols="12">
                  <fieldset class="form-group border p-3">
                    <!-- <legend class="w-auto px-2">{{maintenanceDetailData.vehicle.name}} - {{maintenanceDetailData.vehicle.vehicleType.name}}</legend> -->
                    <legend class="w-auto px-2">Maintenance Info</legend>
                    <table class="table table-striped">
                      <tbody>
                        <tr>
                          <th width="18%">Job Code</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%">{{ maintenanceDetailData.jobCode }}</td>
                          <th width="18%">Job Class</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%">{{ `${maintenanceDetailData.jobClass.jobClassCode} - ${maintenanceDetailData.jobClass.jobClass}` }}</td>
                        </tr>
                        <tr>
                          <th width="18%">Job Type</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%">{{maintenanceDetailData.jobType.jobTypeCode}} {{maintenanceDetailData.jobType.description}}</td>
                          <th width="18%">Job Name</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%">{{ maintenanceDetailData.jobName }}</td>
                        </tr>
                        <tr>
                          <th width="18%">Type - Serial No</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%">{{maintenanceDetailData.maintenanceMaster.equipmentId}} - {{maintenanceDetailData.maintenanceMaster.equipmentName}}</td>
                          <th width="18%">Interval/Frequency</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%" v-if="maintenanceDetailData.trigger === 'CALENDAR'">{{maintenanceDetailData.interval}} MONTH</td>
                          <td width="30%" v-if="maintenanceDetailData.trigger === 'COUNTER'">{{maintenanceDetailData.interval}} HOURS</td>
                        </tr>
                        <tr>
                          <th width="18%">Trigger</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%" v-if="maintenanceDetailData.trigger === 'CALENDAR'">CALENDAR</td>
                          <td width="30%" v-else-if="maintenanceDetailData.trigger === 'COUNTER'">COUNTER</td>
                          <th width="18%">Status</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%"><b-badge variant="danger">{{ maintenanceDetailData.status }}</b-badge></td>
                        </tr>
                        <tr v-if="maintenanceDetailData.trigger === 'CALENDAR'">
                          <th width="18%">Last Maintenance</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%">{{ maintenanceDetailData.lastMaintenanceDate }}</td>
                          <th width="18%">Possible Maintenance</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%">{{ maintenanceDetailData.possibleMaintenanceDate }}</td>
                        </tr>
                        <tr>
                          <th width="18%">Last Cost</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%">{{ maintenanceDetailData.cost?.toLocaleString() }}</td>
                          <th width="18%">Attachment</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%">
                            <span class="text-primary" style="cursor:pointer" v-if="maintenanceDetailData?.file" @click="handleOpenFilePreview(maintenanceDetailData?.file?.url)"> {{ maintenanceDetailData?.file?.fileName }}</span>
                            <span class="text-muted" v-else>No File Exist..</span>
                          </td> 
                        </tr>
                        <tr>
                          <th class="align-top">Job Description</th>
                          <td class="text-center align-top">:</td>
                          <td colspan="4"><p v-html="maintenanceDetailData.jobDesc"></p></td>
                        </tr>
                        <tr>
                          <th class="align-top">Last Notes</th>
                          <td class="text-center align-top">:</td>
                          <td colspan="4"><p v-html="maintenanceDetailData.notes">{{maintenanceDetailData.notes || '-'}}"></p></td>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>
                  <fieldset class="form-group border p-3" v-if="maintenanceDetailData.trigger === 'COUNTER'">
                    <legend class="w-auto px-2">{{maintenanceDetailData.power.powerType.name}} - {{maintenanceDetailData.power.powerSeries.name}} {{maintenanceDetailData.power.powerSeries.model}}</legend>
                    <table class="table table-striped">
                      <tbody>
                        <tr>
                          <th width="23%">Last Maintenance Date</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="25%">{{ maintenanceDetailData.lastMaintenanceDate }}</td>
                          <th width="23%">Last Maintenance RH</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="25%">{{ maintenanceDetailData.lastMaintenanceHours }} HOURS</td>
                        </tr>
                        <tr>
                          <th width="23%">Actual RH</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="25%">{{ maintenanceDetailData.power.actualRunningHours }} HOURS</td>
                          <th width="23%">Possible Maintenance RH</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="25%">{{ maintenanceDetailData.possibleMaintenanceHours }} HOURS</td>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>
                  <fieldset id="form-field" v-if="['ON_MAINTENANCE','ON_POSTPONE','ON_CONVERT'].includes(stateAction) && !isEdit" class="form-group border p-3">
                    <legend class="w-auto px-2">Maintenance Process</legend>
                    <b-row v-if="stateAction === 'ON_MAINTENANCE'">
                      <b-col cols="6">
                        <b-form-group label="Maintenance Date *">
                          <date-picker style="width: 100%;" v-model="formMaintenance.maintenanceDate" value-type="DD-MM-YYYY" format="DD MMM YYYY" type="date" placeholder="Select date"></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6" v-if="maintenanceDetailData.trigger === 'COUNTER'">
                        <b-form-group label="Actual Running Hours *">
                          <input type="number" v-model="formMaintenance.maintenanceRunningHours" class="form-control">
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="File Maintenance">
                          <b-form-file
                            placeholder="Choose a file"
                            name="select-file-maintenance"
                            @change="uploadFile"
                          ></b-form-file>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Cost">
                          <input type="number" v-model="formMaintenance.cost" class="form-control">
                          <div class="text-right">
                            Rp. {{numberFormat(formMaintenance.cost)}}
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group label="Notes *">
                          <b-form-textarea rows="3" placeholder="Type Notes" v-model="formMaintenance.notes"></b-form-textarea>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-button @click="saveMaintenance()" variant="primary" class="mr-3">Save</b-button>
                        <b-button @click="onAction('VIEW_DETAIL')">Cancel</b-button>
                      </b-col>
                    </b-row>
                    <b-row v-else-if="stateAction === 'ON_POSTPONE'">
                      <b-col cols="6" v-if="maintenanceDetailData.trigger === 'COUNTER'">
                        <b-form-group label="Extra Hours *">
                          <input type="number" v-model="formPostpone.extraHours" class="form-control">
                        </b-form-group>
                      </b-col>
                      <b-col cols="6" v-if="maintenanceDetailData.trigger === 'CALENDAR'">
                        <b-form-group label="Extra Date *">
                          <input type="date" v-model="formPostpone.extraDate" class="form-control">
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group label="Reason *">
                          <b-form-textarea rows="3" placeholder="Type Reason" v-model="formPostpone.reason"></b-form-textarea>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-button @click="savePostpone()" variant="primary" class="mr-3">Save</b-button>
                        <b-button @click="onAction('VIEW_DETAIL')">Cancel</b-button>
                      </b-col>
                    </b-row>
                    <b-row v-else-if="stateAction === 'ON_CONVERT'">
                      <!-- todo -->
                    </b-row>
                  </fieldset>
                  <fieldset id="form-field" v-if="['REVIEW','APPROVE','FINISHED'].includes(maintenanceDetailData.status)" class="form-group border p-3">
                    <legend class="w-auto px-2">Review Maintenance</legend>
                    <table class="table table-striped">
                      <tbody>
                        <tr>
                          <th width="30%">Last Maintenance Date</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="65%">{{maintenanceDetailData.lastMaintenanceDate }}</td>
                        </tr>
                        <tr>
                          <th width="30%">Current Maintenance Date</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="65%" v-if="isEdit">
                            <date-picker style="width: 100%;" v-model="formEdit.maintenanceDate" value-type="DD-MM-YYYY" format="DD MMM YYYY" type="date" placeholder="Select date"></date-picker>
                          </td>
                          <td width="65%" v-else>{{maintenanceDetailData.maintenanceDate }}</td>
                        </tr>
                        <tr v-if="maintenanceDetailData.trigger === 'COUNTER'">
                          <th width="30%">Maintenance Running Hours</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="65%" v-if="isEdit">
                            <b-form-input v-model="formEdit.maintenanceRunningHours" type="number" placeholder="0"></b-form-input>
                          </td>
                          <td width="65%" v-else><p v-if="maintenanceDetailData.trigger === 'COUNTER'">{{maintenanceDetailData.lastMaintenanceHours}} HOURS</p></td>
                        </tr>
                        <tr>
                          <th width="30%">Attachment</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="65%" v-if="isEdit">
                            <div class="d-flex flex-column">
                              <b-form-file
                                placeholder="Choose a file"
                                name="select-file-maintenance"
                                @change="uploadFile"
                                accept="application/*"
                              ></b-form-file>
                              <span v-if="formEdit.fileName" class="text-primary">New File: <i class="fa fa-file" /> {{formEdit.fileName}}</span>
                              <span v-if="maintenanceDetailData?.file?.fileName" @click="handleOpenFilePreview(maintenanceDetailData?.file?.url)" class="text-secondary">Existed File: <i class="fa fa-file" /> {{maintenanceDetailData?.file?.fileName}}</span>
                            </div>
                          </td>
                          <td width="65%" v-else><span class="text-primary" @click="handleOpenFilePreview(maintenanceDetailData?.file?.url)">{{maintenanceDetailData?.file?.fileName}}</span></td>
                        </tr>
                        <tr>
                          <th width="30%">Cost</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="65%" v-if="isEdit">
                            <b-input-group prepend="Rp">
                              <b-form-input v-model="formEdit.cost" type="number" placeholder="0"></b-form-input>
                            </b-input-group>
                            <div class="text-right">
                              Rp. {{numberFormat(formEdit.cost)}}
                            </div>
                          </td>
                          <td width="65%" v-else>Rp.{{numberFormat(maintenanceDetailData.cost)}}</td>
                        </tr>
                        <tr>
                          <th class="align-top">Current Notes</th>
                          <td class="text-center align-top">:</td>
                          <td colspan="4" v-if="isEdit">
                              <b-form-textarea v-model="formEdit.notes" placeholder="Type Text"></b-form-textarea>
                          </td>
                          <td colspan="4" v-else>
                            <span v-if="maintenanceDetailData.status === 'APPROVE'">{{maintenanceDetailData.finishNotes}}</span>
                            <span v-else>{{maintenanceDetailData.notes}}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <b-row v-if="isEdit">
                      <b-col md="12">
                        <b-button @click="editDoMaintenance()" variant="primary" class="mr-3" type="button">Save Update</b-button>
                        <b-button @click="onAction('VIEW_DETAIL')" type="button">Cancel</b-button>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <!-- <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2">Maintenance Info</legend>
                    <table class="table table-striped">
                      <tbody>
                        <tr>
                          <th width="18%">Job Code</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%">{{ maintenanceDetailData.jobCode }}</td>
                          <th width="18%">Job Class</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%">{{ `${maintenanceDetailData.jobClass.jobClassCode} - ${maintenanceDetailData.jobClass.jobClass}` }}</td>
                        </tr>
                        <tr>
                          <th width="18%">Job Type</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%">{{maintenanceDetailData.jobType.jobTypeCode}} {{maintenanceDetailData.jobType.description}}</td>
                          <th width="18%">Job Name</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%">{{ maintenanceDetailData.jobName }}</td>
                        </tr>
                        <tr>
                          <th width="18%">Type - Serial No</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%">{{maintenanceDetailData.maintenanceMaster.equipmentId}} - {{maintenanceDetailData.maintenanceMaster.equipmentName}}</td>
                          <th width="18%">Interval/Frequency</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%" v-if="maintenanceDetailData.trigger === 'CALENDAR'">{{maintenanceDetailData.interval}} MONTH</td>
                          <td width="30%" v-else-if="maintenanceDetailData.trigger === 'COUNTER'">{{maintenanceDetailData.interval}} HOURS</td>
                        </tr>
                        <tr>
                          <th width="18%">Tigger</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%" v-if="maintenanceDetailData.trigger === 'CALENDAR'">CALENDAR</td>
                          <td width="30%" v-else-if="maintenanceDetailData.trigger === 'COUNTER'">COUNTER</td>

                          <th width="18%">Status</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%"><b-badge variant="danger">{{ maintenanceDetailData.status }}</b-badge></td>
                        </tr>
                        <tr v-if="maintenanceDetailData.trigger === 'CALENDAR'">
                          <th width="18%">Last Cost</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%">{{ maintenanceDetailData?.cost ? formatCurrency(maintenanceDetailData?.cost): '-' }}</td>
                          <th width="18%">Attachment</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%" v-if="maintenanceDetailData?.file" @click="handleOpenFilePreview(maintenanceDetailData?.file)"><i class="fa fa-file text-primary" /></td>
                          <td width="30%" class="text-muted" v-else>No File Exist.</td>
                        </tr>
                        <tr v-if="maintenanceDetailData.trigger === 'CALENDAR'">
                          <th width="18%">Last Maintenance</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%">{{ maintenanceDetailData.lastMaintenanceDate }}</td>
                          <th width="18%">Possible Maintenance</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="30%">{{ maintenanceDetailData.possibleMaintenanceDate }}</td>
                        </tr>
                        <tr>
                          <th class="align-top">Job Description</th>
                          <td class="text-center align-top">:</td>
                          <td colspan="4"><p v-html="maintenanceDetailData.jobDesc"></p></td>
                        </tr>
                        <tr>
                          <th class="align-top">Current Notes</th>
                          <td class="text-center align-top">:</td>
                          <td colspan="4">{{ maintenanceDetailData.notes }}</p></td>
                        </tr>
                        <tr>
                          <th class="align-top">Last Notes</th>
                          <td class="text-center align-top">:</td>
                          <td colspan="4"><p v-html="maintenanceDetailData.notes">{{maintenanceDetailData.notes || '-'}}"></p></td>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset> -->
                  <fieldset class="form-group border p-3" v-if="maintenanceDetailData.trigger === 'COUNTER'">
                    <legend class="w-auto px-2">{{maintenanceDetailData.power.powerType.name}} - {{maintenanceDetailData.power.powerSeries.name}} {{maintenanceDetailData.power.powerSeries.model}}</legend>
                    <table class="table table-striped">
                      <tbody>
                        <tr>
                          <th width="23%">Last Maintenance Date</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="25%">{{ maintenanceDetailData.lastMaintenanceDate}}</td>
                          <th width="23%">Last Maintenance RH</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="25%">{{ maintenanceDetailData.lastMaintenanceHours }} HOURS</td>
                        </tr>
                        <tr>
                          <th width="23%">Actual RH</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="25%">{{ maintenanceDetailData.power.actualRunningHours }} HOURS</td>
                          <th width="23%">Possible Maintenance RH</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="25%">{{ maintenanceDetailData.possibleMaintenanceHours }} HOURS</td>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2">Equipment Info - Propulsion</legend>
                    <table class="table table-striped">
                      <tbody>
                        <tr>
                          <th width="23%">Type No</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="25%">{{ maintenanceDetailData.possibleMaintenanceHours?.componentSetup?.typeNo ?? '-' }}</td>
                          <th width="23%">Serial No</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="25%">{{ maintenanceDetailData.possibleMaintenanceHours?.componentSetup?.serialNo ?? '-' }}</td>
                        </tr>
                        <tr>
                          <th width="23%">Maker No</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="25%">{{ maintenanceDetailData.possibleMaintenanceHours?.componentSetup?.makerNo ?? '-' }}</td>
                          <th width="23%">Maker</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="25%">{{ maintenanceDetailData.possibleMaintenanceHours?.componentSetup?.maker ?? '-' }}</td>
                        </tr>
                        <tr>
                          <th width="23%">Drawing No</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="25%">{{ maintenanceDetailData.possibleMaintenanceHours?.componentSetup?.drawingNo ?? '-' }}</td>
                          <th width="23%">Certificate</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="25%">{{ maintenanceDetailData.possibleMaintenanceHours?.componentSetup?.haveCertificate ? 'Yes' : 'No' }}</td>
                        </tr>
                        <tr>
                          <th width="18%">Additional Information</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="25%">{{ maintenanceDetailData.possibleMaintenanceHours?.componentSetup?.additionalInfo ?? '-' }}</td>
                          <th width="18%">Descriptions</th>
                          <td width="2%" class="text-center">:</td>
                          <td width="25%">{{ maintenanceDetailData.possibleMaintenanceHours?.componentSetup?.description ?? '-' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>
                </b-col>
                <!-- History Log -->
                <b-col md="12" class="mb-3">
                  <hr>
                  <b-btn block href="#" v-b-toggle.history variant="light">
                    History Form
                    <span class="when-opened"><i class="fa fa-arrow-down" /></span>
                    <span class="when-closed"><i class="fa fa-arrow-up" /></span>
                  </b-btn>

                  <b-collapse id="history" role="tabpanel">
                    <!-- some content -->
                    <ul class="iq-timeline">
                      <li v-if="maintenanceDetailData.logs.length === 0">Tidak ada histori log</li>
                      <li v-for="(log, logIndex) in maintenanceDetailData.logs" :key="logIndex">
                        <div class="timeline-dots border-secondary"></div>
                        <small class="mt-1">{{log.createdAt}}</small>
                        <h6 class="" v-if="log.status === 'INIT'"><strong>{{ log.createdBy }}</strong><br />  Telah membuat form</h6>
                        <h6 class="" v-else-if="['APPROVED','APPROVAL','APPROVE'].includes(log.status)"><strong>{{ log.createdBy }}</strong> <br /> Telah memberikan approval form</h6>
                        <h6 class="" v-else-if="['REVIEWED','REVIEW'].includes(log.status)"><strong>{{ log.createdBy }}</strong><br />  Telah memberikan review form</h6>
                        <h6 class="" v-else-if="['OVERDUE','TOWARDS'].includes(log.status)"><strong>{{ log.createdBy }}</strong><br />  Harap segera lakukan maintenance</h6>
                        <h6 class="" v-else-if="['NEW'].includes(log.status)"><strong>{{ log.createdBy }}</strong> <br />  Maintenance schedule telah dibuat</h6>
                      </li>
                    </ul>
                  </b-collapse>
                </b-col>
                <!-- End History Log -->
                <!-- Comment -->
                <b-col cols="12" class="mt-3">
                  <b-btn block href="#" v-b-toggle.comment variant="secondary">
                    <i class="fa fa-comment"></i> Comment Box ({{maintenanceDetailData.comments.length}})
                    <span class="when-opened"><i class="fa fa-arrow-down" /></span>
                    <span class="when-closed"><i class="fa fa-arrow-up" /></span>
                  </b-btn>

                  <b-collapse id="comment" role="tabpanel">
                    <!-- some content -->
                    <div class="detailBox">
                      <div class="titleBox">
                        <label>Comment Box</label>
                        <button type="button" class="close" aria-hidden="true" v-b-toggle.comment>&times;</button>
                      </div>
                      <div class="actionBox">
                        <ul class="commentList">
                          <li v-if="maintenanceDetailData.comments.length === 0">Tidak ada komentar</li>
                          <li v-for="comment in maintenanceDetailData.comments">
<!--                            <div class="commenterImage">-->
<!--                              <img src="http://placekitten.com/50/50" />-->
<!--                            </div>-->
                            <div class="commentText">
                              <p><span><strong>{{comment.name}}</strong> - {{comment.roleName}}</span></p>
                              <p class="">{{comment.comment}}</p> <span class="date sub-text">on {{ comment.createdAt }}</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <b-row>
                        <b-col cols="10">
                          <b-form-input v-model="formComment.comment" type="text" placeholder="Input Your Comments..."></b-form-input>
                        </b-col>
                        <b-col cols="2">
                          <b-button variant="primary" :disabled="!formComment.comment" @click="saveComment()" block>Add</b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </b-collapse>
                </b-col>
                <!-- End Comment -->
              </b-row>
            </b-col>
          </b-row>
        </fieldset>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { filesActions, fleetsActions, maintenanceActions } from '@src/Utils/helper'
import { numberFormat } from '@src/plugins/helpers'
import moment from 'moment'

export default {
  name: 'ViewMaintenanceDetail',
  props: {
    maintenanceId: {
      type: Number,
      require: true
    },
    action: {
      type: String,
      default: 'VIEW_DETAIL'
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    jobClassList: {
      type: Array,
      default: () => []
    },
    jobTypeList: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    maintenanceId: {
      immediate: true,
      async handler (value) {
        if (value) {
          await this.fetchMaintenanceDetail(value)
          await this.getComponentStruct()
        }
      }
    },
    stateAction: {
      immediate: true,
      async handler (value) {
        let title = ''

        switch (value) {
          case 'VIEW_DETAIL':
            title = 'Maintenance Detail'
            break
          case 'ON_MAINTENANCE':
            title = 'Create Maintenance'
            break
          case 'ON_POSTPONE':
            title = 'Postponed Maintenance'
            break
          case 'ON_CONVERT':
            title = 'Convert Service & Repair'
            break
          default:
            title = 'Detail Maintenance'
            break
        }

        setTimeout(() => {
          document.getElementById('view-detail-maintenance___title__').innerText = title
        },300)
      }
    }
  },
  data () {
    return {
      stateAction: 'VIEW_DETAIL',
      checkedFleets: [],
      maintenanceDetailData: null,
      treeInitMaintenance: [],
      formComment: {
        comment: ''
      },
      formMaintenance: {
        fileName: '',
        maintenanceDate: '',
        maintenanceRunningHours: '',
        cost: '',
        notes: ''
      },
      formPostpone: {
        reason: '',
        extraHours: '',
        extraDate: ''
      },
      formEdit: {
        "fileName": "",
        "maintenanceDate": "",
        "maintenanceRunningHours": 0,
        "cost": 0,
        "notes": ""
      },
      lsProfile: JSON.parse(localStorage.getItem('profile')),
    }
  },
  computed: {
    maintenancePermission () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Technical')?.child.find(child => child.menu === 'Maintenance')
    },
    canAdd () {
      return this.maintenancePermission ? this.maintenancePermission?.add : false
    },
    canEdit () {
      return this.maintenancePermission ? this.maintenancePermission?.update : false
    },
    canApprove () {
      return this.maintenancePermission ? this.maintenancePermission?.approval : false
    },
    canReview () {
      return this.maintenancePermission ? this.maintenancePermission?.review : false
    },
    canDelete () {
      return this.maintenancePermission ? this.maintenancePermission?.delete : false
    },
    canExport () {
      return this.maintenancePermission ? this.maintenancePermission?.export : false
    }
  },
  methods: {
    ...fleetsActions,
    ...maintenanceActions,
    ...filesActions,
    handleOpenFilePreview: function (url) {
      window.open(url, "_blank");
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
    async fetchMaintenanceDetail (maintenanceId) {
      const res = await this.getMaintenanceDetail(maintenanceId)
      if (res.status === 'success') {
        if (res.data) {
          this.maintenanceDetailData = { ...res.data, maintenanceId }
          this.checkedFleets.push({ ...this.maintenanceDetailData.vehicle, componentSetup: [] })
          this.componentIdSelected = this.maintenanceDetailData.component.id
          this.formMaintenance.maintenanceDate = this.maintenanceDetailData.possibleMaintenanceDate
          this.maintenanceDetailData.vehicle.powers.sort((a, b) => {
            return a.powerType.id - b.powerType.id
          })
          this.formEdit = {
            "fileName": "",
            "maintenanceDate": this.maintenanceDetailData.maintenanceDate,
            "maintenanceRunningHours": this.maintenanceDetailData.maintenanceRunningHours,
            "cost": this.maintenanceDetailData.cost,
            "notes": this.maintenanceDetailData.notes,
          }
          await this.componentSetupCheck()
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async componentSetupCheck () {
      const paramsQ = {
        vehicleIds: []
      }

      if (this.checkedFleets && this.checkedFleets.length > 0) {
        this.checkedFleets.forEach(el => {
          paramsQ.vehicleIds.push(el.id)
        })
        paramsQ.vehicleIds = paramsQ.vehicleIds.toString()
      }

      const dataNeed = {
        id: this.componentIdSelected,
        params: paramsQ
      }

      const res = await this.checkHasComponentSetup(dataNeed)

      if (res.status === 'success') {
        if (res.data.data && res.data.data.length > 0) {
          if (this.checkedFleets && this.checkedFleets.length > 0) {
            res.data.data.forEach(el => {
              this.checkedFleets.forEach(fleet => {
                if (fleet.id === el.vehicle.id) {
                  fleet.componentSetup = []
                  el.componentSetup.forEach(componentSetup => {
                    fleet.componentSetup.push(componentSetup)
                  })
                }
              })
            })
          }
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async getComponentStruct () {
      this.treeInitMaintenance = []
      const paramsQ = {
        vehicleTypeId: 1
      }
      const res = await this.getComponent(paramsQ)

      if (res.status === 'success') {
        if (res.data.maintenanceComponents && res.data.maintenanceComponents.length > 0) {
          const dataTemp = res.data.maintenanceComponents
          dataTemp.forEach((s) => this.treeInitMaintenance.push(this.putStructureObject(s)))
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async saveComment () {
      let payload = {
        id: this.maintenanceId,
        comment: this.formComment.comment
      }
      const res = await this.saveCommentMaintenance(payload)

      if (res.status === 'success') {
        this.formComment.comment = ''
        await this.fetchMaintenanceDetail(this.maintenanceId)
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async saveMaintenance () {
      // if(!this.formMaintenance.cost || !this.formMaintenance.fileName) {
      //   return alert('Lengkapi form maintenance terlebih dahulu')
      // }

      let payload = {
        id: this.maintenanceId,
        data: this.formMaintenance
      }

      // if(Number(payload.data.maintenanceRunningHours) <= Number(this.maintenanceDetailData?.lastMaintenanceHours)){
      //   return this.$swal(
      //     `Running Hours must be greater than last maintenance`,
      //     'Oops.',
      //     'warning'
      //   )
      // }
      const res = await this.saveDoMaintenance(payload)

      if (res.status === 'success') {
        this.$swal(
          `Maintenance Berhasil`,
          '',
          'success'
        )
        await this.fetchMaintenanceDetail(this.maintenanceId)
        this.onAction('VIEW_DETAIL')
        this.$emit('save')
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async savePostpone () {
      if(!this.formPostpone.reason) {
        return alert('Lengkapi form postpone terlebih dahulu')
      }

      let payload = {
        id: this.maintenanceId,
        data: {
          ...this.formPostpone,
          extraDate: moment(this.formPostpone.extraDate).format('DD-MM-YYYY')
        }
      }
      const res = await this.savePostponeMaintenance(payload)

      if (res.status === 'success') {
        this.$swal(
          `Postpone Berhasil`,
          '',
          'success'
        )
        await this.fetchMaintenanceDetail(this.maintenanceId)
        this.onAction('VIEW_DETAIL')
        this.$emit('save')
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async saveReview () {
      let payload = {
        id: this.maintenanceId
      }
      const res = await this.saveReviewMaintenance(payload)

      if (res.status === 'success') {
        this.$swal(
          `Review Berhasil`,
          '',
          'success'
        )
        await this.fetchMaintenanceDetail(this.maintenanceId)
        this.onAction('VIEW_DETAIL')
        this.$emit('save')
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async saveApprove () {
      let payload = {
        id: this.maintenanceId
      }
      const res = await this.saveApproveMaintenance(payload)

      if (res.status === 'success') {
        this.$swal(
          `Approve Berhasil`,
          '',
          'success'
        )
        await this.fetchMaintenanceDetail(this.maintenanceId)
        this.onAction('VIEW_DETAIL')
        this.$emit('save')
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async editDoMaintenance () {
      let payload = {
        id: this.maintenanceId,
        data: {
          ...this.formEdit
        }
      }

      const res = await this.updateDoMaintenance(payload)

      if (res.status === 'success') {
        this.$swal(
          `Update Maintenance Berhasil`,
          '',
          'success'
        )
        await this.fetchMaintenanceDetail(this.maintenanceId)
        this.onAction('VIEW_DETAIL')
        this.$emit('save')
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async uploadFile (event) {
      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      let res = await this.upload(formData)
      if (res.status === 'success') {
        if(this.isEdit){
          this.formEdit.fileName = res.data.fileName
        } else {
          this.formMaintenance.fileName = res.data.fileName
        }
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },

    putStructureObject (object) {
      let children = []

      if (object.child.length > 0) {
        object.child.forEach((child) =>
          children.push(this.putStructureObject(child))
        )
      }

      let selected = this.maintenanceDetailData && this.maintenanceDetailData.component ? (this.maintenanceDetailData.component.id === object.component.id) : false
      let disabled = this.maintenanceDetailData

      return {
        id: object.component.id,
        text: object.component.componentName,
        disabled,
        opened: true,
        selected,
        children
      }
    },
    onAction (action) {
      this.resetFormMaintenance()
      this.resetFormPostpone()

      if(action === 'ON_REVIEW') {
        this.$swal({
          title: 'Lakukan Review Maintenance ?',
          text: `Periksa Kembali Seluruh Data`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Review'
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.saveReview()
          }
        })
      } else if(action === 'ON_APPROVE') {
        this.$swal({
          title: 'Lakukan Approve Maintenance ?',
          text: `Periksa Kembali Seluruh Data`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Approve'
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.saveApprove()
          }
        })
      }

      this.stateAction = action
    },
    resetFormMaintenance () {
      this.formMaintenance = {
        fileName: '',
        maintenanceDate: '',
        maintenanceRunningHours: this.maintenanceDetailData?.power?.actualRunningHours ?? 0,
        cost: '',
        notes: ''
      }
    },
    resetFormPostpone () {
      this.formPostpone = {
        reason: '',
        extraHours: '',
        extraDate: ''
      }
    },
    numberFormat(number) {
      return numberFormat(number)
    },
  },
  mounted () {
    this.onAction(this.action)
  }
}
</script>

<style>
.ul-lists-styled ul, .ul-lists-styled {
  list-style: circle;
}
</style>
