var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Email Template")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          staticClass: "d-flex",
          attrs: {
            "cols": "4"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search templates..."
          }
        }), _c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }), _c('b-col', {
          attrs: {
            "cols": "2"
          }
        }, [_c('b-button', {
          attrs: {
            "block": "",
            "variant": "primary"
          },
          on: {
            "click": _vm.openSlideCreateEmail
          }
        }, [[_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v("   Create ")]], 2)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Data")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-pagination', {
          staticClass: "mt-3",
          attrs: {
            "total-rows": 1,
            "per-page": 1,
            "first-number": "",
            "align": "center"
          }
        })];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [[_c('div', {
          staticClass: "d-flex flex-column overflow-auto"
        }, [_c('table', {
          staticClass: "table table-striped table-hover"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "type": "checkbox"
          }
        })], 1), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "width": "30%"
          }
        }, [_vm._v("NAME")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("APPLIES TO")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "width": "30%"
          }
        }, [_vm._v("SUBJECT")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "width": "30%"
          }
        }, [_vm._v("FROM")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "width": "30%"
          }
        }, [_vm._v("TO (EMAILS)")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "width": "30%"
          }
        }, [_vm._v("TO (PARTNERS)")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "width": "30%"
          }
        }, [_vm._v("REPORT FILENAME")])])]), _c('tbody', _vm._l(_vm.dataTable, function (state, index) {
          return _c('tr', {
            key: index,
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": _vm.openSlideCreateEmail
            }
          }, [_c('td', {
            staticClass: "text-truncate text-center"
          }, [_c('b-form-checkbox', {
            attrs: {
              "type": "checkbox"
            }
          })], 1), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.applies_to))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.subject))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.from))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.to_emails))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.to_partners))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.report_filename))])]);
        }), 0)])])]];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "slideCreateEmail",
      "title": 'Create Email Template',
      "width": "100%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('SlideFormCreateEmail', {
          attrs: {
            "openSlideCreateEmail": _vm.openSlideCreateEmail
          }
        })];
      }
    }]),
    model: {
      value: _vm.slideCreateEmail,
      callback: function callback($$v) {
        _vm.slideCreateEmail = $$v;
      },
      expression: "slideCreateEmail"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }