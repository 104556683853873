<template>
  <div>
    <tab-nav :tabs="true" align="center">
      <tab-nav-items :active="true" href="#blacklist-crew" title="Blacklist Crew" />
      <tab-nav-items :active="false" href="#blacklist-candidate" title="Blacklist Candidate" />
    </tab-nav>
    <div id="myTabContent2" class="tab-content">
      <tab-content-item :active="true" id="blacklist-crew">
        <b-row>
          <b-col lg="12">
            <ContentBlacklistCrew :optionsPosition="optionsPosition" />
          </b-col>
        </b-row>
      </tab-content-item>
      <tab-content-item :active="false" id="blacklist-candidate">
        <b-row>
          <b-col lg="12">
            <ContentBlacklistCandidate :optionsPosition="optionsPosition" />
          </b-col>
        </b-row>
      </tab-content-item>
    </div>
  </div>
</template>

<script>
import { crewingActions } from '@src/Utils/helper'
import _ from 'lodash'
import ContentBlacklistCrew from './ContentBlacklistCrew.vue'
import ContentBlacklistCandidate from './ContentBlacklistCandidate.vue'

export default {
  name: 'TabBlacklist',
  props:{
    optionsPosition:{
      type:Array,
      default: () => []
    },
  },
  data(){
    return {
      
    }
  },
  components:{
    ContentBlacklistCrew,
    ContentBlacklistCandidate,
  },
  async mounted () {},

  methods: {
    ...crewingActions,
  
  }
}
</script>
