var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-input', {
    staticClass: "mb-3",
    attrs: {
      "placeholder": "Search..."
    },
    model: {
      value: _vm.keyword,
      callback: function callback($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  })], 1)], 1), _vm._l(_vm.erms, function (list, index) {
    return _c('b-row', {
      key: index
    }, [list.data ? [_c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('iq-card', {
      attrs: {
        "class-name": "iq-card-block iq-card-stretch iq-card-height",
        "body-class": ""
      },
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', {
            staticClass: "card-title text-primary"
          }, [_c('strong', [_vm._v(_vm._s(list.vehicle.name))])])];
        },
        proxy: true
      }, {
        key: "headerAction",
        fn: function fn() {
          return [_c('a', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: "collapse-erm-i-".concat(index),
              expression: "`collapse-erm-i-${index}`"
            }],
            staticClass: "text-primary float-right",
            staticStyle: {
              "cursor": "pointer"
            },
            attrs: {
              "role": "button"
            }
          }, [_c('i', {
            staticClass: "fa fa-filter"
          })])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('b-collapse', {
            staticClass: "mb-2",
            attrs: {
              "id": "collapse-erm-i-".concat(index),
              "visible": ""
            }
          }, [_c('b-row', {
            staticClass: "font-size-18 sticky-top bg-info text-white px-3 py-3 border-bottom"
          }, [_c('b-col', {
            attrs: {
              "md": "3"
            }
          }, [_c('strong', [_vm._v("Vessel:")]), _c('span', {
            staticClass: "ml-2"
          }, [_vm._v(_vm._s(list.vehicle.name))])]), _c('b-col', {
            attrs: {
              "md": "3"
            }
          }, [_c('strong', [_vm._v("Assessor:")]), _c('span', {
            staticClass: "ml-2"
          }, [_vm._v(_vm._s(list.data.assessor_fullname))])]), _c('b-col', {
            attrs: {
              "md": "3"
            }
          }, [_c('strong', [_vm._v("Target Location:")]), _c('span', {
            staticClass: "ml-2"
          }, [_vm._v(_vm._s(list.data.target_location))])]), _c('b-col', {
            attrs: {
              "md": "3"
            }
          }, [_c('strong', [_vm._v("Target PIC:")]), _c('span', {
            staticClass: "ml-2"
          }, [_vm._v(_vm._s(list.data.target_pic))])])], 1), _c('b-row', {
            staticClass: "px-3"
          }, [_c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Sasaran Smart")]), _c('table', {
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', {
            staticClass: "bg-warning"
          }, [_c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "16%"
            }
          }, [_vm._v("Sasaran")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "16%"
            }
          }, [_vm._v("Specific")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "16%"
            }
          }, [_vm._v("Measurable")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "16%"
            }
          }, [_vm._v("Achievable")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "16%"
            }
          }, [_vm._v("Relevant")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "16%"
            }
          }, [_vm._v("Time-Bound")])])]), _c('tbody', [list.data.sasaran_smart.length === 0 ? _c('tr', [_c('td', {
            attrs: {
              "colspan": "6"
            }
          }, [_vm._v("No Data")])]) : _vm._e(), _vm._l(list.data.sasaran_smart, function (child, childIndex) {
            return _c('tr', {
              key: childIndex
            }, [_c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.sasaran) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.spesific) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.measurable) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.achievable) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.relevant) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.timebound) + " ")])]);
          })], 2)])]), _c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Penamaan Risiko")]), _c('table', {
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', {
            staticClass: "bg-warning"
          }, [_c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "14%"
            }
          }, [_vm._v("Sasaran")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "8%"
            }
          }, [_vm._v("Nama Risiko")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "14%"
            }
          }, [_vm._v("Perisitiwa")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "14%"
            }
          }, [_vm._v("Sebab")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "14%"
            }
          }, [_vm._v("Konsekuensi")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "8%"
            }
          }, [_vm._v("Kategori Risiko")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "20%"
            }
          }, [_vm._v("Tindakan")])])]), _c('tbody', [list.data.penamaan_resiko.length === 0 ? _c('tr', [_c('td', {
            attrs: {
              "colspan": "7"
            }
          }, [_vm._v("No Data")])]) : _vm._e(), _vm._l(list.data.penamaan_resiko, function (child, childIndex) {
            return _c('tr', {
              key: childIndex
            }, [_c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.sasaran) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.risiko) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.peristiwa) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.sebab) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.konsekuensi) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.kategori_risiko) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.tindakan) + " ")])]);
          })], 2)])]), _c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Prinsip Manajemen Risiko")]), _c('table', {
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', {
            staticClass: "bg-warning"
          }, [_c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "15%"
            }
          }, [_vm._v("Prinsip Manajemen Risiko")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "25%"
            }
          }, [_vm._v("Definisi Prinsip")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "25%"
            }
          }, [_vm._v("Implementasi Manajemen Risiko")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "10%"
            }
          }, [_vm._v("Status Implementasi")])])]), _c('tbody', [list.data.prinsip_manajemen_resiko.length === 0 ? _c('tr', [_c('td', {
            attrs: {
              "colspan": "4"
            }
          }, [_vm._v("No Data")])]) : _vm._e(), _vm._l(list.data.prinsip_manajemen_resiko, function (child, childIndex) {
            return _c('tr', {
              key: childIndex
            }, [_c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.prinsip) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.definisi) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.implementasi) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.status) + " ")])]);
          })], 2)])]), _c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Kepemimpinan Dan Komitmen")]), _c('table', {
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', {
            staticClass: "bg-warning"
          }, [_c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "15%"
            }
          }, [_vm._v("Pemimpin Risiko")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "24%"
            }
          }, [_vm._v("Uraian Tugas Pemimpin Risiko")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "20%"
            }
          }, [_vm._v("Dokumen Pendukung")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "24%"
            }
          }, [_vm._v("Remarks")])])]), _c('tbody', [list.data.kepemimpinan_komitmen.length === 0 ? _c('tr', [_c('td', {
            attrs: {
              "colspan": "4"
            }
          }, [_vm._v("No Data")])]) : _vm._e(), _vm._l(list.data.kepemimpinan_komitmen, function (child, childIndex) {
            return _c('tr', {
              key: childIndex
            }, [_c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.pimpinan) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.tugas) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [child.dokumen ? _c('div', {
              staticClass: "d-flex justify-content-between"
            }, [_c('a', {
              directives: [{
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                modifiers: {
                  "hover": true
                }
              }],
              staticClass: "text-primary",
              attrs: {
                "href": child.dokumen,
                "title": "Download File",
                "download": ""
              }
            }, [_c('i', {
              staticClass: "fa fa-download"
            }), _vm._v(" Download")])]) : _c('div', [_vm._v(" - ")])]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.remarks) + " ")])]);
          })], 2)])]), _c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Analisa Pemangku Kepentingan")]), _c('table', {
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', {
            staticClass: "bg-warning"
          }, [_c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "11%"
            }
          }, [_vm._v("Status")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "11%"
            }
          }, [_vm._v("Nama")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "14%"
            }
          }, [_vm._v("Kebutuhan/Tantangan")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "11%"
            }
          }, [_vm._v("Dukungan")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "11%"
            }
          }, [_vm._v("Ancaman")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "11%"
            }
          }, [_vm._v("Strategi")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "14%"
            }
          }, [_vm._v("Deskripsi Tindakan")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "14%"
            }
          }, [_vm._v("Remarks")])])]), _c('tbody', [list.data.analisa_pemangku_jabatan.length === 0 ? _c('tr', [_c('td', {
            attrs: {
              "colspan": "8"
            }
          }, [_vm._v("No Data")])]) : _vm._e(), _vm._l(list.data.analisa_pemangku_jabatan, function (child, childIndex) {
            return _c('tr', {
              key: childIndex
            }, [_c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.status) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.nama) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.tantangan) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.dukungan) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.ancaman) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.strategi) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.tindakan) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.remarks) + " ")])]);
          })], 2)])]), _c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Integrasi - Sasaran Smart")]), _c('table', {
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', {
            staticClass: "bg-warning"
          }, [_c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "25%"
            }
          }, [_vm._v("Status Kesesuaian")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "25%"
            }
          }, [_vm._v("Deskripsi")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "25%"
            }
          }, [_vm._v("Dokumen Pendukung")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "25%"
            }
          }, [_vm._v("Remarks")])])]), _c('tbody', [list.data.integrasi_sasaran_smart.length === 0 ? _c('tr', [_c('td', {
            attrs: {
              "colspan": "4"
            }
          }, [_vm._v("No Data")])]) : _vm._e(), _vm._l(list.data.integrasi_sasaran_smart, function (child, childIndex) {
            return _c('tr', {
              key: childIndex
            }, [_c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.kesesuaian) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.deskripsi) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [child.dokumen ? _c('div', {
              staticClass: "d-flex justify-content-between"
            }, [_c('a', {
              directives: [{
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                modifiers: {
                  "hover": true
                }
              }],
              staticClass: "text-primary",
              attrs: {
                "href": child.dokumen,
                "title": "Download File",
                "download": ""
              }
            }, [_c('i', {
              staticClass: "fa fa-download"
            }), _vm._v(" Download")])]) : _c('div', [_vm._v(" - ")])]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.remarks) + " ")])]);
          })], 2)])]), _c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Evaluasi")]), _c('table', {
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', {
            staticClass: "bg-warning"
          }, [_c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "13%"
            }
          }, [_vm._v("Tahapan Kegiatan")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "13%"
            }
          }, [_vm._v("Sasaran Tahapan (Milestone)")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "13%"
            }
          }, [_vm._v("Uraian Tugas")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "13%"
            }
          }, [_vm._v("Indikator Keberhasilan")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("Metode Evaluasi")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("Frekuensi")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("Remarks")])])]), _c('tbody', [list.data.evaluasi.length === 0 ? _c('tr', [_c('td', {
            attrs: {
              "colspan": "7"
            }
          }, [_vm._v("No Data")])]) : _vm._e(), _vm._l(list.data.evaluasi, function (child, childIndex) {
            return _c('tr', {
              key: childIndex
            }, [_c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.kegiatan) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.sasaran) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.uraian) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.keberhasilan) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.evaluasi) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.frekuensi) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.remarks) + " ")])]);
          })], 2)])]), _c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Perbaikan Sinambung")]), _c('table', {
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', {
            staticClass: "bg-warning"
          }, [_c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "14%"
            }
          }, [_vm._v("Tahapan")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "14%"
            }
          }, [_vm._v("Sasaran Tahapan")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "15%"
            }
          }, [_vm._v("Uraian Tugas")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "14%"
            }
          }, [_vm._v("Indikator Keberhasilan")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "14%"
            }
          }, [_vm._v("Hasil Evaluasi")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "15%"
            }
          }, [_vm._v("Frekuensi")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "14%"
            }
          }, [_vm._v("Pelaksana")])])]), _c('tbody', [list.data.perbaikan_sinambung.length === 0 ? _c('tr', [_c('td', {
            attrs: {
              "colspan": "7"
            }
          }, [_vm._v("No Data")])]) : _vm._e(), _vm._l(list.data.perbaikan_sinambung, function (child, childIndex) {
            return _c('tr', {
              key: childIndex
            }, [_c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.tahapan) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.sasaran) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.uraian) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.keberhasilan) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.evaluasi) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.frekuensi) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.pelaksana) + " ")])]);
          })], 2)])]), _c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Komunikasi Dan Konsultasi")]), _c('table', {
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', {
            staticClass: "bg-warning"
          }, [_c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("Pemangku")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("Konten")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("Tujuan")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("Komunikator")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("Waktu")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("Frekuensi")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("Media/Metode")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("PIC")])])]), _c('tbody', [list.data.komunikasi_konsultasi.length === 0 ? _c('tr', [_c('td', {
            attrs: {
              "colspan": "8"
            }
          }, [_vm._v("No Data")])]) : _vm._e(), _vm._l(list.data.komunikasi_konsultasi, function (child, childIndex) {
            return _c('tr', {
              key: childIndex
            }, [_c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.pemangku) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.konten) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.tujuan) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.komunikator) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.waktu) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.frekuensi) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.metode) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.pic) + " ")])]);
          })], 2)])]), _c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Raci Matrix")]), _c('b-row', [_c('b-col', {
            staticClass: "mt-2 m-auto",
            attrs: {
              "md": "12"
            }
          }, [_vm.raciMatrix ? _c('table', {
            staticClass: "table mb-2"
          }, [_c('thead', [_c('tr', [_c('th', {
            staticClass: "text-center",
            attrs: {
              "rowspan": "2"
            }
          }, [_vm._v("Details")]), _vm.raciMatrix.columns ? _c('th', {
            staticClass: "text-center",
            attrs: {
              "colspan": _vm.raciMatrix.columns.length
            }
          }, [_vm._v("Owner Risk")]) : _vm._e()]), _vm.raciMatrix.columns ? _c('tr', _vm._l(_vm.raciMatrix.columns, function (col, colIndex) {
            return _c('th', {
              key: colIndex,
              staticClass: "text-center"
            }, [_vm._v(_vm._s(col.name))]);
          }), 0) : _vm._e()]), _vm.raciMatrix.rows ? _c('tbody', _vm._l(_vm.raciMatrix.rows, function (row, rowIndex) {
            return _c('tr', {
              key: rowIndex
            }, [_c('td', [_c('strong', [_vm._v(_vm._s(row.name))])]), _vm.raciMatrix.columns ? _vm._l(_vm.raciMatrix.columns, function (col, colIndex) {
              return _c('td', {
                key: colIndex,
                staticClass: "text-center"
              }, [_vm._v(" " + _vm._s(_vm.raxiMatrixFormValue(index, row.id, col.id) && _vm.raxiMatrixFormValue(index, row.id, col.id).length > 0 ? _vm.raxiMatrixFormValue(index, row.id, col.id).join(',') : '-') + " ")]);
            }) : _vm._e()], 2);
          }), 0) : _vm._e()]) : _vm._e()])], 1), _c('hr'), _c('b-row', [_c('b-col', {
            attrs: {
              "md": "1"
            }
          }, [_c('div', {
            staticClass: "bg-danger text-center text-white"
          }, [_c('h4', {
            staticClass: "text-white"
          }, [_vm._v("R")])])]), _c('b-col', {
            attrs: {
              "md": "11"
            }
          }, [_c('p', [_c('strong', [_vm._v("\"Responsible\"")]), _vm._v(" adalah orang atau unit yang harus melaksanakan kegiatan atau aktivitas dalam mekanisme informasi dan pelaporan")])]), _c('b-col', {
            attrs: {
              "md": "1"
            }
          }, [_c('div', {
            staticClass: "bg-warning text-center text-white"
          }, [_c('h4', {
            staticClass: "text-white"
          }, [_vm._v("A")])])]), _c('b-col', {
            attrs: {
              "md": "11"
            }
          }, [_c('p', [_c('strong', [_vm._v("\"Accountable\"")]), _vm._v(" adalah orang atau unit yang berhak membuat keputusan atas kegiatan dalam mekanisme informasi dan pelaporan")])]), _c('b-col', {
            attrs: {
              "md": "1"
            }
          }, [_c('div', {
            staticClass: "bg-success text-center text-white"
          }, [_c('h4', {
            staticClass: "text-white"
          }, [_vm._v("C")])])]), _c('b-col', {
            attrs: {
              "md": "11"
            }
          }, [_c('p', [_c('strong', [_vm._v("\"Consulted\"")]), _vm._v(" adalah orang atau unit yang harus di ajak konsultasi atau dilibatkan sebelum atau saat kegiatan dalam mekanisme informasi dan pelaporan")])]), _c('b-col', {
            attrs: {
              "md": "1"
            }
          }, [_c('div', {
            staticClass: "bg-info text-center text-white"
          }, [_c('h4', {
            staticClass: "text-white"
          }, [_vm._v("I")])])]), _c('b-col', {
            attrs: {
              "md": "11"
            }
          }, [_c('p', [_c('strong', [_vm._v("\"Informed\"")]), _vm._v(" adalah orang atau unit yang harus di beri cukup informasi mengenal kegiatan dalam mekanisme informasi, berikut pelaporan hasil dan proses pelaksanaan")])])], 1)], 1), _c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Kriteria Konsultasi")]), _c('table', {
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', {
            staticClass: "bg-warning"
          }, [_c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "10%"
            }
          }, [_vm._v("Skala Konsekuensi")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "35%"
            }
          }, [_vm._v("Deskripsi Skala")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "20%"
            }
          }, [_vm._v("Deskripsi Kriteria")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "35%"
            }
          }, [_vm._v("Remarks")])])]), _c('tbody', [list.data.kriteria_konsekuensi.length === 0 ? _c('tr', [_c('td', {
            attrs: {
              "colspan": "4"
            }
          }, [_vm._v("No Data")])]) : _vm._e(), _vm._l(list.data.kriteria_konsekuensi, function (child, childIndex) {
            return _c('tr', {
              key: childIndex
            }, [_c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.skala) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.deskripsi_skala) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.deskripsi_kriteria) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.remarks) + " ")])]);
          })], 2)])]), _c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Kriteria Kemungkinan")]), _c('table', {
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', {
            staticClass: "bg-warning"
          }, [_c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "10%"
            }
          }, [_vm._v("Skala Kemungkinan")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "35%"
            }
          }, [_vm._v("Deskripsi Skala")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "20%"
            }
          }, [_vm._v("Deskripsi Kriteria")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "35%"
            }
          }, [_vm._v("Remarks")])])]), _c('tbody', [list.data.kriteria_kemungkinan.length === 0 ? _c('tr', [_c('td', {
            attrs: {
              "colspan": "4"
            }
          }, [_vm._v("No Data")])]) : _vm._e(), _vm._l(list.data.kriteria_kemungkinan, function (child, childIndex) {
            return _c('tr', {
              key: childIndex
            }, [_c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.skala) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.deskripsi_skala) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.deskripsi_kriteria) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.remarks) + " ")])]);
          })], 2)])]), _c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Efektifitas Kendali")]), _c('table', {
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', {
            staticClass: "bg-warning"
          }, [_c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "10%"
            }
          }, [_vm._v("Skala Efiktifitas Kendali")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "35%"
            }
          }, [_vm._v("Deskripsi Skala")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "20%"
            }
          }, [_vm._v("Deskripsi Kriteria")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "35%"
            }
          }, [_vm._v("Remarks")])])]), _c('tbody', [list.data.efektifitas_kendali.length === 0 ? _c('tr', [_c('td', {
            attrs: {
              "colspan": "4"
            }
          }, [_vm._v("No Data")])]) : _vm._e(), _vm._l(list.data.efektifitas_kendali, function (child, childIndex) {
            return _c('tr', {
              key: childIndex
            }, [_c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.skala) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.deskripsi_skala) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.deskripsi_kriteria) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.remarks) + " ")])]);
          })], 2)])]), _c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Kriteria Evaluasi")]), _c('table', {
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', {
            staticClass: "bg-warning"
          }, [_c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "10%"
            }
          }, [_vm._v("Efiktifitas Kendali")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "30%"
            }
          }, [_vm._v("Selera Risiko")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "30%"
            }
          }, [_vm._v("Toleransi Risiko")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "30%"
            }
          }, [_vm._v("Tindakan & Eskalasi")])])]), _c('tbody', [list.data.kriteria_evaluasi.length === 0 ? _c('tr', [_c('td', {
            attrs: {
              "colspan": "4"
            }
          }, [_vm._v("No Data")])]) : _vm._e(), _vm._l(list.data.kriteria_evaluasi, function (child, childIndex) {
            return _c('tr', {
              key: childIndex
            }, [_c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.skala) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.selera) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.toleransi) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.tindakan) + " ")])]);
          })], 2)])]), _c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Identifikasi Risiko")]), _c('table', {
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', {
            staticClass: "bg-warning"
          }, [_c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "25%"
            }
          }, [_vm._v("Sasaran")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "25%"
            }
          }, [_vm._v("Peristiwa Risiko")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "25%"
            }
          }, [_vm._v("Penyebab")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "25%"
            }
          }, [_vm._v("Akibat")])])]), _c('tbody', [list.data.identifikasi_resiko.length === 0 ? _c('tr', [_c('td', {
            attrs: {
              "colspan": "4"
            }
          }, [_vm._v("No Data")])]) : _vm._e(), _vm._l(list.data.identifikasi_resiko, function (child, childIndex) {
            return _c('tr', {
              key: childIndex
            }, [_c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.sasaran) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.peristiwa) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.penyebab) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.akibat) + " ")])]);
          })], 2)])]), _c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Analisis Dan Evaluasi Risiko")]), _c('table', {
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', {
            staticClass: "bg-warning"
          }, [_c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "18%"
            }
          }, [_vm._v("Peristiwa Risiko")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("Efektivitas")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("Konsekuensi")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("Kemungkinan")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("Total Eksposur")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("Tingkat Risiko")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("Priority Risk")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("Evaluasi Risiko")])])]), _c('tbody', [list.data.analisis_evaluasi_resiko.length === 0 ? _c('tr', [_c('td', {
            attrs: {
              "colspan": "8"
            }
          }, [_vm._v("No Data")])]) : _vm._e(), _vm._l(list.data.analisis_evaluasi_resiko, function (child, childIndex) {
            return _c('tr', {
              key: childIndex
            }, [_c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.peristiwa) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.efektifitas) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.konsekuensi) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.kemungkinan) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.eksposur) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.tingkat_risiko) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.prioritas) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.evaluasi) + " ")])]);
          })], 2)])]), _c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Peta Risiko - Before")]), _c('table', {
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', {
            staticClass: "bg-warning"
          }, [_c('th', {
            staticClass: "text-center",
            attrs: {
              "rowspan": "2"
            }
          }, [_vm._v("Likelihood/Kemungkinan/")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "colspan": "5"
            }
          }, [_vm._v("Impact/Konsekuensi")])]), _c('tr', {
            staticClass: "bg-warning"
          }, _vm._l(5, function (col) {
            return _c('th', {
              key: col,
              staticClass: "text-center"
            }, [_vm._v(_vm._s(col))]);
          }), 0)]), _c('tbody', _vm._l(5, function (row) {
            return _c('tr', {
              key: row
            }, [_c('td', {
              staticClass: "text-center"
            }, [_c('p', [_vm._v(_vm._s(row))])]), _vm._l(5, function (col) {
              return _c('td', {
                key: col,
                staticClass: "text-center",
                class: {
                  'bg-danger text-white': _vm.petaRisikoBefore(index, row, col).length > 0
                }
              }, [_vm._v(" " + _vm._s(_vm.petaRisikoBefore(index, row, col).map(function (r, ir) {
                return ir + 1;
              }).join(',')) + " ")]);
            })], 2);
          }), 0)])]), _c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Perlakuan Risiko")]), _c('table', {
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', {
            staticClass: "bg-warning"
          }, [_c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "15%"
            }
          }, [_vm._v("Peristiwa Risiko")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "8%"
            }
          }, [_vm._v("Total Eksposur")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "8%"
            }
          }, [_vm._v("Evaluasi Risiko")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "19%"
            }
          }, [_vm._v("Tindakan/Kendali")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "10%"
            }
          }, [_vm._v("Frekuensi Pemantauan")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "10%"
            }
          }, [_vm._v("Frekuensi Pelaporan")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "10%"
            }
          }, [_vm._v("Konsekuensi Residual")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "10%"
            }
          }, [_vm._v("Kemungkinan Residual")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "10%"
            }
          }, [_vm._v("Total Eksposur Residual")])])]), _c('tbody', [list.data.perlakuan_resiko.length === 0 ? _c('tr', [_c('td', {
            attrs: {
              "colspan": "9"
            }
          }, [_vm._v("No Data")])]) : _vm._e(), _vm._l(list.data.perlakuan_resiko, function (child, childIndex) {
            return _c('tr', {
              key: childIndex
            }, [_c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.peristiwa) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.total_eksposur) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.evaluasi_resiko) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.tindakan) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.frekuensi_pemantauan) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.frekuensi_pelaporan) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.konsekuensi_residual) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.kemungkinan_residual) + " ")]), _c('td', {
              staticClass: "align-top"
            }, [_vm._v(" " + _vm._s(child.total_eksposur_residual) + " ")])]);
          })], 2)])]), _c('b-col', {
            staticClass: "mt-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Peta Risiko - After")]), _c('table', {
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', {
            staticClass: "bg-warning"
          }, [_c('th', {
            staticClass: "text-center",
            attrs: {
              "rowspan": "2"
            }
          }, [_vm._v("Likelihood/Kemungkinan/")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "colspan": "5"
            }
          }, [_vm._v("Impact/Konsekuensi")])]), _c('tr', {
            staticClass: "bg-warning"
          }, _vm._l(5, function (col) {
            return _c('th', {
              key: col,
              staticClass: "text-center"
            }, [_vm._v(_vm._s(col))]);
          }), 0)]), _c('tbody', _vm._l(5, function (row) {
            return _c('tr', {
              key: row
            }, [_c('td', {
              staticClass: "text-center"
            }, [_c('p', [_vm._v(_vm._s(row))])]), _vm._l(5, function (col) {
              return _c('td', {
                key: col,
                staticClass: "text-center",
                class: {
                  'bg-success text-white': _vm.petaRisikoAfter(index, row, col).length > 0
                }
              }, [_vm._v(" " + _vm._s(_vm.petaRisikoAfter(index, row, col).map(function (r, ir) {
                return ir + 1;
              }).join(',')) + " ")]);
            })], 2);
          }), 0)])])], 1)], 1)];
        },
        proxy: true
      }], null, true)
    })], 1)] : _c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('iq-card', {
      attrs: {
        "class-name": "iq-card-block iq-card-stretch iq-card-height",
        "body-class": ""
      },
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          var _list$vehicle$name, _list$vehicle, _list$type_data, _list$type_data2, _list$type_data3, _list$type_data4, _list$type_data5;
          return [_c('h4', {
            staticClass: "card-title text-primary"
          }, [_c('strong', [_vm._v(_vm._s((_list$vehicle$name = list === null || list === void 0 ? void 0 : (_list$vehicle = list.vehicle) === null || _list$vehicle === void 0 ? void 0 : _list$vehicle.name) !== null && _list$vehicle$name !== void 0 ? _list$vehicle$name : "N/A"))]), _vm._v(" - "), list !== null && list !== void 0 && (_list$type_data = list.type_data) !== null && _list$type_data !== void 0 && _list$type_data.type ? _c('strong', {
            class: "".concat((list === null || list === void 0 ? void 0 : (_list$type_data2 = list.type_data) === null || _list$type_data2 === void 0 ? void 0 : _list$type_data2.type) === 'non_claim' ? 'text-warning' : 'text-success')
          }, [_vm._v(_vm._s((list === null || list === void 0 ? void 0 : (_list$type_data3 = list.type_data) === null || _list$type_data3 === void 0 ? void 0 : _list$type_data3.type) === 'non_claim' ? 'Non Claim' : 'Claim'))]) : _vm._e(), _vm._v(" - "), list !== null && list !== void 0 && (_list$type_data4 = list.type_data) !== null && _list$type_data4 !== void 0 && _list$type_data4.date_of_loss ? _c('strong', [_vm._v(_vm._s(_vm.moment(list === null || list === void 0 ? void 0 : (_list$type_data5 = list.type_data) === null || _list$type_data5 === void 0 ? void 0 : _list$type_data5.date_of_loss).format('DD MMMM YYYY')))]) : _vm._e()])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('strong', {
            staticClass: "uppercase"
          }, [_vm._v("No Data.")])];
        },
        proxy: true
      }], null, true)
    })], 1)], 2);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }