var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.checked ? _c('b-row', [_c('b-form-group', {
    staticClass: "col-md-6",
    attrs: {
      "label": "Provinsi",
      "label-for": "Provinsi"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "sm",
      "disabled": _vm.checked,
      "required": ""
    },
    on: {
      "change": function change(e) {
        return _vm.onSelect(e, 'provinsi');
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Provinsi")]), _vm._l(_vm.option.provience, function (state, index) {
          return _c('b-form-select-option', {
            key: index,
            attrs: {
              "value": state.name
            }
          }, [_vm._v(" " + _vm._s(state.name) + " ")]);
        })];
      },
      proxy: true
    }], null, false, 2297385475),
    model: {
      value: _vm.form.provinsi,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "provinsi", $$v);
      },
      expression: "form.provinsi"
    }
  })], 1), _c('b-form-group', {
    staticClass: "col-md-6",
    attrs: {
      "label": "Kota / Kabupaten",
      "label-for": "Kota"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "disabled": _vm.checked || this.loadingKota || !_vm.form.provinsi,
      "size": "sm",
      "required": ""
    },
    on: {
      "change": function change(e) {
        return _vm.onSelect(e, 'kabupaten');
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [!_vm.form.provinsi ? _c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Pilih provinsi dahulu")]) : _c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Kota")]), _vm._l(_vm.option.cities, function (state, index) {
          return _c('b-form-select-option', {
            key: index,
            attrs: {
              "value": state.name
            }
          }, [_vm._v(" " + _vm._s(state.name) + " ")]);
        })];
      },
      proxy: true
    }], null, false, 3035068371),
    model: {
      value: _vm.form.kabupaten,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "kabupaten", $$v);
      },
      expression: "form.kabupaten"
    }
  })], 1), _c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "Kecamatan",
      "label-for": "Kecamatan"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "disabled": _vm.checked || this.loadingKecamatan || !_vm.form.kabupaten,
      "size": "sm",
      "required": ""
    },
    on: {
      "change": function change(e) {
        return _vm.onSelect(e, 'kecamatan');
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [!_vm.form.kabupaten ? _c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Pilih kota/kabupaten dahulu")]) : _c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Kecamatan")]), _vm._l(_vm.option.districts, function (state, index) {
          return _c('b-form-select-option', {
            key: index,
            attrs: {
              "value": state.name
            }
          }, [_vm._v(" " + _vm._s(state.name) + " ")]);
        })];
      },
      proxy: true
    }], null, false, 1998356723),
    model: {
      value: _vm.form.kecamatan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "kecamatan", $$v);
      },
      expression: "form.kecamatan"
    }
  })], 1), _c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "Kelurahan",
      "label-for": "Kelurahan"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "disabled": _vm.checked || this.loadingKelurahan || !_vm.form.kecamatan,
      "size": "sm",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [!_vm.form.kecamatan ? _c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Pilih kecamatan dahulu")]) : _vm._e(), _c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Kelurahan")]), _vm._l(_vm.option.villages, function (state, index) {
          return _c('b-form-select-option', {
            key: index,
            attrs: {
              "value": state.name
            }
          }, [_vm._v(" " + _vm._s(state.name) + " ")]);
        })];
      },
      proxy: true
    }], null, false, 159019448),
    model: {
      value: _vm.form.kelurahan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "kelurahan", $$v);
      },
      expression: "form.kelurahan"
    }
  })], 1), _c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "No Rumah",
      "label-for": "no_rumah"
    }
  }, [_c('b-form-input', {
    staticStyle: {
      "height": "35px"
    },
    attrs: {
      "id": "no_rumah",
      "type": "number",
      "name": "no_rumah",
      "placeholder": "Type Number...",
      "disabled": _vm.checked,
      "required": ""
    },
    model: {
      value: _vm.form.no_rumah,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "no_rumah", $$v);
      },
      expression: "form.no_rumah"
    }
  })], 1), _c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "Kode POS",
      "label-for": "kode_pos"
    }
  }, [_c('b-form-input', {
    staticStyle: {
      "height": "35px"
    },
    attrs: {
      "id": "kode_pos",
      "type": "number",
      "name": "kode_pos",
      "disabled": _vm.checked,
      "required": ""
    },
    model: {
      value: _vm.form.kode_pos,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "kode_pos", $$v);
      },
      expression: "form.kode_pos"
    }
  })], 1), _c('b-form-group', {
    staticClass: "col-md-12",
    attrs: {
      "label": "Alamat Domisili",
      "label-for": "alamat_domisili"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "alamat_domisili",
      "name": "alamat_domisili",
      "rows": "3",
      "disabled": _vm.checked,
      "required": "",
      "placeholder": "Alamat..."
    },
    model: {
      value: _vm.form.alamat,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "alamat", $$v);
      },
      expression: "form.alamat"
    }
  })], 1)], 1) : _c('b-row', [_c('b-form-group', {
    staticClass: "col-md-6",
    attrs: {
      "label": "Provinsi",
      "label-for": "Provinsi"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.form.provinsi ? _vm.form.provinsi : 'Kosong pilih alamat KTP terlebih dahulu'))])]), _c('b-form-group', {
    staticClass: "col-md-6",
    attrs: {
      "label": "Kabupaten",
      "label-for": "Kabupaten"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.form.kabupaten ? _vm.form.kabupaten : 'Kosong pilih alamat KTP terlebih dahulu'))])]), _c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "Kecamatan",
      "label-for": "Kecamatan"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.form.kecamatan ? _vm.form.kecamatan : 'Kosong pilih alamat KTP terlebih dahulu'))])]), _c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "Kelurahan",
      "label-for": "Kelurahan"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.form.kelurahan ? _vm.form.kelurahan : 'Kosong pilih alamat KTP terlebih dahulu'))])]), _c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "No Rumah",
      "label-for": "no_rumah"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.form.no_rumah ? _vm.form.no_rumah : 'Kosong pilih alamat KTP terlebih dahulu'))])]), _c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "Kode Pos",
      "label-for": "Kode Pos"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.form.kode_pos ? _vm.form.kode_pos : 'Kosong pilih alamat KTP terlebih dahulu'))])]), _c('b-form-group', {
    staticClass: "col-md-12",
    attrs: {
      "label": "Alamat",
      "label-for": "Alamat"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.form.alamat ? _vm.form.alamat : 'Kosong Kosong pilih alamat KTP terlebih dahulu'))])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }