var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "px-3",
    attrs: {
      "lg": "3"
    }
  }, [_c('div', {
    staticClass: "d-flex mb-2"
  }, [_c('b-form-input', {
    staticClass: "mr-2",
    attrs: {
      "placeholder": "Search fleet..."
    },
    on: {
      "input": _vm.filterFleetByName
    },
    model: {
      value: _vm.searchFleet,
      callback: function callback($$v) {
        _vm.searchFleet = $$v;
      },
      expression: "searchFleet"
    }
  }), _c('b-button', {
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": _vm.onOpenSlideDetailVehicle
    }
  }, [_vm._v("Select")])], 1), _c('div', {
    staticClass: "d-flex flex-column overflow-auto px-3 mb-4 mt-2",
    staticStyle: {
      "max-height": "100vh"
    }
  }, _vm._l(_vm.filteredItems, function (fleet, i) {
    return _c('div', {
      key: "fleet-".concat(i),
      on: {
        "click": function click($event) {
          return _vm.onCheckedFleet(fleet, i);
        }
      }
    }, [_c('b-form-checkbox', {
      attrs: {
        "type": "checkbox",
        "checked": fleet.checked
      }
    }), _c('div', {
      staticClass: "d-flex flex-column align-items-center justify-content-center"
    }, [_c('img', {
      staticStyle: {
        "width": "200px",
        "height": "100px"
      },
      attrs: {
        "src": require('@src/assets/images/fleet/PusherBarge.png'),
        "alt": "#"
      }
    }), _c('small', [_c('strong', [_vm._v(_vm._s(fleet === null || fleet === void 0 ? void 0 : fleet.text))])])])], 1);
  }), 0)]), _c('b-col', {
    attrs: {
      "lg": "9"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-2 mb-4",
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {}, [_c('tab-nav', {
    staticClass: "d-flex",
    attrs: {
      "pills": true
    }
  }, [_c('tab-nav-items', {
    staticClass: "p-0",
    attrs: {
      "active": true,
      "href": "#operational",
      "title": "Operational"
    }
  }), _c('tab-nav-items', {
    staticClass: "p-0",
    attrs: {
      "active": false,
      "href": "#certificate",
      "title": "Certificate"
    }
  })], 1)], 1)]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "operational"
    }
  }, [_vm.fleetId ? [_c('TabOperational', {
    attrs: {
      "fleet-id": _vm.fleetId,
      "preview": true
    }
  })] : _c('div', {
    staticClass: "alert alert-dark m-4"
  }, [_vm._v(" Select Fleet ")])], 2), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "certificate"
    }
  }, [_vm.fleetId ? [_c('TabCertificate', {
    attrs: {
      "fleet-id": _vm.fleetId,
      "preview": true
    }
  })] : _c('div', {
    staticClass: "alert alert-dark m-4"
  }, [_vm._v(" Select Fleet ")])], 2)], 1)])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }