var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": true,
            "href": "#masterCategory",
            "title": _vm.findMenuPermissionLv2ByIndex('menu', _vm.$route.query.menuId, 0)
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": false,
            "href": "#masterModule",
            "title": _vm.findMenuPermissionLv2ByIndex('menu', _vm.$route.query.menuId, 1)
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": false,
            "href": "#setting",
            "title": _vm.findMenuPermissionLv2ByIndex('menu', _vm.$route.query.menuId, 2)
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": false,
            "href": "#fleetForms",
            "title": "Fleet Forms"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "masterCategory"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0) && !_vm.findMenuPermissionLv2ByIndex('add', _vm.$route.query.menuId, 0) ? '12' : '8'
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Fleets Category")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.loading_category ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.fleet_types.length > 0 ? _c('div', {
          staticClass: "overflow-auto"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Group")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Category")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Crew")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Sea Trial / Section")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Towing")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Images/Icon")]), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("Status")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.fleet_types, function (c, i) {
          var _c$vehicleTypeGroup;
          return _c('tr', {
            key: "c-".concat(i)
          }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(_vm._s(c === null || c === void 0 ? void 0 : (_c$vehicleTypeGroup = c.vehicleTypeGroup) === null || _c$vehicleTypeGroup === void 0 ? void 0 : _c$vehicleTypeGroup.name))]), _c('td', [_vm._v(_vm._s(c === null || c === void 0 ? void 0 : c.name))]), _c('td', [_c('b-badge', {
            attrs: {
              "variant": "success"
            }
          }, [_vm._v(_vm._s(c.crewing ? 'Yes' : 'No'))]), _c('br'), _vm._v("Capacity : " + _vm._s(c === null || c === void 0 ? void 0 : c.crewCapacity))], 1), _c('td', [_c('b-badge', {
            attrs: {
              "variant": "success"
            }
          }, [_vm._v("Sea Trial: " + _vm._s(c !== null && c !== void 0 && c.seaTrial ? 'Yes' : 'No'))]), _c('br'), _c('div', {
            staticClass: "d-flex flex-column"
          }, [_c('small', [_vm._v("Section : ")]), _vm._l(c === null || c === void 0 ? void 0 : c.vehicleTypeSection, function (state, index) {
            return _c('b-badge', {
              key: index,
              staticClass: "border border-primary text-primary text-uppercase mb-1 mr-1",
              attrs: {
                "variant": "none"
              }
            }, [_vm._v(_vm._s(state.name))]);
          })], 2)], 1), _c('td', [_c('div', {
            staticClass: "d-flex flex-column"
          }, _vm._l(c === null || c === void 0 ? void 0 : c.vehicleTypeTow, function (state, index) {
            return _c('b-badge', {
              key: index,
              staticClass: "border border-primary text-primary text-uppercase mb-1 mr-1",
              attrs: {
                "variant": "none"
              }
            }, [_vm._v(_vm._s(state.name))]);
          }), 1)]), _c('td', [c.icon ? _c('img', {
            attrs: {
              "src": c.icon.url,
              "alt": c.name,
              "width": "175px",
              "height": "auto"
            }
          }) : _c('span', [_vm._v("-")])]), _c('td', [_vm._v(_vm._s(c.active ? 'Active' : 'Inactive'))]), _c('td', [_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0) ? _c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onEditCategory(c);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), _vm.findMenuPermissionLv2ByIndex('delete', _vm.$route.query.menuId, 0) ? _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteCategory(c);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e()], 1)]);
        }), 0)])]) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No categories data found.")])]];
      },
      proxy: true
    }])
  })], 1), _vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0) || _vm.findMenuPermissionLv2ByIndex('add', _vm.$route.query.menuId, 0) ? _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.form_category_edit_mode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.form_category_edit_mode ? 'Edit' : 'Add') + " Fleets Category")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSubmitFormCategory.apply(null, arguments);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Category Name",
            "label-for": "category_name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "category_name",
            "type": "text",
            "placeholder": "Category Name"
          },
          model: {
            value: _vm.form.category.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.category, "name", $$v);
            },
            expression: "form.category.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Type group",
            "label-for": "type_group"
          }
        }, [_c('b-row', [_c('b-col', [_c('v-multiselect', {
          attrs: {
            "options": _vm.optionsVehicleTypeGroup,
            "placeholder": "Select Vehicle Type Group",
            "track-by": "id",
            "label": "name"
          },
          model: {
            value: _vm.form.category.group,
            callback: function callback($$v) {
              _vm.$set(_vm.form.category, "group", $$v);
            },
            expression: "form.category.group"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1), _c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.type-group",
            modifiers: {
              "type-group": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-2",
          attrs: {
            "variant": "outline-primary",
            "title": "Add More Type Group"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        })])], 1)], 1), _c('b-form-group', {
          attrs: {
            "label": "Icon",
            "label-for": "imagesModulUrl",
            "description": "Accept only .jpg, .jpeg, .gif, and .png"
          }
        }, [_vm.form.category.icon && _vm.form.category.icon.url ? _c('img', {
          staticClass: "mb-3",
          attrs: {
            "src": _vm.form.category.icon.url,
            "alt": _vm.form.category.name,
            "width": "256px",
            "height": "auto"
          }
        }) : _vm._e(), _c('b-form-file', {
          ref: "form_upload_icon",
          attrs: {
            "accept": "image/jpeg, image/png, image/jpg, image/gif",
            "name": "category_icon",
            "disabled": _vm.loading_upload_icon,
            "browse-text": _vm.loading_upload_icon ? 'Uploading...' : 'Browse'
          },
          on: {
            "change": _vm.onChangeIcon
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "There's Crew ?",
            "label-for": "is_crewing"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.category.isCrewing,
            callback: function callback($$v) {
              _vm.$set(_vm.form.category, "isCrewing", $$v);
            },
            expression: "form.category.isCrewing"
          }
        }, [_vm._v(" Active / Inactive ")])], 1), _vm.form.category.isCrewing ? _c('b-form-group', {
          attrs: {
            "label": "Crew Capacity",
            "label-for": "crew_capacity"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "crew_capacity",
            "type": "number",
            "placeholder": "Crew Capacity"
          },
          model: {
            value: _vm.form.category.crewCapacity,
            callback: function callback($$v) {
              _vm.$set(_vm.form.category, "crewCapacity", $$v);
            },
            expression: "form.category.crewCapacity"
          }
        })], 1) : _vm._e(), _c('b-form-group', {
          attrs: {
            "label": "Need Sea Trial ?",
            "label-for": "sea_trial"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.category.seaTrial,
            callback: function callback($$v) {
              _vm.$set(_vm.form.category, "seaTrial", $$v);
            },
            expression: "form.category.seaTrial"
          }
        }, [_vm._v(" Active / Inactive ")])], 1), _c('b-form-group', {
          attrs: {
            "label": "Section",
            "label-for": "section"
          }
        }, [_c('b-row', [_c('b-col', [_c('v-multiselect', {
          attrs: {
            "options": _vm.optionsVehicleTypeSection,
            "multiple": true,
            "placeholder": "Select Vehicle Section",
            "track-by": "id",
            "label": "name"
          },
          model: {
            value: _vm.form.category.vehicleTypeSectionIds,
            callback: function callback($$v) {
              _vm.$set(_vm.form.category, "vehicleTypeSectionIds", $$v);
            },
            expression: "form.category.vehicleTypeSectionIds"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1), _c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.type-section",
            modifiers: {
              "type-section": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-2",
          attrs: {
            "variant": "outline-primary",
            "title": "Add More Section"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        })])], 1)], 1), _c('b-form-group', {
          attrs: {
            "label": "Fleet Towing",
            "label-for": "vehicleTowing"
          }
        }, [_c('b-row', [_c('b-col', [_c('v-multiselect', {
          attrs: {
            "options": _vm.fleet_types,
            "multiple": true,
            "placeholder": "Select Fleet Towing",
            "track-by": "id",
            "label": "name"
          },
          model: {
            value: _vm.form.category.vehicleTowIds,
            callback: function callback($$v) {
              _vm.$set(_vm.form.category, "vehicleTowIds", $$v);
            },
            expression: "form.category.vehicleTowIds"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1)], 1), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "sub_sub_project"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.category.active,
            callback: function callback($$v) {
              _vm.$set(_vm.form.category, "active", $$v);
            },
            expression: "form.category.active"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.category.active ? 'Active' : 'Inactive') + " ")])], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.loading_form_category ? _c('span', [_vm._v(_vm._s(_vm.form_category_edit_mode || !_vm.findMenuPermissionLv2ByIndex('add', _vm.$route.query.menuId, 0) ? 'Save' : 'Add'))]) : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Saving... ")]], 2), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.resetFormCategory.apply(null, arguments);
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 3043280348)
  })], 1) : _vm._e()], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "masterModule"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 1) && !_vm.findMenuPermissionLv2ByIndex('add', _vm.$route.query.menuId, 1) ? '12' : '8'
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Module / Fitur")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.loading_module ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.modules.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Modul / Fitur Name")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Modul / Fitur Url")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Icon")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Same Category")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.modules, function (m, i) {
          return _c('tr', {
            key: "mod-".concat(i)
          }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(_vm._s(m.name))]), _c('td', [_vm._v(_vm._s(m.url))]), _c('td', [m.icon ? _c('img', {
            attrs: {
              "src": m.icon.url,
              "alt": m.name,
              "width": "64px",
              "height": "auto"
            }
          }) : _c('span', [_vm._v("-")])]), _c('td', [_vm._v(" " + _vm._s(m.sameCategory ? 'Yes' : 'No') + " "), m.referenceId ? [_vm._v(" , with "), _c('br'), _c('strong', [_vm._v(_vm._s(m.referenceId.name))])] : _vm._e()], 2), _c('td', [_vm._v(_vm._s(m.active ? 'Active' : 'Inactive'))]), _c('td', [_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 1) ? _c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onEditModule(m);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), _vm.findMenuPermissionLv2ByIndex('delete', _vm.$route.query.menuId, 1) ? _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteModule(m);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e()], 1)]);
        }), 0)])]) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No module data found.")])]];
      },
      proxy: true
    }])
  })], 1), _vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 1) || _vm.findMenuPermissionLv2ByIndex('add', _vm.$route.query.menuId, 1) ? _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.form_module_edit_mode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.form_module_edit_mode ? 'Edit' : 'Add') + " Module / Fitur")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSubmitFormModule.apply(null, arguments);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Modul / Fitur Name",
            "label-for": "modulName"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "modulName",
            "type": "text",
            "placeholder": "Modul / Fitur Name"
          },
          model: {
            value: _vm.form.module.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.module, "name", $$v);
            },
            expression: "form.module.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Modul / Fitur Url",
            "label-for": "modulUrl"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-append"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("https://base_url/")])]), _c('b-form-input', {
          attrs: {
            "id": "modulUrl",
            "type": "text",
            "placeholder": "Url after base"
          },
          model: {
            value: _vm.form.module.url,
            callback: function callback($$v) {
              _vm.$set(_vm.form.module, "url", $$v);
            },
            expression: "form.module.url"
          }
        })], 1)]), _c('b-form-group', {
          attrs: {
            "label": "Images Fitur / Modul",
            "label-for": "imagesModulUrl",
            "description": "Accept only .jpg, .jpeg, and .png"
          }
        }, [_vm.form.module.icon && _vm.form.module.icon.url ? _c('img', {
          staticClass: "mb-3",
          attrs: {
            "src": _vm.form.module.icon.url,
            "alt": _vm.form.module.name,
            "width": "128px",
            "height": "auto"
          }
        }) : _vm._e(), _c('b-form-file', {
          ref: "form_upload_image",
          attrs: {
            "accept": "image/jpeg, image/png, image/jpg",
            "name": "module_icon",
            "disabled": _vm.loading_upload_image,
            "browse-text": _vm.loading_upload_image ? 'Uploading...' : 'Browse'
          },
          on: {
            "change": _vm.onChangeImage
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Same category with existing module?",
            "label-for": "has_sub"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.module.sameCategory,
            callback: function callback($$v) {
              _vm.$set(_vm.form.module, "sameCategory", $$v);
            },
            expression: "form.module.sameCategory"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.module.sameCategory ? 'Yes' : 'No') + " ")])], 1), _vm.form.module.sameCategory ? _c('b-form-group', {
          attrs: {
            "label": "Same with module",
            "label-for": "category"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.modules,
            "reduce": function reduce(m) {
              return m.id;
            }
          },
          model: {
            value: _vm.form.module.referenceId,
            callback: function callback($$v) {
              _vm.$set(_vm.form.module, "referenceId", $$v);
            },
            expression: "form.module.referenceId"
          }
        })], 1) : _vm._e(), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.module.active,
            callback: function callback($$v) {
              _vm.$set(_vm.form.module, "active", $$v);
            },
            expression: "form.module.active"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.module.active ? 'Active' : 'Inactive') + " ")])], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.loading_form_module ? _c('span', [_vm._v(_vm._s(_vm.form_module_edit_mode || !_vm.findMenuPermissionLv2ByIndex('add', _vm.$route.query.menuId, 1) ? 'Save' : 'Add'))]) : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Saving... ")]], 2), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.resetFormModule.apply(null, arguments);
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 228230548)
  })], 1) : _vm._e()], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "setting"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Setup Fleets Module")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "thead-dark text-center"
        }, [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Category")]), _vm._l(_vm.modules, function (m, i) {
          return _c('th', {
            key: "th-mod-".concat(i)
          }, [_vm._v(_vm._s(m.name))]);
        })], 2)]), _c('tbody', _vm._l(_vm.merged_module_categories, function (cat, ic) {
          return _c('tr', {
            key: "cat-".concat(ic)
          }, [_c('td', [_vm._v(_vm._s(cat.name))]), _vm._l(cat.modules, function (m, i) {
            return _c('td', {
              key: "bd-mod-".concat(i),
              staticClass: "text-center"
            }, [_c('b-form-checkbox', {
              attrs: {
                "name": "operational-1",
                "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 2),
                "switch": "",
                "inline": ""
              },
              model: {
                value: m.hasAccess,
                callback: function callback($$v) {
                  _vm.$set(m, "hasAccess", $$v);
                },
                expression: "m.hasAccess"
              }
            })], 1);
          })], 2);
        }), 0)])])];
      },
      proxy: true
    }, _vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 2) ? {
      key: "footer",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "12"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.saveSetting
          }
        }, [!_vm.loading_form_setting ? _c('span', [_vm._v("Save")]) : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Saving... ")]], 2)], 1)], 1)];
      },
      proxy: true
    } : null], null, true)
  })], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "fleetForms"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 3) && !_vm.findMenuPermissionLv2ByIndex('add', _vm.$route.query.menuId, 3) ? '12' : '8'
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Fleet Forms")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.loading_fleet_form ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.fleet_collection_forms.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("No")]), _c('th', [_vm._v("Fleet Types")]), _c('th', [_vm._v("Collection of Forms")]), _c('th', [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.fleet_collection_forms.data, function (state, index) {
          var _state$vehicle_type;
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$vehicle_type = state.vehicle_type) === null || _state$vehicle_type === void 0 ? void 0 : _state$vehicle_type.name))]), _c('td', [_c('div', {
            staticClass: "d-flex flex-column align-items-start"
          }, _vm._l(state === null || state === void 0 ? void 0 : state.collection_forms, function (form, formsIndex) {
            return _c('b-badge', {
              key: formsIndex,
              staticClass: "border border-primary text-primary text-uppercase mb-2",
              staticStyle: {
                "cursor": "pointer"
              },
              attrs: {
                "variant": "none"
              },
              on: {
                "click": function click($event) {
                  return _vm.onShowDetailForm(form);
                }
              }
            }, [_vm._v(_vm._s(form === null || form === void 0 ? void 0 : form.name))]);
          }), 1)]), _c('td', [_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 3) ? _c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onEditFleetForm(state);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), _vm.findMenuPermissionLv2ByIndex('delete', _vm.$route.query.menuId, 3) ? _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteFleetForm(state);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e()], 1)]);
        }), 0)])]) : _vm._e(), _vm.fleet_collection_forms.data.length > 0 ? _c('b-pagination', {
          attrs: {
            "total-rows": _vm.fleet_collection_forms.meta.totalItem,
            "per-page": _vm.fleet_collection_forms.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getVehicleTypeForm
          },
          model: {
            value: _vm.fleet_collection_forms.meta.page,
            callback: function callback($$v) {
              _vm.$set(_vm.fleet_collection_forms.meta, "page", $$v);
            },
            expression: "fleet_collection_forms.meta.page"
          }
        }) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No Fleet Forms data found.")])]];
      },
      proxy: true
    }])
  })], 1), _vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 3) || _vm.findMenuPermissionLv2ByIndex('add', _vm.$route.query.menuId, 3) ? _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.form_fleet_form_edit_mode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.form_fleet_form_edit_mode ? 'Edit' : 'Add') + " Fleets Forms")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSubmitFormFleet.apply(null, arguments);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet Types",
            "label-for": "fleet_types"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "options": _vm.optionsFleetTypes,
            "placeholder": "Select Fleet Types",
            "track-by": "name",
            "label": "name"
          },
          model: {
            value: _vm.form.fleet_form.fleet_types,
            callback: function callback($$v) {
              _vm.$set(_vm.form.fleet_form, "fleet_types", $$v);
            },
            expression: "form.fleet_form.fleet_types"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1), _c('b-form-group', {
          attrs: {
            "label": "Collection Forms",
            "label-for": "collection_forms"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "options": _vm.collectionFormOptions,
            "multiple": true,
            "group-select": true,
            "placeholder": "Select forms",
            "track-by": "name",
            "label": "name"
          },
          model: {
            value: _vm.form.fleet_form.collection_forms,
            callback: function callback($$v) {
              _vm.$set(_vm.form.fleet_form, "collection_forms", $$v);
            },
            expression: "form.fleet_form.collection_forms"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1), _vm.form.fleet_form.collection_forms.length > 0 ? _c('b-form-group', {
          attrs: {
            "label": "Selected Forms"
          }
        }, [_c('div', {
          staticClass: "d-flex flex-column"
        }, _vm._l(_vm.form.fleet_form.collection_forms, function (state, index) {
          return _c('b-badge', {
            key: index,
            staticClass: "border border-primary text-primary mb-1",
            staticStyle: {
              "cursor": "pointer"
            },
            attrs: {
              "variant": "none"
            },
            on: {
              "click": function click($event) {
                return _vm.onShowDetailForm(state);
              }
            }
          }, [_vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : state.name) + " ")]);
        }), 1)]) : _vm._e(), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.loading_form_fleet_form ? _c('span', [_vm._v(_vm._s(_vm.form_fleet_form_edit_mode || !_vm.findMenuPermissionLv2ByIndex('add', _vm.$route.query.menuId, 3) ? 'Save' : 'Add'))]) : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Saving... ")]], 2), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.resetFormsFleet.apply(null, arguments);
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 2368676130)
  })], 1) : _vm._e()], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "add-customer",
      "title": "Detail Forms",
      "width": "70%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('SlideDetailForm', {
          attrs: {
            "detail-form": _vm.detailForm
          }
        })], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.showDetailForm,
      callback: function callback($$v) {
        _vm.showDetailForm = $$v;
      },
      expression: "showDetailForm"
    }
  })], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "type-group",
      "size": "md",
      "title": "Add Vehicle Type Group",
      "cancel-title": "Close",
      "hide-footer": ""
    }
  }, [_c('form', {
    attrs: {
      "method": "post"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSaveTypeGroup.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text",
      "required": ""
    },
    model: {
      value: _vm.form.typeGroup.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form.typeGroup, "name", $$v);
      },
      expression: "form.typeGroup.name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "alias"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text",
      "required": ""
    },
    model: {
      value: _vm.form.typeGroup.alias,
      callback: function callback($$v) {
        _vm.$set(_vm.form.typeGroup, "alias", $$v);
      },
      expression: "form.typeGroup.alias"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-end"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "dark",
      "type": "button"
    }
  }, [_vm._v("Cancel")]), _c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm._v("Submit")])], 1)])], 1)], 1)]), _c('b-modal', {
    attrs: {
      "id": "type-section",
      "size": "md",
      "title": "Add Vehicle Type Section",
      "cancel-title": "Close",
      "hide-footer": ""
    }
  }, [_c('form', {
    attrs: {
      "method": "post"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSaveTypeSection.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Code"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text",
      "required": ""
    },
    model: {
      value: _vm.form.typeSection.code,
      callback: function callback($$v) {
        _vm.$set(_vm.form.typeSection, "code", $$v);
      },
      expression: "form.typeSection.code"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text",
      "required": ""
    },
    model: {
      value: _vm.form.typeSection.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form.typeSection, "name", $$v);
      },
      expression: "form.typeSection.name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-end"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "dark",
      "type": "button"
    }
  }, [_vm._v("Cancel")]), _c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm._v("Submit")])], 1)])], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }