<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items
                  class="col-md-3 p-0"
                  :active="true"
                  href="#checklist"
                  title="CHECKLIST"
                />  
                <tab-nav-items
                  class="col-md-3 p-0"
                  :active="false"
                  href="#question-type"
                  title="QUESTION TYPE"
                /> 
                <tab-nav-items
                  class="col-md-3 p-0"
                  :active="false"
                  href="#cargo-type"
                  title="CARGO TYPE"
                />
                <tab-nav-items
                  class="col-md-3 p-0"
                  :active="false"
                  href="#contract-type"
                  title="CONTRACT TYPE"
                />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <tab-content-item :active="true" id="checklist">
        <MasterChecklist />
      </tab-content-item>
      <tab-content-item :active="false" id="question-type">
        <MasterQuestionType />
      </tab-content-item>
      <tab-content-item :active="false" id="cargo-type">
        <MasterCargoType />
      </tab-content-item>
      <tab-content-item :active="false" id="contract-type">
        <MasterContractType />
      </tab-content-item>
    </div>
  </b-container>
</template>

<script>
import _ from 'lodash'
import MasterChecklist from './MasterChecklist.vue'
import MasterQuestionType from './MasterQuestionType.vue'
import MasterCargoType from './MasterCargoType.vue'
import MasterContractType from './MasterContractType.vue'

export default {
  name: 'MasterDataIdle',
  data () {
    return {
    }
  },
  components:{
    MasterChecklist,
    MasterQuestionType,
    MasterCargoType,
    MasterContractType,
  },
}
</script>

<style scoped>
</style>
