<template>
  <b-row>
    <b-col lg="12">
      <b-row>
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">WhatsApp Template</h4>
            </template>
            <!-- <template v-slot:headerAction>
              <b-breadcrumb
                class="mt-3"
                :items="breadcrumIcon"
                style="background: none"
              />
            </template> -->   
            <template v-slot:body>
              <b-row>
                <b-col cols="4" class="d-flex">
                  <b-form-input
                    id="fleet_name"
                    type="text"
                    placeholder="Search templates..."
                  ></b-form-input>
                  <b-button variant="primary" class="ml-2">
                    <i class="fa fa-search"></i>
                  </b-button>
                </b-col>
                <b-col cols="6"> </b-col>
                <b-col cols="2">
                  <b-button block variant="primary" @click="openSlideCreateWhatsapp">
                    <template>
                      <i class="fa fa-plus"></i> &nbsp; Create
                    </template>
                    <!-- <template>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Exporting...
                    </template> -->
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <b-row>
        <!-- Card Fleet -->
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">List Data</h4>
            </template>
            <template v-slot:headerAction>
              <b-pagination
                class="mt-3"
                :total-rows="1"
                :per-page="1"
                first-number
                align="center"
              ></b-pagination>
            </template>
            <template v-slot:body>
              <!-- <div v-if="ports._loading" class="text-center my-5">
                <b-spinner type="grow" label="Spinning"></b-spinner>
              </div> -->
              <template>
                <div class="d-flex flex-column overflow-auto">
                  <table class="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th width="5%" class="text-center"><b-form-checkbox type="checkbox"></b-form-checkbox></th>
                        <th width="30%" class="text-truncate">NAME</th>
                        <th width="65%" class="text-truncate">MESSAGE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(state, index) in dataTable" :key="index" style="cursor:pointer" @click="openSlideCreateWhatsapp">
                        <td class="text-truncate text-center"><b-form-checkbox type="checkbox"></b-form-checkbox></td>
                        <td class="text-truncate">{{ state?.name }}</td>
                        <td>{{ state?.message }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- <p class="text-center my-4">No ports data found.</p> -->
              </template>
            </template>
          </iq-card>
        </b-col>
        <!-- End Card Fleet -->
      </b-row>
    </b-col>

    <b-col lg="12">
      <!-- SLIDE - CREATE EMAIL -->
      <b-sidebar
        v-model="slideCreateWhatsapp"
        id="slideCreateWhatsapp"
        :title="'Create Whatsapp Template'"
        width="100%"
        backdrop-variant="dark"
        bg-variant="white"
        right
        backdrop
        shadow>
        <template #default="{hide}">
          <SlideFormCreateWhatsapp  :openSlideCreateWhatsapp="openSlideCreateWhatsapp" />
        </template>
      </b-sidebar>
      <!-- SLIDE - CREATE EMAIL END -->
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'
import { saveAs } from 'file-saver'
import { portsActions, usersActions } from '@src/Utils/helper'
import SlideFormCreateWhatsapp from './SlideFormCreateWhatsapp.vue'

export default {
  name: 'TemplateWhatsapp',
  data () {
    return {
      slideCreateWhatsapp:false,
      dataTable: [
        {
          name: '	Invitation:Test',
          message:'Dear ${applicant_name}, thank you for your interest in joining ${company_id}. We appreciate the time you take to apply at our company for a ${job}. Through this message, we would like to inform you that we are interested in your resume. For the next process, we invite you to take this online test ${survey_name} which you can take by clicking the link in this message: ${survey_url}'
        },
      ]
    }
  },
  components:{
    SlideFormCreateWhatsapp
  },
  mounted () {},
  methods: {
    openSlideCreateWhatsapp(){
      this.slideCreateWhatsapp = !this.slideCreateWhatsapp
    }
  }
}
</script>