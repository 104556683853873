var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("DOCKING PROGRESS")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.docking_schedule_progress_card",
            modifiers: {
              "docking_schedule_progress_card": true
            }
          }],
          staticClass: "text-primary float-right ml-3",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })]), _c('ModalDocking', {
          attrs: {
            "action": _vm.dockingAction,
            "vehicle-id": _vm.selectedVehicleId,
            "docking-id": _vm.selectedDockingId
          },
          on: {
            "cancel": _vm.onCloseModalCreateDocking,
            "submit": _vm.onSubmitCreateDocking
          },
          model: {
            value: _vm.showDockingModal,
            callback: function callback($$v) {
              _vm.showDockingModal = $$v;
            },
            expression: "showDockingModal"
          }
        }), _c('ModalDockingActivity', {
          attrs: {
            "docking-id": _vm.selectedDockingId
          },
          on: {
            "cancel": _vm.onCloseModalActivityDocking,
            "submit": _vm.onSubmitActivityDocking
          },
          model: {
            value: _vm.showActivityDockingModal,
            callback: function callback($$v) {
              _vm.showActivityDockingModal = $$v;
            },
            expression: "showActivityDockingModal"
          }
        })];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "visible": "",
            "id": "docking_schedule_progress_card"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet Category",
            "label-for": "fleet_category",
            "placeholder": "Select Fleet category"
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "label": "name",
            "options": _vm.vehicleTypes,
            "reduce": function reduce(type) {
              return type.id;
            }
          },
          on: {
            "input": _vm.onChangeFleetCategory
          },
          model: {
            value: _vm.lists.params.vehicleTypeIds,
            callback: function callback($$v) {
              _vm.$set(_vm.lists.params, "vehicleTypeIds", $$v);
            },
            expression: "lists.params.vehicleTypeIds"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "lg": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Vehicles",
            "label-for": "vehicleId"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.fleetFilteredOptions,
            "reduce": function reduce(fleetFilteredOptions) {
              return fleetFilteredOptions.value;
            },
            "placeholder": "Select Vehicle"
          },
          model: {
            value: _vm.lists.params.vehicleId,
            callback: function callback($$v) {
              _vm.$set(_vm.lists.params, "vehicleId", $$v);
            },
            expression: "lists.params.vehicleId"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Docking Type",
            "label-for": "docking_type"
          }
        }, [_c('v-select', {
          attrs: {
            "options": _vm.dockingTypes
          },
          model: {
            value: _vm.lists.params.dockingType,
            callback: function callback($$v) {
              _vm.$set(_vm.lists.params, "dockingType", $$v);
            },
            expression: "lists.params.dockingType"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status"
          }
        }, [_c('v-select', {
          attrs: {
            "options": _vm.dockingStatus
          },
          model: {
            value: _vm.lists.params.dockingStatus,
            callback: function callback($$v) {
              _vm.$set(_vm.lists.params, "dockingStatus", $$v);
            },
            expression: "lists.params.dockingStatus"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Period",
            "label-for": "Period"
          }
        }, [_c('date-picker', {
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "format": "DD MMM YYYY",
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          on: {
            "change": function change($event) {
              return _vm.onFilterChangeRangeDate();
            }
          },
          model: {
            value: _vm.lists.dateRange,
            callback: function callback($$v) {
              _vm.$set(_vm.lists, "dateRange", $$v);
            },
            expression: "lists.dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getDockingScheduleProgressData();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("FLEET")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DOCKING TYPE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("SHIPYARD")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DOCKING DATE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("COMPLETE DATE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("JOB")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_vm.lists.loading ? _c('tr', [_c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "9"
          }
        }, [_vm._v("Loading...")])]) : _vm._l(_vm.lists.data, function (data, index) {
          return _c('tr', {
            key: index,
            class: {
              'bg-danger': ['REJECT'].includes(data.status)
            }
          }, [_c('th', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(index + 1))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.vehicle.name))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.dockingType))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.shipyard.name))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.dockingSchedule))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.deliveryDate))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.jobCount.finishedJob) + "/" + _vm._s(data.jobCount.totalJob))]), _c('td', {
            staticClass: "text-center"
          }, [['SEA_TRIAL'].includes(data.status) ? _c('b-badge', {
            attrs: {
              "variant": "success d-block"
            }
          }, [_vm._v("SEA TRIAL")]) : ['REVIEW'].includes(data.status) ? _c('b-badge', {
            attrs: {
              "variant": "warning d-block"
            }
          }, [_vm._v("NEED REVIEW")]) : ['HANDOVER'].includes(data.status) ? _c('b-badge', {
            staticClass: "text-white w-100",
            staticStyle: {
              "background-color": "#9E07E6"
            },
            attrs: {
              "variant": "d-block"
            }
          }, [_vm._v("SERAH TERIMA")]) : ['APPROVE'].includes(data.status) ? _c('b-badge', {
            attrs: {
              "variant": "primary d-block"
            }
          }, [_vm._v("NEED APPROVE")]) : ['PROGRESS'].includes(data.status) ? _c('b-badge', {
            attrs: {
              "variant": "info d-block"
            }
          }, [_vm._v("ON PROGRESS")]) : ['REJECT'].includes(data.status) ? _c('b-badge', {
            attrs: {
              "variant": "light d-block"
            }
          }, [_vm._v("REJECT")]) : _vm._e()], 1), _c('td', {
            staticClass: "text-center"
          }, [_c('b-dropdown', {
            attrs: {
              "size": "lg",
              "variant": "link iq-bg-primary iq-border-radius-10",
              "toggle-class": "text-decoration-none",
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_c('span', {
                  staticClass: "dropdown-toggle p-0",
                  attrs: {
                    "id": "dropdownMenuButton5",
                    "data-toggle": "dropdown"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-ship m-0 text-primary"
                })])];
              },
              proxy: true
            }], null, true)
          }, [['REVIEW'].includes(data.status) ? _c('b-dropdown-item', {
            attrs: {
              "variant": "warning"
            },
            on: {
              "click": function click($event) {
                return _vm.onOpenModalDocking(data, 'REVIEW');
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-search mr-2"
          }), _vm._v("REVIEW")]) : _vm._e(), ['APPROVE'].includes(data.status) ? _c('b-dropdown-item', {
            attrs: {
              "variant": "success"
            },
            on: {
              "click": function click($event) {
                return _vm.onOpenModalDocking(data, 'APPROVE');
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-check mr-2"
          }), _vm._v("APPROVE")]) : _vm._e(), ['PROGRESS'].includes(data.status) ? _c('b-dropdown-item', {
            attrs: {
              "variant": "info"
            },
            on: {
              "click": function click($event) {
                return _vm.onOpenModalActivityDocking(data);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-comments mr-2"
          }), _vm._v("ACTIVITY")]) : _vm._e(), ['PROGRESS', 'REVIEW'].includes(data.status) ? _c('b-dropdown-item', {
            attrs: {
              "variant": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.onOpenModalDocking(data, 'UPDATE');
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-plus-circle mr-2"
          }), _vm._v("ADD JOB")]) : _vm._e(), ['REJECT'].includes(data.status) ? _c('b-dropdown-item', {
            attrs: {
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                return _vm.onOpenModalDocking(data, 'UPDATE');
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-percent mr-2"
          }), _vm._v("UPDATE DOCKING")]) : _vm._e(), ['SEA_TRIAL'].includes(data.status) ? _c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "success"
            }
          }, [_c('router-link', {
            attrs: {
              "to": "/docking/sea-trial?dockingId=".concat(data.id)
            }
          }, [_c('i', {
            staticClass: "fa fa-ship mr-2"
          }), _vm._v("SEA TRIAL")])], 1) : _vm._e(), ['HANDOVER'].includes(data.status) ? _c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "#9E07E6"
            }
          }, [_c('router-link', {
            attrs: {
              "to": "/docking/serah-terima?dockingId=".concat(data.id)
            }
          }, [_c('i', {
            staticClass: "fas fa-handshake mr-2"
          }), _vm._v("SERAH TERIMA")])], 1) : _vm._e(), _c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "success"
            },
            on: {
              "click": function click($event) {
                return _vm.onOpenModalDocking(data, 'DETAIL');
              }
            }
          }, [_c('i', {
            staticClass: "fas fa-info-circle"
          }), _vm._v(" DETAIL")])], 1)], 1)]);
        })], 2)]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.lists.meta.total,
            "per-page": _vm.lists.meta.perPage,
            "align": "center"
          },
          on: {
            "input": _vm.getDockingScheduleProgressData
          },
          model: {
            value: _vm.lists.params.page,
            callback: function callback($$v) {
              _vm.$set(_vm.lists.params, "page", $$v);
            },
            expression: "lists.params.page"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }