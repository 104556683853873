<template>
  <div v-if="activeRecommendation">
    <!-- Top Section !-->
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>Recommendation Form List</strong></h4>
          </template>
          <template v-slot:headerAction>
            <a class="text-primary float-right" v-b-toggle.collapse-filter-recommendation role="button"
               style="cursor: pointer;">
              <i class="fa fa-filter" />
            </a>
          </template>
          <template v-slot:body>
            <b-collapse id="collapse-filter-recommendation" visible>
              <b-row>
                <b-col md="3">
                  <b-form-group label-for="dateIssued" label="Date Range">
                    <date-picker @change="handleDateRange" class="w-100" v-model="dateRange" type="date" value-type="YYYY-MM-DD"
                                 range placeholder="Select date range"></date-picker>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label-for="dateIssued" label="Fleet/Vessel">
                    <p v-if="vehicleId" class="form-control-static">
                      {{fleets.find(fleet => fleet.code === vehicleId) ? fleets.find(fleet => fleet.code === vehicleId).name : '-'}}
                    </p>
                    <v-select v-else v-model="recommendations.params.vehicle_id" label="name" :options="fleets"
                              :reduce="fleetList => fleetList.code"></v-select>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label-for="dateIssued" label="Type of Coverage">
                    <v-select v-model="recommendations.params.type_coverage_id" label="name" :options="typeCoverages"
                              :reduce="typeOfCoverage => typeOfCoverage.code"></v-select>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label-for="dateIssued" label="Type of Loss">
                    <v-select v-model="recommendations.params.type_loss_id" label="name" :options="typeLoss"
                              :reduce="typeOfLoss => typeOfLoss.code"></v-select>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label-for="dateIssued" label="Status">
                    <v-select v-model="recommendations.params.status_id" label="text" :options="statusOptions"
                              :reduce="statusOptions => statusOptions.value"></v-select>
                  </b-form-group>
                </b-col>
                <b-col md="1">
                  <b-form-group label="Filter" label-for="button_search">
                    <b-button variant="primary" class="mb-3 mr-1" @click="handleFetchRecommendation(1)"><i
                      class="fa fa-filter"></i></b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-collapse>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Top Section !-->
    <!-- Content Section !-->
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="text-right">
                <router-link to="#" v-if="canExport || canExportRecommendation">
                  <button class="btn btn-dark mb-3 mr-1"><i class="fa fa-file-excel"></i> Export</button>
                </router-link>
                <router-link to="/mi/add-form-recommendation" v-if="canAdd && canAddRecommendation">
                  <button class="btn btn-primary mb-3"><i class="fa fa-plus"></i> Add Form Recommendation</button>
                </router-link>
              </b-col>
              <b-col cols="12">
                <table class="table mb-0 table-borderless table-box-shadow">
                  <thead>
                  <tr>
                    <th>No.</th>
                    <th>Permasalahan</th>
                    <th>Vessel Case</th>
                    <th>Root Cause</th>
                    <th>Rekomendasi</th>
                    <th>Cause of Loss</th>
                    <th>Status</th>
                    <th>Closed Date</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-if="recommendations.data.length === 0 || recommendations.isLoading">
                    <td colspan="10" class="text-center">{{ recommendations.isLoading ? 'Loading...' : "No Data" }}</td>
                  </tr>
                  <tr v-for="(data, index) in recommendations.data" :key="index">
                    <td>{{recommendations.meta.pageNumber + index}}</td>
                    <td>{{data.problem}}</td>
                    <td>{{data.vessel_case}}</td>
                    <td>{{data.root_cause}}</td>
                    <td>{{data.recommendation}}</td>
                    <td>{{(causesOfLoss.find(val => data.cause_of_loss_id === val.code)) ? causesOfLoss.find(val => data.cause_of_loss_id === val.code).name : '-'}}</td>
                    <td>{{data.status}}</td>
                    <td>{{data.closed_at}}</td>
                    <td>
                      <b-button @click="handleEditRecommendation(data.id, data.form_id)" variant="info" size="sm" v-if="canEditRecommendation"><i class="fa fa-edit"></i> </b-button>
                      <b-button @click="handleDeleteRecommendation(data)" variant="danger" size="sm" v-if="canDeleteRecommendation"><i class="fa fa-trash"></i> </b-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="mt-3 mb-3">
                  <b-pagination
                    v-model="recommendations.params.page"
                    :total-rows="recommendations.meta.total"
                    :per-page="recommendations.meta.perPage"
                    align="center"
                    @input="handleFetchRecommendation"
                  ></b-pagination>
                </div>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Content Section !-->
  </div>
  <div v-else>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:body>
            <p>You don't have permission to access this menu</p>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { companiesActions, fleetsActions, miActions } from '@src/Utils/helper'
import moment from 'moment'

export default {
  name: 'ListInitialRecommendation',
  props: {
    vehicleId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      lsProfile: JSON.parse(localStorage.getItem('profile')),
      dateRange: [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      statuses: [],
      fleets: [],
      typeCoverages: [],
      typeLoss: [],
      statusOptions: [],
      causesOfLoss: [],
      recommendations: {
        isLoading: false,
        data: [],
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
          pageNumber:0
        },
        params: {
          page: 1,
          from: null,
          to: null,
          vehicle_id: null,
          status_id: null,
          type_coverage_id: null,
          type_loss_id: null
        }
      }
    }
  },
  mounted () {
    if (this.vehicleId) {
      this.recommendations.params.vehicle_id = this.vehicleId
    }

    this.handleDateRange()
    this.handleFetchCauseLoss()
    this.handleFetchFleets()
    this.handleFetchTypeCoverages()
    this.handleFetchTypeLoss()
    this.handleFetchStatusList()
    this.handleFetchRecommendation()
  },
  computed: {
    menuPermission () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Marine Insurance')?.child.find(child => child.menu === 'List Initial Accident / Incident')
    },
    canAdd () {
      return this.menuPermission ? this.menuPermission?.add : false
    },
    canEdit () {
      return this.menuPermission ? this.menuPermission?.update : false
    },
    canApprove () {
      return this.menuPermission ? this.menuPermission?.approval : false
    },
    canReview () {
      return this.menuPermission ? this.menuPermission?.review : false
    },
    canDelete () {
      return this.menuPermission ? this.menuPermission?.delete : false
    },
    canExport () {
      return this.menuPermission ? this.menuPermission?.export : false
    },
    recommendation () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Marine Insurance')?.child.find(child => child.menu === 'Recommendation')
    },
    activeRecommendation () {
      return this.recommendation ? this.recommendation?.active : false
    },
    canAddRecommendation () {
      return this.recommendation ? this.recommendation?.add : false
    },
    canEditRecommendation () {
      return this.recommendation ? this.recommendation?.update : false
    },
    canApproveRecommendation () {
      return this.recommendation ? this.recommendation?.approval : false
    },
    canReviewRecommendation () {
      return this.recommendation ? this.recommendation?.review : false
    },
    canDeleteRecommendation () {
      return this.recommendation ? this.recommendation?.delete : false
    },
    canExportRecommendation () {
      return this.recommendation ? this.recommendation?.export : false
    },
  },
  methods: {
    ...fleetsActions,
    ...companiesActions,
    ...miActions,

    handleDateRange () {
      this.recommendations.params.from = this.dateRange ? moment(this.dateRange[0]).format('YYYY-MM-DD') : null
      this.recommendations.params.to = this.dateRange ? moment(this.dateRange[1]).format('YYYY-MM-DD') : null
    },
    async handleFetchRecommendation (page = 1) {
      this.recommendations.data = []
      this.recommendations.isLoading = true
      this.recommendations.params.page = page
      const { data } = await this.getRecommendationList(this.recommendations.params)

      if (data.data) {
        this.recommendations.data = data.data

        this.recommendations.meta.perPage = data.per_page
        this.recommendations.meta.currentPage = data.current_page
        this.recommendations.meta.total = data.total
        this.recommendations.meta.pageNumber = data.from
      }

      this.recommendations.isLoading = false
    },
    async handleFetchFleets () {
      this.fleetsLoading = true
      this.fleets = []

      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
        search: ''
      })
      let res = await this.getFleets(params)

      if (res.status) {
        this.fleetsLoading = false
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.fleets.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
    async handleFetchTypeCoverages () {
      this.typeCoverages = []
      let res = await this.getTypeCoverages({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.typeCoverages.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
    async handleFetchCauseLoss () {
      this.causesOfLoss = []
      let res = await this.getMasterCauseOfLoss({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach(el => {
            this.causesOfLoss.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
    async handleFetchTypeLoss () {
      this.typeLoss = []
      let res = await this.getTypeLoss({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.typeLoss.push({
              name: el.name,
              code: el.id
            })
          })

          this.statusOptions = res.data.map((val) => ({
            value: val.id,
            text: val.name
          }))
        }
      }
    },
    async handleFetchStatusList () {
      this.statuses = []
      let res = await this.getStatuses({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.statuses.push({
              name: el.name,
              code: el.id
            })
          })
          this.statusOptions = res.data.map((val) => ({
            value: val.id,
            text: val.name
          }))
        }
      }
    },
    onCreateRecommendation (formId) {
      this.$router.push({
        name: 'mi.add-form-recommendation',
        query: {
          formId
        }
      })
    },
    handleEditRecommendation (id, formId) {
      this.$router.push({
        name: 'mi.add-form-recommendation',
        params: {
          id
        },
        query: {
          formId
        }
      })
    },
    handleDeleteRecommendation (recommendation) {
      this.$swal({
        title: 'Delete Recommendation?',
        text: `Recommendation for ${recommendation.problem} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.deleteRecommendation(recommendation.id)
          if (res.status !== 'success') {
            if (res.data.message !== null) { this.$swal('Error', res.data.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get Recommendation data.',
                'error'
              )
            }
          } else {
            this.handleFetchRecommendation()
            this.$swal(
              `Recommendation deleted!`,
              `Recommendation for ${recommendation.problem} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
