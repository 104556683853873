<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-4 p-0" :active="true" href="#hasil_rakor" title="HASIL RAKOR" />
                <tab-nav-items class="col-md-4 p-0" :active="false" href="#proses_penawaran" title="PROSES PENAWARAN" />
                <tab-nav-items class="col-md-4 p-0" :active="false" href="#realisasi" title="REALISASI" />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <div class="iq-edit-list-data">
          <div id="pills-tabContent-2" class="tab-content">
            <tab-content-item :active="true" id="hasil_rakor" >
              <iq-card>
                <template v-slot:headerTitle>
                    <h5 class="card-title text-primary"><strong>HASIL RAKOR</strong></h5>
                </template>
                <template v-slot:headerAction>
                  <b-button @click="slideAddRakor = !slideAddRakor;"  variant="primary" class="mr-1"><i class="fa fa-plus"></i> &nbsp; TAMBAH RAKOR</b-button>
                </template>
                <template v-slot:body>
                  <!-- FILTER START -->
                  <b-row>
                      <b-col md="3">
                        <b-form-group label="Period" label-for="Period">
                          <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range"></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label="Keyword" label-for="keyword">
                          <b-form-input type="text" id="keyword"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group style label="Search" label-for="button_search">
                          <b-button variant="primary" class="mb-3 mr-1"><i class="fa fa-search"></i></b-button>
                        </b-form-group>
                      </b-col>
                  </b-row>
                  <!-- FILTER END -->

                  <b-row>
                    <b-col md="12">
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th class="text-center">NO</th>
                            <th class="text-center">NAMA PERUSAHAAN</th>
                            <th class="text-center">POTENSI PROJECT</th>
                            <th class="text-center">POTENSI TRIP</th>
                            <th class="text-center">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th class="text-center">1</th>
                            <td class="text-center">ARUTMIN</td>
                            <td class="text-center">10</td>
                            <td class="text-center">41</td>
                            <td class="text-center">
                              <b-button @click="slideDetailRakor = !slideDetailRakor;" size="sm" variant="primary">
                                <i class="fa fa-eye pr-0"></i> Detail
                              </b-button>
                            </td>
                          </tr>
                          <tr>
                            <th class="text-center">2</th>
                            <td class="text-center">KPC</td>
                            <td class="text-center">15</td>
                            <td class="text-center">50</td>
                            <td class="text-center">
                              <b-button @click="slideDetailRakor = !slideDetailRakor;" size="sm" variant="primary">
                                <i class="fa fa-eye pr-0"></i> Detail
                              </b-button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="mt-3">
                        <b-pagination :value="1" :total-rows="50" align="center" />
                      </div>
                    </b-col>
                  </b-row>

                  <!-- SLIDE - FORM DETAIL RAKOR -->
                  <b-sidebar
                    v-model="slideDetailRakor"
                    id="detail_rakor"
                    title="TAHUN 2023"
                    width="70%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col cols="12">
                          <table class="table table-striped table-sm">
                            <thead>
                              <tr>
                                <th class="text-center">NO</th>
                                <th class="text-center">LOCATION</th>
                                <th class="text-center">TERM</th>
                                <th class="text-center">PLAN DATE</th>
                                <th class="text-center">PLAN TRIP</th>
                                <th class="text-center">PLAN CARGO</th>
                                <th class="text-center">ACTION</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th class="text-center">1</th>
                                <td class="text-center">LABUAN</td>
                                <td class="text-center">CIF</td>
                                <td class="text-center">
                                  <b-badge variant="success d-block" class="mb-1">18/01/2023</b-badge>
                                  <b-badge variant="success d-block" class="mb-1">20/01/2023</b-badge>
                                  <b-badge variant="success d-block" class="mb-1">25/01/2023</b-badge>
                                </td>
                                <td class="text-center">3</td>
                                <td class="text-center">30.000</td>
                                <td class="text-center">
                                  <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                      <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                        <i class="fa fa-eye m-0 text-primary"></i>
                                      </span>
                                    </template>
                                    <b-dropdown-item href="#" variant="info"><i class="fa fa-pencil-square-o mr-2"></i>EDIT</b-dropdown-item>
                                    <b-dropdown-item href="#" variant="danger"><i class="fa fa-trash mr-2"></i>HAPUS</b-dropdown-item>
                                  </b-dropdown>
                                </td>
                              </tr>
                              <tr>
                                <th class="text-center">2</th>
                                <td class="text-center">LABUAN</td>
                                <td class="text-center">FOB</td>
                                <td class="text-center">
                                  <b-badge variant="success d-block" class="mb-1">18/01/2023</b-badge>
                                  <b-badge variant="success d-block" class="mb-1">23/01/2023</b-badge>
                                  <b-badge variant="success d-block" class="mb-1">27/01/2023</b-badge>
                                </td>
                                <td class="text-center">3</td>
                                <td class="text-center">37.000</td>
                                <td class="text-center">
                                  <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                      <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                        <i class="fa fa-eye m-0 text-primary"></i>
                                      </span>
                                    </template>
                                    <b-dropdown-item href="#" variant="info"><i class="fa fa-pencil-square-o mr-2"></i>EDIT</b-dropdown-item>
                                    <b-dropdown-item href="#" variant="danger"><i class="fa fa-trash mr-2"></i>HAPUS</b-dropdown-item>
                                  </b-dropdown>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="mt-3">
                            <b-pagination :value="1" :total-rows="50" align="center" />
                          </div>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE - FORM DETAIL RAKOR END -->

                  <!-- SLIDE - FORM ADD PLAN -->
                  <b-sidebar
                    v-model="slideAddRakor"
                    id="add_rakor"
                    title="ADD PLAN / RAKOR PROJECT"
                    width="100%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col cols="4">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Company Info</legend>
                            <b-alert :show="true" variant="primary">
                              <template v-for="(item,index) in company">
                                <b-form-radio inline v-model="companyActive[item[Object.keys(item)[0]]]" :name="item.name" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                              </template>
                            </b-alert>
                            <b-form-row>
                              <b-col>
                                <b-form-group label="Nama Perusahaan" label-for="nama_perusahaan">
                                  <b-form-input type="text" id="nama_perusahaan"></b-form-input>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="Email Perusahaan" label-for="email_perusahaan">
                                  <b-form-input type="email" id="email_perusahaan"></b-form-input>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                            <b-form-row>
                              <b-col>
                                <b-form-group label="Negara" label-for="negara">
                                  <template v-for="(item,index) in state">
                                    <b-form-radio inline v-model="stateActive[item[Object.keys(item)[0]]]" :name="item.name" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                                  </template>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="Provinsi" label-for="provinsi">
                                  <b-form-select plain v-model="selectedProvinsi" :options="optionProvinsi" size="sm">
                                    <template v-slot:first>
                                    <b-form-select-option :value="null">Select Provinsi</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                            <b-form-row>
                              <b-col>
                                <b-form-group label="Kota" label-for="kota">
                                  <b-form-select plain v-model="selectedKota" :options="optionKota" size="sm">
                                    <template v-slot:first>
                                    <b-form-select-option :value="null">Select Kota</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="Kecamatan" label-for="kecamatan">
                                  <b-form-select plain v-model="selectedKecamatan" :options="optionKecamatan" size="sm">
                                    <template v-slot:first>
                                    <b-form-select-option :value="null">Select Kecamatan</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                            <b-form-row>
                              <b-col>
                                <b-form-group label="Kelurahan" label-for="kelurahan">
                                  <b-form-select plain v-model="selectedKelurahan" :options="optionKelurahan" size="sm">
                                    <template v-slot:first>
                                    <b-form-select-option :value="null">Select Kelurahan</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="Alamat" label-for="alamat">
                                  <b-form-textarea id="alamat" rows="3"></b-form-textarea>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                            <b-form-row>
                              <b-col>
                                <b-form-group label="PIC Name" label-for="pic">
                                  <b-form-input type="text" id="pic"></b-form-input>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="Handphone / Telp." label-for="telepon">
                                  <b-form-input type="text" id="telepon"></b-form-input>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                          </fieldset>
                        </b-col>
                        <b-col cols="8">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Detail Plan</legend>
                            <b-alert :show="true" variant="primary">
                              <b-form-select plain v-model="selectedTahun" :options="optionTahun" size="sm" style="border: solid 1px gray;">
                                <template v-slot:first>
                                <b-form-select-option :value="null">Select Tahun</b-form-select-option>
                                </template>
                              </b-form-select>
                            </b-alert>
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th class="text-center">NO</th>
                                  <th class="text-center">LOCATION</th>
                                  <th class="text-center">TERM</th>
                                  <th class="text-center">MONTH</th>
                                  <th class="text-center">PLAN DATE</th>
                                  <th class="text-center">PLAN CARGO</th>
                                  <th class="text-center">ACTION</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th class="text-center align-top">#1</th>
                                  <td class="text-center align-top">
                                    <b-form-input type="text" id="nama_perusahaan" size="sm" style="border: solid 1px gray;"></b-form-input>
                                  </td>
                                  <td class="text-center align-top">
                                    <b-form-select plain size="sm" style="border: solid 1px gray;">
                                      <b-form-select-option>SELECT TERM</b-form-select-option>
                                      <b-form-select-option>CIF</b-form-select-option>
                                      <b-form-select-option>FOB</b-form-select-option>
                                    </b-form-select>
                                  </td>
                                  <td class="text-center align-top">
                                    <b-form-select plain size="sm" style="border: solid 1px gray;">
                                      <b-form-select-option>SELECT MONTH</b-form-select-option>
                                      <b-form-select-option>JANUARI</b-form-select-option>
                                      <b-form-select-option>FEBRUARI</b-form-select-option>
                                      <b-form-select-option>MARET</b-form-select-option>
                                      <b-form-select-option>APRIL</b-form-select-option>
                                      <b-form-select-option>MEI</b-form-select-option>
                                      <b-form-select-option>JUNI</b-form-select-option>
                                      <b-form-select-option>JULI</b-form-select-option>
                                      <b-form-select-option>AGUSTUS</b-form-select-option>
                                      <b-form-select-option>SEPTEMBER</b-form-select-option>
                                      <b-form-select-option>OKTOBER</b-form-select-option>
                                      <b-form-select-option>NOVEMBER</b-form-select-option>
                                      <b-form-select-option>DESEMBER</b-form-select-option>
                                    </b-form-select>
                                  </td>
                                  <td class="text-center align-top">
                                    <b-form-input type="date" id="plan_date" class="mb-1" size="sm" style="border: solid 1px gray;"></b-form-input>
                                    <b-form-input type="date" id="plan_date" class="mb-1" size="sm" style="border: solid 1px gray;"></b-form-input>
                                    <span><b-badge variant="primary d-block"><i class="fa fa-plus pr-0"></i> Add Form</b-badge></span>
                                  </td>
                                  <td class="text-center align-top">
                                    <b-form-input type="number" id="plan_cargo" size="sm" style="border: solid 1px gray;"></b-form-input>
                                  </td>
                                  <td class="text-center align-top">
                                    <b-button size="sm" variant="primary"><i class="fa fa-trash pr-0"></i></b-button>
                                  </td>
                                </tr>
                                <tr>
                                  <th class="text-center align-top">#2</th>
                                  <td class="text-center align-top">
                                    <b-form-input type="text" id="nama_perusahaan" size="sm" style="border: solid 1px gray;"></b-form-input>
                                  </td>
                                  <td class="text-center align-top">
                                    <b-form-select plain size="sm" style="border: solid 1px gray;">
                                      <b-form-select-option>SELECT TERM</b-form-select-option>
                                      <b-form-select-option>CIF</b-form-select-option>
                                      <b-form-select-option>FOB</b-form-select-option>
                                    </b-form-select>
                                  </td>
                                  <td class="text-center align-top">
                                    <b-form-select plain size="sm" style="border: solid 1px gray;">
                                      <b-form-select-option>SELECT MONTH</b-form-select-option>
                                      <b-form-select-option>JANUARI</b-form-select-option>
                                      <b-form-select-option>FEBRUARI</b-form-select-option>
                                      <b-form-select-option>MARET</b-form-select-option>
                                      <b-form-select-option>APRIL</b-form-select-option>
                                      <b-form-select-option>MEI</b-form-select-option>
                                      <b-form-select-option>JUNI</b-form-select-option>
                                      <b-form-select-option>JULI</b-form-select-option>
                                      <b-form-select-option>AGUSTUS</b-form-select-option>
                                      <b-form-select-option>SEPTEMBER</b-form-select-option>
                                      <b-form-select-option>OKTOBER</b-form-select-option>
                                      <b-form-select-option>NOVEMBER</b-form-select-option>
                                      <b-form-select-option>DESEMBER</b-form-select-option>
                                    </b-form-select>
                                  </td>
                                  <td class="text-center align-top">
                                    <b-form-input type="date" id="plan_date" class="mb-1" size="sm" style="border: solid 1px gray;"></b-form-input>
                                    <b-form-input type="date" id="plan_date" class="mb-1" size="sm" style="border: solid 1px gray;"></b-form-input>
                                    <span><b-badge variant="primary d-block"><i class="fa fa-plus pr-0"></i> Add Form</b-badge></span>
                                  </td>
                                  <td class="text-center align-top">
                                    <b-form-input type="number" id="plan_cargo" size="sm" style="border: solid 1px gray;"></b-form-input>
                                  </td>
                                  <td class="text-center align-top">
                                    <b-button size="sm" variant="primary"><i class="fa fa-trash pr-0"></i></b-button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <b-button block variant="primary"><i class="fa fa-plus pr-0"></i> ADD MORE DATA</b-button>
                          </fieldset>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE - FORM ADD PLAN END -->
                </template>
              </iq-card>
            </tab-content-item>

            <tab-content-item :active="false" id="proses_penawaran">
              <iq-card>
                <template v-slot:headerTitle>
                    <h5 class="card-title text-primary"><strong>PROSES PENAWARAN</strong></h5>
                </template>
                <template v-slot:body>

                </template>
              </iq-card>
            </tab-content-item>

            <tab-content-item :active="false" id="realisasi">
              <iq-card>
                <template v-slot:headerTitle>
                    <h5 class="card-title text-primary"><strong>REALISASI</strong></h5>
                </template>
                <template v-slot:body>

                </template>
              </iq-card>
            </tab-content-item>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import _ from 'lodash'
import {
  fleetsActions
} from '@src/Utils/helper'
import { createPopper } from '@popperjs/core'

export default {
  name: 'ProfileEdit',
  mounted () {
    xray.index()
    this.fetchFleets()
  },
  data () {
    return {
      // fleet
      slideDetailRakor: false,
      slideAddRakor: false,
      
      // COMPANY
      companyActive: {
        single: 'active',
        disable: 'active',
        number: 'two',
        colorDisabled: 'five',
        color: 'danger'
      },
      company: [
        {
          name: 'single',
          label: 'NEW COMPANY',
          value: 'active',
          disabled: false
        },
        {
          name: 'single',
          label: 'EXISTING COMPANY',
          value: 'inactive',
          disabled: false
        }
      ],

      // NEGARA
      stateActive: {
        single: 'active',
        disable: 'active',
        number: 'two',
        colorDisabled: 'five',
        color: 'danger'
      },
      state: [
        {
          name: 'single',
          label: 'INDONESIA',
          value: 'active',
          disabled: false
        },
        {
          name: 'single',
          label: 'FOREIGN',
          value: 'inactive',
          disabled: false
        }
      ],
      
      // SELECT PROVINSI
      selectedProvinsi: 1,
      optionProvinsi: [
        { value: '1', text: 'DKI JAKARTA' },
        { value: '2', text: 'JAWA BARAT' },
        { value: '3', text: 'JAWA TENGAH' },
        { value: '4', text: 'JAWA TIMUR' }
      ],

      // SELECT PROVINSI
      selectedKota: 4,
      optionKota: [
        { value: '1', text: 'JAKARTA PUSAT' },
        { value: '2', text: 'JAKARTA UTARA' },
        { value: '3', text: 'JAKARTA TIMUR' },
        { value: '4', text: 'JAKARTA SELATAN' },
        { value: '5', text: 'JAKARTA BARAT' },
        { value: '6', text: 'KEPULAUAN SERIBU' },
      ],

      // SELECT KECAMATAN
      selectedKecamatan: 1,
      optionKecamatan: [
        { value: '1', text: 'KEBAYORAN LAMA' },
        { value: '2', text: 'KEBAYORAN BARU' },
        { value: '3', text: 'GROGOL' },
        { value: '4', text: 'PASAR MINGGU' }
      ],

      // SELECT KELURAHAN
      selectedKelurahan: 1,
      optionKelurahan: [
        { value: '1', text: 'PONDOK PINANG' },
        { value: '2', text: 'PESANGGRAHAN' },
        { value: '3', text: 'PETUKANGAN' }
      ],

      // SELECT TAHUN
      selectedTahun: null,
      optionTahun: [
        { value: '1', text: '2023' },
        { value: '2', text: '2022' },
        { value: '3', text: '2021' },
        { value: '3', text: '2020' }
      ],
    }
  }
}
</script>