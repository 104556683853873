<template>
  <b-container id="forecast-form" style="overflow-x: scroll;" fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>{{id ? (!isPreview ? 'Edit Year' : 'Detail Year') : 'Add New Year'}} - Balancing Loss Ratio</strong></h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="4">
                <div class="form-group">
                  <label class="mb-3">Select Vehicle Type</label>
                  <v-select label="name" :options="typeFleet" v-model="checkPayload.group_id" :reduce="val => val.code"
                    placeholder="Select Vehicle Type" :clearable="false" :disabled="forecastAvailable"></v-select>
                </div>
              </b-col>
              <b-col md="2">
                <div class="form-group">
                  <label class="mb-3">Select Year</label>
                  <v-select v-model="checkPayload.year" :options="yearOption" placeholder="Select Year"
                    :clearable="false" :disabled="forecastAvailable"></v-select>
                </div>
              </b-col>
              <b-col md="2" v-if="!id">
                <b-button v-if="!forecastAvailable" @click="checkForecast(checkPayload)" variant="success" class="mt-4" style="margin-top: 37px!important;">
                  Check
                </b-button>
                <b-button v-else @click="forecastAvailable = false" variant="danger" class="mt-4" style="margin-top: 37px!important;">
                  Change
                </b-button>
              </b-col>
              <!--              <b-col md="3">
                <div class="form-group">
                  <label>Premium &nbsp;&nbsp;&nbsp;</label>
                  <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color ml-2">
                    <span><small>From Budget &nbsp;</small></span>
                    <div class="custom-switch-inner">
                      <input type="checkbox" class="custom-control-input" :class="'bg-'+premiumFromBudget.class" :id="'premiumFromBudget'" v-model="premiumFromBudget.checked">
                      <label class="custom-control-label" :for="'premiumFromBudget'" :data-on-label="premiumFromBudget.activeLabel" :data-off-label="premiumFromBudget.inactiveLabel">
                      </label>
                    </div>
                  </div>
                  <b-form-input type="text" placeholder="Input Premium"></b-form-input>
                  <div class="text-right"><router-link to="" v-b-toggle.show-budget>Show Budget</router-link>
                  </div>
                </div>
              </b-col>
              <b-col md="3">
                <div class="form-group">
                  <label class="mb-3">Claim Loss</label>
                  <b-form-input type="text" placeholder="Input Premium"></b-form-input>
                </div>
              </b-col>
              <b-col md="2">
                <div class="form-group">
                  <label class="mb-3">Loss Ratio</label>
                  <b-input-group size="md" append="%">
                    <b-form-input type="number"></b-form-input>
                  </b-input-group>
                </div>
              </b-col>-->
            </b-row>
            <b-row>
              <b-col md="10">
                <p v-if="vehicleTypes">{{vehicleTypes}}</p>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <template v-if="forecastAvailable">
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerAction>
              <b-button v-if="isPreview" class="btn btn-primary" @click="handleEdit(Number(id))" variant="success">
                Edit
              </b-button> 
              <b-button :disabled="saving" @click="onSave()" variant="success">
                Simpan
              </b-button>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="12">
          <iq-card>
            <template v-slot:body>
              <b-row>
                <b-col md="3">
                  <div class="form-group">
                    <label class="mb-3">
                      <h4 class="text-capitalize">Forecast Premium <small>(Dengan Beban)</small></h4>
                    </label>
                    <h3>{{ formatCurrency(budgetGrandTotalSum) }}</h3>
                  </div>
                </b-col>
                <b-col md="3">
                  <div class="form-group">
                    <label class="mb-3">
                      <h4 class="text-capitalize">Forecast Premium <small>(Tanpa Beban)</small></h4>
                    </label>
                    <h3>{{ formatCurrency(forecastPremium) }}</h3>
                  </div>
                </b-col>
                <b-col md="3">
                  <div class="form-group">
                    <label class="mb-3">
                      <h4 class="text-capitalize">Actual Premium</h4>
                    </label>
                    <b-input-group size="md" prepend="IDR">
                      <b-form-input v-model="actualPremium" type="number"></b-form-input>
                    </b-input-group>
                    <p>{{formatCurrency(actualPremium)}}</p>
                  </div>
                </b-col>
                <b-col md="3" class="d-none">
                  <div class="form-group">
                    <label class="mb-3">
                      <h4 class="text-capitalize">Rate HM</h4>
                    </label>
                    <b-input-group size="md" append="%">
                      <b-form-input v-model="currentHm" type="number" width="50%"></b-form-input>
                    </b-input-group>
                  </div>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
        <!-- Loading Point -->
        <b-col md="12">
          <iq-card>
            <template v-slot:body>
              <b-row>
                <b-col md="8">
                  <b-row>
                    <b-col md="4">
                      <div class="form-group">
                        <label class="mb-3">
                          <h4 class="text-capitalize">Loading Point Insurer</h4>
                        </label>
                        <h3>{{loadingPointInsurer.toFixed(2)}}%</h3>
                      </div>
                    </b-col>
                    <b-col md="4">
                      <div class="form-group">
                        <label class="mb-3">
                          <h4 class="text-capitalize">Additional Loading Point Insurer</h4>
                        </label>
                        <h3>{{additionalLoadingPointInsurer.toFixed(2)}}%</h3>
                      </div>
                    </b-col>
                    <b-col md="4">
                      <div class="form-group">
                        <label class="mb-3">
                          <h4 class="text-capitalize">Final Loading Point</h4>
                        </label>
                        <h3>{{finalLoadingPointInsurer.toFixed(3)}}%</h3>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="4">
                  <div class="mb-4">
                    NILAI TAMBAH YANG DIBERIKAN OLEH ASURANSI KEPADA OWNER UNTUK MENAMBAH BEBAN RATE
                  </div>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Claim</th>
                        <th>Percentage (%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0 - IDR 1.000.000.000</td>
                        <td>2.5%</td>
                      </tr>
                      <tr>
                        <td>IDR 1.100.000.000 - IDR 2.000.000.000</td>
                        <td>5.0%</td>
                      </tr>
                      <tr>
                        <td>IDR 2.100.000.000 - IDR 3.000.000.000</td>
                        <td>7.5%</td>
                      </tr>
                      <tr>
                        <td>IDR 3.100.000.000 - IDR 4.000.000.000</td>
                        <td>10.0%</td>
                      </tr>
                      <tr>
                        <td>IDR 4.100.000.000 - IDR 5.000.000.000</td>
                        <td>12.5%</td>
                      </tr>
                      <tr>
                        <td>IDR 5.100.000.000 - IDR 6.000.000.000</td>
                        <td>15.0%</td>
                      </tr>
                      <tr>
                        <td>IDR 6.100.000.000 - IDR 7.000.000.000</td>
                        <td>17.5%</td>
                      </tr>
                      <tr>
                        <td>IDR 7.100.000.000 - IDR 8.000.000.000</td>
                        <td>20.0%</td>
                      </tr>
                      <tr>
                        <td>IDR 8.100.000.000 - IDR 9.000.000.000</td>
                        <td>22.5%</td>
                      </tr>
                      <tr>
                        <td>IDR 9.100.000.000 - IDR 10.000.000.000</td>
                        <td>25.0%</td>
                      </tr>
                      <tr>
                        <td>IDR 10.100.000.000 - IDR 11.000.000.000</td>
                        <td>27.5%</td>
                      </tr>
                      <tr>
                        <td>> IDR 11.000.000.000</td>
                        <td>30.0%</td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
        <!-- End of Loading Point -->
        <!-- Step 1 -->
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-capitalize text-black"><strong>Step 1 - Pure Risk Component</strong></h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right"
                v-b-toggle.collapse-forecast-step-1
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-window-minimize" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse id="collapse-forecast-step-1" class="mb-2" :visible="!isPreview">
                <div class="row">
                  <div class="col-md-6">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                        <tr>
                          <th>No</th>
                          <th>Details</th>
                          <th>Percentage</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in pureRiskItems" :key="item.id">
                          <td>{{index+1}}.</td>
                          <td>{{item.name.toUpperCase()}}</td>
                          <td>
                            <b-input-group size="md" append="%">
                              <b-form-input type="number" v-model="item.value" style="max-width: 50%;" step=".00001"></b-form-input>
                            </b-input-group>
                          </td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                          <td colspan="2" class="text-center bg-success"><strong>Total</strong></td>
                          <td class="text-center bg-success"><strong>{{pureRiskItemTotal.toFixed(2)}}%</strong></td>
                        </tr>
                        <tr>
                          <td colspan="2" class="text-center bg-info"><strong>Pure Risk Cost</strong></td>
                          <td class="text-center bg-info"><strong>{{pureRiskItemCost.toFixed(2)}}%</strong></td>
                        </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <img src="@assets/images/financial-pure-risk-component.jpg" width="100%" class="mt-5" />
                  </div>
                </div>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- End Step 1 -->
        <!-- Step 2 -->
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-capitalize text-black"><strong>Step 2 - Outstanding & Future Claim</strong>
              </h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right"
                v-b-toggle.collapse-forecast-step-2
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-window-minimize" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse id="collapse-forecast-step-2" class="mb-2" :visible="!isPreview">
                <div class="row">
                  <div class="col-md-12 mb-3">
                    <h4 class="mb-4">Outstanding (From Claim Form)</h4>
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th>No</th>
                        <th>Vessel / Form Claim Linked</th>
                        <th>EST Cost</th>
                        <th>Nett Lost</th>
                        <th>Additional THUMB RULES (1%)</th>
                        <th>Est Closed File</th>
                        <th>Status</th>
                        <th>Period Coverage</th>
                        <th>#</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(data, index) in outstanding" :key="index">
                        <td>{{index + 1}}.</td>
                        <td>
                          <div v-if="!data.premiumFromBudget.checked">
                            <template v-if="id">
                              <template v-if="data?.forms?.vehicle || data?.forms?.cause_of_loss">
                                {{data?.forms?.vehicle ? data?.forms?.vehicle?.name : ''}} - {{data?.forms?.cause_of_loss ? data?.forms?.cause_of_loss?.name : ''}}
                              </template>
                              <template v-else>
                                {{data?.form_free_text}}
                              </template>
                            </template>
                            <template v-else>
                              <template v-if="data?.vehicle || data?.cause_of_loss">
                              {{data?.vehicle ? data?.vehicle?.name : ''}} - {{data?.cause_of_loss ? data?.cause_of_loss?.name : ''}}
                              </template>
                              <template v-else>
                                {{data?.form_free_text}}
                              </template>
                            </template>
                          </div>
                          <b-form-input v-else v-model="data.form_free_text" type="text"></b-form-input>
                        </td>
                        <td>
                          <h5 v-if="isPreview">{{formatCurrency(data.cost)}}</h5>
                          <b-form-input v-else v-model="data.cost" type="text"></b-form-input>
                        </td>
                        <td>
                          <h5 v-if="isPreview">{{formatCurrency(data.net_claim_loss)}}</h5>
                          <b-form-input v-else v-model="data.net_claim_loss" type="text"></b-form-input>
                        </td>
                        <td>
                          <h5>{{formatCurrency(data.thumb_rules_cost = (Number(data.cost) * (1 / 100)) + Number(data.cost))}}</h5>
                        </td>
                        <td>
                          <div v-if="!data.premiumFromBudget.checked">
                            <template>
                              <date-picker v-model="data.summary.act_settled_date" style="width: 100%" type="date" value-type="YYYY-MM-DD" placeholder="Select Date" v-if="data.follow_up_response === 'settled'"></date-picker>
                              <date-picker v-model="data.summary.est_settled_date" style="width: 100%" type="date" value-type="YYYY-MM-DD" placeholder="Select Date" v-else></date-picker>
                            </template>
                          </div>
                          <div v-else>
                            <date-picker v-if="data?.follow_up_response === 'settled'" v-model="data.summary.act_settled_date" style="width: 100%" type="date" value-type="YYYY-MM-DD" placeholder="Select Date"></date-picker>
                            <date-picker v-else v-model="data.summary.est_settled_date" style="width: 100%" type="date" value-type="YYYY-MM-DD" placeholder="Select Date"></date-picker>
                          </div>
                        </td>
                        <td>
                          <div v-if="!data.premiumFromBudget.checked">
                            {{ data.follow_up_response.split('_').join(' ') }}
                          </div>
                          <v-select v-else v-model="data.follow_up_response"
                                    :options="['on_progress', 'settled', 'withdrawal']"
                                    :reduce="val => val.split(' ').join('_')" :clearable="false"></v-select>
                        </td>
                        <td>
                          <date-picker style="width: 100%" @change="() => {
                              data.coverage_period = data.coverage_date_range ? moment(data.coverage_date_range[0]).format('YYYY') : null
                              data.coverage_period_to = data.coverage_date_range ? moment(data.coverage_date_range[1]).format('YYYY') : null
                            }" v-model="data.coverage_date_range" type="year" value-type="YYYY"
                                       range placeholder="Select year"></date-picker>
                        </td>
                        <td>
                          <b-button @click="deleteOutstanding(index)"
                                    variant="danger" size="sm"><i class="fa fa-trash"></i> </b-button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <b-col md="12">
                      <b-button @click="addOutstanding" variant="warning" class="mb-3 mr-1" block><i
                        class="ri-add-line"></i> Add More Data Outstanding</b-button>
                    </b-col>
                  </div>
                  <div class="col-md-12">
                    <hr />
                  </div>
                  <div class="col-md-12 mb-3">
                    <h4 class="mb-4">Future Claim</h4>
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                        <tr>
                          <th>Items</th>
                          <th>Content</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>Est Increase Loss Ratio</td>
                          <td>
                            <b-textarea rows="2" v-model="futureClaim.est_inc_loss_ratio"></b-textarea>
                          </td>
                        </tr>
                        <tr>
                          <td>Condition</td>
                          <td>
                            <b-textarea rows="2" v-model="futureClaim.condition"></b-textarea>
                          </td>
                        </tr>
                        <tr>
                          <td>Amount /Years</td>
                          <td>
                            <b-input-group size="md" prepend="IDR">
                              <b-form-input type="number" v-model="futureClaim.amount_of_years" style="max-width: 50%;"></b-form-input>
                            </b-input-group>
                            <p>{{formatCurrency(futureClaim.amount_of_years)}}</p>
                          </td>
                        </tr>
                        <tr>
                          <td>Estimate Loss Future Until Renewal</td>
                          <td>
                            <b-input-group size="md" prepend="IDR">
                              <b-form-input v-model="futureClaim.estimate_loss_future" type="number"
                                            style="max-width: 50%;"></b-form-input>
                            </b-input-group>
                            <p>{{formatCurrency(futureClaim.estimate_loss_future)}}</p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- End Step 2 -->
        <!-- Step 3 -->
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-capitalize text-black"><strong>Step 3 - Calculation & Forecast
                  Condition</strong></h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right"
                v-b-toggle.collapse-forecast-step-3
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-window-minimize" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse id="collapse-forecast-step-3" class="mb-2" :visible="!isPreview">
                <b-row>
                  <b-col md="6">
                    <b-col cols="12" class="text-center mb-3">
                      <template v-for="(item, index) in typeOfCalculation">
                        <b-form-radio v-model="typeCalculation" class="custom-radio-color-checked" inline
                                      :color="item.color" name="color" :key="index" :value="item.value" :disabled="item.disabled" @change="accumulativeRecords">
                          <strong>
                            <h5>{{ item.label }}</h5>
                          </strong></b-form-radio>
                      </template>
                    </b-col>
                  </b-col>
                  <b-col md="6" class="mb-4">
                    <b-input-group size="md" prepend="Input Your Max Number of Year for Calculation"
                                   v-if="typeCalculation === 'data'">
                      <b-form-input v-model="initialCalculationPayload.decrement" type="number"></b-form-input>
                      <b-button @click="() => {getInitialCalculation()}" variant="success">Check</b-button>
                    </b-input-group>
                  </b-col>
                  <b-col md="12" class="mb-5">
                    <b-alert :show="true" variant="secondary">
                      <div class="iq-alert-text text-center">LOSS RECORD</div>
                    </b-alert>
                    <b-row v-if="typeCalculation === 'data'">
                      <b-col md="12">
                        <table class="table table-striped table-warning">
                          <thead>
                          <tr>
                            <th>Year</th>
                            <th>Premium</th>
                            <th>Claim Loss</th>
                            <th>Loss Ratio</th>
                            <th>Pure Risk</th>
                            <th>Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(data, index) in individualRecordsBefore" :key="index">
                            <td><strong>{{data.year}}</strong></td>
                            <td>
                              <b-form-input
                                v-if="data.is_edit === true"
                                type="number"
                                id="actual_premium"
                                name="actual_premium"
                                placeholder="Type Number ..."
                                v-model="data.actual_premium"
                                class="border-black"
                              ></b-form-input>
                              <small v-if="data.is_edit === true" class="text-muted">{{formatCurrency(data.actual_premium)}}</small>
                              <span v-else>{{formatCurrency(data.actual_premium)}}</span>
                            </td>
                            <td>
                              <b-form-input
                                v-if="data.is_edit"
                                type="number"
                                id="claim_loss"
                                name="claim_loss"
                                placeholder="Type Number ..."
                                v-model="data.claim_loss"
                              ></b-form-input>
                              <small v-if="data.is_edit === true" class="text-muted">{{formatCurrency(data.claim_loss)}}</small>
                              <span v-else>{{formatCurrency(data.claim_loss)}}</span>
                            </td>
                            <td>{{parseFloat(data.loss_ratio).toFixed(2)}}%</td>
                            <td>
                              <b-form-input
                                v-if="data.is_edit"
                                type="number"
                                id="pure_risk"
                                name="pure_risk"
                                placeholder="Type Number ..."
                                v-model="data.pure_risk"
                              ></b-form-input>
                              <span v-else>{{parseFloat(data.pure_risk).toFixed(2)}}%</span>
                            </td>
                            <!-- <td>
                              <b-button v-if="data.manual" size="sm"
                                        variant="danger" class="mb-3 mr-1"><i class="ri-delete-bin-line"></i></b-button>
                            </td> -->
                            <td>
                              <b-button
                                variant="success"
                                size="sm"
                                class="mr-2"
                                @click="changeEditIndividualRecord(data, index)"
                              >
                              <i class="fa fa-edit" v-if="!data.is_edit"></i>
                              <i class="fa fa-check" v-else ></i>
                              </b-button>
                              <b-button
                                variant="danger"
                                size="sm"
                                @click="deleteIndividualRecord(index)" 
                              >
                                <i class="fa fa-trash" ></i>
                              </b-button>
                            </td>
                          </tr>
                          <tr>
                            <td><strong>{{initialIndividualRecords.year}}</strong></td>
                            <td>{{formatCurrency(initialIndividualRecords.actual_premium)}}</td>
                            <td>{{formatCurrency(initialIndividualRecords.claim_loss)}}</td>
                            <td>{{parseFloat(initialIndividualRecords.loss_ratio).toFixed(2)}}%</td>
                            <td>{{isNaN(initialIndividualRecords.pure_risk) ? 0 : Number(initialIndividualRecords.pure_risk).toFixed(2)}}%</td>
                          </tr>
                          <tr class="bg-white" v-if="typeCalculation === 'manual'">
                            <td>
                              <v-select v-model="calculation.year" label="text" :options="yearCalculationOption"
                                        placeholder="Select Year" :clearable="false"></v-select>
                            </td>
                            <td>
                              <b-form-input v-model="calculation.actual_premium" @input="setCalculationLossRatio"
                                            type="number" placeholder="Input Premium"></b-form-input>
                              <p>{{formatCurrency(calculation.actual_premium ?? 0)}}</p>
                            </td>
                            <td>
                              <b-form-input v-model="calculation.claim_loss" @input="setCalculationLossRatio"
                                            type="number" placeholder="Input Claim Loss"></b-form-input>
                              <p>{{formatCurrency(calculation.claim_loss)}}</p>
                            </td>
                            <td>
                              <b-form-input v-model="calculation.loss_ratio" type="number" placeholder="Input Loss Ratio"
                                            readonly></b-form-input>
                            </td>
                            <td>
                              <b-form-input v-model="calculation.pure_risk" type="number" placeholder="Input Pure Risk"></b-form-input>
                            </td>
                            <td>
                              <b-button v-if="typeCalculation === 'manual'" @click="insertIndividualRecord(calculation)"
                                variant="warning" class="mb-3 mr-1" block>Submit
                              </b-button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </b-col>
                    </b-row> 
                    <b-row v-else>
                      <b-col md="12">
                        <table class="table table-striped table-warning">
                          <thead>
                          <tr>
                            <th>Year</th>
                            <th>Premium</th>
                            <th>Claim Loss</th>
                            <th>Loss Ratio</th>
                            <th>Pure Risk</th>
                            <th>Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(data, index) in individualRecords" :key="index">
                            <td><strong>{{data.year}}</strong></td>
                            <td>
                              <b-form-input
                                v-if="data.is_edit === true"
                                type="number"
                                id="actual_premium"
                                name="actual_premium"
                                placeholder="Type Number ..."
                                v-model="data.actual_premium"
                                class="border-black"
                              ></b-form-input>
                              <small v-if="data.is_edit === true" class="text-muted">{{formatCurrency(data.actual_premium)}}</small>
                              <span v-else>{{formatCurrency(data.actual_premium)}}</span>
                            </td>
                            <td>
                              <b-form-input
                                v-if="data.is_edit"
                                type="number"
                                id="claim_loss"
                                name="claim_loss"
                                placeholder="Type Number ..."
                                v-model="data.claim_loss"
                              ></b-form-input>
                              <small v-if="data.is_edit === true" class="text-muted">{{formatCurrency(data.claim_loss)}}</small>
                              <span v-else>{{formatCurrency(data.claim_loss)}}</span>
                            </td>
                            <td>{{parseFloat(data.loss_ratio).toFixed(2)}}%</td>
                            <td>
                              <b-form-input
                                v-if="data.is_edit"
                                type="number"
                                id="pure_risk"
                                name="pure_risk"
                                placeholder="Type Number ..."
                                v-model="data.pure_risk"
                              ></b-form-input>
                              <span v-else>{{parseFloat(data.pure_risk).toFixed(2)}}%</span>
                            </td>
                            <!-- <td>
                              <b-button v-if="data.manual" size="sm"
                                        variant="danger" class="mb-3 mr-1"><i class="ri-delete-bin-line"></i></b-button>
                            </td> -->
                            <td>
                              <b-button
                                variant="success"
                                size="sm"
                                class="mr-2"
                                @click="changeEditIndividualRecord(data, index)"
                              >
                              <i class="fa fa-edit" v-if="!data.is_edit"></i>
                              <i class="fa fa-check" v-else></i>
                              </b-button>
                              <b-button
                                variant="danger"
                                size="sm"
                                @click="deleteIndividualRecord(index)" 
                              >
                                <i class="fa fa-trash" ></i>
                              </b-button>
                            </td>
                          </tr>
                          <tr>
                            <td><strong>{{initialIndividualRecords.year}}</strong></td>
                            <td>{{formatCurrency(initialIndividualRecords.actual_premium)}}</td>
                            <td>{{formatCurrency(initialIndividualRecords.claim_loss)}}</td>
                            <td>{{parseFloat(initialIndividualRecords.loss_ratio).toFixed(2)}}%</td>
                            <td>{{isNaN(initialIndividualRecords.pure_risk) ? 0 : Number(initialIndividualRecords.pure_risk).toFixed(2)}}%</td>
                          </tr>
                          <tr class="bg-white" v-if="typeCalculation === 'manual'">
                            <td>
                              <v-select v-model="calculation.year" label="text" :options="yearCalculationOption"
                                        placeholder="Select Year" :clearable="false"></v-select>
                            </td>
                            <td>
                              <b-form-input v-model="calculation.actual_premium" @input="setCalculationLossRatio"
                                            type="number" placeholder="Input Premium"></b-form-input>
                              <p>{{formatCurrency(calculation.actual_premium ?? 0)}}</p>
                            </td>
                            <td>
                              <b-form-input v-model="calculation.claim_loss" @input="setCalculationLossRatio"
                                            type="number" placeholder="Input Claim Loss"></b-form-input>
                              <p>{{formatCurrency(calculation.claim_loss)}}</p>
                            </td>
                            <td>
                              <b-form-input v-model="calculation.loss_ratio" type="number" placeholder="Input Loss Ratio"
                                            readonly></b-form-input>
                            </td>
                            <td>
                              <b-form-input v-model="calculation.pure_risk" type="number" placeholder="Input Pure Risk"></b-form-input>
                            </td>
                            <td>
                              <b-button v-if="typeCalculation === 'manual'" @click="insertIndividualRecord(calculation)"
                                variant="warning" class="mb-3 mr-1" block>Submit
                              </b-button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </b-col>
                    </b-row>
                  </b-col>
                  <!-- Accumulative Record -->
                  <b-col md="12">
                    <b-alert :show="true" variant="secondary">
                      <div class="iq-alert-text text-center">LOADING POINT CALCULATION</div>
                    </b-alert>
                    <b-row>
                      <b-col md="12">
                        <table class="table table-striped table-success">
                          <thead>
                          <tr>
                            <th rowspan="2" style="vertical-align: middle;">Year</th>
                            <th rowspan="2" style="vertical-align: middle;">Acc.Premium</th>
                            <th rowspan="2" style="vertical-align: middle;">Acc.Loss</th>
                            <th rowspan="2" style="vertical-align: middle;">Acc.Loss Ratio</th>
                            <th rowspan="2" style="vertical-align: middle;">Pure Risk</th>
                            <th rowspan="2" style="vertical-align: middle;">Insurer Recovery (Month)</th>
                            <th rowspan="2" style="vertical-align: middle;">Insurer Recovery</th>
                            <th colspan="2" class="text-center">Crosscheck</th>
                          </tr>
                          <tr>
                            <th>Kerugian/Keuntungan yang diterima Asuransi</th>
                            <th>%</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(data, index) in accumulativeRecords" :key="index">
                            <td><strong>{{data.year}}</strong></td>
                            <td>{{formatCurrency(data.acc_premium)}}</td>
                            <td>{{formatCurrency(data.acc_loss)}}</td>
                            <td>{{parseFloat(data.acc_loss_ratio).toFixed(2)}}%</td>
                            <td>{{parseFloat(data.pure_risk).toFixed(2)}}%</td>
                            <td>{{parseFloat(data.insurer_rec_month).toFixed(3)}}</td>
                            <td>{{parseFloat(data.insurer_recovery).toFixed(3)}}%</td>
                            <td>{{formatCurrency(data.keuntungan_kerugian)}}</td>
                            <td>{{parseFloat(data.insurer_recovery).toFixed(3)}}%</td>
                          </tr>
                          </tbody>
                        </table>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- End Step 3 -->
        <!-- Step 4 -->
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-capitalize text-black"><strong>Step 4 - Final Calculation</strong>
              </h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right"
                v-b-toggle.collapse-forecast-step-4
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-window-minimize" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse id="collapse-forecast-step-4" class="mb-2" :visible="!isPreview">
                <b-row>
                  <b-col md="12" class="mb-5">
                    <b-alert :show="true" variant="secondary">
                      <div class="iq-alert-text text-center">forecasting premium by simple linear calculation</div>
                    </b-alert>
                    <b-row>
                      <b-col md="2">{{ textReducePremium }}<br /><small class="text-success">Premium (Current) – Forecast</small>
                      </b-col>
                      <b-col md="4">
                        <h4>{{formatCurrency(reducePremium)}}</h4>
                      </b-col>
                      <b-col md="3">{{ textReducePremiumPercent }}
                        <br /><small class="text-success">{{ textReducePremium }} / Premium (Current) x 100%</small>
                      </b-col>
                      <b-col md="3">
                        <h4>{{Number(reducePremiumPercent).toFixed(3)}}%</h4>
                      </b-col>
                    </b-row>
                    <b-row class="pl-4">
                      <b-col md="12" class="mt-5">
                        <b-alert :show="true" variant="info">
                          <div class="iq-alert-text text-center">PESIMISTIC CALCULATION</div>
                        </b-alert>
                        <b-row>
                          <b-col md="12">
                            <table class="table table-striped">
                              <thead>
                              <tr>
                                <th width="25%">Select Company</th>
                                <th>Current Rate</th>
                                <th>{{textReducePremium === 'Reduce Premium' ? 'Decrease' : 'Increase'}}<br /></th>
                                <th><strong>Result</strong></th>
                                <th>Action</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="(data, i) in pesimisticCalculation" :key="i">
                                <td>
                                  <v-select v-model="data.companies" label="company" :options="companies"
                                            placeholder="Company Name" :reduce="(c) => c.id" :multiple="true"
                                            @input="setCompanyOptions"></v-select>
                                </td>
                                <td>
                                  <div>
                                    <template v-for="(item, index) in pesimisticRateOptions">
                                      <b-form-radio v-model="data.pesimisticRate" class="custom-radio-color-checked" inline
                                                    :color="item.color" :name="`pesimistic-rate-options-${i}-${index}`" :key="`${i}-${index}`" :id="`${i}-${index}`" :value="item.value"
                                                    :disabled="item.disabled" @change="changeCurrentRate(i)"><strong>
                                        <h5>{{ item.label }}</h5>
                                      </strong></b-form-radio>
                                    </template>
                                  </div>
                                  <div>
                                    <b-input-group size="md" append="%">
                                      <b-form-input v-model="data.current_rate" type="number" style="max-width: 50%;" @input="setDecreaseAndResult(data.current_rate, i)"></b-form-input>
                                    </b-input-group>
                                  </div>
                                </td>
                                <td><small>{{Number(data.decrease).toFixed(3)}}%</small></td>
                                <td><small>{{Number(data.result).toFixed(3)}}%</small></td>
                                <td>
                                  <b-button size="sm" variant="danger" class="mb-3 mr-1" @click="pesimisticCalculation.splice(i, 1)"><i
                                    class="ri-delete-bin-line"></i></b-button>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </b-col>
                          <b-col md="12">
                            <b-button @click="addPesimisticCalculation" variant="info" class="mb-3 mr-1" block><i class="ri-add-line"></i> Add Data</b-button>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col md="12" class="mt-4">
                        <b-alert :show="true" variant="success">
                          <div class="iq-alert-text text-center">OPTIMISTIC CALCULATION</div>
                        </b-alert>
                        <b-row>
                          <b-col md="12">
                            <table class="table table-striped">
                              <thead>
                              <tr>
                                <th width="25%">Company</th>
                                <th>Pesimistic Rate</th>
                                <th>Additional %</th>
                                <th><strong>Result</strong></th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="(data, index) in optimisticCalculation" :key="index">
                                <td>
                                  <b-badge variant="warning" v-for="company in data.companies" :key="company.id">{{company.name}}</b-badge> &nbsp;
                                </td>
                                <td><i><strong>{{ Number(data.rate).toFixed(3) }}%</strong></i></td>
                                <td><i><strong>{{ Number(data.additional).toFixed(3) }}%</strong></i></td>
                                <td><i><strong>{{ Number(data.result).toFixed(3) }}%</strong></i></td>
                              </tr>
                              </tbody>
                            </table>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col md="12" class="mt-5">
                        <table class="table table-bordered table-striped shadow">
                          <thead>
                          <tr>
                            <th width="50%" colspan="2" rowspan="2" style="vertical-align: middle;">FINAL RESULT </th>
                            <th colspan="3">FINAL RATE FOR RENEWAL </th>
                          </tr>
                          <tr>
                            <td class="text-center"><strong>HM</strong></td>
                            <td class="text-center"><strong>IV</strong></td>
                            <td class="text-center"><strong>WAR</strong></td>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(data, index) in optimisticCalculation" :key="index">
                            <td>
                              <b-badge variant="warning" v-for="company in data.companies" :key="company.id">{{company.name}}</b-badge> &nbsp;
                            </td>
                            <td><strong>{{ Number(data.result).toFixed(3) }}%</strong></td>
                            <td><strong>{{ Number(data.result).toFixed(3) }}%</strong></td>
                            <td>
                              <b-input-group size="md" append="%">
                                <b-form-input v-model="data.iv" @input="handleChangePesimisticCalculationIv(index, $event)" type="number" width="50%"></b-form-input>
                              </b-input-group>
                            </td>

                            <td>
                              <b-input-group size="md" append="%">
                                <b-form-input v-model="data.war" @input="handleChangePesimisticCalculationWar(index, $event)" type="number" width="50%"></b-form-input>
                              </b-input-group>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </b-col>
                      <b-col md="12" class="mt-5">
                        <b-alert :show="true" variant="danger">
                          <div class="iq-alert-text text-center">Kurs Manual Input</div>
                        </b-alert>
                        <b-row>
                          <b-col md="12">
                            <div class="accordion" role="tablist">
                              <b-card no-body class="mb-5">
                                <b-card-header variant="danger" header-tag="header" class="p-1" role="tab" v-b-toggle.kurs>
                                  <h4 style="cursor: pointer" class="p-3">
                                    Select Your Kurs Data
                                  </h4>
                                </b-card-header>
                                <b-collapse id="kurs" :visible="true" role="tabpanel">
                                  <b-card-body>
                                    <b-card-text>
                                      <div class="table-responsive">
                                        <table class="table mb-0 table-striped table-hover">
                                          <thead class="thead-dark text-center">
                                          <tr>
                                            <th scope="col">#</th>
                                            <th v-for="currencyMaster in currencies" v-if="currencyMaster.alias !== 'IDR'" :key="currencyMaster.id" scope="col">{{currencyMaster.alias}}</th>
                                            <th scope="col">Update Kurs</th>
                                            <th scope="col">Action</th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          <tr v-for="(currency, index) in currenciesMapping" :key="index" @click="currencyMappingId = currency.id">
                                            <td class="text-center">
                                              <b-form-radio v-model="currencyMappingId" name="some-radios" :value="currency.id"></b-form-radio>
                                            </td>
                                            <td v-for="currencyMaster in currencies" v-if="currencyMaster.alias !== 'IDR'" :key="currencyMaster.id">
                                              <h5>IDR {{currency.childs.find(child => child.currency_id === currencyMaster.id) ? currency.childs.find(child => child.currency_id === currencyMaster.id).value : '-'}},-</h5>
                                            </td>
                                            <td>
                                              {{ currency.date }}
                                            </td>
                                            <td>
                                              <!--                                    <b-button-group>-->
                                              <!--                                      <b-button variant="info" size="sm"><i class="fa fa-edit"></i> </b-button>-->
                                              <!--                                      <b-button variant="danger" size="sm"><i class="fa fa-trash"></i></b-button>-->
                                              <!--                                    </b-button-group>-->
                                            </td>
                                          </tr>
                                          <tr>
                                            <td class="text-center">
                                            </td>
                                            <td v-for="child in formCurrency.contents" scope="col">
                                              <b-input-group size="md" :prepend="child.currency">
                                                <b-form-input type="number" v-model="child.value" style="max-width: 50%;"></b-form-input>
                                              </b-input-group>
                                            </td>
                                            <td>
                                              <date-picker v-model="formCurrency.date" type="date" value-type="YYYY-MM-DD" placeholder="Select date"></date-picker>
                                            </td>
                                            <td>
                                              <b-button @click="handleSaveCurrencyMapping" :disabled="formCurrency.date === '' || formCurrency.contents.filter(content => content.value === '').length > 0" variant="primary"><i class="fa fa-save"></i> Submit </b-button>
                                            </td>
                                          </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </b-card-text>
                                  </b-card-body>
                                </b-collapse>
                              </b-card>
                            </div>
                          </b-col>
                          <b-col md="12">
                            <table class="table table-bordered table-striped shadow">
                              <thead>
                              <tr>
                                <th>Currency <small>!= IDR</small></th>
                                <th>Input Kurs to IDR</th>
                              </tr>
                              </thead>
                              <tbody>
                              <template v-for="(currency, index) in currenciesMapping.filter(currency => currency.id === currencyMappingId)">
                                <tr v-for="(type, indexType) in currency.childs" :key="indexType">
                                  <td><strong>{{ type.currency.name }}</strong></td>
                                  <td>
                                    <h5><strong>IDR {{ type.value }}-</strong></h5>
                                  </td>
                                </tr>
                              </template>
                              </tbody>
                            </table>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="12"></b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <!-- End Step 4 -->
        <!-- Step 5 -->
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-capitalize text-black"><strong>Step 5 - Result Budget Periode <strong>{{checkPayload.year}}</strong></strong></h4>
            </template>
            <template v-slot:headerAction>
              <strong>All Data in TSI (price-fleet) Group by Company & Vehicle Type</strong>

              <a
                class="text-primary float-right"
                v-b-toggle.collapse-forecast-step-5
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-window-minimize" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse id="collapse-forecast-step-5" class="mb-2" :visible="!isPreview">
                <b-row>
                  <b-col md="12" v-for="(budget, budgetIndex) in budgets" :key="budgetIndex">
                    <b-alert :show="true" variant="secondary">
                      <div class="iq-alert-text text-center">{{ budget.company }}</div>
                    </b-alert>
                    <div class="table-responsive">
                      <table class="table table-bordered table-striped shadow">
                        <thead>
                        <tr class="text-bold text-center">
                          <td rowspan="2"><strong>Vehicle Type In TSI</strong></td>
                          <td colspan="6" style="vertical-align: middle"><strong>Details</strong></td>
                        </tr>
                        <tr>
                          <td><strong>TSI <small>(Total in each vehicle type)</small></strong></td>
                          <td><strong>HM (75%)</strong></td>
                          <td><strong>IV (25%)</strong></td>
                          <td><strong>WAR</strong></td>
                          <td><strong>HM Rate <small>{HM 75% x Final Rate (match company)</small></strong></td>
                          <td><strong>IV Rate <small>{IV 25% x Final Rate (match company)</small></strong></td>
                          <td><strong>WAR Rate</strong></td>
                          <td><strong>Sub Total</strong></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(type, typeIndex) in budget.types" :key="typeIndex">
                          <td>{{ type.name }}</td>
                          <td>
                            <!--                            <template v-for="(currency, index) in currenciesMapping.filter(currency => currency.id === currencyMappingId)">-->
                            <!--                              <template v-for="(c, indexType) in currency.childs">-->
                            <!--                                {{ c.currency.alias }}-->
                            <!--                                <template v-if="indexType !== currency.childs.length - 1">-->
                            <!--                                  /-->
                            <!--                                </template>-->
                            <!--                              </template>-->
                            <!--                            </template>-->
                            {{formatCurrency(type.data.tsi)}}
                          </td>
                          <td>
                            {{formatCurrency(type.data.hm)}}
                          </td>
                          <td>
                            {{formatCurrency(type.data.iv)}}
                          </td>
                          <td>
                            {{formatCurrency(type.data.war)}}
                          </td>
                          <td>
                            {{formatCurrency(type.data.hm_rate = budgetHmRateCalculation(budgetIndex, typeIndex, budget.id))}}
                          </td>
                          <td>
                            {{formatCurrency(type.data.iv_rate = budgetIvRateCalculation(budgetIndex, typeIndex, budget.id))}}
                          </td>
                          <td>
                            {{formatCurrency(type.data.war_rate = budgetWarRateCalculation(budgetIndex, typeIndex, budget.id))}}
                          </td>
                          <td>
                            {{formatCurrency(type.data.sub_total = Number(type.data.hm_rate) + Number(type.data.iv_rate) + Number(type.data.war_rate))}}
                          </td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                          <td class="text-right" colspan="8"><strong>Grand Total (IDR)</strong></td>
                          <td>{{formatCurrency(budget.grand_total = budgetSubtotalSum(budget.id))}}</td>
                        </tr>
                        </tfoot>
                      </table>
                    </div>
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
            <template v-slot:footer>
              <b-row class="bg-success text-white p-3">
                <b-col md="4">
                  TOTAL SELURUH PREMIUM PERIODE BUDGET TAHUN <strong>{{checkPayload.year}}</strong>
                  <br /><small>from grand total each company</small>
                </b-col>
                <b-col md="3">
                  <h3 class="text-white">{{formatCurrency(budgetGrandTotalSum)}}</h3>
                </b-col>
                <b-col md="3">DIFFERENCE <br /> <small>(-(100%-(Total Seluruh Budget/Premium / Forecast Tanpa
                    Beban)))</small></b-col>
                <b-col md="2">
                  <h3 class="text-white">{{forecastPremium > 0 ? (100-(budgetGrandTotalSum / forecastPremium)).toFixed(2) : 0}}%</h3>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
        <!-- End Step 5 -->
        <!-- Sidebar View Show Budget -->
        <b-sidebar id="show-budget" title="LIST OF BUDGET" width="1500px" backdrop-variant="dark" right backdrop shadow>
          <b-row class="pt-2 pl-3 pr-3 pb-2">
          </b-row>
        </b-sidebar>
        <!-- End Sidebar View Show Budget -->
      </template>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { companiesActions, fleetsActions, miActions } from '@src/Utils/helper'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'BlankPage',
  components: { },
  async mounted () {
    xray.index()
    await this.getVehicleTypeData()
    await this.fetchCompanies()
    this.setYearOption()
    await this.handleFetchCurrenciesMapping()
    await this.handleFetchCurrencies()

    if (this.id) {
      await this.handleFetchForecast()

      if (this.$route.query.preview === 'true' || this.$route.query.preview === true) {
        this.isPreview = true
      }
    }
  },
  watch: {
    isPreview (value) {
      const inputs = document.getElementById('forecast-form').querySelectorAll('input,select,textarea')
      const buttons = document.getElementById('forecast-form').querySelectorAll('button')
      if (inputs) {
        for (const input of inputs) {
          input.classList.remove('transparent-disabled')
          input.removeAttribute('disabled')
        }

        if (typeof value === 'boolean') {
          if (value) {
            for (const input of inputs) {
              input.classList.add('transparent-disabled')
              input.setAttribute('disabled', 'disabled')
            }
          }
        }
      }
      if (buttons) {
        for (const button of buttons) {
          button.classList.remove('d-none')
        }

        if (typeof value === 'boolean') {
          if (value) {
            for (const button of buttons) {
              if (button.id !== 'goto-forecast-edit') {
                button.classList.add('d-none')
              }
            }
          }
        }
      }
    }
  },
  data () {
    return {
      isPreview: false,
      saving: false,
      selectYear: new Date(Date.now()).getFullYear(),
      vehicleTypeDetail: '',
      status: 'open',
      selectedKurs: '',
      typeFleet: [],
      companies: [],
      currencies: [],
      budgets: [],
      currenciesMapping: [],
      currencyMappingId: '',
      companiesSelected: [],
      actualPremium: 0,
      rateHm: 0,
      formCurrency: {
        saving: false,
        'date': '',
        'contents': []
      },
      companyOptions: [],
      currentHm: 0,
      componentStruct: {
        editMode: false,
        params: {
          search: '',
          vehicleTypeId: 1
        },
        data: [],
        form: {
          parentId: null,
          componentName: '',
          description: '',
          vehicleTypeId: 1,
          haveEquipment: true
        },
        detailData: null
      },
      checkPayload: {
        year: new Date(Date.now()).getFullYear(),
        group_id: 1
      },
      yearOption: [],
      yearCalculationOption: [],
      calculation: {
        year: null,
        premium: '',
        claim_loss: '',
        pure_risk: 0,
        loss_ration: 0
      },
      statusOption: [
        { value: 'open', text: 'open' },
        { value: 'close', text: 'close' }
      ],
      pureRiskId: null,
      pureRiskItems: [],
      individualRecordsBefore: [],
      individualRecords: [],
      individualRecordsCurrentYear: null,
      typeCalculation: 'manual',
      calculationId: null,
      finalCalculationId: null,
      resultBudgetId: null,
      typeOfCalculation: [
        {
          label: 'From Data Before',
          color: 'success',
          value: 'data'
        },
        {
          label: 'Manual Input Years (Free Text)',
          color: 'info',
          value: 'manual'
        }
      ],
      pesimisticRate: 'manual',
      pesimisticRateOptions: [
        {
          label: 'Manual (Free Text)',
          color: 'success',
          value: 'manual'
        },
        {
          label: 'From Rate HM (Current)',
          color: 'info',
          value: 'tsi_hm'
        }
      ],
      pesimisticCalculation: [
        {
          companies: [],
          pesimisticRate: 'manual',
          current_rate: 0,
          decrease: 0,
          result: 0,
          iv: 0,
          war: 0
        }
      ],
      optimisticCalculationList: [],
      finalCalculationLists: [],
      accumulativeCalculationLists: [],
      budgetLists: [],
      finalResult: [],
      forecastAvailable: false,
      outstanding: [],
      outstandingYearOption: [],
      initialCalculationPayload: {
        decrement: 1
      },
      futureClaim: {
        est_inc_loss_ratio: '',
        condition: '',
        amount_of_years: 0,
        estimate_loss_future: 0,

        est_inc_loss_ratio_id: '',
        condition_id: '',
        amount_of_years_id: '',
        estimate_loss_future_id: ''
      },
      textReducePremium: 'Reduce Premium',
      textReducePremiumPercent: 'Reduce Premium (%)'
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    },
    vehicleTypes () {
      let types = []
      if (this.typeFleet.length && this.checkPayload.group_id) {
        types = this.typeFleet.find(
          data => data.code === this.checkPayload.group_id
        ).types
      }
      if (types.length) {
        return types.map(data => data.name).join(', ')
      }
      return ''
    },
    pureRiskItemTotal () {
      let total = 0
      if (this.pureRiskItems.length) total = this.pureRiskItems.reduce((t, num) => t + parseFloat(num.value), 0)
      if (Number.isNaN(total)) return 0
      return total
    },
    pureRiskItemCost () {
      return 100 - parseFloat(this.pureRiskItemTotal ?? 0).toFixed(2)
    },
    selectedYear () {
      return this.checkPayload.year
    },
    initialClaimLoss () {
      const additionalThumbRules = this.outstanding.filter(val => val.follow_up_response !== 'settled')
      return additionalThumbRules.reduce((t, data) => t + ((Number(data.cost) * (1 / 100)) + Number(data.cost)), 0)
    },
    initialIndividualRecords () {
      const claimLoss = this.initialClaimLoss + Number(this.futureClaim.estimate_loss_future || 0)
      const lossRatio = this.actualPremium > 0 ? claimLoss / this.actualPremium * 100 : 0
      const obj = {
        year: this.selectedYear,
        actual_premium: this.actualPremium,
        claim_loss: parseFloat(claimLoss ?? 0).toFixed(2),
        loss_ratio: Number.isNaN(lossRatio) ? 0 : parseFloat(lossRatio ?? 0).toFixed(2),
        pure_risk: parseFloat(this.pureRiskItemCost ?? 0).toFixed(2)
      }

      if (this.individualRecordsCurrentYear) {
        obj.id = this.individualRecordsCurrentYear.id
      }

      return obj
    },
    accumulativeRecords () {
      const self = this
      const arr = []
      function sumRecordByField (field, index) {
        return _.sumBy(self.individualRecords.filter((a, i) => i <= index), o => Number(o[field]))
      }
      if(this.typeCalculation === 'manual'){
        if (this.individualRecords.length > 0) {
        this.individualRecords.forEach((data, index) => {
          const accPremium = sumRecordByField('actual_premium', index)
          const accLoss = sumRecordByField('claim_loss', index)
          const accLossRatio = accPremium > 0 ? accLoss / accPremium * 100 : 0
          const insurerRecMonth = (parseFloat(accLossRatio).toFixed(2) / parseFloat(data.pure_risk).toFixed(2)) * 12
          const profitLoss = parseFloat(insurerRecMonth).toFixed(3) > 0 ? (accLoss - accPremium) / parseFloat(insurerRecMonth).toFixed(3) : 0
          const insurerRecovery = accLoss > 0 ? profitLoss / accLoss * 100 : 0

          arr.push({
            year: data.year,
            acc_premium: accPremium,
            acc_loss: accLoss,
            acc_loss_ratio: parseFloat(accLossRatio).toFixed(2),
            pure_risk: parseFloat(data.pure_risk).toFixed(2),
            insurer_rec_month: parseFloat(insurerRecMonth).toFixed(3),
            insurer_recovery: parseFloat(insurerRecovery).toFixed(3),
            keuntungan_kerugian: profitLoss,
            percent: parseFloat(insurerRecovery).toFixed(3)
          })
        })

        const arrayBefore = arr[arr.length - 1]

        if (arrayBefore) {
          const accPremium = arrayBefore.acc_premium + Number(this.initialIndividualRecords.actual_premium)
          const accLoss = arrayBefore.acc_loss + Number(this.initialIndividualRecords.claim_loss)
          const accLossRatio = accPremium > 0 ? accLoss / accPremium * 100 : 0
          const insurerRecMonth = (parseFloat(accLossRatio).toFixed(2)  / parseFloat(this.initialIndividualRecords.pure_risk).toFixed(2)) * 12
          const profitLoss = parseFloat(insurerRecMonth).toFixed(3) > 0 ? (accLoss - accPremium) / parseFloat(insurerRecMonth).toFixed(3) : 0
          const insurerRecovery = accLoss > 0 ? profitLoss / accLoss * 100 : 0

          arr.push({
            year: this.initialIndividualRecords.year,
            acc_premium: accPremium,
            acc_loss: accLoss,
            acc_loss_ratio: parseFloat(accLossRatio).toFixed(2),
            pure_risk: parseFloat(this.initialIndividualRecords.pure_risk).toFixed(2),
            insurer_rec_month: parseFloat(insurerRecMonth).toFixed(3),
            insurer_recovery: parseFloat(insurerRecovery).toFixed(3),
            keuntungan_kerugian: profitLoss,
            percent: parseFloat(insurerRecovery).toFixed(3)
          })
        }
        } else {
          const accPremium = Number(this.initialIndividualRecords.actual_premium)
          const accLoss = Number(this.initialIndividualRecords.claim_loss)
          const accLossRatio = Number(this.initialIndividualRecords.loss_ratio.toFixed(2))
          const insurerRecMonth = (accLossRatio / parseFloat(this.initialIndividualRecords.pure_risk).toFixed(2)) * 12
          const profitLoss = (accLoss - accPremium) / parseFloat(insurerRecMonth).toFixed(3)
          const insurerRecovery = accLoss > 0 ? profitLoss / accLoss : 0
          arr.push({
            year: this.initialIndividualRecords.year,
            acc_premium: accPremium,
            acc_loss: accLoss,
            acc_loss_ratio: parseFloat(accLossRatio).toFixed(2),
            pure_risk: parseFloat(this.initialIndividualRecords.pure_risk).toFixed(2),
            insurer_rec_month:  parseFloat(insurerRecMonth).toFixed(3),
            insurer_recovery: parseFloat(insurerRecovery).toFixed(3),
            keuntungan_kerugian: profitLoss,
            percent: parseFloat(insurerRecovery).toFixed(3)
          })
        }
      } else {
        if (this.individualRecordsBefore.length > 0) {
        this.individualRecordsBefore.forEach((data, index) => {
          const accPremium = sumRecordByField('actual_premium', index)
          const accLoss = sumRecordByField('claim_loss', index)
          const accLossRatio = accPremium > 0 ? accLoss / accPremium * 100 : 0
          const insurerRecMonth = (accLossRatio / Number(data.pure_risk).toFixed(2)) * 12
          const profitLoss = Number(insurerRecMonth).toFixed(3) > 0 ? (accLoss - accPremium) / Number(insurerRecMonth).toFixed(3) : 0
          const insurerRecovery = accLoss > 0 ? profitLoss / accLoss * 100 : 0

          console.log(insurerRecMonth, 'insurerRecMonth') 
          arr.push({
            year: data.year,
            acc_premium: accPremium,
            acc_loss: accLoss,
            acc_loss_ratio: Number(accLossRatio).toFixed(2),
            pure_risk: Number(data.pure_risk).toFixed(2),
            insurer_rec_month: parseFloat(insurerRecMonth).toFixed(3),
            insurer_recovery: Number(insurerRecovery).toFixed(3),
            keuntungan_kerugian: profitLoss,
            percent: Number(insurerRecovery).toFixed(3)
          })
        })

        const arrayBefore = arr[arr.length - 1]

        if (arrayBefore) {
          const accPremium = arrayBefore.acc_premium + Number(this.initialIndividualRecords.actual_premium)
          const accLoss = arrayBefore.acc_loss + Number(this.initialIndividualRecords.claim_loss)
          const accLossRatio = accPremium > 0 ? accLoss / accPremium * 100 : 0
          const insurerRecMonth = (accLossRatio / parseFloat(this.initialIndividualRecords.pure_risk).toFixed(2)) * 12
          const profitLoss = parseFloat(insurerRecMonth).toFixed(3) > 0 ? (accLoss - accPremium) / parseFloat(insurerRecMonth).toFixed(3) : 0
          const insurerRecovery = accLoss > 0 ? profitLoss / accLoss * 100 : 0
          arr.push({
            year: this.initialIndividualRecords.year,
            acc_premium: accPremium,
            acc_loss: accLoss,
            acc_loss_ratio: parseFloat(accLossRatio).toFixed(2),
            pure_risk: parseFloat(this.initialIndividualRecords.pure_risk).toFixed(2),
            insurer_rec_month: parseFloat(insurerRecMonth).toFixed(3),
            insurer_recovery: parseFloat(insurerRecovery).toFixed(3),
            keuntungan_kerugian: profitLoss,
            percent: parseFloat(insurerRecovery).toFixed(3)
          })
        }
        } else {
          const accPremium = Number(this.initialIndividualRecords.actual_premium)
          const accLoss = Number(this.initialIndividualRecords.claim_loss)
          const accLossRatio = Number(this.initialIndividualRecords.loss_ratio).toFixed(2)
          const insurerRecMonth = (accLossRatio / parseFloat(this.initialIndividualRecords.pure_risk).toFixed(2)) * 12
          const profitLoss = (accLoss - accPremium) / insurerRecMonth
          const insurerRecovery = accLoss > 0 ? profitLoss / accLoss : 0
          arr.push({
            year: this.initialIndividualRecords.year,
            acc_premium: accPremium,
            acc_loss: accLoss,
            acc_loss_ratio: parseFloat(accLossRatio).toFixed(2),
            pure_risk: parseFloat(this.initialIndividualRecords.pure_risk).toFixed(2),
            insurer_rec_month: parseFloat(insurerRecMonth).toFixed(3),
            insurer_recovery: parseFloat(insurerRecovery).toFixed(3),
            keuntungan_kerugian: profitLoss,
            percent: parseFloat(insurerRecovery).toFixed(3)
          })
        }
      }
    
      return arr
    },
    loadingPointInsurer () {
      return parseFloat(this.accumulativeRecords.find(val => val.year === this.initialIndividualRecords.year).insurer_recovery)
    },
    additionalLoadingPointInsurer () {
      if (this.initialIndividualRecords.claim_loss > 0 && this.initialIndividualRecords.claim_loss <= 1099000000) {
        return 2.5
      } else if (this.initialIndividualRecords.claim_loss > 1099000000 && this.initialIndividualRecords.claim_loss <= 2099999999) {
        return 5
      } else if (this.initialIndividualRecords.claim_loss > 2099999999 && this.initialIndividualRecords.claim_loss <= 3099999999) {
        return 7.5
      } else if (this.initialIndividualRecords.claim_loss > 3099999999 && this.initialIndividualRecords.claim_loss <= 4099999999) {
        return 10
      } else if (this.initialIndividualRecords.claim_loss > 4099999999 && this.initialIndividualRecords.claim_loss <= 5099999999) {
        return 12.5
      } else if (this.initialIndividualRecords.claim_loss > 5099999999 && this.initialIndividualRecords.claim_loss <= 6099999999) {
        return 15.0
      } else if (this.initialIndividualRecords.claim_loss > 6099999999 && this.initialIndividualRecords.claim_loss <= 7099999999) {
        return 17.5
      } else if (this.initialIndividualRecords.claim_loss > 7099999999 && this.initialIndividualRecords.claim_loss <= 8099999999) {
        return 20
      } else if (this.initialIndividualRecords.claim_loss > 8099999999 && this.initialIndividualRecords.claim_loss <= 9099999999) {
        return 22.5
      } else if (this.initialIndividualRecords.claim_loss > 9099999999 && this.initialIndividualRecords.claim_loss <= 10099999999) {
        return 25
      } else if (this.initialIndividualRecords.claim_loss > 10099999999 && this.initialIndividualRecords.claim_loss <= 11000000000) {
        return 27.5
      } else if (this.initialIndividualRecords.claim_loss > 11000000000) {
        return 30
      }else{
        return 0
      }
    },
    finalLoadingPointInsurer () {
      return this.loadingPointInsurer + this.additionalLoadingPointInsurer
    },
    forecastPremium () {
      const x = this.individualRecords.map(val => Number(val.year, 10))
      const y = this.individualRecords.map(val => parseFloat(val.actual_premium))
      x.push(this.initialIndividualRecords.year)
      y.push(Number(this.initialIndividualRecords.actual_premium))
      const nextYear = this.initialIndividualRecords.year + 1
      return this.linearRegression(nextYear, y, x)
    },
    reducePremium () {
      const forecastPremiumAmount = Number.isNaN(this.forecastPremium) ? 0 : Number(this.forecastPremium)
      const actualPremium = Number.isNaN(this.initialIndividualRecords.actual_premium) ? 0 : Number(this.initialIndividualRecords.actual_premium)
      this.textReducePremium = 'Reduce Premium'
      this.textReducePremiumPercent = 'Reduce Premium (%)'

      if (Number(forecastPremiumAmount) > Number(actualPremium)) {
        this.textReducePremium = 'Increase Premium'
        this.textReducePremiumPercent = 'Increase Premium (%)'
        return Number(forecastPremiumAmount) - Number(actualPremium)
      }

      return actualPremium - forecastPremiumAmount
    },
    reducePremiumPercent () {
      const reducePremium = Number.isNaN(this.reducePremium) ? 0 : Number(this.reducePremium)
      const actualPremium = Number.isNaN(this.initialIndividualRecords.actual_premium) ? 0 : Number(this.initialIndividualRecords.actual_premium)

      return actualPremium > 0 ? (reducePremium / actualPremium) * 100 : 0
    },
    optimisticCalculation () {
      return this.pesimisticCalculation.map((val, index) => {
        const getId = this.optimisticCalculationList[index] ? this.optimisticCalculationList[index].id : null

        const additional = (Number(val.result) * Number(this.finalLoadingPointInsurer)).toFixed(3) / 100
        const rate = Number(val.result)
        const result = additional + rate

        const obj = {
          companies: val.companies.map(company => ({
            id: company,
            name: this.companies.find(data => data.id === company).company
          })),
          rate,
          additional,
          result: parseFloat(result).toFixed(3),
          iv: val.iv,
          war: val.war
        }

        if (getId) {
          obj.id = getId
        }

        return obj
      })
    },
    budgetGrandTotalSum () {
      return _.sumBy(this.budgets, o => o.grand_total)
    }
  },
  methods: {
    ...fleetsActions,
    ...companiesActions,
    ...miActions,
    changeEditIndividualRecord(data, index){
      this.individualRecords[index].is_edit = !this.individualRecords[index].is_edit

      if(this.individualRecords[index].is_edit === false){
        let newLossRatio = (this.individualRecords[index].claim_loss / this.individualRecords[index].actual_premium) * 100
        this.individualRecords[index].loss_ratio = parseFloat(newLossRatio).toFixed(2)
      }
    },
    async fetchForecastSuggestInitialCalculaiton () {

      let payload = {
        group_id:this.checkPayload.group_id,
        year:this.checkPayload.year,
      }

      const res = await this.getForecastSuggestInitialCalculaiton({data:payload})
    
      if (res.status === 'success' && res.data && res.data.length) {
        res.data.forEach(val => {
          this.individualRecords.push({
            id: null,
            year: val.year,
            actual_premium: val.premium,
            claim_loss: val.claim_loss,
            pure_risk: parseFloat(val.pure_risk).toFixed(2) || 0,
            loss_ratio: parseFloat(val.loss_ratio).toFixed(2),
            is_edit:false
          })
        })
      } else {
        this.$swal(
          `Oops!`,
          res?.data,
          'error'
        )
      }
    }, 
    async getVehicleTypeData () {
      const res = await this.getFleetTypeGroups()

      if (res.status === 'success' && res.data && res.data.length) {
        res.data.forEach(element => {
          this.typeFleet.push({
            name: element.name,
            code: element.id,
            types: element.types
          })
        })
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async handleFetchCurrenciesMapping () {
      const { data } = await this.getCurrencyMapping()
      this.currenciesMapping = data
    },
    async handleFetchCurrencies () {
      const { data } = await this.getCurrencies()
      this.currencies = data
      this.formCurrency.contents = this.currencies.filter(child => child.alias !== 'IDR').map(child => {
        return {
          currency: child.alias,
          id: child.id,
          value: ''
        }
      })
    },
    async handleSaveCurrencyMapping () {
      this.formCurrency.saving = true
      const payload = { ...this.formCurrency }

      const { status } = await this.createCurrencyMapping(payload)

      if (status === 422) {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        return
      }

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      this.handleFetchCurrenciesMapping()
      this.handleResetCurrencyForm()
      this.formCurrency.saving = false
    },
    async fetchCompanies () {
      let res = await this.getCompanies()
      this.companies = res.data
      this.companyOptions = res.data
    },
    async getPureRiskItems () {
      this.pureRiskItems = []
      let res = await this.getMasterPureRiskItem()

      if (res.status === 'success' && res.data && res.data.length > 0) {
        res.data.forEach(element => {
          if (element.active) {
            this.pureRiskItems.push({
              risk_detail_id: null,
              id: element.id,
              name: element.name,
              value: null
            })
          }
        })
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async getOutstanding (year) {
      let res = await this.getOutstandingForecast(year)

      if (res.status === 'success' && res.data) {
        this.outstanding = res.data.map(val => {
          val.cost = val.cost || 0
          val.net_claim_loss = val.net_claim_loss || 0
          val.coverage_date_range = [
            val.coverage_period ? moment(val.coverage_period.toString()).year().toString() : moment().year().toString(),
            val.coverage_period_to ? moment(val.coverage_period_to.toString()).year().toString() : moment().year().toString()
          ]
          val.summary = {
            est_settled_date: val?.summary?.est_settled_date ?? null,
            act_settled_date: val?.summary?.act_settled_date ?? null
          }
  
          return {
            form_free_text: '',
            ...val,
            premiumFromBudget: {
              activeLabel: 'Yes',
              inactiveLabel: 'No',
              label: '',
              class: 'success',
              checked: false
            }
          }
        })
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    addOutstanding () {
      this.outstanding.push({
        id: '',
        form_free_text: '',
        cost: null,
        summary: {
          est_settled_date: null,
          act_settled_date: null
        },
        follow_up_response: null,
        net_claim_loss: null,
        premiumFromBudget: {
          activeLabel: 'Yes',
          inactiveLabel: 'No',
          label: '',
          class: 'success',
          checked: true
        }
      })
    },
    deleteOutstanding (index) {
      this.outstanding.splice(index, 1)
    },
    async getInitialCalculation () {
      this.individualRecordsBefore = []
      const payload = {
        year: this.selectedYear,
        group_id: this.checkPayload.group_id,
        decrement: this.initialCalculationPayload.decrement
      }
      let res = await this.getInitialCalculationForecast(payload)

      if (res.status === 'success' && res.data) {
        res.data.forEach(val => {
          this.individualRecordsBefore.push({
            id: null,
            year: val.year,
            actual_premium: val.actual_premium,
            claim_loss: val.claim_loss,
            pure_risk: val.pure_risk_cost || 0,
            loss_ratio: parseFloat(val.loss_ratio).toFixed(2),
            is_edit:false
          })
        })
      }
    },
    handleResetCurrencyForm () {
      this.formCurrency.saving = false
      this.formCurrency.date = ''
    },
    handleResetForm () {
      this.handleResetCurrencyForm()
      this.pesimisticCalculation = [
        {
          companies: [],
          pesimisticRate: 'manual',
          current_rate: 0,
          decrease: 0,
          result: 0,
          iv: 0,
          war: 0
        }
      ]
      this.futureClaim = {
        est_inc_loss_ratio: '',
        condition: '',
        amount_of_years: 0,
        estimate_loss_future: 0,

        est_inc_loss_ratio_id: '',
        condition_id: '',
        amount_of_years_id: '',
        estimate_loss_future_id: ''
      }
      this.calculation = {
        year: null,
        premium: '',
        claim_loss: '',
        pure_risk: 0,
        loss_ration: 0
      }
      this.actualPremium = 0
      this.currentHm = 0
      this.getPureRiskItems()
    },
    handleChangePesimisticCalculationIv (index, event) {
      if (this.pesimisticCalculation[index]) {
        this.pesimisticCalculation[index].iv = Number(event)
        console.log('is iv connected')
      }
    },
    handleChangePesimisticCalculationWar (index, event) {
      if (this.pesimisticCalculation[index]) {
        this.pesimisticCalculation[index].war = Number(event)
        console.log('is war connected')
      }
    },
    async checkForecast (payload) {
      this.handleResetForm()
      let { status } = await this.getCheckForecast(payload)
      if (status) {
        this.setOutstandingYearOption()
        await this.getOutstanding(payload)
        this.individualRecords = []
        await this.getInitialCalculation()
        await this.fetchForecastSuggestInitialCalculaiton()
        const length = this.individualRecords.length
        if (length) {
          this.setOutstandingYearOption(this.individualRecords[length - 1].year)
        }
      } else {
        this.$swal(
          `Oops!`,
          'Data Sudah Terisi Sebelumnya',
          'error'
        )
      }
      this.forecastAvailable = status
    },
    async handleFetchForecast () {
      this.handleResetForm()
      const { status, data } = await this.getForecastById({
        id: this.id
      })
      if (status) {
        this.checkPayload.year = data.year
        this.checkPayload.group_id = data.vehicle_type_group_id
        this.forecastAvailable = true
        this.actualPremium = data.actual_premium
        this.rateHm = parseFloat(data.rate_hm).toFixed(3)
        this.currentHm = parseFloat(data.rate_hm).toFixed(3)

        if (data.risks[0]) {
          this.pureRiskId = data.risks[0].id
          data.risks[0].detail.forEach(risk => {
            if (risk.item) {
              const detailRisk = this.pureRiskItems.find(array => array.id === risk.item.id)
              if (detailRisk) {
                detailRisk.value = risk.percentage
                detailRisk.risk_detail_id = risk.id
              }
            }
          })
        }

        this.outstanding = []

        if (data.outstandings && data.outstandings.length > 0) {
          this.outstanding = data.outstandings.map(val => {
            val.cost = val.estimated_cost || 0
            val.net_claim_loss = val.nett_lost || 0
            val.follow_up_response = val.status
            val.coverage_date_range = [
              val.period_from ? moment(val.period_from.toString()).year().toString() : moment().year().toString(),
              val.period_to ? moment(val.period_to.toString()).year().toString() : moment().year().toString()
            ]
            val.summary = {
              est_settled_date: val?.estimated_closed_file,
              act_settled_date: val?.estimated_closed_file
            }
            return {
              ...val,
              premiumFromBudget: {
                activeLabel: 'Yes',
                inactiveLabel: 'No',
                label: '',
                class: 'success',
                checked: false
              }
            }
          })
        } 

        if (data.future_claim) {
          if (data.future_claim[0]) {
            this.futureClaim.est_inc_loss_ratio = data.future_claim[0].content
            this.futureClaim.est_inc_loss_ratio_id = data.future_claim[0].id
          }
          if (data.future_claim[1]) {
            this.futureClaim.condition = data.future_claim[1].content
            this.futureClaim.condition_id = data.future_claim[1].id
          }
          if (data.future_claim[2]) {
            this.futureClaim.amount_of_years = data.future_claim[2].content
            this.futureClaim.amount_of_years_id = data.future_claim[2].id
          }
          if (data.future_claim[3]) {
            this.futureClaim.estimate_loss_future = data.future_claim[3].content
            this.futureClaim.estimate_loss_future_id = data.future_claim[3].id
          }
        }

        if (data.calculations) {
          this.calculationId = data.calculations.id

          if (data.calculations.individual) {
            this.individualRecords = []
            this.individualRecordsCurrentYear = null

            data.calculations.individual.forEach(individual => {
              if (individual.year !== this.initialIndividualRecords.year) {
                this.individualRecords.push({
                  id: individual.id,
                  year: individual.year,
                  actual_premium: individual.premium || 0,
                  claim_loss: individual.claim_loss || 0,
                  pure_risk: parseFloat(individual.pure_risk).toFixed(2) || 0,
                  loss_ratio: parseFloat(individual.loss_ratio).toFixed(2) || 0,
                  is_edit:false
                })
              } else {
                this.individualRecordsCurrentYear = {
                  id: individual.id,
                  year: individual.year,
                  actual_premium: individual.premium || 0,
                  claim_loss: individual.claim_loss || 0,
                  pure_risk: parseFloat(individual.pure_risk).toFixed(2) || 0,
                  loss_ratio: parseFloat(individual.loss_ratio).toFixed(2) || 0,
                }
              }
            })

            this.individualRecords = this.individualRecords.sort((a, b) => {
              return a.year - b.year
            })

            this.setOutstandingYearOption()
            if (this.individualRecords.length) {
              if (this.individualRecords[this.individualRecords.length - 1]) {
                this.setOutstandingYearOption(this.individualRecords[this.individualRecords.length - 1].year)
              }
            }
          }

          if (data.calculations.accumulative) {
            this.accumulativeCalculationLists = data.calculations.accumulative
          }
        }

        if (data.final_calculation) {
          this.finalCalculationId = data.final_calculation.id
          this.pesimisticCalculation = []

          data.final_calculation.pesimistic_calculation.forEach((pesimistic, pesimisticIndex) => {
            this.pesimisticCalculation.push({
              id: pesimistic.id,
              companies: pesimistic.company ? JSON.parse(pesimistic.company) : [],
              pesimisticRate: pesimistic.current_rate_type,
              current_rate: parseFloat(pesimistic.current_rate).toFixed(3),
              decrease: pesimistic.decrease,
              result: parseFloat(pesimistic.result).toFixed(3),
              iv: data.final_calculation.final_result[pesimisticIndex] ?  parseFloat(data.final_calculation.final_result[pesimisticIndex].final_rate_iv).toFixed(3) : 0,
              war: data.final_calculation.final_result[pesimisticIndex] ?  parseFloat(data.final_calculation.final_result[pesimisticIndex].final_rate_war).toFixed(3)  : 0
            })
          })

          this.optimisticCalculationList = data.final_calculation.optimistic_calculation
          this.finalCalculationLists = data.final_calculation.final_result

          if (data.final_calculation.selected_kurs_mapping_id) {
            this.currencyMappingId = data.final_calculation.selected_kurs_mapping_id
          }

          await this.setCompanyOptions()
        }

        if (data.result_budget) {
          this.resultBudgetId = data.result_budget.id
          this.budgetLists = data.result_budget.data_result_budget

          if (this.budgetLists) {
            this.budgets.map(budget => {
              const budgetList = this.budgetLists.find(list => Number(list.company) === budget.id)
              const types = budget.types

              if (budgetList) {
                budget.dataId = budgetList.id

                if (types) {
                  types.map(type => {
                    const typeList = budgetList.grouping_vehicle_type.find(list => Number(list.vehicle_type_id) === type.id)
                    if (typeList) {
                      type.dataId = typeList.id
                    }

                    return type
                  })
                }
              }

              return budget
            })
          }
        }
      } else {
        this.$swal(
          `Oops!`,
          'Data Sudah Terisi Sebelumnya',
          'error'
        )
      }
    },
    setYearOption () {
      const yearNow = new Date(Date.now()).getFullYear()
      const arr = [yearNow]
      for (let i = 1; i <= 5; i++) {
        arr.unshift(yearNow + i)
      }
      for (let i = 1; i <= 7; i++) {
        arr.push(yearNow - i)
      }
      this.yearOption = arr
    },
    setOutstandingYearOption (year = null) {
      const arr = [this.selectedYear]
      const calcArr = []
      for (let i = 1; i <= 7; i++) {
        arr.push(this.selectedYear - i)
        if (year) {
          const option = (this.selectedYear - i)
          if (option < year) {
            calcArr.push(option)
          }
        } else {
          calcArr.push(this.selectedYear - i)
        }
      }
      this.outstandingYearOption = arr
      this.yearCalculationOption = calcArr
    
      this.individualRecords.map(obj => {
        this.yearCalculationOption = this.yearCalculationOption.filter(val => val !== obj.year)
      })

      this.calculation.year = this.yearCalculationOption?.[0] ?? null
    },
    deleteIndividualRecord (index) {
      this.individualRecords.splice(index, 1)
      this.setOutstandingYearOption()
    },
    setCalculationLossRatio () {
      const lossRatio = this.calculation.claim_loss / this.calculation.actual_premium * 100
      if (Number.isNaN(lossRatio)) this.calculation.loss_ratio = 0
      else this.calculation.loss_ratio = parseFloat(lossRatio).toFixed(2)
    },
    insertIndividualRecord (data) {
      this.individualRecords.unshift({
        year: data.year,
        actual_premium: data.actual_premium,
        claim_loss: data.claim_loss,
        loss_ratio: parseFloat(data.loss_ratio).toFixed(2),
        pure_risk: parseFloat(data.pure_risk).toFixed(2),
        manual: true
      })
      const idx = this.yearCalculationOption.indexOf(data.year)
      this.yearCalculationOption.splice(idx, 1)
      this.calculation = {
        year: data.year - 1,
        actual_premium: '',
        claim_loss: '',
        pure_risk: 0,
        loss_ratio: 0
      }
      this.individualRecords = this.individualRecords.sort((a, b) => {
        return a.year - b.year
      })
    },
    formatCurrency (number) {
      const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 2
      })
      return formatter.format(Number.isNaN(number) ? 0 : number)
    },
    linearRegression (x, ky, kx) {
      let i = 0; let nr = 0; let dr = 0; let ax = 0; let ay = 0; let a = 0; let b = 0
      const average = (ar) => {
        let r = 0
        for (i = 0; i < ar.length; i++) {
          r = r + ar[i]
        }
        return r / ar.length
      }
      ax = average(kx)
      ay = average(ky)
      for (i = 0; i < kx.length; i++) {
        nr = nr + ((kx[i] - ax) * (ky[i] - ay))
        dr = dr + ((kx[i] - ax) * (kx[i] - ax))
      }
      b = nr / dr
      a = ay - (b * ax)
      return (a + (b * x))
    },
    setDecreaseAndResult (num, index) {
      const decrease = (Number(num) * Number(this.reducePremiumPercent).toFixed(3)) / 100
      this.pesimisticCalculation[index].decrease = decrease

      if (this.textReducePremium === 'Increase Premium') {
        this.pesimisticCalculation[index].result = Number(num) + decrease
      } else {
        this.pesimisticCalculation[index].result = Number(num) - decrease
      }
    },
    addPesimisticCalculation () {
      this.pesimisticCalculation.push({
        id: null,
        companies: [],
        pesimisticRate: 'manual',
        current_rate: 0,
        decrease: 0,
        result: 0,
        iv: 0,
        war: 0
      })
    },
    async setCompanyOptions () {
      const selectedCompany = []
      this.pesimisticCalculation.forEach(data => {
        data.companies.forEach(company => {
          selectedCompany.push(company)
        })
      })
      this.companiesSelected = selectedCompany
      this.companyOptions = this.companies.filter(val => selectedCompany.indexOf(val.id) === -1)

      await this.getForecastPriceFleetData()
    },
    async changeCurrentRate (index) {
      if (this.pesimisticCalculation[index].pesimisticRate === 'tsi_hm') {
        const payload = {
          year: this.checkPayload.year,
          company_ids: this.pesimisticCalculation[index].companies
        }
        const { data, status } = await this.getPesimistic(payload)
        if (status === 'success') {
          this.pesimisticCalculation[index].current_rate = parseFloat(data[0].rate_hm).toFixed(3)
          this.rateHm = parseFloat(data[0].rate_hm).toFixed(3) 
        }
      }
    },
    async getForecastPriceFleetData () {
      const payload = {
        year: this.checkPayload.year,
        companies: this.companiesSelected,
        group_id: this.checkPayload.group_id
      }
      const { data, status } = await this.getForecastPriceFleet(payload)
      if (status === 'success' && data) {
        this.budgets = data.map(d => {
          const types = d.types.map(type => {
            return {
              ...type,
              sub_total: 0
            }
          })
          return {
            ...d,
            types,
            grand_total: 0
          }
        })
      }
    },
    budgetHmRateCalculation (budgetIndex, typeIndex, companyId) {
      const getOptimisticCalculation = this.optimisticCalculation.find(oc => oc.companies.find(company => company.id === companyId))
      const getBudget = this.budgets[budgetIndex]

      if (getOptimisticCalculation && getBudget) {
        const getType = this.budgets[budgetIndex].types[typeIndex]
        if (getType) {
          return getType.data.hm * (getOptimisticCalculation.result > 0 ? Number(getOptimisticCalculation.result).toFixed(3) / 100 : 0)
        }
      }

      return 0
    },
    budgetIvRateCalculation (budgetIndex, typeIndex, companyId) {
      const getOptimisticCalculation = this.optimisticCalculation.find(oc => oc.companies.find(company => company.id === companyId))
      const getBudget = this.budgets[budgetIndex]

      if (getOptimisticCalculation && getBudget) {
        const getType = this.budgets[budgetIndex].types[typeIndex]
        if (getType) {
          return getType.data.iv * (getOptimisticCalculation.iv > 0 ? Number(getOptimisticCalculation.iv).toFixed(3) / 100 : 0)
        }
      }

      return 0
    },
    budgetWarRateCalculation (budgetIndex, typeIndex, companyId) {
      const getOptimisticCalculation = this.optimisticCalculation.find(oc => oc.companies.find(company => company.id === companyId))
      const getBudget = this.budgets[budgetIndex]

      if (getOptimisticCalculation && getBudget) {
        const getType = this.budgets[budgetIndex].types[typeIndex]
        if (getType) {
          return getType.data.war * (getOptimisticCalculation.war > 0 ? Number(getOptimisticCalculation.war).toFixed(3) / 100 : 0)
        }
      }

      return 0
    },
    budgetSubtotalSum (companyId) {
      const getBudget = this.budgets.find(company => company.id === companyId)

      if (getBudget) {
        return _.sumBy(getBudget.types, o => o.data.sub_total)
      }

      return 0
    },
    handleEdit (id) {
      this.$router.push({
        name: 'mi.forecast-condition-input',
        query: {
          id
        }
      })
      location.reload()
    },
    async onSave () {
      let readyToSave = {
        company:'',
        ready:false
      }
      this.budgets.map(val => {
        if(val.types.length === 0) {
          readyToSave.company = val.company
          readyToSave.ready = true
        }
      })

      if(readyToSave.ready === true){
        return this.$swal(
          `Oops!`,
          'Please input TSI first for this period',
          'error'
        )
      }

      const risks = {
        'total_pure_risk': parseFloat(this.pureRiskItemTotal).toFixed(2),
        'pure_risk_cost': parseFloat(this.pureRiskItemCost).toFixed(2),
        'detail': this.pureRiskItems.map(item => {
          const obj = {
            'component_id': item.id,
            'percentage': item.value
          }

          if (item.risk_detail_id) {
            obj.id = item.risk_detail_id
          }

          return obj
        })
      }
      if (this.pureRiskId) {
        risks.id = this.pureRiskId
      }

      const outstandings = this.outstanding.map(outstanding => {
        const obj = {
          'form_id': this.id ? outstanding.form_id : outstanding.id,
          'estimated_cost': outstanding.cost ? Number(outstanding.cost) : 0,
          'nett_lost': outstanding.nett_lost ? Number(outstanding.nett_lost) : 0,
          'thumb_rules': outstanding.thumb_rules_cost ? Number(outstanding.thumb_rules_cost) : 0,
          'form_free_text': outstanding.form_free_text,
          'estimated_closed_file': outstanding.follow_up_response === 'settled' ? (
            outstanding.summary && outstanding.summary.act_settled_date ? moment(outstanding.summary.act_settled_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
          ) : (
            outstanding.summary && outstanding.summary.est_settled_date ? moment(outstanding.summary.est_settled_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
          ),
          'status': outstanding.follow_up_response, // based on follow up response field
          'period_from': outstanding.coverage_period ? moment(outstanding.coverage_period.toString()).startOf('year').format('YYYY-MM-DD') : moment().startOf('year').format('YYYY-MM-DD'),
          'period_to': outstanding.coverage_period_to ? moment(outstanding.coverage_period_to.toString()).endOf('year').format('YYYY-MM-DD') : moment().endOf('year').format('YYYY-MM-DD')
        }

        if(!obj.form_id) obj.form_id = null
        if(this.id) obj.id = outstanding.id

        return obj
      })

      const futureClaims = [
        {
          'title': 'Est Increase Loss Ratio',
          'content': this.futureClaim.est_inc_loss_ratio
        },
        {
          'title': 'Condition',
          'content': this.futureClaim.condition
        },
        {
          'title': 'Amount /Years',
          'content': this.futureClaim.amount_of_years
        },
        {
          'title': 'Estimate Loss Future Until Renewal',
          'content': this.futureClaim.estimate_loss_future
        }
      ]
      if (this.futureClaim.est_inc_loss_ratio_id) {
        futureClaims[0].id = this.futureClaim.est_inc_loss_ratio_id
      }
      if (this.futureClaim.condition_id) {
        futureClaims[1].id = this.futureClaim.condition_id
      }
      if (this.futureClaim.amount_of_years_id) {
        futureClaims[2].id = this.futureClaim.amount_of_years_id
      }
      if (this.futureClaim.estimate_loss_future_id) {
        futureClaims[3].id = this.futureClaim.estimate_loss_future_id
      }

      const calculations = {
        'get_data': this.typeCalculation, // Manual & Data Before
        'max_calculation': Number(this.initialCalculationPayload.decrement), // let it null, if you choose manual
        'individual': [...this.individualRecords, { ...this.initialIndividualRecords }].map(record => {
          const obj = {
            'year': record.year,
            'premium': record.actual_premium,
            'claim_loss': record.claim_loss,
            'loss_ratio': parseFloat(record?.loss_ratio ?? 0).toFixed(2),
            'pure_risk': parseFloat(record?.pure_risk ?? 0).toFixed(2)
          }

          if (record.id) {
            obj.id = record.id
          }

          return obj
        }),
        'accumulative': this.accumulativeRecords.map((record, index) => {
          const obj = {
            'year': record.year,
            'acc_premium': record.acc_premium,
            'acc_loss': record.acc_loss,
            'acc_loss_ratio': parseFloat(record?.acc_loss_ratio ?? 0).toFixed(2),
            'pure_risk': parseFloat(record?.pure_risk ?? 0).toFixed(2),
            'insurer_recovery_month': parseFloat(record.insurer_rec_month).toFixed(3),
            'insurer_recovery': parseFloat(record.insurer_recovery).toFixed(3),
            'crosscheck_rugi_untung': record.keuntungan_kerugian,
            'crosscheck_percent': parseFloat(record.insurer_recovery).toFixed(3)
          }
          const getId = this.accumulativeCalculationLists[index] ? this.accumulativeCalculationLists[index].id : null
          if (getId) {
            obj.id = getId
          }

          return obj
        })
      }
      if (this.calculationId) {
        calculations.id = this.calculationId
      }

      const finalCalculations = {
        'reduce_premium': this.reducePremium,
        'reduce_premium_percent': Number(this.reducePremiumPercent).toFixed(3),
        'selected_kurs_mapping_id': this.currencyMappingId,
        'pesimistic_calculation': this.pesimisticCalculation.map(calculation => {
          const obj = {
            'company': calculation.companies,
            'current_rate_type': calculation.pesimisticRate, // manual or api
            'current_rate': calculation.current_rate ? Number(calculation.current_rate).toFixed(3) : 0,
            'decrease': calculation.decrease ? Number(calculation.decrease).toFixed(3) : 0,
            'result': calculation.result ? Number(calculation.result).toFixed(3) : 0
          }
          if (calculation.id) {
            obj.id = calculation.id
          }
          return obj
        }),
        'optimistic_calculation': this.optimisticCalculation.map(calculation => {
          const obj = {
            'company': calculation.companies,
            'pesimistic_rate': calculation.rate ? Number(calculation.rate).toFixed(3) : 0,
            'additional_percent': calculation.additional ? Number(calculation.additional).toFixed(3) : 0,
            'result': calculation.result ? Number(calculation.result).toFixed(3) : 0
          }
          if (calculation.id) {
            obj.id = calculation.id
          }
          return obj
        }),
        'final_result': this.pesimisticCalculation.map((calculation, index) => {
          const obj = {
            'company': calculation.companies,
            'value': calculation.result ? Number(calculation.result).toFixed(3) : 0,
            'final_rate_hm': calculation.result ? Number(calculation.result).toFixed(3) : 0,
            'final_rate_iv': calculation.iv ? Number(calculation.iv).toFixed(3) : 0,
            'final_rate_war': calculation.war ? Number(calculation.war).toFixed(3) : 0
          }
          const getId = this.finalCalculationLists[index] ? this.finalCalculationLists[index].id : null
          if (getId) {
            obj.id = getId
          }
          return obj
        })
      }
      if (this.finalCalculationId) {
        finalCalculations.id = this.finalCalculationId
      }

      const resultBudget = {
        'total_seluruh': this.budgetGrandTotalSum,
        'difference': this.forecastPremium > 0 ? (100 - (this.budgetGrandTotalSum / this.forecastPremium)) : 0,
        'data_result_budget': this.budgets.map(budget => {
          const types = budget.types.map(type => {
            const obj = {
              'vehicle_type_id': type.id,
              'tsi_value': type.data.tsi,
              'tsi_hm': type.data.hm,
              'tsi_iv': type.data.iv,
              'tsi_war': type.data.war,
              'hm_rate': parseFloat(type.data.hm_rate).toFixed(3),
              'iv_rate': parseFloat(type.data.iv_rate).toFixed(3),
              'war_rate': parseFloat(type.data.war_rate).toFixed(3),
              'sub_total': type.data.sub_total
            }

            if (type.dataId) {
              obj.id = type.dataId
            }

            return obj
          })

          const obj = {
            'company': budget.id,
            'sub_total': budget.grand_total,
            'grand_total': budget.grand_total,
            'grouping_vehicle_type': types
          }

          if (budget.dataId) {
            obj.id = budget.dataId
          }

          return obj
        })
      }
      if (this.resultBudgetId) {
        resultBudget.id = this.resultBudgetId
      }

      const payload = {
        'year': this.checkPayload.year,
        'vehicle_type_group_id': this.checkPayload.group_id,
        'actual_premium': this.actualPremium,
        'forecast_burden': this.budgetGrandTotalSum ? Number(this.budgetGrandTotalSum).toFixed(3) : 0,
        'forecast_unburden': this.forecastPremium ? Number(this.forecastPremium).toFixed(3) : 0,
        'rate_hm': parseFloat(this.rateHm).toFixed(3) || parseFloat(this.currentHm).toFixed(3),
        'loading_point_insurer': this.loadingPointInsurer,
        'additional_loading_point_insurer': this.additionalLoadingPointInsurer,
        'final_loading_point': this.finalLoadingPointInsurer.toFixed(3),
        risks,
        outstandings,
        future_claim: futureClaims,
        calculations,
        final_calculation: finalCalculations,
        result_budget: resultBudget
      }
      let funcName = 'saveForecast'
      if (this.id) {
        payload.id = this.id
        funcName = 'updateForecast'
      }

      this.saving = true

      const res = await this[funcName](payload)
      console.log(res)

      if (res.status !== 'success') {
        this.$swal(
          `Oops!`,
          res?.data?.message,
          'error'
        )
        this.saving = false
        return
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      this.$router.push('/mi/forecast-condition')

      this.saving = false
    }
  }
}
</script>
