var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tab-nav', {
    attrs: {
      "tabs": true,
      "align": "center"
    }
  }, [_c('tab-nav-items', {
    attrs: {
      "active": true,
      "href": "#cargo",
      "title": "Master Data Cargo Type"
    }
  }), _c('tab-nav-items', {
    attrs: {
      "active": false,
      "href": "#unit",
      "title": "Master Data Unit"
    }
  })], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "myTabContent2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "cargo"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MASTER DATA CARGO TYPE")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.getCargoType();
            }
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Cargo Type:",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.cargoTypeList.params.name,
            callback: function callback($$v) {
              _vm.$set(_vm.cargoTypeList.params, "name", $$v);
            },
            expression: "cargoTypeList.params.name"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Master Data Cargo Type")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.cargoTypeList._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.cargoTypeList.data && _vm.cargoTypeList.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("No")]), _c('th', [_vm._v("Name")]), _c('th', [_vm._v("Alias")]), _c('th', [_vm._v("Unit")]), _c('th', [_vm._v("Status")]), _c('th', {
          staticClass: "text-right",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.cargoTypeList.data, function (p, i) {
          var _p$unit$name, _p$unit;
          return _c('tr', {
            key: "p-".concat(i)
          }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(_vm._s(p.name))]), _c('td', [_vm._v(_vm._s(p.alias))]), _c('td', [_c('b-badge', {
            staticClass: "border border-primary text-primary text-uppercase",
            attrs: {
              "variant": "none"
            }
          }, [_vm._v(_vm._s((_p$unit$name = p === null || p === void 0 ? void 0 : (_p$unit = p.unit) === null || _p$unit === void 0 ? void 0 : _p$unit.name) !== null && _p$unit$name !== void 0 ? _p$unit$name : '-'))])], 1), _c('td', {
            class: p.status ? 'text-success' : 'text-danger'
          }, [_vm._v(_vm._s(p.status ? 'Active' : 'Inactive'))]), _c('td', {
            staticClass: "text-right"
          }, [_c('b-button', {
            staticClass: "mb-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.editFormCargoType(p);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]), _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteCargoType(p);
              }
            }
          }, [!_vm.delete_cargo_type ? _c('i', {
            staticClass: "fa fa-trash"
          }) : [_c('span', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status",
              "aria-hidden": "true"
            }
          }), _vm._v("Deleting... ")]], 2)], 1)]);
        }), 0)])]) : _vm._e(), _vm.cargoTypeList.data && _vm.cargoTypeList.data.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.cargoTypeList.meta.totalItem,
            "per-page": _vm.cargoTypeList.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getCargoType
          },
          model: {
            value: _vm.cargoTypeList.meta.page,
            callback: function callback($$v) {
              _vm.$set(_vm.cargoTypeList.meta, "page", $$v);
            },
            expression: "cargoTypeList.meta.page"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No Cargo Type data found.")])]];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.cargo_type_edit
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.cargo_type_edit ? 'Edit' : 'Add') + " Cargo Type")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$unitChild;
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSaveCargoType();
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Name",
            "label-for": "name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "placeholder": "Type text",
            "maxlength": "30"
          },
          model: {
            value: _vm.formCargoType.name,
            callback: function callback($$v) {
              _vm.$set(_vm.formCargoType, "name", $$v);
            },
            expression: "formCargoType.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Alias",
            "label-for": "alias"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "alias",
            "type": "text",
            "placeholder": "Type text",
            "maxlength": "30"
          },
          model: {
            value: _vm.formCargoType.alias,
            callback: function callback($$v) {
              _vm.$set(_vm.formCargoType, "alias", $$v);
            },
            expression: "formCargoType.alias"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Unit",
            "label-for": "unit_id"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.unitList.data,
            "reduce": function reduce(obj) {
              return obj.id;
            },
            "placeholder": "Select unit..."
          },
          on: {
            "input": _vm.onChangeUnit
          },
          model: {
            value: _vm.formCargoType.unit_id,
            callback: function callback($$v) {
              _vm.$set(_vm.formCargoType, "unit_id", $$v);
            },
            expression: "formCargoType.unit_id"
          }
        }), ((_vm$unitChild = _vm.unitChild) === null || _vm$unitChild === void 0 ? void 0 : _vm$unitChild.length) > 0 ? _c('div', {
          staticClass: "d-flex flex-wrap mt-2"
        }, _vm._l(_vm.unitChild, function (child, childIndex) {
          return _c('b-badge', {
            key: childIndex,
            staticClass: "border border-primary text-primary text-uppercase mb-1 mr-2",
            attrs: {
              "variant": "none"
            }
          }, [_vm._v(_vm._s(child.name))]);
        }), 1) : _vm._e()], 1), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.formCargoType.status,
            callback: function callback($$v) {
              _vm.$set(_vm.formCargoType, "status", $$v);
            },
            expression: "formCargoType.status"
          }
        }, [_vm._v("Active / Inactive ")])], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.cargo_type_edit ? _c('span', [_vm._v("Add")]) : _c('span', [_vm._v("Save Edit")])]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.resetFormCargoType();
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "unit"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MASTER DATA UNIT")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.getUnit();
            }
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search unit:",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "Unit",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.unitList.params.name,
            callback: function callback($$v) {
              _vm.$set(_vm.unitList.params, "name", $$v);
            },
            expression: "unitList.params.name"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Master Data Unit")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.unitList._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.unitList.data && _vm.unitList.data.length > 0 ? _c('div', [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Name")]), _c('th', [_vm._v("Alias")]), _c('th', [_vm._v("Child")]), _c('th', [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.unitList.data, function (state, index) {
          var _state$child;
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(state.name))]), _c('td', [_vm._v(_vm._s(state.alias))]), _c('td', [(state === null || state === void 0 ? void 0 : (_state$child = state.child) === null || _state$child === void 0 ? void 0 : _state$child.length) > 0 ? _c('div', {
            staticClass: "d-flex flex-wrap"
          }, _vm._l(state === null || state === void 0 ? void 0 : state.child, function (child, childIndex) {
            return _c('b-badge', {
              key: childIndex,
              staticClass: "border border-primary text-primary text-uppercase mb-1 mr-2",
              attrs: {
                "variant": "none"
              }
            }, [_vm._v(_vm._s(child.name))]);
          }), 1) : _c('span', [_vm._v("-")])]), _c('td', [_c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            }
          }, [_c('i', {
            staticClass: "fa fa-edit",
            on: {
              "click": function click($event) {
                return _vm.editFormUnit(state);
              }
            }
          })]), _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteUnit(state);
              }
            }
          }, [!_vm.delete_unit ? _c('i', {
            staticClass: "fa fa-trash"
          }) : [_c('span', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status",
              "aria-hidden": "true"
            }
          }), _vm._v("Deleting... ")]], 2)], 1)]);
        }), 0)])])]) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No Unit data found.")])]];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.unit_edit
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.unit_edit ? 'Edit' : 'Add') + " Unit")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSaveUnit();
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Name",
            "label-for": "name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "placeholder": "Type text"
          },
          model: {
            value: _vm.formUnit.name,
            callback: function callback($$v) {
              _vm.$set(_vm.formUnit, "name", $$v);
            },
            expression: "formUnit.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Alias",
            "label-for": "alias"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "alias",
            "type": "text",
            "placeholder": "Type text",
            "maxlength": "30"
          },
          model: {
            value: _vm.formUnit.alias,
            callback: function callback($$v) {
              _vm.$set(_vm.formUnit, "alias", $$v);
            },
            expression: "formUnit.alias"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Child",
            "label-for": "child"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "options": _vm.optionsUnit,
            "multiple": true,
            "placeholder": "Select unit...",
            "track-by": "id",
            "label": "name"
          },
          model: {
            value: _vm.formUnit.child,
            callback: function callback($$v) {
              _vm.$set(_vm.formUnit, "child", $$v);
            },
            expression: "formUnit.child"
          }
        })], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_vm.saving_unit ? _c('span', {
          staticClass: "spinner-border spinner-border-sm"
        }) : !_vm.unit_edit && !_vm.saving_unit ? _c('span', [_vm._v("Add")]) : _vm.unit_edit && !_vm.saving_unit ? _c('span', [_vm._v("Save Edit")]) : _vm._e()]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.resetFormUnit();
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }