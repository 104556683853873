var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "m-2"
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 align-items-center justify-content-between"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Date Filter"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "sm",
      "placeholder": "Select options..."
    },
    on: {
      "change": function change($event) {
        return _vm.convertDataIntoGanttChart();
      }
    },
    model: {
      value: _vm.dateOption,
      callback: function callback($$v) {
        _vm.dateOption = $$v;
      },
      expression: "dateOption"
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("Monthly")]), _c('option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v("Quarterly")]), _c('option', {
    attrs: {
      "value": "3"
    }
  }, [_vm._v("Semesterly")]), _c('option', {
    attrs: {
      "value": "4"
    }
  }, [_vm._v("Yearly")])])], 1), _c('b-form-group', [!_vm.isImmutable ? _c('b-button', {
    staticClass: "mt-3",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.onDisableImmutable
    }
  }, [_c('i', {
    staticClass: "fa fa-pen mr-1"
  }), _vm._v(" Edit Periode")]) : _c('b-button', {
    staticClass: "mt-3",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": _vm.onDisableImmutable
    }
  }, [_c('i', {
    staticClass: "fa fa-ban mr-1"
  }), _vm._v("Cancel")])], 1)], 1)]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('g-gantt-chart', {
    attrs: {
      "chart-start": _vm.ganttChartData.chartStart,
      "chart-end": _vm.ganttChartData.chartEnd,
      "grid": _vm.ganttChartData.grid,
      "grid-size": _vm.ganttChartData.gridSize,
      "hide-timeaxis": _vm.ganttChartData.hideTimeaxis,
      "push-on-overlap": _vm.ganttChartData.pushOnOverlap,
      "snap-back-on-overlap": "",
      "precision": _vm.ganttChartData.precision,
      "is-magnetic": _vm.ganttChartData.isMagnetic,
      "row-label-width": _vm.ganttChartData.rowLabelWidth,
      "row-height": _vm.ganttChartData.rowHeight,
      "theme": _vm.theme,
      "width": _vm.ganttChartData.width,
      "height": _vm.ganttChartData.height,
      "allow-add": _vm.ganttChartData.allowAdd
    },
    on: {
      "dragend-bar": function dragendBar($event) {
        return _vm.onDragend($event);
      }
    }
  }, _vm._l(_vm.ganttChartData.rows, function (row) {
    return _c('g-gantt-row', {
      key: row.label,
      attrs: {
        "label": row.label,
        "row-style": row.style,
        "bars": row.bars
      },
      scopedSlots: _vm._u([{
        key: "bar-label",
        fn: function fn(_ref) {
          var bar = _ref.bar;
          return [_c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.top",
              value: bar.label,
              expression: "bar.label",
              modifiers: {
                "top": true
              }
            }],
            staticClass: "d-flex w-100 align-items-center justify-content-between px-2"
          }, [_c('span', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(bar.label))]), _c('b-dropdown', {
            staticClass: "mt-0",
            attrs: {
              "size": "sm",
              "variant": "link iq-border-radius-10",
              "toggle-class": "text-decoration-none",
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_c('span', {
                  staticClass: "dropdown-toggle p-0",
                  attrs: {
                    "id": "dropdownMenuButton5",
                    "data-toggle": "dropdown"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-pencil-square-o m-0 text-primary"
                })])];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            attrs: {
              "variant": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.openSlideDetailProjectContract(row.id);
              }
            }
          }, [_c('i', {
            staticClass: "fas fa-eye mr-2"
          }), _vm._v("Detail ")]), _c('b-dropdown-item', {
            attrs: {
              "variant": "warning"
            }
          }, [_c('i', {
            staticClass: "fas fa-info-circle mr-2"
          }), _vm._v("PFA ")]), _c('b-dropdown-item', {
            attrs: {
              "variant": "primary"
            }
          }, [_c('i', {
            staticClass: "fas fa-info-circle mr-2"
          }), _vm._v("Project Forms ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
            attrs: {
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteProjectContract(row === null || row === void 0 ? void 0 : row.id);
              }
            }
          }, [_c('i', {
            staticClass: "fas fa-trash mr-2"
          }), _vm._v("Delete ")])], 1)], 1)];
        }
      }], null, true)
    });
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }