<template>
  <form @submit.prevent="onSavePlan" v-if="!detail_project_loading"> 
    <b-row class="m-3">
      <!-- CHANGE STAGE ON EDIT ACTION -->
      <b-col lg="12" class="mb-4" v-if="['EDIT'].includes(action)">
        <div class="d-flex align-items-center w-100 justify-content-end">
          <div v-for="(state, index) in listProjectStage" :key="index">
            <b-badge 
              class="border border-primary text-primary text-uppercase" 
              :variant="detailProjectContract?.detail_project_stage_id === state.id ? 'primary' : 'none'" 
              style="cursor: pointer;" 
              @click="onChangeStatusStage(detailProjectContract?.id, state.id)"
            >
              <span :class="detailProjectContract?.detail_project_stage_id === state.id ? 'text-white' : ''">{{ state?.title }}</span>
            </b-badge>
            <strong v-if="listProjectStage?.length - 1 !== index">&nbsp;>>&nbsp;</strong>
          </div>
        </div>
      </b-col>

      <!-- SUBMIT BUTTON -->
      <b-col lg="12" class="mb-4" v-if="['EDIT', 'PREVIEW'].includes(action)">
        <div :class="`d-flex align-items-center justify-content-end w-100`">
          <b-button
              v-if="['EDIT'].includes(action)"
              :disabled="saving"
              block
              variant="primary"
              type="submit"
              style="width:15%"
              class="mt-2 mr-3"
            >
              <span
                v-if="saving"
                class="spinner-border spinner-border-sm"
              />
              <div v-else>
                <i class="fa fa-save"></i>
                <span>Submit Data</span>
              </div>
            </b-button>
          <b-button
          v-if="['EDIT'].includes(action)"
              :disabled="saving"
              block
              type="button"
              variant="danger"
              style="width:10%"
  
              @click="changeAction('PREVIEW')"
            >
              <div>
                <i class="fa fa-ban"></i>
                <span>Cancel</span>
              </div>
            </b-button>

            <b-button
              type="button"
              v-if="['PREVIEW'].includes(action)"
              block
              variant="primary"
              style="width:15%"
              @click="changeAction('EDIT')"
            >
              <div>
                <i class="fa fa-pen"></i>
                <span>Edit Data</span>
              </div>
            </b-button>
        </div>
      </b-col>
      <b-col lg="12" v-if="['ADD'].includes(action)">
        <div class="d-flex align-items-center justify-content-end w-100">
          <b-button
              :disabled="saving"
              block
              variant="primary"
              type="submit"
              style="width:15%"
            >
              <span
                v-if="saving"
                class="spinner-border spinner-border-sm"
              />
              <div v-else>
                <i class="fa fa-save"></i>
                <span>Submit Data</span>
              </div>
            </b-button>
        </div>
      </b-col>

      <b-col cols="12">
        <fieldset class="form-group border p-3">
          <legend class="w-auto px-2 text-primary">Project</legend>
          <b-row v-if="['ADD'].includes(action)">
            <b-col lg="12">
              <b-alert :show="true" variant="primary">
                <template v-for="(item,index) in company">
                  <b-form-radio inline v-model="project_type" :name="item.name" :key="index" :value="item.value">{{ item.label }}</b-form-radio>
                </template>
              </b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" v-if="project_type === 'existing'">
              <b-form-group label-for="dateIssued" label="Projects" v-if="project_type === 'existing'">
                <v-select
                  v-if="['ADD', 'EDIT'].includes(action)"
                  label="text"
                  name="project_sub_id"
                  v-model="form.project_sub_id"
                  :options="projectOptions"
                  :reduce="
                    (projectOptions) => projectOptions.value
                  "
                  placeholder="Select Project"
                  required
                  @input="onSelectedProject"
                ></v-select>
                <span v-else>{{ detailProjectContract?.project_sub?.project?.project_name }} - {{ detailProjectContract?.project_sub?.name }}</span>
              </b-form-group>
            </b-col>
            <b-col :lg="project_type === 'new_project' ? '12' : '6'">
              <b-form-group
                label="Customer"
                label-for="customer_id"
              >
                <v-select
                  v-if="['ADD', 'EDIT'].includes(action)"
                  label="text"
                  name="customer_id"
                  v-model="form.customer_id"
                  :options="optionsListCustomer"
                  :reduce="
                    (optionsListCustomer) => optionsListCustomer.value
                  "
                  placeholder="Select Customer"
                  required
                  @input="onSelectedCustomer"
                ></v-select>
                <span v-else>{{ detailProjectContract?.customer?.customer_code }} - {{ detailProjectContract?.customer?.account_name }}</span>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button @click="openCustomerSlide"  variant="success" size="sm" v-if="project_type === 'new_project'"><i class="fa fa-plus"></i> &nbsp; ADD NEW CUSTOMER</b-button>
        </fieldset>
      </b-col>
      <b-col lg="12">
        <b-row>
          <!-- PROJECT PLAN -->
          <b-col lg="6" md="12" v-if="project_type === 'existing' && form.customer_id && form.project_sub_id">
            <fieldset class="form-group border p-3">
              <legend class="w-auto px-2 text-primary">Project Info</legend>
              <div class="d-flex w-100 overflow-auto ">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>PROJECT NAME</th>
                      <th>PROJECT SUB NAME</th>
                      <th>LOCATION</th>
                      <th>STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ projectDetail?.project?.project_name }}</td>
                      <td>{{ projectDetail?.name }}</td>
                      <td>{{ projectDetail?.project?.location }}</td>
                      <td>{{ projectDetail?.status ?? '-' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </fieldset>
          </b-col>

          <!-- LOG ACITIVITY HISTORY PROJECT CONTRACT -->
          <b-col lg="6" v-if="['EDIT', 'PREVIEW'].includes(action)">
            <fieldset class="form-group border p-3">
              <legend class="w-auto px-2 text-primary">Log History Activity</legend>
        
              <div class="overflow-auto d-flex w-100 " style="max-height:544px">
                <b-row>
                  <b-col lg="12" class="mb-4" v-for="(state, index) in detailProjectContract?.project_log" :key="index">
                    <div class="d-flex">
                      <strong class="mr-2">{{ state?.name }} -</strong>
                      <span class="text-muted">{{ moment(state.date_log).format('DD MMMM YYYY') }}</span>
                    </div>
                    <span>{{ state.log_description }}</span>
                    <!-- <div class="ml-4">
                      > <strong class="text-muted">New</strong> -> <strong class="text-primary">Proposition</strong> <i>(Stage)</i>
                    </div> -->
                    <br />
                    <strong>----</strong>
                  </b-col>
                </b-row>
              </div>
            </fieldset>
          </b-col>

          <!-- FORM PROJECT -->
          <b-col cols="6" v-if="(project_type === 'new_project' && form.customer_id) && ['ADD'].includes(action)">
            <fieldset class="form-group border p-3">
              <legend class="w-auto px-2 text-primary">Project Form</legend>
              <b-form @submit.prevent="submitFormProject">
                <b-form-group label="Project Name" label-for="project_name">
                  <v-multiselect
                    v-model="formProject.selected_project"
                    placeholder="Search or add new project"
                    label="projectName"
                    track-by="id"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :options="projects"
                    @search-change="searchProjects"
                    required
                  >
                    <span slot="noResult" @click="doAddProject">
                      Not Found. Click to add
                      <strong>{{ formProject.projectName }}</strong>
                    </span>
                  </v-multiselect>
                </b-form-group>

                <b-form-group
                  label="Project Location"
                  label-for="project_location"
                >
                  <b-form-input
                    id="port_name"
                    type="text"
                    placeholder="Project Location"
                    maxlength="30"
                    v-model="formProject.location"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Sub Project"
                  label-for="sub_project_name"
                >
                  <b-form-input
                    id="port_name"
                    type="text"
                    placeholder="Sub Project Name"
                    class="d-inline-block"
                    maxlength="30"
                    v-model="formProject.subName"
                  ></b-form-input>
                </b-form-group>
              </b-form>
            </fieldset>
          </b-col>
          <!-- COMPANY INFO -->
          <b-col lg="6" md='12'>
            <fieldset class="form-group border p-3" v-if="(form.customer_id && form.project_sub_id && project_type === 'existing') || (project_type === 'new_project' && form.customer_id)">
              <legend class="w-auto px-2 text-primary">Company Info</legend>
              <div class="d-flex w-100 overflow-auto ">
                <table class="table mb-0 table-striped">
                  <tbody>
                    <tr>
                      <th width="15%">Nama Perusahaan</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.account_name ?? '-' }}</td>
                      <th width="15%">Alamat</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.address ?? '-' }}</td>
                    </tr>
                    <tr>
                      <th width="15%">No. Telepon</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.phone1 ?? customerDetail?.phone2 ?? '-' }}</td>
                      <th width="15%">Email</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.email ?? '-' }}</td>
                    </tr>
  
                    <tr>
                      <th width="15%">Negara</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.country?.name ?? '-' }}</td>
                      <th width="15%">Provinsi</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.province ?? '-' }}</td>
                    </tr>
  
                    <tr>
                      <th width="15%">Kota</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.city ?? '-' }}</td>
                      <th width="15%">Kecamatan</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.district ?? '-' }}</td>
                    </tr>
  
                    <tr>
                      <th width="15%">Kelurahan</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.village ?? '-' }}</td>
                      <th width="15%"></th>
                      <td width="5%"></td>
                      <td width="30%"></td>
                    </tr>
  
                    <tr>
                      <th width="15%">PIC</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.name ?? '-' }}</td>
                      <th width="15%">No. Handphone</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.mobile1 ?? customerDetail?.mobile2 ?? '-' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </fieldset>
          </b-col>
        </b-row>
      </b-col>
      <!-- DETAIL PROJECT CONTRACT -->

      <b-col lg="12">
        <b-row>
          <!-- DETAIL PROJECT CONTRACT FORM -->
          <b-col 
            :lg="'12'"
            v-if="(form.customer_id && form.project_sub_id && project_type === 'existing') || (project_type === 'new_project' && form.customer_id)"
          >
            <fieldset class="form-group border p-3">
              <legend class="w-auto px-2 text-primary">Detail Project Contract Form</legend>
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <th width="15%">Fleets</th>
                    <td width="5%">:</td>
                    <td width="30%" colSpan="4">
                      <div class="d-flex flex-column" v-if="['ADD','EDIT'].includes(action)">
                        <b-row>
                          <b-col lg="3">
                            <span>Fleet ownership</span>
                            <b-form-select plain v-model="form.fleet_ownership" class="form-control" required name="fleet_ownership"
                              @change="onDataIntoPlan('fleet_ownership', form.fleet_ownership); onChangeOwnership(form.fleet_ownership)"
                            >
                              <option value="">Select ownership...</option>
                              <option value="own-ship">OWN SHIP</option>
                              <option value="chartered">CHARTERED</option>
                            </b-form-select>
                          </b-col>
                          <b-col lg="3"  v-if="form.fleet_ownership">
                            <span>Fleet Type</span>
                            <b-form-select plain v-model="form.fleet_type_id" :options="optionsFleetTypes" class="form-control" required name="fleet_type_id" @change="onChangeFleetType(false, null, null); onDataIntoPlan('fleet_type_id', form.fleet_type_id);">
                              <template v-slot:first>
                                <b-form-select-option :value="null">Select fleet type...</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-col>
                        </b-row>
                        <b-row v-if="form.fleet_type_id">
                          <b-col md="3" v-for="(obj, objIndex) in form.fleets" :key="objIndex">
                            <div class="d-flex mt-2 flex-column w-100">
                              <strong class="text-truncate">{{ showVehicleName(obj.vehicle_id) }}</strong>
                              <b-button variant="primary" size="sm" @click="onOpenSlideDetailVehicle(obj.vehicle_id, objIndex)">{{ obj.vehicle_id ? 'Change ' : 'Select ' }} fleet</b-button>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                      <div class="d-flex flex-column" v-else>
                        <b-row class="mb-2">
                          <b-col lg="3" class="mb-2">
                            <span>Fleet ownership</span> <br />
                            <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ form.fleet_ownership === 'own-ship' ? "OWN SHIP" : 'CHARTERED' }}</b-badge>
                          </b-col>
                          <b-col lg="3" v-if="form.fleet_ownership">
                            <span>Fleet Type</span> <br />
                            <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">Tug & Barge</b-badge>
                          </b-col>
                        </b-row>
                        <b-row v-if="form.fleet_type_id">
                          <b-col lg='12'>Fleets</b-col>
                          <b-col md="12" >
                            <div class="d-flex flex-warp">
                              <b-badge v-for="(objDetail, detailIndex) in form.fleets" :key="detailIndex" class="border border-primary text-primary text-uppercase mb-2 mr-2" variant="none">TB. TAVIA 00{{ detailIndex + 1 }}</b-badge>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th width="15%">Collection of Forms</th>
                    <td width="5%">:</td>
                    <td width="30%" colSpan="4" v-if="['ADD','EDIT'].includes(action)">
                      <v-multiselect 
                        v-if="optionsForms.length > 0"
                        v-model="form.project_forms"
                        :options="optionsForms" 
                        :multiple="true" 
                        :group-select="true" 
                        placeholder="Select Forms" 
                        track-by="label" 
                        label="label"
                      />     
                      <strong v-else class="text-muted">No forms yet.</strong>
                    </td>
                    <td width="30%" v-else>
                      <div class="d-flex flex-wrap gap-2">
                        <b-badge 
                          v-for="(state, index) in form.project_forms"
                          :key="index"
                          class="border border-primary text-primary text-uppercase m-1" 
                          variant="none"
                        >
                          {{ state?.label }}
                        </b-badge>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th width="15%">Periode Start</th>
                    <td width="5%">:</td>
                    <td width="30%">
                      <b-form-input
                        v-if="['ADD','EDIT'].includes(action)"
                        id="periode_start"
                        name="periode_start"
                        type="date"
                        v-model="form.periode_start"
                      ></b-form-input>
                      <span v-else>{{ form?.periode_start ? moment(form?.periode_start).format('DD MMMM YYYY') : '-' }}</span>
                    </td>
                    <th width="15%">Periode End</th>
                    <td width="5%">:</td>
                    <td width="30%">
                      <b-form-input
                        v-if="['ADD','EDIT'].includes(action)"
                        id="periode_end"
                        name="periode_end"
                        type="date"
                        v-model="form.periode_end"
                      ></b-form-input>
                      <span v-else>{{ form?.periode_start ? moment(form?.periode_start).format('DD MMMM YYYY') : '-' }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th width="15%">Contract Type</th>
                    <td width="5%">:</td>
                    <td width="30%">
                      <v-multiselect
                        v-if="['ADD','EDIT'].includes(action)"
                        v-model="form.contract_type_id"
                        placeholder="search/add contract"
                        label="name"
                        track-by="id"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :options="optionsContractType"
                        @search-change="searchContractType"
                        required
                      >
                        <span slot="noResult" @click="doAddContractType">
                          Not Found. Click to add
                          <strong>{{ contractTypeName }}</strong>
                        </span>
                      </v-multiselect>   
                      <span v-if="['ADD','EDIT'].includes(action) && form.contract_type_id">{{ form.contract_type_id?.is_spal ? "Yes, using spal" : 'Not using spal' }}</span>
                      <span v-else>{{ detailProjectContract?.contract_type?.name }}</span>
                     
                    </td>
                    <th width="15%">Cargo Type</th>
                    <td width="5%">:</td>
                    <td width="30%">
                      <b-form-select 
                        v-if="['ADD','EDIT'].includes(action)"
                        plain size="sm" :options="optionsCargoType" v-model="form.cargo_type_id" @change="onDataIntoPlan('cargo_type_id', form.cargo_type_id); onChangeCargoType()"> 
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Cargo Type</b-form-select-option>
                        </template>
                      </b-form-select>
                      <span v-else>{{ detailProjectContract?.cargo_type?.name ?? '-' }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th width="15%">Term</th>
                    <td width="5%">:</td>
                    <td width="30%">
                      <b-form-select 
                        v-if="['ADD','EDIT'].includes(action)"
                        plain size="sm"  :options="optionsTerms" v-model="form.terms_id" @change="onDataIntoPlan('terms_id', form.terms_id)" class="mb-2">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Term</b-form-select-option>
                        </template>
                      </b-form-select>
                      <b-form-textarea  v-if="['ADD','EDIT'].includes(action)" placeholder="Type Text" @change="onDataIntoPlan('terms_remarks', form.terms_remarks)"></b-form-textarea>
                      <span v-else>{{ detailProjectContract?.terms?.name ?? '-' }} <br /> Remarks: {{ form.terms_remarks }}</span>
                    </td> 
                    <th width="15%">Total Quantity</th>
                    <td width="5%">:</td>
                    <td width="30%">
                      <div class="d-flex align-items-center" v-if="form.cargo_type_id">
                        <span class="mr-2">{{ totalQuantityAdd?.toLocaleString() }}</span>
                        <strong v-if="optionUnit.length <= 0 && !unit_others">{{ form.unit }}</strong>
                        <b-form-select v-if="optionUnit.length > 0 && !unit_others" plain size="sm" v-model="form.unit" class="w-50"> 
                          <b-form-select-option :value="''">Select unit</b-form-select-option>
                          <b-form-select-option :value="obj.name" v-for="(obj, objIndex) in optionUnit">{{ obj?.name }}</b-form-select-option>
                        </b-form-select>
                        <b-form-input v-if="unit_others" size="sm" placeholder="Type Text" class="w-50" v-model="form.unit" />
                      </div>
                      <div v-else>
                        <i>Choose cargo type first.</i>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th width="15%">POL</th>
                    <td width="5%">:</td>
                    <td width="30%" v-if="['ADD','EDIT'].includes(action)">
                      <div class="d-flex align-items-center mb-2" v-for="(obj, polIndex) in form.pol" :key="polIndex">
                        <b-form-input
                          id="pol"
                          name="pol"
                          placeholder="Type Text ..."
                          v-model="obj.value"
                          class="mr-2"
                        ></b-form-input>
                        <i class="fa fa-plus text-success border border-success p-1 rounded-circle" style="cursor:pointer" @click="form.pol.push({value:''})" v-if="polIndex === 0" />
                        <i class="fa fa-trash text-danger border border-danger p-1 rounded-circle" style="cursor:pointer" @click="form.pol.splice(polIndex, 1)" v-else />
                      </div>
                      <td width="30%" v-else>
                        <div class="d-flex flex-column mb-2 w-100">
                          <span v-for="(obj, polIndex) in form.pol" :key="polIndex">{{polIndex + 1}}. {{ obj?.value ?? '-' }}</span>
                        </div>
                      </td>
                    </td>
                    <th width="15%">POD</th>
                    <td width="5%">:</td>
                    <td width="30%" v-if="['ADD','EDIT'].includes(action)">
                      <div class="d-flex align-items-center mb-2" v-for="(obj, podIndex) in form.pod" :key="podIndex">
                        <b-form-input
                          id="pol"
                          name="pol"
                          placeholder="Type Text ..."
                          v-model="obj.value"
                          class="mr-2"
                        ></b-form-input>
                        <i class="fa fa-plus text-success border border-success p-1 rounded-circle" style="cursor:pointer" @click="form.pod.push({value:''})" v-if="podIndex === 0" />
                        <i class="fa fa-trash text-danger border border-danger p-1 rounded-circle" style="cursor:pointer" @click="form.pod.splice(podIndex, 1)" v-else />
                      </div>
                    </td>
                    <td width="30%" v-else>
                      <div class="d-flex flex-column mb-2 w-100">
                        <span v-for="(obj, podIndex) in form.pod" :key="podIndex">{{podIndex + 1}}. {{ obj?.value ?? '-' }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th width="15%">Laycan</th>
                    <td width="5%">:</td>
                    <td width="30%">
                      <b-form-input
                        v-if="['ADD','EDIT'].includes(action)"
                        id="laycan"
                        name="laycan"
                        type="date"
                        v-model="form.laycan"
                      ></b-form-input>
                      <span v-else>{{ form?.laycan ? moment(form?.laycan).format('DD MMMM YYYY') : '-' }}</span>
                    </td>
                    <th width="15%">Distance</th>
                    <td width="5%">:</td>
                    <td width="30%">
                      <b-form-input
                        v-if="['ADD','EDIT'].includes(action)"
                        id="distance"
                        name="distance"
                        placeholder="Type Number ..."
                        type="number"
                        v-model="form.distance"
                      ></b-form-input>
                      <span v-else>{{ form?.distance }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th width="15%">Duration</th>
                    <td width="5%">:</td>
                    <td width="30%">
                      <b-form-input
                        v-if="['ADD','EDIT'].includes(action)"
                        id="duration"
                        name="duration"
                        placeholder="Type Number ..."
                        type="number"
                        v-model="form.duration"
                      ></b-form-input>
                      <span v-else>{{ form?.duration }}</span>
                    </td>
                    <th width="15%"></th>
                    <td width="5%"></td>
                    <td width="30%"></td>
                  </tr>
                  <tr>
                    <th width="15%">Freigh Rate (Est)</th>
                    <td width="5%">:</td>
                    <td width="30%" v-if="['ADD','EDIT'].includes(action)">
                      <div class="d-flex flex-column ">
                        <b-form-input
                          id="freight_rate_est"
                          name="freight_rate_est"
                          placeholder="Type Number ..."
                          v-model="form.freight_rate_est"
                          type="number"
                          class="mb-2"
                        ></b-form-input>
                        <small>{{ formatCurrency(form.freight_rate_est) }}</small>
                        <b-form-radio-group inline>
                          <b-form-radio inline value="include" v-model="form.ppn" required name="include" id="include">Include PPN</b-form-radio>
                          <b-form-radio inline value="exclude" v-model="form.ppn" required name="exclude" id="exclude">Exclude PPN</b-form-radio>
                        </b-form-radio-group>
                      </div>
                    </td>
                    <td width="30%" v-else>
                      <div class="d-flex flex-column">
                        <span>{{ formatCurrency(form?.freight_rate_est) }}</span>
                        <strong class="text-muted">{{ form.ppn == 'include' ? 'Include ' : 'Exclude ' }} PPN</strong>
                      </div>
                    </td>
                    <th width="15%">Freigh Rate</th>
                    <td width="5%">:</td>
                    <td width="30%">
                      <b-form-input
                        v-if="['ADD','EDIT'].includes(action)"
                        id="freight_rate"
                        name="freight_rate"
                        placeholder="Type Number ..."
                        v-model="form.freight_rate"
                        type="number"
                      ></b-form-input>
                      <small v-if="['ADD','EDIT'].includes(action)">{{ formatCurrency(form.freight_rate) }}</small>
                      <span v-else>{{ formatCurrency(form.freight_rate) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th width="15%">Remarks</th>
                    <td width="5%">:</td>
                    <td width="30%" colSpan="4">
                      <b-form-textarea
                        v-if="['ADD','EDIT'].includes(action)"
                        id="remarks"
                        name="remarks"
                        placeholder="Type Text ..."
                        v-model="form.remarks"
                      ></b-form-textarea>
                      <span v-else>{{ form.remarks }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th width="15%">Needs More Detail Plan ?</th>
                    <td width="5%">:</td>
                    <td width="30%">
                      <b-form-checkbox
                        name="check-button"
                        switch
                        inline
                        v-model="form.need_more_detail"
                        :disabled="['PREVIEW'].includes(action)"
                      >
                        Yes / No
                      </b-form-checkbox>
                    </td>
                    <th width="15%">Need Tender ?</th>
                    <td width="5%">:</td>
                    <td width="30%">
                      <b-form-checkbox
                        name="check-button"
                        switch
                        inline
                        v-model="form.is_tender"
                        :disabled="['PREVIEW'].includes(action)"
                      >
                        Yes / No
                      </b-form-checkbox>
                    </td>
                   
                  </tr>
                  <tr>
                    <th width="15%">Needs Pre-Fixtured Analysis?</th>
                    <td width="5%">:</td>
                    <td width="30%">
                      <b-form-checkbox
                        name="check-button"
                        switch
                        inline
                        v-model="form.is_pfa"
                        :disabled="['PREVIEW'].includes(action)"
                      >
                        Yes / No
                      </b-form-checkbox>
                    </td>
                    <th width="15%">Need Document?</th>
                    <td width="5%">:</td>
                    <td width="30%">
                      <b-form-checkbox
                        name="check-button"
                        switch
                        inline
                        v-model="form.need_document"
                        :disabled="['PREVIEW'].includes(action)"
                      >
                        Yes / No
                      </b-form-checkbox>
                    </td>
                  </tr>
                
                  <tr>
                    <th width="15%">Is this project needs approval ?</th>
                    <td width="5%">:</td>
                    <td width="30%">
                      <b-form-checkbox
                        name="check-button"
                        switch
                        inline
                        v-model="form.need_approval"
                        :disabled="['PREVIEW'].includes(action)"
                      >
                        Yes / No
                      </b-form-checkbox>
                    </td>
                    <th width="15%" v-if="form.need_approval">How much approval is needed?</th>
                    <td width="5%" v-if="form.need_approval">:</td>
                    <td width="30%" v-if="form.need_approval">
                      <b-form-select 
                        v-if="['ADD', 'EDIT'].includes(action)"
                        plain size="sm" v-model="form.many_approval" required @change="onChangeManyApproval"> 
                        <b-form-select-option value="">Select values....</b-form-select-option>
                        <b-form-select-option value="1">1</b-form-select-option>
                        <b-form-select-option value="2">2</b-form-select-option>
                        <b-form-select-option value="3">3</b-form-select-option>
                        <b-form-select-option value="4">4</b-form-select-option>
                      </b-form-select>
                      <span v-else>{{ form.many_approval }}</span>
                    </td>
                  </tr>  
                  <tr v-if="form.many_approval">
                    <th width="15%">User Approval</th>
                    <td width="5%">:</td>
                    <td width="30%" colSpan="4" v-if="['ADD', 'EDIT'].includes(action)">
                      <div class="d-flex align-items-center mb-2" v-for="(user, userIndex) in form.user_approval" :key="userIndex">
                        <strong>#{{ userIndex + 1}}</strong>
                        <v-select
                          label="text"
                          name="user"
                          v-model="user.user"
                          :options="optionsExpertUsers"
                          :reduce="
                            (optionsExpertUsers) => optionsExpertUsers.value
                          "
                          placeholder="Select User"
                          class="w-100 ml-2"
                          required
                        ></v-select>
                      </div>
                    </td>
                    <td width="30%" colSpan="4" v-else>
                      <div class="d-flex align-items-center mb-2" v-for="(user, userIndex) in detailProjectContract?.user_approval" :key="userIndex">
                        <strong class="mr-2">#{{ userIndex + 1 }}</strong>
                        <span>{{ user?.name }}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
          </b-col>
          <b-col lg="12">
            <b-row>
              <!-- TENDER -->
              <b-col lg="6" v-if="form.is_tender && form.customer_id && form.project_sub_id">
                <fieldset class="form-group border p-3">
                  <legend class="w-auto px-2 text-primary">Tender Form</legend>
                  <table class="table table-striped">
                    <tbody>
                      <tr>
                        <th width="15%">PIC</th>
                        <td width="5%">:</td>
                        <td width="30%">
                          <b-form-input v-if="['ADD', 'EDIT'].includes(action)" placeholder="Type Text" required v-model="form.formTender.pic" />
                          <span v-else>{{ form.formTender.pic }}</span>
                        </td>
                        <th width="15%">Url</th>
                        <td width="5%">:</td>
                        <td width="30%">
                          <b-form-input v-if="['ADD', 'EDIT'].includes(action)" placeholder="Type Text" required v-model="form.formTender.url" />
                          <span v-else>{{ form.formTender.url }}</span>
                        </td>
                      </tr>
                      <tr>
                        <th width="15%">Description</th>
                        <td width="5%">:</td>
                        <td colSpan="4">
                          <b-form-textarea v-if="['ADD', 'EDIT'].includes(action)" placeholder="Type Text" required v-model="form.formTender.description_of_tender" />
                          <span v-else>{{ form.formTender.description_of_tender }}</span>
                        </td>
                      </tr>
                      <tr>
                        <th width="15%">File</th>
                        <td width="5%">:</td>
                        <td  colSpan="4">
                          <b-form-file
                            v-if="['ADD', 'EDIT'].includes(action)"
                            plain
                            id="file"
                            v-model="form.formTender.file"
                            accept="image/*,application/*"
                            multiple
                            class="form-control"
                          ></b-form-file>
                          <img v-else src="../../../assets/images/activity/pdf.png" alt="img-image" style="width: 40px; height: 40px;" v-else />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </b-col>
              <!-- DOCUMENT ATTACHMENT -->
              <b-col lg="6" v-if="form.need_document && form.customer_id && form.project_sub_id">
                <fieldset class="form-group border p-3">
                  <legend class="w-auto px-2 text-primary">Document Attachment</legend>
                  <div class="overflow-auto">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th class="text-center" style="vertical-align:middle; width: 20px">NO</th>
                          <th class="text-center" style="vertical-align:middle;" >Name of File / Document</th>
                          <th class="text-center" style="vertical-align:middle;">File / Document</th>
                          <th class="text-center" style="vertical-align:middle; width: 20px">#</th>
                        </tr>
                      </thead>
                      <tbody v-if="['ADD', 'EDIT'].includes(action)"> 
                        <tr v-for="(state, index) in form.form_document" :key="index">
                          <th class="text-center align-top">#{{ index + 1 }}</th>
                          <td>
                            <b-form-input placeholder="Name of File" v-model="state.filename" required />
                          </td>
                          <td>
                            <b-form-file
                              :required="state?.url ? false : true"
                              :id="'fileSupport'+index"
                              placeholder="Choose a file"
                              accept="application/pdf"
                              v-model="state.file"
                              >
                            </b-form-file>
                            <a :href="state?.url" class="text-primary">{{ state?.filename }}.{{ state?.extension }}</a>
                          </td>
                          <td class="text-center align-top">
                            <b-button size="sm" variant="danger" @click="form.form_document.splice(index, 1)" v-if="index !== 0"><i class="fa fa-trash pr-0"></i></b-button>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                          <tr v-for="(state, index) in detailProjectContract.project_document" :key="index">
                            <td class="text-center align-top">{{ index + 1 }}</td>
                            <td>
                              <span>{{ state?.filename }}</span>
                            </td>
                            <td class="text-center">
                              <a :href="state?.url" class="text-primary">{{ state?.filename }}.{{ state?.extension }}</a>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                  <b-button v-if="['ADD', 'EDIT'].includes(action)" block variant="success" @click="addFormDocument()"><i class="fa fa-plus pr-0"></i> ADD MORE ATTACHMENT</b-button>
                </fieldset>
              </b-col> 
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <!-- DETAIL PLAN -->
      <b-col lg="12" v-if="form.need_more_detail && form.customer_id && form.project_sub_id">
        <fieldset class="form-group border p-3">
          <legend class="w-auto px-2 text-primary">Detail Project Contract Plan</legend>
          <div class="overflow-auto">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="text-center" rowspan="2" style="vertical-align:middle;">NO</th>
                  <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 350px;" >DESCRIPTION</th>
                  <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 250px;">FLEET</th>
                  <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 300px;">CARGO TYPE</th>
                  <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 300px;">LOCATION</th>
                  <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 250px;">SHIPMENT (MONTH)</th>
                  <th class="text-center" colspan="2" style="min-width: 250px;">PLAN DATE</th>
                  <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 250px;">PLAN QUANTITY</th>
                  <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 250px;">REMARKS</th>
                  <th class="text-center" rowspan="2" style="vertical-align:middle;">ACTION</th>
                </tr>
                <tr>
                  <th class="text-center" style="min-width: 250px;">ETA POL</th>
                  <th class="text-center" style="min-width: 250px;">ETA POD</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(state, index) in form.detail_plan" :key="index">
                  <th class="text-center align-top">#{{ index + 1 }}</th>
                  <td class="text-center align-top">
                    <b-form-textarea v-if="['ADD', 'EDIT'].includes(action)" placeholder="Type Text" type="text" id="description" name="description" v-model="state.description" size="sm" rows="4"></b-form-textarea>
                    <span v-else>{{ state?.description ?? '-' }}</span>
                  </td>
                  <td class="text-left align-top" v-if="['ADD', 'EDIT'].includes(action)">
                    <b-row class="mb-2">
                      <b-col lg="12" class="mb-2">
                        <span>Fleet ownership</span>
                        <b-form-select plain v-model="state.fleet_ownership" class="form-control" required name="fleet_ownership"
                          @change="onChangeOwnership(state.fleet_ownership)"
                        >
                          <option value="">Select ownership...</option>
                          <option value="own-ship">OWN SHIP</option>
                          <option value="chartered">CHARTERED</option>
                        </b-form-select>
                      </b-col>
                      <b-col lg="12" v-if="state.fleet_ownership">
                        <span>Fleet Type</span>
                        <b-form-select plain v-model="state.fleet_type_id" class="form-control" required name="fleet_type_id">
                          <option :value="null">Select fleet type...</option>
                          <option :value="1">Tug & Barge</option>
                          <option :value="2">Mother Vessel</option>
                          <option :value="3">Floating Crane</option>
                          <option :value="4">Floating Barge (Oil Barge)</option>
                          <option :value="5">Pusher Tug & Pusher Barge / Barge</option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                    <b-row v-if="state.fleet_type_id">
                      <b-col md="12" v-for="(objDetail, detailIndex) in state.fleets" :key="detailIndex">
                        <div class="d-flex mt-2 flex-column w-100">
                          <strong class="text-truncate">{{ showVehicleName(objDetail.vehicle_id) }}</strong>
                          <b-button variant="primary" size="sm" @click="onOpenSlideDetailVehicle(objDetail?.vehicle_id, detailIndex)">{{ objDetail.vehicle_id ? 'Change ' : 'Select ' }} fleet</b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </td>
                  <td class="text-left align-top" v-else>
                    <b-row class="mb-2">
                      <b-col lg="12" class="mb-2">
                        <span>Fleet ownership</span> <br />
                        <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ state.fleet_ownership === 'own-ship' ? "OWN SHIP" : 'CHARTERED' }}</b-badge>
                      </b-col>
                      <b-col lg="12" v-if="state.fleet_ownership">
                        <span>Fleet Type</span> <br />
                        <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">Tug & Barge</b-badge>
                      </b-col>
                    </b-row>
                    <b-row v-if="state.fleet_type_id">
                      <b-col lg='12'>Fleets</b-col>
                      <b-col md="12" v-for="(objDetail, detailIndex) in state.fleets" :key="detailIndex">
                        <b-badge class="border border-primary text-primary text-uppercase mb-2" variant="none">TB. TAVIA 00{{ detailIndex + 1 }}</b-badge>
                      </b-col>
                    </b-row>
                  </td>
                  <td class="text-left align-top">
                    <b-form-group class="mb-2" label="Cargo Type" label-for="cargo_type_id">
                      <b-form-select v-if="['ADD', 'EDIT'].includes(action)" plain size="sm"  :options="optionsCargoType" v-model="state.cargo_type_id">
                        <b-form-select-option :value="null">Select Cargo Type</b-form-select-option>
                      </b-form-select>
                      <b-badge v-else class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ cargoTypeLabel(state.cargo_type_id) }}</b-badge>
                    </b-form-group>
                    <b-form-group class="mb-2" label="Term">
                      <b-form-select  v-if="['ADD', 'EDIT'].includes(action)"  plain size="sm"  :options="optionsTerms" v-model="state.terms_id">
                        <b-form-select-option :value="null">Select Term</b-form-select-option>
                      </b-form-select>
                      <b-badge v-else class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ termsLabel(state.terms_id) }}</b-badge>
                      <b-form-textarea v-if="['ADD', 'EDIT'].includes(action)" rows="2" placeholder="Text Type" class="mt-2" v-model="state.terms_remarks" /> 
                      <span v-else>Remarks: <span class="text-muted">{{ state?.terms_remarks ?? '-' }}</span></span>
                    </b-form-group>
                  </td>
                  <td class="text-left align-top">
                      <span>POL</span>
                      <template v-if="['ADD', 'EDIT'].includes(action)">
                        <div class="d-flex align-items-center mb-2 w-100" v-for="(obj, polIndex) in state.pol" :key="polIndex">
                          <b-form-input
                            id="pol"
                            name="pol"
                            placeholder="Type Text ..."
                            v-model="obj.value"
                            class="mr-2 w-100"
                          ></b-form-input>
                          <i class="fa fa-plus text-success border border-success p-1 rounded-circle" style="cursor:pointer" @click="state.pol.push({value:''})" v-if="polIndex === 0" />
                          <i class="fa fa-trash text-danger border border-danger p-1 rounded-circle" style="cursor:pointer" @click="state.pol.splice(polIndex, 1)" v-else />
                        </div>
                      </template>
                      <template v-else>
                        <div class="d-flex flex-column mb-2 w-100">
                          <span v-for="(obj, polIndex) in state.pol" :key="polIndex">{{polIndex + 1}}. {{ obj?.value ?? '-' }}</span>
                        </div>
                      </template>
                      <div class="d-flex w-100 border mb-2" />
                      <!-- <b-form-input type="text" id="pol" size="sm" placeholder="Type Text" ></b-form-input> -->
                      <span>POD</span>
                      <template v-if="['ADD', 'EDIT'].includes(action)">
                        <div class="d-flex align-items-center mb-2 w-100" v-for="(obj, podIndex) in state.pod" :key="podIndex">
                          <b-form-input
                            id="pol"
                            name="pol"
                            placeholder="Type Text ..."
                            v-model="obj.value"
                            class="mr-2 w-100"
                          ></b-form-input>
                          <i class="fa fa-plus text-success border border-success p-1 rounded-circle" style="cursor:pointer" @click="state.pod.push({value:''})" v-if="podIndex === 0" />
                          <i class="fa fa-trash text-danger border border-danger p-1 rounded-circle" style="cursor:pointer" @click="state.pod.splice(podIndex, 1)" v-else />
                        </div>
                      </template>
                      <template v-else>
                        <div class="d-flex flex-column mb-2 w-100">
                          <span v-for="(obj, podIndex) in state.pod" :key="podIndex">{{podIndex + 1}}. {{ obj?.value ?? '-' }}</span>
                        </div>
                      </template>
                  </td>
                  <td class="text-center align-top">
                    <b-form-select v-if="['ADD', 'EDIT'].includes(action)"  plain size="sm"  v-model="state.shipment">
                      <b-form-select-option value="">SELECT MONTH</b-form-select-option>
                      <b-form-select-option value="JANUARY">JANUARI</b-form-select-option>
                      <b-form-select-option value="FEBRUARI">FEBRUARI</b-form-select-option>
                      <b-form-select-option value="MARET">MARET</b-form-select-option>
                      <b-form-select-option value="APRIL">APRIL</b-form-select-option>
                      <b-form-select-option value="MEI">MEI</b-form-select-option>
                      <b-form-select-option value="JUNI">JUNI</b-form-select-option>
                      <b-form-select-option value="JULI">JULI</b-form-select-option>
                      <b-form-select-option value="AGUSTUS">AGUSTUS</b-form-select-option>
                      <b-form-select-option value="SEPTEMBER">SEPTEMBER</b-form-select-option>
                      <b-form-select-option value="OKTOBER">OKTOBER</b-form-select-option>
                      <b-form-select-option value="NOVEMBER">NOVEMBER</b-form-select-option>
                      <b-form-select-option value="DESEMBER">DESEMBER</b-form-select-option>
                    </b-form-select>
                    <span v-else>{{ state?.shipment }}</span>
                  </td>
                  <td class="text-center align-top" colspan="2">
                    <b-row>
                      <b-col md="6">
                        <b-form-input v-if="['ADD', 'EDIT'].includes(action)"  type="date" id="eta_pol" v-model="state.eta_pol" class="mb-1" size="sm" ></b-form-input>
                        <span v-else>{{ state.eta_pol ? moment(state.eta_pol).format('DD MMMM YYYY') : '-' }}</span>
                      </b-col>
                      <b-col md="6">
                        <b-form-input v-if="['ADD', 'EDIT'].includes(action)"  type="date" id="eta_pod" v-model="state.eta_pod" class="mb-1" size="sm" ></b-form-input>
                        <span v-else>{{ state.eta_pod ? moment(state.eta_pod).format('DD MMMM YYYY') : '-' }}</span>
                      </b-col>
                    </b-row>
                  </td>
                  <td class="text-center align-top">
                    <b-form-input v-if="['ADD', 'EDIT'].includes(action)" type="number" id="plan_quantity" v-model="state.plan_quantity" size="sm"  placeholder="0" required></b-form-input>
                    <span v-else>{{ state.plan_quantity.toLocaleString() }}</span>
                  </td>
                  <td class="text-center align-top">
                    <b-form-textarea v-if="['ADD', 'EDIT'].includes(action)" rows="4" placeholder="Comments if any / Komentar jika ada" v-model="state.remarks" name="remarks"></b-form-textarea>
                    <span v-else>{{ state?.remarks ?? '-' }}</span>
                  </td>
                  <td class="text-center align-top">
                    <b-button size="sm" variant="danger" @click="form.detail_plan.splice(index, 1)" v-if="index !== 0 || ['ADD', 'EDIT'].includes(action)"><i class="fa fa-trash pr-0"></i></b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <b-button v-if="['ADD', 'EDIT'].includes(action)" block variant="success" @click="addDetailPlanData(false)"><i class="fa fa-plus pr-0"></i> ADD MORE PLAN</b-button>
        </fieldset>
      </b-col>

      <b-col md="12">
        <!-- SLIDE - PREVIEW DETAIL FLEETS -->
        <b-sidebar
          v-model="slidePreviewDetailVehicle"
          id="detail_vehicle"
          title="Detail Fleet"
          width="100%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{hide}">
            <SlidePreviewDetailVehicle 
              :options-fleets="optionsFilteredFleets" 
              :fleet="selectedFleet" 
              :fleet-type-id="form.fleet_type_id" 
              @updateVehicleId="updateVehicleId" 
              :ownership="form.fleet_ownership" 
              :on-open-slide-detail-vehicle="onOpenSlideDetailVehicle"
            />
          </template>
        </b-sidebar>
        <!-- SLIDE - PREVIEW DETAIL FLEETS END -->
      </b-col>
    </b-row>
  </form>
  <div v-else class="alert alert-info m-4">
    Loading...
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { projectsActions, companiesActions, fleetsActions, busdevActions, crewingActions } from '@src/Utils/helper'
import SlidePreviewDetailVehicle from './SlidePreviewDetailVehicle.vue'

export default {
  name: 'FormAddPlan',
  props:{
    action:{
      type:String,
      default:''
    },  
    editId:{
      type:Number,
      default:true
    }, 
    openCustomerSlide:{
      type:Function,
      default:true
    }, 
    refreshDataTable:{
      type:Function,
      default:true
    }, 
  },
  data() {
    return {
      saving: false,
      loading:false,
      fleet_loading:false,
      detail_project_loading:false,
      isEdit:false,

      selectedFleet:null,
      fleetIndex:null,

      slidePreviewDetailVehicle:false,

      projectOptions: [],
      optionsFleetTypes: [],
      optionsListCustomer: [],
      optionsTerms: [],
      optionsFleets: [],
      optionsFilteredFleets: [],
      optionsContractType: [],
      optionsCargoType: [],
      optionsExpertUsers: [],
      optionsForms: [],
      optionUnit: [],
      unit_others: false,
      listProjectStage:[],

      customerDetail:null,
      projectDetail:null,
      
      selectedCustomerData: null,

      project_type:'existing',

      detailProjectContract:null,
      
      // COMPANY
      company: [
        {
          name: 'single',
          label: 'EXISTING PROJECT',
          value: 'existing',
          disabled: false
        },
        {
          name: 'single',
          label: 'NEW PROJECT',
          value: 'new_project',
          disabled: false
        }
      ],

      // OWNERSHIP
      ownership: [
        {
          name: 'ownship',
          label: 'OWN SHIP',
          value: 'own-ship',
          disabled: false
        },
        {
          name: 'chartered',
          label: 'CHARTERED',
          value: 'chartered',
          disabled: false
        }
      ],

      projects:[],
      formProject: {
        selected_project: null,
        projectName: '',
        location:'',
        subName:'',
      },
      contractTypeName:'',
      form: {
        "active": 1, // 1 = active , 0 = Inactive
        "created_by": JSON.parse(localStorage.getItem('profile')).id,
        "created_by_email": JSON.parse(localStorage.getItem('profile')).email,
        "created_name": JSON.parse(localStorage.getItem('profile')).userDetail.name,
        "created_date": moment().format('YYYY-MM-DD'),
        "update_by":null,
        "update_date": "",
        "status":"proposed", // proposed, running, waiting, process

        customer_id:null,
        "project_sub_id":null,
        "contract_type_id":null,
        "cargo_type_id":null,
        "total_quantity":null,
        "unit":'',
        "detail_project_stage_id":null,
        "history_project_id":null,
        "terms_id":null,
        "terms_remakrs":'',
        "periode_start":null,
        "periode_end":null,
        "fleet_ownership":"",
        "fleet_type_id":"",
        "fleets":[],
        "laycan":"",
        "pol":[{value:''}],
        "pod":[{value:''}],
        "distance":"",
        "duration":"",
        "freight_rate_est":null,
        "freight_rate":null,
        "ppn":'exclude',
        "remarks":"",

        is_tender: false,
        need_more_detail:true,
        need_approval:true,
        need_document:true,
        is_pfa:false,
        many_approval:'1',
        user_approval:[{
          user:null
        }],
        project_forms:[],

        form_document: [
          {
            filename:'',
            file:null
          }
        ],
        formTender:{
          pic:'',
          url:'',
          description_of_tender:'',
          file:''
        },
        detail_plan:[
          {
            "description":"",
            "fleet_ownership":"",
            "fleet_type_id":null,
            "fleets":[],
            "cargo_type_id":null,
            "terms_id":null,
            "terms_remarks":'',
            "pol":[{value:''}],
            "pod":[{value:''}],
            "shipment":"",
            "eta_pol":"",
            "eta_pod":"",
            "plan_quantity":null,
            "remarks":""
          }
        ],
      },
    }
  },
  components:{
    SlidePreviewDetailVehicle
  },
  async mounted () {
    await this.getListCustomer()
    await this.getContractType()
    await this.getVehicleType()
    await this.getCargoType()
    await this.getTerms()
    await this.fetchListProjects()
    await this.getProjectList()
    await this.fetchFleets()
    await this.getListCollectionForm()
    await this.fetchDataUserExpert()
    await this.getListProjectStage()
  },
  computed: {
    totalQuantityAdd() {
      return this.form.detail_plan.reduce((total, item) => total + Number(item.plan_quantity), 0);
    },

  },
  watch: {
    project_type: {
      deep: true,
      handler: function (value) {
        if (value) {
          this.resetFormPlan()
        }
      },
    },
    editId: {
      deep: true,
      handler: function (value) {
        if (value) {
          this.getDetailProjectContract(value)
        }
      },
    },
    action: {
      deep: true,
      handler: function (value) {
        if (value === "ADD") {
          this.resetFormPlan()
        }
      },
    }
  },
  methods: {
    ...projectsActions,
    ...fleetsActions,
    ...companiesActions,
    ...crewingActions,
    ...busdevActions,
    changeAction(val) {
      // Emit an event to notify the parent component
      console.log(val, "WHAT ACTION IS THIS")
      this.$emit('updateAction', val);
    },
    onOpenSlideDetailVehicle(vehicle_id, index){
      this.slidePreviewDetailVehicle = !this.slidePreviewDetailVehicle

      this.selectedFleet = vehicle_id
      this.fleetIndex = index
    },
    updateVehicleId(newVehicleId) {
      // Update vehicleId when received from the child component
      this.form.fleets[this.fleetIndex].vehicle_id = newVehicleId
      this.form.detail_plan[0].fleets[this.fleetIndex].vehicle_id = newVehicleId

      console.log(this.form.detail_plan, "DETAIL PLAN")
    },
    onChangeFleetType(isDetail, fleetType, index){
      if(isDetail){
        let fleets = []
        let findTow = this.optionsFleetTypes.find(obj => obj.value == fleetType)
        if(findTow?.vehicleTypeTow.length > 0){
          findTow.vehicleTypeTow.map(tow => {
            fleets.push({
              vehicle_id:null
            })
          })
        } else {
          fleets.push({
            vehicle_id:null
          })
        }

        this.form.detail_plan[index].fleets = fleets
      } else {
        this.form.fleets = []
        let fleetsTypes = []
        let findTow = this.optionsFleetTypes.find(obj => obj.value == this.form.fleet_type_id)
        fleetsTypes.push(findTow.id)
        if(findTow?.vehicleTypeTow?.length > 0){
          findTow.vehicleTypeTow.map(tow => {
            fleetsTypes.push(tow.id)
            this.form.fleets.push({
              vehicle_id:null
            })
          })
        } else {
          this.form.fleets.push({
            vehicle_id:null
          })
        }

        if(fleetsTypes.length > 0) this.optionsFilteredFleets = this.optionsFleets.filter(fleet => fleetsTypes.includes(fleet.vehicle_type_id));
        console.log(fleetsTypes, "fleetsTypes")
        console.log(this.optionsFilteredFleets, "WHAT IS FLEET IS THIS")
        this.form.detail_plan[0].fleets = this.form.fleets
      }
    },
    showVehicleName(value){
      // let vehicle = ''
      let vehicle = this.optionsFleets.find(obj => obj.value == value)

      return vehicle?.text ?? 'Select fleet first'
    },
    userExpertName(id){
      let user = this.optionsExpertUsers.find(obj => obj.value === id)

      return user?.text
    },
    cargoTypeLabel(val){
      let label = this.optionsCargoType.find(obj => obj.id === val)

      return label?.name
    },
    termsLabel(val){
      let label = this.optionsTerms.find(obj => obj.id === val)

      return label?.text
    },
    onChangeCargoType(){
      if(this.form.cargo_type_id){
        let cargoUnit = this.optionsCargoType.find(obj => obj.id === Number(this.form.cargo_type_id))

        if(cargoUnit.unit.child.length > 0){
          this.form.unit = cargoUnit.unit.child[0].name
          this.optionUnit = cargoUnit.unit.child
          this.unit_others = false
        } else if (cargoUnit.unit.alias === 'others'){
          this.optionUnit = []
          this.form.unit = ''
          this.unit_others = true
        } else {
          this.optionUnit = []
          this.form.unit = cargoUnit.unit.name
          this.unit_others = false
        }
      }
    },
    onChangeManyApproval(){
      this.form.user_approval = []
      let many = Number(this.form.many_approval)

      for(let i = 0; i < many; i++){
        this.form.user_approval.push({
          user:null
        })
      }
    },
    onDataIntoPlan(key, value){
      this.form.detail_plan[0][key] = value
    },
    onSelectOwnership(){},
    vehicleName(id){
      let vehicle = this.optionsFleets.find(obj => obj.value == id)

      return vehicle?.text
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
    refreshData(){
      this.project_type = 'existing'
      this.getListCustomer()
      this.refreshDataTable()
    },
    onSelectedCustomer(){
      const customer = this.optionsListCustomer.find(obj => obj.value === this.form.customer_id)

      if(customer){
        this.customerDetail = customer
      }
    },
    onSelectedProject(){
      const project = this.projectOptions.find(obj => obj.value === this.form.project_sub_id)

      if(project){
        this.projectDetail = project
      }
    }, 
    addFormDocument(){
      this.form.form_document.push({
          filename:'',
          file:null
      })
    },
    addDetailPlanData(){
      this.form.detail_plan.push({
        "description":"",
        "fleet_ownership":this.form.fleet_ownership ?? '',
        "fleets":this.form.fleets ?? null,
        "cargo_type_id":this.form.cargo_type_id ?? null,
        "terms_id":this.form.terms_id ?? null,
        "pol":this.form.pol ?? '',
        "pod":this.form.pod ?? '',
        "shipment":"",
        "eta_pol":"",
        "eta_pod":"",
        "plan_quantity":null,
        "remarks":""
      })
    },
    resetFormPlan(){
      this.formProject = {
        selected_project: null,
        projectName: '',
        location:'',
        subName:'',
        "active": 1, // 1 = active , 0 = Inactive
        "created_by": JSON.parse(localStorage.getItem('profile')).id,
        "created_date": moment().format('YYYY-MM-DD'),
        "update_by":null,
        "update_date": "",
        "status":"proposed", // proposed, running, waiting, process
      }
      this.projects = []
      this.contractTypeName = '',
      this.form = {
        ...this.form,
        "active": 1, // 1 = active , 0 = Inactive
        "created_by": JSON.parse(localStorage.getItem('profile')).id,
        "created_by_email": JSON.parse(localStorage.getItem('profile')).email,
        "created_name": JSON.parse(localStorage.getItem('profile')).userDetail.name,
        "created_date": moment().format('YYYY-MM-DD'),
        "update_by":null,
        "update_date": "",
        "status":"proposed", // proposed, running, waiting, process

        customer_id:null,
        "project_sub_id":null,
        "contract_type_id":null,
        "cargo_type_id":null,
        "detail_project_stage_id":null,
        "history_project_id":null,
        "terms_id":null,
        "terms_remarks":'',
        "periode_start":null,
        "periode_end":null,
        "fleet_ownership":"",
        "fleets":"",
        total_quantity:null,
        unit:'',
        "laycan":"",
        "pol":[{value:''}],
        "pod":[{value:''}],
        "distance":"",
        "duration":"",
        "freight_rate_est":"",
        "freight_rate":"",
        "ppn":'exclude',
        "remarks":"",

        is_tender: false,
        need_more_detail:true,
        need_approval:true,
        need_document:true,
        is_pfa:false,
        many_approval:'1',
        user_approval:[{user:null}],
        form_document: [
          {
            filename:'',
            file:null
          }
        ],
        formTender:{
          pic:'',
          url:'',
          description_of_tender:'',
          file:''
        },
        detail_plan:[
          {
            "description":"",
            "fleet_ownership":"",
            "fleet_type_id":null,
            "fleets":[],
            "cargo_type_id":null,
            "terms_id":null,
            "terms_remarks":'',
            "pol":[{value:''}],
            "pod":[{value:''}],
            "shipment":"",
            "eta_pol":"",
            "eta_pod":"",
            "plan_quantity":null,
            "remarks":""
          }
        ],
      }

      if(this.project_type == 'existing') {
        this.form.is_pfa = false
      } else {
        this.form.is_pfa = true
      }

      this.getListCollectionForm()
    },
    async onChangeOwnership(value){
      let ownership = value === 'own-ship' ? 'OWNED' : 'CHARTERED'
      this.form.fleet_type_id = null
      await this.fetchFleets(ownership)
    },
    searchContractType (query) {
      this.contractTypeName = query
    },
    async doAddContractType () {
      let payload = {
        name:this.contractTypeName,
        alias:this.contractTypeName,
        status:1
      }
      let res = await this.createContractType({data: payload})
      if (res.data.status == true) {
        this.form.contract_type_id = res.data.data
        this.getContractType()
      } else {
        if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    async doAddProject () {
      let payload = {
        project_name:this.formProject.projectName ,
        active: this.formProject.active,
        created_by: Number(this.formProject.created_by),
        created_date: this.formProject.created_date,
      }
      let res = await this.createProject({data: payload})
      console.log(res, "PROJECT RESPONSES")
      if (res.status == 'success') {
        this.formProject.selected_project = {
          id:res.data.data.id,
          projectName: res.data.data.project_name
        }
        this.fetchListProjects()
      } else {
        if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    searchProjects (query) {
      this.formProject.projectName = query
    },

    // FETCHING DATA EDIT & DETAIL PLAN
    async getDetailProjectContract(id){
      this.detail_project_loading = true

      let res = await this.fetchDetailProjectContract({id})

      if (res.data.status === true) {
        this.detailProjectContract = res.data.data

        const customer = this.optionsListCustomer.find(obj => obj.value === this.detailProjectContract.customer_id)
        const project = this.projectOptions.find(obj => obj.value === this.detailProjectContract.project_sub_id)
        if(project) this.projectDetail = project
        if(customer) this.customerDetail = customer

        this.form = {
          ...this.form, 
          "created_by": JSON.parse(localStorage.getItem('profile')).id,
          "created_by_email": JSON.parse(localStorage.getItem('profile')).email,
          "created_name": JSON.parse(localStorage.getItem('profile')).userDetail.name,
          "created_date": moment().format('YYYY-MM-DD'),
          customer_id: this.detailProjectContract.customer_id,
          "project_sub_id":this.detailProjectContract.project_sub_id,
          "contract_type_id":this.detailProjectContract.contract_type,
          "cargo_type_id":this.detailProjectContract.cargo_type_id,
          "terms_id":this.detailProjectContract.terms_id,
          "terms_remarks":this.detailProjectContract.terms_remarks,
          "periode_start":moment(this.detailProjectContract.periode_start).format('YYYY-MM-DD'),
          "periode_end":moment(this.detailProjectContract.periode_end).format('YYYY-MM-DD'),
          "fleet_ownership":this.detailProjectContract.fleet_ownership,
          "fleets":this.detailProjectContract.fleets,
          "fleet_type_id":this.detailProjectContract.fleet_type_id,
          "laycan":this.detailProjectContract.laycan,
          "pol":this.detailProjectContract.pol,
          "pod":this.detailProjectContract.pod,
          "distance":this.detailProjectContract.distance,
          "duration":this.detailProjectContract.duration,
          "unit":this.detailProjectContract.unit,
          "total_quantity":this.detailProjectContract.total_quantity,
          "freight_rate_est":this.detailProjectContract.freight_rate_est,
          "freight_rate":this.detailProjectContract.freight_rate,
          "ppn":this.detailProjectContract.ppn,
          "remarks":this.detailProjectContract.remarks,
          is_tender: this.detailProjectContract.is_tender ? true : false,
          need_more_detail: this.detailProjectContract.need_more_detail ? true : false,
          need_approval: this.detailProjectContract.need_approval ? true : false,
          need_document: this.detailProjectContract.need_document ? true : false,
          many_approval: this.detailProjectContract.user_approval.length.toString(),
          is_pfa: this.detailProjectContract.is_pfa ? true : false,
          detail_plan: this.detailProjectContract?.project_contract_plan?.length > 0 
                      ? this.detailProjectContract?.project_contract_plan 
                      : [{
                          "description":"",
                          "fleet_ownership":"",
                          "fleet_type_id":null,
                          "fleets":[],
                          "cargo_type_id":null,
                          "terms_id":null,
                          "terms_remarks":'',
                          "pol":[{value:''}],
                          "pod":[{value:''}],
                          "shipment":"",
                          "eta_pol":"",
                          "eta_pod":"",
                          "plan_quantity":null,
                          "remarks":""
                      }],
          user_approval: this.detailProjectContract?.user_approval?.length > 0 ? this.detailProjectContract?.user_approval.map(val => {
            return {
              user: val.id
            }
          }) : [{user:null}],
          project_forms: this.detailProjectContract?.project_forms?.length > 0 ? this.detailProjectContract?.project_forms?.map(val => {
            return {
              ...val,
              label: `${val.code}/${val.name}`
            }
          }) : [],
        }

        console.log(this.form.user_approval, "USER APPROVAL")

        if(this.form.is_tender){
          this.form.formTender = {
            pic:this.detailProjectContract.project_tender.pic,
            url:this.detailProjectContract.project_tender.url,
            description_of_tender:this.detailProjectContract.project_tender.description_of_tender,
            file:null
          }
        }

        if(this.form.need_document){
          this.form.form_document = this.detailProjectContract.project_document
        }
      } else {
        this.detailProjectContract = null
      }
      this.detail_project_loading = false
    },

    // FETCHING MASTER DATA
    async getListCustomer (){
      this.optionsListCustomer = []
      let params = {  
        page:1,
        perPage:100000,
        status:1
      }

      let res = await this.fetchCustomer(params)

      if (res.data.status === true && res.data.data?.data.length > 0) {
        res.data.data?.data.map(val => {
          this.optionsListCustomer?.push({
            ...val,
            value: val.id,
            text:`${val.customer_code} - ${val.account_name}`
          })
        })
      }
    }, 
    async getListCollectionForm() {
      this.optionsForms = []
      let res = await this.fetchOptionsCollectionForm()

      if (res?.data?.status === true && res?.data?.data?.length > 0) {
        this.optionsForms = res.data.data.map(val => {
          return {
            ...val,
            label: `${val.code}/${val.name}`
          }
        })

        let selectedForms = this.optionsForms?.filter(obj => obj.default_for_shipment_planning === 1)

        if(this.action == 'ADD') this.form.project_forms = selectedForms
      } 
    },  
    
    async getVehicleType() {
      this.optionsFleetTypes = []
      let {status, data} = await this.getFleetTypes()

      if (status === 'success') {
        this.optionsFleetTypes = data.map(val => {
          return {
            ...val,
            value:val.id,
            text:val.name
          }
        })
      } 
    }, 
    
    async fetchDataUserExpert() {
      let params = {
        page: 1,
        active: true,
        perPage: 200,
      }
      const { status, data } = await this.fetchUserExpert(params)

      if (status) {
        if (data && data.length > 0) {
          data.map((state) => {
            this.optionsExpertUsers.push({
              value: state.id,
              text: state.userDetail.name,
              email: state.email,
            })
          })
        }
      }
    },
    async getTerms() {
      let res = await this.fetchTerms()
      if (res.data.status === true && res.data.data.length > 0) {
        res.data.data.map((state) => {
          this.optionsTerms.push({
            value: state.id,
            text: state.name,
          })
        })
      }
    },  
    async getCargoType() {
      let res = await this.fetchCargoType()
      if (res.data.status === true && res.data.data.length > 0) {
        res.data.data.map((state) => {
          this.optionsCargoType.push({
            ...state,
            value: state.id,
            text: state.name,
          })
        })
      }
    }, 
    async getContractType() {
      let res = await this.fetchContractType()
      if (res.data.status === true && res.data.data.length > 0) {
        this.optionsContractType = res.data.data
      }
    },
    async getListProjectStage () {
      this.listProjectStage = []
      let res = await this.fetchDetailProjectStage()
      if (res?.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.listProjectStage = res.data.data
        }
      } 
    },
    async fetchListProjects () {
      this.projects = []
      let params = {
        perPage: 10000,
        page: 1
      }

      let res = await this.getProjects(params)
      if (res.data.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.projects = res.data.data
        }
      } 
    },
    async getProjectList () {
      const res = await this.fetchProjects()
      if (res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(val => {
            this.projectOptions.push({
              ...val,
              text: `${val.project.project_name} - ${val.name}`,
              value: val.id
            })
          })
        }
      }
    },
    async fetchFleets(ownership) {
      this.fleet_loading = true
      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
        ownership
      })

      if(!params.ownership) delete params.ownership
      
      let res = await this.getFleets(params)

      if (res.status) {
        if (res.data && res.data.length > 0) {
          this.optionsFleets = res.data.map((val) => ({
            text: val.name,
            value: val.id,
            vehicle_type_id: val.vehicleType.id,
            checked:false
          }))
          this.optionsFilteredFleets = this.optionsFleets
        }
      }

      this.fleet_loading = false
    },
    
    async onSavePlan(){
      this.saving = true

      let findProcessStage = this.listProjectStage.find(obj => obj.title == 'Process')

      if(!this.form?.contract_type_id?.id){
        this.$swal(`Oops.`, 'Contract type required.', 'warning')
        return 
      }

      let payload = {
        ...this.form,
        ...this.formProject,
        project_id:Number(this.form.project_sub_id),
        total_quantity: Number(this.totalQuantityAdd),
        detail_project_stage_id:Number(findProcessStage?.id),
        project_type: this.project_type,
        laycan: this.form.laycan ? moment(this.form.laycan).format('YYYY-MM-DD') : '',
        contract_type_id: Number(this.form.contract_type_id?.id),
        is_tender: this.form.is_tender ? 1 : 0,
        need_more_detail: this.form.need_more_detail ? 1 : 0,
        need_approval: this.form.need_approval ? 1 : 0,
        need_document: this.form.need_document ? 1 : 0,
        many_approval: Number(this.form.many_approval),
        is_pfa: this.form.is_pfa ? 1 : 0,
        detail_plan: this.form.need_more_detail ? this.form.detail_plan : [],
        project_forms: this.form.project_forms.map(val => {
          return {
            collection_forms_id:val?.id,
            inspection_checklist_id: null
          }
        }),
        user_approval: this.form.user_approval.map(val => {
          let userExpert = this.optionsExpertUsers.find(obj => obj.value == val.user)
          return {
            id: val.user,
            email:userExpert?.email,
            name:userExpert?.text,
            status_approval:''
          }
        })
      }
      payload.fleets = payload.fleets.map(val => {
        return{
          vehicle_id: val.vehicle_id,
          vehicle_name: this.vehicleName(val.vehicle_id)
        }
      })

      if(this.editId && this.action === 'EDIT'){
        let res = await this.updateProjectContract({data: payload, id: Number(this.detailProjectContract?.id)})

        if([500, 404, 403, 422].includes(res.status)){
          this.saving = false
          this.$swal(`Oops.`, res?.message ?? res?.data?.message ?? 'Something went wrong!', 'error')
          return
        }

        if(res?.data?.status === true){
          // if(payload?.is_tender){
          //   const formData = new FormData()
  
          //   formData.append('pic', this.form.formTender.pic)
          //   formData.append('url', this.form.formTender.url)
          //   formData.append('description_of_tender', this.form.formTender.description_of_tender)
          //   // if(this.form?.formTender?.file?.length > 0){
          //   //   this.form.formTender.file?.forEach((file, fileIndex) => {
          //   //     formData.append(`file[${fileIndex}]`, file);
          //   //   });
          //   // }
  
          //   await this.updateProjectContractTender({data: formData, id:Number(this.detailProjectContract?.id)})
          // }
          const promises = payload.user_approval.map(async val => {
            let payload = {
              "to":val.email,
              "name":`Dear, ${val.name}`,
              "subject":"Project Plan Edited",
              "instructions":`Edited by: ${JSON.parse(localStorage.getItem('profile')).userDetail.name}`,
              "intro":"Project Planning Edited.",
              "outro":"Thank you for reading this email, as you know this email is automatically",
              "buttonText":"Go to Dashboard",
              "project_contract_id": Number(this.detailProjectContract?.id),
              "url":"busdev/dashboard",
              "urlQuery":""
            }
  
            await this.sendEmailNotification({data: payload})
          })

          let otherPayloadEmail = {
            "to":payload.created_by_email,
            "name":`Dear, ${payload.created_name}`,
            "subject":"Project Plan Edited",
            "instructions":`Edited by: ${JSON.parse(localStorage.getItem('profile')).userDetail.name}`,
            "intro":"Project Planning Edited.",
            "outro":"Thank you for reading this email, as you know this email is automatically",
            "buttonText":"Go to Dashboard",
            "project_contract_id": Number(this.detailProjectContract?.id),
            "url":"busdev/dashboard",
            "urlQuery":""
          }
  
          await this.sendEmailNotification({data: otherPayloadEmail})
  
          Promise.all(promises)

          this.saving = false
          this.refreshData()
          this.resetFormPlan()
          this.$swal(`Success!`, `Edit Plan Success.`, 'success')
        } else {
          this.saving = false
          this.$swal(`Oops.`, res?.data?.message, 'error')
        }

      } else {
        let res = await this.createShipmentPlanning({data: payload})
  
        if([500, 404, 403, 422].includes(res.status)){
          this.saving = false
          this.$swal(`Oops.`, res?.message ?? res?.data?.message ?? 'Something went wrong!', 'error')
          return
        }
  
        if(res?.data?.status === true){
          if(payload?.is_tender){
            const formData = new FormData()
  
            formData.append('project_contract_id', res.data.data.id)
            formData.append('pic', this.form.formTender.pic)
            formData.append('url', this.form.formTender.url)
            formData.append('description_of_tender', this.form.formTender.description_of_tender)
            if(this.form.formTender.file && this.form.formTender.file?.length > 0){
              this.form.formTender.file?.forEach((file, fileIndex) => {
                formData.append(`file[${fileIndex}]`, file);
              });
            }
  
            await this.createProjectContractTender({data: formData})
          } 
          
          if(payload?.need_document){
            const formData = new FormData()
  
            if(this.form.form_document && this.form.form_document?.length > 0){
              formData.append('project_contract_id', res.data.data.id)
              this.form.form_document?.forEach((item, index) => {
                for (const property in item) {
                  formData.append(`document[${index}][${property}]`, item[property])
                }
              });
            }
  
            await this.createProjectContractDocument({data: formData})
          }
  
          const promises = payload.user_approval.map(async val => {
            let payloadEmail = {
              "to":val.email,
              "name":`Dear, ${val.name}`,
              "subject":"Project Plan Created",
              "instructions":"",
              "intro":"Project Planning Created.",
              "outro":"Thank you for reading this email, as you know this email is automatically",
              "buttonText":"Go to Dashboard",
              "project_contract_id": Number(res?.data?.data?.id),
              "url":`approval-mail?value=`,
              "urlQuery":{
                  "id": Number(res?.data?.data?.id),
                  "userId":val.id,
                  "userName": val.name
              }
            }
  
            await this.sendEmailNotification({data: payloadEmail})
          })
          let otherPayloadEmail = {
            "to":payload.created_by_email,
            "name":`Dear, ${payload.created_name}`,
            "subject":"Project Plan Created",
            "instructions":"",
            "intro":"Project Planning Created.",
            "outro":"Thank you for reading this email, as you know this email is automatically",
            "buttonText":"Go to Dashboard",
            "project_contract_id": Number(res?.data?.data?.id),
            "url":`approval-mail?value=`,
            "urlQuery":{
                "id": Number(res?.data?.data?.id),
                "userId":payload.created_by,
                "userName": payload.created_name
            }
          }
  
          await this.sendEmailNotification({data: otherPayloadEmail})
  
          Promise.all(promises)
  
          this.saving = false
          this.refreshData()
          this.resetFormPlan()
          this.$swal(`Success!`, `Create Plan Success.`, 'success')
        } else {
          this.saving = false
          this.$swal(`Oops.`, res?.data?.message, 'error')
        }
      }

      this.saving = false
    },
  }
}
</script>

