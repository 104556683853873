<template>
  <div>
    <tab-nav :tabs="true" align="center">
      <tab-nav-items :active="true" href="#section-head" title="Master Data Section Head" />
      <tab-nav-items :active="false" href="#expense" title="Master Data Component Expense" />
    </tab-nav>
    <div id="myTabContent2" class="tab-content">
      <tab-content-item :active="true" id="section-head">
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"
                ><strong>MASTER SECTION HEAD</strong></h4
                >
              </template>
              <template v-slot:headerAction>
                <!-- <b-button variant="primary">
                  <template v-if="!loading_export_power">
                    <i class="fa fa-file-excel"></i> &nbsp; Export
                  </template>
                  <template v-else>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                    Exporting...
                  </template>
                </b-button> -->
              </template>
              <template v-slot:body>
                <b-form class="row" @submit.prevent="getSectionHead()">
                  <b-col cols="12" md="3">
                    <b-form-group
                      label="Search Master Section Head :"
                      label-for="kind-fleet"
                    >
                      <b-form-input
                        id="name"
                        class="w-100"
                        type="text"
                        placeholder="Search..."
                        v-model="sectionHeadList.params.name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="2">
                    <b-form-group label="Search" label-for="button_search">
                      <b-button type="submit" variant="primary" class="ml-2"><i class="fa fa-search"></i></b-button>
                    </b-form-group>
                  </b-col>
                </b-form>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="8">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">List Master Section Head</h4>
              </template>
              <template v-slot:body>
                <div v-if="sectionHeadList._loading" class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <div class="table-responsive" v-if="sectionHeadList.data && sectionHeadList.data.length > 0">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th width="10%">No</th>
                          <th width="25%">Name</th>
                          <th width="10%">Status</th>
                          <th width="15%">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(p, i) in sectionHeadList.data" :key="`p-${i}`">
                          <td>{{ i + 1 }}</td>
                          <td>{{ p.name }}</td>
                          <td>{{ p.status ? 'Active' : 'Inactive' }}</td>
                          <td>
                            <b-button variant="success" size="sm" class="mr-2" @click="editFormSectionHead(p)"><i class="fa fa-edit"></i></b-button>
                            <b-button variant="danger" size="sm" @click="onDeleteSectionHead(p)">
                              <i v-if="!delete_section_head" class="fa fa-trash"></i>
                              <template v-else>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Deleting...
                              </template>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="sectionHeadList.data && sectionHeadList.data.length > 0">
                    <b-pagination
                      v-model="sectionHeadList.meta.page"
                      :total-rows="sectionHeadList.meta.totalItem"
                      @change="getSectionHead"
                      :per-page="sectionHeadList.meta.perPage"
                      first-number
                      align="center"
                    ></b-pagination>
                  </div>
                  <p v-else class="text-center my-4">No Section Head data found.</p>
                </template>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="4">
            <iq-card
              :class="{
                'border border-danger shadow-lg': section_head_edit,
              }"
            >
              <template v-slot:headerTitle>
                <h4 class="card-title"
                >{{ section_head_edit ? 'Edit' : 'Add' }} Section Head</h4
                >
              </template>
              <template v-slot:body>
                <b-form @submit.prevent="onSaveSectionHead()">
                  <b-form-group label="Section Head" label-for="name">
                    <b-form-input v-model="formSectionHead.name" id="name" type="text" placeholder="Type text" maxlength="30"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Status" label-for="status">
                    <b-form-checkbox v-model="formSectionHead.status" name="check-button" switch inline>Active / Inactive
                    </b-form-checkbox>
                  </b-form-group>
                  <hr />
                  <b-form-group class="text-right">
                    <b-button type="submit" variant="primary">
                      <span v-if="!section_head_edit">Add</span>
                      <span v-else>Save Edit</span>
                    </b-button>
                    <b-button
                      type="button"
                      variant="danger"
                      class="iq-bg-danger ml-3"
                      @click="resetFormSectionHead()"
                    >Cancel</b-button>
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
      <tab-content-item :active="false" id="expense">
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"
                ><strong>MASTER COMPONENT EXPENSE</strong></h4
                >
              </template>
              <template v-slot:body>
                <b-form class="row" @submit.prevent="getComponentExpense()">
                  <b-col cols="12" md="3">
                    <b-form-group
                      label="Search Master Component Expense:"
                      label-for="kind-fleet"
                    >
                      <b-form-input
                        id="name"
                        class="w-100"
                        type="text"
                        placeholder="Search..."
                        v-model="componentExpenseList.params.name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="2">
                    <b-form-group label="Search" label-for="button_search">
                      <b-button type="submit" variant="primary" class="ml-2"><i class="fa fa-search"></i></b-button>
                    </b-form-group>
                  </b-col>
                </b-form>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="8">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">List Master Data Component Expense</h4>
              </template>
              <template v-slot:body>
                <div v-if="componentExpenseList._loading" class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <div class="table-responsive" v-if="componentExpenseList.data && componentExpenseList.data.length > 0">
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th>No</th>
                        <th>Component Expense</th>
                        <th>Section Head</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(p, i) in componentExpenseList.data" :key="`p-${i}`">
                        <td>{{ i + 1 }}</td>
                        <td>{{ p.name }}</td>
                        <td>{{ p.section_head_id }}</td>
                        <td>{{ p.status ? 'Active' : 'Inactive' }}</td>
                        <td>
                          <b-button variant="success" size="sm" class="mr-2"><i class="fa fa-edit" @click="editFormComponentExpense(p)"></i></b-button>
                          <b-button variant="danger" size="sm" @click="onDeleteComponentExpense(p)">
                            <i v-if="!delete_component_expense" class="fa fa-trash"></i>
                            <template v-else>
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Deleting...
                            </template>
                          </b-button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="componentExpenseList.data && componentExpenseList.data.length > 0">
                    <b-pagination
                      v-model="componentExpenseList.meta.page"
                      :total-rows="componentExpenseList.meta.totalItem"
                      @change="getComponentExpense"
                      :per-page="componentExpenseList.meta.perPage"
                      first-number
                      align="center"
                    ></b-pagination>
                  </div>
                  <p v-else class="text-center my-4">No ComponentExpense data found.</p>
                </template>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="4">
            <iq-card
              :class="{
                'border border-danger shadow-lg': component_expense_edit,
              }"
            >
              <template v-slot:headerTitle>
                <h4 class="card-title"
                >{{ component_expense_edit ? 'Edit' : 'Add' }} Component Expense</h4
                >
              </template>
              <template v-slot:body>
                <b-form @submit.prevent="onSaveComponentExpense()">
                  <b-form-group label="Component Expense" label-for="name">
                    <b-form-input v-model="formComponentExpense.name" id="name" type="text" placeholder="Type text"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Section Head" label-for="section_head_id">
                    <b-form-select
                      plain
                      v-model="formComponentExpense.section_head_id"
                      size="sm"
                      placeholder="Select options..."
                      :options="listSectionHead"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null"
                          >Select Section Head</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label="Calculation?" label-for="is_calculation">
                    <b-form-checkbox v-model="formComponentExpense.is_calculation" name="check-button" switch inline>Active / Inactive
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group label="Operator" label-for="operator" v-if="formComponentExpense.is_calculation">
                    <b-form-select
                      plain
                      v-model="formComponentExpense.operator"
                      size="sm"
                      placeholder="Select options..."
                      :options="optionsOperator"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null"
                          >Select Operator</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>  
                  <b-form-group label="Sum with Port Stay?" label-for="sum_port_stay">
                    <b-form-checkbox v-model="formComponentExpense.sum_port_stay" name="check-button" switch inline>Yes / No
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group label="Cost Factor" label-for="cost_factor_id">
                    <b-form-select
                      plain
                      v-model="formComponentExpense.cost_factor_id"
                      size="sm"
                      placeholder="Select options..."
                      :options="optionsCostFactor"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null"
                          >Select Cost Factor</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label="More Calculation" label-for="more_calculation">
                    <b-form-checkbox v-model="formComponentExpense.more_calculation" name="check-button" switch inline>Yes / No</b-form-checkbox>
                  </b-form-group>
                  <b-form-group label="Operator" label-for="operator" v-if="formComponentExpense.more_calculation">
                    <b-form-select
                      plain
                      v-model="formComponentExpense.more_calculation_operator"
                      size="sm"
                      placeholder="Select options..."
                      :options="optionsOperator"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null"
                          >Select Operator</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>  
                  <b-form-group label="Number" label-for="more_calculation_number" v-if="formComponentExpense.more_calculation">
                    <b-form-input v-model="formComponentExpense.more_calculation_number">
                    </b-form-input>
                  </b-form-group>  
                  <b-form-group label="Status" label-for="status">
                    <b-form-checkbox v-model="formComponentExpense.status" name="check-button" switch inline>Active / Inactive
                    </b-form-checkbox>
                  </b-form-group>
                  <hr />
                  <b-form-group class="text-right">
                    <b-button type="submit" variant="primary">
                      <span v-if="saving_component_expense" class="spinner-border spinner-border-sm"/>
                      <span v-else-if="!component_expense_edit && !saving_component_expense">Add</span>
                      <span v-else-if="component_expense_edit && !saving_component_expense">Save Edit</span>
                    </b-button>
                    <b-button
                      type="button"
                      variant="danger"
                      class="iq-bg-danger ml-3"
                      @click="resetFormComponentExpense()"
                    >Cancel</b-button>
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
    </div>
  </div>
</template>

<script>
import { busdevActions } from '@src/Utils/helper'
import _ from 'lodash'
export default {
  name: 'MasterComponent',
  data () {
    return {
      optionsOperator:[
        {
          text:'Addition (+)',
          value:'addition'
        },
        {
          text:'Subtraction (-)',
          value:'subtraction'
        },
        {
          text:'Multiplication (*)',
          value:'multiplication'
        },
        {
          text:'Division (/)',
          value:'division'
        },
        {
          text:'Modulus (%)',
          value:'modulus'
        }
      ],
      optionsCostFactor:[],

      listSectionHead:[],
      componentExpenseList: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          component_expense: '',
          // active: true,
          perPage: 30,
          page: 1
        },
        meta: {
          perPage: 0,
          page: 0,
          totalItem: 0,
          totalPages: 0
        }
      },
      sectionHeadList: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          name: '',
          // active: true,
          perPage: 30,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      loading_component_expense:false,
      saving_component_expense:false,
      delete_component_expense:false,

      loading_section_head:false,
      saving_section_head:false,
      delete_section_head:false,

      formComponentExpense: {
        "section_head_id":2,
        "name": "",
        "operator": "",
        is_calculation:false,
        "cost_factor_id":null,
        sum_port_stay:false,
        more_calculation:false,
        more_calculation_operator:'',
        more_calculation_number:null,
        "status": true
      },
      formSectionHead: {
        "name": "",
        "status": true
      },
      component_expense_edit: null,
      component_expense_id: null,
      section_head_edit: null,
      section_head_id: null
    }
  },
  async mounted () {
    await this.getSectionHead()
    await this.getListSectionHead()
    await this.getCostFactor()

    await this.getComponentExpense()
  },
  created () {
    this.getSectionHead = _.debounce(this.getSectionHead, 200)
    this.getComponentExpense = _.debounce(this.getComponentExpense, 200)
  },
  methods: {
    ...busdevActions,
    async getCostFactor () {
      let params = {
        perPage: 100000,
        page: 1
      }

      const res = await this.fetchCostFactor(params)
  
      if (res.data.status == true) {
        if(res?.data?.data?.data.length > 0){
          this.optionsCostFactor = res.data.data?.data.map(val => {
            return {
              value:val.id,
              text:val.name
            }
          })
        }
      }
    }, 
    async getListSectionHead (){
      let params = {
        page:1,
        perPage:1000,
        status: 1
      }

      const res = await this.fetchSectionHead(params)

      if (res.data.status == true && res.data.data?.data?.length > 0) {
        this.listSectionHead = res.data.data?.data?.map(val => {
          return {
            text: val.name,
            value: val.id,
          }
        })
      }
    },
    async getSectionHead (page) {
      this.sectionHeadList._loading = true
      this.sectionHeadList.data = []
      let params = {
        ...this.sectionHeadList.params,
        page: page || 1
      }
      if(!params.name) delete params.name 

      const res = await this.fetchSectionHead(params)
  
      if (res.data.status == true) {
        if(res?.data?.data?.data.length > 0){
          this.sectionHeadList.data = res.data.data?.data
          this.sectionHeadList.meta = res.data.data?.meta
        }
        this.sectionHeadList._loading = false
      } else {
        this.sectionHeadList._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async getComponentExpense (page) {
      this.componentExpenseList._loading = true
      this.componentExpenseList.data = []
      let params = {
        ...this.componentExpenseList.params,
        page: page || 1
      }
      if(!params.component_expense) delete params.component_expense 

      const res = await this.fetchComponentExpense(params)

      if (res.data.status == true) {
        if(res?.data?.data?.data.length > 0){
          this.componentExpenseList.data = res.data.data?.data
          this.componentExpenseList.meta = res.data.data?.meta
        }
        this.componentExpenseList._loading = false
      } else {
        this.componentExpenseList._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },

    async onSaveSectionHead () {
      this.saving_section_head = true
      let payload = {
        ...this.formSectionHead,
        status: this.formSectionHead.status ? 1 : 0
      }

      let res = null
      if(this.section_head_edit && this.section_head_edit){
        res = await this.updateSectionHead({data: payload, id: this.section_head_id})
      } else if (!this.section_head_edit) {
        res = await this.createSectionHead({data: payload})
      }

      console.log(res.data, "what is this")

      if (res.data.status === true) {
        this.$swal(`${this.section_head_edit ? 'Edit' : "Add"} Section ead Success!`, res.data.message, 'success')
        this.resetFormSectionHead()
        this.getSectionHead()
        this.saving_section_head = false
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
        this.saving_section_head = false
      }
      this.saving_section_head = false
    },
    async onSaveComponentExpense() {
      this.saving_component_expense = true
      let payload = {
        ...this.formComponentExpense,
        status: this.formComponentExpense.status ? 1 : 0,
        sum_port_stay: this.formComponentExpense.sum_port_stay ? 1 : 0,
        is_calculation: this.formComponentExpense.is_calculation ? 1 : 0
      }

      let res = null
      if(this.component_expense_edit && this.component_expense_id){
        res = await this.updateComponentExpense({data: payload, id: this.component_expense_id})
      } else if (!this.component_expense_edit) {
        res = await this.createComponentExpense({data: payload})
      }

      if (res?.data?.status === true) {
        this.$swal(`${this.component_expense_edit ? 'Edit' : "Add"} Component Expense Success!`, res.data.message, 'success')
        this.resetFormComponentExpense()
        this.getComponentExpense()
        this.saving_component_expense = false
      } else {
        this.$swal(
          `Oops!`,
          'Something went wrong!',
          'error'
        )
        this.saving_component_expense = false
      }
      this.saving_component_expense = false
    },

    resetFormSectionHead () {
      this.formSectionHead = {
        "name": "",
        "status": true
      }
      this.section_head_edit = false
      this.section_head_id = null
    },
    resetFormComponentExpense () {
      this.formComponentExpense = {
        "section_head_id":2,
        "name": "",
        "operator": "",
        "cost_factor_id":null,
        sum_port_stay:false,
        more_calculation:false,
        more_calculation_operator:'',
        more_calculation_number:null,
        is_calculation:false,
        "status": true
      }
      this.component_expense_edit = false
      this.component_expense_id = null
    },

    editFormSectionHead (data) {
      this.formSectionHead = {
        "name": data?.name,
        "status":data.status == 1 ? true : false
      }
      this.section_head_edit = true
      this.section_head_id = data.id
    },
    editFormComponentExpense (data) {
      this.formComponentExpense = {
        "section_head_id":data?.section_head_id,
        "name": data?.name,
        "operator": data?.operator,
        "cost_factor_id":data?.cost_factor_id,
        sum_port_stay:data?.sum_port_stay  == 1 ? true : false,
        more_calculation: data?.more_calculation  == 1 ? true : false,
        more_calculation_operator: data?.more_calculation_operator,
        more_calculation_number:data?.more_calculation_number,
        is_calculation: data?.is_calculation  == 1 ? true : false,
   
        "status":data?.status == 1 ? true : false
      }
      this.component_expense_edit = true
      this.component_expense_id = data.id
    },

    async onDeleteSectionHead (data) {
      this.$swal({
        title: 'Delete Section Head?',
        text: `Section Head ${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.saving_section_head = true
          let res = await this.deleteSectionHead({id: data.id})
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
            this.saving_section_head = false
          } else {
            this.$swal(
              `Section Head deleted!`,
              `Section Head ${data.name} successfully deleted`,
              'success'
            )
            await this.getSectionHead()
            this.saving_section_head = false
          }
        }
      })
    },
    async onDeleteComponentExpense (data) {
      this.$swal({
        title: 'Delete Component Expense?',
        text: `${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.saving_component_expense = true
          let res = await this.deleteComponentExpense({id: data.id})
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
            this.saving_component_expense = false
          } else {
            this.$swal(
              `Component Expense deleted!`,
              `${data.name} successfully deleted`,
              'success'
            )
            await this.getComponentExpense()
            this.saving_component_expense = false
          }
        }
      })
    },
  }
}
</script>

<style scoped>
</style>
