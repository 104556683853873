var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "m-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "d-flex overflow-auto"
  }, [_c('draggable', {
    staticClass: "d-flex column-height",
    attrs: {
      "list": _vm.kanbanData,
      "animation": 200,
      "group": "kanbanData"
    },
    on: {
      "change": _vm.onMoveStage
    }
  }, _vm._l(_vm.kanbanData, function (column) {
    return _c('div', {
      key: column.title,
      staticClass: "bg-light px-3 column-width column-height mr-4 rounded pb-4 mb-4"
    }, [!column.edit ? _c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('p', {
      staticClass: "text-primary font-weight-bold font-sans mt-3"
    }, [_vm._v(_vm._s(column.title))]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [column.is_end_stage ? _c('b-badge', {
      staticClass: "border border-primary text-primary text-uppercase mr-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v("End Stage")]) : _vm._e(), column.is_deny ? _c('b-badge', {
      staticClass: "border border-primary text-warning text-uppercase",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v("Denied")]) : _vm._e(), column.spal_process ? _c('b-badge', {
      staticClass: "border border-primary text-success text-uppercase",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v("Spal Process")]) : _vm._e()], 1), _c('b-dropdown', {
      staticClass: "mt-0",
      attrs: {
        "size": "sm",
        "variant": "link iq-border-radius-10",
        "toggle-class": "text-decoration-none",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_c('span', {
            staticClass: "dropdown-toggle p-0",
            attrs: {
              "id": "dropdownMenuButton5",
              "data-toggle": "dropdown"
            }
          }, [_c('i', {
            staticClass: "fa fa-pencil-square-o m-0 text-primary"
          })])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          column.edit = true;
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-pen mr-2"
    }), _vm._v("Edit Stage ")]), _c('b-dropdown-item', {
      attrs: {
        "variant": "warning"
      },
      on: {
        "click": function click($event) {
          return _vm.onDenied(column.id);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-exclamation mr-3"
    }), _vm._v("Is Denied? ")]), _c('b-dropdown-item', {
      attrs: {
        "variant": "success"
      },
      on: {
        "click": function click($event) {
          return _vm.onSpalProcess(column.id);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-check mr-2"
    }), _vm._v("Spal Process? ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.onRemoveList(column);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-ban mr-2"
    }), _vm._v("Remove List ")])], 1)], 1) : _c('div', {
      staticClass: "mt-3"
    }, [_c('b-form-input', {
      attrs: {
        "placeholder": "Edit title...",
        "value": column.title,
        "name": "title"
      }
    }), _c('div', {
      staticClass: "d-flex align-items-center mt-2"
    }, [_c('b-button', {
      staticClass: "mr-2",
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          _vm.onEditTitle(column.id, column.title);
          column.edit = false;
        }
      }
    }, [_vm._v("Edit title")]), _c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          column.edit = false;
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-ban",
      staticStyle: {
        "cursor": "pointer"
      }
    })])], 1)], 1), _c('draggable', {
      attrs: {
        "list": column.tasks,
        "animation": 200,
        "ghost-class": "ghost-card",
        "group": "tasks"
      },
      on: {
        "change": _vm.onMove
      }
    }, _vm._l(column.tasks, function (task) {
      return _c('task-card', {
        key: task.id,
        staticClass: "mt-3 cursor-move",
        attrs: {
          "task": task,
          "open-slide-detail-project-contract": _vm.openSlideDetailProjectContract,
          "on-change-prioritize": _vm.onChangePrioritize,
          "on-delete-project-contract": _vm.onDeleteProjectContract,
          "open-pfa-slide": _vm.openPfaSlide,
          "open-project-form-slide": _vm.openProjectFormSlide,
          "on-save-approval": _vm.onSaveApproval
        }
      });
    }), 1)], 1);
  }), 0), !_vm.isAddList && _vm.kanbanData.length > 0 ? _c('div', {
    staticClass: "column-width",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": _vm.isAddAnotherList
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center bg-primary px-3 mr-4 rounded"
  }, [_c('p', {
    staticClass: "font-weight-bold font-sans mt-3"
  }, [_vm._v("Add Another List")]), _c('i', {
    staticClass: "fa fa-plus"
  })])]) : _vm.isAddList ? _c('div', {
    staticClass: "column-width"
  }, [_c('div', {
    staticClass: "mr-4 rounded bg-light px-3 py-3"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Enter list title...",
      "name": "columnTitle"
    },
    model: {
      value: _vm.columnTitle,
      callback: function callback($$v) {
        _vm.columnTitle = $$v;
      },
      expression: "columnTitle"
    }
  }), _c('div', {
    staticClass: "d-flex align-items-center mt-2"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.addAnotherList
    }
  }, [_vm._v("Add list")]), _c('b-button', {
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": _vm.isAddAnotherList
    }
  }, [_c('i', {
    staticClass: "fa fa-ban",
    staticStyle: {
      "cursor": "pointer"
    }
  })])], 1)], 1)]) : _vm._e()], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }