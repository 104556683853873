<template>
  <form @submit.prevent="onSave">
    <b-row class="m-3">
      <b-col lg="12" class="mb-2" v-if="!loading && detailProjectContract?.project_pfa">
        <div class="d-flex w-100 justify-content-end">
          <b-dropdown v-b-tooltip.top="'Export Data'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
            <template v-slot:button-content>
                <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                <i class="fa fa-save m-0"></i>
                </span>
            </template>
            <b-dropdown-item href="#" variant="dark" @click="onExportPFA(detailProjectContract?.id, 'pdf')"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
            <b-dropdown-item href="#" variant="dark" disabled><i class="fa fa-file-excel mr-2"></i>Export Excel</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
      <b-col md="12" v-if="!loading">
        <fieldset class="form-group border p-3">
          <legend class="w-auto px-2 h5 text-primary">General Information</legend>
          <b-row class="pl-3 pr-3">
            <b-col md="6">
                <b-form-group class="row" label-cols-md="4" label="PFA ID" label-for="inventoryID">
                  <b-form-input id="orderNumber" disabled  v-model="form.pfa_id" placeholder="<< CREATE PFA ID >>"></b-form-input>
                </b-form-group>
                <b-form-group class="row" label-cols-md="4" label="Status" label-for="status">
                  <b-form-select plain size="lg" placeholder="Select Status..." v-model="form.status">
                    <template v-slot:first>
                    <b-form-select-option :value="''">Select Status...</b-form-select-option>
                    <b-form-select-option :value="'active'">Active</b-form-select-option>
                    <b-form-select-option :value="'inactive'">Inactive</b-form-select-option>
                    <b-form-select-option :value="'no-sales'">No Sales</b-form-select-option>
                    <b-form-select-option :value="'no-purchase'">No Purchase</b-form-select-option>
                    <b-form-select-option :value="'no-request'">No Request</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="row" label-cols-md="4" label="Name Pfa" label-for="descriptionItem">
                  <b-form-input type="text" placeholder="Type Text" id="descriptionItem" v-model="form.pfa_name"></b-form-input>
                </b-form-group>
                <b-form-group class="row" label-cols-md="4" label="Voyage" label-for="uom">
                  <b-button-group size="sm">
                    <b-button variant="primary" @click="handleCountVoyage(false)"><i class="fa fa-minus"></i></b-button>
                    <b-button style="width:80px">{{ form?.voyage ?? 1 }}</b-button>
                    <b-button variant="primary" @click="handleCountVoyage(true)"><i class="fa fa-plus"></i></b-button>
                  </b-button-group>
                </b-form-group>
                <b-form-group class="row" label-cols-md="4" label="Port of Loading" label-for="descriptionItem">
                  <b-form-input type="text" id="descriptionItem" v-model="form.pol[0].value"></b-form-input>
                </b-form-group>
                <b-form-group class="row" label-cols-md="4" label="Port of Discharge" label-for="descriptionItem">
                  <b-form-input type="text" id="descriptionItem" v-model="form.pod[0].value"></b-form-input>
                </b-form-group>
            </b-col>
  
            <b-col md="6">
              <b-form>
                <b-form-group class="row" label-cols-md="4" :label="detailProjectContract?.fleet_type?.name" label-for="Department">
                  <div class="d-flex flex-wrap">
                    <b-badge v-for="(state, index) in detailProjectContract?.fleets" :key="index" class="border border-primary text-primary text-uppercase mr-1 mb-1" variant="none">{{ showVehicleName(state?.vehicle_id) }}</b-badge>
                  </div>
                </b-form-group> 
                <b-form-group class="row" label-cols-md="4" label="Kind of Cargo" label-for="picName">
                  <b-form-select plain :options="optionsCargoType" v-model="form.cargo_type_id">
                    <b-form-select-option :value="null">Select Cargo Type</b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="row" label-cols-md="4" :label="`Exch. Rate`" label-for="location" v-if="['MOTHER VESSEL'].includes(detailProjectContract?.fleet_type?.name)">
                  <b-form-input type="number" step=".0001" class="text-right" id="qty" v-model="usdRate"></b-form-input>
                  <small>{{ formatCurrency(usdRate) }}</small>
                </b-form-group>
                <b-form-group class="row" label-cols-md="4" :label="`Quantity (${detailProjectContract?.unit})`" label-for="location">
                  <b-form-input type="number" step=".0001" class="text-right" id="qty" v-model="form.quantity"></b-form-input>
                </b-form-group>
                <b-form-group class="row" label-cols-md="4" label="Freight" label-for="location" v-if="['TUG BOAT'].includes(detailProjectContract?.fleet_type?.name)">
                  <b-form-input type="number" step=".0001" class="text-right" id="qty" v-model="form.freight_rate"></b-form-input>
                  <small>{{ formatCurrency(form.freight_rate) }}</small>
                </b-form-group>
                <b-form-group class="row" label-cols-md="4" label="Freight IDR" label-for="location" v-if="['MOTHER VESSEL'].includes(detailProjectContract?.fleet_type?.name)">
                  <b-form-input type="number" step=".0001" class="text-right" id="qty" v-model="form.freight_rate_idr"></b-form-input>
                  <small>{{ formatCurrency(form.freight_rate_idr ?? 0) }}</small>
                </b-form-group>
                <b-form-group class="row" label-cols-md="4" label="Freight USD$" label-for="location" v-if="['MOTHER VESSEL'].includes(detailProjectContract?.fleet_type?.name)">
                  <span>${{ handleCountIDRToUSD(form.freight_rate_idr, 15500).toFixed(2) }}</span>
                </b-form-group>
                <b-form-group class="row" label-cols-md="4" label="Revenue" label-for="location">
                  <h3><b-badge variant="success">{{ formatCurrency(handleCountRevenue) }}</b-badge></h3>
                </b-form-group>
              </b-form>
            </b-col>
          </b-row>
        </fieldset>

        <!-- CHARTER COMPANY -->
        <fieldset class="form-group border p-3">
          <legend class="w-auto px-2 h5 text-primary">Charter Company</legend>
          <b-row v-for="(state, index) in tableCharterCompany" :key="index">
            <b-col md="6">
              <span class="form-control">{{ state.name }}</span>
            </b-col>
            <b-col md="6">
              <b-form-input type="text" placeholder="Type text" v-model="state.value"></b-form-input>
            </b-col>
          </b-row>
        </fieldset>

        <!-- LADEN/BALLAST -->
        <fieldset class="form-group border p-3" v-if="['TUG BOAT'].includes(detailProjectContract?.fleet_type?.name)">
          <legend class="w-auto px-2 h5 text-primary">Distance, Speed, Est Time(Hour)</legend>
          <b-row>
            <b-col md="12">
              <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">Laden</th>
                        <th class="text-center">Ballast</th>
                        <th class="text-center">Est Dist (NM)</th>
                        <th class="text-center">Speed</th>
                        <th class="text-center">Est Time(Hour)</th>
                        <th class="text-center">#</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(state, index) in form.pfa_travel" :key="index">
                        <td>
                          <b-form-input type="text" class="text-left" placeholder="Type Text" id="qty" v-model="state.laden"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="text" class="text-left" placeholder="Type Text" id="qty" v-model="state.ballast"></b-form-input> 
                        </td>
                        <td>
                          <b-form-input type="number" step=".0001" class="text-center" placeholder="Type Number" id="qty" v-model="state.est_dist" @input="handleCountEstTime(state)"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" step=".0001" class="text-center" placeholder="Type Number" id="qty" v-model="state.speed" @input="handleCountEstTime(state)"></b-form-input>
                        </td>
                        <td class="text-center">
                          <h5><b-badge variant="info">{{ state?.est_time?.toFixed(2) ?? 0 }}</b-badge></h5>
                        </td>
                        <td>
                          <i class="fa fa-trash text-danger" style="cursor:pointer" @click="form.pfa_travel.splice(index, 1)"></i>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="6"><b-button variant="info" block @click="handleAddPfaTravel(form.pfa_travel, 'TUG BOAT')"><i class="fa fa-plus"></i> Add Data</b-button></td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
        </fieldset>
        <fieldset class="form-group border p-3" v-if="['MOTHER VESSEL'].includes(detailProjectContract?.fleet_type?.name)">
          <legend class="w-auto px-2 h5 text-primary">Distance, Speed, Est Time(Hour)</legend>
          <b-row>
            <b-col md="12">
              <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">Laden</th>
                        <th class="text-center">Ballast</th>
                        <th class="text-center">Est Dist (NM)</th>
                        <th class="text-center">Speed</th>
                        <th class="text-center">Est Time(Hour)</th>
                        <th class="text-center">Est Time(Day)</th>
                        <th class="text-center">#</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(state, index) in form.pfa_travel_mv" :key="index">
                        <td>
                          <b-form-input type="text" class="text-left" placeholder="Type Text" id="qty" v-model="state.laden"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="text" class="text-left" placeholder="Type Text" id="qty" v-model="state.ballast"></b-form-input> 
                        </td>
                        <td>
                          <b-form-input type="number" step=".0001" class="text-center" placeholder="Type Number" id="qty" v-model="state.est_dist" @input="handleCountEstTime(state)"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" step=".0001" class="text-center" placeholder="Type Number" id="qty" v-model="state.speed" @input="handleCountEstTime(state)"></b-form-input>
                        </td>
                        <td class="text-center">
                          <h5><b-badge variant="info">{{ state?.est_time?.toFixed(2) ?? 0 }}</b-badge></h5>
                        </td>
                        <td class="text-center">
                          <h5><b-badge variant="info">{{ handleCountEstDay(state?.est_time).toFixed(2) }}</b-badge></h5>
                        </td>
                        <td>
                          <i class="fa fa-trash text-danger" style="cursor:pointer" @click="form.pfa_travel_mv.splice(index, 1)"></i>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="6"><b-button variant="info" block @click="handleAddPfaTravel(form.pfa_travel_mv, 'MV')"><i class="fa fa-plus"></i> Add Data</b-button></td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
        </fieldset>

        <!-- COST FACTOR -->
        <fieldset class="form-group border p-3" v-if="['TUG BOAT'].includes(detailProjectContract?.fleet_type?.name)">
          <legend class="w-auto px-2 h5 text-primary">Cost Factor</legend>
          <b-row>
            <b-col md="12">
              <div class="d-flex w-100 justify-content-end">
                <b-form-group label="Cost Factor Multiplication">
                  <b-form-input type="number" step=".0001" v-model="cost_factor_multiplication"></b-form-input>
                </b-form-group>
              </div>
              <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">Item</th>
                        <th class="text-center">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(state, index) in tableCostFactor" :key="index">
                        <td>{{ state?.name }}</td>
                        <td>
                          <b-form-input type="number" step=".0001" placeholder="0" v-model="state.value"></b-form-input>
                          <small class="text-muted text-truncate" v-if="state?.is_calculation">
                            {{ formatCurrency(Number(state.value)) }}{{ operatorSymbol(state.operator) }}{{ cost_factor_multiplication }} = {{state.value ? formatCurrency(handleCalcute(state.operator, true, state.value, cost_factor_multiplication)) : '0' }}
                          </small>
                          <small v-else>{{ formatCurrency(Number(state.value)) }}</small>
                        </td>
                      </tr>
                    </tbody>
                </table>
              </div>
          </b-col>
          </b-row>
        </fieldset>
        <fieldset class="form-group border p-3" v-if="['MOTHER VESSEL'].includes(detailProjectContract?.fleet_type?.name)">
          <legend class="w-auto px-2 h5 text-primary">FUEL PRICE</legend>
          <b-row>
            <b-col md="12">
              <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">Item</th>
                        <th class="text-center">Price</th>
                        <th class="text-center">Unit</th>
                        <th class="text-right">#</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(state, index) in tableCostFactor" :key="index">
                        <td><b-form-input type="text" placeholder="Type Text" v-model="state.name"></b-form-input></td>
                        <td>
                          <b-form-input type="number" step=".0001" placeholder="0" v-model="state.value"></b-form-input>             
                        </td>
                        <td><b-form-input type="text" placeholder="Type Text" v-model="state.unit"></b-form-input></td>
                        <td>
                          <i class="fa fa-trash text-danger" style="cursor:pointer" @click="tableCostFactor.splice(index, 1)"></i>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4"><b-button variant="info" block @click="tableCostFactor.push({
                          key: tableCostFactor[tableCostFactor.length - 1].key + 1,
                          name:'',
                          value:null,
                          unit:''
                        })"><i class="fa fa-plus"></i> Add Data</b-button></td>
                      </tr>
                    </tbody>
                </table>
              </div>
          </b-col>
          </b-row>
        </fieldset>

        <!-- PORT STAY -->
        <fieldset class="form-group border p-3" v-if="['TUG BOAT'].includes(detailProjectContract?.fleet_type?.name)"> 
          <legend class="w-auto px-2 h5 text-primary">Port Stay</legend>
          <b-row>
            <b-col md="12" class="mb-4">
              <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">Port Stay (load/discharge)</th>
                        <th>Steaming Time / day</th>
                        <th>Idling/waiting</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(state, index) in tablePortStay" :key="index">
                        <td>{{ state?.name }}</td>
                        <td>
                          <b-form-input type="number" step=".0001" placeholder="0" v-model="state.streaming_time" @input="handleCountIdle(state, 168);"></b-form-input>
                        </td>
                        <td >
                          <b-form-input type="number" step=".0001" placeholder="0" v-model="state.idle"></b-form-input>
                          <small class="text-muted text-truncate" v-if="state?.is_calculation">
                            {{ state?.streaming_time ?? 0 }}{{ operatorSymbol(state.operator) }}(24 * 7) = {{state.streaming_time ? handleCalcute(state.operator,false, state.streaming_time, 168) : '0' }}
                          </small>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td class="bg-warning">Total duration & Total Bunker Consumption</td>
                        <td class="bg-info">{{ handleCountDurationStreamingTime }}</td>
                        <td class="bg-info">{{ handleCountDurationIdle }} </td>
                      </tr>
                      <tr>
                        <td class="bg-warning">Grand total duration (day) & consumption	</td>
                        <td class="bg-info">{{ handleCountGrandTotalStreamingTime }}</td>
                        <td class="bg-info">{{ handleCountGrandTotalIdle }} </td>
                      </tr>
                    </tfoot>
                </table>
              </div>
            </b-col>
          </b-row>
        </fieldset>
        <fieldset class="form-group border p-3" v-if="['MOTHER VESSEL'].includes(detailProjectContract?.fleet_type?.name)"> 
          <legend class="w-auto px-2 h5 text-primary">CARGO ESTIMATION LOAD & DISCH PORT</legend>
          <b-row>
            <b-col md="12" class="mb-4">
              <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="text-center" colSpan="2">loading/disch rate/day (FC)</th>
                        <th class="text-center">laytime POL/POD</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(state, index) in tablePortStay" :key="index">
                        <td>{{ state?.name }}</td>
                        <td>
                          <b-form-input type="number" step=".0001" placeholder="0" v-model="state.rate" @input="handleCountLaytime(state)"></b-form-input>
                        </td>
                        <td >
                          <b-form-input type="number" step=".0001" placeholder="0" v-model="state.laytime"></b-form-input>
                        </td>
                        <td>
                          <span>Days</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Idle (Turn Time)</td>
                        <td></td>
                        <td>
                          <b-form-input type="number" step=".0001" placeholder="0" v-model="idle_turn_time"></b-form-input>
                        </td>
                        <td><span>Days</span></td>
                      </tr>
                      <tr>
                        <td>TC Hire/Day</td>
                        <td></td>
                        <td>
                          <b-form-input type="number" step=".0001" placeholder="0" v-model="tc_hire_day"></b-form-input>
                        </td>
                        <td><span>Per Days</span></td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
        </fieldset>

        <!-- TIME VOYAGE -->
        <fieldset class="form-group border p-3" v-if="['TUG BOAT'].includes(detailProjectContract?.fleet_type?.name)">
          <legend class="w-auto px-2 h5 text-primary">Time Voyage</legend>
          <b-row>
            <b-col md="12" class="mb-4">
              <div class="d-flex w-100 justify-content-end">
                <b-form-group label="Cost Factor Multiplication">
                  <b-form-input type="number" step=".0001" v-model="time_voyage_multiplication"></b-form-input>
                </b-form-group>
              </div>
              <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">Distances  steamed (Ballast / Laden)</th>
                        <th class="text-center">Steaming Time / hours</th>
                        <th class="text-center">Cons</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(state, index) in tableTimeVoyage" :key="index">
                        <td>{{ state?.name }}</td>
                        <td>
                          <b-form-input type="number" step=".0001" placeholder="0" v-model="state.streaming_time" @input="handleCountCons(state, time_voyage_multiplication);"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" step=".0001" placeholder="0" v-model="state.cons"></b-form-input>
                          <small class="text-muted text-truncate" v-if="state?.is_calculation">
                            {{ state?.streaming_time ?? 0 }}{{ operatorSymbol(state.operator) }}{{ time_voyage_multiplication }} = {{state.streaming_time ? handleCalcute(state.operator,false, state.streaming_time, time_voyage_multiplication) : '0' }}
                          </small>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td class="bg-warning">Total duration(hour/Day) & Consumption</td>
                        <td class="bg-info">{{ handleCountDuration }}</td>
                        <td class="bg-info">{{ handleCountConsumption  }} </td>
                      </tr>
                    </tfoot>
                </table>
              </div>
            </b-col>
          </b-row>
        </fieldset>
        <fieldset class="form-group border p-3" v-if="['MOTHER VESSEL'].includes(detailProjectContract?.fleet_type?.name)">
          <legend class="w-auto px-2 h5 text-primary">FUEL CONSUMPTION</legend>
          <b-row>
            <b-col md="12" class="mb-4">
              <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">Distances  steamed (Ballast / Laden)</th>
                        <th class="text-center">CONS/DAY (MT/DAY)</th>
                        <th class="text-center">Cons MT</th>
                        <th class="text-center">Cons KL</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(state, index) in tableTimeVoyage" :key="index">
                        <td>{{ state?.name }}</td>
                        <td>
                          <b-form-input type="number" step=".0001" placeholder="0" v-model="state.cons_day"></b-form-input>
                          <small class="text-muted text-truncate" v-if="state?.cons_day_calculation">
                            {{ state?.cons_day ?? 0 }} * {{ handleCountConsDay(state).multiplyBy }} = {{ handleCountConsDay(state).value }}
                          </small>  
                        </td> 
                         <td>
                          <span class="text-truncate" v-if="state?.cons_mt_calculation">
                            <span class="text-muted">{{ state?.cons_mt ?? 0 }} * {{ handleCountConsMT(state).multiplyBy }}</span> = {{ handleCountConsMT(state).value }}
                          </span>
                        </td>
                        <td>
                          <span class="text-truncate" v-if="state?.cons_kl_calculation">
                            <span class="text-muted">{{ state?.cons_kl ?? 0 }} / {{ handleCountConsKL(state).multiplyBy }}</span> = {{ handleCountConsKL(state).value }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td class="bg-warning">Total duration(hour/Day) & Consumption</td>
                        <td class="bg-info"></td>
                        <td class="bg-info">{{ handleCountTotalConsMT }}</td>
                        <td class="bg-info">{{ handleCountTotalConsKL }}</td>
                      </tr>
                    </tfoot>
                </table>
              </div>
            </b-col>
          </b-row>
        </fieldset>

         <!-- DATE LOADING & DATE DISCHARGE -->
         <!-- <fieldset class="form-group border p-3">
          <legend class="w-auto px-2 h5 text-primary">Date Loading & Discharge</legend>
          <b-row >
            <b-col md="12">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th class="text-center" colSpan="2">Date Loading</th>
                    <th class="text-center" colSpan="2">Date Discharge</th>
                  </tr>
                  <tr>
                    <th class="text-center bg-warning">Commence</th>
                    <th class="text-center bg-warning">Complete</th>
                    <th class="text-center bg-warning">Commence</th>
                    <th class="text-center bg-warning">Complete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      <b-form-input v-model="form.date_loading_discharge.loading_commence1" type="number" step=".0001" class="text-center" placeholder="0"></b-form-input>
                    </td>
                    <td class="text-center">
                      <b-form-input v-model="form.date_loading_discharge.loading_complete1" type="number" step=".0001" class="text-center" placeholder="0"></b-form-input>
                    </td>
                    <td class="text-center">
                      <b-form-input v-model="form.date_loading_discharge.discharge_commence1" type="number" step=".0001" class="text-center" placeholder="0"></b-form-input>
                    </td>
                    <td class="text-center">
                      <b-form-input v-model="form.date_loading_discharge.discharge_complete1" type="number" step=".0001" class="text-center" placeholder="0"></b-form-input>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <b-form-input v-model="form.date_loading_discharge.loading_commence2" type="number" step=".0001" class="text-center" placeholder="0"></b-form-input>
                    </td>
                    <td class="text-center">
                      <b-form-input v-model="form.date_loading_discharge.loading_complete2" type="number" step=".0001" class="text-center" placeholder="0"></b-form-input>
                    </td>
                    <td class="text-center">
                      <b-form-input  v-model="form.date_loading_discharge.discharge_commence2" type="number" step=".0001" class="text-center" placeholder="0"></b-form-input>
                    </td>
                    <td class="text-center">
                      <b-form-input v-model="form.date_loading_discharge.discharge_complete2" type="number" step=".0001" class="text-center" placeholder="0"></b-form-input>
                    </td>
                  </tr>
                </tbody>
                <tfoot> 
                  <th class="bg-warning">Dem/day</th>
                  <td class="text-center">
                      <b-form-input type="number" step=".0001" class="text-center" placeholder="0"></b-form-input>
                  </td>
                  <th class="bg-warning">Dem/day</th>
                  <td class="text-center">
                      <b-form-input type="number" step=".0001" class="text-center" placeholder="0"></b-form-input>
                  </td>
                </tfoot>
              </table>
            </b-col>
          </b-row>
        </fieldset> -->

        <!-- MISCELLANEOUS CHARGE -->
        <fieldset class="form-group border p-3" v-if="['TUG BOAT'].includes(detailProjectContract?.fleet_type?.name)">
          <legend class="w-auto px-2 h5 text-primary">Miscellaneous Charge</legend>
          <b-row>
            <b-col md="12">
              <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">Item</th>
                        <th class="text-center">Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(state, index) in tableMiscellaneousCharge" :key="index">
                        <td>{{ state?.name }}</td>
                        <td class="text-center">
                          <b-form-input type="number" step=".0001" class="text-center" placeholder="0" v-model="state.value"></b-form-input>
                          <small>{{ formatCurrency(Number(state.value)) }}</small>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td><strong>TOTAL</strong></td>
                        <td class="text-center bg-success font-weight-bold">{{ formatCurrency(handleCountTotalMC) }}</td>
                      </tr>
                    </tfoot>
                </table>
              </div>
          </b-col>
          </b-row>
        </fieldset>
        
        <!-- RESULT PLUS -->
        <fieldset class="form-group border p-3" v-if="['TUG BOAT'].includes(detailProjectContract?.fleet_type?.name)">
          <legend class="w-auto px-2 h5 text-primary">Result Plus - General</legend>
          <b-row>
            <b-col md="12">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="text-center">Item</th>
                      <th class="text-center">Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(state, index) in tableResultPlus" :key="index">
                      <td>{{ state?.name }}</td>
                      <td class="text-center">
                        <b-form-input type="number" step=".0001" class="text-center" placeholder="0" v-model="state.value"></b-form-input>
                          <small>{{ formatCurrency(Number(state.value)) }}</small>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                      <tr>
                        <td><strong>TOTAL</strong></td>
                        <td class="text-center bg-success font-weight-bold">{{ formatCurrency(handleCountResultPlus) }}</td>
                      </tr>
                    </tfoot>
                </table>
              </div>
            </b-col>
          </b-row>
        </fieldset>

        <!-- OPERATIONAL EXPENSES -->
        <fieldset class="form-group border p-3" v-if="['TUG BOAT'].includes(detailProjectContract?.fleet_type?.name)">
          <legend class="w-auto px-2 h5 text-primary">Operational Expenses</legend>
          <b-row>
            <b-col md="12">
              <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">Operation</th>
                        <th class="text-center">Expense</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(state, index) in tableComponentExpense" :key="index">
                        <td class="text-truncate">
                          <div class="d-flex">
                            <span class="mr-2">{{ state?.name }}</span> <span class="mr-2">{{ operatorSymbol(state.operator) }}</span>
                            <b-form-select v-model="state.multiplyBy" plain size="sm" v-if="state?.is_calculation" required>
                              <b-form-select-option :value="null">Select Options</b-form-select-option>
                              <b-form-select-option :key :value="1">Grand total (Streaming Day)</b-form-select-option>
                              <b-form-select-option :value="2">Grand total (Idling/Waiting)</b-form-select-option>
                            </b-form-select> 
                          </div>
                        </td>
                        <td>
                          <b-form-input type="number" step=".0001" placeholder="0" v-model="state.value" v-if="!state?.is_calculation"></b-form-input>
                          <small v-if="!state?.is_calculation">{{ formatCurrency(Number(state.value)) }}</small>
                          <div class="d-flex align-items-center" v-else>
                            <span class="mr-2">{{ formatCurrency(handleCountComponentExpense(state)) }}</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td><strong>Total Operational Expenses</strong></td>
                        <td class="text-center bg-primary font-weight-bold"> {{ formatCurrency(handleCountTotalOperationalExpenses) }} </td>
                      </tr>
                      <tr>
                        <td><strong>Revenue	</strong></td>
                        <td class="text-center bg-primary font-weight-bold">{{ formatCurrency(handleCountRevenue) }}</td>
                      </tr>
                      <tr>
                        <td><strong>Grand Total Expenses	</strong></td>
                        <td class="text-center bg-primary font-weight-bold">{{ formatCurrency(handleCountGrandTotalExpenses) }}</td>
                      </tr>
                      <tr>
                        <td><strong>Profit</strong></td>
                        <td class="text-center bg-success font-weight-bold">{{ formatCurrency(handleCountProfit) }}</td>
                      </tr>
                      <tr>
                        <td><strong>Profit %</strong></td>
                        <td :class="`text-center font-weight-bold ${handleCountProfitPersen.toFixed(2) > 16 ? 'bg-success' : 'bg-warning'}`">{{ handleCountProfitPersen.toFixed(2) }}% <small>from Equity ( Grand Total Expenses)</small>   </td>
                      </tr>
                      <tr>
                        <td><strong>Profit Per MT</strong></td>
                        <td class="text-center bg-success font-weight-bold">{{ handleCountCostM3.toFixed(2) }}</td>
                      </tr>
                    </tfoot>
                </table>
              </div>
          </b-col>
          </b-row>
        </fieldset>
        <fieldset class="form-group border p-3" v-if="['MOTHER VESSEL'].includes(detailProjectContract?.fleet_type?.name)">
          <legend class="w-auto px-2 h5 text-primary">Operational Expenses</legend>
          <b-row>
            <b-col md="12">
              <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">Operation</th>
                        <th class="text-center">Expense IDR</th>
                        <th class="text-center">Expense USD</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(state, index) in tableComponentExpense" :key="index">
                        <td class="text-truncate">{{ state?.name }}</td>
                        <td>
                          <span v-if="state?.sum_port_stay">{{ formatCurrency(handleCountComponentExpenseMV(state)?.value) }}</span>
                          <b-form-input v-if="!state?.sum_port_stay" type="number" step=".0001" placeholder="0" v-model="state.value"></b-form-input>
                          <small v-if="!state?.is_calculation && !state?.more_calculation && !state.sum_port_stay">{{ formatCurrency(state?.value)}}</small>
                          <small v-if="(state?.is_calculation || state?.more_calculation) && !state.sum_port_stay">{{ state?.value ? formatCurrency(state.value) : 0 }} {{ operatorSymbol(state?.operation ? state?.operation : state.more_calculation_operator ?? '') }} {{ state.more_calculation_number.toLocaleString() }} = {{ formatCurrency(handleCountComponentExpenseMV(state).value) }}</small>
                        </td>
                        <td>
                          <span>{{ formatUSD(convertComponentExpenseToUSD(handleCountComponentExpenseMV(state)?.value)) }}</span>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td><strong>Total Operational Expenses</strong></td>
                        <td class="text-center bg-primary font-weight-bold"> {{ formatCurrency(handleCountTotalExpensesMVIDR) }} </td>
                        <td class="text-center bg-primary font-weight-bold"> {{ formatUSD(handleCountTotalExpensesMVUSD) }} </td>
                      </tr>
                     
                    </tfoot>
                </table>
              </div>
          </b-col>
          </b-row>
        </fieldset>

        <!-- VARIABLE COST (MV) -->
        <fieldset class="form-group border p-3" v-if="['MOTHER VESSEL'].includes(detailProjectContract?.fleet_type?.name)">
          <legend class="w-auto px-2 h5 text-primary">Variable Cost</legend>
          <b-row>
            <b-col md="12">
              <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">Operation</th>
                        <th>Expense IDR</th>
                        <th>Expense USD</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-truncate">
                          <div class="d-flex">
                            <span class="mr-1">MFO Fuel Consumption</span> * 
                            <b-form-select v-model="mfoMultiply" plain size="sm" required class="ml-1">
                              <b-form-select-option :value="0">Select Options</b-form-select-option>
                              <b-form-select-option v-for="(obj, objIndex) in tableCostFactor" :value="obj.value" :key="objIndex">{{ obj.name }}</b-form-select-option>
                            </b-form-select> 
                          </div>
                        </td>
                        <td>
                          <span>{{ formatCurrency(handleCountMFO) }}</span>
                        </td>
                        <td>
                          <span>{{ formatUSD(handleCountMFO / usdRate) }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-truncate">
                          <div class="d-flex">
                            <span class="mr-1">MDO Fuel Consumption</span> * 
                            <b-form-select v-model="mdoMultiply" plain size="sm" required class="ml-1">
                              <b-form-select-option :value="0">Select Options</b-form-select-option>
                              <b-form-select-option v-for="(obj, objIndex) in tableCostFactor" :value="obj.value" :key="objIndex">{{ obj.name }}</b-form-select-option>
                            </b-form-select> 
                          </div>
                        </td>
                        <td>
                          <span>{{ formatCurrency(handleCountMDO) }}</span>
                        </td>
                        <td>
                          <span>{{ formatUSD(handleCountMDO / usdRate) }}</span>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td><strong>Actual Fuel Consumption</strong></td>
                        <td class="text-center bg-primary font-weight-bold"> {{ formatCurrency(handleCountActualIDR) }} </td>
                        <td class="text-center bg-primary font-weight-bold"> {{ formatUSD(handleCountActualUSD) }} </td>
                      </tr>
                    </tfoot>
                </table>
              </div>
          </b-col>
          </b-row>
        </fieldset> 
        <!-- GRAND TOTAL EXPENSES (MV) -->
        <fieldset class="form-group border p-3" v-if="['MOTHER VESSEL'].includes(detailProjectContract?.fleet_type?.name)">
          <legend class="w-auto px-2 h5 text-primary">Grand Total Expenses</legend>
          <b-row>
            <b-col md="12">
              <div class="table-responsive">
                <table class="table table-striped">
                    <tbody>
                      <tr>
                        <td class="bg-warning"><strong>TOTAL GRAND EXPENSES</strong></td>
                        <td class="text-center bg-primary font-weight-bold">{{ formatCurrency(handleCountGrandTotalExpenseIDR) }}</td>
                        <td class="text-center bg-primary font-weight-bold">{{ formatUSD(handleCountGrandTotalExpenseUSD) }}</td>
                      </tr>
                    </tbody>
                </table>
              </div>
          </b-col>
          </b-row>
        </fieldset>
         <!-- BREAKEVEN FREIGHT (PFA) (MV) -->
         <fieldset class="form-group border p-3" v-if="['MOTHER VESSEL'].includes(detailProjectContract?.fleet_type?.name)">
          <legend class="w-auto px-2 h5 text-primary">BREAKEVEN FREIGHT (PFA)</legend>
          <b-row>
            <b-col md="12">
              <div class="table-responsive">
                <table class="table table-striped">
                    <tbody>
                      <tr>
                        <td class="bg-warning"><strong>Freight</strong></td>
                        <td class="text-center bg-primary font-weight-bold">{{ formatCurrency(0) }}</td>
                        <td class="text-center bg-primary font-weight-bold">{{ formatUSD(0) }}</td>
                      </tr>
                      <tr>
                        <td class="bg-warning"><strong>Bunker</strong></td>
                        <td class="text-center bg-primary font-weight-bold">{{ formatCurrency(0) }}</td>
                        <td class="text-center bg-primary font-weight-bold">{{ formatUSD(0) }}</td>
                      </tr>
                    </tbody>
                </table>
              </div>
          </b-col>
          </b-row>
        </fieldset>

      </b-col>
      <b-col md="12" class="mt-5 mb-4" v-if="!loading">
        <b-button variant="primary" type="submit" block :disabled="saving">
          <span v-if="saving" class="spinner-border spinner-border-sm"/>
          <span v-else>Submit</span>
        </b-button>
      </b-col>
      <b-col v-else md="12">
        <div class="alert alert-info m-4">
          Loading...
        </div>
      </b-col>
    </b-row>
  </form>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { projectsActions, companiesActions, fleetsActions, busdevActions, crewingActions } from '@src/Utils/helper'

export default {
  name: 'SlideFormPFA',
  props:{
    pfaId:{
      type:Number,
      default:true
    }, 
    optionsCargoType:{
      type:Array,
      default:[]
    },  
    openPfaSlide:{
      type:Function,
      default:true
    },   
    optionsFleets:{
      type:Array,
      default:true
    },  
    onExportPFA:{
      type:Function,
      default:true
    }, 
    refreshDataTable:{
      type:Function,
      default:true
    }, 
  },
  data() {
    return {
      loading:false,
      saving:false,

      detailProjectContract:null,

      usdRate:15500,
      tableTimeVoyage:[],
      tableCharterCompany:[],
      tableMiscellaneousCharge:[],
      tablePortStay:[],
      tableResultPlus:[],
      tableComponentExpense:[],
      tableCostFactor:[],
      mvTimeVoyage: [
        {
          name:'MFO (ME) Consumption Laden (isi)',
          cons_day_calculation:false,
          cons_mt_calculation:true,
          cons_kl_calculation:true,
          for_mfo:true,
          for_mdo:false,
          cons_day:null,
          cons_mt:null,
          cons_kl:null,
        },
        {
          name:'MFO (ME) Consumption Ballast (kosong)',
          cons_day_calculation:false,
          cons_mt_calculation:true,
          cons_kl_calculation:true,
          for_mfo:true,
          for_mdo:false,
          cons_day:null,
          cons_mt:null,
          cons_kl:null,
        },
        {
          name:'MFO (ME) Consumption Port Stay',
          cons_day_calculation:false,
          cons_mt_calculation:true,
          cons_kl_calculation:true,
          for_mfo:true,
          for_mdo:false,
          cons_day:null,
          cons_mt:null,
          cons_kl:null,
        },
        {
          name:'MFO (ME) Consumption Idle',
          cons_day_calculation:false,
          cons_mt_calculation:true,
          cons_kl_calculation:true,
          for_mfo:true,
          for_mdo:false,
          cons_day:null,
          cons_mt:null,
          cons_kl:null,
        },
        {
          name:'MDO (AE) Cons. Streaming Idle',
          cons_day_calculation:true,
          cons_mt_calculation:true,
          cons_kl_calculation:true,
          for_mfo:false,
          for_mdo:true,
          cons_day:null,
          cons_mt:null,
          cons_kl:null,
        },
        {
          name:'MDO (AE) Cons. Cargo operation (POL & POD)',
          cons_day_calculation:false,
          cons_mt_calculation:true,
          cons_kl_calculation:true,
          for_mfo:false,
          for_mdo:true,
          cons_day:null,
          cons_mt:null,
          cons_kl:null,
        },
      ],

      cost_factor_multiplication: 30,
      time_voyage_multiplication: 130,

      total_duration: 0,
      total_consumption: 0,

      idle_turn_time: null,
      tc_hire_day: null,

      mfoMultiply:0,
      mdoMultiply:0,

      form: {
        pfa_id:null,
        pfa_name:'',
        cargo_type_id:null,
        status:'active',
        voyage:1,
        quantity:null,
        pol:[{value:''}],
        pod:[{value:''}],
        freight_rate_est:null,
        freight_rate:null,
        freight_rate_usd:null,
        freight_rate_idr:null,
        revenue:null,
        pfa_travel:[{
          laden:null,
          ballast:null,
          est_dist:null,
          speed:null,
          est_time:null
        }],
        pfa_travel_mv:[{
          laden:null,
          ballast:null,
          est_dist:null,
          speed:null,
          est_time_hour:null,
          est_time_day:null,
        },{
          laden:null,
          ballast:null,
          est_dist:null,
          speed:null,
          est_time_hour:null,
          est_time_day:null,
        }],
        date_loading_discharge:{
          loading_commence1:'',
          loading_commence2:'',
          loading_complete1:'',
          loading_complete2:'',
          discharge_commence1:'',
          discharge_commence2:'',
          discharge_complete1:'',
          discharge_complete2:'',
        }
      }
    }
  },
  async mounted () {},

  computed: {
    handleCountGrandTotalExpenseIDR(){
      let result = 0
      
      result = Number(this.handleCountActualIDR) + Number(this.handleCountTotalExpensesMVIDR)

      return result
    },    
    handleCountGrandTotalExpenseUSD(){
      let result = 0
      
      result = Number(this.handleCountGrandTotalExpenseIDR) / Number(this.usdRate)

      return result
    },   
     handleCountActualIDR(){
      let result = 0
      
      result = Number(this.handleCountMFO) + Number(this.handleCountMDO)

      return result
    },  
    handleCountActualUSD(){
      let result = 0
      
      result = Number(this.handleCountMFO) + Number(this.handleCountMDO)

      return result / Number(this.usdRate)
    },
    handleCountMFO(){
      let result = 0

      let filteredArr = this.tableTimeVoyage.filter(val => val.for_mfo === true)
      
      let resultTimeVoyage = filteredArr.reduce((acc, obj) => {
        if(this.handleCountConsMT(obj)?.value){
          acc += Number(this.handleCountConsMT(obj)?.value)
        }

        return acc
      }, 0)

      result = Number(resultTimeVoyage) * Number(this.mfoMultiply)

      return Number(result).toFixed(2)
    },
    handleCountMDO(){
      let result = 0

      let filteredArr = this.tableTimeVoyage.filter(val => val.for_mdo === true)

      let resultTimeVoyage = filteredArr.reduce((acc, obj) => {
        if(this.handleCountConsKL(obj)?.value){
          acc += Number(this.handleCountConsKL(obj)?.value)
        }

        return acc
      }, 0)

      result = Number(resultTimeVoyage) * Number(this.mdoMultiply)

      return Number(result).toFixed(2)
    },
    handleCountTotalExpensesMVUSD(){
      let result = this.tableComponentExpense.reduce((acc, obj) => {
        acc += (Number(this.handleCountComponentExpenseMV(obj).value) / this.usdRate)
        return acc 
      }, 0)

      return result 
    },  
    handleCountTotalExpensesMVIDR(){
      let result = this.tableComponentExpense.reduce((acc, obj) => {
        acc += Number(this.handleCountComponentExpenseMV(obj).value)
        return acc
      }, 0)

      return result
    },  
    handleCountTotalConsKL(){
      let result = 0

      if(this.tableTimeVoyage.length > 0){
        this.tableTimeVoyage.map(val => {
          if(this.handleCountConsKL(val)?.value){
            result += Number(this.handleCountConsKL(val)?.value)
          }
        })
      }
    

      return Number(result).toFixed(2)  
    },
    handleCountTotalConsMT(){
      let result = 0

      if(this.tableTimeVoyage.length > 0){
        this.tableTimeVoyage.map(val => {
          if(this.handleCountConsMT(val)?.value){
            result += Number(this.handleCountConsMT(val)?.value)
          }
        })
      }

      return Number(result).toFixed(2)  
    },
    countPortStayMV(){
      let result = 0

      result = this.tablePortStay.reduce((acc, obj) => {
        acc += Number(obj.laytime)
        return acc
      }, 0)

      return result.toFixed(2)
    },
    handleCountCostM3(){
      let result = 0
      result = this.handleCountGrandTotalExpenses / Number(this.detailProjectContract?.total_quantity)

      return result
    },
    handleCountProfitPersen(){
      let result = 0
      result = this.handleCountProfit / (this.handleCountGrandTotalExpenses / 100)

      return result
    },
    handleCountProfit(){
      let result = 0
      result = this.handleCountRevenue - this.handleCountGrandTotalExpenses

      return result
    },
    handleCountGrandTotalExpenses(){
      let result = 0
      result = this.handleCountTotalMC + this.handleCountTotalOperationalExpenses

      return result
    },  
    handleCountTotalOperationalExpenses(){
      let result = 0
      this.tableComponentExpense.map(val => {
        if(val?.is_calculation){
          let resultExpenses = this.handleCountComponentExpense(val)
          result += resultExpenses
        } else {
          result += Number(val.value)
        }
      })

      return result
    }, 
    handleCountResultPlus(){
      let result = this.tableResultPlus.reduce((acc, obj) => {
        acc += Number(obj.value)
        return acc
      }, 0)

      return result
    },  
    handleCountRevenue(){
      let revenue = Number(this.form?.quantity) * Number(this.form?.freight_rate)

      return revenue
    },
    handleCountTotalMC(){
      let result = this.tableMiscellaneousCharge.reduce((acc, obj) => {
        acc += Number(obj.value)
        return acc
      }, 0)

      return result
    },
    handleCountDuration(){
      let result = this.tableTimeVoyage.reduce((acc, obj) => {
        acc += Number(obj.streaming_time)
        return acc
      }, 0)

      return result
    },
    handleCountConsumption(){
      let result = this.tableTimeVoyage.reduce((acc, obj) => {
        acc += Number(obj.cons)
        return acc
      }, 0)

      return result
    },
    handleCountDurationStreamingTime(){
      let result = 0

      result = this.tablePortStay.reduce((acc, obj) => {
        acc += Number(obj.streaming_time)
        return acc
      }, 0)
  
      return result.toFixed(2)
    },  
    handleCountGrandTotalStreamingTime(){
      let result = this.tablePortStay.reduce((acc, obj) => {
        acc += Number(obj.streaming_time)
        return acc
      }, 0)

      let resultTimeVoyage = this.tableTimeVoyage.reduce((acc, obj) => {
        acc += Number(obj.streaming_time)
        return acc
      }, 0)

      let granTotalResult = result + (resultTimeVoyage / 24)
  
      return granTotalResult.toFixed(2)
    }, 
    handleCountDurationIdle(){
      let result = 0

      result = this.tablePortStay.reduce((acc, obj) => {
        acc += Number(obj.idle)
        return acc
      }, 0)

      return result.toFixed(2)
    }, 
    handleCountGrandTotalIdle(){
      let result = this.tablePortStay.reduce((acc, obj) => {
        acc += Number(obj.idle)
        return acc
      }, 0)

      let resultTimeVoyage = this.tableTimeVoyage.reduce((acc, obj) => {
        acc += Number(obj.cons)
        return acc
      }, 0)

      let granTotalResult = result + resultTimeVoyage

      return granTotalResult.toFixed(2)
    }, 
  },
  watch: {
    pfaId: {
      deep: true,
      handler: function (value) {
        if (value) {
          this.getDetailProjectContract(value)
        }
      },
    }
  },
  methods:{
    ...busdevActions,
    convertComponentExpenseToUSD(value){
      let result = 0

      result = Number(value) / this.usdRate

      return result
    },
    handleCountComponentExpenseMV(state){
      let multiplyBy = 0
      let value = 0

      if(state.sum_port_stay){
        let countLaytime = 0
        this.form.pfa_travel_mv.map(val => {
          countLaytime += Number(this.handleCountEstDay(val.est_time))
        })
       
        multiplyBy = Number(this.tc_hire_day)
        value = (Number(this.countPortStayMV) + Number(this.idle_turn_time) + Number(countLaytime)) * multiplyBy
        state.value = value
      } else if(state.more_calculation_operator) {
        value = this.countBasedOnOperator(Number(state.value), state?.more_calculation_number, state.more_calculation_operator)
      } else {
        value = state.value
      }

      return {
        multiplyBy,
        value
      }
    },
    handleCountConsDay(state){
      let multiplyBy = 0
      let value = 0

     
      if(state.key === 5){
        multiplyBy = 0.86
      }

      return {
        multiplyBy,
        value
      }
    }, 
    handleCountConsMT(state){
      let multiplyBy = 0
      let value = 0

      if([1].includes(state.key)){
        multiplyBy = this.handleCountEstDay(this.form.pfa_travel_mv[1]?.est_time).toFixed(2)
        value = state.cons_day * multiplyBy
        state.cons_mt = state.cons_day 
      } else if([2].includes(state.key)){
        multiplyBy = this.handleCountEstDay(this.form.pfa_travel_mv[0]?.est_time).toFixed(2)
        value = state.cons_day * multiplyBy
        state.cons_mt = state.cons_day 
      } else if([3, 6].includes(state.key)){
        multiplyBy = this.countPortStayMV
        state.cons_mt = state.cons_day 
        value = state.cons_mt * multiplyBy
      } else if([4, 5].includes(state.key)){
        multiplyBy = this.idle_turn_time
        state.cons_mt = state.cons_day 
        value = Number(state.cons_mt) * Number(multiplyBy)
      } 

      return {
        multiplyBy: Number(multiplyBy).toFixed(2),
        value: Number(value).toFixed(2),
      }
    }, 
    handleCountConsKL(state){
      let multiplyBy = 0
      let value = 0

      if([1, 2, 3, 4].includes(state.key)){
        multiplyBy = 0.9695
        state.cons_kl = this.handleCountConsMT(state).value
        value = state.cons_kl / multiplyBy
      } else {
        multiplyBy = 0.86
        state.cons_kl = this.handleCountConsMT(state).value
        value = state.cons_kl / multiplyBy
      }

      return {
        multiplyBy: Number(multiplyBy),
        value: Number(value).toFixed(2),
      }
    },
    handleCountLaytime(state){
      let result = 0

      if(state.is_calculation){
        result = this.form.quantity / Number(state.rate)
      }

      state.laytime = result.toFixed(1)
    },
    handleCountEstDay(estTime){
      let result = 0
      let tempEstTime = estTime ?? 0
      result = Number(tempEstTime) / 24

      return result
    }, 
    handleCountIDRToUSD(idr, usdRate){
      let result = 0
      let idrVal = idr ?? 0
      result = Number(idrVal) / usdRate

      return result
    },
    showVehicleName(value){
      // let vehicle = ''
      let vehicle = this.optionsFleets.find(obj => obj.value == value)

      return vehicle?.text ?? '-'
    },
    unitName(cargoTypeId){
      let cargo = this.optionsCargoType.find(obj => obj.value == cargoTypeId).name

      return cargo ?? 'N/A'
    },
    countBasedOnOperator(acc, obj, operator){
      if(operator == 'addition'){
        acc = acc + obj
      } else if(operator == 'subtraction'){
        acc = acc - obj
      } else if(operator == 'multiplication'){
        acc = acc * obj
      } else if(operator == 'division'){
        acc = acc / obj
      } else if(operator == 'modulus'){
        acc = acc % obj
      } else {
        acc = 0
      }
      return acc
    },
    operatorSymbol(operator){
      if(operator == 'addition'){
        return '+'
      } else if(operator == 'subtraction'){
        return '-'
      } else if(operator == 'multiplication'){
        return '*'
      } else if(operator == 'division'){
        return '/'
      } else if(operator == 'modulus'){
        return '%'
      } else {
        return ''
      }
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
    formatUSD(value){
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(value);
    },
    handleCountComponentExpense(value){
      let result = 0
      let costFactor = this.tableCostFactor.find(obj => obj.id === value.cost_factor_id)

      let costFactorValue = 0
      if(costFactor?.is_calculation){
        costFactorValue = this.countBasedOnOperator(Number(costFactor.value), this.cost_factor_multiplication, costFactor.operator)
      } else {
        costFactorValue = Number(costFactor.value)
      }

      if(costFactor && this.handleCountGrandTotalStreamingTime && this.handleCountGrandTotalIdle){
        let choosedGrandTotal = null
        if(value.multiplyBy == 1){
          choosedGrandTotal = this.handleCountGrandTotalStreamingTime
        } else if(value.multiplyBy == 2){
          choosedGrandTotal = this.handleCountGrandTotalIdle
        } else {
          choosedGrandTotal = 1
        }

        let totalCounted = this.countBasedOnOperator(Number(choosedGrandTotal), Number(costFactorValue), value.operator)
        result += totalCounted
      }

      if(value.more_calculation){
        let totalCounted = this.countBasedOnOperator(result, value.more_calculation_number, value.more_calculation_operator)
        result = totalCounted
      }
      return result
    },
    handleCalcute(key,formatIDR, value, multiplication){
      let result = 0
      if(key === 'addition') {
        result = Number(value) + multiplication
      } else if(key === 'subtraction') {
        result = Number(value) - multiplication
      } else if(key === 'multiplication') {
        result = Number(value) * multiplication
      } else if(key === 'division') {
        result = Number(value) / multiplication
      } else if(key === 'modulus') {
        result = Number(value) % multiplication
      } else {
        result = 0
      }

      return result.toFixed(2)
    },
    handleCountIdle(value, multiplication){
      if(value.streaming_time && multiplication && value?.is_calculation) {
        if(value.operator === 'addition') {
          value.idle = value.streaming_time + multiplication
        } else if(value.operator === 'subtraction') {
          value.idle = value.streaming_time - multiplication
        } else if(value.operator === 'multiplication') {
          value.idle = value.streaming_time * multiplication
        } else if(value.operator === 'division') {
          value.idle = value.streaming_time / multiplication
        } else if(value.operator === 'modulus') {
          value.idle = value.streaming_time % multiplication
        } else {
          value.idle = 0
        }
      } else {
        value.idle = 0
      }
    },
    handleCountCons(value, multiplication){
      if(value.streaming_time && multiplication) {
        value.cons = value.streaming_time * multiplication
      } else {
        value.cons = 0
      }
    },
    handleCountEstTime(value){
      if(value.est_dist && value.speed) {
        value.est_time = value.est_dist / value.speed
      } else {
        value.est_time = 0
      }
    },
    handleAddPfaTravel(travelData, type){
      let length = travelData.length
      let previousData = travelData[length - 1]

      if(type === 'TUG BOAT'){
        travelData.push({
          laden:previousData.ballast,
          ballast:null,
          est_dist:null,
          speed:null,
          est_time:null
        })
      } else if(type === 'MV'){
        travelData.push({
          laden:previousData.ballast,
          ballast:null,
          est_dist:null,
          speed:null,
          est_time_hour:null,
          est_time_day:null,
        })
      } 
    },
    handleCountVoyage(key){
      if(key){
        if(this.form.voyage === 10) return
        this.form.voyage += 1
      } else {
        if(this.form.voyage === 1) return
        this.form.voyage -= 1
      }
    },
    async getDetailProjectContract(id){
      this.loading = true

      let res = await this.fetchDetailProjectContract({id})

      if (res.data.status === true) {
        this.detailProjectContract = res.data.data
        let {data} = await this.fetchMasterPfaById({id: Number(this.detailProjectContract?.fleet_type_id)})
        this.getPfaNextId()
        if(!this.detailProjectContract?.project_pfa){
          this.createForm(data)
        } else {
          this.setForm()
        }
      } else {
        this.detailProjectContract = null
      }

      this.loading = false
    }, 
    async getPfaNextId(){
      let res = await this.fetchPfaNextId()

      if (res.data.status === true) {
        this.form.pfa_id = `PFA - ${res.data.data}`
      } else {
        this.form.pfa_id = `PFA - ${0}`
      }
    },

    async createForm(data){
      if(['TUG BOAT'].includes(this.detailProjectContract?.fleet_type?.name)){
        this.form = {
          ...this.form,
          cargo_type_id:this.detailProjectContract?.cargo_type_id,
          fleets:this.detailProjectContract?.fleets,
          pol: this.detailProjectContract?.pol,
          pod:this.detailProjectContract?.pod,
          quantity:this.detailProjectContract?.total_quantity,
          freight_rate_est:this.detailProjectContract?.freight_rate_est,
          freight_rate:this.detailProjectContract?.freight_rate,
          pfa_travel:[{
            laden:this.detailProjectContract?.pol?.[0]?.value,
            ballast:this.detailProjectContract?.pod?.[0]?.value,
            est_dist:null,
            speed:null,
            est_time:null
          }],
        }
        this.tableComponentExpense = data.data.component_expense.map(obj => {
          return {
            ...obj,
            value:null,
            multiplyBy: null
          }
        })
        this.tableCostFactor = data.data.cost_factor.map(obj => {
          return {
            ...obj,
            value:null
          }
        })

        this.tablePortStay = data.data.port_stay.map(obj => {
          return {
            ...obj,
            streaming_time:null,
            idle:null
          }
        })

        await this.getListTimeVoyage()
      } else if (['MOTHER VESSEL'].includes(this.detailProjectContract?.fleet_type?.name)){
        this.form = {
        ...this.form,
        cargo_type_id:this.detailProjectContract?.cargo_type_id,
        fleets:this.detailProjectContract?.fleets,
        pol: this.detailProjectContract?.pol,
        pod:this.detailProjectContract?.pod,
        quantity:this.detailProjectContract?.total_quantity,
        freight_rate_est:this.detailProjectContract?.freight_rate_est,
        freight_rate:this.detailProjectContract?.freight_rate,
        freight_rate_idr:this.detailProjectContract?.freight_rate,
        freight_rate_usd:Number(this.detailProjectContract?.freight_rate) / 15500,
        pfa_travel:[{
          laden:this.detailProjectContract?.pol?.[0]?.value,
          ballast:this.detailProjectContract?.pod?.[0]?.value,
          est_dist:null,
          speed:null,
          est_time:null
        }],
        pfa_travel_mv:[{
          laden:this.detailProjectContract?.pol?.[0]?.value,
          ballast:this.detailProjectContract?.pod?.[0]?.value,
          est_dist:null,
          speed:null,
          est_time_hour:null,
          est_time_day:null,
        },{
          laden:this.detailProjectContract?.pod?.[0]?.value,
          ballast:'',
          est_dist:null,
          speed:null,
          est_time_hour:null,
          est_time_day:null,
        }],
      }
        this.tableComponentExpense = data.data.component_expense.map(obj => {
          return {
            ...obj,
            value:null,
          }
        })
        this.tableCostFactor = [{
          key:1,
          name:'MFO Agustus 2023 (Bunker Balikpapan 21 Agustus 2023 )',
          unit:'MT',
          value:null
        }, {
          key:2,
          name:'MDO Agustus 2023 (Bunker 8 Agustus 2023)',
          unit:'KL',
          value:null
        }]
        this.tablePortStay = data.data.port_stay.map(obj => {
          return {
            ...obj,
            rate:null,
            laytime:null
          }
        })
        this.mvTimeVoyage.map((val, index) => {
          this.tableTimeVoyage.push({
            ...val,
            key: index + 1
          })
        })
      }

      await this.getListCharterCompany()
      // await this.getListCostFactor()
      await this.getListMiscellaneousCharge()
   
      await this.getListResultPlus()
      // await this.getListComponentExpense()
    }, 
    setForm(){
     if(['TUG BOAT'].includes(this.detailProjectContract?.fleet_type?.name)){
      let pfaData = this.detailProjectContract?.project_pfa?.pfa
      this.form = {
        ...this.form,
        pfa_name:pfaData.pfa_name,
        cargo_type_id:pfaData.cargo_type_id,
        status:pfaData.status,
        voyage:pfaData.voyage,
        quantity:pfaData.quantity,
        pol:pfaData.pol,
        pod:pfaData.pod,
        freight_rate_est:pfaData.freight_rate_est,
        freight_rate:pfaData.freight_rate,
        pfa_travel:pfaData.pfa_travel.length > 0 ? pfaData.pfa_travel : [{
          laden:null,
          ballast:null,
          est_dist:null,
          speed:null,
          est_time:null
        }],
        date_loading_discharge:pfaData.date_loading_discharge ?? {
          loading_commence1:'',
          loading_commence2:'',
          loading_complete1:'',
          loading_complete2:'',
          discharge_commence1:'',
          discharge_commence2:'',
          discharge_complete1:'',
          discharge_complete2:'',
        }
      }

      this.tableTimeVoyage = pfaData.dynamicData.tableTimeVoyage
      this.tableCharterCompany = pfaData.dynamicData.tableCharterCompany
      this.tableCostFactor = pfaData.dynamicData.tableCostFactor
      this.tableMiscellaneousCharge = pfaData.dynamicData.tableMiscellaneousCharge
      this.tablePortStay = pfaData.dynamicData.tablePortStay
      this.tableResultPlus = pfaData.dynamicData.tableResultPlus
      this.tableComponentExpense = pfaData.dynamicData.tableComponentExpense
     } else if (['MOTHER VESSEL'].includes(this.detailProjectContract?.fleet_type?.name)){
      let pfaData = this.detailProjectContract?.project_pfa?.pfa
      this.form = {
        ...this.form,
        pfa_name:pfaData.pfa_name,
        cargo_type_id:pfaData.cargo_type_id,
        status:pfaData.status,
        voyage:pfaData.voyage,
        quantity:Number(pfaData.quantity),
        pol:pfaData.pol,
        pod:pfaData.pod,
        freight_rate:Number(pfaData?.freight_rate),
        freight_rate_idr:Number(pfaData?.freight_rate_idr),
        freight_rate_usd:Number(pfaData?.freight_rate_usd),
        pfa_travel_mv:pfaData.pfa_travel_mv.length > 0 ? pfaData.pfa_travel_mv : [{
          laden:this.pfaData?.pol?.[0]?.value,
          ballast:this.pfaData?.pod?.[0]?.value,
          est_dist:null,
          speed:null,
          est_time_hour:null,
          est_time_day:null,
        },{
          laden:this.pfaData?.pod?.[0]?.value,
          ballast:'',
          est_dist:null,
          speed:null,
          est_time_hour:null,
          est_time_day:null,
        }],
      }
      this.mfoMultiply = Number(pfaData.mfoMultiply)
      this.mdoMultiply = Number(pfaData.mdoMultiply)
      this.idle_turn_time = Number(pfaData.idle_turn_time)
      this.tc_hire_day = Number(pfaData.tc_hire_day)

      this.tableCostFactor = pfaData.dynamicData.tableCostFactor
      this.tablePortStay = pfaData.dynamicData.tablePortStay
      this.tableTimeVoyage = pfaData.dynamicData.tableTimeVoyage
      this.tableComponentExpense = pfaData.dynamicData.tableComponentExpense
      this.tableCharterCompany = pfaData.dynamicData.tableCharterCompany
     }
    },
    async getListTimeVoyage () {
      this.tableTimeVoyage = []
      let params = {
        perPage:10000,
        page:1
      }
      const res = await this.fetchTimeVoyage(params)
  
      if (res.data.status == true) {
        if(res?.data?.data?.data.length > 0){
          this.tableTimeVoyage = res.data.data?.data?.map(val => {
            return {
              ...val,
              streaming_time:null,
              cons:null,
            }
          })
        }
      } 
    },
    async getListCharterCompany () {
      this.tableCharterCompany = []
      let params = {
        perPage:10000,
        page:1
      }
      const res = await this.fetchCharterCompany(params)
  
      if (res.data.status == true) {
        if(res?.data?.data?.data.length > 0){
          this.tableCharterCompany = res.data.data?.data.map(val => {
            return {
              ...val,
              value:''
            }
          })
        }
      } 
    },
    async getListCostFactor () {
      this.tableCostFactor = []
      let params = {
        perPage:10000,
        page:1
      }
      const res = await this.fetchCostFactor(params)
  
      if (res.data.status == true) {
        if(res?.data?.data?.data.length > 0){
          this.tableCostFactor = res.data.data?.data.map(val => {
            return {
              ...val,
              value:null
            }
          })
        }
      } 
    },
    async getListMiscellaneousCharge() {
      this.tableMiscellaneousCharge = []
      let params = {
        perPage:10000,
        page:1
      }
      const res = await this.fetchMiscellaneousCharge(params)
  
      if (res.data.status == true) {
        if(res?.data?.data?.data.length > 0){
          this.tableMiscellaneousCharge = res.data.data?.data.map(val => {
            return {
              ...val,
              value:null
            }
          })
        }
      } 
    },
    async getListPortStay () {
      this.tablePortStay = []
      let params = {
        perPage:10000,
        page:1
      }
      const res = await this.fetchPortStay(params)
  
      if (res.data.status == true) {
        if(res?.data?.data?.data.length > 0){
          this.tablePortStay = res.data.data?.data.map(val => {
            return {
              ...val,
              streaming_time:null,
              idle:null
            }
          })
        }
      } 
    },
    async getListResultPlus () {
      this.tableResultPlus = []
      let params = {
        perPage:10000,
        page:1
      }
      const res = await this.fetchResultPlus(params)
  
      if (res.data.status == true) {
        if(res?.data?.data?.data.length > 0){
          this.tableResultPlus = res.data.data?.data.map(val => {
            return {
              ...val,
              value:null
            }
          })
        }
      } 
    },
    async getListComponentExpense () {
      this.tableComponentExpense = []
      let params = {
        perPage:10000,
        page:1
      }
      const res = await this.fetchComponentExpense(params)
  
      if (res.data.status == true) {
        if(res?.data?.data?.data.length > 0){
          this.tableComponentExpense = res.data.data?.data.map(val => {
            return {
              ...val,
              value:null,
              multiplyBy: null
            }
          })
        }
      } 
    },

    resetForm(){
      this.detailProjectContract = null
      this.tableTimeVoyage = [] 
      this.tableCharterCompany = []
      this.tableCostFactor = []
      this.tableMiscellaneousCharge = []
      this.tablePortStay = []
      this.tableResultPlus = []
      this.tableComponentExpense = []
      this.cost_factor_multiplication = 30
      this.time_voyage_multiplication = 130
      this.total_duration = 0
      this.total_consumption = 0 
      this.form = {
        pfa_id:null,
        pfa_name:'',
        cargo_type_id:null,
        status:'active',
        voyage:1,
        quantity:null,
        pol:[{value:''}],
        pod:[{value:''}],
        freight_rate_est:null,
        freight_rate:null,
        revenue:null,
        pfa_travel:[{
          laden:null,
          ballast:null,
          est_dist:null,
          speed:null,
          est_time:null
        }],
        date_loading_discharge:{
          loading_commence1:'',
          loading_commence2:'',
          loading_complete1:'',
          loading_complete2:'',
          discharge_commence1:'',
          discharge_commence2:'',
          discharge_complete1:'',
          discharge_complete2:'',
        }
      }
    },

    async onSave(){
      this.saving = true

      let payload = {}

      if(['TUG BOAT'].includes(this.detailProjectContract?.fleet_type?.name)){
        payload = {
          ...this.form, 
          fleet_type:this.detailProjectContract?.fleet_type?.name,
          fleets:this.detailProjectContract?.fleets?.map(val => {
            return {
              fleet_name:this.showVehicleName(val?.vehicle_id)
            }
          }),
          cargo_type:this.detailProjectContract?.cargo_type?.name,
          unit:this.detailProjectContract?.unit,
          dynamicData: {
            tableTimeVoyage : this.tableTimeVoyage,
            tableCharterCompany: this.tableCharterCompany,
            tableCostFactor: this.tableCostFactor,
            tableMiscellaneousCharge: this.tableMiscellaneousCharge,
            tablePortStay: this.tablePortStay,
            tableResultPlus: this.tableResultPlus,
            tableComponentExpense: this.tableComponentExpense,
          },
          countedDynamicData: {
            tableTimeVoyage : this.tableTimeVoyage,
            tableCharterCompany: this.tableCharterCompany,
            tableCostFactor: this.tableCostFactor.map(val => {
              return {
                ...val,
                value: val?.is_calculation ? this.handleCalcute(val.operator, true, Number(val.value), this.cost_factor_multiplication) : val.value
              }
            }),
            tableMiscellaneousCharge: this.tableMiscellaneousCharge,
            tablePortStay: this.tablePortStay.map(val => {
              return {
                ...val,
                idle: val?.is_calculation ? this.handleCalcute(val.operator,false, val.streaming_time, 168) : val.idle
              }
            }),
            tableResultPlus: this.tableResultPlus,
            tableComponentExpense: this.tableComponentExpense.map(val => {
              let result = 0
              if(val?.is_calculation) {
                result = this.handleCountComponentExpense(val)
              }

              return {
                ...val,
                countedValue: result
              }
            }),
    
            // TOTAL TIME VOYAGE
            time_voyage_total_streaming_time: Number(this.handleCountDuration),
            time_voyage_total_cons: Number(this.handleCountConsumption),

            time_voyage_total_streaming_time: Number(this.handleCountDuration),
            time_voyage_total_cons: Number(this.handleCountConsumption),

            // PORT STAY
            port_stay_total_streaming_time: Number(this.handleCountDurationStreamingTime),
            port_stay_total_idle: Number(this.handleCountDurationIdle),
            port_stay_grand_total_streaming_time: Number(this.handleCountGrandTotalStreamingTime),
            port_stay_grand_total_idle: Number(this.handleCountGrandTotalIdle),

            // Miscellaneous Charge
            mc_total: Number(this.handleCountTotalMC),

            // RESULT PLUS
            result_plus_total: Number(this.handleCountResultPlus),

            // OPERATIONAL EXPENSES
            total_operational_expenses: Number(this.handleCountTotalOperationalExpenses),
            revenue: Number(this.handleCountRevenue),
            grand_total_expenses: Number(this.handleCountGrandTotalExpenses),
            profit: Number(this.handleCountProfit),
            profit_percentage: Number(this.handleCountProfitPersen),
            profit_per_mt: Number(this.handleCountCostM3),
          }
        }
      } else if(['MOTHER VESSEL'].includes(this.detailProjectContract?.fleet_type?.name)){
        payload = {
          ...this.form, 
          fleet_type:this.detailProjectContract?.fleet_type?.name,
          freight_rate: Number(this.form?.freight_rate_idr),
          fleets:this.detailProjectContract?.fleets?.map(val => {
            return {
              fleet_name:this.showVehicleName(val?.vehicle_id)
            }
          }),
          pfa_travel_mv:this.form.pfa_travel_mv.map(val => {
            return {
              ...val,
              est_time_day: Number(this.handleCountEstDay(val?.est_time)).toFixed(2)
            }
          }),
          cargo_type:this.detailProjectContract?.cargo_type?.name,
          unit:this.detailProjectContract?.unit,
          mfoMultiply: Number(this.mfoMultiply), 
          mdoMultiply: Number(this.mdoMultiply),
          idle_turn_time: Number(this.idle_turn_time),
          tc_hire_day: Number(this.tc_hire_day),
          usdRate: Number(this.usdRate),
          dynamicData: {
            tableCostFactor: this.tableCostFactor,
            tablePortStay: this.tablePortStay,
            tableTimeVoyage: this.tableTimeVoyage,
            tableComponentExpense: this.tableComponentExpense,
            tableCharterCompany: this.tableCharterCompany,
          },
          countedDynamicData: {
            revenue:Number(this.handleCountRevenue),
            tableCharterCompany: this.tableCharterCompany,
            tableCostFactor: this.tableCostFactor.map(val => {
              return {
                ...val,
                value: Number(val.value)
              }
            }),
            tablePortStay: this.tablePortStay.map(val => {
              return { 
                ...val,
                rate:Number(val.rate).toFixed(2),
                laytime:Number(val.laytime).toFixed(2),
              }
            }),
            idle_turn_time: Number(this.idle_turn_time),
            tc_hire_day: Number(this.tc_hire_day),
            tableTimeVoyage: this.tableTimeVoyage.map(val => {
              return { 
                ...val,
                countedConsDay:Number(this.handleCountConsDay(val).value).toFixed(2),
                countedConsMT:Number(this.handleCountConsMT(val).value).toFixed(2),
                countedConsKL:Number(this.handleCountConsKL(val).value).toFixed(2),
              }
            }),
            totalConsMT: Number(this.handleCountTotalConsMT).toFixed(2),
            totalConsKL: Number(this.handleCountTotalConsKL).toFixed(2),
            tableComponentExpense: this.tableComponentExpense.map(val => {
              let countedIDR = 0
              let countedUSD = 0
              if(val.sum_port_stay){
                countedIDR = this.handleCountComponentExpenseMV(val)?.value
              } else if(!val?.is_calculation && !val?.more_calculation && !val.sum_port_stay){
                countedIDR = val?.value
              } else if ((val?.is_calculation || val?.more_calculation) && !val.sum_port_stay){
                countedIDR = this.handleCountComponentExpenseMV(val).value
              }

              countedUSD = this.convertComponentExpenseToUSD(this.handleCountComponentExpenseMV(val)?.value)

              return {
                ...val,
                countedIDR: Number(countedIDR).toFixed(2),
                countedUSD: Number(countedUSD).toFixed(2)
              }
            }),
            totalComponentExpenseIDR: Number(this.handleCountTotalExpensesMVIDR).toFixed(2),
            totalComponentExpenseUSD: Number(this.handleCountTotalExpensesMVUSD).toFixed(2),
            variableCost: {
              mfoIDR: Number(this.handleCountMFO).toFixed(2),
              mfoUSD: (Number(this.handleCountMFO) / Number(this.usdRate)).toFixed(2),
              mdoIDR: Number(this.handleCountMDO).toFixed(2),
              mdoUSD: (Number(this.handleCountMDO) / Number(this.usdRate)).toFixed(2),
              actualFuelIDR: Number(this.handleCountActualIDR).toFixed(2),
              actualFuelUSD: Number(this.handleCountActualUSD).toFixed(2)
            },
            totalGrandExpenseIDR: Number(this.handleCountGrandTotalExpenseIDR).toFixed(2),
            totalGrandExpenseUSD: Number(this.handleCountGrandTotalExpenseUSD).toFixed(2),
          } 
        }
      }

      let newPayload = {
        project_contract_id:this.detailProjectContract?.id,
        pfa: payload
      }

      let res = await this.createOrUpdateProjectPfa({data: newPayload})

      if(res.data.status == true){
        this.saving = false

        this.resetForm()
        this.refreshDataTable()
        this.openPfaSlide(null)
        this.$swal(`Success!`, `Create PFA.`, 'success')
      } else {
        this.saving = false
        this.$swal(`Oops.`, res?.data?.message, 'error')
      }
      this.saving = false
    }
  }
}
</script>
