<template>
  <b-row>
    <b-col md="12">
      <iq-card>
        <template v-slot:headerTitle>
            <h5 class="card-title text-primary"><strong>RUNNING SHIPMENT</strong></h5>
        </template>
        <template v-slot:headerAction>
          <b-dropdown v-b-tooltip.top="'Export Data'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
              <template v-slot:button-content>
                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                  <i class="fa fa-save m-0"></i>
                  </span>
              </template>
              <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-excel mr-2"></i>Export Excel</b-dropdown-item>
              <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
          </b-dropdown>
          <b-button v-b-tooltip.top="'Refresh Data'" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-refresh"></i></b-button>
          <b-button @click="realisasiData = !realisasiData;"  variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-plus"></i> &nbsp; ADD REALISASI DATA</b-button>
        </template>
        <template v-slot:body>
          <!-- FILTER START -->
          <b-row>
              <b-col md="3">
                <b-form-group label="Period" label-for="Period">
                  <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range"></date-picker>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Cargo Type" label-for="cargo">
                  <b-form-select plain>
                    <b-form-select-option>All</b-form-select-option>
                    <b-form-select-option>COAL</b-form-select-option>
                    <b-form-select-option>SPLIT STONE</b-form-select-option>
                    <b-form-select-option>NICKEL</b-form-select-option>
                    <b-form-select-option>CPO</b-form-select-option>
                    <b-form-select-option>ASD</b-form-select-option>
                    <b-form-select-option>OTHERS</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Ownership" label-for="cargo">
                  <b-form-select plain>
                    <b-form-select-option>All</b-form-select-option>
                    <b-form-select-option>Own Ship</b-form-select-option>
                    <b-form-select-option>Third Party</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Keyword" label-for="keyword">
                  <b-form-input type="text" id="keyword"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group style label="Search" label-for="button_search">
                  <b-button variant="primary" class="mb-3 mr-1"><i class="fa fa-search"></i></b-button>
                </b-form-group>
              </b-col>
          </b-row>
          <!-- FILTER END -->

          <b-row>
            <b-col md="12">
              <div class="table-responsive">
                <table class="table" style="width:5000px;max-height:750px;overflow-y:scroll;">
                  <thead>
                    <tr>
                      <!-- <th style="position:sticky;left:0px; width:50px" class="text-center table-primary">NO</th>
                      <th style="position:sticky;left:50px; width:150px" class="text-center table-primary">FLEET</th>
                      <th style="position:sticky;left:200px; width:250px" class="text-center table-primary">PROJECT NAME</th>
                      <th style="position:sticky;left:450px; width:150px" class="text-center table-primary">CARGO & TERM</th> -->

                      <th style="position:sticky;left:0px; width:50px" class="text-center table-info">NO</th>
                      <th style="position:sticky;left:50px; width:150px" class="text-center table-info">SHIPPER</th>
                      <th style="position:sticky;left:200px; width:250px" class="text-center table-info">SHIPMENT</th>
                      <th style="position:sticky;left:450px; width:100px" class="text-center table-info">STATUS</th>
                      <th class="text-center table-info">MODA</th>
                      <th style="width:200px" class="text-center table-info">FLEET</th>
                      <th style="width:200px" class="text-center table-info">COMPANY</th>
                      <th style="width:150px" class="text-center table-info">CARGO TYPE</th>
                      <th style="width:150px" class="text-center table-info">POL</th>
                      <th style="width:150px" class="text-center table-info">TA POL/READY</th>
                      <th style="width:150px" class="text-center table-info">BERTHING</th>
                      <th style="width:150px" class="text-center table-info">COMMENCED</th>                          
                      <th class="text-center table-info">COMPLETED</th>
                      <th class="text-center table-info">CARGO LOADING</th>  
                      <th class="text-center table-info">POD</th>
                      <th class="text-center table-info">TA POD/READY</th>
                      <th class="text-center table-info">BERTHING</th>
                      <th class="text-center table-info">COMMENCED</th>                          
                      <th class="text-center table-info">COMPLETED</th>
                      <th class="text-center table-info">CARGO UNLOADING</th>
                      <th class="text-center table-info">PRICE</th>
                      <th class="text-center table-info">PRICE TO VENDOR</th>
                      <th class="text-center table-info">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="position:sticky;left:0px; width:50px; background-color: white;" class="text-center">1</td>
                      <td style="position:sticky;left:50px; width:150px; background-color: white;" class="text-center">PLN Rembang - Longhauling<br><b-badge variant="info">PT.Arutmin Indonesia</b-badge></td>
                      <td style="position:sticky;left:200px; width:250px; background-color: white;" class="text-center">JANUARY</td>
                      <td style="position:sticky;left:450px; width:100px; background-color:white;" class="text-center"><b-badge variant="success">Completed</b-badge></td>
                      <td class="text-center">330 ft</td>
                      <td class="text-center">PT.TCP 201/PB.TCP 3301</td>
                      <td class="text-center">PT.Transcoal Pacific</td>
                      <td class="text-center">Nickel</td>
                      <td class="text-center">MP Asam2</td>
                      <td class="text-center">31-Dec-21 00.00</td>
                      <td class="text-center">02-Jan-22 02.10</td>
                      <td class="text-center">02-Jan-22 03.05</td>
                      <td class="text-center">02-Jan-22 05.05</td>
                      <td class="text-center">7.606 MT</td>
                      <td class="text-center">LABUAN</td>
                      <td class="text-center">04-Jan-22 20.00</td>
                      <td class="text-center">04-Jan-22 20.00</td>
                      <td class="text-center">04-Jan-22 20.00</td>
                      <td class="text-center">04-Jan-22 20.00</td>
                      <td class="text-center">7.506 MT</td>
                      <td class="text-center">IDR 198.078</td>
                      <td class="text-center">-</td>
                      <td class="text-center">
                        <b-dropdown size="lg" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                          <template v-slot:button-content>
                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                              <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                            </span>
                          </template>
                          <b-dropdown-item href="#" v-b-toggle.add-activity>
                            <i class="fa fa-info-circle mr-1"></i>Show
                          </b-dropdown-item>
                          <b-dropdown-item href="#">
                            <i class="fa fa-trash mr-1"></i>Delete
                          </b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
            <b-col md="12">
              <div class="mt-3">
                  <b-pagination :value="1" :total-rows="50" align="center" />
              </div>
            </b-col>
          </b-row>

          <!-- REALISASI DATA -->
          <b-sidebar
            v-model="realisasiData"
            id="realisasiData"
            title="ADD REALISASI DATA"
            width="90%"
            backdrop-variant="dark"
            bg-variant="white"
            right
            backdrop
            shadow>
            <template #default="{hide}">
              <b-row class="m-3">
                <b-col md="12">
                  <fieldset class="form-group border p-3 bg-default">
                    <legend class="w-auto px-2 h5 text-primary">Find Data</legend>
                    <b-form-row>
                      <b-col md="2">
                        <b-form-group label="Realisasi Period" label-for="Period">
                          <date-picker :lang="lang" v-model="realisasiPeriod" type="month" placeholder="Select Month"></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label-for="dateIssued" label="Project Filter">
                          <v-multiselect v-model="filterDailyOps.subProject" :options="projectOptions" :multiple="true" group-values="sub" group-label="project" :group-select="true" placeholder="Select one" track-by="name" label="name">
                            <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                          </v-multiselect>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group label="Search" label-for="kind-fleet" class="mb-0">
                          <b-button type="submit" variant="success"><i class="fa fa-search"></i></b-button>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                  </fieldset>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 h5 text-primary">Select Shipper Data</legend>
                    <b-row class="pl-3 pr-3">
                      <b-col cols="12">
                        <b-alert :show="true" variant=" " class="text-white bg-warning">
                          <div class="iq-alert-icon">
                            <i class="ri-information-fill"></i>
                          </div>
                          <div class="iq-alert-text">Not Found Data of Shipper in this period</div>
                        </b-alert>
                      </b-col>
                      <b-col md="12">
                        <div class="table-responsive">
                          <table class="table table-striped table-sm">
                            <thead>
                              <tr class="bg-primary">
                                <th class="text-center">NO</th>
                                <th class="text-center">TYPE</th>
                                <th class="text-center">PROJECT</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="iq-bg-primary">
                                <th class="text-center">1</th>
                                <th class="text-center"><b-badge class="border border-primary text-primary" variant="none">EXISTING</b-badge></th>
                                <td class="text-center"><strong>ARUTMIN</strong></td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <table class="table mb-0" width="100%">
                                    <thead>
                                      <tr class="bg-info">
                                        <th>#</th>
                                        <th width="17%">Description</th>
                                        <th>Fleet</th>
                                        <th>Cargo & Term</th>
                                        <th>Location</th>
                                        <th class="text-center" width="18%">Shipment Plan</th>
                                        <th class="text-center">Plan Quantity</th>
                                        <th>Remarks</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td><input type="checkbox" name="shipper1" id="shipper1"/></td>
                                        <td>PLN Rembang</td>
                                        <td><b-badge variant="info">Third Party</b-badge><br/> TB.Dharma 1</td>
                                        <td>
                                          <b-form-group class="row mb-2" label-cols-md="5" label="CARGO TYPE" label-for="descriptionItem">
                                            <p><strong>COAL</strong></p>
                                          </b-form-group>
                                          <b-form-group class="row mb-2" label-cols-md="5" label="TERM" label-for="descriptionItem">
                                            <p><strong>CIF</strong></p>
                                          </b-form-group>
                                        </td>
                                        <td>
                                          <b-form-group class="row mb-2" label-cols-md="4" label="POL" label-for="descriptionItem">
                                            <p><strong>Paiton</strong></p>
                                          </b-form-group>
                                          <b-form-group class="row mb-2" label-cols-md="4" label="POD" label-for="descriptionItem">
                                            <p><strong>Rembang</strong></p>
                                          </b-form-group>
                                        </td>
                                        <td>
                                          <b-row>
                                            <b-col md="12" class="text-center"><strong>Januari</strong></b-col>
                                            <b-col md="6" class="text-center">
                                              <strong>ETA POL</strong>
                                            </b-col>
                                            <b-col md="6" class="text-center">
                                              <strong>ETA POD</strong>
                                            </b-col>
                                            <b-col md="6" class="text-center">
                                              <p>10 Januari 2023</p>
                                            </b-col>
                                            <b-col md="6" class="text-center">
                                              <p>16 Januari 2023</p>
                                            </b-col>
                                          </b-row>
                                        </td>
                                        <td class="text-center"><strong>7.000 MT</strong></td>
                                        <td>-</td>
                                      </tr>
                                      <tr>
                                        <td><input type="checkbox" name="shipper2" id="shipper2"/></td>
                                        <td>PLN Rembang</td>
                                        <td><b-badge variant="primary">Own Ship</b-badge><br/> TB.ETI 102</td>
                                        <td>
                                          <b-form-group class="row mb-2" label-cols-md="5" label="CARGO TYPE" label-for="descriptionItem">
                                            <p><strong>COAL</strong></p>
                                          </b-form-group>
                                          <b-form-group class="row mb-2" label-cols-md="5" label="TERM" label-for="descriptionItem">
                                            <p><strong>CIF</strong></p>
                                          </b-form-group>
                                        </td>
                                        <td>
                                          <b-form-group class="row mb-2" label-cols-md="4" label="POL" label-for="descriptionItem">
                                            <p><strong>Paiton</strong></p>
                                          </b-form-group>
                                          <b-form-group class="row mb-2" label-cols-md="4" label="POD" label-for="descriptionItem">
                                            <p><strong>Rembang</strong></p>
                                          </b-form-group>
                                        </td>
                                        <td>
                                          <b-row>
                                            <b-col md="12" class="text-center"><strong>Januari</strong></b-col>
                                            <b-col md="6" class="text-center">
                                              <strong>ETA POL</strong>
                                            </b-col>
                                            <b-col md="6" class="text-center">
                                              <strong>ETA POD</strong>
                                            </b-col>
                                            <b-col md="6" class="text-center">
                                              <p>19 Januari 2023</p>
                                            </b-col>
                                            <b-col md="6" class="text-center">
                                              <p>23 Januari 2023</p>
                                            </b-col>
                                          </b-row>
                                        </td>
                                        <td class="text-center"><strong>7.500 MT</strong></td>
                                        <td>-</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr class="iq-bg-primary">
                                <th class="text-center">2</th>
                                <th class="text-center"><b-badge variant="success" style="font-size:12pt;">New</b-badge></th>
                                <td class="text-center"><strong style="font-size:12pt;">Pertamina Gas</strong></td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <table class="table mb-0" width="100%">
                                    <thead>
                                      <tr class="bg-info">
                                        <th>#</th>
                                        <th width="17%">Description</th>
                                        <th>Fleet</th>
                                        <th>Cargo & Term</th>
                                        <th>Location</th>
                                        <th class="text-center" width="18%">Shipment Plan</th>
                                        <th class="text-center">Plan Quantity</th>
                                        <th>Remarks</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td><input type="checkbox" name="shipper3" id="shipper3"/></td>
                                        <td>PLN Rembang</td>
                                        <td><b-badge variant="info">Third Party</b-badge><br/> TB.Dharma 1</td>
                                        <td>
                                          <b-form-group class="row mb-2" label-cols-md="5" label="CARGO TYPE" label-for="descriptionItem">
                                            <p><strong>COAL</strong></p>
                                          </b-form-group>
                                          <b-form-group class="row mb-2" label-cols-md="5" label="TERM" label-for="descriptionItem">
                                            <p><strong>CIF</strong></p>
                                          </b-form-group>
                                        </td>
                                        <td>
                                          <b-form-group class="row mb-2" label-cols-md="4" label="POL" label-for="descriptionItem">
                                            <p><strong>Paiton</strong></p>
                                          </b-form-group>
                                          <b-form-group class="row mb-2" label-cols-md="4" label="POD" label-for="descriptionItem">
                                            <p><strong>Rembang</strong></p>
                                          </b-form-group>
                                        </td>
                                        <td>
                                          <b-row>
                                            <b-col md="12" class="text-center"><strong>Januari</strong></b-col>
                                            <b-col md="6" class="text-center">
                                              <strong>ETA POL</strong>
                                            </b-col>
                                            <b-col md="6" class="text-center">
                                              <strong>ETA POD</strong>
                                            </b-col>
                                            <b-col md="6" class="text-center">
                                              <p>10 Januari 2023</p>
                                            </b-col>
                                            <b-col md="6" class="text-center">
                                              <p>16 Januari 2023</p>
                                            </b-col>
                                          </b-row>
                                        </td>
                                        <td class="text-center"><strong>29.000 MT</strong></td>
                                        <td>-</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 h5 text-primary">Select Operational Data</legend>
                    <b-row class="pl-3 pr-3">
                      <b-col cols="12">
                        <b-alert :show="true" variant=" " class="text-white bg-warning">
                          <div class="iq-alert-icon">
                            <i class="ri-information-fill"></i>
                          </div>
                          <div class="iq-alert-text">Operational Not Yet Input Data For This Period, Please Input First</div>
                        </b-alert>
                      </b-col>
                      <b-col md="12">
                        <div class="table-responsive">
                          <table class="table table-striped" style="width: 3700px;max-height: 750px;overflow-y: scroll;">
                            <thead class="thead-dark text-center">
                              <tr>
                                <th class="text-center" style="position:sticky;left:0px;background-color:#039BAB;" width="50px">#</th>
                                <th class="text-center" style="position:sticky;left:45px;background-color:#039BAB;" width="250px">PROJECT</th>
                                <th class="text-center" style="position:sticky;left:295px;background-color:#039BAB;" width="150px">FLEET</th>
                                <th class="text-center" style="position:sticky;left:425px;background-color:#039BAB;" width="250px">STATUS</th>
                                <th class="text-center" width="127px">MODA</th>
                                <th class="text-center" width="197px">COMPANY</th>
                                <th class="text-center" width="127px">CARGO TYPE</th>
                                <th class="text-center" width="197px">POL</th>
                                <th class="text-center" width="187px">TA POL/READY</th>
                                <th class="text-center" width="147px">BERTHING</th>
                                <th class="text-center" width="147px">COMMENCED</th>                          
                                <th class="text-center" width="147px">COMPLETED</th>
                                <th class="text-center" width="147px">CARGO LOADING</th>  
                                <th class="text-center" width="197px">POD</th>
                                <th class="text-center" width="147px">TA POD/READY</th>
                                <th class="text-center" width="147px">BERTHING</th>
                                <th class="text-center" width="147px">COMMENCED</th>                          
                                <th class="text-center" width="147px">COMPLETED</th>
                                <th class="text-center" width="147px">CARGO UNLOADING</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style="position:sticky;left:0px;background-color:#039BAB;color:#ffffff;"><input type="checkbox" name="voyage" id="voyage"/></td>
                                <td style="position:sticky;left:45px;background-color:#039BAB;color:#ffffff;">AI PROJECT - LONG HAULING</td>
                                <td style="position:sticky;left:295px;background-color:#039BAB;color:#ffffff;">PT.TCP 201/PB.TCP 3301</td>
                                <td style="position:sticky;left:425px;background-color:#039BAB;color:#ffffff;"><b-badge variant="success" style="font-size:12pt;">Completed</b-badge></td>
                                <td>330 ft</td>
                                <td>PT.Transcoal Pacific</td>
                                <td>Nickel</td>
                                <td>MP Asam2</td>
                                <td>31-Dec-21 00.00</td>
                                <td>02-Jan-22 02.10</td>
                                <td>02-Jan-22 03.05</td>
                                <td>02-Jan-22 05.05</td>
                                <td>7.606 MT</td>
                                <td>LABUAN</td>
                                <td>04-Jan-22 20.00</td>
                                <td>04-Jan-22 20.00</td>
                                <td>04-Jan-22 20.00</td>
                                <td>04-Jan-22 20.00</td>
                                <td>7.506 MT</td>
                              </tr>
                              <tr>
                                <td style="position:sticky;left:0px;background-color:#039BAB;color:#ffffff;"><input type="checkbox" name="voyage" id="voyage"/></td>
                                <td style="position:sticky;left:45px;background-color:#039BAB;color:#ffffff;">AI PROJECT - LONG HAULING</td>
                                <td style="position:sticky;left:295px;background-color:#039BAB;color:#ffffff;">PT.TCP 202/PB.TCP 3302</td>
                                <td style="position:sticky;left:425px;background-color:#039BAB;color:#ffffff;"><b-badge variant="success" style="font-size:12pt;">Completed</b-badge></td>
                                <td>330 ft</td>
                                <td>PT.Transcoal Pacific</td>
                                <td>Nickel</td>
                                <td>MP Asam2</td>
                                <td>31-Dec-21 00.00</td>
                                <td>02-Jan-22 02.10</td>
                                <td>02-Jan-22 03.05</td>
                                <td>02-Jan-22 05.05</td>
                                <td>7.606 MT</td>
                                <td>LABUAN</td>
                                <td>04-Jan-22 20.00</td>
                                <td>04-Jan-22 20.00</td>
                                <td>04-Jan-22 20.00</td>
                                <td>04-Jan-22 20.00</td>
                                <td>7.506 MT</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mt-4">
                      <b-col md="12">
                        <b-row>
                          <b-col>
                            <b-form-group label-for="dateIssued" label="Company">
                              <b-form-select
                                plain
                                :options="CompanyName"
                                id="gender"
                                v-model="companySelected"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group label="Price" label-for="cost">
                              <b-input-group size="md" prepend="Rp">
                                <b-form-input type="number" class="form-control"></b-form-input>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group label="Price to Vendor" label-for="cost">
                              <b-input-group size="md" prepend="Rp">
                                <b-form-input type="number"></b-form-input>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
                <b-col md="12" class="mt-5">
                  <b-button size="lg" variant="success" block>
                    <i class="fa fa-save"></i> Save Data
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-sidebar>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { projectsActions, companiesActions, fleetsActions } from '@src/Utils/helper'

export default {
  name: 'TabRealisasi',
  async mounted () {
    await this.getProjectList()
    await this.fetchFleets()
    await this.getCompanyListData()
  },
  data () {
    return {
      vehicle: '',
      projectValue: [],
      filterDailyOps: {
        date: moment().format('YYYY-MM-DD'),
        ownership: 'OWNED',
        subProject: [],
        vehicleTypeIds: [1, 2]
      },
      CompanyName: [{ value: null, text: 'Select Company' }],
      companySelected: null,
      optionsVehicles: [],
      crewList: {
        isFiltering: false,
        params: {
          fullname: '',
          vehicle_id: null,
          from: null,
          to: null,
          position_id: null,
          status: null,
        },
        data: {
          head: [],
          body: [],
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        },
      },
      realisasiPeriod: '',
      projectValue: '',
      projectOptions: [
      ],

      pageDetailSalesOrder: false,
      addPurchaseRequest: false,
      spalHistory: false,
      realisasiData: false,

      // fleet
      slideDetailRakor: false,
      slideAddRakor: false,
      slideDetailPlan: false,
      
      // COMPANY
      companyActive: {
        single: 'active',
        disable: 'active',
        number: 'two',
        colorDisabled: 'five',
        color: 'danger'
      },
      company: [
        {
          name: 'single',
          label: 'EXISTING PROJECT',
          value: 'active',
          disabled: false
        },
        {
          name: 'single',
          label: 'NEW PROJECT',
          value: 'inactive',
          disabled: false
        }
      ],
      shipmentPlanningActive: {
        single: 'yes',
        disable: 'yes',
        number: 'two',
        colorDisabled: 'five',
        color: 'danger'
      },
      shipmentPlanning: [
        {
          name: 'single',
          label: 'Yes',
          value: 'yes',
          disabled: false
        },
        {
          name: 'single',
          label: 'No',
          value: 'no',
          disabled: false
        }
      ],

      // NEGARA
      stateActive: {
        single: 'active',
        disable: 'active',
        number: 'two',
        colorDisabled: 'five',
        color: 'danger'
      },
      state: [
        {
          name: 'single',
          label: 'INDONESIA',
          value: 'active',
          disabled: false
        },
        {
          name: 'single',
          label: 'FOREIGN',
          value: 'inactive',
          disabled: false
        }
      ],

      // OWNERSHIP
      ownershipActive: {
        single: 'active',
        disable: 'active',
        number: 'two',
        colorDisabled: 'five',
        color: 'danger'
      },
      ownership: [
        {
          name: 'ownship',
          label: 'OWN SHIP',
          value: 'active',
          disabled: false
        },
        {
          name: 'chartered',
          label: 'CHARTERED',
          value: 'inactive',
          disabled: false
        }
      ],

      // SELECT PROVINSI
      selectedPlanning: 1,
      optionPlanning: [
        { value: '1', text: 'Arutmin == PLN Rembang - Longhauling' },
        { value: '2', text: 'KPC == PLN Rembang' }
      ],
      
      // SELECT PROVINSI
      selectedProvinsi: 1,
      optionProvinsi: [
        { value: '1', text: 'DKI JAKARTA' },
        { value: '2', text: 'JAWA BARAT' },
        { value: '3', text: 'JAWA TENGAH' },
        { value: '4', text: 'JAWA TIMUR' }
      ],

      // SELECT PROVINSI
      selectedKota: 4,
      optionKota: [
        { value: '1', text: 'JAKARTA PUSAT' },
        { value: '2', text: 'JAKARTA UTARA' },
        { value: '3', text: 'JAKARTA TIMUR' },
        { value: '4', text: 'JAKARTA SELATAN' },
        { value: '5', text: 'JAKARTA BARAT' },
        { value: '6', text: 'KEPULAUAN SERIBU' },
      ],

      // SELECT KECAMATAN
      selectedKecamatan: 1,
      optionKecamatan: [
        { value: '1', text: 'KEBAYORAN LAMA' },
        { value: '2', text: 'KEBAYORAN BARU' },
        { value: '3', text: 'GROGOL' },
        { value: '4', text: 'PASAR MINGGU' }
      ],

      // SELECT KELURAHAN
      selectedKelurahan: 1,
      optionKelurahan: [
        { value: '1', text: 'PONDOK PINANG' },
        { value: '2', text: 'PESANGGRAHAN' },
        { value: '3', text: 'PETUKANGAN' }
      ],

      // SELECT TAHUN
      selectedTahun: null,
      optionTahun: [
        { value: '1', text: '2023' },
        { value: '2', text: '2022' },
        { value: '3', text: '2021' },
        { value: '3', text: '2020' }
      ],
      fix: [{
          activeLabel: 'Yes',
          inactiveLabel: 'No',
          class: 'primary',
          checked: false
        }],
    }
  },
  methods: {
    ...projectsActions,
    ...fleetsActions,
    ...companiesActions,
    async getCompanyListData() {
      const res = await this.getCompanies()

      if (res.status == 'success' && res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          this.CompanyName.push({
            // text: `${element.company} - ${element.location}`,
            text: `${element.company}`,
            value: element.id,
          })
        })
      } else {
        this.$swal(`Oops!`, res.data, 'error')
      }
    },
    async getProjectList () {
      let params = {
        active: true,
        search: '',
        page: 1,
        perPage: 250
      }
      const res = await this.getProjects(params)
      if (res.status == 'success') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(val => {
            this.projectOptions.push({
              project: val.projectName,
              sub: val.subProject
            })
          })
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data.data,
          'error'
        )
      }
    },
    async fetchFleets() {
      this.fleetsLoading = true
      this.fleets = []

      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
        search: '',
      })
      let res = await this.getFleets(params)

      if (res.status) {
        this.fleetsLoading = false
        if (res.data && res.data.length > 0) {
          this.fleetOptions = res.data.map((val) => ({
            text: val.name,
            value: val.id,
          }))
        }
      }
    },
  }
}
</script>
<style>
/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/
.table-fixed tbody {
    height: 300px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tbody td,
.table-fixed tbody th,
.table-fixed thead > tr > th {
    float: left;
    position: relative;

    &::after {
        content: '';
        clear: both;
        display: block;
    }
}
</style>